//@ts-nocheck
/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * MetaIS Notification Engine
 * OpenAPI spec version: latest
 */
import { useQuery, useMutation } from '@tanstack/react-query'
import type { UseQueryOptions, UseMutationOptions, QueryFunction, MutationFunction, UseQueryResult, QueryKey } from '@tanstack/react-query'
import { useNotificationsSwaggerClient } from '../hooks/useNotificationsSwaggerClient'
export type GetNotificationListElasticParams = {
    eventType?: string
    pageNumber: number
    perPage: number
    onlyUnread?: boolean
    sortBy?: string
    ascending?: boolean
    fulltextSearch?: string
}

export type RemoveNotificationList200 = { [key: string]: any }

export type RemoveNotificationListParams = {
    idList?: number[]
}

export type GetNotificationListParams = {
    eventType?: string
    pageNumber: number
    perPage: number
    onlyUnread?: boolean
    sortBy?: string
    ascending?: boolean
}

export type Reindex200 = { [key: string]: any }

export type ReindexParams = {
    offset?: number
    limit?: number
}

export type AddNotification200 = { [key: string]: any }

export type RemoveNotification200 = { [key: string]: any }

export type SetNotificationAsRead200 = { [key: string]: any }

export type RemoveNotifications200 = { [key: string]: any }

export type RemoveNotificationsParams = {
    onlyUnread?: boolean
}

export type SetAllNotificationsAsRead200 = { [key: string]: any }

export interface ApiNewsPreview {
    id?: number
    title?: string
    shortDescription?: string
    createdAt?: string
    createdBy?: string
}

export interface ApiNewsPreviewList {
    news?: ApiNewsPreview[]
    newsCount?: number
}

export interface PaginationData {
    page?: number
    perPage?: number
    totalPages?: number
    totalItems?: number
    totalUnreadedItems?: number
}

export interface Notification {
    id?: number
    userId?: string
    message?: string
    messagePerex?: string
    targetUri?: string
    eventType?: string
    createdAt?: string
    readedAt?: string
    deleted?: boolean
    sendToUserIdSet?: string[]
    notifType?: string
}

export interface NotificationsList {
    notifications?: Notification[]
    pagination?: PaginationData
}

export interface ApiNews {
    id?: number
    title?: string
    shortDescription?: string
    createdAt?: string
    createdBy?: string
    longDescription?: string
}

export type ApiErrorData = { [key: string]: any }

export interface ApiError {
    type?: string
    message?: string
    data?: ApiErrorData
    logToken?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const useSetAllNotificationsAsReadHook = () => {
    const setAllNotificationsAsRead = useNotificationsSwaggerClient<SetAllNotificationsAsRead200>()

    return () => {
        return setAllNotificationsAsRead({ url: `/notifications`, method: 'put' })
    }
}

export const useSetAllNotificationsAsReadMutationOptions = <TError = ApiError, TVariables = void, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSetAllNotificationsAsReadHook>>>, TError, TVariables, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSetAllNotificationsAsReadHook>>>, TError, TVariables, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const setAllNotificationsAsRead = useSetAllNotificationsAsReadHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSetAllNotificationsAsReadHook>>>, TVariables> = () => {
        return setAllNotificationsAsRead()
    }

    return { mutationFn, ...mutationOptions }
}

export type SetAllNotificationsAsReadMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSetAllNotificationsAsReadHook>>>>

export type SetAllNotificationsAsReadMutationError = ApiError

export const useSetAllNotificationsAsRead = <TError = ApiError, TVariables = void, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSetAllNotificationsAsReadHook>>>, TError, TVariables, TContext>
}) => {
    const mutationOptions = useSetAllNotificationsAsReadMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useRemoveNotificationsHook = () => {
    const removeNotifications = useNotificationsSwaggerClient<RemoveNotifications200>()

    return (params?: RemoveNotificationsParams) => {
        return removeNotifications({ url: `/notifications`, method: 'delete', params })
    }
}

export const useRemoveNotificationsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRemoveNotificationsHook>>>,
        TError,
        { params?: RemoveNotificationsParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRemoveNotificationsHook>>>,
    TError,
    { params?: RemoveNotificationsParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const removeNotifications = useRemoveNotificationsHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRemoveNotificationsHook>>>, { params?: RemoveNotificationsParams }> = (
        props,
    ) => {
        const { params } = props ?? {}

        return removeNotifications(params)
    }

    return { mutationFn, ...mutationOptions }
}

export type RemoveNotificationsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRemoveNotificationsHook>>>>

export type RemoveNotificationsMutationError = ApiError

export const useRemoveNotifications = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRemoveNotificationsHook>>>,
        TError,
        { params?: RemoveNotificationsParams },
        TContext
    >
}) => {
    const mutationOptions = useRemoveNotificationsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetNotificationDetailHook = () => {
    const getNotificationDetail = useNotificationsSwaggerClient<Notification>()

    return (id: number, signal?: AbortSignal) => {
        return getNotificationDetail({ url: `/notifications/${id}`, method: 'get', signal })
    }
}

export const getGetNotificationDetailQueryKey = (id: number) => [`/notifications/${id}`] as const

export const useGetNotificationDetailQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationDetailHook>>>, TError = ApiError>(
    id: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationDetailHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationDetailHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetNotificationDetailQueryKey(id)

    const getNotificationDetail = useGetNotificationDetailHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetNotificationDetailHook>>>> = ({ signal }) =>
        getNotificationDetail(id, signal)

    return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type GetNotificationDetailQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetNotificationDetailHook>>>>
export type GetNotificationDetailQueryError = ApiError

export const useGetNotificationDetail = <TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationDetailHook>>>, TError = ApiError>(
    id: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationDetailHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetNotificationDetailQueryOptions(id, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useSetNotificationAsReadHook = () => {
    const setNotificationAsRead = useNotificationsSwaggerClient<SetNotificationAsRead200>()

    return (id: number) => {
        return setNotificationAsRead({ url: `/notifications/${id}`, method: 'put' })
    }
}

export const useSetNotificationAsReadMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSetNotificationAsReadHook>>>, TError, { id: number }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSetNotificationAsReadHook>>>, TError, { id: number }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const setNotificationAsRead = useSetNotificationAsReadHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSetNotificationAsReadHook>>>, { id: number }> = (props) => {
        const { id } = props ?? {}

        return setNotificationAsRead(id)
    }

    return { mutationFn, ...mutationOptions }
}

export type SetNotificationAsReadMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSetNotificationAsReadHook>>>>

export type SetNotificationAsReadMutationError = ApiError

export const useSetNotificationAsRead = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSetNotificationAsReadHook>>>, TError, { id: number }, TContext>
}) => {
    const mutationOptions = useSetNotificationAsReadMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useRemoveNotificationHook = () => {
    const removeNotification = useNotificationsSwaggerClient<RemoveNotification200>()

    return (id: number) => {
        return removeNotification({ url: `/notifications/${id}`, method: 'delete' })
    }
}

export const useRemoveNotificationMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRemoveNotificationHook>>>, TError, { id: number }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRemoveNotificationHook>>>, TError, { id: number }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const removeNotification = useRemoveNotificationHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRemoveNotificationHook>>>, { id: number }> = (props) => {
        const { id } = props ?? {}

        return removeNotification(id)
    }

    return { mutationFn, ...mutationOptions }
}

export type RemoveNotificationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRemoveNotificationHook>>>>

export type RemoveNotificationMutationError = ApiError

export const useRemoveNotification = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRemoveNotificationHook>>>, TError, { id: number }, TContext>
}) => {
    const mutationOptions = useRemoveNotificationMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useAddNotificationHook = () => {
    const addNotification = useNotificationsSwaggerClient<AddNotification200>()

    return (notification: Notification) => {
        return addNotification({
            url: `/notifications/new`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: notification,
        })
    }
}

export const useAddNotificationMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddNotificationHook>>>, TError, { data: Notification }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddNotificationHook>>>, TError, { data: Notification }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const addNotification = useAddNotificationHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAddNotificationHook>>>, { data: Notification }> = (props) => {
        const { data } = props ?? {}

        return addNotification(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type AddNotificationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddNotificationHook>>>>
export type AddNotificationMutationBody = Notification
export type AddNotificationMutationError = ApiError

export const useAddNotification = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddNotificationHook>>>, TError, { data: Notification }, TContext>
}) => {
    const mutationOptions = useAddNotificationMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useReindexHook = () => {
    const reindex = useNotificationsSwaggerClient<Reindex200>()

    return (params?: ReindexParams, signal?: AbortSignal) => {
        return reindex({ url: `/notifications/reindex`, method: 'get', params, signal })
    }
}

export const getReindexQueryKey = (params?: ReindexParams) => [`/notifications/reindex`, ...(params ? [params] : [])] as const

export const useReindexQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useReindexHook>>>, TError = ApiError>(
    params?: ReindexParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReindexHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReindexHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReindexQueryKey(params)

    const reindex = useReindexHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReindexHook>>>> = ({ signal }) => reindex(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type ReindexQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReindexHook>>>>
export type ReindexQueryError = ApiError

export const useReindex = <TData = Awaited<ReturnType<ReturnType<typeof useReindexHook>>>, TError = ApiError>(
    params?: ReindexParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReindexHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReindexQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetNotificationListHook = () => {
    const getNotificationList = useNotificationsSwaggerClient<NotificationsList>()

    return (params: GetNotificationListParams, signal?: AbortSignal) => {
        return getNotificationList({ url: `/notifications/list`, method: 'get', params, signal })
    }
}

export const getGetNotificationListQueryKey = (params: GetNotificationListParams) => [`/notifications/list`, ...(params ? [params] : [])] as const

export const useGetNotificationListQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationListHook>>>, TError = ApiError>(
    params: GetNotificationListParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationListHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationListHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetNotificationListQueryKey(params)

    const getNotificationList = useGetNotificationListHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetNotificationListHook>>>> = ({ signal }) =>
        getNotificationList(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetNotificationListQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetNotificationListHook>>>>
export type GetNotificationListQueryError = ApiError

export const useGetNotificationList = <TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationListHook>>>, TError = ApiError>(
    params: GetNotificationListParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationListHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetNotificationListQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useRemoveNotificationListHook = () => {
    const removeNotificationList = useNotificationsSwaggerClient<RemoveNotificationList200>()

    return (params?: RemoveNotificationListParams) => {
        return removeNotificationList({ url: `/notifications/list`, method: 'delete', params })
    }
}

export const useRemoveNotificationListMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRemoveNotificationListHook>>>,
        TError,
        { params?: RemoveNotificationListParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRemoveNotificationListHook>>>,
    TError,
    { params?: RemoveNotificationListParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const removeNotificationList = useRemoveNotificationListHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useRemoveNotificationListHook>>>,
        { params?: RemoveNotificationListParams }
    > = (props) => {
        const { params } = props ?? {}

        return removeNotificationList(params)
    }

    return { mutationFn, ...mutationOptions }
}

export type RemoveNotificationListMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRemoveNotificationListHook>>>>

export type RemoveNotificationListMutationError = ApiError

export const useRemoveNotificationList = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRemoveNotificationListHook>>>,
        TError,
        { params?: RemoveNotificationListParams },
        TContext
    >
}) => {
    const mutationOptions = useRemoveNotificationListMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetNotificationListElasticHook = () => {
    const getNotificationListElastic = useNotificationsSwaggerClient<NotificationsList>()

    return (params: GetNotificationListElasticParams, signal?: AbortSignal) => {
        return getNotificationListElastic({ url: `/notifications/listElastic`, method: 'get', params, signal })
    }
}

export const getGetNotificationListElasticQueryKey = (params: GetNotificationListElasticParams) =>
    [`/notifications/listElastic`, ...(params ? [params] : [])] as const

export const useGetNotificationListElasticQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationListElasticHook>>>,
    TError = ApiError,
>(
    params: GetNotificationListElasticParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationListElasticHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationListElasticHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetNotificationListElasticQueryKey(params)

    const getNotificationListElastic = useGetNotificationListElasticHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetNotificationListElasticHook>>>> = ({ signal }) =>
        getNotificationListElastic(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetNotificationListElasticQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetNotificationListElasticHook>>>>
export type GetNotificationListElasticQueryError = ApiError

export const useGetNotificationListElastic = <TData = Awaited<ReturnType<ReturnType<typeof useGetNotificationListElasticHook>>>, TError = ApiError>(
    params: GetNotificationListElasticParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNotificationListElasticHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetNotificationListElasticQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}
