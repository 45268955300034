import { Pagination } from '@isdd/idsk-ui-kit/types'
import { useState } from 'react'

import { BASE_PAGE_SIZE } from '@isdd/metais-common/api'
import { BASE_PAGE_NUMBER } from '@isdd/metais-common/constants'
import { getPagination } from '@isdd/metais-common/utils/utils'

export const useCustomPagination = (dataLength: number) => {
    const defaultPagination: Pagination = {
        pageNumber: BASE_PAGE_NUMBER,
        pageSize: BASE_PAGE_SIZE,
        dataLength: dataLength,
    }

    const [pagination, setPagination] = useState<Pagination>(defaultPagination)
    const { pageNumber, pageSize, startOfList, endOfList } = getPagination(pagination.pageNumber, pagination.pageSize, pagination.dataLength)

    return {
        dataLength: pagination.dataLength,
        pagination,
        pageNumber,
        pageSize,
        startOfList,
        endOfList,
        setPagination,
    }
}
