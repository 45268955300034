import { BreadCrumbs, HomeIcon } from '@isdd/idsk-ui-kit/index'
import { Tab } from '@isdd/idsk-ui-kit/tabs/Tabs'
import { useReadConfigurationItem } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { CI_ITEM_QUERY_KEY, Profil_Webove_Sidlo_typ_ISVS_APP_WEB, Profil_Webove_Sidlo_typ_rozhrania_list } from '@isdd/metais-common/constants'
import { Actions, useUserAbility } from '@isdd/metais-common/hooks/permissions/useUserAbility'
import { useGetCiTypeWrapper } from '@isdd/metais-common/hooks/useCiType.hook'
import { ATTRIBUTE_NAME, QueryFeedback } from '@isdd/metais-common/index'
import { setDocumentTitle } from '@isdd/metais-common/utils/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'

import { getCiHowToBreadCrumb, useCiDetailPageTitle, useCiListPageHeading, useGetEntityParamsFromUrl } from '@/componentHelpers/ci'
import { MainContentWrapper } from '@/components/MainContentWrapper'
import { CiPermissionsWrapper } from '@/components/permissions/CiPermissionsWrapper'
import { CiEntityDetailView } from '@/components/views/ci/detail/CiEntityDetailView'
import { useCiCheckEntityTypeRedirectHook } from '@/hooks/useCiCheckEntityTypeRedirect.hook'

const ISVSDetailPage: React.FC = () => {
    const { t } = useTranslation()
    const { entityId, entityName } = useGetEntityParamsFromUrl()
    const userAbility = useUserAbility()

    const {
        state: { user },
    } = useAuth()

    const { data: ciTypeData, isLoading: isCiTypeDataLoading, isError: isCiTypeDataError } = useGetCiTypeWrapper(entityName ?? '')

    const {
        data: ciItemData,
        isLoading: isCiItemDataLoading,
        isError: isCiItemDataError,
        refetch,
    } = useReadConfigurationItem(entityId ?? '', {
        query: {
            queryKey: [CI_ITEM_QUERY_KEY, entityId],
        },
    })
    useCiCheckEntityTypeRedirectHook(ciItemData, entityName)

    const { getHeading } = useCiListPageHeading(ciTypeData?.name ?? '', t)
    const { getHeading: getHeadingDetail } = useCiDetailPageTitle(
        ciTypeData?.name ?? '',
        ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov],
        t,
    )
    setDocumentTitle(getHeadingDetail())

    const showDeclarationTab =
        Profil_Webove_Sidlo_typ_ISVS_APP_WEB.includes(ciItemData?.attributes?.EA_Profil_ISVS_typ_isvs) &&
        Profil_Webove_Sidlo_typ_rozhrania_list.includes(ciItemData?.attributes?.Profil_Webove_Sidlo_typ_rozhrania)

    const showFinancesTab = ciItemData?.attributes?.EA_Profil_ISVS_prvok_rozpoctu?.length > 0 && !!user

    const tabList: Tab[] = [
        {
            id: 'information',
            path: `/ci/${entityName}/${entityId}/`,
            title: t('ciType.informations'),
            content: <Outlet />,
        },
        {
            id: 'documents',
            path: `/ci/${entityName}/${entityId}/documents`,
            title: t('ciType.documents'),
            content: <Outlet />,
        },
        {
            id: 'relationships',
            path: `/ci/${entityName}/${entityId}/relationships`,
            title: t('ciType.relationships'),
            content: <Outlet />,
        },
        ...(showDeclarationTab
            ? [
                  {
                      id: 'declaration',
                      path: `/ci/${entityName}/${entityId}/declaration`,
                      title: t('ciType.declaration'),
                      content: <Outlet />,
                  },
              ]
            : []),
        ...(userAbility.can(Actions.HISTORY, 'ci')
            ? [
                  {
                      id: 'history',
                      path: `/ci/${entityName}/${entityId}/history`,
                      title: t('ciType.history'),
                      content: <Outlet />,
                  },
              ]
            : []),
        ...(showFinancesTab
            ? [
                  {
                      id: 'finances',
                      path: `/ci/${entityName}/${entityId}/finances`,
                      title: t('ciType.finances'),
                      content: <Outlet />,
                  },
              ]
            : []),
    ]

    return (
        <>
            <BreadCrumbs
                withWidthContainer
                links={[
                    { label: t('breadcrumbs.home'), href: '/', icon: HomeIcon },
                    ...getCiHowToBreadCrumb(entityName ?? '', t),
                    { label: getHeading(), href: `/ci/${entityName}` },
                    {
                        label: ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] ?? t('breadcrumbs.noName'),
                        href: `/ci/${entityName}/${entityId}`,
                    },
                ]}
            />

            <MainContentWrapper>
                {isCiItemDataError ? (
                    <QueryFeedback loading={false} error={isCiItemDataError} />
                ) : (
                    <CiPermissionsWrapper entityId={entityId ?? ''} entityName={entityName ?? ''}>
                        <CiEntityDetailView
                            isError={isCiItemDataError || isCiTypeDataError}
                            isLoading={isCiItemDataLoading || isCiTypeDataLoading}
                            ciItemData={ciItemData}
                            ciTypeData={ciTypeData}
                            ciItemDataRefetch={refetch}
                            tabList={tabList}
                            entityId={entityId ?? ''}
                            entityName={entityName ?? ''}
                        />
                    </CiPermissionsWrapper>
                )}
            </MainContentWrapper>
        </>
    )
}

export default ISVSDetailPage
