import { ATTRIBUTE_NAME } from '@isdd/metais-common/api/constants'
import { Attribute } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { HTML_TYPE, STRING_TYPE } from '@isdd/metais-common/constants'
import { Languages } from '@isdd/metais-common/localization/languages'
import { ConfigurationItemUiAttributes } from '@isdd/metais-common/api/generated/cmdb-swagger'

export interface ColumnsOutputDefinition {
    attributes?: ConfigurationItemUiAttributes
    metaAttributes?: {
        [key: string]: string
    }
    type?: string
    uuid?: string
    checked?: boolean
}

type GetLangForAttributeProps = {
    language: string
    technicalName: string
    schemaAttribute?: Attribute
}

const ENGLISH_ATTRIBUTES: string[] = [ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov, ATTRIBUTE_NAME.Gen_Profil_anglicky_popis]

const getLanguageForAttribute = (isEnglishAttribute: boolean, language: string): Languages | undefined => {
    if (language === Languages.ENGLISH) {
        return isEnglishAttribute ? undefined : Languages.SLOVAK
    } else {
        return isEnglishAttribute ? Languages.ENGLISH : undefined
    }
}

export const setLangForAttribute = ({ language, technicalName, schemaAttribute }: GetLangForAttributeProps): Languages | undefined => {
    const isEnglishAttribute = ENGLISH_ATTRIBUTES.includes(technicalName)
    const isHTML = schemaAttribute?.type === HTML_TYPE
    const isString = schemaAttribute?.type === STRING_TYPE
    const isNotEnum = !schemaAttribute?.constraints?.length

    if (!schemaAttribute) {
        return getLanguageForAttribute(isEnglishAttribute, language)
    }

    if ((isHTML || isString) && isNotEnum) {
        return getLanguageForAttribute(isEnglishAttribute, language)
    }

    return undefined
}
