import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@isdd/idsk-ui-kit/index'
import { Stepper } from '@isdd/idsk-ui-kit/stepper/Stepper'
import { ISection } from '@isdd/idsk-ui-kit/stepper/StepperSection'
import { ConfigurationItemUiAttributes, HierarchyRightsUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { GidRoleData } from '@isdd/metais-common/api/generated/iam-swagger'
import { AttributeProfile, CiCode, CiType } from '@isdd/metais-common/api/generated/types-repo-swagger'
import {
    EA_Profil_ISVS_typ_isvs,
    ENTITY_ISVS,
    ENTITY_KRIS,
    ENTITY_PROJECT,
    ISVS_Attribute_Profiles_for_web,
    KRIS_Profil_nazov,
    Profil_Webove_Sidlo_typ_ISVS_APP_WEB,
} from '@isdd/metais-common/constants'
import { ATTRIBUTE_NAME, SubmitWithFeedback } from '@isdd/metais-common/index'
import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react'
import { FieldErrors, FieldValues, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { generateFormSchema } from './createCiEntityFormSchema'
import styles from './createEntity.module.scss'
import { getFilteredAttributeProfilesBasedOnRole, getValidAndVisibleAttributes } from './createEntityHelpers'

import { filterFormValuesBasedOnCurrentRole, formatForFormDefaultValues } from '@/componentHelpers/ci'
import { AttributesConfigTechNames } from '@/components/attribute-input/attributeDisplaySettings'

type Props = {
    entityName: string
    generatedEntityId: CiCode
    ciTypeData: CiType | undefined
    onSubmit: (formData: FieldValues) => void
    onError?: (errors: FieldErrors) => void
    isProcessing?: boolean
    selectedRole?: GidRoleData | null
    stepperList: ISection[]
    formDefaultValues: ConfigurationItemUiAttributes
    isUpdate: boolean
    setHasReset: Dispatch<SetStateAction<boolean>>
    isSubmitDisabled: boolean
    selectedOrg?: HierarchyRightsUi | null
    handleSectionOpen: (id: string) => void
    openOrCloseAllSections: () => void
    relationSchemaAttributeProfiles?: AttributeProfile[]
}

export const CiEntityFormBody: React.FC<Props> = ({
    entityName,
    formDefaultValues,
    onSubmit,
    onError,
    stepperList,
    selectedRole,
    isUpdate,
    generatedEntityId,
    setHasReset,
    isProcessing,
    isSubmitDisabled,
    ciTypeData,
    selectedOrg,
    handleSectionOpen,
    openOrCloseAllSections,
    relationSchemaAttributeProfiles,
}) => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    const combinedProfiles = useMemo(
        () => [ciTypeData as AttributeProfile, ...(ciTypeData?.attributeProfiles ?? []), ...(relationSchemaAttributeProfiles ?? [])],
        [ciTypeData, relationSchemaAttributeProfiles],
    )

    const formSchema = useMemo(() => {
        return generateFormSchema(
            isUpdate ? combinedProfiles : getFilteredAttributeProfilesBasedOnRole(combinedProfiles, selectedRole?.roleName ?? ''),
            t,
            i18n.language,
            null,
            ciTypeData?.technicalName,
            formDefaultValues,
        )
    }, [isUpdate, combinedProfiles, selectedRole?.roleName, t, i18n.language, ciTypeData?.technicalName, formDefaultValues])

    const methods = useForm({
        defaultValues: formDefaultValues,
        mode: 'onChange',
        resolver: yupResolver(formSchema),
    })
    const { handleSubmit, reset, formState, getValues, setValue, trigger, watch } = methods
    useEffect(() => {
        formState.isSubmitted && trigger()
    }, [formState.isSubmitted, t, trigger])
    useEffect(() => {
        if (!isUpdate) {
            const currentValues = getValues()
            const currentRole = selectedRole
            const attributes = getValidAndVisibleAttributes(ciTypeData)

            const filteredFormValuesWithoutPermission = filterFormValuesBasedOnCurrentRole(
                combinedProfiles,
                currentRole?.roleName ?? '',
                currentValues,
            )

            reset(formatForFormDefaultValues(filteredFormValuesWithoutPermission, attributes))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRole])

    useEffect(() => {
        const referenceIdValue = generatedEntityId?.ciurl?.split('/').pop()
        const metaIsCodeValue = generatedEntityId?.cicode
        setValue(AttributesConfigTechNames.REFERENCE_ID, referenceIdValue)
        setValue(AttributesConfigTechNames.METAIS_CODE, metaIsCodeValue)
        Object.entries(formDefaultValues).forEach((item) => {
            const element = formState.defaultValues?.[item[0]]
            if (element == '' || element == undefined) {
                setValue(item[0], item[1])
            }
        })
    }, [formState.defaultValues, formDefaultValues, setValue, generatedEntityId?.ciurl, generatedEntityId?.cicode])

    useEffect(() => {
        if (entityName === ENTITY_KRIS) setValue(ATTRIBUTE_NAME.Gen_Profil_nazov, `${KRIS_Profil_nazov} ${selectedOrg?.poName}`)
        if (entityName === ENTITY_PROJECT) setValue(AttributesConfigTechNames.EA_Profil_Projekt_prijimatel, selectedOrg?.poName)
    }, [selectedOrg, setValue, entityName])

    const isvsWeboveSidloOmittedProfiles =
        entityName === ENTITY_ISVS && !Profil_Webove_Sidlo_typ_ISVS_APP_WEB.includes(watch(EA_Profil_ISVS_typ_isvs))
            ? ISVS_Attribute_Profiles_for_web
            : []

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit, onError)} noValidate>
                <Stepper
                    subtitleTitle=""
                    stepperList={stepperList.filter((step) => !isvsWeboveSidloOmittedProfiles.includes(step.id))}
                    handleSectionOpen={handleSectionOpen}
                    openOrCloseAllSections={openOrCloseAllSections}
                />
                <SubmitWithFeedback
                    className={styles.buttonGroup}
                    additionalButtons={[
                        <Button
                            key={1}
                            label={t('button.cancel')}
                            type="reset"
                            variant="secondary"
                            onClick={() => {
                                reset()
                                setHasReset(true)
                                navigate(-1)
                            }}
                        />,
                    ]}
                    submitButtonLabel={t('button.saveChanges')}
                    loading={isProcessing || formState.isValidating}
                    disabled={isSubmitDisabled}
                />
            </form>
        </FormProvider>
    )
}
