import { TextBody, TextHeading } from '@isdd/idsk-ui-kit'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ATTRIBUTE_NAME } from '@isdd/metais-common/api/constants'
import { ConfigurationItemUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { EnumItem, EnumType } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { FindAll11200 } from '@isdd/metais-common/api/generated/iam-swagger'
import { Languages } from '@isdd/metais-common/localization/languages'

type Props = {
    poItem: ConfigurationItemUi
    roleGroupsData: EnumType | undefined
    allRoles: FindAll11200 | undefined
}

export const UserRightsPOItem: React.FC<Props> = ({ poItem, roleGroupsData, allRoles }) => {
    const {
        state: { user },
    } = useAuth()
    const { i18n } = useTranslation()
    const groupSet = new Set<EnumItem>([])
    user?.groupData
        .find((po) => po.orgId === poItem.uuid)
        ?.roles.forEach((role) => {
            if (Array.isArray(allRoles)) {
                const groupCodes = allRoles.find((r) => r.uuid === role.roleUuid)?.assignedGroup
                if (groupCodes) {
                    groupCodes.forEach((code: string) => {
                        const group = roleGroupsData?.enumItems?.find((o: EnumItem) => o.code === code)
                        if (group) groupSet.add(group)
                    })
                }
            }
        })
    const groups = Array.from(groupSet)

    return (
        <li>
            <TextHeading size="M">{poItem?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov]}</TextHeading>
            <ul>
                {groups.map((group) => (
                    <li key={group.id}>
                        <TextBody> {i18n.language == Languages.SLOVAK ? group.description ?? '' : group.engDescription ?? ''}</TextBody>
                    </li>
                ))}
            </ul>
        </li>
    )
}
