import { useState } from 'react'

// Used to capture 'Escape' event, to prevent bubling event to parents such as Modal.
// This way closing select inside modal wont close modal itself.
export const useKeyEvents = () => {
    const [isMenuOpen, setMenuOpen] = useState(false)
    const onMenuClose = () => setMenuOpen(false)
    const onMenuOpen = () => setMenuOpen(true)
    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.code === 'Escape' && isMenuOpen) {
            event.stopPropagation()
            event.nativeEvent.stopPropagation()
        }
    }

    return {
        onMenuClose,
        onMenuOpen,
        onKeyDown,
    }
}
