import { useTranslation } from 'react-i18next'
import { setDocumentTitle } from '@isdd/metais-common/utils/utils'

import { OlaContractDetailView } from '@/components/views/ola-contract-list/OlaContractDetailView'
import { OlaContractDetailContainer } from '@/components/containers/OlaContractDetailContainer'

export const OlaContractDetail = () => {
    const { t } = useTranslation()
    return (
        <OlaContractDetailContainer
            View={(props) => {
                setDocumentTitle(t('olaContracts.detail.title', { name: props.olaContract?.name }))
                return <OlaContractDetailView {...props} />
            }}
        />
    )
}
