//@ts-nocheck
/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * MetaIS Types Repo
 * OpenAPI spec version: latest
 */
import { useQuery, useMutation } from '@tanstack/react-query'
import type { UseQueryOptions, UseMutationOptions, QueryFunction, MutationFunction, UseQueryResult, QueryKey } from '@tanstack/react-query'
import { useTypesRepoSwaggerClient } from '../hooks/useTypesRepoSwaggerClient'
export type ListGenericAttrProfileParams = {
    filter: AttributeProfileFilter
}

export type GenerateCodeAndURL1Params = {
    count: number
}

export type ListRelationshipTypes2Params = {
    roles: string[]
}

export type StoreAdminEntity1Params = {
    isFromZC?: boolean
}

export type ListAttrProfileParams = {
    filter: AttributeProfileFilter
}

export type ListCiTypesParams = {
    filter: CiTypeFilter
}

export type ListRelationshipTypesParams = {
    filter: RelationshipTypeFilter
}

export interface AttributeTranslateMap {
    sourceAttributeTN?: string
    targetAttributeTN?: string
    targetLocale?: string
}

export interface AttributeTranslateMapList {
    mapList?: AttributeTranslateMap[]
}

export type RelatedCiTypePreviewRelationshipTypeUsageType =
    (typeof RelatedCiTypePreviewRelationshipTypeUsageType)[keyof typeof RelatedCiTypePreviewRelationshipTypeUsageType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelatedCiTypePreviewRelationshipTypeUsageType = {
    system: 'system',
    application: 'application',
} as const

export type RelatedCiTypePreviewCiTypeUsageType = (typeof RelatedCiTypePreviewCiTypeUsageType)[keyof typeof RelatedCiTypePreviewCiTypeUsageType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelatedCiTypePreviewCiTypeUsageType = {
    system: 'system',
    application: 'application',
} as const

export interface RelatedCiTypePreview {
    ciTypeName?: string
    ciTypeTechnicalName?: string
    engCiTypeName?: string
    ciTypeUsageType?: RelatedCiTypePreviewCiTypeUsageType
    ciTypeValid?: boolean
    ciRoleList?: string[]
    ciColor?: string
    ciCategory?: string
    relationshipTypeTechnicalName?: string
    relationshipTypeName?: string
    engRelationshipTypeName?: string
    relationshipTypeUsageType?: RelatedCiTypePreviewRelationshipTypeUsageType
    relationshipTypeValid?: boolean
    relationshipRoleList?: string[]
    isOwnerRelationship?: boolean
    relationshipCategory?: string
    ownerRelationship?: boolean
}

export interface RelatedCiTypePreviewList {
    cisAsSources?: RelatedCiTypePreview[]
    cisAsTargets?: RelatedCiTypePreview[]
    derivedCisAsSources?: DerivedCiTypePreview[]
    derivedCisAsTargets?: DerivedCiTypePreview[]
}

export interface CiCode {
    cicode?: string
    ciurl?: string
}

export interface RelationshipCode {
    code?: string
    refId?: string
}

export interface DerivedRelationshipType {
    technicalName?: string
    name?: string
    nameEn?: string
    description?: string
    descriptionEn?: string
    sourceCiTypeTn?: string
    targetCiTypeTn?: string
    relationshipTns?: string[]
    valid?: boolean
}

export interface RightsTypeHolder {
    ciType?: CiTypePreviewList
    role?: string
    attributeProfile?: AttributeProfilePreview
    relationshipsType?: RelationshipTypePreviewList
}

export interface CiTypesRelationshipTypesList {
    ciTypes?: CiTypePreview[]
    relationshipTypes?: RelationshipTypePreview[]
}

export interface TypesFilter {
    ciTypes?: string[]
    relTypes?: string[]
}

export type CiTypeRelationshipTypeMapCiTypeRoleEnum =
    (typeof CiTypeRelationshipTypeMapCiTypeRoleEnum)[keyof typeof CiTypeRelationshipTypeMapCiTypeRoleEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CiTypeRelationshipTypeMapCiTypeRoleEnum = {
    SOURCE: 'SOURCE',
    TARGET: 'TARGET',
} as const

export interface CiTypeRelationshipTypeMap {
    id?: number
    ciTypeId?: number
    relationshipTypeid?: number
    ciTypeRoleEnum?: CiTypeRelationshipTypeMapCiTypeRoleEnum
}

export interface CiTypeRelationshipTypesList {
    asSource?: RelationshipTypePreview[]
    asTarget?: RelationshipTypePreview[]
}

export type CiTypeType = (typeof CiTypeType)[keyof typeof CiTypeType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CiTypeType = {
    system: 'system',
    application: 'application',
} as const

export interface CiType {
    id?: number
    name?: string
    technicalName?: string
    description?: string
    roleList?: string[]
    type?: CiTypeType
    valid?: boolean
    color?: string
    engName?: string
    category?: string
    attributes?: Attribute[]
    attributeProfiles?: AttributeProfile[]
    codePrefix?: string
    ownerRelation?: string
    uriPrefix?: string
    labels?: string[]
    relationshipTypes?: CiTypeRelationshipTypesList
}

export interface SummarizingCardItemUi {
    attribute?: Attribute
    description?: string
    attrOrder?: number
}

export interface SummarizingCardUi {
    ciType?: string
    description?: string
    summarizingCardItems?: SummarizingCardItemUi[]
    showOwner?: boolean
}

export type RelationshipTypeType = (typeof RelationshipTypeType)[keyof typeof RelationshipTypeType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelationshipTypeType = {
    system: 'system',
    application: 'application',
} as const

export interface AttributeProfilePreview {
    attributeProfileList?: AttributeProfile[]
}

export interface AttributeProfileFilter {
    role?: string
}

export interface Mandatory {
    type?: string
    processIds?: string[]
    processId?: string[]
}

export type CiTypePreviewType = (typeof CiTypePreviewType)[keyof typeof CiTypePreviewType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CiTypePreviewType = {
    system: 'system',
    application: 'application',
} as const

export type AttributeProfileType = (typeof AttributeProfileType)[keyof typeof AttributeProfileType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AttributeProfileType = {
    system: 'system',
    application: 'application',
} as const

export interface AttributeProfile {
    id?: number
    name?: string
    description?: string
    technicalName?: string
    roleList?: string[]
    type?: AttributeProfileType
    valid?: boolean
    isGeneric?: boolean
    engDescription?: string
    attributes?: Attribute[]
}

export interface CiTypePreview {
    id?: number
    name?: string
    technicalName?: string
    description?: string
    roleList?: string[]
    type?: CiTypePreviewType
    valid?: boolean
    color?: string
    engName?: string
    category?: string
    attributes?: Attribute[]
    attributeProfiles?: AttributeProfile[]
    codePrefix?: string
    ownerRelation?: string
    uriPrefix?: string
    roles?: string
    labels?: string[]
}

export interface RelationshipType {
    id?: number
    name?: string
    technicalName?: string
    description?: string
    roleList?: string[]
    type?: RelationshipTypeType
    valid?: boolean
    color?: string
    engName?: string
    category?: string
    attributes?: Attribute[]
    attributeProfiles?: AttributeProfile[]
    sourceCardinality?: Cardinality
    targetCardinality?: Cardinality
    sources?: CiTypePreview[]
    targets?: CiTypePreview[]
    engDescription?: string
    codePrefix?: string
    uriPrefix?: string
}

export interface DerivedCiTypePreview {
    name?: string
    engName?: string
    technicalName?: string
    description?: string
    descriptionEn?: string
    ciType?: CiTypePreview
    relations?: RelationshipType[]
}

export interface CiTypePreviewList {
    results?: CiTypePreview[]
}

export type AttributeConstraintRegexAllOf = {
    regex?: string
}

export type AttributeConstraintRegex = AttributeConstraint & AttributeConstraintRegexAllOf

export type AttributeConstraintIntervalAllOf = {
    minValue?: number
    maxValue?: number
}

export type AttributeConstraintEnumAllOf = {
    enumCode?: string
}

export type AttributeConstraintEnum = AttributeConstraint & AttributeConstraintEnumAllOf

export type AttributeConstraintCiTypeAllOf = {
    ciType?: string
}

export interface AttributeConstraint {
    id?: number
    type?: string
}

export type AttributeConstraintInterval = AttributeConstraint & AttributeConstraintIntervalAllOf

export type AttributeConstraintCiType = AttributeConstraint & AttributeConstraintCiTypeAllOf

export type AttributeAttributeTypeEnum = (typeof AttributeAttributeTypeEnum)[keyof typeof AttributeAttributeTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AttributeAttributeTypeEnum = {
    BOOLEAN: 'BOOLEAN',
    BYTE: 'BYTE',
    SHORT: 'SHORT',
    INTEGER: 'INTEGER',
    LONG: 'LONG',
    FLOAT: 'FLOAT',
    DOUBLE: 'DOUBLE',
    CHARACTER: 'CHARACTER',
    STRING: 'STRING',
    DATE: 'DATE',
    STRING_PAIR: 'STRING_PAIR',
    IMAGE: 'IMAGE',
    HTML: 'HTML',
    DATETIME: 'DATETIME',
    URL: 'URL',
    EMAIL: 'EMAIL',
    PHONE: 'PHONE',
} as const

export type AttributeConstraintsItem = AttributeConstraintCiType | AttributeConstraintEnum | AttributeConstraintInterval | AttributeConstraintRegex

export interface Attribute {
    id?: number
    name?: string
    description?: string
    type?: string
    displayAs?: string
    units?: string
    defaultValue?: string
    technicalName?: string
    order?: number
    valid?: boolean
    readOnly?: boolean
    mandatory?: Mandatory
    invisible?: boolean
    constraints?: AttributeConstraintsItem[]
    attributeTypeEnum?: AttributeAttributeTypeEnum
    engName?: string
    engDescription?: string
    opendata?: boolean
    array?: boolean
    isArray?: boolean
}

export interface CiTypeFilter {
    role?: string
    roles?: string[]
    labels?: string[]
}

export interface RelationshipTypePreviewList {
    results?: RelationshipTypePreview[]
}

export type RelationshipTypePreviewType = (typeof RelationshipTypePreviewType)[keyof typeof RelationshipTypePreviewType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelationshipTypePreviewType = {
    system: 'system',
    application: 'application',
} as const

export interface Cardinality {
    min?: number
    max?: number
}

export interface RelationshipTypePreview {
    id?: number
    name?: string
    engName?: string
    technicalName?: string
    description?: string
    type?: RelationshipTypePreviewType
    valid?: boolean
    roleList?: string[]
    engDescription?: string
    category?: string
    cardinalitySource?: Cardinality
    cardinalityTarget?: Cardinality
    codePrefix?: string
    uriPrefix?: string
}

export interface RelationshipTypeFilter {
    role?: string
}

export type ApiErrorData = { [key: string]: any }

export interface ApiError {
    type?: string
    message?: string
    data?: ApiErrorData
    logToken?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

/**
 * @summary listRelationshipTypes
 */
export const useListRelationshipTypesHook = () => {
    const listRelationshipTypes = useTypesRepoSwaggerClient<RelationshipTypePreviewList>()

    return (params: ListRelationshipTypesParams, signal?: AbortSignal) => {
        return listRelationshipTypes({ url: `/relationshiptypes/list`, method: 'get', params, signal })
    }
}

export const getListRelationshipTypesQueryKey = (params: ListRelationshipTypesParams) =>
    [`/relationshiptypes/list`, ...(params ? [params] : [])] as const

export const useListRelationshipTypesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListRelationshipTypesHook>>>, TError = ApiError>(
    params: ListRelationshipTypesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypesHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListRelationshipTypesQueryKey(params)

    const listRelationshipTypes = useListRelationshipTypesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypesHook>>>> = ({ signal }) =>
        listRelationshipTypes(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type ListRelationshipTypesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypesHook>>>>
export type ListRelationshipTypesQueryError = ApiError

/**
 * @summary listRelationshipTypes
 */
export const useListRelationshipTypes = <TData = Awaited<ReturnType<ReturnType<typeof useListRelationshipTypesHook>>>, TError = ApiError>(
    params: ListRelationshipTypesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListRelationshipTypesQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary listRelationshipTypes
 */
export const useListRelationshipTypes1Hook = () => {
    const listRelationshipTypes1 = useTypesRepoSwaggerClient<RelationshipTypePreviewList>()

    return (relationshipTypeFilter: RelationshipTypeFilter) => {
        return listRelationshipTypes1({
            url: `/relationshiptypes/list`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: relationshipTypeFilter,
        })
    }
}

export const useListRelationshipTypes1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes1Hook>>>,
        TError,
        { data: RelationshipTypeFilter },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes1Hook>>>, TError, { data: RelationshipTypeFilter }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const listRelationshipTypes1 = useListRelationshipTypes1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes1Hook>>>, { data: RelationshipTypeFilter }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return listRelationshipTypes1(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ListRelationshipTypes1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes1Hook>>>>
export type ListRelationshipTypes1MutationBody = RelationshipTypeFilter
export type ListRelationshipTypes1MutationError = ApiError

/**
 * @summary listRelationshipTypes
 */
export const useListRelationshipTypes1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes1Hook>>>,
        TError,
        { data: RelationshipTypeFilter },
        TContext
    >
}) => {
    const mutationOptions = useListRelationshipTypes1MutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary listCiTypes
 */
export const useListCiTypesHook = () => {
    const listCiTypes = useTypesRepoSwaggerClient<CiTypePreviewList>()

    return (params: ListCiTypesParams, signal?: AbortSignal) => {
        return listCiTypes({ url: `/citypes/list`, method: 'get', params, signal })
    }
}

export const getListCiTypesQueryKey = (params: ListCiTypesParams) => [`/citypes/list`, ...(params ? [params] : [])] as const

export const useListCiTypesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListCiTypesHook>>>, TError = ApiError>(
    params: ListCiTypesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListCiTypesHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListCiTypesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListCiTypesQueryKey(params)

    const listCiTypes = useListCiTypesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListCiTypesHook>>>> = ({ signal }) => listCiTypes(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type ListCiTypesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListCiTypesHook>>>>
export type ListCiTypesQueryError = ApiError

/**
 * @summary listCiTypes
 */
export const useListCiTypes = <TData = Awaited<ReturnType<ReturnType<typeof useListCiTypesHook>>>, TError = ApiError>(
    params: ListCiTypesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListCiTypesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListCiTypesQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary listCiTypes
 */
export const useListCiTypes1Hook = () => {
    const listCiTypes1 = useTypesRepoSwaggerClient<CiTypePreviewList>()

    return (ciTypeFilter: CiTypeFilter) => {
        return listCiTypes1({ url: `/citypes/list`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: ciTypeFilter })
    }
}

export const useListCiTypes1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useListCiTypes1Hook>>>, TError, { data: CiTypeFilter }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useListCiTypes1Hook>>>, TError, { data: CiTypeFilter }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const listCiTypes1 = useListCiTypes1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useListCiTypes1Hook>>>, { data: CiTypeFilter }> = (props) => {
        const { data } = props ?? {}

        return listCiTypes1(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ListCiTypes1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListCiTypes1Hook>>>>
export type ListCiTypes1MutationBody = CiTypeFilter
export type ListCiTypes1MutationError = ApiError

/**
 * @summary listCiTypes
 */
export const useListCiTypes1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useListCiTypes1Hook>>>, TError, { data: CiTypeFilter }, TContext>
}) => {
    const mutationOptions = useListCiTypes1MutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary listAttrProfile
 */
export const useListAttrProfileHook = () => {
    const listAttrProfile = useTypesRepoSwaggerClient<AttributeProfilePreview>()

    return (params: ListAttrProfileParams, signal?: AbortSignal) => {
        return listAttrProfile({ url: `/attrprofiles/list`, method: 'get', params, signal })
    }
}

export const getListAttrProfileQueryKey = (params: ListAttrProfileParams) => [`/attrprofiles/list`, ...(params ? [params] : [])] as const

export const useListAttrProfileQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListAttrProfileHook>>>, TError = ApiError>(
    params: ListAttrProfileParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListAttrProfileHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListAttrProfileHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListAttrProfileQueryKey(params)

    const listAttrProfile = useListAttrProfileHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListAttrProfileHook>>>> = ({ signal }) => listAttrProfile(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type ListAttrProfileQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListAttrProfileHook>>>>
export type ListAttrProfileQueryError = ApiError

/**
 * @summary listAttrProfile
 */
export const useListAttrProfile = <TData = Awaited<ReturnType<ReturnType<typeof useListAttrProfileHook>>>, TError = ApiError>(
    params: ListAttrProfileParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListAttrProfileHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListAttrProfileQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary listAttrProfile
 */
export const useListAttrProfile1Hook = () => {
    const listAttrProfile1 = useTypesRepoSwaggerClient<AttributeProfilePreview>()

    return (attributeProfileFilter: AttributeProfileFilter) => {
        return listAttrProfile1({
            url: `/attrprofiles/list`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: attributeProfileFilter,
        })
    }
}

export const getListAttrProfile1QueryKey = (attributeProfileFilter: AttributeProfileFilter) => [`/attrprofiles/list`, attributeProfileFilter] as const

export const useListAttrProfile1QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListAttrProfile1Hook>>>, TError = ApiError>(
    attributeProfileFilter: AttributeProfileFilter,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListAttrProfile1Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListAttrProfile1Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListAttrProfile1QueryKey(attributeProfileFilter)

    const listAttrProfile1 = useListAttrProfile1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListAttrProfile1Hook>>>> = () => listAttrProfile1(attributeProfileFilter)

    return { queryKey, queryFn, ...queryOptions }
}

export type ListAttrProfile1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListAttrProfile1Hook>>>>
export type ListAttrProfile1QueryError = ApiError

/**
 * @summary listAttrProfile
 */
export const useListAttrProfile1 = <TData = Awaited<ReturnType<ReturnType<typeof useListAttrProfile1Hook>>>, TError = ApiError>(
    attributeProfileFilter: AttributeProfileFilter,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListAttrProfile1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListAttrProfile1QueryOptions(attributeProfileFilter, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useStoreAttributeTextationHook = () => {
    const storeAttributeTextation = useTypesRepoSwaggerClient<void>()

    return (technicalName: string, attTecName: string, attribute: Attribute) => {
        return storeAttributeTextation({
            url: `/relationshiptypes/relationshiptype/${technicalName}/attribute/${attTecName}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: attribute,
        })
    }
}

export const useStoreAttributeTextationMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreAttributeTextationHook>>>,
        TError,
        { technicalName: string; attTecName: string; data: Attribute },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreAttributeTextationHook>>>,
    TError,
    { technicalName: string; attTecName: string; data: Attribute },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeAttributeTextation = useStoreAttributeTextationHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStoreAttributeTextationHook>>>,
        { technicalName: string; attTecName: string; data: Attribute }
    > = (props) => {
        const { technicalName, attTecName, data } = props ?? {}

        return storeAttributeTextation(technicalName, attTecName, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreAttributeTextationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreAttributeTextationHook>>>>
export type StoreAttributeTextationMutationBody = Attribute
export type StoreAttributeTextationMutationError = ApiError

export const useStoreAttributeTextation = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreAttributeTextationHook>>>,
        TError,
        { technicalName: string; attTecName: string; data: Attribute },
        TContext
    >
}) => {
    const mutationOptions = useStoreAttributeTextationMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteAttributeTextationHook = () => {
    const deleteAttributeTextation = useTypesRepoSwaggerClient<void>()

    return (technicalName: string, attTecName: string) => {
        return deleteAttributeTextation({ url: `/relationshiptypes/relationshiptype/${technicalName}/attribute/${attTecName}`, method: 'delete' })
    }
}

export const useDeleteAttributeTextationMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteAttributeTextationHook>>>,
        TError,
        { technicalName: string; attTecName: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteAttributeTextationHook>>>,
    TError,
    { technicalName: string; attTecName: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteAttributeTextation = useDeleteAttributeTextationHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useDeleteAttributeTextationHook>>>,
        { technicalName: string; attTecName: string }
    > = (props) => {
        const { technicalName, attTecName } = props ?? {}

        return deleteAttributeTextation(technicalName, attTecName)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteAttributeTextationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteAttributeTextationHook>>>>

export type DeleteAttributeTextationMutationError = ApiError

export const useDeleteAttributeTextation = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteAttributeTextationHook>>>,
        TError,
        { technicalName: string; attTecName: string },
        TContext
    >
}) => {
    const mutationOptions = useDeleteAttributeTextationMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useValidRelationshipTypeHook = () => {
    const validRelationshipType = useTypesRepoSwaggerClient<void>()

    return (technicalName: string) => {
        return validRelationshipType({ url: `/relationshiptypes/relationshiptype/valid/${technicalName}`, method: 'put' })
    }
}

export const useValidRelationshipTypeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useValidRelationshipTypeHook>>>, TError, { technicalName: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useValidRelationshipTypeHook>>>, TError, { technicalName: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const validRelationshipType = useValidRelationshipTypeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useValidRelationshipTypeHook>>>, { technicalName: string }> = (props) => {
        const { technicalName } = props ?? {}

        return validRelationshipType(technicalName)
    }

    return { mutationFn, ...mutationOptions }
}

export type ValidRelationshipTypeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useValidRelationshipTypeHook>>>>

export type ValidRelationshipTypeMutationError = ApiError

export const useValidRelationshipType = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useValidRelationshipTypeHook>>>, TError, { technicalName: string }, TContext>
}) => {
    const mutationOptions = useValidRelationshipTypeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUnvalidRelationshipTypeHook = () => {
    const unvalidRelationshipType = useTypesRepoSwaggerClient<void>()

    return (technicalName: string) => {
        return unvalidRelationshipType({ url: `/relationshiptypes/relationshiptype/valid/${technicalName}`, method: 'delete' })
    }
}

export const useUnvalidRelationshipTypeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUnvalidRelationshipTypeHook>>>, TError, { technicalName: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUnvalidRelationshipTypeHook>>>, TError, { technicalName: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const unvalidRelationshipType = useUnvalidRelationshipTypeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUnvalidRelationshipTypeHook>>>, { technicalName: string }> = (
        props,
    ) => {
        const { technicalName } = props ?? {}

        return unvalidRelationshipType(technicalName)
    }

    return { mutationFn, ...mutationOptions }
}

export type UnvalidRelationshipTypeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUnvalidRelationshipTypeHook>>>>

export type UnvalidRelationshipTypeMutationError = ApiError

export const useUnvalidRelationshipType = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUnvalidRelationshipTypeHook>>>, TError, { technicalName: string }, TContext>
}) => {
    const mutationOptions = useUnvalidRelationshipTypeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreExistRelationshipTypeHook = () => {
    const storeExistRelationshipType = useTypesRepoSwaggerClient<RelationshipType>()

    return (relationshipType: RelationshipType) => {
        return storeExistRelationshipType({
            url: `/relationshiptypes/relationshiptype/store`,
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: relationshipType,
        })
    }
}

export const useStoreExistRelationshipTypeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreExistRelationshipTypeHook>>>,
        TError,
        { data: RelationshipType },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreExistRelationshipTypeHook>>>, TError, { data: RelationshipType }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeExistRelationshipType = useStoreExistRelationshipTypeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreExistRelationshipTypeHook>>>, { data: RelationshipType }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return storeExistRelationshipType(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreExistRelationshipTypeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreExistRelationshipTypeHook>>>>
export type StoreExistRelationshipTypeMutationBody = RelationshipType
export type StoreExistRelationshipTypeMutationError = ApiError

export const useStoreExistRelationshipType = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreExistRelationshipTypeHook>>>,
        TError,
        { data: RelationshipType },
        TContext
    >
}) => {
    const mutationOptions = useStoreExistRelationshipTypeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreNewRelationshipTypeHook = () => {
    const storeNewRelationshipType = useTypesRepoSwaggerClient<RelationshipType>()

    return (relationshipType: RelationshipType) => {
        return storeNewRelationshipType({
            url: `/relationshiptypes/relationshiptype/store`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: relationshipType,
        })
    }
}

export const useStoreNewRelationshipTypeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreNewRelationshipTypeHook>>>,
        TError,
        { data: RelationshipType },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreNewRelationshipTypeHook>>>, TError, { data: RelationshipType }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeNewRelationshipType = useStoreNewRelationshipTypeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreNewRelationshipTypeHook>>>, { data: RelationshipType }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return storeNewRelationshipType(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreNewRelationshipTypeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreNewRelationshipTypeHook>>>>
export type StoreNewRelationshipTypeMutationBody = RelationshipType
export type StoreNewRelationshipTypeMutationError = ApiError

export const useStoreNewRelationshipType = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreNewRelationshipTypeHook>>>,
        TError,
        { data: RelationshipType },
        TContext
    >
}) => {
    const mutationOptions = useStoreNewRelationshipTypeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetSummarizingCardHook = () => {
    const getSummarizingCard = useTypesRepoSwaggerClient<SummarizingCardUi>()

    return (technicalName: string, signal?: AbortSignal) => {
        return getSummarizingCard({ url: `/citypes/citype/${technicalName}/summarizingcard`, method: 'get', signal })
    }
}

export const getGetSummarizingCardQueryKey = (technicalName: string) => [`/citypes/citype/${technicalName}/summarizingcard`] as const

export const useGetSummarizingCardQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetSummarizingCardHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSummarizingCardHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSummarizingCardHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetSummarizingCardQueryKey(technicalName)

    const getSummarizingCard = useGetSummarizingCardHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSummarizingCardHook>>>> = ({ signal }) =>
        getSummarizingCard(technicalName, signal)

    return { queryKey, queryFn, enabled: !!technicalName, ...queryOptions }
}

export type GetSummarizingCardQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetSummarizingCardHook>>>>
export type GetSummarizingCardQueryError = ApiError

export const useGetSummarizingCard = <TData = Awaited<ReturnType<ReturnType<typeof useGetSummarizingCardHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSummarizingCardHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetSummarizingCardQueryOptions(technicalName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useSetSummarizingCardHook = () => {
    const setSummarizingCard = useTypesRepoSwaggerClient<void>()

    return (technicalName: string, summarizingCardUi: SummarizingCardUi) => {
        return setSummarizingCard({
            url: `/citypes/citype/${technicalName}/summarizingcard`,
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: summarizingCardUi,
        })
    }
}

export const useSetSummarizingCardMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSetSummarizingCardHook>>>,
        TError,
        { technicalName: string; data: SummarizingCardUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetSummarizingCardHook>>>,
    TError,
    { technicalName: string; data: SummarizingCardUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const setSummarizingCard = useSetSummarizingCardHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useSetSummarizingCardHook>>>,
        { technicalName: string; data: SummarizingCardUi }
    > = (props) => {
        const { technicalName, data } = props ?? {}

        return setSummarizingCard(technicalName, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type SetSummarizingCardMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSetSummarizingCardHook>>>>
export type SetSummarizingCardMutationBody = SummarizingCardUi
export type SetSummarizingCardMutationError = ApiError

export const useSetSummarizingCard = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSetSummarizingCardHook>>>,
        TError,
        { technicalName: string; data: SummarizingCardUi },
        TContext
    >
}) => {
    const mutationOptions = useSetSummarizingCardMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteSummarizingCardHook = () => {
    const deleteSummarizingCard = useTypesRepoSwaggerClient<void>()

    return (technicalName: string) => {
        return deleteSummarizingCard({ url: `/citypes/citype/${technicalName}/summarizingcard`, method: 'delete' })
    }
}

export const useDeleteSummarizingCardMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteSummarizingCardHook>>>, TError, { technicalName: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteSummarizingCardHook>>>, TError, { technicalName: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteSummarizingCard = useDeleteSummarizingCardHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteSummarizingCardHook>>>, { technicalName: string }> = (props) => {
        const { technicalName } = props ?? {}

        return deleteSummarizingCard(technicalName)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteSummarizingCardMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteSummarizingCardHook>>>>

export type DeleteSummarizingCardMutationError = ApiError

export const useDeleteSummarizingCard = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteSummarizingCardHook>>>, TError, { technicalName: string }, TContext>
}) => {
    const mutationOptions = useDeleteSummarizingCardMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreAttributeTextation1Hook = () => {
    const storeAttributeTextation1 = useTypesRepoSwaggerClient<void>()

    return (technicalName: string, attTecName: string, attribute: Attribute) => {
        return storeAttributeTextation1({
            url: `/citypes/citype/${technicalName}/attribute/${attTecName}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: attribute,
        })
    }
}

export const useStoreAttributeTextation1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreAttributeTextation1Hook>>>,
        TError,
        { technicalName: string; attTecName: string; data: Attribute },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreAttributeTextation1Hook>>>,
    TError,
    { technicalName: string; attTecName: string; data: Attribute },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeAttributeTextation1 = useStoreAttributeTextation1Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStoreAttributeTextation1Hook>>>,
        { technicalName: string; attTecName: string; data: Attribute }
    > = (props) => {
        const { technicalName, attTecName, data } = props ?? {}

        return storeAttributeTextation1(technicalName, attTecName, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreAttributeTextation1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreAttributeTextation1Hook>>>>
export type StoreAttributeTextation1MutationBody = Attribute
export type StoreAttributeTextation1MutationError = ApiError

export const useStoreAttributeTextation1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreAttributeTextation1Hook>>>,
        TError,
        { technicalName: string; attTecName: string; data: Attribute },
        TContext
    >
}) => {
    const mutationOptions = useStoreAttributeTextation1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteAttributeTextation1Hook = () => {
    const deleteAttributeTextation1 = useTypesRepoSwaggerClient<void>()

    return (technicalName: string, attTecName: string) => {
        return deleteAttributeTextation1({ url: `/citypes/citype/${technicalName}/attribute/${attTecName}`, method: 'delete' })
    }
}

export const useDeleteAttributeTextation1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteAttributeTextation1Hook>>>,
        TError,
        { technicalName: string; attTecName: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteAttributeTextation1Hook>>>,
    TError,
    { technicalName: string; attTecName: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteAttributeTextation1 = useDeleteAttributeTextation1Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useDeleteAttributeTextation1Hook>>>,
        { technicalName: string; attTecName: string }
    > = (props) => {
        const { technicalName, attTecName } = props ?? {}

        return deleteAttributeTextation1(technicalName, attTecName)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteAttributeTextation1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteAttributeTextation1Hook>>>>

export type DeleteAttributeTextation1MutationError = ApiError

export const useDeleteAttributeTextation1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteAttributeTextation1Hook>>>,
        TError,
        { technicalName: string; attTecName: string },
        TContext
    >
}) => {
    const mutationOptions = useDeleteAttributeTextation1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreValidHook = () => {
    const storeValid = useTypesRepoSwaggerClient<void>()

    return (technicalName: string) => {
        return storeValid({ url: `/citypes/citype/valid/${technicalName}`, method: 'put' })
    }
}

export const useStoreValidMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreValidHook>>>, TError, { technicalName: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreValidHook>>>, TError, { technicalName: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeValid = useStoreValidHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreValidHook>>>, { technicalName: string }> = (props) => {
        const { technicalName } = props ?? {}

        return storeValid(technicalName)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreValidMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreValidHook>>>>

export type StoreValidMutationError = ApiError

export const useStoreValid = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreValidHook>>>, TError, { technicalName: string }, TContext>
}) => {
    const mutationOptions = useStoreValidMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreUnvalidHook = () => {
    const storeUnvalid = useTypesRepoSwaggerClient<void>()

    return (technicalName: string) => {
        return storeUnvalid({ url: `/citypes/citype/valid/${technicalName}`, method: 'delete' })
    }
}

export const useStoreUnvalidMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreUnvalidHook>>>, TError, { technicalName: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreUnvalidHook>>>, TError, { technicalName: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeUnvalid = useStoreUnvalidHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreUnvalidHook>>>, { technicalName: string }> = (props) => {
        const { technicalName } = props ?? {}

        return storeUnvalid(technicalName)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreUnvalidMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreUnvalidHook>>>>

export type StoreUnvalidMutationError = ApiError

export const useStoreUnvalid = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreUnvalidHook>>>, TError, { technicalName: string }, TContext>
}) => {
    const mutationOptions = useStoreUnvalidMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreExistCiTypeHook = () => {
    const storeExistCiType = useTypesRepoSwaggerClient<void>()

    return (ciType: CiType) => {
        return storeExistCiType({ url: `/citypes/citype/store`, method: 'put', headers: { 'Content-Type': 'application/json' }, data: ciType })
    }
}

export const useStoreExistCiTypeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreExistCiTypeHook>>>, TError, { data: CiType }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreExistCiTypeHook>>>, TError, { data: CiType }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeExistCiType = useStoreExistCiTypeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreExistCiTypeHook>>>, { data: CiType }> = (props) => {
        const { data } = props ?? {}

        return storeExistCiType(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreExistCiTypeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreExistCiTypeHook>>>>
export type StoreExistCiTypeMutationBody = CiType
export type StoreExistCiTypeMutationError = ApiError

export const useStoreExistCiType = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreExistCiTypeHook>>>, TError, { data: CiType }, TContext>
}) => {
    const mutationOptions = useStoreExistCiTypeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreNewCiTypeHook = () => {
    const storeNewCiType = useTypesRepoSwaggerClient<void>()

    return (ciType: CiType) => {
        return storeNewCiType({ url: `/citypes/citype/store`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: ciType })
    }
}

export const useStoreNewCiTypeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreNewCiTypeHook>>>, TError, { data: CiType }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreNewCiTypeHook>>>, TError, { data: CiType }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeNewCiType = useStoreNewCiTypeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreNewCiTypeHook>>>, { data: CiType }> = (props) => {
        const { data } = props ?? {}

        return storeNewCiType(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreNewCiTypeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreNewCiTypeHook>>>>
export type StoreNewCiTypeMutationBody = CiType
export type StoreNewCiTypeMutationError = ApiError

export const useStoreNewCiType = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreNewCiTypeHook>>>, TError, { data: CiType }, TContext>
}) => {
    const mutationOptions = useStoreNewCiTypeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreExistsCiTypeRelationshipTypeMapHook = () => {
    const storeExistsCiTypeRelationshipTypeMap = useTypesRepoSwaggerClient<void>()

    return (ciTypeRelationshipTypeMap: CiTypeRelationshipTypeMap) => {
        return storeExistsCiTypeRelationshipTypeMap({
            url: `/cityperelationshiptypemaps/cityperelationshiptypemap/store`,
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: ciTypeRelationshipTypeMap,
        })
    }
}

export const useStoreExistsCiTypeRelationshipTypeMapMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreExistsCiTypeRelationshipTypeMapHook>>>,
        TError,
        { data: CiTypeRelationshipTypeMap },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreExistsCiTypeRelationshipTypeMapHook>>>,
    TError,
    { data: CiTypeRelationshipTypeMap },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeExistsCiTypeRelationshipTypeMap = useStoreExistsCiTypeRelationshipTypeMapHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStoreExistsCiTypeRelationshipTypeMapHook>>>,
        { data: CiTypeRelationshipTypeMap }
    > = (props) => {
        const { data } = props ?? {}

        return storeExistsCiTypeRelationshipTypeMap(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreExistsCiTypeRelationshipTypeMapMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useStoreExistsCiTypeRelationshipTypeMapHook>>>
>
export type StoreExistsCiTypeRelationshipTypeMapMutationBody = CiTypeRelationshipTypeMap
export type StoreExistsCiTypeRelationshipTypeMapMutationError = ApiError

export const useStoreExistsCiTypeRelationshipTypeMap = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreExistsCiTypeRelationshipTypeMapHook>>>,
        TError,
        { data: CiTypeRelationshipTypeMap },
        TContext
    >
}) => {
    const mutationOptions = useStoreExistsCiTypeRelationshipTypeMapMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreNewCiTypeRelationshipTypeMapHook = () => {
    const storeNewCiTypeRelationshipTypeMap = useTypesRepoSwaggerClient<void>()

    return (ciTypeRelationshipTypeMap: CiTypeRelationshipTypeMap) => {
        return storeNewCiTypeRelationshipTypeMap({
            url: `/cityperelationshiptypemaps/cityperelationshiptypemap/store`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: ciTypeRelationshipTypeMap,
        })
    }
}

export const useStoreNewCiTypeRelationshipTypeMapMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreNewCiTypeRelationshipTypeMapHook>>>,
        TError,
        { data: CiTypeRelationshipTypeMap },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreNewCiTypeRelationshipTypeMapHook>>>,
    TError,
    { data: CiTypeRelationshipTypeMap },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeNewCiTypeRelationshipTypeMap = useStoreNewCiTypeRelationshipTypeMapHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStoreNewCiTypeRelationshipTypeMapHook>>>,
        { data: CiTypeRelationshipTypeMap }
    > = (props) => {
        const { data } = props ?? {}

        return storeNewCiTypeRelationshipTypeMap(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreNewCiTypeRelationshipTypeMapMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useStoreNewCiTypeRelationshipTypeMapHook>>>
>
export type StoreNewCiTypeRelationshipTypeMapMutationBody = CiTypeRelationshipTypeMap
export type StoreNewCiTypeRelationshipTypeMapMutationError = ApiError

export const useStoreNewCiTypeRelationshipTypeMap = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreNewCiTypeRelationshipTypeMapHook>>>,
        TError,
        { data: CiTypeRelationshipTypeMap },
        TContext
    >
}) => {
    const mutationOptions = useStoreNewCiTypeRelationshipTypeMapMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreValid1Hook = () => {
    const storeValid1 = useTypesRepoSwaggerClient<void>()

    return (technicalName: string) => {
        return storeValid1({ url: `/attrprofiles/attrprofile/valid/${technicalName}`, method: 'put' })
    }
}

export const useStoreValid1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreValid1Hook>>>, TError, { technicalName: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreValid1Hook>>>, TError, { technicalName: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeValid1 = useStoreValid1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreValid1Hook>>>, { technicalName: string }> = (props) => {
        const { technicalName } = props ?? {}

        return storeValid1(technicalName)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreValid1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreValid1Hook>>>>

export type StoreValid1MutationError = ApiError

export const useStoreValid1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreValid1Hook>>>, TError, { technicalName: string }, TContext>
}) => {
    const mutationOptions = useStoreValid1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreUnValidHook = () => {
    const storeUnValid = useTypesRepoSwaggerClient<void>()

    return (technicalName: string) => {
        return storeUnValid({ url: `/attrprofiles/attrprofile/valid/${technicalName}`, method: 'delete' })
    }
}

export const useStoreUnValidMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreUnValidHook>>>, TError, { technicalName: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreUnValidHook>>>, TError, { technicalName: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeUnValid = useStoreUnValidHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreUnValidHook>>>, { technicalName: string }> = (props) => {
        const { technicalName } = props ?? {}

        return storeUnValid(technicalName)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreUnValidMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreUnValidHook>>>>

export type StoreUnValidMutationError = ApiError

export const useStoreUnValid = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreUnValidHook>>>, TError, { technicalName: string }, TContext>
}) => {
    const mutationOptions = useStoreUnValidMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreExistAttrProfileHook = () => {
    const storeExistAttrProfile = useTypesRepoSwaggerClient<void>()

    return (attributeProfile: AttributeProfile) => {
        return storeExistAttrProfile({
            url: `/attrprofiles/attrprofile/store`,
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: attributeProfile,
        })
    }
}

export const useStoreExistAttrProfileMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreExistAttrProfileHook>>>, TError, { data: AttributeProfile }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreExistAttrProfileHook>>>, TError, { data: AttributeProfile }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeExistAttrProfile = useStoreExistAttrProfileHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreExistAttrProfileHook>>>, { data: AttributeProfile }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return storeExistAttrProfile(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreExistAttrProfileMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreExistAttrProfileHook>>>>
export type StoreExistAttrProfileMutationBody = AttributeProfile
export type StoreExistAttrProfileMutationError = ApiError

export const useStoreExistAttrProfile = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreExistAttrProfileHook>>>, TError, { data: AttributeProfile }, TContext>
}) => {
    const mutationOptions = useStoreExistAttrProfileMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreNewAttrProfileHook = () => {
    const storeNewAttrProfile = useTypesRepoSwaggerClient<void>()

    return (attributeProfile: AttributeProfile) => {
        return storeNewAttrProfile({
            url: `/attrprofiles/attrprofile/store`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: attributeProfile,
        })
    }
}

export const useStoreNewAttrProfileMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreNewAttrProfileHook>>>, TError, { data: AttributeProfile }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreNewAttrProfileHook>>>, TError, { data: AttributeProfile }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeNewAttrProfile = useStoreNewAttrProfileHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreNewAttrProfileHook>>>, { data: AttributeProfile }> = (props) => {
        const { data } = props ?? {}

        return storeNewAttrProfile(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreNewAttrProfileMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreNewAttrProfileHook>>>>
export type StoreNewAttrProfileMutationBody = AttributeProfile
export type StoreNewAttrProfileMutationError = ApiError

export const useStoreNewAttrProfile = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreNewAttrProfileHook>>>, TError, { data: AttributeProfile }, TContext>
}) => {
    const mutationOptions = useStoreNewAttrProfileMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreExistGenericAttrProfileHook = () => {
    const storeExistGenericAttrProfile = useTypesRepoSwaggerClient<void>()

    return (attributeProfile: AttributeProfile) => {
        return storeExistGenericAttrProfile({
            url: `/attrprofiles/attrprofile/store/generic`,
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: attributeProfile,
        })
    }
}

export const useStoreExistGenericAttrProfileMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreExistGenericAttrProfileHook>>>,
        TError,
        { data: AttributeProfile },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreExistGenericAttrProfileHook>>>, TError, { data: AttributeProfile }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeExistGenericAttrProfile = useStoreExistGenericAttrProfileHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreExistGenericAttrProfileHook>>>, { data: AttributeProfile }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return storeExistGenericAttrProfile(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreExistGenericAttrProfileMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreExistGenericAttrProfileHook>>>>
export type StoreExistGenericAttrProfileMutationBody = AttributeProfile
export type StoreExistGenericAttrProfileMutationError = ApiError

export const useStoreExistGenericAttrProfile = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreExistGenericAttrProfileHook>>>,
        TError,
        { data: AttributeProfile },
        TContext
    >
}) => {
    const mutationOptions = useStoreExistGenericAttrProfileMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreValid2Hook = () => {
    const storeValid2 = useTypesRepoSwaggerClient<void>()

    return (attrProfileTechnicalName: string, technicalName: string) => {
        return storeValid2({ url: `/attributes/attribute/valid/${attrProfileTechnicalName}/${technicalName}`, method: 'put' })
    }
}

export const useStoreValid2MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreValid2Hook>>>,
        TError,
        { attrProfileTechnicalName: string; technicalName: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreValid2Hook>>>,
    TError,
    { attrProfileTechnicalName: string; technicalName: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeValid2 = useStoreValid2Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStoreValid2Hook>>>,
        { attrProfileTechnicalName: string; technicalName: string }
    > = (props) => {
        const { attrProfileTechnicalName, technicalName } = props ?? {}

        return storeValid2(attrProfileTechnicalName, technicalName)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreValid2MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreValid2Hook>>>>

export type StoreValid2MutationError = ApiError

export const useStoreValid2 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreValid2Hook>>>,
        TError,
        { attrProfileTechnicalName: string; technicalName: string },
        TContext
    >
}) => {
    const mutationOptions = useStoreValid2MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreUnvalid1Hook = () => {
    const storeUnvalid1 = useTypesRepoSwaggerClient<void>()

    return (attrProfileTechnicalName: string, technicalName: string) => {
        return storeUnvalid1({ url: `/attributes/attribute/valid/${attrProfileTechnicalName}/${technicalName}`, method: 'delete' })
    }
}

export const useStoreUnvalid1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreUnvalid1Hook>>>,
        TError,
        { attrProfileTechnicalName: string; technicalName: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreUnvalid1Hook>>>,
    TError,
    { attrProfileTechnicalName: string; technicalName: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeUnvalid1 = useStoreUnvalid1Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStoreUnvalid1Hook>>>,
        { attrProfileTechnicalName: string; technicalName: string }
    > = (props) => {
        const { attrProfileTechnicalName, technicalName } = props ?? {}

        return storeUnvalid1(attrProfileTechnicalName, technicalName)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreUnvalid1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreUnvalid1Hook>>>>

export type StoreUnvalid1MutationError = ApiError

export const useStoreUnvalid1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreUnvalid1Hook>>>,
        TError,
        { attrProfileTechnicalName: string; technicalName: string },
        TContext
    >
}) => {
    const mutationOptions = useStoreUnvalid1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreExistAttributeHook = () => {
    const storeExistAttribute = useTypesRepoSwaggerClient<void>()

    return (attribute: Attribute) => {
        return storeExistAttribute({
            url: `/attributes/attribute/store`,
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: attribute,
        })
    }
}

export const useStoreExistAttributeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreExistAttributeHook>>>, TError, { data: Attribute }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreExistAttributeHook>>>, TError, { data: Attribute }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeExistAttribute = useStoreExistAttributeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreExistAttributeHook>>>, { data: Attribute }> = (props) => {
        const { data } = props ?? {}

        return storeExistAttribute(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreExistAttributeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreExistAttributeHook>>>>
export type StoreExistAttributeMutationBody = Attribute
export type StoreExistAttributeMutationError = ApiError

export const useStoreExistAttribute = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreExistAttributeHook>>>, TError, { data: Attribute }, TContext>
}) => {
    const mutationOptions = useStoreExistAttributeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreVisibleHook = () => {
    const storeVisible = useTypesRepoSwaggerClient<void>()

    return (attrProfileTechnicalName: string, technicalName: string) => {
        return storeVisible({ url: `/attributes/attribute/invisible/${attrProfileTechnicalName}/${technicalName}`, method: 'put' })
    }
}

export const useStoreVisibleMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreVisibleHook>>>,
        TError,
        { attrProfileTechnicalName: string; technicalName: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreVisibleHook>>>,
    TError,
    { attrProfileTechnicalName: string; technicalName: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeVisible = useStoreVisibleHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStoreVisibleHook>>>,
        { attrProfileTechnicalName: string; technicalName: string }
    > = (props) => {
        const { attrProfileTechnicalName, technicalName } = props ?? {}

        return storeVisible(attrProfileTechnicalName, technicalName)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreVisibleMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreVisibleHook>>>>

export type StoreVisibleMutationError = ApiError

export const useStoreVisible = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreVisibleHook>>>,
        TError,
        { attrProfileTechnicalName: string; technicalName: string },
        TContext
    >
}) => {
    const mutationOptions = useStoreVisibleMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreInvisibleHook = () => {
    const storeInvisible = useTypesRepoSwaggerClient<void>()

    return (attrProfileTechnicalName: string, technicalName: string) => {
        return storeInvisible({ url: `/attributes/attribute/invisible/${attrProfileTechnicalName}/${technicalName}`, method: 'delete' })
    }
}

export const useStoreInvisibleMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreInvisibleHook>>>,
        TError,
        { attrProfileTechnicalName: string; technicalName: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreInvisibleHook>>>,
    TError,
    { attrProfileTechnicalName: string; technicalName: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeInvisible = useStoreInvisibleHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStoreInvisibleHook>>>,
        { attrProfileTechnicalName: string; technicalName: string }
    > = (props) => {
        const { attrProfileTechnicalName, technicalName } = props ?? {}

        return storeInvisible(attrProfileTechnicalName, technicalName)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreInvisibleMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreInvisibleHook>>>>

export type StoreInvisibleMutationError = ApiError

export const useStoreInvisible = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreInvisibleHook>>>,
        TError,
        { attrProfileTechnicalName: string; technicalName: string },
        TContext
    >
}) => {
    const mutationOptions = useStoreInvisibleMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreAdminEntityHook = () => {
    const storeAdminEntity = useTypesRepoSwaggerClient<void>()

    return (relationshipType: RelationshipType) => {
        return storeAdminEntity({
            url: `/relationshiptypes/adminentity/store`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: relationshipType,
        })
    }
}

export const useStoreAdminEntityMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreAdminEntityHook>>>, TError, { data: RelationshipType }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreAdminEntityHook>>>, TError, { data: RelationshipType }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeAdminEntity = useStoreAdminEntityHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreAdminEntityHook>>>, { data: RelationshipType }> = (props) => {
        const { data } = props ?? {}

        return storeAdminEntity(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreAdminEntityMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreAdminEntityHook>>>>
export type StoreAdminEntityMutationBody = RelationshipType
export type StoreAdminEntityMutationError = ApiError

export const useStoreAdminEntity = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreAdminEntityHook>>>, TError, { data: RelationshipType }, TContext>
}) => {
    const mutationOptions = useStoreAdminEntityMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary listTypes
 */
export const useListTypesHook = () => {
    const listTypes = useTypesRepoSwaggerClient<CiTypesRelationshipTypesList>()

    return (typesFilter: TypesFilter) => {
        return listTypes({
            url: `/citypes/relationshiptypes/list`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: typesFilter,
        })
    }
}

export const useListTypesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useListTypesHook>>>, TError, { data: TypesFilter }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useListTypesHook>>>, TError, { data: TypesFilter }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const listTypes = useListTypesHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useListTypesHook>>>, { data: TypesFilter }> = (props) => {
        const { data } = props ?? {}

        return listTypes(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ListTypesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListTypesHook>>>>
export type ListTypesMutationBody = TypesFilter
export type ListTypesMutationError = ApiError

/**
 * @summary listTypes
 */
export const useListTypes = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useListTypesHook>>>, TError, { data: TypesFilter }, TContext>
}) => {
    const mutationOptions = useListTypesMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreAdminEntity1Hook = () => {
    const storeAdminEntity1 = useTypesRepoSwaggerClient<void>()

    return (ciType: CiType, params?: StoreAdminEntity1Params) => {
        return storeAdminEntity1({
            url: `/citypes/adminentity/store`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: ciType,
            params,
        })
    }
}

export const useStoreAdminEntity1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreAdminEntity1Hook>>>,
        TError,
        { data: CiType; params?: StoreAdminEntity1Params },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreAdminEntity1Hook>>>,
    TError,
    { data: CiType; params?: StoreAdminEntity1Params },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeAdminEntity1 = useStoreAdminEntity1Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStoreAdminEntity1Hook>>>,
        { data: CiType; params?: StoreAdminEntity1Params }
    > = (props) => {
        const { data, params } = props ?? {}

        return storeAdminEntity1(data, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreAdminEntity1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreAdminEntity1Hook>>>>
export type StoreAdminEntity1MutationBody = CiType
export type StoreAdminEntity1MutationError = ApiError

export const useStoreAdminEntity1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreAdminEntity1Hook>>>,
        TError,
        { data: CiType; params?: StoreAdminEntity1Params },
        TContext
    >
}) => {
    const mutationOptions = useStoreAdminEntity1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreNewAttributeHook = () => {
    const storeNewAttribute = useTypesRepoSwaggerClient<void>()

    return (atrProfTechnicalName: string, attribute: Attribute) => {
        return storeNewAttribute({
            url: `/attributes/attribute/store/${atrProfTechnicalName}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: attribute,
        })
    }
}

export const useStoreNewAttributeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreNewAttributeHook>>>,
        TError,
        { atrProfTechnicalName: string; data: Attribute },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useStoreNewAttributeHook>>>,
    TError,
    { atrProfTechnicalName: string; data: Attribute },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeNewAttribute = useStoreNewAttributeHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useStoreNewAttributeHook>>>,
        { atrProfTechnicalName: string; data: Attribute }
    > = (props) => {
        const { atrProfTechnicalName, data } = props ?? {}

        return storeNewAttribute(atrProfTechnicalName, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreNewAttributeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreNewAttributeHook>>>>
export type StoreNewAttributeMutationBody = Attribute
export type StoreNewAttributeMutationError = ApiError

export const useStoreNewAttribute = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useStoreNewAttributeHook>>>,
        TError,
        { atrProfTechnicalName: string; data: Attribute },
        TContext
    >
}) => {
    const mutationOptions = useStoreNewAttributeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useListRelationshipTypes2Hook = () => {
    const listRelationshipTypes2 = useTypesRepoSwaggerClient<RightsTypeHolder[]>()

    return (params: ListRelationshipTypes2Params, signal?: AbortSignal) => {
        return listRelationshipTypes2({ url: `/rights/list`, method: 'get', params, signal })
    }
}

export const getListRelationshipTypes2QueryKey = (params: ListRelationshipTypes2Params) => [`/rights/list`, ...(params ? [params] : [])] as const

export const useListRelationshipTypes2QueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes2Hook>>>,
    TError = ApiError,
>(
    params: ListRelationshipTypes2Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes2Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes2Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListRelationshipTypes2QueryKey(params)

    const listRelationshipTypes2 = useListRelationshipTypes2Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes2Hook>>>> = ({ signal }) =>
        listRelationshipTypes2(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type ListRelationshipTypes2QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes2Hook>>>>
export type ListRelationshipTypes2QueryError = ApiError

export const useListRelationshipTypes2 = <TData = Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes2Hook>>>, TError = ApiError>(
    params: ListRelationshipTypes2Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListRelationshipTypes2Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListRelationshipTypes2QueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindDerivedByDerivedTechnicalNameHook = () => {
    const findDerivedByDerivedTechnicalName = useTypesRepoSwaggerClient<DerivedRelationshipType>()

    return (derivedTechnicalName: string, signal?: AbortSignal) => {
        return findDerivedByDerivedTechnicalName({ url: `/relationshiptypes/${derivedTechnicalName}/derived`, method: 'get', signal })
    }
}

export const getFindDerivedByDerivedTechnicalNameQueryKey = (derivedTechnicalName: string) =>
    [`/relationshiptypes/${derivedTechnicalName}/derived`] as const

export const useFindDerivedByDerivedTechnicalNameQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindDerivedByDerivedTechnicalNameHook>>>,
    TError = ApiError,
>(
    derivedTechnicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindDerivedByDerivedTechnicalNameHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindDerivedByDerivedTechnicalNameHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindDerivedByDerivedTechnicalNameQueryKey(derivedTechnicalName)

    const findDerivedByDerivedTechnicalName = useFindDerivedByDerivedTechnicalNameHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindDerivedByDerivedTechnicalNameHook>>>> = ({ signal }) =>
        findDerivedByDerivedTechnicalName(derivedTechnicalName, signal)

    return { queryKey, queryFn, enabled: !!derivedTechnicalName, ...queryOptions }
}

export type FindDerivedByDerivedTechnicalNameQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useFindDerivedByDerivedTechnicalNameHook>>>
>
export type FindDerivedByDerivedTechnicalNameQueryError = ApiError

export const useFindDerivedByDerivedTechnicalName = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindDerivedByDerivedTechnicalNameHook>>>,
    TError = ApiError,
>(
    derivedTechnicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindDerivedByDerivedTechnicalNameHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindDerivedByDerivedTechnicalNameQueryOptions(derivedTechnicalName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetRelationshipTypeHook = () => {
    const getRelationshipType = useTypesRepoSwaggerClient<RelationshipType>()

    return (technicalName: string, signal?: AbortSignal) => {
        return getRelationshipType({ url: `/relationshiptypes/relationshiptype/${technicalName}`, method: 'get', signal })
    }
}

export const getGetRelationshipTypeQueryKey = (technicalName: string) => [`/relationshiptypes/relationshiptype/${technicalName}`] as const

export const useGetRelationshipTypeQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetRelationshipTypeHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRelationshipTypeHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRelationshipTypeHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetRelationshipTypeQueryKey(technicalName)

    const getRelationshipType = useGetRelationshipTypeHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetRelationshipTypeHook>>>> = ({ signal }) =>
        getRelationshipType(technicalName, signal)

    return { queryKey, queryFn, enabled: !!technicalName, ...queryOptions }
}

export type GetRelationshipTypeQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetRelationshipTypeHook>>>>
export type GetRelationshipTypeQueryError = ApiError

export const useGetRelationshipType = <TData = Awaited<ReturnType<ReturnType<typeof useGetRelationshipTypeHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRelationshipTypeHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetRelationshipTypeQueryOptions(technicalName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetAttributeOverridesHook = () => {
    const getAttributeOverrides = useTypesRepoSwaggerClient<Attribute[]>()

    return (technicalName: string, signal?: AbortSignal) => {
        return getAttributeOverrides({ url: `/relationshiptypes/relationshiptype/${technicalName}/attributeOverride`, method: 'get', signal })
    }
}

export const getGetAttributeOverridesQueryKey = (technicalName: string) =>
    [`/relationshiptypes/relationshiptype/${technicalName}/attributeOverride`] as const

export const useGetAttributeOverridesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAttributeOverridesHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAttributeOverridesHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAttributeOverridesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetAttributeOverridesQueryKey(technicalName)

    const getAttributeOverrides = useGetAttributeOverridesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAttributeOverridesHook>>>> = ({ signal }) =>
        getAttributeOverrides(technicalName, signal)

    return { queryKey, queryFn, enabled: !!technicalName, ...queryOptions }
}

export type GetAttributeOverridesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAttributeOverridesHook>>>>
export type GetAttributeOverridesQueryError = ApiError

export const useGetAttributeOverrides = <TData = Awaited<ReturnType<ReturnType<typeof useGetAttributeOverridesHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAttributeOverridesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetAttributeOverridesQueryOptions(technicalName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGenerateCodeHook = () => {
    const generateCode = useTypesRepoSwaggerClient<RelationshipCode>()

    return (technicalName: string, signal?: AbortSignal) => {
        return generateCode({ url: `/relationshiptypes/generate/${technicalName}`, method: 'get', signal })
    }
}

export const getGenerateCodeQueryKey = (technicalName: string) => [`/relationshiptypes/generate/${technicalName}`] as const

export const useGenerateCodeQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGenerateCodeHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGenerateCodeHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGenerateCodeHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGenerateCodeQueryKey(technicalName)

    const generateCode = useGenerateCodeHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGenerateCodeHook>>>> = ({ signal }) => generateCode(technicalName, signal)

    return { queryKey, queryFn, enabled: !!technicalName, ...queryOptions }
}

export type GenerateCodeQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGenerateCodeHook>>>>
export type GenerateCodeQueryError = ApiError

export const useGenerateCode = <TData = Awaited<ReturnType<ReturnType<typeof useGenerateCodeHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGenerateCodeHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGenerateCodeQueryOptions(technicalName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindAllDerivedByCiTypeHook = () => {
    const findAllDerivedByCiType = useTypesRepoSwaggerClient<DerivedRelationshipType[]>()

    return (ciType: string, signal?: AbortSignal) => {
        return findAllDerivedByCiType({ url: `/relationshiptypes/citypes/${ciType}/derived`, method: 'get', signal })
    }
}

export const getFindAllDerivedByCiTypeQueryKey = (ciType: string) => [`/relationshiptypes/citypes/${ciType}/derived`] as const

export const useFindAllDerivedByCiTypeQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindAllDerivedByCiTypeHook>>>,
    TError = ApiError,
>(
    ciType: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAllDerivedByCiTypeHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAllDerivedByCiTypeHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindAllDerivedByCiTypeQueryKey(ciType)

    const findAllDerivedByCiType = useFindAllDerivedByCiTypeHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindAllDerivedByCiTypeHook>>>> = ({ signal }) =>
        findAllDerivedByCiType(ciType, signal)

    return { queryKey, queryFn, enabled: !!ciType, ...queryOptions }
}

export type FindAllDerivedByCiTypeQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindAllDerivedByCiTypeHook>>>>
export type FindAllDerivedByCiTypeQueryError = ApiError

export const useFindAllDerivedByCiType = <TData = Awaited<ReturnType<ReturnType<typeof useFindAllDerivedByCiTypeHook>>>, TError = ApiError>(
    ciType: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAllDerivedByCiTypeHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindAllDerivedByCiTypeQueryOptions(ciType, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetLastCodeHook = () => {
    const getLastCode = useTypesRepoSwaggerClient<CiCode>()

    return (technicalName: string, signal?: AbortSignal) => {
        return getLastCode({ url: `/citypes/getLastCode/${technicalName}`, method: 'get', signal })
    }
}

export const getGetLastCodeQueryKey = (technicalName: string) => [`/citypes/getLastCode/${technicalName}`] as const

export const useGetLastCodeQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetLastCodeHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLastCodeHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLastCodeHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetLastCodeQueryKey(technicalName)

    const getLastCode = useGetLastCodeHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLastCodeHook>>>> = ({ signal }) => getLastCode(technicalName, signal)

    return { queryKey, queryFn, enabled: !!technicalName, ...queryOptions }
}

export type GetLastCodeQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetLastCodeHook>>>>
export type GetLastCodeQueryError = ApiError

export const useGetLastCode = <TData = Awaited<ReturnType<ReturnType<typeof useGetLastCodeHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLastCodeHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetLastCodeQueryOptions(technicalName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGenerateCodeAndURLHook = () => {
    const generateCodeAndURL = useTypesRepoSwaggerClient<CiCode>()

    return (technicalName: string, signal?: AbortSignal) => {
        return generateCodeAndURL({ url: `/citypes/generate/${technicalName}`, method: 'get', signal })
    }
}

export const getGenerateCodeAndURLQueryKey = (technicalName: string) => [`/citypes/generate/${technicalName}`] as const

export const useGenerateCodeAndURLQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURLHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURLHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURLHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGenerateCodeAndURLQueryKey(technicalName)

    const generateCodeAndURL = useGenerateCodeAndURLHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURLHook>>>> = ({ signal }) =>
        generateCodeAndURL(technicalName, signal)

    return { queryKey, queryFn, enabled: !!technicalName, ...queryOptions }
}

export type GenerateCodeAndURLQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURLHook>>>>
export type GenerateCodeAndURLQueryError = ApiError

export const useGenerateCodeAndURL = <TData = Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURLHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURLHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGenerateCodeAndURLQueryOptions(technicalName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGenerateCodeAndURL1Hook = () => {
    const generateCodeAndURL1 = useTypesRepoSwaggerClient<CiCode[]>()

    return (technicalName: string, params: GenerateCodeAndURL1Params, signal?: AbortSignal) => {
        return generateCodeAndURL1({ url: `/citypes/generate/${technicalName}/bulk`, method: 'get', params, signal })
    }
}

export const getGenerateCodeAndURL1QueryKey = (technicalName: string, params: GenerateCodeAndURL1Params) =>
    [`/citypes/generate/${technicalName}/bulk`, ...(params ? [params] : [])] as const

export const useGenerateCodeAndURL1QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURL1Hook>>>, TError = ApiError>(
    technicalName: string,
    params: GenerateCodeAndURL1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURL1Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURL1Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGenerateCodeAndURL1QueryKey(technicalName, params)

    const generateCodeAndURL1 = useGenerateCodeAndURL1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURL1Hook>>>> = ({ signal }) =>
        generateCodeAndURL1(technicalName, params, signal)

    return { queryKey, queryFn, enabled: !!technicalName, ...queryOptions }
}

export type GenerateCodeAndURL1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURL1Hook>>>>
export type GenerateCodeAndURL1QueryError = ApiError

export const useGenerateCodeAndURL1 = <TData = Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURL1Hook>>>, TError = ApiError>(
    technicalName: string,
    params: GenerateCodeAndURL1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGenerateCodeAndURL1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGenerateCodeAndURL1QueryOptions(technicalName, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetCiTypeHook = () => {
    const getCiType = useTypesRepoSwaggerClient<CiType>()

    return (technicalName: string, signal?: AbortSignal) => {
        return getCiType({ url: `/citypes/citype/${technicalName}`, method: 'get', signal })
    }
}

export const getGetCiTypeQueryKey = (technicalName: string) => [`/citypes/citype/${technicalName}`] as const

export const useGetCiTypeQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetCiTypeHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCiTypeHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCiTypeHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetCiTypeQueryKey(technicalName)

    const getCiType = useGetCiTypeHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCiTypeHook>>>> = ({ signal }) => getCiType(technicalName, signal)

    return { queryKey, queryFn, enabled: !!technicalName, ...queryOptions }
}

export type GetCiTypeQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCiTypeHook>>>>
export type GetCiTypeQueryError = ApiError

export const useGetCiType = <TData = Awaited<ReturnType<ReturnType<typeof useGetCiTypeHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCiTypeHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetCiTypeQueryOptions(technicalName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useListRelatedCiTypesHook = () => {
    const listRelatedCiTypes = useTypesRepoSwaggerClient<RelatedCiTypePreviewList>()

    return (technicalName: string, signal?: AbortSignal) => {
        return listRelatedCiTypes({ url: `/citypes/citype/${technicalName}/related`, method: 'get', signal })
    }
}

export const getListRelatedCiTypesQueryKey = (technicalName: string) => [`/citypes/citype/${technicalName}/related`] as const

export const useListRelatedCiTypesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListRelatedCiTypesHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListRelatedCiTypesHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListRelatedCiTypesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListRelatedCiTypesQueryKey(technicalName)

    const listRelatedCiTypes = useListRelatedCiTypesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListRelatedCiTypesHook>>>> = ({ signal }) =>
        listRelatedCiTypes(technicalName, signal)

    return { queryKey, queryFn, enabled: !!technicalName, ...queryOptions }
}

export type ListRelatedCiTypesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListRelatedCiTypesHook>>>>
export type ListRelatedCiTypesQueryError = ApiError

export const useListRelatedCiTypes = <TData = Awaited<ReturnType<ReturnType<typeof useListRelatedCiTypesHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListRelatedCiTypesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListRelatedCiTypesQueryOptions(technicalName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetAttributeOverrides1Hook = () => {
    const getAttributeOverrides1 = useTypesRepoSwaggerClient<Attribute[]>()

    return (technicalName: string, signal?: AbortSignal) => {
        return getAttributeOverrides1({ url: `/citypes/citype/${technicalName}/attributeOverride`, method: 'get', signal })
    }
}

export const getGetAttributeOverrides1QueryKey = (technicalName: string) => [`/citypes/citype/${technicalName}/attributeOverride`] as const

export const useGetAttributeOverrides1QueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetAttributeOverrides1Hook>>>,
    TError = ApiError,
>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAttributeOverrides1Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAttributeOverrides1Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetAttributeOverrides1QueryKey(technicalName)

    const getAttributeOverrides1 = useGetAttributeOverrides1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAttributeOverrides1Hook>>>> = ({ signal }) =>
        getAttributeOverrides1(technicalName, signal)

    return { queryKey, queryFn, enabled: !!technicalName, ...queryOptions }
}

export type GetAttributeOverrides1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAttributeOverrides1Hook>>>>
export type GetAttributeOverrides1QueryError = ApiError

export const useGetAttributeOverrides1 = <TData = Awaited<ReturnType<ReturnType<typeof useGetAttributeOverrides1Hook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAttributeOverrides1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetAttributeOverrides1QueryOptions(technicalName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useListAttrProfileLMHook = () => {
    const listAttrProfileLM = useTypesRepoSwaggerClient<AttributeProfilePreview>()

    return (signal?: AbortSignal) => {
        return listAttrProfileLM({ url: `/attrprofiles/listLM`, method: 'get', signal })
    }
}

export const getListAttrProfileLMQueryKey = () => [`/attrprofiles/listLM`] as const

export const useListAttrProfileLMQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useListAttrProfileLMHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListAttrProfileLMHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListAttrProfileLMHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListAttrProfileLMQueryKey()

    const listAttrProfileLM = useListAttrProfileLMHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListAttrProfileLMHook>>>> = ({ signal }) => listAttrProfileLM(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type ListAttrProfileLMQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListAttrProfileLMHook>>>>
export type ListAttrProfileLMQueryError = ApiError

export const useListAttrProfileLM = <TData = Awaited<ReturnType<ReturnType<typeof useListAttrProfileLMHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListAttrProfileLMHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListAttrProfileLMQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useListGenericAttrProfileHook = () => {
    const listGenericAttrProfile = useTypesRepoSwaggerClient<AttributeProfilePreview>()

    return (params: ListGenericAttrProfileParams, signal?: AbortSignal) => {
        return listGenericAttrProfile({ url: `/attrprofiles/list/generic`, method: 'get', params, signal })
    }
}

export const getListGenericAttrProfileQueryKey = (params: ListGenericAttrProfileParams) =>
    [`/attrprofiles/list/generic`, ...(params ? [params] : [])] as const

export const useListGenericAttrProfileQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useListGenericAttrProfileHook>>>,
    TError = ApiError,
>(
    params: ListGenericAttrProfileParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListGenericAttrProfileHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListGenericAttrProfileHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListGenericAttrProfileQueryKey(params)

    const listGenericAttrProfile = useListGenericAttrProfileHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListGenericAttrProfileHook>>>> = ({ signal }) =>
        listGenericAttrProfile(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type ListGenericAttrProfileQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListGenericAttrProfileHook>>>>
export type ListGenericAttrProfileQueryError = ApiError

export const useListGenericAttrProfile = <TData = Awaited<ReturnType<ReturnType<typeof useListGenericAttrProfileHook>>>, TError = ApiError>(
    params: ListGenericAttrProfileParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListGenericAttrProfileHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListGenericAttrProfileQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetAttributeProfileHook = () => {
    const getAttributeProfile = useTypesRepoSwaggerClient<AttributeProfile>()

    return (technicalName: string, signal?: AbortSignal) => {
        return getAttributeProfile({ url: `/attrprofiles/attrprofile/${technicalName}`, method: 'get', signal })
    }
}

export const getGetAttributeProfileQueryKey = (technicalName: string) => [`/attrprofiles/attrprofile/${technicalName}`] as const

export const useGetAttributeProfileQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAttributeProfileHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAttributeProfileHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAttributeProfileHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetAttributeProfileQueryKey(technicalName)

    const getAttributeProfile = useGetAttributeProfileHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAttributeProfileHook>>>> = ({ signal }) =>
        getAttributeProfile(technicalName, signal)

    return { queryKey, queryFn, enabled: !!technicalName, ...queryOptions }
}

export type GetAttributeProfileQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAttributeProfileHook>>>>
export type GetAttributeProfileQueryError = ApiError

export const useGetAttributeProfile = <TData = Awaited<ReturnType<ReturnType<typeof useGetAttributeProfileHook>>>, TError = ApiError>(
    technicalName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAttributeProfileHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetAttributeProfileQueryOptions(technicalName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}
