//@ts-nocheck
/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * MetaIS Report
 * OpenAPI spec version: latest
 */
import { useQuery, useMutation } from '@tanstack/react-query'
import type { UseQueryOptions, UseMutationOptions, QueryFunction, MutationFunction, UseQueryResult, QueryKey } from '@tanstack/react-query'
import { useReportSwaggerClient } from '../hooks/useReportSwaggerClient'
export type ListReportParams = {
    page: number
    perPage: number
    sortBy?: string[]
    ascending?: boolean
    published?: boolean
    category?: number
    showAllLanguages?: boolean
    fulltext?: string
}

export type GetLookupsParams = {
    page?: number
    perPage?: number
    sortBy?: string[]
    ascending?: boolean
    published?: boolean
    category?: number
}

export interface CategoryHeaderList {
    totalCount?: number
    categories?: Category[]
}

export type ReportHeaderLanguage = (typeof ReportHeaderLanguage)[keyof typeof ReportHeaderLanguage]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportHeaderLanguage = {
    sk: 'sk',
    en: 'en',
} as const

export type ReportHeaderReportType = (typeof ReportHeaderReportType)[keyof typeof ReportHeaderReportType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportHeaderReportType = {
    INTERNY: 'INTERNY',
    CUSTOM: 'CUSTOM',
} as const

export interface ReportHeader {
    id?: number
    name?: string
    lookupKey?: string
    description?: string
    lastUpdatedTime?: string
    publikovany?: boolean
    reportType?: ReportHeaderReportType
    category?: Category
    language?: ReportHeaderLanguage
}

export interface ReportHeaderList {
    totalCount?: number
    reportHeaders?: ReportHeader[]
}

export interface ReportLookupsList {
    totalCount?: number
    result?: string[]
}

export interface ParameterList {
    parameters?: Parameter[]
}

export type ReportExecuteParameters = { [key: string]: { [key: string]: any } }

export interface ReportExecute {
    page?: number
    perPage?: number
    parameters?: ReportExecuteParameters
}

export type ReportResultObjectResult = { [key: string]: any }

export interface ReportResultObject {
    type?: string
    page?: number
    perPage?: number
    totalCount?: number
    result?: ReportResultObjectResult
}

export type ScriptExecuteParameters = { [key: string]: { [key: string]: any } }

export interface ScriptExecute {
    page?: number
    perPage?: number
    parameters?: ScriptExecuteParameters
    body?: string
}

export type ScriptType = (typeof ScriptType)[keyof typeof ScriptType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScriptType = {
    TABLE: 'TABLE',
    GRAPH: 'GRAPH',
    RAW: 'RAW',
} as const

export interface Script {
    id?: number
    type?: ScriptType
    body?: string
}

export type ReportDefinitionLanguage = (typeof ReportDefinitionLanguage)[keyof typeof ReportDefinitionLanguage]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportDefinitionLanguage = {
    sk: 'sk',
    en: 'en',
} as const

export type ReportDefinitionReportType = (typeof ReportDefinitionReportType)[keyof typeof ReportDefinitionReportType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportDefinitionReportType = {
    INTERNY: 'INTERNY',
    CUSTOM: 'CUSTOM',
} as const

export type ParameterType = (typeof ParameterType)[keyof typeof ParameterType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ParameterType = {
    STRING: 'STRING',
    BOOLEAN: 'BOOLEAN',
    NUMBER: 'NUMBER',
    ENUMERATE: 'ENUMERATE',
    ENUMS_CMDB: 'ENUMS_CMDB',
    ENUMS_REPO: 'ENUMS_REPO',
    DATE: 'DATE',
    DATE_TIME: 'DATE_TIME',
} as const

export interface Parameter {
    id?: number
    key?: string
    name?: string
    required?: boolean
    defaultValue?: string
    type?: ParameterType
    description?: string
    metaData?: string
}

export interface Category {
    id?: number
    name?: string
    nameEn?: string
}

export interface ReportDefinition {
    id?: number
    name?: string
    lookupKey?: string
    description?: string
    lastUpdatedTime?: string
    parameters?: Parameter[]
    scripts?: Script
    publikovany?: boolean
    reportType?: ReportDefinitionReportType
    category?: Category
    language?: ReportDefinitionLanguage
    deleted?: boolean
}

export type ApiErrorData = { [key: string]: any }

export interface ApiError {
    type?: string
    message?: string
    data?: ApiErrorData
    logToken?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const useListCategoriesHook = () => {
    const listCategories = useReportSwaggerClient<CategoryHeaderList>()

    return (signal?: AbortSignal) => {
        return listCategories({ url: `/reports/categories`, method: 'get', signal })
    }
}

export const getListCategoriesQueryKey = () => [`/reports/categories`] as const

export const useListCategoriesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListCategoriesHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListCategoriesHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListCategoriesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListCategoriesQueryKey()

    const listCategories = useListCategoriesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListCategoriesHook>>>> = ({ signal }) => listCategories(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type ListCategoriesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListCategoriesHook>>>>
export type ListCategoriesQueryError = ApiError

export const useListCategories = <TData = Awaited<ReturnType<ReturnType<typeof useListCategoriesHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListCategoriesHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListCategoriesQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUpdateCategory1Hook = () => {
    const updateCategory1 = useReportSwaggerClient<void>()

    return (category: Category) => {
        return updateCategory1({ url: `/reports/categories`, method: 'put', headers: { 'Content-Type': 'application/json' }, data: category })
    }
}

export const useUpdateCategory1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateCategory1Hook>>>, TError, { data: Category }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateCategory1Hook>>>, TError, { data: Category }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateCategory1 = useUpdateCategory1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateCategory1Hook>>>, { data: Category }> = (props) => {
        const { data } = props ?? {}

        return updateCategory1(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateCategory1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateCategory1Hook>>>>
export type UpdateCategory1MutationBody = Category
export type UpdateCategory1MutationError = ApiError

export const useUpdateCategory1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateCategory1Hook>>>, TError, { data: Category }, TContext>
}) => {
    const mutationOptions = useUpdateCategory1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateCategoryHook = () => {
    const updateCategory = useReportSwaggerClient<void>()

    return (category: Category) => {
        return updateCategory({ url: `/reports/categories`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: category })
    }
}

export const useUpdateCategoryMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateCategoryHook>>>, TError, { data: Category }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateCategoryHook>>>, TError, { data: Category }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateCategory = useUpdateCategoryHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateCategoryHook>>>, { data: Category }> = (props) => {
        const { data } = props ?? {}

        return updateCategory(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateCategoryMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateCategoryHook>>>>
export type UpdateCategoryMutationBody = Category
export type UpdateCategoryMutationError = ApiError

export const useUpdateCategory = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateCategoryHook>>>, TError, { data: Category }, TContext>
}) => {
    const mutationOptions = useUpdateCategoryMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useListCategories1Hook = () => {
    const listCategories1 = useReportSwaggerClient<CategoryHeaderList>()

    return (signal?: AbortSignal) => {
        return listCategories1({ url: `/categories`, method: 'get', signal })
    }
}

export const getListCategories1QueryKey = () => [`/categories`] as const

export const useListCategories1QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListCategories1Hook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListCategories1Hook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListCategories1Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListCategories1QueryKey()

    const listCategories1 = useListCategories1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListCategories1Hook>>>> = ({ signal }) => listCategories1(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type ListCategories1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListCategories1Hook>>>>
export type ListCategories1QueryError = ApiError

export const useListCategories1 = <TData = Awaited<ReturnType<ReturnType<typeof useListCategories1Hook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListCategories1Hook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListCategories1QueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUpdateCategory3Hook = () => {
    const updateCategory3 = useReportSwaggerClient<void>()

    return (category: Category) => {
        return updateCategory3({ url: `/categories`, method: 'put', headers: { 'Content-Type': 'application/json' }, data: category })
    }
}

export const useUpdateCategory3MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateCategory3Hook>>>, TError, { data: Category }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateCategory3Hook>>>, TError, { data: Category }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateCategory3 = useUpdateCategory3Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateCategory3Hook>>>, { data: Category }> = (props) => {
        const { data } = props ?? {}

        return updateCategory3(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateCategory3MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateCategory3Hook>>>>
export type UpdateCategory3MutationBody = Category
export type UpdateCategory3MutationError = ApiError

export const useUpdateCategory3 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateCategory3Hook>>>, TError, { data: Category }, TContext>
}) => {
    const mutationOptions = useUpdateCategory3MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateCategory2Hook = () => {
    const updateCategory2 = useReportSwaggerClient<void>()

    return (category: Category) => {
        return updateCategory2({ url: `/categories`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: category })
    }
}

export const useUpdateCategory2MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateCategory2Hook>>>, TError, { data: Category }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateCategory2Hook>>>, TError, { data: Category }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateCategory2 = useUpdateCategory2Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateCategory2Hook>>>, { data: Category }> = (props) => {
        const { data } = props ?? {}

        return updateCategory2(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateCategory2MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateCategory2Hook>>>>
export type UpdateCategory2MutationBody = Category
export type UpdateCategory2MutationError = ApiError

export const useUpdateCategory2 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateCategory2Hook>>>, TError, { data: Category }, TContext>
}) => {
    const mutationOptions = useUpdateCategory2MutationOptions(options)

    return useMutation(mutationOptions)
}

export const usePublishReportHook = () => {
    const publishReport = useReportSwaggerClient<void>()

    return (reportId: number) => {
        return publishReport({ url: `/reports/publish/${reportId}`, method: 'put' })
    }
}

export const usePublishReportMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePublishReportHook>>>, TError, { reportId: number }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePublishReportHook>>>, TError, { reportId: number }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const publishReport = usePublishReportHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePublishReportHook>>>, { reportId: number }> = (props) => {
        const { reportId } = props ?? {}

        return publishReport(reportId)
    }

    return { mutationFn, ...mutationOptions }
}

export type PublishReportMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePublishReportHook>>>>

export type PublishReportMutationError = ApiError

export const usePublishReport = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePublishReportHook>>>, TError, { reportId: number }, TContext>
}) => {
    const mutationOptions = usePublishReportMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUnpublishReportHook = () => {
    const unpublishReport = useReportSwaggerClient<void>()

    return (reportId: number) => {
        return unpublishReport({ url: `/reports/publish/${reportId}`, method: 'delete' })
    }
}

export const useUnpublishReportMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUnpublishReportHook>>>, TError, { reportId: number }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUnpublishReportHook>>>, TError, { reportId: number }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const unpublishReport = useUnpublishReportHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUnpublishReportHook>>>, { reportId: number }> = (props) => {
        const { reportId } = props ?? {}

        return unpublishReport(reportId)
    }

    return { mutationFn, ...mutationOptions }
}

export type UnpublishReportMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUnpublishReportHook>>>>

export type UnpublishReportMutationError = ApiError

export const useUnpublishReport = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUnpublishReportHook>>>, TError, { reportId: number }, TContext>
}) => {
    const mutationOptions = useUnpublishReportMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetParametersHook = () => {
    const getParameters = useReportSwaggerClient<ParameterList>()

    return (reportId: number, signal?: AbortSignal) => {
        return getParameters({ url: `/reports/parameters/${reportId}`, method: 'get', signal })
    }
}

export const getGetParametersQueryKey = (reportId: number) => [`/reports/parameters/${reportId}`] as const

export const useGetParametersQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetParametersHook>>>, TError = ApiError>(
    reportId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetParametersHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetParametersHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetParametersQueryKey(reportId)

    const getParameters = useGetParametersHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetParametersHook>>>> = ({ signal }) => getParameters(reportId, signal)

    return { queryKey, queryFn, enabled: !!reportId, ...queryOptions }
}

export type GetParametersQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetParametersHook>>>>
export type GetParametersQueryError = ApiError

export const useGetParameters = <TData = Awaited<ReturnType<ReturnType<typeof useGetParametersHook>>>, TError = ApiError>(
    reportId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetParametersHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetParametersQueryOptions(reportId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUpdateParameterHook = () => {
    const updateParameter = useReportSwaggerClient<void>()

    return (reportId: number, parameter: Parameter) => {
        return updateParameter({
            url: `/reports/parameters/${reportId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: parameter,
        })
    }
}

export const useUpdateParameterMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateParameterHook>>>,
        TError,
        { reportId: number; data: Parameter },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateParameterHook>>>, TError, { reportId: number; data: Parameter }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateParameter = useUpdateParameterHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateParameterHook>>>, { reportId: number; data: Parameter }> = (
        props,
    ) => {
        const { reportId, data } = props ?? {}

        return updateParameter(reportId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateParameterMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateParameterHook>>>>
export type UpdateParameterMutationBody = Parameter
export type UpdateParameterMutationError = ApiError

export const useUpdateParameter = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateParameterHook>>>,
        TError,
        { reportId: number; data: Parameter },
        TContext
    >
}) => {
    const mutationOptions = useUpdateParameterMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useAddParameterHook = () => {
    const addParameter = useReportSwaggerClient<Parameter>()

    return (reportId: number, parameter: Parameter) => {
        return addParameter({
            url: `/reports/parameters/${reportId}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: parameter,
        })
    }
}

export const useAddParameterMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddParameterHook>>>,
        TError,
        { reportId: number; data: Parameter },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddParameterHook>>>, TError, { reportId: number; data: Parameter }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const addParameter = useAddParameterHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAddParameterHook>>>, { reportId: number; data: Parameter }> = (
        props,
    ) => {
        const { reportId, data } = props ?? {}

        return addParameter(reportId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type AddParameterMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddParameterHook>>>>
export type AddParameterMutationBody = Parameter
export type AddParameterMutationError = ApiError

export const useAddParameter = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddParameterHook>>>,
        TError,
        { reportId: number; data: Parameter },
        TContext
    >
}) => {
    const mutationOptions = useAddParameterMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useSaveReportHook = () => {
    const saveReport = useReportSwaggerClient<ReportDefinition>()

    return (reportDefinition: ReportDefinition) => {
        return saveReport({ url: `/reports/save`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: reportDefinition })
    }
}

export const useSaveReportMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSaveReportHook>>>, TError, { data: ReportDefinition }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSaveReportHook>>>, TError, { data: ReportDefinition }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const saveReport = useSaveReportHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSaveReportHook>>>, { data: ReportDefinition }> = (props) => {
        const { data } = props ?? {}

        return saveReport(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type SaveReportMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSaveReportHook>>>>
export type SaveReportMutationBody = ReportDefinition
export type SaveReportMutationError = ApiError

export const useSaveReport = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSaveReportHook>>>, TError, { data: ReportDefinition }, TContext>
}) => {
    const mutationOptions = useSaveReportMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useRunHook = () => {
    const run = useReportSwaggerClient<ReportResultObject>()

    return (scriptExecute: ScriptExecute) => {
        return run({ url: `/reports/run`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: scriptExecute })
    }
}

export const useRunMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRunHook>>>, TError, { data: ScriptExecute }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRunHook>>>, TError, { data: ScriptExecute }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const run = useRunHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRunHook>>>, { data: ScriptExecute }> = (props) => {
        const { data } = props ?? {}

        return run(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type RunMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRunHook>>>>
export type RunMutationBody = ScriptExecute
export type RunMutationError = ApiError

export const useRun = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRunHook>>>, TError, { data: ScriptExecute }, TContext>
}) => {
    const mutationOptions = useRunMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExecuteHook = () => {
    const execute = useReportSwaggerClient<ReportResultObject>()

    return (reportId: number, type: string, reportExecute: ReportExecute) => {
        return execute({
            url: `/reports/execute/${reportId}/type/${type}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: reportExecute,
        })
    }
}

export const getExecuteQueryKey = (reportId: number, type: string, reportExecute: ReportExecute) =>
    [`/reports/execute/${reportId}/type/${type}`, reportExecute] as const

export const useExecuteQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useExecuteHook>>>, TError = ApiError>(
    reportId: number,
    type: string,
    reportExecute: ReportExecute,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useExecuteHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useExecuteHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getExecuteQueryKey(reportId, type, reportExecute)

    const execute = useExecuteHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useExecuteHook>>>> = () => execute(reportId, type, reportExecute)

    return { queryKey, queryFn, enabled: !!(reportId && type), ...queryOptions }
}

export type ExecuteQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExecuteHook>>>>
export type ExecuteQueryError = ApiError

export const useExecute = <TData = Awaited<ReturnType<ReturnType<typeof useExecuteHook>>>, TError = ApiError>(
    reportId: number,
    type: string,
    reportExecute: ReportExecute,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useExecuteHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useExecuteQueryOptions(reportId, type, reportExecute, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useExecute1Hook = () => {
    const execute1 = useReportSwaggerClient<ReportResultObject>()

    return (lookupKey: string, type: string, reportExecute: ReportExecute) => {
        return execute1({
            url: `/reports/execute/lookup/${lookupKey}/type/${type}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: reportExecute,
        })
    }
}

export const useExecute1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExecute1Hook>>>,
        TError,
        { lookupKey: string; type: string; data: ReportExecute },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExecute1Hook>>>,
    TError,
    { lookupKey: string; type: string; data: ReportExecute },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const execute1 = useExecute1Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExecute1Hook>>>,
        { lookupKey: string; type: string; data: ReportExecute }
    > = (props) => {
        const { lookupKey, type, data } = props ?? {}

        return execute1(lookupKey, type, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type Execute1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExecute1Hook>>>>
export type Execute1MutationBody = ReportExecute
export type Execute1MutationError = ApiError

export const useExecute1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExecute1Hook>>>,
        TError,
        { lookupKey: string; type: string; data: ReportExecute },
        TContext
    >
}) => {
    const mutationOptions = useExecute1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetReportHook = () => {
    const getReport = useReportSwaggerClient<ReportDefinition>()

    return (reportId: number, signal?: AbortSignal) => {
        return getReport({ url: `/reports/${reportId}`, method: 'get', signal })
    }
}

export const getGetReportQueryKey = (reportId: number) => [`/reports/${reportId}`] as const

export const useGetReportQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetReportHook>>>, TError = ApiError>(
    reportId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReportHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReportHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetReportQueryKey(reportId)

    const getReport = useGetReportHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetReportHook>>>> = ({ signal }) => getReport(reportId, signal)

    return { queryKey, queryFn, enabled: !!reportId, ...queryOptions }
}

export type GetReportQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetReportHook>>>>
export type GetReportQueryError = ApiError

export const useGetReport = <TData = Awaited<ReturnType<ReturnType<typeof useGetReportHook>>>, TError = ApiError>(
    reportId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReportHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetReportQueryOptions(reportId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useDeleteReportHook = () => {
    const deleteReport = useReportSwaggerClient<void>()

    return (reportId: number) => {
        return deleteReport({ url: `/reports/${reportId}`, method: 'delete' })
    }
}

export const useDeleteReportMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteReportHook>>>, TError, { reportId: number }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteReportHook>>>, TError, { reportId: number }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteReport = useDeleteReportHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteReportHook>>>, { reportId: number }> = (props) => {
        const { reportId } = props ?? {}

        return deleteReport(reportId)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteReportMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteReportHook>>>>

export type DeleteReportMutationError = ApiError

export const useDeleteReport = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteReportHook>>>, TError, { reportId: number }, TContext>
}) => {
    const mutationOptions = useDeleteReportMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useListParameterTypeHook = () => {
    const listParameterType = useReportSwaggerClient<string[]>()

    return (signal?: AbortSignal) => {
        return listParameterType({ url: `/reports/parameters/type`, method: 'get', signal })
    }
}

export const getListParameterTypeQueryKey = () => [`/reports/parameters/type`] as const

export const useListParameterTypeQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useListParameterTypeHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListParameterTypeHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListParameterTypeHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListParameterTypeQueryKey()

    const listParameterType = useListParameterTypeHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListParameterTypeHook>>>> = ({ signal }) => listParameterType(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type ListParameterTypeQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListParameterTypeHook>>>>
export type ListParameterTypeQueryError = ApiError

export const useListParameterType = <TData = Awaited<ReturnType<ReturnType<typeof useListParameterTypeHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListParameterTypeHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListParameterTypeQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetParameters1Hook = () => {
    const getParameters1 = useReportSwaggerClient<ParameterList>()

    return (lookupKey: string, signal?: AbortSignal) => {
        return getParameters1({ url: `/reports/parameters/lookup/${lookupKey}`, method: 'get', signal })
    }
}

export const getGetParameters1QueryKey = (lookupKey: string) => [`/reports/parameters/lookup/${lookupKey}`] as const

export const useGetParameters1QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetParameters1Hook>>>, TError = ApiError>(
    lookupKey: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetParameters1Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetParameters1Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetParameters1QueryKey(lookupKey)

    const getParameters1 = useGetParameters1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetParameters1Hook>>>> = ({ signal }) => getParameters1(lookupKey, signal)

    return { queryKey, queryFn, enabled: !!lookupKey, ...queryOptions }
}

export type GetParameters1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetParameters1Hook>>>>
export type GetParameters1QueryError = ApiError

export const useGetParameters1 = <TData = Awaited<ReturnType<ReturnType<typeof useGetParameters1Hook>>>, TError = ApiError>(
    lookupKey: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetParameters1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetParameters1QueryOptions(lookupKey, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetLookupsHook = () => {
    const getLookups = useReportSwaggerClient<ReportLookupsList>()

    return (params?: GetLookupsParams, signal?: AbortSignal) => {
        return getLookups({ url: `/reports/lookups`, method: 'get', params, signal })
    }
}

export const getGetLookupsQueryKey = (params?: GetLookupsParams) => [`/reports/lookups`, ...(params ? [params] : [])] as const

export const useGetLookupsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetLookupsHook>>>, TError = ApiError>(
    params?: GetLookupsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLookupsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLookupsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetLookupsQueryKey(params)

    const getLookups = useGetLookupsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLookupsHook>>>> = ({ signal }) => getLookups(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetLookupsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetLookupsHook>>>>
export type GetLookupsQueryError = ApiError

export const useGetLookups = <TData = Awaited<ReturnType<ReturnType<typeof useGetLookupsHook>>>, TError = ApiError>(
    params?: GetLookupsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLookupsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetLookupsQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetReport1Hook = () => {
    const getReport1 = useReportSwaggerClient<ReportDefinition>()

    return (lookupKey: string, signal?: AbortSignal) => {
        return getReport1({ url: `/reports/lookup/${lookupKey}`, method: 'get', signal })
    }
}

export const getGetReport1QueryKey = (lookupKey: string) => [`/reports/lookup/${lookupKey}`] as const

export const useGetReport1QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetReport1Hook>>>, TError = ApiError>(
    lookupKey: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReport1Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReport1Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetReport1QueryKey(lookupKey)

    const getReport1 = useGetReport1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetReport1Hook>>>> = ({ signal }) => getReport1(lookupKey, signal)

    return { queryKey, queryFn, enabled: !!lookupKey, ...queryOptions }
}

export type GetReport1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetReport1Hook>>>>
export type GetReport1QueryError = ApiError

export const useGetReport1 = <TData = Awaited<ReturnType<ReturnType<typeof useGetReport1Hook>>>, TError = ApiError>(
    lookupKey: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReport1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetReport1QueryOptions(lookupKey, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useListReportHook = () => {
    const listReport = useReportSwaggerClient<ReportHeaderList>()

    return (params: ListReportParams, signal?: AbortSignal) => {
        return listReport({ url: `/reports/list`, method: 'get', params, signal })
    }
}

export const getListReportQueryKey = (params: ListReportParams) => [`/reports/list`, ...(params ? [params] : [])] as const

export const useListReportQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListReportHook>>>, TError = ApiError>(
    params: ListReportParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListReportHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListReportHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListReportQueryKey(params)

    const listReport = useListReportHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListReportHook>>>> = ({ signal }) => listReport(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type ListReportQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListReportHook>>>>
export type ListReportQueryError = ApiError

export const useListReport = <TData = Awaited<ReturnType<ReturnType<typeof useListReportHook>>>, TError = ApiError>(
    params: ListReportParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListReportHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListReportQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useDeleteParameterHook = () => {
    const deleteParameter = useReportSwaggerClient<void>()

    return (reportId: number, parameterId: number) => {
        return deleteParameter({ url: `/reports/parameters/report/${reportId}/parameter/${parameterId}`, method: 'delete' })
    }
}

export const useDeleteParameterMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteParameterHook>>>,
        TError,
        { reportId: number; parameterId: number },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteParameterHook>>>,
    TError,
    { reportId: number; parameterId: number },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteParameter = useDeleteParameterHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteParameterHook>>>, { reportId: number; parameterId: number }> = (
        props,
    ) => {
        const { reportId, parameterId } = props ?? {}

        return deleteParameter(reportId, parameterId)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteParameterMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteParameterHook>>>>

export type DeleteParameterMutationError = ApiError

export const useDeleteParameter = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteParameterHook>>>,
        TError,
        { reportId: number; parameterId: number },
        TContext
    >
}) => {
    const mutationOptions = useDeleteParameterMutationOptions(options)

    return useMutation(mutationOptions)
}
