import { BreadCrumbs, HomeIcon, TextBody, TextHeading } from '@isdd/idsk-ui-kit/index'
import { FooterRouteNames } from '@isdd/metais-common/navigation/routeNames'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { MainContentWrapper } from '@/components/MainContentWrapper'

export const SpravcaObsahu = () => {
    const { t } = useTranslation()
    const TLACOVE_MAIL = 'tlacove@mirri.gov.sk'
    return (
        <>
            <BreadCrumbs
                withWidthContainer
                links={[
                    { label: t('breadcrumbs.home'), href: '/', icon: HomeIcon },
                    { label: t('footer.contentAdmin'), href: FooterRouteNames.CONTENT_ADMIN },
                ]}
            />
            <MainContentWrapper>
                <TextHeading size="XL">{t('footer.contentAdmin')}</TextHeading>
                <TextBody>
                    <Trans i18nKey={`contentAdmin.body`} components={[<Link key="link" to={`mailto:${TLACOVE_MAIL}`} className="govuk-link" />]} />
                </TextBody>
            </MainContentWrapper>
        </>
    )
}
