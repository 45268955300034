import React, { PropsWithChildren, forwardRef } from 'react'
import classNames from 'classnames'

import styles from './styles.module.scss'

interface ITextWarningProps extends PropsWithChildren {
    assistive?: string
    smallBottomMargin?: boolean
}

export const TextWarning = forwardRef<HTMLDivElement, ITextWarningProps>(({ children, assistive, smallBottomMargin }, ref) => {
    return (
        <div className={classNames('govuk-warning-text', { [styles.marginBottomSmall]: smallBottomMargin })} ref={ref}>
            <span className="govuk-warning-text__icon" aria-hidden="true">
                !
            </span>
            <strong className="govuk-warning-text__text">
                <span className="govuk-warning-text__assistive">{assistive}</span>
                {children}
            </strong>
        </div>
    )
})
