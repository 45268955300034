import { Input } from '@isdd/idsk-ui-kit/index'
import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { Spacer } from '@isdd/metais-common/components/spacer/Spacer'
import { useEffect } from 'react'
import { FieldErrors, UseFormRegister, UseFormUnregister, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { RefTemplateUriFormType } from './forms/refCreateSchema'
import styles from './refIdentifiers.module.scss'

type ParametersLineType = {
    index?: number
    register: UseFormRegister<RefTemplateUriFormType>
    unregister?: UseFormUnregister<RefTemplateUriFormType>
    errors: FieldErrors<RefTemplateUriFormType>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: any
}

export const UriParameters: React.FC<ParametersLineType> = ({ index, errors, register, unregister, control }) => {
    const { t } = useTranslation()

    const { remove: removeName } = useFieldArray({
        control,
        name: `attributes.${ATTRIBUTE_NAME.Profil_Individuum_parameter}`,
    })

    const { remove: removeValue } = useFieldArray({
        control,
        name: `attributes.${ATTRIBUTE_NAME.Profil_Individuum_parameter_desc}`,
    })

    useEffect(
        () => () => {
            removeName(index)
            removeValue(index)
            unregister?.(`attributes.${ATTRIBUTE_NAME.Profil_Individuum_parameter}.${index ?? 0}`)
            unregister?.(`attributes.${ATTRIBUTE_NAME.Profil_Individuum_parameter_desc}.${index ?? 0}`)
        },
        [index, removeValue, removeName, unregister],
    )

    return (
        <div className={styles.inline}>
            <Input
                required
                id={`documentLinks.${index}.0`}
                placeholder={t('refIdentifiers.create.generalInputPlaceholder')}
                {...register(`attributes.${ATTRIBUTE_NAME.Profil_Individuum_parameter}.${index ?? 0}`)}
                error={errors?.attributes?.[ATTRIBUTE_NAME.Profil_Individuum_parameter]?.[index ?? 0]?.message}
                label={t('refIdentifiers.create.parameter.name')}
                className={styles.inlineItem}
            />
            <Spacer horizontal />
            <Input
                required
                id={`parameters.${index}.1`}
                placeholder={t('refIdentifiers.create.generalInputPlaceholder')}
                {...register(`attributes.${ATTRIBUTE_NAME.Profil_Individuum_parameter_desc}.${index ?? 0}`)}
                error={errors?.attributes?.[ATTRIBUTE_NAME.Profil_Individuum_parameter_desc]?.[index ?? 0]?.message}
                label={t('refIdentifiers.create.parameter.value')}
                className={styles.inlineItem}
            />
        </div>
    )
}
