import { BreadCrumbs, Button, GridCol, GridRow, HomeIcon, Table, TextBody, TextHeading } from '@isdd/idsk-ui-kit/index'
import { SafeHtmlComponent } from '@isdd/idsk-ui-kit/save-html-component/SafeHtmlComponent'
import { Identity } from '@isdd/metais-common/api/generated/iam-swagger'
import { Task, TaskHistory, TaskState } from '@isdd/metais-common/api/generated/tasks-swagger'
import { DefinitionList } from '@isdd/metais-common/components/definition-list/DefinitionList'
import { DefinitionListItem } from '@isdd/metais-common/components/definition-list/DefinitionListItem'
import { FlexColumnReverseWrapper } from '@isdd/metais-common/components/flex-column-reverse-wrapper/FlexColumnReverseWrapper'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { useScroll } from '@isdd/metais-common/hooks/useScroll'
import { MutationFeedback, QueryFeedback } from '@isdd/metais-common/index'
import { RouteNames } from '@isdd/metais-common/navigation/routeNames'
import { setDocumentTitle } from '@isdd/metais-common/utils/utils'
import { ColumnDef } from '@tanstack/react-table'
import React, { SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './tasks.module.scss'

import { MainContentWrapper } from '@/components/MainContentWrapper'
import { IGetAssignee } from '@/components/containers/tasks/TaskDetailContainer'
import { IdentitySelect } from '@/components/identity-lazy-select/IdentitySelect'
interface ITaskDetailView {
    task: Task | undefined
    historyColumns: ColumnDef<TaskHistory>[]
    isLoading: boolean
    isError: boolean
    closeTask: () => void
    reassignTask: () => void
    setSelectedLogin: React.Dispatch<SetStateAction<Identity | undefined>>
    getAssignee: ({ assignee, assigneeType }: IGetAssignee) => string
}

export const TaskDetailView: React.FC<ITaskDetailView> = ({
    task,
    historyColumns,
    isLoading,
    isError,
    closeTask,
    reassignTask,
    getAssignee,
    setSelectedLogin,
}) => {
    const { t } = useTranslation()
    setDocumentTitle(`${t('titles.taskDetail')} ${task?.name ?? ''}`)

    const { isActionSuccess } = useActionSuccess()
    const { wrapperRef, scrollToMutationFeedback } = useScroll()
    useEffect(() => {
        if (isActionSuccess.value) {
            scrollToMutationFeedback()
        }
    }, [isActionSuccess, scrollToMutationFeedback])

    return (
        <>
            <BreadCrumbs
                withWidthContainer
                links={[
                    { label: t('tasks.home'), href: RouteNames.HOME, icon: HomeIcon },
                    { label: t('tasks.tasks'), href: RouteNames.TASKS },
                    { label: `${task?.name}`, href: '' },
                ]}
            />
            <MainContentWrapper>
                <QueryFeedback loading={isLoading} error={false} withChildren>
                    <FlexColumnReverseWrapper>
                        <TextHeading size="L">{task?.name}</TextHeading>
                        <QueryFeedback loading={false} error={isError} />
                    </FlexColumnReverseWrapper>
                    <MutationFeedback
                        success={isActionSuccess.value}
                        successMessage={
                            isActionSuccess.additionalInfo?.type == 'toUser'
                                ? t('tasks.reassignedToUser', { name: isActionSuccess.additionalInfo?.userName })
                                : t('tasks.reassignedToGroup', { name: isActionSuccess.additionalInfo?.groupName })
                        }
                    />
                    <div ref={wrapperRef} />

                    {task?.state !== TaskState.DONE ? (
                        <>
                            <Button onClick={closeTask} label={t('tasks.finish')} className="idsk-button" />
                            <GridRow>
                                <GridCol setWidth="two-thirds">
                                    <IdentitySelect
                                        name="identitySelect"
                                        onChange={(val) => {
                                            setSelectedLogin(Array.isArray(val) ? val[0] : val)
                                        }}
                                    />
                                </GridCol>
                                <GridCol setWidth="one-third">
                                    <Button
                                        label={t('tasks.assignToUser')}
                                        className={'idsk-button--secondary ' + styles.marginTop30}
                                        onClick={reassignTask}
                                    />
                                </GridCol>
                            </GridRow>
                        </>
                    ) : (
                        <>
                            <Button label={t('tasks.toMe')} className="idsk-button" onClick={reassignTask} />
                        </>
                    )}
                    <DefinitionList>
                        {task?.dueDate && <DefinitionListItem label={t('tasks.deadline')} value={t('dateTime', { date: task?.dueDate })} />}
                        <DefinitionListItem label={t('tasks.tableHeaders.createdAt')} value={t('dateTime', { date: task?.createdAt })} />
                        <DefinitionListItem label={t('tasks.state')} value={t(`tasks.${task?.state}`)} />
                        <DefinitionListItem label={t('tasks.tableHeaders.type')} value={task?.appId ?? ''} />
                        <DefinitionListItem
                            label={t('tasks.assigne')}
                            value={getAssignee({ assignee: task?.assignedTo, assigneeType: task?.assigneeType }) ?? ''}
                        />
                    </DefinitionList>
                    <TextHeading size="L">{t('tasks.description')}</TextHeading>
                    <TextBody>
                        <SafeHtmlComponent dirtyHtml={task?.description ?? ''} />
                    </TextBody>
                    <TextHeading size="L">{t('tasks.baseInfo')}</TextHeading>
                    <Table<TaskHistory>
                        columns={historyColumns}
                        isLoading={isLoading}
                        error={isError}
                        data={task?.taskHistoryList?.taskHistoryList}
                    />
                </QueryFeedback>
            </MainContentWrapper>
        </>
    )
}
