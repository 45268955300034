import { IFilter, SortType } from '@isdd/idsk-ui-kit/types'
import { ATTRIBUTE_NAME, RELATION_TYPE, RefIdentifierTypeEnum } from '@isdd/metais-common/api'
import {
    AttributeUiValue,
    ConfigurationItemUi,
    useReadCiList1Hook,
    useStoreConfigurationItem,
    useStoreRelationship,
} from '@isdd/metais-common/api/generated/cmdb-swagger'
import { IFilterParams } from '@isdd/metais-common/hooks/useFilter'
import { useGetStatus } from '@isdd/metais-common/hooks/useGetRequestStatus'
import { useScroll } from '@isdd/metais-common/hooks/useScroll'
import { RouterRoutes } from '@isdd/metais-common/navigation/routeNames'
import React, { useEffect, useState } from 'react'
import { FieldValues } from 'react-hook-form'
import { v4 as uuidV4 } from 'uuid'
import { useNavigate } from 'react-router-dom'
import { useGenerateCodeHook } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { ApiStandardRequestRequestChannel, useCreateStandardRequest } from '@isdd/metais-common/api/generated/standards-swagger'
import { useTranslation } from 'react-i18next'

import { createURIStandardRequest } from './RefIdentifierRequest.helper'
import { PageType } from './RefIdentifiersContainer'

import { useCiCreateEditOnStatusSuccess, useCiCreateUpdateOnSubmit } from '@/components/create-entity/createEntityHelpers'
import { RefIdentifierCreateView } from '@/components/views/ref-identifiers/RefIdentifierCreateView'
import {
    RefCatalogFormTypeEnum,
    RefDataItemFormType,
    RefDataItemFormTypeEnum,
    RefOntologyFormType,
    RefOntologyFormTypeEnum,
    RefOntologyVersionFormType,
    RefOntologyVersionFormTypeEnum,
    RefTemplateUriFormType,
    RefTemplateUriFormTypeEnum,
} from '@/components/views/ref-identifiers/forms/refCreateSchema'
import { RefIdentifierListShowEnum } from '@/components/views/ref-identifiers/refIdentifierListProps'
import { useRefIdentifierHook } from '@/hooks/useRefIdentifier.hook'

export interface RefIdentifierListFilterData extends IFilterParams, IFilter {
    type: RefIdentifierTypeEnum[]
    state: string
    createdAtFrom: string
    createdAtTo: string
    view: RefIdentifierListShowEnum
}

const getTextAfterLastSlash = (url?: string) => {
    const parts = url?.split('/')
    return parts?.[parts.length - 1]
}

interface Props {
    pageType: PageType
}

export const RefIdentifierCreateContainer: React.FC<Props> = ({ pageType }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [type, setType] = useState<RefIdentifierTypeEnum>(RefIdentifierTypeEnum.DatovyPrvok)
    const [isUriExist, setIsUriExist] = useState<boolean>(false)

    const {
        ciTypeData,
        attributes,
        generatedEntityId,
        ownerOptions,
        templateUriOptions,
        dataItemTypeOptions,
        groupDataFiltered,
        isLoading: isRefLoading,
        isError: isRefError,
        checkUriIfExist,
        isCheckUriLoading,
        regStates,
    } = useRefIdentifierHook(type)

    const onStatusSuccess = useCiCreateEditOnStatusSuccess(`${RouterRoutes.DATA_OBJECT_REF_IDENTIFIER_REQUESTS}`)
    const { isError: isRedirectError, isLoading: isRedirectLoading, isProcessedError, getRequestStatus, isTooManyFetchesError } = useGetStatus()
    const { onSubmit, setUploadError, configurationItemId } = useCiCreateUpdateOnSubmit(type)
    const {
        mutateAsync: createStandartRequestHook,
        isError: isStandartsRequestError,
        isLoading: isStandartsRequestLoading,
    } = useCreateStandardRequest()

    const ciOptionsHook = useReadCiList1Hook()

    const loadPO = async (uuid: string) => {
        const ciResponse = await ciOptionsHook({
            page: 1,
            perpage: 20,
            sortBy: ATTRIBUTE_NAME.Gen_Profil_nazov,
            sortType: SortType.ASC,
            filter: {
                type: ['PO'],
                metaAttributes: { state: ['DRAFT'] },
                uuid: [uuid],
            },
        })

        return ciResponse.configurationItemSet || []
    }

    const loadOntology = async (uuid: string) => {
        const ciResponse = await ciOptionsHook({
            page: 1,
            perpage: 20,
            sortBy: ATTRIBUTE_NAME.Gen_Profil_nazov,
            sortType: SortType.ASC,
            filter: {
                type: [RefIdentifierTypeEnum.Ontologia],
                metaAttributes: { state: ['DRAFT'] },
                uuid: [uuid],
            },
        })

        return ciResponse.configurationItemSet || []
    }

    const createStandardRequest = async (ciUuid: string, formData: FieldValues, riType: RefIdentifierTypeEnum) => {
        let po: ConfigurationItemUi[] | undefined
        let ontology: ConfigurationItemUi[] | undefined
        if (formData.po) {
            po = await loadPO(formData.po)
        }

        if (riType === RefIdentifierTypeEnum.VerziaOntologie) {
            ontology = await loadOntology(formData.ontology)
        }

        const request = createURIStandardRequest(formData, riType, templateUriOptions, dataItemTypeOptions, ownerOptions, t, ontology, po)

        await createStandartRequestHook({
            data: {
                requestChannel: ApiStandardRequestRequestChannel.RI,
                requestChannelAttributes: [{ attributeName: 'ri_uuid', attributeValue: ciUuid }],
                name: request.name,
                description: request.description,
            },
        })
    }

    const storeConfigurationItem = useStoreConfigurationItem({
        mutation: {
            onError() {
                setUploadError(true)
            },
            onSuccess(successData) {
                if (successData.requestId != null) {
                    getRequestStatus(successData.requestId, () => onStatusSuccess({ configurationItemId, isUpdate: false, entityName: type }))
                } else {
                    setUploadError(true)
                }
            },
        },
    })

    const { isLoading: isStoreRelationsLoading, isError: isStoreRelationsError, mutateAsync: createRelations } = useStoreRelationship()

    const { wrapperRef, scrollToMutationFeedback } = useScroll()

    useEffect(() => {
        if (!(isRedirectError || isProcessedError || isRedirectLoading)) {
            scrollToMutationFeedback()
        }
    }, [isProcessedError, isRedirectError, isRedirectLoading, scrollToMutationFeedback])

    const isLoading = [isRefLoading].some((item) => item)
    const isError = [isRefError].some((item) => item)

    const createCiItem = async (formData: FieldValues, isSend: boolean) => {
        const registrationState = isSend ? 'c_stav_registracie.4' : 'c_stav_registracie.1'
        const owner = groupDataFiltered.find((item) => item.orgId === formData[RefCatalogFormTypeEnum.OWNER])
        const ownerRoleGid = owner?.roles.find((role) => role.roleName == 'REFID_URI_DEF')?.gid
        const attributeList = {
            ...formData.attributes,
            [ATTRIBUTE_NAME.Gen_Profil_RefID_stav_registracie]: registrationState,
            [ATTRIBUTE_NAME.Gen_Profil_kod_metais]: generatedEntityId?.cicode,
            [ATTRIBUTE_NAME.Gen_Profil_ref_id]: getTextAfterLastSlash(generatedEntityId?.ciurl),
        }
        const uuid = await onSubmit({
            formData: attributeList,
            storeCiItem: storeConfigurationItem.mutateAsync,
            ownerId: ownerRoleGid,
            generatedEntityId,
        })

        return { ownerRoleGid, uuid }
    }

    const generateRelCode = useGenerateCodeHook()
    const handleTemplateUriSubmit = async (formData: RefTemplateUriFormType, isSend: boolean) => {
        setIsUriExist(false)
        const isExisting = await checkUriIfExist(
            ATTRIBUTE_NAME.Profil_Individuum_zaklad_uri,
            formData.attributes[ATTRIBUTE_NAME.Profil_Individuum_zaklad_uri],
        )
        if (isExisting) {
            return setIsUriExist(true)
        }
        const { ownerRoleGid, uuid } = await createCiItem(formData, isSend)
        const relCode = await generateRelCode(RELATION_TYPE.Individuum_je_typu_DatovyPrvok)
        await createRelations({
            data: {
                type: RELATION_TYPE.Individuum_je_typu_DatovyPrvok,
                startUuid: uuid,
                uuid: uuidV4(),
                endUuid: formData[RefTemplateUriFormTypeEnum.TEMPLATE_URI],
                owner: ownerRoleGid,
                attributes: [{ name: ATTRIBUTE_NAME.Gen_Profil_Rel_kod_metais, value: relCode.code as unknown as AttributeUiValue }],
            },
        })
        if (isSend) {
            await createStandardRequest(uuid, formData, RefIdentifierTypeEnum.Individuum)
        }
    }

    const handleDataItemSubmit = async (formData: RefDataItemFormType, isSend: boolean) => {
        const { ownerRoleGid, uuid } = await createCiItem(formData, isSend)

        const relCodeGestor = await generateRelCode(RELATION_TYPE.PO_je_gestor_DatovyPrvok)
        await createRelations({
            data: {
                type: RELATION_TYPE.PO_je_gestor_DatovyPrvok,
                startUuid: formData[RefDataItemFormTypeEnum.PO],
                uuid: uuidV4(),
                endUuid: uuid,
                owner: ownerRoleGid,
                attributes: [{ name: ATTRIBUTE_NAME.Gen_Profil_Rel_kod_metais, value: relCodeGestor.code as unknown as AttributeUiValue }],
            },
        })

        formData[RefDataItemFormTypeEnum.DATA_ITEM]?.forEach(async (itemId) => {
            const relCodeSklada = await generateRelCode(RELATION_TYPE.DatovyPrvok_sa_sklada_DatovyPrvok)
            createRelations({
                data: {
                    type: RELATION_TYPE.DatovyPrvok_sa_sklada_DatovyPrvok,
                    startUuid: uuid,
                    uuid: uuidV4(),
                    endUuid: itemId,
                    owner: ownerRoleGid,
                    attributes: [{ name: ATTRIBUTE_NAME.Gen_Profil_Rel_kod_metais, value: relCodeSklada.code as unknown as AttributeUiValue }],
                },
            })
        })
        if (isSend) {
            await createStandardRequest(uuid, formData, RefIdentifierTypeEnum.DatovyPrvok)
        }
    }

    const handleOntologySubmit = async (formData: RefOntologyFormType, isSend: boolean) => {
        setIsUriExist(false)
        const isExisting = await checkUriIfExist(
            ATTRIBUTE_NAME.Profil_Ontologia_uri_pre_ontologiu,
            formData.attributes[ATTRIBUTE_NAME.Profil_Ontologia_uri_pre_ontologiu],
        )
        if (isExisting) {
            return setIsUriExist(true)
        }

        const { ownerRoleGid, uuid } = await createCiItem(formData, isSend)

        const relCodeOntologyGestor = await generateRelCode(RELATION_TYPE.PO_je_gestor_Ontologia)
        await createRelations({
            data: {
                type: RELATION_TYPE.PO_je_gestor_Ontologia,
                startUuid: formData[RefOntologyFormTypeEnum.PO],
                uuid: uuidV4(),
                endUuid: uuid,
                owner: ownerRoleGid,
                attributes: [{ name: ATTRIBUTE_NAME.Gen_Profil_Rel_kod_metais, value: relCodeOntologyGestor.code as unknown as AttributeUiValue }],
            },
        })

        if (isSend) {
            await createStandardRequest(uuid, formData, RefIdentifierTypeEnum.Ontologia)
        }
    }

    const handleOntologyVersionSubmit = async (formData: RefOntologyVersionFormType, isSend: boolean) => {
        setIsUriExist(false)
        const isExisting = await checkUriIfExist(
            ATTRIBUTE_NAME.Profil_VerziaOntologie_uri_distribucie,
            formData.attributes[ATTRIBUTE_NAME.Profil_VerziaOntologie_uri_distribucie],
        )
        if (isExisting) {
            return setIsUriExist(true)
        }

        const { ownerRoleGid, uuid } = await createCiItem(
            {
                ...formData,
                [ATTRIBUTE_NAME.Gen_Profil_nazov]: `${
                    ciTypeData?.attributes?.find((attr) => attr.technicalName === ATTRIBUTE_NAME.Gen_Profil_nazov)?.name
                }${formData?.[RefOntologyVersionFormTypeEnum.ONTOLOGY]}`,
            },
            isSend,
        )

        const relCodeOntologyGestor = await generateRelCode(RELATION_TYPE.Ontologia_ma_verziu)
        await createRelations({
            data: {
                type: RELATION_TYPE.Ontologia_ma_verziu,
                startUuid: formData[RefOntologyVersionFormTypeEnum.ONTOLOGY],
                uuid: uuidV4(),
                endUuid: uuid,
                owner: ownerRoleGid,
                attributes: [{ name: ATTRIBUTE_NAME.Gen_Profil_Rel_kod_metais, value: relCodeOntologyGestor.code as unknown as AttributeUiValue }],
            },
        })

        if (isSend) {
            await createStandardRequest(uuid, formData, RefIdentifierTypeEnum.VerziaOntologie)
        }
    }

    const handleCancelRequest = () => {
        navigate(-1)
    }

    return (
        <RefIdentifierCreateView
            ciTypeData={ciTypeData}
            groupData={groupDataFiltered}
            generatedEntityId={generatedEntityId}
            attributes={attributes}
            ownerOptions={ownerOptions}
            templateUriOptions={templateUriOptions}
            dataItemTypeOptions={dataItemTypeOptions}
            type={type}
            ciCode={generatedEntityId?.cicode}
            setType={setType}
            handleCancelRequest={handleCancelRequest}
            handleDataItemSubmit={handleDataItemSubmit}
            handleTemplateUriSubmit={handleTemplateUriSubmit}
            handleOntologySubmit={handleOntologySubmit}
            handleOntologyVersionSubmit={handleOntologyVersionSubmit}
            wrapperRef={wrapperRef}
            isUriExist={isUriExist}
            clearUriExist={() => setIsUriExist(false)}
            isUpdate={false}
            isProcessedError={isProcessedError}
            isRedirectError={isRedirectError || isStoreRelationsError || isStandartsRequestError}
            isRedirectLoading={isRedirectLoading || isStoreRelationsLoading || isCheckUriLoading || isStandartsRequestLoading}
            isStoreError={storeConfigurationItem.error}
            isTooManyFetchesError={isTooManyFetchesError}
            isLoading={isLoading}
            isError={isError}
            regStates={regStates}
            pageType={pageType}
        />
    )
}
