//@ts-nocheck
/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Wiki Integration
 * OpenAPI spec version: latest
 */
import { useQuery } from '@tanstack/react-query'
import type { UseQueryOptions, QueryFunction, UseQueryResult, QueryKey } from '@tanstack/react-query'
import { useWikiSwaggerClient } from '../hooks/useWikiSwaggerClient'
export type UploadProjectDocument1Params = {
    documentUuid?: string
}

export type GetDocumentParams = {
    createIfNotExists?: boolean
    rerenderDynamicContent?: boolean
}

export type UploadProjectDocumentParams = {
    documentUuid?: string
}

export type GetProjectDocumentParams = {
    createIfNotExists?: boolean
    rerenderDynamicContent?: boolean
}

export interface WikiDocument {
    name?: string
    type?: string
    wiki?: string
    xwikiPath?: string
    xwikiUrl?: string
    created?: string
    createdBy?: string
    modified?: string
    modifiedBy?: string
    majorVersion?: number
    minorVersion?: number
}

export type ApiErrorData = { [key: string]: any }

export interface ApiError {
    type?: string
    message?: string
    data?: ApiErrorData
    logToken?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const useGetProjectDocumentHook = () => {
    const getProjectDocument = useWikiSwaggerClient<WikiDocument>()

    return (projectUuid: string, documentId: number, params?: GetProjectDocumentParams, signal?: AbortSignal) => {
        return getProjectDocument({ url: `/project/${projectUuid}/document/${documentId}`, method: 'get', params, signal })
    }
}

export const getGetProjectDocumentQueryKey = (projectUuid: string, documentId: number, params?: GetProjectDocumentParams) =>
    [`/project/${projectUuid}/document/${documentId}`, ...(params ? [params] : [])] as const

export const useGetProjectDocumentQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectDocumentHook>>>, TError = ApiError>(
    projectUuid: string,
    documentId: number,
    params?: GetProjectDocumentParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectDocumentHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectDocumentHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetProjectDocumentQueryKey(projectUuid, documentId, params)

    const getProjectDocument = useGetProjectDocumentHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProjectDocumentHook>>>> = ({ signal }) =>
        getProjectDocument(projectUuid, documentId, params, signal)

    return { queryKey, queryFn, enabled: !!(projectUuid && documentId), ...queryOptions }
}

export type GetProjectDocumentQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetProjectDocumentHook>>>>
export type GetProjectDocumentQueryError = ApiError

export const useGetProjectDocument = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectDocumentHook>>>, TError = ApiError>(
    projectUuid: string,
    documentId: number,
    params?: GetProjectDocumentParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectDocumentHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetProjectDocumentQueryOptions(projectUuid, documentId, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUploadProjectDocumentHook = () => {
    const uploadProjectDocument = useWikiSwaggerClient<string>()

    return (projectUuid: string, documentId: number, params?: UploadProjectDocumentParams, signal?: AbortSignal) => {
        return uploadProjectDocument({ url: `/project/${projectUuid}/document/${documentId}/upload`, method: 'get', params, signal })
    }
}

export const getUploadProjectDocumentQueryKey = (projectUuid: string, documentId: number, params?: UploadProjectDocumentParams) =>
    [`/project/${projectUuid}/document/${documentId}/upload`, ...(params ? [params] : [])] as const

export const useUploadProjectDocumentQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useUploadProjectDocumentHook>>>, TError = ApiError>(
    projectUuid: string,
    documentId: number,
    params?: UploadProjectDocumentParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUploadProjectDocumentHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUploadProjectDocumentHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getUploadProjectDocumentQueryKey(projectUuid, documentId, params)

    const uploadProjectDocument = useUploadProjectDocumentHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useUploadProjectDocumentHook>>>> = ({ signal }) =>
        uploadProjectDocument(projectUuid, documentId, params, signal)

    return { queryKey, queryFn, enabled: !!(projectUuid && documentId), ...queryOptions }
}

export type UploadProjectDocumentQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUploadProjectDocumentHook>>>>
export type UploadProjectDocumentQueryError = ApiError

export const useUploadProjectDocument = <TData = Awaited<ReturnType<ReturnType<typeof useUploadProjectDocumentHook>>>, TError = ApiError>(
    projectUuid: string,
    documentId: number,
    params?: UploadProjectDocumentParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUploadProjectDocumentHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useUploadProjectDocumentQueryOptions(projectUuid, documentId, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetDocumentHook = () => {
    const getDocument = useWikiSwaggerClient<WikiDocument>()

    return (entityType: string, entityId: string, documentType: string, params?: GetDocumentParams, signal?: AbortSignal) => {
        return getDocument({ url: `/document/${entityType}/${entityId}/document/${documentType}`, method: 'get', params, signal })
    }
}

export const getGetDocumentQueryKey = (entityType: string, entityId: string, documentType: string, params?: GetDocumentParams) =>
    [`/document/${entityType}/${entityId}/document/${documentType}`, ...(params ? [params] : [])] as const

export const useGetDocumentQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetDocumentHook>>>, TError = ApiError>(
    entityType: string,
    entityId: string,
    documentType: string,
    params?: GetDocumentParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetDocumentQueryKey(entityType, entityId, documentType, params)

    const getDocument = useGetDocumentHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDocumentHook>>>> = ({ signal }) =>
        getDocument(entityType, entityId, documentType, params, signal)

    return { queryKey, queryFn, enabled: !!(entityType && entityId && documentType), ...queryOptions }
}

export type GetDocumentQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetDocumentHook>>>>
export type GetDocumentQueryError = ApiError

export const useGetDocument = <TData = Awaited<ReturnType<ReturnType<typeof useGetDocumentHook>>>, TError = ApiError>(
    entityType: string,
    entityId: string,
    documentType: string,
    params?: GetDocumentParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetDocumentQueryOptions(entityType, entityId, documentType, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUploadProjectDocument1Hook = () => {
    const uploadProjectDocument1 = useWikiSwaggerClient<string>()

    return (entityType: string, entityId: string, documentType: string, params?: UploadProjectDocument1Params, signal?: AbortSignal) => {
        return uploadProjectDocument1({ url: `/document/${entityType}/${entityId}/document/${documentType}/upload`, method: 'get', params, signal })
    }
}

export const getUploadProjectDocument1QueryKey = (
    entityType: string,
    entityId: string,
    documentType: string,
    params?: UploadProjectDocument1Params,
) => [`/document/${entityType}/${entityId}/document/${documentType}/upload`, ...(params ? [params] : [])] as const

export const useUploadProjectDocument1QueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useUploadProjectDocument1Hook>>>,
    TError = ApiError,
>(
    entityType: string,
    entityId: string,
    documentType: string,
    params?: UploadProjectDocument1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUploadProjectDocument1Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUploadProjectDocument1Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getUploadProjectDocument1QueryKey(entityType, entityId, documentType, params)

    const uploadProjectDocument1 = useUploadProjectDocument1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useUploadProjectDocument1Hook>>>> = ({ signal }) =>
        uploadProjectDocument1(entityType, entityId, documentType, params, signal)

    return { queryKey, queryFn, enabled: !!(entityType && entityId && documentType), ...queryOptions }
}

export type UploadProjectDocument1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUploadProjectDocument1Hook>>>>
export type UploadProjectDocument1QueryError = ApiError

export const useUploadProjectDocument1 = <TData = Awaited<ReturnType<ReturnType<typeof useUploadProjectDocument1Hook>>>, TError = ApiError>(
    entityType: string,
    entityId: string,
    documentType: string,
    params?: UploadProjectDocument1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useUploadProjectDocument1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useUploadProjectDocument1QueryOptions(entityType, entityId, documentType, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}
