import { AbilityContextWithFeedback, useAbilityContext } from '@isdd/metais-common/hooks/permissions/useAbilityContext'
import { useCanCreateRelationTypeUnderOrgAndRole } from '@isdd/metais-common/hooks/permissions/useCanCreateRelationTypeUnderOrgAndRole'
import React, { PropsWithChildren } from 'react'

interface Props extends PropsWithChildren {
    selectedRoleName: string
    rolesToCompareWith: string[]
    isError: boolean
    isLoading: boolean
}

export const RelationTypePermissionWrapper: React.FC<Props> = ({ children, selectedRoleName, rolesToCompareWith, isError, isLoading }) => {
    const ability = useAbilityContext()
    useCanCreateRelationTypeUnderOrgAndRole(selectedRoleName, rolesToCompareWith)
    return <AbilityContextWithFeedback.Provider value={{ ability, isError, isLoading }}>{children}</AbilityContextWithFeedback.Provider>
}
