import React, { PropsWithChildren, ReactNode, forwardRef } from 'react'
import classNames from 'classnames'
import sanitizeHtml from 'sanitize-html'
import { useTranslation } from 'react-i18next'
import { MODAL_TOOLTIP_ZINDEX } from '@isdd/metais-common/constants'

import styles from './infoIcon.module.scss'

import { Tooltip } from '@isdd/idsk-ui-kit/tooltip/Tooltip'

interface IInfoIconWithTextProps extends PropsWithChildren {
    tooltip?: string
    hideIcon?: boolean
    label?: ReactNode
    isInModal?: boolean
}

export const InfoIconWithText = forwardRef<HTMLDivElement, IInfoIconWithTextProps>(
    ({ children, tooltip, hideIcon = false, label, isInModal = false }, ref) => {
        const { t } = useTranslation()

        return (
            <span className={classNames(styles.infoIconGroup)} ref={ref}>
                {!hideIcon && (
                    <span className={classNames(styles.infoIcon)}>
                        <Tooltip
                            altText={label && typeof label === 'string' ? t('tooltip.iconAltText', { text: label }) : ''}
                            contentStyle={isInModal ? { zIndex: MODAL_TOOLTIP_ZINDEX } : undefined}
                            descriptionElement={<div className="tooltipWidth500" dangerouslySetInnerHTML={{ __html: sanitizeHtml(tooltip ?? '') }} />}
                        />
                    </span>
                )}
                {children}
            </span>
        )
    },
)
