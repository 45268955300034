import { split } from 'lodash'

import { OPERATOR_SEPARATOR, RELATION_FILTER_PREFIX } from '@isdd/metais-common/constants'

export const reduceTableDataToObject = <T extends { uuid?: string }>(array: T[]): Record<string, T> => {
    return array.reduce<Record<string, T>>((result, item) => {
        if (item.uuid) {
            result[item.uuid] = item
        }
        return result
    }, {})
}

export const isAttributeRelation = (technicalName: string): boolean => {
    return split(technicalName, OPERATOR_SEPARATOR, 1)[0] === RELATION_FILTER_PREFIX
}
