import classnames from 'classnames'
import React, { useEffect, useId, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { NavMenuSubItem } from './NavMenuSubItem'

import styles from '@isdd/metais-common/components/navbar/navbar.module.scss'
import { NavigationItem } from '@isdd/metais-common/navigation/routeNames'

interface INavMenuItem {
    item: NavigationItem
    closeMenu: () => void
    expandedSubmenu: string | null
    setExpandedSubmenu: React.Dispatch<string | null>
    isAdmin?: boolean
}

export const NavMenuItem: React.FC<INavMenuItem> = ({ item, closeMenu, expandedSubmenu, setExpandedSubmenu, isAdmin }) => {
    const { t } = useTranslation()
    const [expanded, setExpanded] = useState(false)
    const ref = useRef<HTMLLIElement>(null)
    const itemsWrapperId = useId()

    const showMenu = `${t('navMenu.show')} ${item.title} menu`
    const hideMenu = `${t('navMenu.hide')} ${item.title} menu`

    const handleClickOutside = (event: React.PointerEvent<HTMLLIElement>) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setExpanded(false)
        }
    }

    useEffect(() => {
        if (expandedSubmenu !== item.path) {
            setExpanded(false)
        }
    }, [expandedSubmenu, item.path])

    useEffect(() => {
        if (expanded) {
            setExpandedSubmenu(item.path)
        }
    }, [expanded, item.path, setExpandedSubmenu])

    const handleKeyDown = (event: React.KeyboardEvent<HTMLLIElement>) => {
        if (!expanded && event.key === 'Enter' && ref.current && !ref.current?.contains(event.target as Node)) {
            event.stopPropagation()
            setExpanded(false)
        }
        if (expanded && event.key === 'Escape') {
            event.stopPropagation()
            setExpanded(false)
        }
    }

    const getSubItemsWithTopLevelItem = (navItem: NavigationItem) => {
        if (navItem.subItems?.length && navItem.subItems?.length > 0) {
            if (isAdmin || navItem.hideHelp) {
                return [navItem]
            }
            return [{ ...navItem, subItems: [], title: t('navMenu.help') }, ...(navItem.subItems ?? [])]
        } else {
            return []
        }
    }

    const hasSubmenu = item.subItems?.length && item.subItems?.length > 0

    return (
        <>
            <li
                ref={ref}
                onClick={handleClickOutside}
                onKeyDown={handleKeyDown}
                className={classnames('idsk-header-web__nav-list-item', {
                    'idsk-header-web__nav-list-item--active': expanded,
                })}
            >
                <Link
                    to={hasSubmenu ? '#' : item.path}
                    {...(hasSubmenu && { state: { from: location } })}
                    className={classnames('govuk-link idsk-header-web__nav-list-item-link', styles.navListItem)}
                    aria-label={item.subItems?.length ? (expanded ? hideMenu : showMenu) : undefined}
                    aria-expanded={expanded}
                    aria-haspopup="menu"
                    onClick={() => {
                        if (hasSubmenu) {
                            setExpanded((prev) => {
                                if (!prev) {
                                    setExpandedSubmenu(item.path)
                                }
                                return !expanded
                            })
                        } else {
                            closeMenu()
                        }
                    }}
                >
                    {item.title}
                    {hasSubmenu && (
                        <>
                            <div className="idsk-header-web__link-arrow" />
                            <div className="idsk-header-web__link-arrow-mobile" />
                        </>
                    )}
                </Link>
                <div className={classnames('idsk-header-web__nav-submenu')}>
                    <div className="govuk-width-container">
                        <div className="govuk-grid-row">
                            <ul
                                id={itemsWrapperId}
                                className="idsk-header-web__nav-submenu-list"
                                aria-label={t('navMenu.innerNav', { parentTitle: item.title })}
                            >
                                {getSubItemsWithTopLevelItem(item).map((subItem) => (
                                    <NavMenuSubItem
                                        key={subItem.title}
                                        item={subItem}
                                        closeSubmenu={() => {
                                            setExpanded(false)
                                            closeMenu()
                                        }}
                                    />
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </li>
        </>
    )
}
