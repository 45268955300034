import React, { DetailedHTMLProps, forwardRef } from 'react'
import classNames from 'classnames'

import styles from './fieldset.module.scss'

interface IFieldsetProps extends DetailedHTMLProps<React.InputHTMLAttributes<HTMLFieldSetElement>, HTMLFieldSetElement> {
    legend?: string | React.ReactNode
    legendClassName?: string
    hideLegend?: boolean
}

export const Fieldset = forwardRef<HTMLDivElement, IFieldsetProps>(({ children, legend, className, legendClassName, hideLegend }, ref) => {
    return (
        <fieldset className={classNames(styles.fieldset, className)}>
            {legend && <legend className={classNames(legendClassName, { 'govuk-label': !legendClassName, 'sr-only': hideLegend })}>{legend}</legend>}
            <div ref={ref}>{children}</div>
        </fieldset>
    )
})
