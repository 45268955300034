import { useContext, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce'

import { getUserInfo } from '@isdd/metais-common/api/userInfoApi'
import { USER_INFO_QUERY_KEY } from '@isdd/metais-common/constants'
import { CustomAuthActions, useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { isTokenExpired } from '@isdd/metais-common/utils/utils'

export const useUserInfo = () => {
    const {
        state: { user, token },
        dispatch,
    } = useAuth()
    const { logOut, login } = useContext<IAuthContext>(AuthContext)
    const navigate = useNavigate()
    const location = useLocation()

    if (token != null && token.length > 0) {
        if (isTokenExpired(token)) {
            dispatch({ type: CustomAuthActions.LOGOUT_USER })
            logOut()
        }
    }

    const { data, isLoading, isError, error } = useQuery({
        queryKey: [USER_INFO_QUERY_KEY, token],
        queryFn: () => getUserInfo(token || ''),
        enabled: !!token && !user && !isTokenExpired(token),
    })
    const userInfoData = data?.data

    useEffect(() => {
        if (data?.statusCode === 401) {
            logOut()
            login()
        }
    }, [data?.statusCode, logOut, location, navigate, login])

    useEffect(() => {
        if (userInfoData && data?.statusCode === 200) {
            dispatch({ type: CustomAuthActions.SET_USER_INFO, value: userInfoData })
        }
    }, [userInfoData, data?.statusCode, dispatch, data])

    return {
        user,
        isLoading,
        isError,
        error,
    }
}
