import { formatDateForDefaultValue } from '@isdd/metais-common/componentHelpers/formatting/formatDateUtils'
import {
    AS_MA_FAZU_ZIVOTNEHO_CYKLU,
    EA_Profil_ISVS_typ_isvs,
    ENTITY_ISVS,
    ENTITY_KRIS,
    ENTITY_KS,
    ENTITY_OSOBITNY_POSTUP,
    ENTITY_PROJECT,
    ENTITY_TRAINING,
    KS_MA_FAZU_ZIVOTNEHO_CYKLU,
    PAYMENT_TYPE_EKOLKY_1,
    PAYMENT_TYPE_EKOLKY_6,
    PO,
    Profil_Webove_Sidlo_typ_ISVS_APP_WEB,
} from '@isdd/metais-common/constants'
import { TFunction } from 'i18next'
import { DateTime } from 'luxon'
import * as yup from 'yup'

const midNight = new Date(new Date().setHours(0, 0, 0, 0))

interface IRulesProps {
    required?: boolean
    t: TFunction<'translation', undefined, 'translation'>
    currentValue?: string
    nameSuffix?: string
}

interface ISpecialRulesConfig {
    [key: string]: {
        entityName: string[]
        rule:
            | yup.DateSchema<Date | undefined | null, yup.AnyObject, undefined, ''>
            | yup.StringSchema<string | undefined, yup.AnyObject, undefined, ''>
    }
}

export const specialRulesConfig = ({ required, t, currentValue, nameSuffix = '' }: IRulesProps): ISpecialRulesConfig => {
    const isCurrentValueSmallerThanActualDate =
        currentValue && DateTime.fromJSDate(new Date(currentValue)).isValid && new Date(currentValue).getTime() < new Date().getTime() ? true : false
    const currentDateValueToCompare = isCurrentValueSmallerThanActualDate ? currentValue ?? new Date() : new Date()
    const midnightValueToCompare = isCurrentValueSmallerThanActualDate ? currentValue ?? midNight : midNight

    return {
        Profil_KRIS_datum_vypracovania: {
            entityName: [ENTITY_KRIS],
            rule: yup.date().when('isRequired', {
                is: () => required,
                then: () =>
                    yup
                        .date()
                        .required(t('validation.required'))
                        .transform((curr, orig) => (orig === '' ? null : curr))
                        .max(
                            midNight,
                            `${t('validation.dateMustBeLessOrEqualThen')} ${formatDateForDefaultValue(midNight.toString(), 'dd.MM.yyyy')}`,
                        ),
                otherwise: () =>
                    yup
                        .date()
                        .nullable()
                        .transform((curr, orig) => (orig === '' ? null : curr))
                        .max(
                            midNight,
                            `${t('validation.dateMustBeLessOrEqualThen')} ${formatDateForDefaultValue(midNight.toString(), 'dd.MM.yyyy')}`,
                        ),
            }),
        },
        Profil_KRIS_datum_schvalenia: {
            entityName: [ENTITY_KRIS],
            rule: yup.date().when('isRequired', {
                is: () => required,
                then: () =>
                    yup
                        .date()
                        .required(t('validation.required'))
                        .transform((curr, orig) => (orig === '' ? null : curr))
                        .max(
                            midNight,
                            `${t('validation.dateMustBeLessOrEqualThen')} ${formatDateForDefaultValue(midNight.toString(), 'dd.MM.yyyy')}`,
                        ),
                otherwise: () =>
                    yup
                        .date()
                        .nullable()
                        .transform((curr, orig) => (orig === '' ? null : curr))
                        .max(
                            midNight,
                            `${t('validation.dateMustBeLessOrEqualThen')} ${formatDateForDefaultValue(midNight.toString(), 'dd.MM.yyyy')}`,
                        ),
            }),
        },
        KS_Profil_UPVS_kod_spoplatnenia: {
            entityName: [ENTITY_KS, PO],
            rule: yup
                .string()
                .when('KS_Profil_UPVS_typ_spoplatnenia' + nameSuffix, (typeOfCharge, yupSchema) =>
                    typeOfCharge?.[0] === PAYMENT_TYPE_EKOLKY_6 || typeOfCharge?.[0] === PAYMENT_TYPE_EKOLKY_1
                        ? yupSchema.required(t('validation.required'))
                        : yupSchema,
                ),
        },
        KS_Profil_PO_UPVS_kod_sluzby_eKolok: {
            entityName: [ENTITY_KS, PO],
            rule: yup
                .string()
                .when('KS_Profil_PO_UPVS_typ_spoplatnenia' + nameSuffix, (typeOfCharge, yupSchema) =>
                    typeOfCharge?.[0] === PAYMENT_TYPE_EKOLKY_6 || typeOfCharge?.[0] === PAYMENT_TYPE_EKOLKY_1
                        ? yupSchema.required(t('validation.required'))
                        : yupSchema,
                ),
        },
        Profil_Webove_Sidlo_typ_rozhrania: {
            entityName: [ENTITY_ISVS],
            rule: yup
                .string()
                .when(EA_Profil_ISVS_typ_isvs + nameSuffix, (typeOfCharge, yupSchema) =>
                    Profil_Webove_Sidlo_typ_ISVS_APP_WEB.includes(typeOfCharge?.[0]) ? yupSchema.required(t('validation.required')) : yupSchema,
                ),
        },
        Profil_Skolenie_zaciatok: {
            entityName: [ENTITY_TRAINING],
            rule: yup
                .date()
                .typeError(t('validation.required'))
                .required(t('validation.required'))
                .min(
                    currentDateValueToCompare,
                    `${t('validation.dateMustBeEqualOrGreaterThen')} ${formatDateForDefaultValue(
                        currentDateValueToCompare.toString(),
                        'dd.MM.yyyy HH:mm',
                    )}`,
                ),
        },
        Profil_Skolenie_koniec: {
            entityName: [ENTITY_TRAINING],
            rule: yup
                .date()
                .typeError(t('validation.required'))
                .required(t('validation.required'))
                .when('Profil_Skolenie_zaciatok', (trainingFrom, yupSchema) => {
                    return DateTime.fromJSDate(new Date(`${trainingFrom}`)).isValid
                        ? yupSchema.min(trainingFrom, `${t('validation.dateMustBeGreaterThen')} ${t('trainings.from')}`)
                        : yupSchema
                }),
        },
        Profil_Osobitny_Postup_datum_ucinnosti_od: {
            entityName: [ENTITY_OSOBITNY_POSTUP],
            rule: yup
                .date()
                .nullable()
                .transform((curr, orig) => (orig === '' ? null : curr))
                .min(
                    midnightValueToCompare,
                    `${t('validation.dateMustBeEqualOrGreaterThen')} ${formatDateForDefaultValue(midnightValueToCompare.toString(), 'dd.MM.yyyy')}`,
                ),
        },
        Profil_Osobitny_Postup_datum_ucinnosti_do: {
            entityName: [ENTITY_OSOBITNY_POSTUP],
            rule: yup
                .date()
                .nullable()
                .transform((curr, orig) => (orig === '' ? null : curr))
                .when('Profil_Osobitny_Postup_datum_ucinnosti_od', (osobitnyPostupOd, yupSchema) => {
                    return DateTime.fromJSDate(new Date(`${osobitnyPostupOd}`)).isValid
                        ? yupSchema.min(osobitnyPostupOd, `${t('validation.dateMustBeGreaterThen')} ${t('form.specialRule.osobitnyPostupDateFrom')}`)
                        : yupSchema
                }),
        },
        Profil_Rel_FazaZivotnehoCyklu_datum_ukoncenia: {
            entityName: [KS_MA_FAZU_ZIVOTNEHO_CYKLU, AS_MA_FAZU_ZIVOTNEHO_CYKLU],
            rule: yup
                .date()
                .nullable()
                .transform((curr, orig) => (orig === '' ? null : curr))
                .when('Profil_Rel_FazaZivotnehoCyklu_datum_zacatia' + nameSuffix, (FazaZivotnehoCykluOd, yupSchema) => {
                    return DateTime.fromJSDate(new Date(`${FazaZivotnehoCykluOd}`)).isValid
                        ? yupSchema.min(
                              FazaZivotnehoCykluOd,
                              `${t('validation.dateMustBeGreaterThen')} ${t('form.specialRule.fazaZivotnehoCykluOd')}`,
                          )
                        : yupSchema
                }),
        },
        EA_Profil_Projekt_termin_ukoncenia: {
            entityName: [ENTITY_PROJECT],
            rule: yup
                .date()
                .typeError(t('validation.required'))
                .required(t('validation.required'))
                .when('EA_Profil_Projekt_datum_zacatia', (projectStart, yupSchema) => {
                    return DateTime.fromJSDate(new Date(`${projectStart}`)).isValid
                        ? yupSchema.min(projectStart, `${t('validation.dateMustBeGreaterThen')} ${t('form.specialRule.projectImplementationStart')}`)
                        : yupSchema
                }),
        },
    }
}

interface IGetRuleProps {
    technicalName: string
    entityName?: string
    required?: boolean
    t: TFunction<'translation', undefined, 'translation'>
    currentValue?: string
    nameSuffix?: string
}

export const getSpecialRule = ({ technicalName, entityName, required, t, currentValue, nameSuffix }: IGetRuleProps) => {
    if (!entityName) {
        return
    }
    const ruleConfig = specialRulesConfig({ required, t, currentValue, nameSuffix })[technicalName]
    return ruleConfig?.entityName?.includes(entityName) ? ruleConfig.rule : undefined
}
