import {
    EA_Profil_ISVS_typ_isvs,
    ENTITY_ISVS,
    ENTITY_KS,
    PAYMENT_TYPE_EKOLKY_1,
    PAYMENT_TYPE_EKOLKY_6,
    PO,
    Profil_Webove_Sidlo_typ_ISVS_APP_WEB,
} from '@isdd/metais-common/constants'
import { FieldError, FieldErrorsImpl, FieldValues, Merge, UseFormClearErrors, UseFormWatch } from 'react-hook-form'

interface IRulesProps {
    watch: UseFormWatch<FieldValues>
    nameSuffix?: string
}

interface ISpecialRulesConfig {
    [key: string]: {
        entityName: string[]
        required: boolean
    }
}

export const specialRequiredPropertyConfig = ({ watch, nameSuffix }: IRulesProps): ISpecialRulesConfig => {
    return {
        KS_Profil_UPVS_kod_spoplatnenia: {
            entityName: [PO, ENTITY_KS],
            required:
                watch(`KS_Profil_UPVS_typ_spoplatnenia${nameSuffix}`) === PAYMENT_TYPE_EKOLKY_6 ||
                watch(`KS_Profil_UPVS_typ_spoplatnenia${nameSuffix}`) === PAYMENT_TYPE_EKOLKY_1
                    ? true
                    : false,
        },
        KS_Profil_PO_UPVS_kod_sluzby_eKolok: {
            entityName: [PO, ENTITY_KS],
            required:
                watch(`KS_Profil_PO_UPVS_typ_spoplatnenia${nameSuffix}`) === PAYMENT_TYPE_EKOLKY_6 ||
                watch(`KS_Profil_PO_UPVS_typ_spoplatnenia${nameSuffix}`) === PAYMENT_TYPE_EKOLKY_1
                    ? true
                    : false,
        },
        Profil_Webove_Sidlo_typ_rozhrania: {
            entityName: [ENTITY_ISVS],
            required: Profil_Webove_Sidlo_typ_ISVS_APP_WEB.includes(watch(`${EA_Profil_ISVS_typ_isvs}${nameSuffix}`)) ? true : false,
        },
    }
}

interface IGetRuleProps {
    technicalName: string
    entityName?: string
    watch?: UseFormWatch<FieldValues>
    clearErrors?: UseFormClearErrors<FieldValues>
    error?: FieldError | Merge<FieldError, FieldErrorsImpl>
    nameSuffix?: string
}

export const getSpecialRequiredProperty = ({ technicalName, entityName, watch, clearErrors, error, nameSuffix }: IGetRuleProps) => {
    if (!entityName || !watch) {
        return
    }

    const ruleConfig = specialRequiredPropertyConfig({ watch, nameSuffix })[technicalName]
    const foundConfig = ruleConfig?.entityName?.includes(entityName)
    if (foundConfig && error && ruleConfig.required === false) {
        clearErrors?.(technicalName)
    }
    return foundConfig ? ruleConfig.required : undefined
}
