import classNames from 'classnames'
import React, { forwardRef, DetailedHTMLProps } from 'react'
import { FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import styles from './searchInput.module.scss'

import { SearchIcon } from '@isdd/idsk-ui-kit/search-icon/SearchIcon'
interface IInputProps extends DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    id: string
    name: string
    error?: FieldError
    disabled?: boolean
    onSearchButtonClick?: () => void
    isColumnSearch?: boolean
}

export const SearchInput = forwardRef<HTMLInputElement, IInputProps>(
    ({ id, placeholder, className, style, onSearchButtonClick, name, error, disabled, isColumnSearch = false, ...rest }, ref) => {
        const { t } = useTranslation()
        return (
            <div className={classNames('idsk-header-web__main-action-search', styles.searchInputWrapper, className)} style={style}>
                <input
                    className={classNames('govuk-input govuk-!-display-inline-block', styles.searchInput, { 'govuk-input--error': !!error })}
                    title={placeholder || t('searchInput.search').toString()}
                    type="search"
                    aria-label={placeholder || t('searchInput.search').toString()}
                    id={id}
                    name={name}
                    placeholder={placeholder || t('searchInput.search').toString()}
                    ref={ref}
                    {...rest}
                    readOnly={disabled}
                    autoComplete="off"
                />
                <button
                    className="govuk-button"
                    onClick={onSearchButtonClick}
                    type="submit"
                    aria-label={t(isColumnSearch ? 'filter.searchColumnsButtonAria' : 'filter.searchButtonAria')}
                >
                    <span className="govuk-visually-hidden">{t('searchInput.search')}</span>
                    <SearchIcon />
                </button>
            </div>
        )
    },
)
