import { ConfigurationItemSetUi, ConfigurationItemUi, ConfigurationItemUiAttributes } from '@isdd/metais-common/api/generated/cmdb-swagger'
import {
    ConfigurationItemSetUi as ConfigurationItemSetUiDefault,
    ConfigurationItemUi as ConfigurationItemUiDefault,
} from '@isdd/metais-common/api/generated/iam-swagger'

export const ciItemArrayToObject = (ciItem?: ConfigurationItemSetUiDefault): ConfigurationItemSetUi => {
    const configurationItemSet = ciItem?.configurationItemSet?.map((item) => {
        const attributes: ConfigurationItemUiAttributes = {}
        item?.attributes?.forEach((attribute) => {
            const attributeName = attribute.name ?? ''
            attributes[attributeName] = attribute.value
        })
        return { ...item, attributes }
    })

    return { ...ciItem, configurationItemSet }
}

export const ciItemAttributesObjectToArray = (ciItem: ConfigurationItemUi): ConfigurationItemUiDefault => {
    const attributes = Object.entries(ciItem.attributes ?? {}).map(([key, value]) => ({ name: key, value }))
    return { ...ciItem, attributes }
}
