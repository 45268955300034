import { ButtonPopup } from '@isdd/idsk-ui-kit/button-popup/ButtonPopup'
import { Button } from '@isdd/idsk-ui-kit/button/Button'
import { CheckBox } from '@isdd/idsk-ui-kit/checkbox/CheckBox'
import { BaseModal } from '@isdd/idsk-ui-kit/modal/BaseModal'
import { ConfigurationItemUi, ConfigurationItemUiAttributes, useReadCiNeighboursWithAllRels } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { QueryFeedback } from '@isdd/metais-common/components/query-feedback/QueryFeedback'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { useUserPreferences } from '@isdd/metais-common/contexts/userPreferences/userPreferencesContext'
import { useAttributesHook } from '@isdd/metais-common/hooks/useAttributes.hook'
import { useCiHook } from '@isdd/metais-common/hooks/useCi.hook'
import classnames from 'classnames'
import * as d3 from 'd3'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalButtons } from '@isdd/metais-common/index'
import { useListRelatedCiTypesWrapper } from '@isdd/metais-common/hooks/useListRelatedCiTypes.hook'
import { TextBody, TextHeading } from '@isdd/idsk-ui-kit/index'
import { GRAPH_SVG_ID } from '@isdd/metais-common/constants'
import { LeftArrow, SearchMinus, SearchPlus } from '@isdd/metais-common/assets/images'

import styles from './relationshipGraph.module.scss'
import { isRelatedCiTypeCmdbView } from './typeHelper'

import { CiInformationAccordion } from '@/components/entities/accordion/CiInformationAccordion'
import { drawGraph, exportGraph, filterCiName, getShortName, prepareData } from '@/components/views/relationships/graphHelpers'

interface RelationshipsGraphProps {
    data?: ConfigurationItemUi
}

export interface TypeFilter {
    [key: string]: {
        color: string
        label: string
        selected: boolean
    }
}

export interface CiItem extends d3.SimulationNodeDatum {
    uuid: string
    type: string
    name: string
    valid?: boolean
    color?: string
    attributes?: ConfigurationItemUiAttributes
    shortName?: string
    group?: number
    degree?: number
}

export interface RelsItem extends d3.SimulationLinkDatum<CiItem> {
    uuid: string
    type: string
    startUuid: string
    endUuid: string
    source: CiItem | string
    target: CiItem | string
}

const RelationshipGraph: FC<RelationshipsGraphProps> = ({ data: selectedItem }) => {
    const [target, setTarget] = useState<ConfigurationItemUi | undefined>(selectedItem)
    const [prevNodeDetail, setPrevNodeDetail] = useState<CiItem | null>(null)
    const [nodeDetail, setNodeDetail] = useState<CiItem | null>(null)
    const [cannotDisplayAll, setCannotDisplayAll] = useState(false)
    const { t } = useTranslation()
    const {
        state: { user },
    } = useAuth()
    const { currentPreferences } = useUserPreferences()
    const [filterTypes, setFilterTypes] = useState<TypeFilter>({})
    const graphWrapperRef = useRef<HTMLDivElement>(null)
    const { ciItemData, gestorData, isLoading: isCiItemLoading, isError: isCiItemError } = useCiHook(nodeDetail?.uuid)
    const { constraintsData, ciTypeData, unitsData, isLoading: isAttLoading, isError: isAttError } = useAttributesHook(nodeDetail?.type)

    const { isLoading: isLoadingRelated, isError: isErrorRelated, data: relatedTypes } = useListRelatedCiTypesWrapper(target?.type ?? '')
    const types = useMemo(() => (relatedTypes?.cisAsSources || []).concat(relatedTypes?.cisAsTargets || []), [relatedTypes])
    const ciTypes = types
        .filter(function (oneType) {
            return isRelatedCiTypeCmdbView(oneType, !!user)
        })
        .map((type) => type.ciTypeTechnicalName || '')

    const state = currentPreferences.showInvalidatedItems ? ['DRAFT', 'INVALIDATED'] : ['DRAFT']
    const NUMBER_OF_NODES = 40
    const {
        isFetching: isLoadingNeihbours,
        isError: isErrorNeighbours,
        data,
    } = useReadCiNeighboursWithAllRels(target?.uuid ?? '', {
        page: 1,
        perPage: NUMBER_OF_NODES,
        state,
        ciTypes: ciTypes.length === 0 ? ['NOT_EXIST_ENTITY'] : ciTypes,
    })
    const ciType = useMemo(() => {
        const result = data?.ciWithRels?.map((rels) => rels.ci?.type)
        result?.push(target?.type)
        return result
    }, [data?.ciWithRels, target?.type])

    const isLoading = [isLoadingNeihbours, isLoadingRelated].some((item) => item)
    const isModalLoading = [isAttLoading || isCiItemLoading].some((item) => item)
    const isError = isErrorNeighbours || isErrorRelated
    const zoom = useMemo(() => {
        return d3
            .zoom<SVGSVGElement, unknown>()
            .scaleExtent([0, 8])
            .on('zoom', (e) => d3.select(graphWrapperRef.current).select<SVGGElement>('g').attr('transform', e.transform))
    }, [])

    useEffect(() => {
        const result: TypeFilter = {}
        types.forEach((type) => {
            if (ciType?.includes(type.ciTypeTechnicalName) && type.ciTypeTechnicalName && type.ciColor && type.ciTypeName) {
                result[type.ciTypeTechnicalName] = {
                    color: type.ciColor,
                    label: type.ciTypeName,
                    selected: true,
                }
            }
        })
        setFilterTypes(result)
    }, [types, ciType])

    useEffect(() => {
        if (data && target) {
            drawGraph(graphWrapperRef, setNodeDetail, zoom, prepareData(data, target, filterTypes))
        }
    }, [data, filterTypes, t, target, zoom])

    const setFocusOnSVG = (nodeDetail1: CiItem | null) => {
        const elementById = document.getElementById(nodeDetail1?.uuid ?? '')
        elementById?.focus()
    }

    useEffect(() => {
        if (prevNodeDetail) {
            setFocusOnSVG(prevNodeDetail)
        }
    }, [nodeDetail, prevNodeDetail])

    useEffect(() => {
        setCannotDisplayAll(!!(data?.pagination?.totaltems && data?.pagination?.totaltems > 50))
    }, [data?.pagination?.totaltems])

    const onExport = async () => {
        if (!graphWrapperRef.current) return
        await exportGraph(graphWrapperRef.current, `${target?.type}: ${getShortName(target ? filterCiName(target) : '', 350)}`)
    }

    const nodeDetailType = nodeDetail && filterTypes[nodeDetail.type]?.label ? ` (${filterTypes[nodeDetail.type]?.label})` : ''

    return (
        <QueryFeedback loading={isLoading} error={isError}>
            <span className="sr-only">{t('relationshipsTab.relationshipsVisualizationHidden')}</span>
            <div>
                <div className={styles.buttonRow}>
                    <Button
                        label={
                            <>
                                <img src={SearchPlus} alt="" className="govuk-!-margin-right-1" />
                                {t('graph.zoomIn')}
                            </>
                        }
                        onClick={() => {
                            if (graphWrapperRef.current) {
                                const svg = d3.select(graphWrapperRef.current).select<SVGSVGElement>('svg').transition().duration(300)
                                zoom.scaleBy(svg, 1.2)
                            }
                        }}
                        variant="secondary"
                    />
                    <Button
                        label={
                            <>
                                <img src={SearchMinus} alt="" className="govuk-!-margin-right-1" />
                                {t('graph.zoomOut')}
                            </>
                        }
                        onClick={() => {
                            if (graphWrapperRef.current) {
                                const svg = d3.select(graphWrapperRef.current).select<SVGSVGElement>('svg').transition().duration(300)
                                if (svg) zoom.scaleBy(svg, 0.8)
                            }
                        }}
                        variant="secondary"
                    />
                    <Button
                        label={<img src={LeftArrow} alt="" />}
                        onClick={() => {
                            if (graphWrapperRef.current) {
                                const svg = d3.select(graphWrapperRef.current).select<SVGSVGElement>('svg').transition().duration(300)
                                zoom.translateBy(svg, 50, 0)
                            }
                        }}
                        aria-label={t('graph.panLeft')}
                        variant="secondary"
                        className={styles.panButtons}
                    />
                    <Button
                        label={<img src={LeftArrow} className={styles.rotate180} alt="" />}
                        onClick={() => {
                            if (graphWrapperRef.current) {
                                const svg = d3.select(graphWrapperRef.current).select<SVGSVGElement>('svg').transition().duration(300)
                                zoom.translateBy(svg, -50, 0)
                            }
                        }}
                        aria-label={t('graph.panRight')}
                        variant="secondary"
                        className={styles.panButtons}
                    />
                    <Button
                        label={<img src={LeftArrow} className={styles.rotate270} alt="" />}
                        onClick={() => {
                            if (graphWrapperRef.current) {
                                const svg = d3.select(graphWrapperRef.current).select<SVGSVGElement>('svg').transition().duration(300)
                                zoom.translateBy(svg, 0, -50)
                            }
                        }}
                        aria-label={t('graph.panDown')}
                        variant="secondary"
                        className={styles.panButtons}
                    />
                    <Button
                        label={<img src={LeftArrow} className={styles.rotate90} alt="" />}
                        onClick={() => {
                            if (graphWrapperRef.current) {
                                const svg = d3.select(graphWrapperRef.current).select<SVGSVGElement>('svg').transition().duration(300)
                                zoom.translateBy(svg, 0, 50)
                            }
                        }}
                        aria-label={t('graph.panUp')}
                        variant="secondary"
                        className={styles.panButtons}
                    />
                    <Button
                        label={t('graph.center')}
                        onClick={() => {
                            if (graphWrapperRef.current) {
                                const svg = d3.select(graphWrapperRef.current).select<SVGSVGElement>('svg').transition().duration(300)

                                const svgElem = document.getElementById(GRAPH_SVG_ID)
                                const [width, height] = [svgElem?.clientWidth, svgElem?.clientHeight]
                                if (width && height) {
                                    zoom.translateTo(svg, 0.5 * width, 0.5 * height)
                                }
                            }
                        }}
                        variant="secondary"
                    />
                    <Button
                        label={t('graph.resetZoom')}
                        onClick={() => {
                            if (graphWrapperRef.current) {
                                const svg = d3.select(graphWrapperRef.current).select<SVGSVGElement>('svg').transition().duration(300)
                                zoom.scaleTo(svg, 1)
                            }
                        }}
                        variant="secondary"
                    />
                </div>
                {cannotDisplayAll && target ? (
                    <TextBody className={styles.tooManyLinks}>
                        {t('graph.tooManyLinks', { total: data?.pagination?.totaltems, count: NUMBER_OF_NODES, target: filterCiName(target) })}
                    </TextBody>
                ) : (
                    <></>
                )}
            </div>

            <div className={classnames(styles.graphWrapper, 'idsk-graph')}>
                <div className={styles.graphDiv} id="graph-relationship" ref={graphWrapperRef} />
                <div className="idsk-graph__meta">
                    <div className={styles.legendRow}>
                        <div className={classnames(styles.buttonRow, 'idsk-graph__meta-download-share')}>
                            <div>
                                <Button
                                    label={`${t('graph.button.export')} (${t('graph.exportPNG')})`}
                                    variant="secondary"
                                    onClick={() => onExport()}
                                />
                            </div>

                            {Object.keys(filterTypes).length > 0 && (
                                <ButtonPopup
                                    buttonClassName={styles.popupButton}
                                    buttonLabel={t('graph.button.type')}
                                    popupContent={(closePopup) => (
                                        <div className={styles.typeFilter}>
                                            {Object.keys(filterTypes).map((key) => {
                                                const type = filterTypes[key]
                                                return (
                                                    <div key={key} className="govuk-!-margin-bottom-2 govuk-checkboxes govuk-checkboxes--small">
                                                        <CheckBox
                                                            id={key}
                                                            label={type.label}
                                                            name={key}
                                                            checked={type.selected}
                                                            onClick={() => {
                                                                setFilterTypes({
                                                                    ...filterTypes,
                                                                    [key]: {
                                                                        ...type,
                                                                        selected: !type.selected,
                                                                    },
                                                                })
                                                                closePopup()
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}
                                />
                            )}
                        </div>
                        <div className={styles.legend}>
                            {Object.keys(filterTypes).map((key) => {
                                const type = filterTypes[key]
                                return (
                                    <TextBody size="S" key={key} className={styles.textBody}>
                                        <span
                                            style={{ backgroundColor: type.color }}
                                            className={classnames(styles.legendIcon, 'govuk-!-margin-right-1')}
                                        />
                                        {type.label}
                                    </TextBody>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <BaseModal
                isOpen={nodeDetail !== null}
                close={() => {
                    setNodeDetail(null)
                    setPrevNodeDetail(nodeDetail)
                }}
            >
                <div className={styles.modalContent}>
                    {nodeDetail && (
                        <>
                            <TextHeading size="L">
                                {nodeDetail.name}
                                {nodeDetailType}
                            </TextHeading>
                            <Button
                                label={t('relationshipsTab.relationshipsVisualization')}
                                variant="secondary"
                                onClick={() => {
                                    setTarget(nodeDetail)
                                    setNodeDetail(null)
                                    setPrevNodeDetail(nodeDetail)
                                }}
                                className={classnames({ [styles.relationshipsVisualizationButton]: !!ciTypeData?.attributeProfiles?.length })}
                            />
                            <CiInformationAccordion
                                data={{ ciItemData, gestorData, constraintsData, ciTypeData, unitsData }}
                                isError={isAttError || isCiItemError}
                                isLoading={isModalLoading}
                            />
                        </>
                    )}
                </div>
                <ModalButtons
                    onClose={() => {
                        setNodeDetail(null)
                        setPrevNodeDetail(nodeDetail)
                    }}
                />
            </BaseModal>
        </QueryFeedback>
    )
}

export default RelationshipGraph
