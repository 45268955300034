import { useMemo, useState } from 'react'
import { BreadCrumbs, Button, ButtonGroupRow, HomeIcon, TextHeading } from '@isdd/idsk-ui-kit/index'
import { useTranslation } from 'react-i18next'
import { FooterRouteNames } from '@isdd/metais-common/navigation/routeNames'
import { InformationGridRow } from '@isdd/metais-common/components/info-grid-row/InformationGridRow'
import { API_MODULES, API_MODULES_DEV } from '@isdd/metais-common/constants'

import { MainContentWrapper } from '@/components/MainContentWrapper'
import { useApiModulesInformation } from '@/hooks/useApiModuleInformation.hook'
import ApiModuleInformation from '@/components/api-module-information/apiModuleInformation'

const versionFormatter = (version: string): string => {
    return version
        .split(/[.-]/)
        .map((n) => n.padStart(8, '0'))
        .join('.')
}

const AboutApplicationPage = () => {
    const { t } = useTranslation()
    const apiModules = import.meta.env.VITE_ENVIRONMENT === 'DEV' ? API_MODULES_DEV : API_MODULES
    const [showAll, setShowAll] = useState(false)

    const modulesUrl = useMemo(() => apiModules.map((mod) => mod.url), [apiModules])
    const { data: apiModulesData, loadingCount } = useApiModulesInformation(modulesUrl)
    const [isCopyDone, setIsCopyDone] = useState(true)

    const mainRelease = useMemo(() => {
        if (!apiModulesData) {
            return
        }
        const releases = apiModulesData
            .filter((apiInfo) => !apiInfo.isLoading && apiInfo.data)
            .map((apiInfo) => ({
                appVersion: apiInfo.data?.app?.version || '',
                chartVersion: apiInfo.data?.chart?.version || '',
            }))
            .sort((a, b) => versionFormatter(b.chartVersion).localeCompare(versionFormatter(a.chartVersion)))
        if (releases[0]) {
            return releases[0]
        }
        return undefined
    }, [apiModulesData])

    const copyToClipboard = async () => {
        setIsCopyDone(false)
        const text =
            `Verzia portal FE.${import.meta.env.VITE_APP_VERSION}\n` +
            apiModulesData
                ?.map(
                    (apiModuleData, index) =>
                        `Verzia ${apiModules[index]?.module} ${apiModuleData.data?.app?.version} (${apiModuleData.data?.chart?.version})`,
                )
                .join('\n')
        await navigator.clipboard.writeText(text || 'undefined')
        setTimeout(() => {
            setIsCopyDone(true)
        }, 1000)
    }

    return (
        <>
            <BreadCrumbs
                withWidthContainer
                links={[
                    { label: t('breadcrumbs.home'), href: '/', icon: HomeIcon },
                    { label: t('footer.aboutApplication'), href: FooterRouteNames.COOKIES },
                ]}
            />
            <MainContentWrapper>
                <TextHeading size="XL">{t('aboutApp.heading')}</TextHeading>
                <InformationGridRow label={t('aboutApp.portalRelease')} value={'FE.' + import.meta.env.VITE_APP_VERSION} hideIcon />
                <InformationGridRow
                    label={t('aboutApp.backendRelease')}
                    value={'BE.' + ((mainRelease && mainRelease.chartVersion) || t('aboutApp.undefinedVersion'))}
                    hideIcon
                />
                <ButtonGroupRow>
                    <Button
                        variant="secondary"
                        type="button"
                        onClick={() => setShowAll((prev) => !prev)}
                        label={showAll ? t('aboutApp.hide') : t('aboutApp.show')}
                        aria-expanded={showAll}
                    />
                    {showAll && apiModules && (
                        <Button
                            variant="secondary"
                            type="button"
                            onClick={copyToClipboard}
                            label={t('aboutApp.copyToClipboard')}
                            disabled={!isCopyDone || loadingCount > 0}
                        />
                    )}
                </ButtonGroupRow>
                {showAll &&
                    apiModulesData &&
                    apiModulesData.map((apiModuleData, index) => {
                        return (
                            apiModuleData && (
                                <ApiModuleInformation
                                    moduleName={apiModules[index]?.module}
                                    isLoading={apiModuleData.isLoading}
                                    data={apiModuleData.data}
                                    key={`apiModule_${apiModules[index]?.module}_${index}`}
                                />
                            )
                        )
                    })}
            </MainContentWrapper>
        </>
    )
}

export default AboutApplicationPage
