import React from 'react'

export const SystemStatusCloseIcon = (isLight: boolean) => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.2987 0.710215C12.9087 0.320215 12.2787 0.320215 11.8887 0.710215L6.99875 5.59022L2.10875 0.700215C1.71875 0.310215 1.08875 0.310215 0.69875 0.700215C0.30875 1.09021 0.30875 1.72022 0.69875 2.11022L5.58875 7.00022L0.69875 11.8902C0.30875 12.2802 0.30875 12.9102 0.69875 13.3002C1.08875 13.6902 1.71875 13.6902 2.10875 13.3002L6.99875 8.41021L11.8887 13.3002C12.2787 13.6902 12.9087 13.6902 13.2987 13.3002C13.6887 12.9102 13.6887 12.2802 13.2987 11.8902L8.40875 7.00022L13.2987 2.11022C13.6787 1.73022 13.6787 1.09022 13.2987 0.710215Z"
                fill={isLight ? '#ffffff' : '#0b0c0c'}
            />
        </svg>
    )
}
