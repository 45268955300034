import { BreadCrumbs, HomeIcon, TextHeading } from '@isdd/idsk-ui-kit/index'
import { ElementToScrollTo } from '@isdd/metais-common/components/element-to-scroll-to/ElementToScrollTo'
import { REFERENCE_REGISTER } from '@isdd/metais-common/constants'
import { MutationFeedback, QueryFeedback } from '@isdd/metais-common/index'
import { RouteNames } from '@isdd/metais-common/navigation/routeNames'
import { EDIT_CONTACT } from '@isdd/metais-common/navigation/searchKeys'
import { setDocumentTitle } from '@isdd/metais-common/utils/utils'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'

import { MainContentWrapper } from '@/components/MainContentWrapper'
import { CreateRefRegisterContainer } from '@/components/containers/refregisters/CreateRefRegisterContainer'
import { RefRegisterCreateView } from '@/components/views/refregisters/createView/RefRegisterCreateView'

const RefRegistersEdit = () => {
    const [urlParams] = useSearchParams()
    const isContact = urlParams.get(EDIT_CONTACT) === 'true' ? true : false

    const { entityId } = useParams()
    const entityName = REFERENCE_REGISTER
    const { t } = useTranslation()
    setDocumentTitle(t('breadcrumbs.refRegistersEdit'))

    return (
        <CreateRefRegisterContainer
            entityName={entityName}
            entityId={entityId}
            View={({
                userGroupData,
                isLoading: userGroupDataIsLoading,
                isError: userGroupDataIsError,
                POData,
                guiAttributes,
                referenceRegisterData,
                renamedAttributes,
                updateRefRegister,
                updateAccessData,
                updateContact,
                isTooManyFetchesError,
                isProcessedError,
            }) => (
                <>
                    <BreadCrumbs
                        withWidthContainer
                        links={[
                            { label: t('breadcrumbs.home'), href: RouteNames.HOME, icon: HomeIcon },
                            { label: t('breadcrumbs.dataObjects'), href: RouteNames.HOW_TO_DATA_OBJECTS },
                            { label: t('breadcrumbs.refRegisters'), href: RouteNames.REFERENCE_REGISTERS },
                            {
                                label: referenceRegisterData?.name ?? t('breadcrumbs.noName'),
                                href: `${RouteNames.REFERENCE_REGISTERS}/${entityId}`,
                            },
                            {
                                label: `${t('breadcrumbs.refRegistersEdit')}`,
                                href: isContact
                                    ? `${RouteNames.REFERENCE_REGISTERS}/${entityId}/edit?${EDIT_CONTACT}=true`
                                    : `${RouteNames.REFERENCE_REGISTERS}/${entityId}/edit`,
                            },
                        ]}
                    />
                    <MainContentWrapper>
                        <TextHeading size="XL">{t('refRegisters.edit.title')}</TextHeading>
                        <ElementToScrollTo trigger={userGroupDataIsError || isTooManyFetchesError || isProcessedError} manualScroll>
                            <MutationFeedback mutationTooLong={isTooManyFetchesError} mutationProcessingError={isProcessedError} />
                            <QueryFeedback loading={userGroupDataIsLoading} error={userGroupDataIsError} />
                        </ElementToScrollTo>
                        <RefRegisterCreateView
                            defaultData={referenceRegisterData}
                            userGroupData={userGroupData}
                            POData={POData}
                            updateRefRegister={updateRefRegister}
                            updateAccessData={updateAccessData}
                            updateContact={updateContact}
                            renamedAttributes={[...(renamedAttributes ?? []), ...(guiAttributes ?? [])]}
                            isLoading={userGroupDataIsLoading}
                        />
                    </MainContentWrapper>
                </>
            )}
        />
    )
}

export default RefRegistersEdit
