import { BreadCrumbs, HomeIcon, TextBody, TextHeading } from '@isdd/idsk-ui-kit/index'
import { FooterRouteNames } from '@isdd/metais-common/navigation/routeNames'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { MainContentWrapper } from '@/components/MainContentWrapper'

export const TechnicalOperator = () => {
    const { t } = useTranslation()
    const WEB_MAIL = 'web@mirri.gov.sk'

    return (
        <>
            <BreadCrumbs
                withWidthContainer
                links={[
                    { label: t('breadcrumbs.home'), href: '/', icon: HomeIcon },
                    { label: t('footer.technicalOperator'), href: FooterRouteNames.TECHNICAL_OPERATOR },
                ]}
            />
            <MainContentWrapper>
                <TextHeading size="XL">{t('footer.technicalOperator')}</TextHeading>
                <TextBody>
                    <Trans i18nKey={`technicalOperator.body`} components={[<Link key="link" to={`mailto:${WEB_MAIL}`} className="govuk-link" />]} />
                </TextBody>
                <TextBody>
                    <Trans i18nKey={`technicalOperator.phone`} components={[<Link key="phone" to="tel:+421220928215" className="govuk-link" />]} />
                </TextBody>
                <br />
                <TextBody>{t('technicalOperator.inCaseOfProblem')}</TextBody>
                <ul>
                    <li>
                        <TextBody size="S">{t('technicalOperator.problem.browser')}</TextBody>
                    </li>
                    <li>
                        <TextBody size="S">{t('technicalOperator.problem.date')}</TextBody>
                    </li>
                    <li>
                        <TextBody size="S">{t('technicalOperator.problem.login')}</TextBody>
                    </li>
                    <li>
                        <TextBody size="S">{t('technicalOperator.problem.description')}</TextBody>
                    </li>
                    <li>
                        <TextBody size="S">{t('technicalOperator.problem.screenshot')}</TextBody>
                    </li>
                </ul>
            </MainContentWrapper>
        </>
    )
}
