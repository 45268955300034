import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ELASTIC_MAX_PAGES } from '@isdd/metais-common/constants'

import { DotsIcon, PaginatorEndArrowIcon, PaginatorLeftArrowIcon, PaginatorRightArrowIcon, PaginatorStartArrowIcon } from '../assets/images'

import styles from './paginator.module.scss'
import { computePageModel } from './paginatorModel'

import { Button } from '@isdd/idsk-ui-kit/button/Button'

type PaginatorProps = {
    pageNumber: number
    pageSize: number
    dataLength: number
    totalPages?: number
    onPageChanged: (pageNumber: number) => void
}

/**
 * Component for showing paginator.
 *
 * @param pageNumber starts from 1 to Math.ceil(dataLength / pageSize)
 */
export const Paginator: React.FC<PaginatorProps> = ({ pageNumber, pageSize, dataLength, totalPages, onPageChanged }) => {
    const { t } = useTranslation()
    const pageNumberInt = Number(pageNumber)
    const pageSizeInt = Number(pageSize)
    const calculatedTotalPages = Math.ceil(dataLength / pageSizeInt)

    const getTotalPages = (): number => {
        if (totalPages && totalPages > 0) {
            return totalPages
        }
        return Math.min(calculatedTotalPages, ELASTIC_MAX_PAGES)
    }

    const totalPageCount = getTotalPages()

    const pages = useMemo(() => {
        return computePageModel(totalPageCount, pageNumberInt)
    }, [pageNumberInt, totalPageCount])

    const selectPage = (page: number) => {
        if (page < 1 || page > totalPageCount) {
            return
        }
        onPageChanged(page)
    }

    const jumpToFirstPage = () => selectPage(1)
    const jumpToNextPage = () => selectPage(pageNumberInt + 1)
    const jumpToPreviousPage = () => selectPage(pageNumberInt - 1)
    const jumpToLastPage = () => selectPage(totalPageCount)
    const hidePaginator = totalPageCount < 2

    return (
        <nav
            className={classNames(styles.pagination, { [styles.hidden]: hidePaginator })}
            role="navigation"
            aria-label={t('table.pagination') ?? undefined}
        >
            <Button
                variant="secondary"
                label={<img src={PaginatorStartArrowIcon} alt="" />}
                aria-label={t('pagination.toFirst')}
                onClick={jumpToFirstPage}
                className={styles.paginatorStepButton}
                disabled={pageNumberInt === 1}
            />
            <Button
                variant="secondary"
                label={<img src={PaginatorLeftArrowIcon} alt="" />}
                aria-label={t('pagination.toPrev')}
                onClick={jumpToPreviousPage}
                className={styles.paginatorStepButton}
                disabled={pageNumberInt === 1}
            />
            <Button
                onClick={jumpToFirstPage}
                className={classNames({ [styles.selectedButton]: pageNumberInt === 1 })}
                aria-current={pageNumberInt === 1 ? 'page' : undefined}
                aria-label={t('pagination.page', { index: 1 })}
                variant="secondary"
                label={1}
            />
            {pages.leftDots && <img src={DotsIcon} alt={t('pagination.dots')} className={styles.paginatorItem} />}
            {pages.range.map((page) => (
                <Button
                    key={page}
                    variant="secondary"
                    label={page}
                    onClick={() => selectPage(page)}
                    className={classNames({ [styles.selectedButton]: pageNumberInt === page })}
                    aria-current={pageNumberInt === page ? 'page' : undefined}
                    aria-label={t('pagination.page', { index: page })}
                />
            ))}
            {pages.rightDots && <img src={DotsIcon} alt={t('pagination.dots')} className={styles.paginatorItem} />}
            <Button
                onClick={jumpToLastPage}
                className={classNames({ [styles.selectedButton]: pageNumberInt === totalPageCount })}
                variant="secondary"
                label={totalPageCount}
                aria-current={pageNumberInt === totalPageCount ? 'page' : undefined}
                aria-label={t('pagination.page', { index: totalPageCount })}
            />
            <Button
                variant="secondary"
                label={<img src={PaginatorRightArrowIcon} alt="" />}
                aria-label={t('pagination.toNext')}
                onClick={jumpToNextPage}
                className={styles.paginatorStepButton}
                disabled={pageNumberInt === totalPageCount}
            />
            <Button
                variant="secondary"
                label={<img src={PaginatorEndArrowIcon} alt="" />}
                aria-label={t('pagination.toLast')}
                onClick={jumpToLastPage}
                className={styles.paginatorStepButton}
                disabled={pageNumberInt === totalPageCount}
            />
        </nav>
    )
}
