import { BaseModal, Button, getListCellValue, Input, IOption, SimpleSelect, Table, TableHandle, TextHeading } from '@isdd/idsk-ui-kit/index'
import { RELATION_TYPE, ATTRIBUTE_PROFILE_NAME, ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import {
    AttributeUi,
    AttributeUiValue,
    RelationshipUi,
    RelTypeFilterUi,
    useGetRoleParticipantBulk,
    useReadCiList1,
    useReadCiNeighboursWithAllRels,
    useStoreRelationship,
} from '@isdd/metais-common/api/generated/cmdb-swagger'
import { useGetEnum } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { useGenerateCodeHook, useGetRelationshipType } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { TYP_OPRAVNENIA, DRAFT, ENTITY_OBJEKT_EVIDENCIE, ENTITY_ATTRIBUT_OBJEKTU_EVIDENCIE, PO } from '@isdd/metais-common/constants'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CiLazySelect } from '@isdd/metais-common/components/ci-lazy-select/CiLazySelect'
import { v4 } from 'uuid'
import { ModalButtons, MutationFeedback, QueryFeedback } from '@isdd/metais-common/index'
import { useGetStatus } from '@isdd/metais-common/hooks/useGetRequestStatus'
import { yupResolver } from '@hookform/resolvers/yup'
import { array, object, string } from 'yup'
import { TFunction } from 'i18next'
import classNames from 'classnames'
import { SortType } from '@isdd/idsk-ui-kit/types'
import { SelectPublicAuthorityAndRole } from '@isdd/metais-common/common/SelectPublicAuthorityAndRole'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import { filter, find, flatMap, flattenDeep } from 'lodash'
import { useEffect, useRef, useState } from 'react'

import styles from '@/components/views/ci/evidence-object/styles.module.scss'
import { getAttribute } from '@/componentHelpers/ci/evidence-object'
import { useCiCreateEditOnStatusSuccess } from '@/components/create-entity/createEntityHelpers'
import { usePublicAuthorityAndRoleHook } from '@/hooks/usePublicAuthorityAndRole.hook'

interface RelationWithAttrObj extends RelationshipUi {
    attributesObj: { [key: string]: string }
}

type ConsumentRelFormProps = {
    objectEvidenceId: string
    onClose: () => void
}
type ConsumentForm = {
    Profil_OEOpravnenie_typ_opravnenia: string
    Profil_OEOpravnenie_pravny_ucel?: string
    Profil_OEOpravnenie_pravny_zaklad?: string
    Profil_OEOpravnenie_pravny_zaklad_kod?: string
    Profil_OEOpravnenie_atribut_objektu_evidencie: string[]
}

const getSchema = (t: TFunction) => {
    const schema = object().shape({
        Profil_OEOpravnenie_typ_opravnenia: string().required(t('validation.required')),
        Profil_OEOpravnenie_pravny_ucel: string(),
        Profil_OEOpravnenie_pravny_zaklad: string(),
        Profil_OEOpravnenie_pravny_zaklad_kod: string(),
        Profil_OEOpravnenie_atribut_objektu_evidencie: array().of(string().required()).required(t('validation.required')),
    })
    return schema
}

export const ConsumentRelForm: React.FC<ConsumentRelFormProps> = ({ objectEvidenceId, onClose }) => {
    const { t } = useTranslation()
    const tableHandle = useRef<TableHandle>(null)

    const [showForm, setShowForm] = useState(false)
    const [dataToUpdate, setDataToUpdate] = useState<RelationWithAttrObj>()

    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm({ resolver: yupResolver(getSchema(t)) })

    const {
        groupData,
        publicAuthorityState,
        roleState,
        isError: publicAuthAndRoleError,
        isLoading: publicAuthAndRoleLoading,
    } = usePublicAuthorityAndRoleHook()

    const {
        data: gestorData,
        isFetching: isGestorFetching,
        isError: isGestorError,
        fetchStatus: gestorFetchStatus,
    } = useGetRoleParticipantBulk({ gids: [dataToUpdate?.metaAttributes?.owner ?? ''] }, { query: { enabled: !!dataToUpdate } })

    useEffect(() => {
        if (dataToUpdate) {
            publicAuthorityState.setSelectedPublicAuthority({
                poUUID: gestorData?.[0].owner,
                poName: gestorData?.[0].configurationItemUi?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov],
            })
            roleState.setSelectedRole({
                roleUuid: gestorData?.[0].role?.uuid,
                roleName: gestorData?.[0].role?.name,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gestorData, groupData])

    const relTypeFiltersForAOERels: RelTypeFilterUi[] = [
        {
            relType: RELATION_TYPE.Objekt_evidencie_ma_atribut_evidencie,
            relCiUuids: [objectEvidenceId],
            onlyValidRel: true,
        },
    ]
    const {
        data: aoeCilistData,
        isLoading: isCilistLoading,
        isError: isCilistError,
    } = useReadCiList1({
        page: 1,
        perpage: 20,
        sortBy: ATTRIBUTE_NAME.Gen_Profil_nazov,
        sortType: SortType.ASC,
        filter: {
            type: [ENTITY_ATTRIBUT_OBJEKTU_EVIDENCIE],
            searchFields: [ATTRIBUTE_NAME.Gen_Profil_nazov],
            relTypeFilters: relTypeFiltersForAOERels,
            metaAttributes: { state: [DRAFT] },
        },
    })
    const hasRelationsOnAEO = aoeCilistData?.configurationItemSet && aoeCilistData?.configurationItemSet?.length > 0
    const {
        data: defaultRelData,
        isError: isDefaultDataError,
        isLoading: isDefaultDataLoading,
    } = useReadCiNeighboursWithAllRels(objectEvidenceId, {
        relTypes: [RELATION_TYPE.PO_ma_opravnenie],
    })

    const tableData = filter(
        flatMap(defaultRelData?.ciWithRels, (ciWithRel) => ciWithRel.rels),
        (rel: RelationshipUi) => rel.type === RELATION_TYPE.PO_ma_opravnenie,
    ) as RelationshipUi[]

    const {
        data: POsToMap,
        isLoading: isPOsLoading,
        isError: isPOsError,
    } = useReadCiList1(
        {
            filter: {
                type: [PO],
                uuid: tableData.map((tableItem) => tableItem.startUuid ?? ''),
            },
        },
        {
            query: {
                enabled: !!tableData,
                select: (data) => {
                    const mappedPONames: { [key: string]: string } = {}
                    data.configurationItemSet?.forEach((ci) => {
                        mappedPONames[ci.uuid ?? ''] = ci.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov]
                    })
                    return mappedPONames
                },
            },
        },
    )

    useEffect(() => {
        tableHandle.current?.refreshColumns()
    }, [tableData])

    const {
        data: AOEsToMap,
        isLoading: isAOEsLoading,
        isError: isAOEsError,
    } = useReadCiList1(
        {
            filter: {
                type: [ENTITY_ATTRIBUT_OBJEKTU_EVIDENCIE],
                uuid: flattenDeep(
                    tableData.map((tableItem) => {
                        const aoeUuids = find(
                            tableItem.attributes,
                            (attr) => attr.name === ATTRIBUTE_NAME.Profil_OEOpravnenie_atribut_objektu_evidencie,
                        )?.value as string[]
                        return aoeUuids
                    }),
                ),
            },
        },
        {
            query: {
                enabled: !!tableData,
                select: (data) => {
                    const mappedAOENames: { [key: string]: string } = {}
                    data.configurationItemSet?.forEach((ci) => {
                        mappedAOENames[ci.uuid ?? ''] = ci.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov]
                    })
                    return mappedAOENames
                },
            },
        },
    )

    const {
        data: poMaOpravnenieSchema,
        isLoading: isRelSchemaLoading,
        isError: isRelSchemaError,
    } = useGetRelationshipType(RELATION_TYPE.PO_ma_opravnenie)

    const { isLoading: isRightsLoading, isError: isRightsError, data: rightsData } = useGetEnum(TYP_OPRAVNENIA)

    const poOEAttributes =
        poMaOpravnenieSchema?.attributeProfiles?.find((prof) => prof.technicalName == ATTRIBUTE_PROFILE_NAME.Profil_OEOpravnenie)?.attributes ?? []

    const rightsOptions: IOption<string>[] = rightsData?.enumItems?.map((i) => ({ label: i.value ?? '', value: i.code ?? '' })) ?? []

    const generateRelCode = useGenerateCodeHook()
    const onStatusSuccess = useCiCreateEditOnStatusSuccess()
    const { isError: isRedirectError, isLoading: isRedirectLoading, isProcessedError, getRequestStatus, isTooManyFetchesError } = useGetStatus()
    const {
        mutateAsync: storeRel,
        isLoading: isStoreRelationLoading,
        isError: isStoreRelationError,
    } = useStoreRelationship({
        mutation: {
            onSuccess: (data) => {
                getRequestStatus(data.requestId ?? '', () => {
                    onStatusSuccess({ configurationItemId: objectEvidenceId, entityName: ENTITY_OBJEKT_EVIDENCIE, isUpdate: false })
                    onClose()
                })
            },
        },
    })

    const onSubmit = async (formData: ConsumentForm) => {
        const ownerPO = publicAuthorityState.selectedPublicAuthority?.poUUID
        const ownerRole = roleState.selectedRole?.roleUuid

        const mappedAttributes: { name: string; value: AttributeUiValue }[] = Object.keys(formData).map((key) => ({
            name: key,
            value: formData[key as keyof ConsumentForm] as AttributeUiValue,
        }))

        const code = await generateRelCode(RELATION_TYPE.PO_ma_opravnenie)
        await storeRel({
            data: {
                type: RELATION_TYPE.PO_ma_opravnenie,
                uuid: dataToUpdate ? dataToUpdate.uuid : v4(),
                startUuid: ownerPO ?? '',
                endUuid: objectEvidenceId,
                owner: `${ownerRole}-${ownerPO}`,
                attributes: [
                    ...mappedAttributes,
                    {
                        name: ATTRIBUTE_NAME.Gen_Profil_Rel_kod_metais,
                        value: (dataToUpdate
                            ? dataToUpdate.attributesObj[ATTRIBUTE_NAME.Gen_Profil_Rel_kod_metais]
                            : code.code) as unknown as AttributeUiValue,
                    },
                ],
            },
        })
    }

    const isLoading =
        isRelSchemaLoading ||
        isRightsLoading ||
        isStoreRelationLoading ||
        isDefaultDataLoading ||
        isCilistLoading ||
        publicAuthAndRoleLoading ||
        (isGestorFetching && gestorFetchStatus != 'idle')
    const isError = isRelSchemaError || isRightsError || isDefaultDataError || isCilistError || publicAuthAndRoleError || isGestorError

    const colums: ColumnDef<RelationshipUi>[] = [
        {
            id: 'consument',
            header: t('evidenceObject.consument.po'),
            cell: (row) => POsToMap?.[row.row.original?.startUuid ?? ''],
        },
        {
            id: ATTRIBUTE_NAME.Profil_OEOpravnenie_atribut_objektu_evidencie,
            header: getAttribute(poOEAttributes, ATTRIBUTE_NAME.Profil_OEOpravnenie_atribut_objektu_evidencie)?.name ?? '',
            cell: (row) => {
                const values = row.row.original?.attributes?.find((atr) => atr.name === ATTRIBUTE_NAME.Profil_OEOpravnenie_atribut_objektu_evidencie)
                    ?.value as string[]
                return getListCellValue(values.map((v) => AOEsToMap?.[v] ?? ''))
            },
        },
        {
            id: ATTRIBUTE_NAME.Profil_OEOpravnenie_typ_opravnenia,
            header: getAttribute(poOEAttributes, ATTRIBUTE_NAME.Profil_OEOpravnenie_typ_opravnenia)?.name ?? '',
            cell: (row) => {
                return find(
                    rightsOptions,
                    (r: IOption<string>) =>
                        r.value ==
                        (find(row.row.original.attributes, (attr) => attr.name === ATTRIBUTE_NAME.Profil_OEOpravnenie_typ_opravnenia)?.value ?? ''),
                )?.label
            },
        },
        {
            accessorFn: (row) => row?.attributes?.find((atr) => atr.name === ATTRIBUTE_NAME.Profil_OEOpravnenie_pravny_ucel)?.value,
            id: ATTRIBUTE_NAME.Profil_OEOpravnenie_pravny_ucel,
            header: getAttribute(poOEAttributes, ATTRIBUTE_NAME.Profil_OEOpravnenie_pravny_ucel)?.name ?? '',
            cell: (ctx) => ctx?.getValue?.() as string,
            meta: { getCellContext: (ctx: CellContext<RelationshipUi, unknown>) => ctx?.getValue?.() },
        },
        {
            accessorFn: (row) => row?.attributes?.find((atr) => atr.name === ATTRIBUTE_NAME.Profil_OEOpravnenie_pravny_zaklad)?.value,
            id: ATTRIBUTE_NAME.Profil_OEOpravnenie_pravny_zaklad,
            header: getAttribute(poOEAttributes, ATTRIBUTE_NAME.Profil_OEOpravnenie_pravny_zaklad)?.name ?? '',
            cell: (ctx) => ctx?.getValue?.() as string,
            meta: { getCellContext: (ctx: CellContext<RelationshipUi, unknown>) => ctx?.getValue?.() },
        },
        {
            accessorFn: (row) => row?.attributes?.find((atr) => atr.name === ATTRIBUTE_NAME.Profil_OEOpravnenie_pravny_zaklad_kod)?.value,
            id: ATTRIBUTE_NAME.Profil_OEOpravnenie_pravny_zaklad_kod,
            header: getAttribute(poOEAttributes, ATTRIBUTE_NAME.Profil_OEOpravnenie_pravny_zaklad_kod)?.name ?? '',
            cell: (ctx) => ctx?.getValue?.() as string,
            meta: { getCellContext: (ctx: CellContext<RelationshipUi, unknown>) => ctx?.getValue?.() },
        },
        {
            id: 'actions',
            header: t('documentsTab.table.actions'),
            cell: (row) => (
                <Button
                    label={t('ciType.editButton')}
                    className={'idsk-button'}
                    onClick={() => {
                        const attributes = Object.fromEntries(
                            row.row.original?.attributes?.map((attribute: AttributeUi) => [attribute.name, attribute.value]) ?? [],
                        )
                        setDataToUpdate({ ...row.row.original, attributesObj: attributes })
                        reset({
                            Profil_OEOpravnenie_typ_opravnenia: attributes['Profil_OEOpravnenie_typ_opravnenia'],
                            Profil_OEOpravnenie_pravny_ucel: attributes['Profil_OEOpravnenie_pravny_ucel'],
                            Profil_OEOpravnenie_pravny_zaklad: attributes['Profil_OEOpravnenie_pravny_zaklad'],
                            Profil_OEOpravnenie_pravny_zaklad_kod: attributes['Profil_OEOpravnenie_pravny_zaklad_kod'],
                            Profil_OEOpravnenie_atribut_objektu_evidencie: attributes['Profil_OEOpravnenie_atribut_objektu_evidencie'],
                        })
                        setShowForm(true)
                    }}
                />
            ),
        },
    ]

    return !showForm ? (
        <>
            <Button
                label={`+ ${t('evidenceObject.consument.addNew')}`}
                className={'idsk-button'}
                onClick={() => {
                    reset({
                        Profil_OEOpravnenie_typ_opravnenia: getAttribute(poOEAttributes, ATTRIBUTE_NAME.Profil_OEOpravnenie_typ_opravnenia)
                            ?.defaultValue,
                    })
                    setShowForm(true)
                }}
            />
            <Table
                handleRef={tableHandle}
                isInModal
                columns={colums}
                data={tableData}
                isLoading={isDefaultDataLoading || isAOEsLoading || isPOsLoading}
                error={isDefaultDataError || isAOEsError || isPOsError}
            />
            <ModalButtons
                isLoading={isLoading}
                closeButtonLabel={t('form.cancel')}
                onClose={() => {
                    clearErrors()
                    reset()
                    onClose()
                }}
            />
        </>
    ) : (
        <div className={classNames({ [styles.indicator]: isLoading || isRedirectLoading })}>
            <QueryFeedback
                loading={isLoading || isRedirectLoading}
                error={isError}
                indicatorProps={{ label: isRedirectLoading ? t('evidenceObject.consument.processing') : undefined }}
            >
                <MutationFeedback error={isStoreRelationError || isRedirectError || isTooManyFetchesError || isProcessedError} />
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    {publicAuthorityState && roleState && (
                        <SelectPublicAuthorityAndRole
                            selectedRole={roleState.selectedRole ?? {}}
                            onChangeAuthority={publicAuthorityState.setSelectedPublicAuthority}
                            onChangeRole={roleState.setSelectedRole}
                            selectedOrg={publicAuthorityState.selectedPublicAuthority}
                            ciRoles={poMaOpravnenieSchema?.roleList ?? []}
                            hideRoleSelect
                            publicAuthorityLabel={t('evidenceObject.consument.po')}
                            disablePOSelect={!!dataToUpdate}
                        />
                    )}
                    <CiLazySelect
                        isMulti
                        relTypeFilters={relTypeFiltersForAOERels}
                        label={getAttribute(poOEAttributes, ATTRIBUTE_NAME.Profil_OEOpravnenie_atribut_objektu_evidencie)?.name ?? ''}
                        name={getAttribute(poOEAttributes, ATTRIBUTE_NAME.Profil_OEOpravnenie_atribut_objektu_evidencie)?.technicalName ?? ''}
                        ciType={ENTITY_ATTRIBUT_OBJEKTU_EVIDENCIE}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        error={
                            !hasRelationsOnAEO
                                ? t('evidenceObject.consument.noAOERelation')
                                : errors.Profil_OEOpravnenie_atribut_objektu_evidencie?.message
                        }
                        placeholder={t('createEntity.select')}
                        metaAttributes={{ state: [DRAFT] }}
                        required
                        defaultValue={dataToUpdate?.attributesObj['Profil_OEOpravnenie_atribut_objektu_evidencie']}
                    />
                    <SimpleSelect
                        name={getAttribute(poOEAttributes, ATTRIBUTE_NAME.Profil_OEOpravnenie_typ_opravnenia)?.technicalName ?? ''}
                        label={getAttribute(poOEAttributes, ATTRIBUTE_NAME.Profil_OEOpravnenie_typ_opravnenia)?.name ?? ''}
                        setValue={setValue}
                        options={rightsOptions}
                        error={errors.Profil_OEOpravnenie_typ_opravnenia?.message}
                        required
                        defaultValue={
                            dataToUpdate?.attributesObj['Profil_OEOpravnenie_typ_opravnenia'] ??
                            getAttribute(poOEAttributes, ATTRIBUTE_NAME.Profil_OEOpravnenie_typ_opravnenia)?.defaultValue
                        }
                    />
                    <Input
                        {...register(ATTRIBUTE_NAME.Profil_OEOpravnenie_pravny_ucel)}
                        label={getAttribute(poOEAttributes, ATTRIBUTE_NAME.Profil_OEOpravnenie_pravny_ucel)?.name ?? ''}
                        error={errors.Profil_OEOpravnenie_pravny_ucel?.message}
                    />
                    <Input
                        {...register(ATTRIBUTE_NAME.Profil_OEOpravnenie_pravny_zaklad)}
                        label={getAttribute(poOEAttributes, ATTRIBUTE_NAME.Profil_OEOpravnenie_pravny_zaklad)?.name ?? ''}
                        error={errors.Profil_OEOpravnenie_pravny_zaklad?.message}
                    />
                    <Input
                        {...register(ATTRIBUTE_NAME.Profil_OEOpravnenie_pravny_zaklad_kod)}
                        label={getAttribute(poOEAttributes, ATTRIBUTE_NAME.Profil_OEOpravnenie_pravny_zaklad_kod)?.name ?? ''}
                        error={errors.Profil_OEOpravnenie_pravny_zaklad_kod?.message}
                    />
                    <ModalButtons
                        isLoading={isLoading}
                        submitButtonLabel={t('form.save')}
                        closeButtonLabel={t('form.cancel')}
                        onClose={() => {
                            clearErrors()
                            reset()
                            onClose()
                        }}
                    />
                </form>
            </QueryFeedback>
        </div>
    )
}

type ConsumentRelModalProps = {
    isOpen: boolean
    onClose: () => void
    objectEvidenceId: string
}
export const ConsumentRelModal: React.FC<ConsumentRelModalProps> = ({ isOpen, onClose, objectEvidenceId }) => {
    const { t } = useTranslation()
    return (
        <BaseModal isOpen={isOpen} close={onClose}>
            <TextHeading size="L">{t('evidenceObject.consument.heading')}</TextHeading>
            <ConsumentRelForm objectEvidenceId={objectEvidenceId} onClose={onClose} />
        </BaseModal>
    )
}
