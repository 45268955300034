import { BreadCrumbs, HomeIcon } from '@isdd/idsk-ui-kit/index'
import { useTranslation } from 'react-i18next'

import { KSHelperContainer } from '@/components/containers/KSHelperContainer'
import { getCiHowToBreadCrumb } from '@/componentHelpers/ci'

const KSHelper = () => {
    const { t } = useTranslation()

    return (
        <>
            <BreadCrumbs
                withWidthContainer
                links={[
                    { label: t('breadcrumbs.home'), href: '/', icon: HomeIcon },
                    ...getCiHowToBreadCrumb('KS', t),
                    { label: t('titles.ciList', { ci: t('KSPublishHelper.breadcrumb') }), href: `/ci/KS` },
                    {
                        label: t('KSPublishHelper.button'),
                        href: `/ci/KS/publish`,
                    },
                ]}
            />
            <KSHelperContainer />
        </>
    )
}

export default KSHelper
