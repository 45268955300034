import { BaseModal } from '@isdd/idsk-ui-kit/index'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Spacer } from '@isdd/metais-common/components/spacer/Spacer'
import { ApiStandardRequestPreviewList } from '@isdd/metais-common/api/generated/standards-swagger'
import { ModalButtons } from '@isdd/metais-common/index'
import { TableWithPagination } from '@isdd/metais-common/components/TableWithPagination/TableWithPagination'
import { useRowSelection } from '@isdd/metais-common/hooks/useRowSelection'

import { standardRequestsListColumns } from './StandardRequestsListColumns'

type StandardRequestsListModalType = {
    allStandardRequestData: ApiStandardRequestPreviewList | undefined
    handleSelect: (index: number) => void
    selectedRequestId?: number
    setSelectedRequestId: (id?: number) => void
}

export type StandardRequestsListModalRefType = {
    open: () => boolean
}

export const StandardRequestsListModal = forwardRef<StandardRequestsListModalRefType, StandardRequestsListModalType>(
    ({ allStandardRequestData, handleSelect, selectedRequestId }, ref) => {
        const { t } = useTranslation()
        const [isStandardRequestsListModalOpen, setIsStandardRequestsListModalOpen] = useState<boolean>(false)
        const rowSelection = useRowSelection()

        const tableData = allStandardRequestData?.standardRequests

        const handleOpenStandardRequestListModal = () => {
            setIsStandardRequestsListModalOpen(true)
        }

        useImperativeHandle(ref, () => {
            return {
                open() {
                    handleOpenStandardRequestListModal()
                    return true
                },
            }
        })
        const handleCancel = () => {
            setIsStandardRequestsListModalOpen(false)
        }

        const handleSelectButtonClick = () => {
            const selectedIds = rowSelection.ids
            if (selectedIds.length === 0) {
                return
            }
            handleSelect(Number(selectedIds[0]))
            handleCancel()
        }

        useEffect(() => {
            rowSelection.setRowSelection({ [String(selectedRequestId)]: true })
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selectedRequestId])

        return (
            <BaseModal isOpen={isStandardRequestsListModalOpen} close={handleCancel}>
                <div>
                    <Spacer vertical />
                    {tableData && (
                        <TableWithPagination
                            tableColumns={standardRequestsListColumns(t)}
                            tableData={tableData}
                            hiddenButtons={{ SELECT_COLUMNS: true }}
                            enableRowSelection
                            enableMultiRowSelection={false}
                            rowSelection={rowSelection.rowSelection}
                            onRowSelectionChange={rowSelection.getOnRowSelectionChange()}
                            getRowId={(originalRow) => String(originalRow.id)}
                        />
                    )}
                    <Spacer vertical />
                    <ModalButtons
                        submitButtonLabel={t('votes.type.select')}
                        onSubmit={handleSelectButtonClick}
                        closeButtonLabel={t('votes.type.cancel')}
                        onClose={handleCancel}
                    />
                </div>
            </BaseModal>
        )
    },
)
