import React, { useEffect, useMemo, useState } from 'react'
import { DRAFT, ENTITY_AGENDA, ENTITY_DATAVY_PRVOK, OTVORENOST_UDAJOV, PO, ZDROJ_PRAVNEHO_ZAKLADU } from '@isdd/metais-common/constants'
import { Button, CheckBox, Input, RadioButton, RadioGroup, SimpleSelect, TextArea } from '@isdd/idsk-ui-kit/index'
import { ATTRIBUTE_NAME, ATTRIBUTE_PROFILE_NAME, BASE_PAGE_NUMBER, attributesFilter } from '@isdd/metais-common/api'
import { useForm } from 'react-hook-form'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import {
    ApiError,
    ConfigurationItemUi,
    useReadCiList1,
    useReadCiNeighboursWithAllRels,
    useStoreConfigurationItem,
} from '@isdd/metais-common/api/generated/cmdb-swagger'
import { yupResolver } from '@hookform/resolvers/yup'
import { MutationFeedback, QueryFeedback, SubmitWithFeedback } from '@isdd/metais-common/index'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'
import { useGetStatus } from '@isdd/metais-common/hooks/useGetRequestStatus'
import { getCmdbError } from '@isdd/metais-common/utils/errorMapper'
import { useAddOrGetGroupHook } from '@isdd/metais-common/api/generated/iam-swagger'
import { CiCode, CiType } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { ElementToScrollTo } from '@isdd/metais-common/components/element-to-scroll-to/ElementToScrollTo'
import { EnumType } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { MultiValue } from 'react-select'
import { includes } from 'lodash'
import classNames from 'classnames'
import { SelectPublicAuthorityAndRole } from '@isdd/metais-common/common/SelectPublicAuthorityAndRole'

import styles from './styles.module.scss'

import { AttributesConfigTechNames } from '@/components/attribute-input/attributeDisplaySettings'
import {
    getHint,
    getUrlStringFromCiCode,
    getValidAndVisibleAttributes,
    useCiCreateEditOnStatusSuccess,
} from '@/components/create-entity/createEntityHelpers'
import {
    EvidenceObjectForm,
    getAttribute,
    getEliUri,
    useGetEvidenceObjectSchema,
    useStoreObjectEvidenceRels,
} from '@/componentHelpers/ci/evidence-object'
import { SelectCiItem } from '@/components/select-ci-item/SelectCiItem'
import { PublicAuthorityState, RoleState } from '@/hooks/usePublicAuthorityAndRole.hook'
import { useRolesForPO } from '@/hooks/useRolesForPO'
import { onKeyDownOfNumberInput } from '@/components/attribute-input/attributeInputHelpers'

const EvidenceObjectFormObj = {
    Gen_Profil_nazov: '',
    Gen_Profil_kod_metais: '',
    Gen_Profil_ref_id: '',
    agenda: '',
    Profil_ObjektEvidencie_otvorenost_udajov: '',
    Profil_ObjektEvidencie_dovod: '',
    Profil_ObjektEvidencie_eu_ref_id: '',
    Profil_ObjektEvidencie_jednotny_URI: '',
    Profil_ObjektEvidencie_nazov: '',
    Profil_ObjektEvidencie_rok: '',
    Profil_ObjektEvidencie_cislo: '',
    Profil_ObjektEvidencie_paragraf: '',
    Profil_ObjektEvidencie_odsek: '',
    Profil_ObjektEvidencie_pismeno: '',
    Profil_ObjektEvidencie_bod: '',
    Profil_ObjektEvidencie_zdroj: '',
    Profil_ObjektEvidencie_popis: '',
} as const

const euUriAttributes: string[] = [
    ATTRIBUTE_NAME.Profil_ObjektEvidencie_rok,
    ATTRIBUTE_NAME.Profil_ObjektEvidencie_cislo,
    ATTRIBUTE_NAME.Profil_ObjektEvidencie_paragraf,
    ATTRIBUTE_NAME.Profil_ObjektEvidencie_odsek,
    ATTRIBUTE_NAME.Profil_ObjektEvidencie_pismeno,
    ATTRIBUTE_NAME.Profil_ObjektEvidencie_bod,
    ATTRIBUTE_NAME.Profil_ObjektEvidencie_zdroj,
    ATTRIBUTE_NAME.Profil_ObjektEvidencie_popis,
    ATTRIBUTE_NAME.Profil_ObjektEvidencie_eli_uri,
]

const otvorenostUdajovCodes = {
    open: 'c_otvorenost_udajov.1',
    others: 'c_otvorenost_udajov.2',
} as const
const fieldsOutOfSchema = {
    provider: 'provider',
    agenda: 'agenda',
} as const

type Props = {
    constraintsData: (EnumType | undefined)[]
    generatedEntityId: CiCode | undefined
    ciTypeData: CiType | undefined
    defaultValues?: EvidenceObjectForm
    entityName: string
    editedEntity?: ConfigurationItemUi
    publicAuthorityState?: PublicAuthorityState
    roleState?: RoleState
    ownerId?: string
}

export const useEvidenceObjectProviderAgendaData = (ciTypeData: CiType | undefined, provider: string) => {
    const {
        state: { user },
    } = useAuth()
    const opravnenePO = user?.groupData.filter((group) => group.roles.find((role) => ciTypeData?.roleList?.includes(role.roleName)))
    const {
        data: opravnenePOData,
        isLoading: isCilistLoading,
        isError: isCiListError,
    } = useReadCiList1(
        {
            filter: { type: [PO], uuid: opravnenePO?.map((po) => po.orgId), metaAttributes: { state: [DRAFT] } },
        },
        { query: { enabled: opravnenePO && opravnenePO.length > 0 } },
    )

    const {
        data: agendaRels,
        isLoading: isAgendaRelsLoading,
        isError: isAgendaRelsError,
    } = useReadCiNeighboursWithAllRels(provider, {
        ciTypes: [ENTITY_AGENDA],
        page: BASE_PAGE_NUMBER,
        perPage: 999,
    })

    return {
        isLoading: isAgendaRelsLoading || isCilistLoading,
        isError: isAgendaRelsError || isCiListError,
        agendaRels,
        opravnenePOData,
    }
}

export const CreateEvidenceObjectView: React.FC<Props> = ({
    ciTypeData,
    constraintsData,
    generatedEntityId,
    defaultValues,
    entityName,
    editedEntity,
    publicAuthorityState,
    roleState,
    ownerId,
}) => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()

    const onStatusSuccess = useCiCreateEditOnStatusSuccess()
    const getGroupData = useAddOrGetGroupHook()
    const { isError: isRedirectError, isLoading: isRedirectLoading, isProcessedError, getRequestStatus, isTooManyFetchesError } = useGetStatus()
    const { storeObjectEvidenceRels, isError, isLoading } = useStoreObjectEvidenceRels()
    const { schema, setIsEU } = useGetEvidenceObjectSchema()

    const { rolesForPO } = useRolesForPO(
        editedEntity ? ownerId ?? '' : publicAuthorityState?.selectedPublicAuthority?.poUUID ?? '',
        ciTypeData?.roleList ?? [],
    )

    const {
        data: agendaRels,
        isLoading: isAgendaRelsLoading,
        isError: isAgendaRelsError,
    } = useReadCiNeighboursWithAllRels(publicAuthorityState?.selectedPublicAuthority?.poUUID ?? '', {
        ciTypes: [ENTITY_AGENDA],
        page: BASE_PAGE_NUMBER,
        perPage: 999,
    })

    const [uploadError, setUploadError] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string[]>()
    const [selectedUnifiedUri, setSelectedUnifiedUri] = useState<ConfigurationItemUi | MultiValue<ConfigurationItemUi> | null>(null)

    const attributes = [...(ciTypeData?.attributes ?? []), ...(ciTypeData?.attributeProfiles?.flatMap((p) => p.attributes) ?? [])]
    const zdrojPravnehoZakladuOptions = useMemo(() => {
        return (
            constraintsData
                ?.find((i) => i?.code === ZDROJ_PRAVNEHO_ZAKLADU)
                ?.enumItems?.map((i) => ({ value: i.code ?? '', label: i.value ?? '' })) ?? []
        )
    }, [constraintsData])
    const otvorenostUdajov = useMemo(() => {
        return constraintsData?.find((i) => i?.code === OTVORENOST_UDAJOV)
    }, [constraintsData])
    const defaultDataFromSchema = attributes
        .filter((i) => Object.keys(EvidenceObjectFormObj).includes(i?.technicalName ?? ''))
        .reduce((acc, obj) => {
            const key: string = obj?.technicalName ?? ''
            const value = obj?.defaultValue
            return { ...acc, [key]: value }
        }, {}) as EvidenceObjectForm

    const { register, setValue, watch, clearErrors, handleSubmit, formState, reset, trigger, setError, getValues, resetField } =
        useForm<EvidenceObjectForm>({
            defaultValues: defaultValues ? defaultValues : defaultDataFromSchema,
            resolver: yupResolver(schema),
        })

    const isEU = watch('Profil_ObjektEvidencie_eu_ref_id')
    const eliUriPreview = getEliUri(watch(), zdrojPravnehoZakladuOptions)

    const hasGenProfilRole = rolesForPO?.some((role) => ciTypeData?.roleList?.includes(role?.roleName ?? ''))
    const hasProfilObjektEvidencieRole = rolesForPO?.some((role) =>
        ciTypeData?.attributeProfiles
            ?.find((prof) => prof.technicalName == ATTRIBUTE_PROFILE_NAME.Profil_ObjektEvidencie)
            ?.roleList?.includes(role?.roleName ?? ''),
    )

    useEffect(() => {
        setIsEU(!!isEU)
        if (isEU) {
            setError('Profil_ObjektEvidencie_rok', { message: '' })
            setError('Profil_ObjektEvidencie_cislo', { message: '' })
            setError('Profil_ObjektEvidencie_paragraf', { message: '' })
        }
    }, [isEU, resetField, setError, setIsEU, trigger])

    useEffect(() => {
        if (zdrojPravnehoZakladuOptions?.[0]?.value) {
            setValue(ATTRIBUTE_NAME.Profil_ObjektEvidencie_zdroj, zdrojPravnehoZakladuOptions[0].value)
        }
    }, [setValue, zdrojPravnehoZakladuOptions])
    useEffect(() => {
        const referenceIdValue = generatedEntityId?.ciurl?.split('/').pop()
        const metaIsCodeValue = generatedEntityId?.cicode
        setValue(AttributesConfigTechNames.REFERENCE_ID, referenceIdValue ?? '')
        setValue(AttributesConfigTechNames.METAIS_CODE, metaIsCodeValue ?? '')
    }, [formState.defaultValues, setValue, generatedEntityId?.ciurl, generatedEntityId?.cicode])

    const storeConfigurationItem = useStoreConfigurationItem({
        mutation: {
            onError() {
                setUploadError(true)
            },
            async onSuccess(successData, variables) {
                if (successData.requestId != null) {
                    const configurationItemId = variables.data.uuid ?? ''
                    await getRequestStatus(successData.requestId, async () => {
                        const agendaId = getValues(fieldsOutOfSchema.agenda)
                        if (agendaId) {
                            await storeObjectEvidenceRels({
                                configurationItemId,
                                agendaId,
                                owner: variables.data.owner ?? '',
                                setError: setUploadError,
                                onSuccess: () => onStatusSuccess({ configurationItemId: configurationItemId, isUpdate: false, entityName }),
                            })
                        } else {
                            onStatusSuccess({ configurationItemId: configurationItemId, isUpdate: false, entityName })
                        }
                    })
                } else {
                    setUploadError(true)
                }
            },
        },
    })

    const onSubmit = async (formData: EvidenceObjectForm) => {
        setUploadError(false)

        const ciUrl = getUrlStringFromCiCode(generatedEntityId ?? {})
        const isFormEu = formData.Profil_ObjektEvidencie_eu_ref_id
        const entityUuid = v4()
        let eliUri
        if (!isFormEu) {
            eliUri = getEliUri(formData, zdrojPravnehoZakladuOptions, true)
        }

        const formatedAttributes = Object.keys(formData)
            .filter((item) => (item as keyof EvidenceObjectForm) != fieldsOutOfSchema.agenda)
            .map((item) => {
                if (item === ATTRIBUTE_NAME.Gen_Profil_ref_id) {
                    return { name: item, value: `${ciUrl ?? ''}${formData[item as keyof EvidenceObjectForm]}` }
                }
                if (isFormEu && includes(euUriAttributes, item)) {
                    return { name: item, value: null }
                }
                return { name: item, value: formData[item as keyof EvidenceObjectForm] }
            })

        try {
            const { gid: owner } = await getGroupData(
                roleState?.selectedRole?.roleUuid ?? '',
                publicAuthorityState?.selectedPublicAuthority?.poUUID ?? '',
            )
            const data: ConfigurationItemUi = {
                uuid: editedEntity?.uuid ? editedEntity.uuid : entityUuid,
                type: entityName,
                owner,
                attributes: [...formatedAttributes, ...(isFormEu ? [{}] : [{ name: ATTRIBUTE_NAME.Profil_ObjektEvidencie_eli_uri, value: eliUri }])],
            }

            await storeConfigurationItem
                .mutateAsync({
                    data: data,
                })
                .catch((err: ApiError) => {
                    if (err.message) {
                        setErrorMessage(getCmdbError(err, i18n.language, getValidAndVisibleAttributes(ciTypeData), t))
                    }
                })
        } catch (error) {
            setUploadError(true)
        }
    }
    const getError = (techName: keyof EvidenceObjectForm) => {
        return formState.errors?.[techName]?.message
    }
    const getInfo = (techName: keyof EvidenceObjectForm) => {
        return getAttribute(attributes, techName)?.description
    }
    const getLabel = (techName: keyof EvidenceObjectForm) => {
        return getAttribute(attributes, techName)?.name
    }

    return (
        <QueryFeedback loading={isRedirectLoading || isLoading || isAgendaRelsLoading} error={isAgendaRelsError} withChildren>
            <ElementToScrollTo trigger={uploadError || isRedirectError || isError} manualScroll>
                <MutationFeedback
                    error={uploadError || isRedirectError || isError}
                    errorMessage={errorMessage ? errorMessage : undefined}
                    mutationProcessingError={isProcessedError}
                    mutationTooLong={isTooManyFetchesError}
                />
            </ElementToScrollTo>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Input
                    error={getError(ATTRIBUTE_NAME.Gen_Profil_nazov)}
                    {...register(ATTRIBUTE_NAME.Gen_Profil_nazov)}
                    label={getLabel(ATTRIBUTE_NAME.Gen_Profil_nazov)}
                    info={getInfo(ATTRIBUTE_NAME.Gen_Profil_nazov)}
                    required
                    disabled={!hasGenProfilRole}
                    maxLength={2000}
                />
                <Input
                    error={getError(ATTRIBUTE_NAME.Gen_Profil_kod_metais)}
                    info={getInfo(ATTRIBUTE_NAME.Gen_Profil_kod_metais)}
                    {...register(ATTRIBUTE_NAME.Gen_Profil_kod_metais)}
                    label={getLabel(ATTRIBUTE_NAME.Gen_Profil_kod_metais)}
                    disabled
                    readOnly
                    required
                    maxLength={50}
                />
                <Input
                    className={styles.marginTop}
                    disabled
                    readOnly
                    info={getInfo(ATTRIBUTE_NAME.Gen_Profil_ref_id)}
                    {...register(ATTRIBUTE_NAME.Gen_Profil_ref_id)}
                    label={getLabel(ATTRIBUTE_NAME.Gen_Profil_ref_id)}
                    hint={getHint(getAttribute(attributes, ATTRIBUTE_NAME.Gen_Profil_ref_id) ?? {}, generatedEntityId ?? {})}
                    required
                    maxLength={50}
                />
                {publicAuthorityState && roleState && (
                    <SelectPublicAuthorityAndRole
                        selectedRole={roleState.selectedRole ?? {}}
                        onChangeAuthority={(val) => {
                            publicAuthorityState.setSelectedPublicAuthority(val)
                            setValue(fieldsOutOfSchema.agenda, null)
                        }}
                        onChangeRole={roleState.setSelectedRole}
                        selectedOrg={publicAuthorityState.selectedPublicAuthority}
                        ciRoles={ciTypeData?.roleList ?? []}
                        hideRoleSelect
                        publicAuthorityLabel={t('evidenceObject.provider')}
                        disablePOSelect={!!editedEntity}
                        publicAuthorityRequired
                    />
                )}
                <SimpleSelect
                    error={getError(fieldsOutOfSchema.agenda)}
                    isClearable={false}
                    clearErrors={clearErrors}
                    label={t(`evidenceObject.${fieldsOutOfSchema.agenda}`)}
                    name={fieldsOutOfSchema.agenda}
                    setValue={setValue}
                    options={
                        agendaRels?.ciWithRels?.map((confItem) => ({
                            value: confItem.ci?.uuid,
                            label: confItem.ci?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov],
                        })) ?? []
                    }
                    value={watch(fieldsOutOfSchema.agenda)}
                />

                <fieldset>
                    <legend className="govuk-heading-m">{t('evidenceObject.opennessOfData')}</legend>
                    <RadioGroup inline className={styles.radioGroup} withoutFieldset>
                        <RadioButton
                            {...register(ATTRIBUTE_NAME.Profil_ObjektEvidencie_otvorenost_udajov)}
                            defaultChecked
                            value={otvorenostUdajovCodes.open}
                            label={otvorenostUdajov?.enumItems?.find((i) => i.code == otvorenostUdajovCodes.open)?.value ?? ''}
                            disabled={!hasProfilObjektEvidencieRole}
                        />
                        <RadioButton
                            {...register(ATTRIBUTE_NAME.Profil_ObjektEvidencie_otvorenost_udajov)}
                            value={otvorenostUdajovCodes.others}
                            label={otvorenostUdajov?.enumItems?.find((i) => i.code == otvorenostUdajovCodes.others)?.value ?? ''}
                            disabled={!hasProfilObjektEvidencieRole}
                        />
                    </RadioGroup>
                    <TextArea
                        error={getError(ATTRIBUTE_NAME.Profil_ObjektEvidencie_dovod)}
                        rows={3}
                        {...register(ATTRIBUTE_NAME.Profil_ObjektEvidencie_dovod)}
                        label={getLabel(ATTRIBUTE_NAME.Profil_ObjektEvidencie_dovod)}
                        info={getInfo(ATTRIBUTE_NAME.Profil_ObjektEvidencie_dovod)}
                        disabled={!hasProfilObjektEvidencieRole}
                    />
                </fieldset>
                <fieldset>
                    <legend className="govuk-heading-m">{t('atributObjektuEvidencie.jednotnyUri')}</legend>
                    <SelectCiItem
                        ciType={ENTITY_DATAVY_PRVOK}
                        onChangeSelectedCiItem={(val) => {
                            setSelectedUnifiedUri(val)
                            setValue(
                                ATTRIBUTE_NAME.Profil_ObjektEvidencie_jednotny_URI,
                                Array.isArray(val)
                                    ? val[0].attributes?.[ATTRIBUTE_NAME.Gen_Profil_ref_id]
                                    : (val as ConfigurationItemUi)?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_ref_id],
                            )
                        }}
                        onCloseModal={() => undefined}
                        onOpenModal={() => undefined}
                        existingRelations={{}}
                        label={t('refIdentifiers.type.DatovyPrvok')}
                        isOpen={false}
                        selectedItems={selectedUnifiedUri}
                        isMulti={false}
                        labelDescriptionAttr={ATTRIBUTE_NAME.Gen_Profil_ref_id}
                        attributesFilter={attributesFilter}
                    />
                    <span className="govuk-hint">
                        <u>{`${watch(ATTRIBUTE_NAME.Profil_ObjektEvidencie_jednotny_URI) || ''}`}</u>
                    </span>
                </fieldset>
                <fieldset>
                    <legend className="govuk-heading-m">{t('atributObjektuEvidencie.pravnyPredpis')}</legend>
                    <div className={styles.inlineBlock}>
                        <CheckBox
                            info={getInfo(ATTRIBUTE_NAME.Profil_ObjektEvidencie_eu_ref_id)}
                            error={getError(ATTRIBUTE_NAME.Profil_ObjektEvidencie_eu_ref_id)}
                            label={getLabel(ATTRIBUTE_NAME.Profil_ObjektEvidencie_eu_ref_id)}
                            {...register(ATTRIBUTE_NAME.Profil_ObjektEvidencie_eu_ref_id)}
                            disabled={!hasProfilObjektEvidencieRole}
                        />
                    </div>
                    <Input
                        {...register(ATTRIBUTE_NAME.Profil_ObjektEvidencie_nazov)}
                        info={getInfo(ATTRIBUTE_NAME.Profil_ObjektEvidencie_nazov)}
                        label={getLabel(ATTRIBUTE_NAME.Profil_ObjektEvidencie_nazov)}
                        error={getError(ATTRIBUTE_NAME.Profil_ObjektEvidencie_nazov)}
                        required
                        disabled={!hasProfilObjektEvidencieRole}
                        className={styles.marginTop}
                    />
                    {!isEU && (
                        <>
                            <div className={styles.gridThree}>
                                <Input
                                    {...register(ATTRIBUTE_NAME.Profil_ObjektEvidencie_cislo)}
                                    info={`${getInfo(ATTRIBUTE_NAME.Profil_ObjektEvidencie_cislo)} (${t('input.number.hint')})`}
                                    label={getLabel(ATTRIBUTE_NAME.Profil_ObjektEvidencie_cislo)}
                                    error={getError(ATTRIBUTE_NAME.Profil_ObjektEvidencie_cislo)}
                                    type="number"
                                    required={!isEU}
                                    disabled={!hasProfilObjektEvidencieRole}
                                    onKeyDown={onKeyDownOfNumberInput}
                                />

                                <Input
                                    {...register(ATTRIBUTE_NAME.Profil_ObjektEvidencie_rok)}
                                    info={`${getInfo(ATTRIBUTE_NAME.Profil_ObjektEvidencie_rok)} (${t('input.number.hint')})`}
                                    label={getLabel(ATTRIBUTE_NAME.Profil_ObjektEvidencie_rok)}
                                    error={getError(ATTRIBUTE_NAME.Profil_ObjektEvidencie_rok)}
                                    type="number"
                                    required={!isEU}
                                    disabled={!hasProfilObjektEvidencieRole}
                                    onKeyDown={onKeyDownOfNumberInput}
                                />
                                <SimpleSelect
                                    isClearable={false}
                                    name={ATTRIBUTE_NAME.Profil_ObjektEvidencie_zdroj}
                                    label={getLabel(ATTRIBUTE_NAME.Profil_ObjektEvidencie_zdroj) ?? ''}
                                    error={getError(ATTRIBUTE_NAME.Profil_ObjektEvidencie_zdroj)}
                                    clearErrors={clearErrors}
                                    setValue={setValue}
                                    options={zdrojPravnehoZakladuOptions}
                                    disabled={!hasProfilObjektEvidencieRole}
                                    value={watch('Profil_ObjektEvidencie_zdroj')}
                                    info={getInfo(ATTRIBUTE_NAME.Profil_ObjektEvidencie_zdroj)}
                                />
                            </div>
                            <div className={styles.gridFour}>
                                <Input
                                    {...register(ATTRIBUTE_NAME.Profil_ObjektEvidencie_paragraf)}
                                    info={`${getInfo(ATTRIBUTE_NAME.Profil_ObjektEvidencie_paragraf)} (${t('input.number.hint')})`}
                                    label={getLabel(ATTRIBUTE_NAME.Profil_ObjektEvidencie_paragraf)}
                                    error={getError(ATTRIBUTE_NAME.Profil_ObjektEvidencie_paragraf)}
                                    required={!isEU}
                                    type="number"
                                    disabled={!hasProfilObjektEvidencieRole}
                                    onKeyDown={onKeyDownOfNumberInput}
                                />
                                <Input
                                    {...register(ATTRIBUTE_NAME.Profil_ObjektEvidencie_odsek)}
                                    info={`${getInfo(ATTRIBUTE_NAME.Profil_ObjektEvidencie_odsek)} (${t('input.number.hint')})`}
                                    label={getLabel(ATTRIBUTE_NAME.Profil_ObjektEvidencie_odsek)}
                                    error={getError(ATTRIBUTE_NAME.Profil_ObjektEvidencie_odsek)}
                                    type="number"
                                    required={!isEU}
                                    disabled={!hasProfilObjektEvidencieRole}
                                    onKeyDown={onKeyDownOfNumberInput}
                                />
                                <Input
                                    {...register(ATTRIBUTE_NAME.Profil_ObjektEvidencie_pismeno)}
                                    info={getInfo(ATTRIBUTE_NAME.Profil_ObjektEvidencie_pismeno)}
                                    label={getLabel(ATTRIBUTE_NAME.Profil_ObjektEvidencie_pismeno)}
                                    error={getError(ATTRIBUTE_NAME.Profil_ObjektEvidencie_pismeno)}
                                    disabled={!hasProfilObjektEvidencieRole}
                                    maxLength={50}
                                />
                                <Input
                                    {...register(ATTRIBUTE_NAME.Profil_ObjektEvidencie_bod)}
                                    info={getInfo(ATTRIBUTE_NAME.Profil_ObjektEvidencie_bod)}
                                    label={getLabel(ATTRIBUTE_NAME.Profil_ObjektEvidencie_bod)}
                                    error={getError(ATTRIBUTE_NAME.Profil_ObjektEvidencie_bod)}
                                    disabled={!hasProfilObjektEvidencieRole}
                                    maxLength={50}
                                />
                            </div>
                            <span className={classNames(['govuk-hint', styles.wrapAnywhere])}>
                                <u>{`${t('atributObjektuEvidencie.uriInsight')}: ${eliUriPreview}`}</u>
                            </span>
                            <TextArea
                                rows={3}
                                {...register(ATTRIBUTE_NAME.Profil_ObjektEvidencie_popis)}
                                label={getLabel(ATTRIBUTE_NAME.Profil_ObjektEvidencie_popis)}
                                error={getError(ATTRIBUTE_NAME.Profil_ObjektEvidencie_popis)}
                                disabled={!hasProfilObjektEvidencieRole}
                            />
                        </>
                    )}
                </fieldset>

                <SubmitWithFeedback
                    additionalButtons={[
                        <Button
                            key={1}
                            label={t('button.cancel')}
                            type="reset"
                            variant="secondary"
                            onClick={() => {
                                reset()
                                navigate(-1)
                            }}
                        />,
                    ]}
                    submitButtonLabel={t('button.saveChanges')}
                    loading={formState.isValidating || formState.isSubmitting}
                    disabled={!hasGenProfilRole}
                />
            </form>
        </QueryFeedback>
    )
}
