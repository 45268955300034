import { useCallback, useEffect, useRef } from 'react'
import { debounce } from 'lodash'

import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { ACTIVITY_DEBOUNCE, LOCAL_STORAGE_ACTIVITY_TRIGGER } from '@isdd/metais-common/constants'

type Props = {
    handleLogout: () => void
    showWarningModal: () => void
    warnTime: number
    logoutTime: number
}

export const useWarnAndLogoutOnUserInactivity = ({ handleLogout, showWarningModal, warnTime, logoutTime }: Props) => {
    const {
        state: { user },
    } = useAuth()

    const warningTimerRef = useRef<ReturnType<typeof setTimeout>>()
    const logoutTimerRef = useRef<ReturnType<typeof setTimeout>>()

    const clearTimeouts = () => {
        if (warningTimerRef) clearTimeout(warningTimerRef.current)
        if (logoutTimerRef) clearTimeout(logoutTimerRef.current)
    }

    const setTimeouts = useCallback(
        (setStorage = true) => {
            if (setStorage) {
                localStorage.setItem(LOCAL_STORAGE_ACTIVITY_TRIGGER, crypto.randomUUID())
            }
            clearTimeouts()
            warningTimerRef.current = setTimeout(showWarningModal, logoutTime - warnTime)
            logoutTimerRef.current = setTimeout(handleLogout, logoutTime)
        },
        [handleLogout, logoutTime, showWarningModal, warnTime],
    )

    const resetTimeout = useCallback(() => {
        clearTimeouts()
        setTimeouts()
    }, [setTimeouts])

    useEffect(() => {
        const reset = () => setTimeouts(false)
        window.addEventListener('storage', reset)
        setTimeouts()
        return () => {
            window.removeEventListener('storage', reset)
            localStorage.removeItem(LOCAL_STORAGE_ACTIVITY_TRIGGER)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const events = [
            'click',
            'mousemove',
            'keypress',
            'scroll',
            'touchstart',
            'touchmove',
            'keydown',
            'keyup',
            'mousedown',
            'mouseup',
            'mouseenter',
            'mouseleave',
            'focus',
            'blur',
            'visibilitychange',
            'resize',
            'contextmenu',
            'wheel',
        ]
        const debouncedReset = debounce(resetTimeout, ACTIVITY_DEBOUNCE, { leading: true, trailing: false })

        if (user?.uuid) {
            events.forEach((event) => {
                window.addEventListener(event, debouncedReset)
            })
        }

        return () => {
            clearTimeouts()
            events.forEach((event) => {
                window.removeEventListener(event, debouncedReset)
            })
        }
    }, [resetTimeout, user?.uuid])
}
