import React, { useId } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import classNames from 'classnames'
import { SearchIcon } from '@isdd/idsk-ui-kit/search-icon/SearchIcon'

import styles from '@isdd/metais-common/components/navbar/navbar.module.scss'
import { RouteNames } from '@isdd/metais-common/navigation/routeNames'
import { BASE_PAGE_NUMBER, BASE_PAGE_SIZE } from '@isdd/metais-common/constants'

export enum GlobalSearchParams {
    SEARCH = 'search',
    PAGE = 'page',
    PER_PAGE = 'pageSize',
    STATE = 'state',
    TOTAL_ITEMS = 'total',
}

type SearchbarForm = {
    [GlobalSearchParams.SEARCH]: string
}

interface NavSearchBarProps {
    isMobile?: boolean
    afterSubmit?: () => void
}

export const NavSearchBar = ({ isMobile = false, afterSubmit }: NavSearchBarProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [uriParams] = useSearchParams()
    const { register, handleSubmit } = useForm<SearchbarForm>({
        defaultValues: { search: uriParams.get('search') ?? '' },
    })
    const inputId = useId()

    const onSubmit = (formData: SearchbarForm) => {
        if (formData[GlobalSearchParams.SEARCH]) {
            const searchUrlParams = new URLSearchParams(formData)
            const paginationUrlString = `&${GlobalSearchParams.PAGE}=${BASE_PAGE_NUMBER}&${GlobalSearchParams.PER_PAGE}=${BASE_PAGE_SIZE}`
            const filter = uriParams.get('filter')
            const filterParams = filter ? `&filter=${new URLSearchParams(filter ?? '').toString().slice(0, -1)}` : ''
            navigate(RouteNames.GLOBAL_SEARCH + `?${searchUrlParams}` + paginationUrlString + filterParams)
        }
        afterSubmit?.()
    }

    return (
        <div role="search" className={classNames(styles.fullWidth, { [styles.desktopSearch]: !isMobile, [styles.mobileSearch]: isMobile })}>
            <form onSubmit={handleSubmit(onSubmit)} className={classNames('idsk-header-web__main-action-search', styles.fullWidth)} noValidate>
                <input
                    required
                    className="govuk-input govuk-!-display-inline-block"
                    id={inputId}
                    placeholder={t('navbar.searchPlaceholder') ?? ''}
                    title={t('navbar.searchPlaceholder') ?? ''}
                    type="search"
                    {...register('search')}
                />
                <label htmlFor={inputId} className="govuk-visually-hidden">
                    {t('navbar.searchPlaceholder')}
                </label>
                <button type="submit" className="govuk-button" data-module="govuk-button" aria-label={t('navbar.searchPlaceholderButtonAria')}>
                    <SearchIcon />
                </button>
            </form>
        </div>
    )
}
