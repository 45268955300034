import { BaseModal, Button, ButtonLink, ButtonPopup } from '@isdd/idsk-ui-kit'
import classnames from 'classnames'
import React, { useId, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import styles from '@isdd/metais-common/components/navbar/navbar.module.scss'
import { ENTITY_AS, ENTITY_INFRA_SLUZBA, ENTITY_ISVS, ENTITY_KRIS, ENTITY_KS, ENTITY_PROJECT, ENTITY_TRAINING } from '@isdd/metais-common/constants'
import { RouterRoutes } from '@isdd/metais-common/navigation/routeNames'
import { useWindowWidthBreakpoints } from '@isdd/metais-common/hooks/window-size/useWindowWidthBreakpoints'

type NewItemPopupList = {
    title: string
    href: string
}

export const NewItemButtonPopup = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const windowWidthBreakpoints = useWindowWidthBreakpoints()
    const [mobileModalIsOpen, setMobileModalIsOpen] = useState<boolean>(false)
    const mobileModalId = useId()

    const newItemPopupListConfig: NewItemPopupList[] = [
        {
            title: t(`ciType.${ENTITY_AS}`),
            href: `/ci/${ENTITY_AS}/create`,
        },
        {
            title: t(`ciType.${ENTITY_KS}`),
            href: `/ci/${ENTITY_KS}/create`,
        },
        {
            title: t(`ciType.${ENTITY_ISVS}`),
            href: `/ci/${ENTITY_ISVS}/create`,
        },
        {
            title: t(`ciType.${ENTITY_PROJECT}`),
            href: `/ci/${ENTITY_PROJECT}/create`,
        },
        {
            title: t(`ciType.${ENTITY_INFRA_SLUZBA}`),
            href: `/ci/${ENTITY_INFRA_SLUZBA}/create`,
        },
        {
            title: t(`ciType.${ENTITY_KRIS}`),
            href: `/ci/${ENTITY_KRIS}/create`,
        },
        {
            title: t(`ciType.${ENTITY_TRAINING}`),
            href: `/ci/${ENTITY_TRAINING}/create`,
        },
        {
            title: t('codeListList.requestTitle'),
            href: RouterRoutes.DATA_OBJECT_REQUESTS_CREATE,
        },
    ]

    const links = (
        <div className={styles.popupList}>
            {newItemPopupListConfig.map((item, index) => {
                return (
                    <li key={index}>
                        <ButtonLink
                            type="button"
                            onClick={() => {
                                navigate(item.href)
                                setMobileModalIsOpen(false)
                            }}
                            label={item.title}
                        />
                    </li>
                )
            })}
        </div>
    )

    const popup = (
        <ButtonPopup
            buttonClassName={classnames('idsk-button idsk-button--secondary', styles.noWrap)}
            buttonLabel={`+ ${t('navbar.newItem')}`}
            triggerAria={{ 'aria-label': t('navbar.newItem') }}
            popupHorizontalPosition={windowWidthBreakpoints?.desktop ? 'right' : 'left'}
            popupVerticalPosition={windowWidthBreakpoints?.desktop ? 'bottom' : 'top'}
            popupContent={() => {
                return <ul className={styles.invisibleList}>{links}</ul>
            }}
        />
    )

    const mobileModal = (
        <>
            <Button
                label={`+ ${t('navbar.newItem')}`}
                variant="secondary"
                aria-expanded={mobileModalIsOpen}
                aria-haspopup="dialog"
                aria-controls={mobileModalId}
                onClick={() => setMobileModalIsOpen(true)}
            />
            <BaseModal isOpen={mobileModalIsOpen} close={() => setMobileModalIsOpen(false)}>
                <div id={mobileModalId}>
                    <ul className={styles.invisibleList}>{links}</ul>
                </div>
            </BaseModal>
        </>
    )

    return windowWidthBreakpoints?.desktop ? popup : mobileModal
}
