import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { DateInput } from '@isdd/idsk-ui-kit/date-input/DateInput'
import { BaseModal, CheckBox, Input, LoadingIndicator, TextArea, TextHeading, TextWarning } from '@isdd/idsk-ui-kit/index'
import {
    ApiCodelistItem,
    useCreateCodelistItem,
    useCreateNewCodelistItemLangExtended,
    useGetNextItemCodeHook,
    useGetOriginalCodelistItemHook,
    useGetTemporalCodelistItemWithLockHook,
    useProcessItemAction,
    useUpdateAndUnlockTemporalCodelistItem,
    useUpdateCodelistItemLangExtended,
} from '@isdd/metais-common/api/generated/codelist-repo-swagger'
import { AttributeProfile } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { ModalButtons, QueryFeedback } from '@isdd/metais-common/index'
import { getErrorTranslateKeys } from '@isdd/metais-common/utils/errorMapper'
import { useMutation } from '@tanstack/react-query'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
    ApiCodeListItemsActions,
    CodeListItemState,
    mapCodeListItemToForm,
    mapFormToCodeListItem,
    removeOtherLanguagesFromItem,
} from '@/componentHelpers/codeList'
import { getItemCodelistRefId } from '@/componentHelpers/requests'
import { getDescription, getName } from '@/components/views/codeLists/CodeListDetailUtils'
import { useItemSchema } from '@/components/views/codeLists/useCodeListSchemas'
import { onKeyDownOfNumberInput } from '@/components/attribute-input/attributeInputHelpers'

export interface IItemForm {
    id?: string
    code: string
    name: string
    shortenedName?: string
    abbreviatedName?: string
    refIdent?: string
    additionalContent?: string
    unitOfMeasure?: string
    note?: string
    legislativeValidity?: string
    exclude?: string
    include?: string
    includeAlso?: string
    order?: number
    validFrom?: string
    effectiveFrom: string
    effectiveTo?: string
    lockedBy?: string
    lockedFrom?: string
    selected?: boolean
}

export interface ItemFormModalProps {
    isOpen: boolean
    item?: ApiCodelistItem
    codeListCode: string
    defaultOrderValue: number
    attributeProfile?: AttributeProfile
    workingLanguage: string
    close: () => void
    isCodelistAutoincrementValid: boolean
    codelistPrefix?: string
    setIsSuccess: (val: boolean) => void
    setIsError: (val: boolean) => void
    setErrorMessage: (val?: string) => void
    codelistId: number
}

export enum CodeListItemFormEnum {
    ID = 'id',
    CODE = 'code',
    NAME = 'name',
    SHORTENED_NAME = 'shortenedName',
    ABBREVIATED_NAME = 'abbreviatedName',
    REF_IDENT = 'refIdent',
    ADDITIONAL_CONTENT = 'additionalContent',
    UNIT_OF_MEASURE = 'unitOfMeasure',
    NOTE = 'note',
    EXCLUDE = 'exclude',
    INCLUDE = 'include',
    INCLUDE_ALSO = 'includeAlso',
    ORDER = 'order',
    LEGISLATIVE_VALIDITY = 'legislativeValidity',
    VALID_FROM = 'validFrom',
    EFFECTIVE_FROM = 'effectiveFrom',
    EFFECTIVE_TO = 'effectiveTo',
}

export const ItemFormModal: React.FC<ItemFormModalProps> = ({
    isOpen,
    close,
    item,
    codeListCode,
    attributeProfile,
    workingLanguage,
    defaultOrderValue = 1,
    isCodelistAutoincrementValid,
    codelistPrefix,
    setIsSuccess,
    setIsError,
    setErrorMessage,
    codelistId,
}) => {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const { schema } = useItemSchema()

    const [defaultValues, setDefaultValues] = useState<IItemForm | undefined>(undefined)
    const [lockedItem, setLockedItem] = useState<ApiCodelistItem | undefined>(undefined)

    const isEdit = !!item?.id ?? false

    const mutationCreateItemLangExtended = useCreateNewCodelistItemLangExtended()
    const mutationUpdateAndUnlockItem = useUpdateAndUnlockTemporalCodelistItem()
    const mutationUpdateItemLangExtended = useUpdateCodelistItemLangExtended()
    const mutationCreateItem = useCreateCodelistItem()
    const mutationOriginalCodelistItemHook = useGetOriginalCodelistItemHook()
    const mutationItemAction = useProcessItemAction()
    const getTemporalItemWithLock = useGetTemporalCodelistItemWithLockHook()
    const mutationTemporalItemWithLock = useMutation({
        mutationFn: (variables: { code: string; itemCode: string }) => {
            return getTemporalItemWithLock(variables.code, variables.itemCode)
        },
    })

    const nextItemCode = useGetNextItemCodeHook()
    const setDefaultRefId = async () => {
        if (isCodelistAutoincrementValid) {
            const itemCode = await nextItemCode(codelistId)
            const finalRefId = getItemCodelistRefId(codelistPrefix ?? '', itemCode)
            const newDefaultValues = {
                ...mapCodeListItemToForm({ itemUri: finalRefId, itemCode: itemCode }, workingLanguage),
            }
            setDefaultValues(newDefaultValues)
        }
    }

    const fetchAndSetDefaultValues = async () => {
        if (item?.codelistItemState === CodeListItemState.READY_TO_PUBLISH) {
            await mutationItemAction.mutateAsync({
                code: codeListCode,
                itemCode: item.itemCode ?? '',
                params: { action: ApiCodeListItemsActions.CODELIST_ITEM_BACK_FROM_READY_TO_PUBLISH },
            })
            if (mutationItemAction.isError) return {}
        }

        const lockedItemApiResult = await mutationTemporalItemWithLock.mutateAsync({ code: codeListCode, itemCode: item?.itemCode ?? '' })
        setLockedItem(lockedItemApiResult)
        if (!lockedItemApiResult || mutationTemporalItemWithLock.isError) return {}

        const newDefaultValues = {
            ...mapCodeListItemToForm(lockedItemApiResult || {}, workingLanguage),
        }

        if (!newDefaultValues.order) {
            newDefaultValues.order = defaultOrderValue
        }

        setDefaultValues(newDefaultValues)
    }

    const { register, handleSubmit, formState, control, setValue } = useForm<IItemForm>({
        resolver: yupResolver(schema),
        values: defaultValues,
    })

    const onSubmitError = (error: unknown) => {
        const [submitErrorMessage] = getErrorTranslateKeys([error as { message: string }])
        setIsError(true)
        setErrorMessage(submitErrorMessage)
    }

    const handleApiSubmitItem = async (form: IItemForm) => {
        if (form.id) {
            // edit
            const mappedItem = mapFormToCodeListItem(workingLanguage, form, item)
            const original = await mutationOriginalCodelistItemHook(codeListCode, mappedItem.itemCode || '')

            if (
                original?.codelistItemNames?.some((name) => name.language === workingLanguage) ||
                lockedItem?.codelistItemNames?.some((name) => name.language === workingLanguage)
            ) {
                // update existing
                await mutationUpdateAndUnlockItem
                    .mutateAsync({ code: codeListCode, itemCode: mappedItem.itemCode || '', data: mappedItem })
                    .then(() => setIsSuccess(true))
                    .catch(onSubmitError)
                    .finally(close)
            } else {
                // add language version
                const strippedItem = removeOtherLanguagesFromItem(mappedItem, workingLanguage)
                await mutationCreateItemLangExtended.mutateAsync({
                    code: codeListCode,
                    itemCode: strippedItem.itemCode || '',
                    data: strippedItem,
                    actualLang: workingLanguage,
                })
                await mutationUpdateItemLangExtended.mutateAsync({ code: codeListCode, itemCode: strippedItem.itemCode || '', data: strippedItem })
                const submitMutations = [mutationUpdateItemLangExtended, mutationCreateItemLangExtended]
                const isSubmitError = submitMutations.some((mutation) => mutation.error)
                if (isSubmitError) {
                    const [submitErrorMessage] = getErrorTranslateKeys(submitMutations.map((mutation) => mutation.error as { message: string }))
                    setIsError(true)
                    setErrorMessage(submitErrorMessage)
                } else {
                    setIsSuccess(true)
                }
                close()
            }
        } else {
            // create
            const mappedItem = mapFormToCodeListItem(workingLanguage, form)
            mutationCreateItem
                .mutateAsync({ code: codeListCode, data: mappedItem })
                .then(() => setIsSuccess(true))
                .catch(onSubmitError)
                .finally(close)
        }
    }
    const submitMutations = useMemo(
        () => [mutationCreateItem, mutationUpdateAndUnlockItem, mutationUpdateItemLangExtended, mutationCreateItemLangExtended],
        [mutationCreateItem, mutationCreateItemLangExtended, mutationUpdateAndUnlockItem, mutationUpdateItemLangExtended],
    )
    const loadMutations = [mutationItemAction, mutationTemporalItemWithLock]

    const [loadErrorMessage] = getErrorTranslateKeys(loadMutations.map((mutation) => mutation.error as { message: string }))
    const isLoadingMutation = submitMutations.some((mutation) => mutation.isLoading)
    const isLoadingFormData = loadMutations.some((mutation) => mutation.isLoading)

    useEffect(() => {
        if (isEdit) {
            fetchAndSetDefaultValues()
        } else {
            setDefaultRefId()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item])

    return (
        <QueryFeedback loading={isLoadingFormData} indicatorProps={{ fullscreen: true }}>
            <BaseModal isOpen={isOpen} close={close}>
                <TextHeading size="L">{t(`codeListDetail.title.${isEdit ? 'editItem' : 'addNewItem'}`)}</TextHeading>
                <TextWarning>
                    {t(`codeListDetail.warning.workingLanguageNotice`, { language: t(`codeListDetail.languages.${workingLanguage}`) })}
                </TextWarning>
                {isLoadingMutation && <LoadingIndicator label={t('feedback.saving')} />}
                {loadErrorMessage && (
                    <QueryFeedback
                        loading={false}
                        error={!!loadErrorMessage}
                        errorProps={{ errorMessage: t([loadErrorMessage, 'feedback.queryErrorMessage']) }}
                        showSupportEmail
                    />
                )}
                {!loadErrorMessage && (
                    <form onSubmit={handleSubmit(handleApiSubmitItem)} noValidate>
                        {isEdit && <input hidden {...register(CodeListItemFormEnum.ID)} id={CodeListItemFormEnum.ID} />}
                        <Input
                            required
                            label={getDescription('Gui_Profil_ZC_nazov_polozky', language, attributeProfile)}
                            info={getName('Gui_Profil_ZC_nazov_polozky', language, attributeProfile)}
                            id={CodeListItemFormEnum.NAME}
                            {...register(CodeListItemFormEnum.NAME)}
                            error={formState.errors?.[CodeListItemFormEnum.NAME]?.message}
                            isInModal
                        />
                        <Input
                            required
                            disabled={isEdit || isCodelistAutoincrementValid}
                            label={getDescription('Gui_Profil_ZC_kod_polozky', language, attributeProfile)}
                            info={getName('Gui_Profil_ZC_kod_polozky', language, attributeProfile)}
                            id={CodeListItemFormEnum.CODE}
                            {...register(CodeListItemFormEnum.CODE)}
                            error={formState.errors?.[CodeListItemFormEnum.CODE]?.message}
                            isInModal
                        />
                        <Input
                            label={getDescription('Gui_Profil_ZC_skrateny_nazov_polozky', language, attributeProfile)}
                            info={getName('Gui_Profil_ZC_skrateny_nazov_polozky', language, attributeProfile)}
                            id={CodeListItemFormEnum.SHORTENED_NAME}
                            {...register(CodeListItemFormEnum.SHORTENED_NAME)}
                            error={formState.errors?.[CodeListItemFormEnum.SHORTENED_NAME]?.message}
                            isInModal
                        />
                        <Input
                            label={getDescription('Gui_Profil_ZC_skratka_nazvu_polozky', language, attributeProfile)}
                            info={getName('Gui_Profil_ZC_skratka_nazvu_polozky', language, attributeProfile)}
                            id={CodeListItemFormEnum.ABBREVIATED_NAME}
                            {...register(CodeListItemFormEnum.ABBREVIATED_NAME)}
                            error={formState.errors?.[CodeListItemFormEnum.ABBREVIATED_NAME]?.message}
                            isInModal
                        />
                        <Input
                            label={getDescription('Gui_Profil_ZC_uri', language, attributeProfile)}
                            info={getName('Gui_Profil_ZC_uri', language, attributeProfile)}
                            id={CodeListItemFormEnum.REF_IDENT}
                            {...register(CodeListItemFormEnum.REF_IDENT)}
                            error={formState.errors?.[CodeListItemFormEnum.REF_IDENT]?.message}
                            disabled={isCodelistAutoincrementValid}
                            isInModal
                        />
                        <Input
                            label={getDescription('Gui_Profil_ZC_doplnujuci_obsah', language, attributeProfile)}
                            info={getName('Gui_Profil_ZC_doplnujuci_obsah', language, attributeProfile)}
                            id={CodeListItemFormEnum.ADDITIONAL_CONTENT}
                            {...register(CodeListItemFormEnum.ADDITIONAL_CONTENT)}
                            error={formState.errors?.[CodeListItemFormEnum.ADDITIONAL_CONTENT]?.message}
                            isInModal
                        />
                        <Input
                            label={getDescription('Gui_Profil_ZC_merna_jednotka', language, attributeProfile)}
                            info={getName('Gui_Profil_ZC_merna_jednotka', language, attributeProfile)}
                            id={CodeListItemFormEnum.UNIT_OF_MEASURE}
                            {...register(CodeListItemFormEnum.UNIT_OF_MEASURE)}
                            error={formState.errors?.[CodeListItemFormEnum.UNIT_OF_MEASURE]?.message}
                            isInModal
                        />
                        <TextArea
                            label={getDescription('Gui_Profil_ZC_poznamka_pre_polozku', language, attributeProfile)}
                            info={getName('Gui_Profil_ZC_poznamka_pre_polozku', language, attributeProfile)}
                            id={CodeListItemFormEnum.NOTE}
                            rows={3}
                            {...register(CodeListItemFormEnum.NOTE)}
                            error={formState.errors?.[CodeListItemFormEnum.NOTE]?.message}
                            isInModal
                        />
                        <Input
                            label={getDescription('Gui_Profil_ZC_zahrna', language, attributeProfile)}
                            info={getName('Gui_Profil_ZC_zahrna', language, attributeProfile)}
                            id={CodeListItemFormEnum.INCLUDE}
                            {...register(CodeListItemFormEnum.INCLUDE)}
                            error={formState.errors?.[CodeListItemFormEnum.INCLUDE]?.message}
                            isInModal
                        />
                        <Input
                            label={getDescription('Gui_Profil_ZC_tiez_zahrna', language, attributeProfile)}
                            info={getName('Gui_Profil_ZC_tiez_zahrna', language, attributeProfile)}
                            id={CodeListItemFormEnum.INCLUDE_ALSO}
                            {...register(CodeListItemFormEnum.INCLUDE_ALSO)}
                            error={formState.errors?.[CodeListItemFormEnum.INCLUDE_ALSO]?.message}
                            isInModal
                        />
                        <Input
                            label={getDescription('Gui_Profil_ZC_vylucuje', language, attributeProfile)}
                            info={getName('Gui_Profil_ZC_vylucuje', language, attributeProfile)}
                            id={CodeListItemFormEnum.EXCLUDE}
                            {...register(CodeListItemFormEnum.EXCLUDE)}
                            error={formState.errors?.[CodeListItemFormEnum.EXCLUDE]?.message}
                            isInModal
                        />
                        <DateInput
                            label={getDescription('Gui_Profil_ZC_datum_platnosti_polozky', language, attributeProfile)}
                            info={getName('Gui_Profil_ZC_datum_platnosti_polozky', language, attributeProfile)}
                            id={CodeListItemFormEnum.VALID_FROM}
                            {...register(CodeListItemFormEnum.VALID_FROM)}
                            error={formState.errors?.[CodeListItemFormEnum.VALID_FROM]?.message}
                            control={control}
                            setValue={setValue}
                            isInModal
                        />
                        <DateInput
                            required
                            label={getDescription('Gui_Profil_ZC_zaciatok_ucinnosti_polozky', language, attributeProfile)}
                            info={getName('Gui_Profil_ZC_zaciatok_ucinnosti_polozky', language, attributeProfile)}
                            id={CodeListItemFormEnum.EFFECTIVE_FROM}
                            {...register(CodeListItemFormEnum.EFFECTIVE_FROM)}
                            error={formState.errors?.[CodeListItemFormEnum.EFFECTIVE_FROM]?.message}
                            control={control}
                            setValue={setValue}
                            isInModal
                        />
                        <DateInput
                            label={getDescription('Gui_Profil_ZC_koniec_ucinnosti_polozky', language, attributeProfile)}
                            info={getName('Gui_Profil_ZC_koniec_ucinnosti_polozky', language, attributeProfile)}
                            id={CodeListItemFormEnum.EFFECTIVE_TO}
                            {...register(CodeListItemFormEnum.EFFECTIVE_TO)}
                            error={formState.errors?.[CodeListItemFormEnum.EFFECTIVE_TO]?.message}
                            control={control}
                            setValue={setValue}
                            isInModal
                        />
                        <Input
                            type="number"
                            label={getDescription('Gui_Profil_ZC_logicke_poradie_polozky', language, attributeProfile)}
                            info={`${getName('Gui_Profil_ZC_logicke_poradie_polozky', language, attributeProfile)} (${t('input.number.hint')})`}
                            id={CodeListItemFormEnum.ORDER}
                            {...register(CodeListItemFormEnum.ORDER)}
                            error={formState.errors?.[CodeListItemFormEnum.ORDER]?.message}
                            isInModal
                            onKeyDown={onKeyDownOfNumberInput}
                        />
                        <CheckBox
                            label={getDescription('Gui_Profil_ZC_legislativna_uznatelnost', language, attributeProfile)}
                            info={getName('Gui_Profil_ZC_legislativna_uznatelnost', language, attributeProfile)}
                            id={CodeListItemFormEnum.LEGISLATIVE_VALIDITY}
                            {...register(CodeListItemFormEnum.LEGISLATIVE_VALIDITY)}
                            error={formState.errors?.[CodeListItemFormEnum.LEGISLATIVE_VALIDITY]?.message}
                            isInModal
                        />
                        <ModalButtons submitButtonLabel={t('codeListDetail.button.save')} closeButtonLabel={t('form.cancel')} onClose={close} />
                    </form>
                )}
            </BaseModal>
        </QueryFeedback>
    )
}
