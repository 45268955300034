import { BreadCrumbs, Button, HomeIcon } from '@isdd/idsk-ui-kit/index'
import { Tab, Tabs } from '@isdd/idsk-ui-kit/tabs/Tabs'
import { useReadConfigurationItem, useReadRelationshipList } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { FlexColumnReverseWrapper } from '@isdd/metais-common/components/flex-column-reverse-wrapper/FlexColumnReverseWrapper'
import {
    CI_ITEM_QUERY_KEY,
    ENTITY_KS,
    ENTITY_PROJECT,
    INVALIDATED,
    PROFILE_REF_LIFE_CYCLE_END,
    PROFILE_REF_LIFE_CYCLE_START,
    ciInformationTab,
} from '@isdd/metais-common/constants'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { useUserAbility } from '@isdd/metais-common/hooks/permissions/useUserAbility'
import { useGetCiTypeWrapper } from '@isdd/metais-common/hooks/useCiType.hook'
import { ATTRIBUTE_NAME, MutationFeedback, QueryFeedback } from '@isdd/metais-common/index'
import { setDocumentTitle } from '@isdd/metais-common/utils/utils'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import {
    getCiHowToBreadCrumb,
    getDefaultCiEntityTabList,
    useCiDetailPageTitle,
    useCiListPageHeading,
    useGetEntityParamsFromUrl,
} from '@/componentHelpers/ci'
import { MainContentWrapper } from '@/components/MainContentWrapper'
import { ProjectStateContainer } from '@/components/containers/ProjectStateContainer'
import { RelationsListContainer } from '@/components/containers/RelationsListContainer'
import { CiPermissionsWrapper } from '@/components/permissions/CiPermissionsWrapper'
import { ProjectEntityIdHeader } from '@/components/views/ci/project/ProjectEntityIdHeader'
import { ProjectStateView } from '@/components/views/ci/project/ProjectStateView'
import { useCiCheckEntityTypeRedirectHook } from '@/hooks/useCiCheckEntityTypeRedirect.hook'

const ProjectEntityDetailPage: React.FC = () => {
    const { t } = useTranslation()
    const { isActionSuccess } = useActionSuccess()
    const { entityId } = useGetEntityParamsFromUrl()
    const navigate = useNavigate()
    const location = useLocation()
    const [selectedTab, setSelectedTab] = useState<string>()

    const {
        state: { user },
    } = useAuth()

    const isUserLogged = !!user

    const userAbility = useUserAbility()

    const { data: ciTypeData, isLoading: isCiTypeDataLoading, isError: isCiTypeDataError } = useGetCiTypeWrapper(ENTITY_PROJECT)
    const { mutateAsync: loadKsPhaseAttributes } = useReadRelationshipList()

    const {
        data: ciItemData,
        isLoading: isCiItemDataLoading,
        isError: isCiItemDataError,
        refetch,
    } = useReadConfigurationItem(entityId ?? '', {
        query: {
            queryKey: [CI_ITEM_QUERY_KEY, entityId],
        },
    })
    useCiCheckEntityTypeRedirectHook(ciItemData, ENTITY_PROJECT)

    const { getHeading } = useCiListPageHeading(ciTypeData?.name ?? '', t)
    const { getHeading: getHeadingDetail } = useCiDetailPageTitle(
        ciTypeData?.name ?? '',
        ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov],
        t,
    )
    setDocumentTitle(getHeadingDetail())
    const tabList: Tab[] = getDefaultCiEntityTabList({ userAbility, entityName: ENTITY_PROJECT, entityId: entityId ?? '', t })

    const isInvalidated = ciItemData?.metaAttributes?.state === INVALIDATED

    if (isUserLogged) {
        tabList.splice(2, 0, {
            id: 'activities',
            path: `/ci/${ENTITY_PROJECT}/${entityId}/activities`,
            title: t('ciType.activities'),
            content: <Outlet />,
        })
    }

    return (
        <>
            <BreadCrumbs
                withWidthContainer
                links={[
                    { label: t('breadcrumbs.home'), href: '/', icon: HomeIcon },
                    ...getCiHowToBreadCrumb(ENTITY_PROJECT, t),

                    { label: getHeading(), href: `/ci/${ENTITY_PROJECT}` },
                    {
                        label: ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] ?? t('breadcrumbs.noName'),
                        href: `/ci/${ENTITY_PROJECT}/${entityId}`,
                    },
                ]}
            />

            <MainContentWrapper>
                {isCiItemDataError ? (
                    <QueryFeedback loading={false} error={isCiItemDataError} />
                ) : (
                    <CiPermissionsWrapper entityId={entityId ?? ''} entityName={ENTITY_PROJECT ?? ''}>
                        <QueryFeedback loading={isCiItemDataLoading || isCiTypeDataLoading}>
                            <FlexColumnReverseWrapper>
                                <ProjectEntityIdHeader
                                    editButton={
                                        <Button
                                            label={t('ciType.editButton')}
                                            onClick={() => navigate(`/ci/${ENTITY_PROJECT}/${entityId}/edit`, { state: location.state })}
                                        />
                                    }
                                    entityData={ciItemData}
                                    entityName={ENTITY_PROJECT}
                                    entityId={entityId ?? ''}
                                    entityItemName={ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] ?? 'Detail'}
                                    ciRoles={ciTypeData?.roleList ?? []}
                                    isInvalidated={isInvalidated}
                                    refetchCi={refetch}
                                />
                                <QueryFeedback loading={false} error={isCiItemDataError || isCiTypeDataError} />
                                <MutationFeedback
                                    success={isActionSuccess.value && isActionSuccess.additionalInfo?.type !== 'relationCreated'}
                                    successMessage={
                                        isActionSuccess.additionalInfo?.type === 'create'
                                            ? t('mutationFeedback.successfulCreated')
                                            : t('mutationFeedback.successfulUpdated')
                                    }
                                />
                            </FlexColumnReverseWrapper>
                            {user && ciItemData && (
                                <ProjectStateContainer
                                    ciData={ciItemData}
                                    configurationItemId={entityId ?? ''}
                                    View={(props) => <ProjectStateView {...props} />}
                                />
                            )}
                            <Tabs tabList={tabList} onSelect={(selected) => setSelectedTab(selected.id)} />
                            {selectedTab === ciInformationTab && (
                                <RelationsListContainer
                                    loadKsPhaseAttributes={loadKsPhaseAttributes}
                                    entityId={entityId ?? ''}
                                    technicalName={ENTITY_PROJECT}
                                    entityName={ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov]}
                                    tabsToShowRelAttributes={[
                                        { entityName: ENTITY_KS, attributes: [PROFILE_REF_LIFE_CYCLE_START, PROFILE_REF_LIFE_CYCLE_END] },
                                    ]}
                                />
                            )}
                        </QueryFeedback>
                    </CiPermissionsWrapper>
                )}
            </MainContentWrapper>
        </>
    )
}

export default ProjectEntityDetailPage
