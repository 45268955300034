import { TFunction } from 'i18next'

import { ENTITY_TRAINING } from '@isdd/metais-common/constants'

export enum MetainformationColumns {
    CREATED_AT = 'createdAt',
    LAST_MODIFIED_AT = 'lastModifiedAt',
    STATE = 'state',
    OWNER = 'owner',
    HEARTBEAT_STATUS = 'Service_Heartbeat_Status',
}

export enum MetaInformationTypes {
    OWNER = 'OWNER',
    STATE = 'STATE',
    CREATED_AT = 'CREATED_AT',
    LAST_MODIFIED = 'LAST_MODIFIED',
}

const OWNER_LABEL = 'ciType.meta.owner'
const GESTOR_LABEL = 'ciType.meta.gestor'
const MANAGER_LABEL = 'ciType.meta.manager'
const SOLVER_LABEL = 'ciType.meta.solver'
const PROVIDER_LABEL = 'ciType.meta.provider'

const labelMap: { [key: string]: string } = {
    AS: MANAGER_LABEL,
    ISVS: MANAGER_LABEL,
    ZC: GESTOR_LABEL,
    KS: GESTOR_LABEL,
    Projekt: SOLVER_LABEL,
    Training: GESTOR_LABEL,
    ObjektEvidencie: PROVIDER_LABEL,
    AtributObjektuEvidencie: PROVIDER_LABEL,
}

const CHANGE_OWNER_LABEL = 'ciType.meta.changeOwner'
const CHANGE_GESTOR_LABEL = 'ciType.meta.changeGestor'
const CHANGE_MANAGER_LABEL = 'ciType.meta.changeManager'
const CHANGE_SOLVER_LABEL = 'ciType.meta.changeSolver'
const CHANGE_PROVIDER_LABEL = 'ciType.meta.changeProvider'

const changeLabelMap: { [key: string]: string } = {
    AS: CHANGE_MANAGER_LABEL,
    ISVS: CHANGE_MANAGER_LABEL,
    ZC: CHANGE_GESTOR_LABEL,
    KS: CHANGE_GESTOR_LABEL,
    Projekt: CHANGE_SOLVER_LABEL,
    Training: CHANGE_GESTOR_LABEL,
    ObjektEvidencie: CHANGE_PROVIDER_LABEL,
    AtributObjektuEvidencie: CHANGE_PROVIDER_LABEL,
}

const changeOwnerTypeMap: { [key: string]: string } = {
    AS: 'manager',
    ISVS: 'manager',
    ZC: 'gestor',
    KS: 'gestor',
    Projekt: 'solver',
    Training: 'gestor',
    ObjektEvidencie: 'provider',
    AtributObjektuEvidencie: 'provider',
}

type GetMetaAttributesProps = {
    t: TFunction
    entityName?: string
}

export const getOwnerLabel = (t: TFunction, entityName?: string) => {
    if (!entityName) return t(OWNER_LABEL)
    const labelKey = labelMap[entityName] ? labelMap[entityName] : OWNER_LABEL
    return t(labelKey)
}

export const getChangeOwnerLabel = (t: TFunction, entityName?: string) => {
    if (!entityName) return t(CHANGE_OWNER_LABEL)
    const labelKey = changeLabelMap[entityName] ? changeLabelMap[entityName] : CHANGE_OWNER_LABEL
    return t(labelKey)
}

export const resolveEntityTypeForTranslations = (entityType: string) => {
    return Object.keys(changeLabelMap).includes(entityType) ? changeOwnerTypeMap[entityType] : 'owner'
}

export const getCiDefaultMetaAttributes = ({ t, entityName }: GetMetaAttributesProps) => {
    const defaultCiMetaAttributes = {
        name: t('ciType.meta.metaInformationsName'),
        attributes: [
            {
                name: t('ciType.meta.lastModifiedAt'),
                engName: t('ciType.meta.lastModifiedAt'),
                technicalName: MetainformationColumns.LAST_MODIFIED_AT,
                invisible: false,
                valid: true,
                attributeTypeEnum: MetaInformationTypes.LAST_MODIFIED as string,
            },
            {
                name: t('ciType.meta.createdAt'),
                engName: t('ciType.meta.createdAt'),
                technicalName: MetainformationColumns.CREATED_AT,
                invisible: false,
                valid: true,
                attributeTypeEnum: MetaInformationTypes.CREATED_AT as string,
            },
            ...(entityName === ENTITY_TRAINING
                ? []
                : [
                      {
                          name: getOwnerLabel(t, entityName),
                          engName: getOwnerLabel(t, entityName),
                          technicalName: MetainformationColumns.OWNER,
                          invisible: false,
                          valid: true,
                          attributeTypeEnum: MetaInformationTypes.OWNER as string,
                      },
                  ]),
            {
                name: t('ciType.meta.state'),
                engName: t('ciType.meta.state'),
                technicalName: MetainformationColumns.STATE,
                invisible: false,
                valid: true,
                attributeTypeEnum: MetaInformationTypes.STATE as string,
            },
        ],
    }

    return defaultCiMetaAttributes
}

export const getRefRegsDefaultMetaAttributes = (t: TFunction<'translation', undefined, 'translation'>) => {
    const defaultCiMetaAttributes = {
        name: t('ciType.meta.metaInformationsName'),
        attributes: [
            {
                name: t('ciType.meta.state'),
                technicalName: MetainformationColumns.STATE,
            },
        ],
    }
    return defaultCiMetaAttributes
}
