import { ConfigurationItemUiAttributes } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { EnumItem } from '@isdd/metais-common/api/generated/enums-repo-swagger'

export const getEliUri = (watch: ConfigurationItemUiAttributes, zz?: EnumItem, withoutDefaults?: boolean) => {
    const getStringWithSlash = (str: string | number | null, withSlash = true) => {
        if (str) {
            return `${str}${withSlash ? '/' : ''}`
        }
        return ''
    }

    const zzName = watch.Profil_AtributObjektuEvidencie_zdroj ? zz?.value : null
    return (
        `http://data.gov.sk/id/eli/sk/` +
        getStringWithSlash(encodeURIComponent(zzName ?? '') || 'zz') +
        getStringWithSlash(watch.Profil_AtributObjektuEvidencie_rok || (withoutDefaults ? '' : 'rok')) +
        getStringWithSlash(watch.Profil_AtributObjektuEvidencie_cislo || (withoutDefaults ? '' : 'cislo')) +
        getStringWithSlash(watch.Profil_AtributObjektuEvidencie_paragraf || (withoutDefaults ? '' : 'paragraf')) +
        getStringWithSlash(watch.Profil_AtributObjektuEvidencie_odsek || (withoutDefaults ? '' : 'odsek')) +
        getStringWithSlash(watch.Profil_AtributObjektuEvidencie_pismeno || (withoutDefaults ? '' : 'pismeno')) +
        getStringWithSlash(encodeURIComponent(watch.Gen_Profil_nazov) || 'nazov', false)
    )
}
