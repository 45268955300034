import { BreadCrumbs, HomeIcon } from '@isdd/idsk-ui-kit/index'
import { useTranslation } from 'react-i18next'
import { FooterRouteNames } from '@isdd/metais-common/navigation/routeNames'

import { MainContentWrapper } from '@/components/MainContentWrapper'
import { SiteMapView } from '@/components/views/siteMap/SiteMapView'

const SiteMapPage = () => {
    const { t } = useTranslation()

    return (
        <>
            <BreadCrumbs
                withWidthContainer
                links={[
                    { label: t('breadcrumbs.home'), href: '/', icon: HomeIcon },
                    { label: t('footer.webResidencyMap'), href: FooterRouteNames.SITEMAP },
                ]}
            />

            <MainContentWrapper>
                <SiteMapView />
            </MainContentWrapper>
        </>
    )
}

export default SiteMapPage
