import { ConfigurationItemUiAttributes, useStoreConfigurationItem, useStoreGraph } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { EnumType } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { CiCode, CiType } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { SelectPublicAuthorityAndRole } from '@isdd/metais-common/common/SelectPublicAuthorityAndRole'
import { useGetStatus } from '@isdd/metais-common/hooks/useGetRequestStatus'
import { useScroll } from '@isdd/metais-common/hooks/useScroll'
import { MutationFeedback, QueryFeedback } from '@isdd/metais-common/index'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { useLocation, useNavigate } from 'react-router-dom'

import { useCiCreateEditOnStatusSuccess, useCiCreateUpdateOnSubmit } from './createEntityHelpers'
import { CreateKrisEntityForm } from './CreateKrisEntityForm'

import { PublicAuthorityState, RoleState } from '@/hooks/usePublicAuthorityAndRole.hook'

export interface AttributesData {
    ciTypeData: CiType | undefined
    constraintsData: (EnumType | undefined)[]
    unitsData?: EnumType
}

export interface CreateEntityData {
    attributesData: AttributesData
    generatedEntityId: CiCode | undefined
    ownerId?: string
}

interface ICreateEntity {
    entityName: string
    data: CreateEntityData
    roleState?: RoleState
    publicAuthorityState?: PublicAuthorityState
    updateCiItemId?: string
    defaultItemAttributeValues?: ConfigurationItemUiAttributes
}

export const CreateKrisEntity: React.FC<ICreateEntity> = ({
    data,
    entityName,
    updateCiItemId,
    defaultItemAttributeValues,
    roleState,
    publicAuthorityState,
}) => {
    const { t } = useTranslation()
    const isUpdate = !!updateCiItemId

    const navigate = useNavigate()
    const { setIsActionSuccess } = useActionSuccess()

    const { attributesData, generatedEntityId, ownerId } = data
    const { constraintsData, ciTypeData, unitsData } = attributesData
    const [ciUuid, setCiUuid] = useState<string>()
    const location = useLocation()

    const onStatusSuccess = useCiCreateEditOnStatusSuccess()
    const { isError: isRedirectError, isLoading: isRedirectLoading, isProcessedError, getRequestStatus, isTooManyFetchesError } = useGetStatus()
    const storeGraph = useStoreGraph({
        mutation: {
            onSuccess: (res) => {
                getRequestStatus(res.requestId ?? '', () => {
                    const toPath = `/ci/${entityName}/${ciUuid}`
                    setIsActionSuccess({ value: true, path: toPath, additionalInfo: { type: isUpdate ? 'edit' : 'create' } })
                    navigate(toPath, { state: { from: location } })
                })
            },
        },
    })
    const { onSubmit, uploadError, setUploadError, configurationItemId } = useCiCreateUpdateOnSubmit(entityName, undefined, storeGraph)

    useEffect(() => {
        setCiUuid(configurationItemId)
    }, [configurationItemId])

    const storeConfigurationItem = useStoreConfigurationItem({
        mutation: {
            onError() {
                setUploadError(true)
            },
            async onSuccess(successData) {
                if (successData.requestId != null) {
                    await getRequestStatus(successData.requestId, () =>
                        onStatusSuccess({ configurationItemId, isUpdate, entityName, withRedirect: false }),
                    )
                } else {
                    setUploadError(true)
                }
            },
        },
    })
    const { wrapperRef, scrollToMutationFeedback } = useScroll()
    useEffect(() => {
        if (!(isRedirectError || isProcessedError || isRedirectLoading) || isTooManyFetchesError) {
            scrollToMutationFeedback()
        }
    }, [isProcessedError, isRedirectError, isRedirectLoading, isTooManyFetchesError, scrollToMutationFeedback])

    return (
        <>
            <MutationFeedback
                error={storeConfigurationItem.isError}
                mutationProcessingError={isProcessedError}
                mutationTooLong={isTooManyFetchesError}
            />

            <QueryFeedback
                loading={isRedirectLoading || storeConfigurationItem.isLoading || storeGraph.isLoading}
                error={isRedirectError}
                indicatorProps={{
                    label: isUpdate ? t('createEntity.redirectLoadingEdit') : t('createEntity.redirectLoading'),
                }}
                errorProps={{
                    errorMessage: isUpdate ? t('createEntity.redirectErrorEdit') : t('createEntity.redirectError'),
                }}
                withChildren
            >
                <div ref={wrapperRef} />
                {!isUpdate && publicAuthorityState && roleState && (
                    <SelectPublicAuthorityAndRole
                        selectedRole={roleState.selectedRole ?? {}}
                        onChangeAuthority={publicAuthorityState.setSelectedPublicAuthority}
                        onChangeRole={roleState.setSelectedRole}
                        selectedOrg={publicAuthorityState.selectedPublicAuthority}
                        ciRoles={ciTypeData?.roleList ?? []}
                    />
                )}

                <CreateKrisEntityForm
                    entityName={entityName}
                    ciTypeData={ciTypeData}
                    generatedEntityId={generatedEntityId ?? { cicode: '', ciurl: '' }}
                    constraintsData={constraintsData}
                    unitsData={unitsData}
                    uploadError={uploadError}
                    onSubmit={(formData) =>
                        onSubmit({
                            formData,
                            updateCiItemId,
                            storeCiItem: storeConfigurationItem.mutateAsync,
                            ownerId: data.ownerId,
                            generatedEntityId,
                            publicAuthorityState,
                        })
                    }
                    defaultItemAttributeValues={defaultItemAttributeValues}
                    updateCiItemId={updateCiItemId}
                    isProcessing={storeConfigurationItem.isLoading || storeGraph.isLoading}
                    selectedRole={roleState?.selectedRole ?? null}
                    ownerId={ownerId}
                    selectedOrg={publicAuthorityState?.selectedPublicAuthority ?? null}
                />
            </QueryFeedback>
        </>
    )
}
