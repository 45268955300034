import { RowSelectionState } from '@tanstack/react-table'
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'

export interface RowSelection<T> {
    rowSelection: RowSelectionState
    setRowSelection: Dispatch<SetStateAction<RowSelectionState>>
    rowSelectionData: {
        [key: number]: T[]
    }
    setRowSelectionData: Dispatch<
        SetStateAction<{
            [key: number]: T[]
        }>
    >
    getOnRowSelectionChange: (params?: {
        pageNumber: number | undefined
        mapper: (value: string, index: number, array: string[]) => T | undefined | null
    }) => React.Dispatch<React.SetStateAction<RowSelectionState>>
    clearSelectedRows: () => void
    count: number
    list: T[]
    ids: string[]
}

export const useRowSelection = <T>(): RowSelection<T> => {
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
    const [rowSelectionData, setRowSelectionData] = useState<{ [key: number]: T[] }>([])
    const [list, setList] = useState<T[]>([])

    const clearSelectedRows = useCallback(() => {
        setRowSelection({})
        setRowSelectionData([])
    }, [])

    const updateRowSectionData = ({
        selectedRows,
        pageNumber,
        mapper,
    }: {
        selectedRows: RowSelectionState
        pageNumber: number | undefined
        mapper: (value: string, index: number, array: string[]) => T | undefined | null
    }) => {
        setRowSelectionData((old) => {
            const newData = {
                ...old,
                ...{
                    [pageNumber ?? 0]: Object.keys(selectedRows ?? {})
                        .map(mapper)
                        .filter((item): item is T => item !== undefined && item !== null),
                },
            }

            setList(Object.values(newData).flatMap((item) => item.map((i) => i)))
            return newData
        })
    }

    const getOnRowSelectionChange =
        (params?: {
            pageNumber: number | undefined
            mapper: (value: string, index: number, array: string[]) => T | undefined | null
            // callback:
        }): React.Dispatch<React.SetStateAction<RowSelectionState>> =>
        (val: React.SetStateAction<RowSelectionState>) => {
            const { pageNumber, mapper } = params || {}
            setRowSelection?.((prevState) => {
                const selectedRows = typeof val === 'function' ? val(prevState) : val
                if (mapper !== undefined && pageNumber !== undefined) {
                    updateRowSectionData({ selectedRows, pageNumber, mapper })
                }
                return selectedRows
            })
        }

    const count = useMemo(() => Object.keys(rowSelection).length, [rowSelection])
    const ids = useMemo(() => Object.keys(rowSelection), [rowSelection])

    return {
        rowSelection,
        setRowSelection,
        rowSelectionData,
        setRowSelectionData,
        getOnRowSelectionChange,
        clearSelectedRows,
        count,
        list,
        ids,
    }
}
