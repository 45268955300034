import { Button, ButtonGroupRow, ButtonPopup, LoadingIndicator, TextHeading } from '@isdd/idsk-ui-kit'
import { ButtonLink } from '@isdd/idsk-ui-kit/button-link/ButtonLink'
import { Tooltip } from '@isdd/idsk-ui-kit/tooltip/Tooltip'
import { Del } from '@isdd/idsk-ui-kit/typography/Del'
import { ApiError, ConfigurationItemUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { getChangeOwnerLabel } from '@isdd/metais-common/componentHelpers/ci/getCiDefaultMetaAttributes'
import styles from '@isdd/metais-common/components/entity-header/ciEntityHeader.module.scss'
import { ENTITY_OBJEKT_EVIDENCIE } from '@isdd/metais-common/constants'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { Can } from '@isdd/metais-common/hooks/permissions/useAbilityContext'
import { Actions } from '@isdd/metais-common/hooks/permissions/useUserAbility'
import { IBulkActionResult, useBulkAction } from '@isdd/metais-common/hooks/useBulkAction'
import { useScroll } from '@isdd/metais-common/hooks/useScroll'
import { ChangeOwnerBulkModal, InvalidateBulkModal, MutationFeedback, ReInvalidateBulkModal } from '@isdd/metais-common/index'
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ConsumentRelModal } from './evidence-object/consument/ConsumentRelForm'

interface Props {
    entityName: string
    entityId: string
    entityItemName: string
    entityData?: ConfigurationItemUi
    ciRoles: string[]
    isInvalidated: boolean
    refetchCi: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
    ) => Promise<QueryObserverResult<ConfigurationItemUi, ApiError>>
    isRelation?: boolean
    editButton: React.ReactNode
    isEntityFromCodeList?: boolean
}

export const CiEntityIdHeader: React.FC<Props> = ({
    entityData,
    entityId,
    entityItemName,
    ciRoles,
    isInvalidated,
    refetchCi,
    isRelation,
    editButton,
    isEntityFromCodeList,
}) => {
    const { t } = useTranslation()
    const {
        state: { user },
    } = useAuth()
    const { handleReInvalidate, handleInvalidate, errorMessage, isBulkLoading } = useBulkAction(isRelation)
    const [showInvalidate, setShowInvalidate] = useState<boolean>(false)
    const [showReInvalidate, setShowReInvalidate] = useState<boolean>(false)
    const [showChangeOwner, setShowChangeOwner] = useState<boolean>(false)
    const [bulkActionResult, setBulkActionResult] = useState<IBulkActionResult>()
    const [isConusmentOpen, setIsConsumentOpen] = useState(false)
    const focusTriggerRef = useRef<() => void>()

    const isAnyModalOpened = () => [showInvalidate, showReInvalidate, showChangeOwner, isConusmentOpen].includes(true)

    const handleBulkAction = (actionResult: IBulkActionResult) => {
        setBulkActionResult(actionResult)
        refetchCi()
    }

    const handleCloseBulkModal = (actionResult: IBulkActionResult, closeFunction: (value: React.SetStateAction<boolean>) => void) => {
        closeFunction(false)
        handleBulkAction(actionResult)
    }

    const entityListData = entityData ? [entityData] : []

    const { wrapperRef, scrollToMutationFeedback } = useScroll()

    useEffect(() => {
        scrollToMutationFeedback()
    }, [bulkActionResult, scrollToMutationFeedback])

    return (
        <>
            <div ref={wrapperRef}>
                <MutationFeedback
                    success={bulkActionResult?.isSuccess}
                    successMessage={bulkActionResult?.successMessage}
                    error={bulkActionResult?.isError}
                    onMessageClose={() => setBulkActionResult(undefined)}
                />
            </div>
            <div className={styles.headerDiv}>
                {isBulkLoading && <LoadingIndicator fullscreen />}
                <TextHeading size="XL">
                    <Del isInvalid={isInvalidated}>{entityItemName}</Del>
                </TextHeading>

                {user && !isEntityFromCodeList && (
                    <ButtonGroupRow>
                        <Can I={Actions.EDIT} a={`ci.${entityId}`}>
                            {editButton}
                        </Can>
                        {entityData?.type === ENTITY_OBJEKT_EVIDENCIE && (
                            <Button
                                onClick={() => {
                                    setIsConsumentOpen(true)
                                }}
                                variant="secondary"
                                label={t('evidenceObject.consument.openButton')}
                            />
                        )}
                        <ButtonPopup
                            buttonClassName={styles.noWrap}
                            buttonLabel={t('ciType.moreButton')}
                            popupHorizontalPosition="right"
                            manuallyHandledFocusAfterClose
                            onClose={() => {
                                if (!isAnyModalOpened()) {
                                    focusTriggerRef.current?.()
                                }
                            }}
                            popupContent={(_, focusTriggerEl) => {
                                focusTriggerRef.current = focusTriggerEl

                                return (
                                    <div className={styles.buttonLinksDiv}>
                                        <Tooltip
                                            key={'invalidateItem'}
                                            descriptionElement={errorMessage}
                                            disabled={!errorMessage}
                                            position={'top center'}
                                            tooltipContent={(open) => (
                                                <ButtonLink
                                                    disabled={isInvalidated}
                                                    onClick={() => {
                                                        handleInvalidate(
                                                            entityListData,
                                                            () => {
                                                                setShowInvalidate(true)
                                                            },
                                                            open,
                                                        )
                                                    }}
                                                    label={t('ciType.invalidateItem')}
                                                    aria={{ 'aria-haspopup': 'dialog' }}
                                                />
                                            )}
                                        />
                                        <Tooltip
                                            key={'revalidateItem'}
                                            descriptionElement={errorMessage}
                                            position={'top center'}
                                            tooltipContent={(open) => (
                                                <ButtonLink
                                                    disabled={!isInvalidated}
                                                    onClick={() => {
                                                        handleReInvalidate(
                                                            entityListData,
                                                            () => {
                                                                setShowReInvalidate(true)
                                                            },
                                                            open,
                                                        )
                                                    }}
                                                    label={t('ciType.revalidateItem')}
                                                    aria={{ 'aria-haspopup': 'dialog' }}
                                                />
                                            )}
                                        />
                                        <Can I={Actions.CHANGE_OWNER} a={`ci.${entityId}`}>
                                            <ButtonLink
                                                onClick={() => {
                                                    setShowChangeOwner(true)
                                                }}
                                                label={getChangeOwnerLabel(t, entityData?.type)}
                                                aria={{ 'aria-haspopup': 'dialog' }}
                                            />
                                        </Can>
                                    </div>
                                )
                            }}
                        />
                    </ButtonGroupRow>
                )}
                {isBulkLoading && <LoadingIndicator fullscreen />}

                <InvalidateBulkModal
                    items={entityListData}
                    open={showInvalidate}
                    onSubmit={(actionResponse) => handleCloseBulkModal(actionResponse, setShowInvalidate)}
                    onClose={() => {
                        setShowInvalidate(false)
                        focusTriggerRef.current?.()
                    }}
                    isRelation={isRelation}
                />
                <ReInvalidateBulkModal
                    items={entityListData}
                    open={showReInvalidate}
                    onSubmit={(actionResponse) => handleCloseBulkModal(actionResponse, setShowReInvalidate)}
                    onClose={() => {
                        setShowReInvalidate(false)
                        focusTriggerRef.current?.()
                    }}
                    isRelation={isRelation}
                />
                <ChangeOwnerBulkModal
                    entityType={entityData?.type}
                    items={entityListData}
                    open={showChangeOwner}
                    onSubmit={(actionResponse) => handleCloseBulkModal(actionResponse, setShowChangeOwner)}
                    onClose={() => {
                        setShowChangeOwner(false)
                        focusTriggerRef.current?.()
                    }}
                    ciRoles={ciRoles}
                    isRelation={isRelation}
                />
                {entityData?.type === ENTITY_OBJEKT_EVIDENCIE && (
                    <ConsumentRelModal
                        isOpen={isConusmentOpen}
                        onClose={() => {
                            setIsConsumentOpen(false)
                            focusTriggerRef.current?.()
                        }}
                        objectEvidenceId={entityId}
                    />
                )}
            </div>
        </>
    )
}
