import { LoadingIndicator, TextArea, TextHeading } from '@isdd/idsk-ui-kit'
import { UppyFile } from '@uppy/core'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { FieldValues, FormState, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ConfigurationItemUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { RefAttributesRefType } from '@isdd/metais-common/api/generated/dms-swagger'
import styles from '@isdd/metais-common/components/actions-over-table/actionsOverTable.module.scss'
import { FileUpload, FileUploadData, IFileUploadRef } from '@isdd/metais-common/components/FileUpload/FileUpload'
import { ModalButtons } from '@isdd/metais-common/components/modal-buttons/ModalButtons'
import { MutationFeedback } from '@isdd/metais-common/components/mutation-feedback/MutationFeedback'
import { ALLOWED_FILE_TYPES } from '@isdd/metais-common/index'

interface IProjectUploadFileViewProps {
    items?: ConfigurationItemUi[]
    register: UseFormRegister<FieldValues>
    onClose: () => void
    onSubmit: () => void
    formState: FormState<FieldValues>
    fileMetaAttributes: Record<string, unknown>
    isLoading: boolean
    fileUploadRef: React.RefObject<IFileUploadRef>
    onFileUploadSuccess: (data: FileUploadData[]) => void
    onFileUploadFailed: () => void
    duplicateDocNames?: string[]
    errorMessage?: string
    isProcessedError?: boolean
    isTooLongError?: boolean
}

export const ProjectUploadFileView: React.FC<IProjectUploadFileViewProps> = ({
    register,
    onSubmit,
    onClose,
    formState,
    isLoading,
    fileUploadRef,
    fileMetaAttributes,
    onFileUploadSuccess,
    duplicateDocNames,
    onFileUploadFailed,
    errorMessage,
    isProcessedError,
    isTooLongError,
}) => {
    const { t } = useTranslation()
    const [currentFiles, setCurrentFiles] = useState<UppyFile[]>()
    const [duplicateDocNamesError, setDuplicateDocNamesError] = useState<string>('')

    useEffect(() => {
        if (duplicateDocNames && duplicateDocNames.length > 0) {
            setDuplicateDocNamesError(
                t(`duplicateFile${duplicateDocNames.length > 1 ? 's' : ''}`, {
                    docs: duplicateDocNames.join(', '),
                }),
            )
        }
    }, [duplicateDocNames, t])

    return (
        <>
            {isLoading && <LoadingIndicator label={t('form.waitSending')} className={classNames({ [styles.marginTopIndicator]: isLoading })} />}
            <div>
                <TextHeading size="L">{t('bulkActions.addFile.title')}</TextHeading>
            </div>

            <form onSubmit={onSubmit} className={classNames({ [styles.positionRelative]: isLoading })} noValidate>
                <TextArea {...register('note')} label={t('bulkActions.updateFile.reason')} rows={3} />
                <FileUpload
                    errorMessage={errorMessage}
                    ref={fileUploadRef}
                    allowedFileTypes={ALLOWED_FILE_TYPES}
                    multiple
                    fileMetaAttributes={fileMetaAttributes}
                    isUsingUuidInFilePath
                    onUploadSuccess={onFileUploadSuccess}
                    onFileUploadFailed={onFileUploadFailed}
                    setCurrentFiles={setCurrentFiles}
                    refType={RefAttributesRefType.CI}
                />
                <MutationFeedback
                    error={!!duplicateDocNamesError}
                    errorMessage={duplicateDocNamesError}
                    onMessageClose={() => setDuplicateDocNamesError('')}
                    mutationProcessingError={isProcessedError}
                    mutationTooLong={isTooLongError}
                />

                <ModalButtons
                    submitButtonLabel={t('bulkActions.addFile.upload')}
                    disabled={!formState.isValid || (currentFiles && currentFiles.length < 1)}
                    closeButtonLabel={t('button.cancel')}
                    onClose={onClose}
                />
            </form>
        </>
    )
}
