import { useGetEnum } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { STAV_PROJEKTU, FAZA_PROJEKTU, STAV_PROJEKTU_AGILE, FAZA_PROJEKTU_AGILE } from '@isdd/metais-common/constants'

export const useGetProjectPhaseAndStatus = () => {
    const { data: stavProjektu, isLoading: isStavLoading, isError: isStavError } = useGetEnum(STAV_PROJEKTU)
    const { data: fazaProjektu, isLoading: isFazaLoading, isError: isFazaError } = useGetEnum(FAZA_PROJEKTU)
    const { data: stavProjektuAgile, isLoading: isStavAgileLoading, isError: isStavAgileError } = useGetEnum(STAV_PROJEKTU_AGILE)
    const { data: fazaProjektuAgile, isLoading: isFazaAgileLoading, isError: isFazaAgileError } = useGetEnum(FAZA_PROJEKTU_AGILE)

    return {
        stavProjektu,
        fazaProjektu,
        stavProjektuAgile,
        fazaProjektuAgile,
        isLoading: isStavLoading || isFazaAgileLoading || isFazaLoading || isStavAgileLoading,
        isError: isStavError || isFazaAgileError || isFazaError || isStavAgileError,
    }
}
