import { CheckBox, PaginatorWrapper } from '@isdd/idsk-ui-kit/index'
import { CHECKBOX_CELL } from '@isdd/idsk-ui-kit/table/constants'
import { Table } from '@isdd/idsk-ui-kit/table/Table'
import { BASE_PAGE_NUMBER, BASE_PAGE_SIZE } from '@isdd/metais-common/api'
import { ApiCodelistItem, ApiCodelistItemName, useGetCodelistItems } from '@isdd/metais-common/api/generated/codelist-repo-swagger'
import { QueryFeedback } from '@isdd/metais-common/index'
import { ColumnDef, ExpandedState, PaginationState, RowSelectionState } from '@tanstack/react-table'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CodeListItemState } from '@/componentHelpers/codeList'
import { isEffective, selectBasedOnLanguageAndDate } from '@/components/views/codeLists/CodeListDetailUtils'

export interface TableCols extends ApiCodelistItem {
    codelistItemName?: string | boolean
    isEffective?: boolean
    selected?: boolean
}

export const DevTestScreen: React.FC = () => {
    const { t } = useTranslation()

    const [selectedRowIds, setSelectedRowIds] = useState<RowSelectionState>({})
    const [expandedState, setExpandedState] = useState<ExpandedState>({})

    const columns = useMemo<ColumnDef<TableCols>[]>(
        () => [
            {
                id: CHECKBOX_CELL,
                header: ({ table }) => {
                    const rowsWithoutDisabled = table
                        .getRowModel()
                        .rows.filter(
                            (row) =>
                                row.original.codelistItemState !== CodeListItemState.PUBLISHED &&
                                row.original.codelistItemState !== CodeListItemState.READY_TO_PUBLISH,
                        )
                    return (
                        <div className="govuk-checkboxes govuk-checkboxes--small">
                            <CheckBox
                                label=""
                                name="checkbox"
                                id="checkbox-all"
                                onChange={table.getToggleAllRowsSelectedHandler()}
                                disabled={rowsWithoutDisabled.length === 0}
                                checked={table.getIsAllRowsSelected()}
                                aria-label={t('table.selectAllItems')}
                            />
                        </div>
                    )
                },
                cell: ({ row }) => (
                    <div className="govuk-checkboxes govuk-checkboxes--small">
                        <CheckBox
                            label=""
                            name="checkbox"
                            id={`checkbox_${row.id}`}
                            value="true"
                            onClick={(event) => event.stopPropagation()}
                            onChange={row.getToggleSelectedHandler()}
                            checked={row.getIsSelected()}
                            disabled={!row.getCanSelect()}
                            aria-label={t('table.selectItem', {
                                itemName: selectBasedOnLanguageAndDate(row.original.codelistItemNames as ApiCodelistItemName[], 'sk'),
                            })}
                        />
                    </div>
                ),
            },
            {
                id: 'codelistNames',
                header: t('codeListDetail.table.name'),
                size: 150,
                accessorFn: (row) => row.codelistItemName,
                enableSorting: true,
            },
            {
                id: 'itemCode',
                header: t('codeListDetail.table.code'),
                accessorFn: (row) => row.itemCode,
                enableSorting: true,
            },
        ],
        [t],
    )

    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 10,
    })

    const {
        isFetching: isLoadingItems,
        isError: isErrorItems,
        data: itemsData,
    } = useGetCodelistItems(
        'CL010139' ?? '',
        {
            language: 'sk',
            pageNumber: pagination.pageIndex,
            perPage: pagination.pageSize,
        },
        {
            query: {
                select: (data) => ({
                    codelistsItemCount: data.codelistsItemCount,
                    codelistsItems: data.codelistsItems?.map((item) => ({
                        ...item,
                        codelistItemName: selectBasedOnLanguageAndDate(item.codelistItemNames, 'sk') ?? '',
                        isEffective: isEffective(item.codelistItemValidities ?? []),
                    })),
                }),
            },
        },
    )

    return (
        <QueryFeedback loading={isLoadingItems} error={isErrorItems} withChildren>
            <Table<TableCols>
                data={itemsData?.codelistsItems}
                columns={columns}
                expandedRowsState={expandedState}
                onExpandedChange={setExpandedState}
                getExpandedRow={() => {
                    return <div>123</div>
                }}
                enableRowSelection={(row) =>
                    row.original.codelistItemState !== CodeListItemState.PUBLISHED &&
                    row.original.codelistItemState !== CodeListItemState.READY_TO_PUBLISH
                }
                getRowId={(originalRow) => `${originalRow.id}`}
                rowSelection={selectedRowIds}
                onRowSelectionChange={setSelectedRowIds}
            />
            <PaginatorWrapper
                pageNumber={pagination.pageIndex || BASE_PAGE_NUMBER}
                pageSize={pagination.pageSize || BASE_PAGE_SIZE}
                dataLength={20}
                handlePageChange={(filter) => setPagination({ pageIndex: filter.pageNumber ?? 0, pageSize: filter.pageSize ?? 0 })}
            />
        </QueryFeedback>
    )
}
