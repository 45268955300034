import React from 'react'
import classNames from 'classnames'

import { NavMenuItem } from './NavMenuItem'

import { NavSearchBar } from '@isdd/metais-common/components/navbar/navbar-main/NavSearchBar'
import { NavigationItem } from '@isdd/metais-common/navigation/routeNames'

interface INavMenuList {
    menuItems: NavigationItem[]
    closeMenu: () => void
    expandedSubmenu: string | null
    setExpandedSubmenu: React.Dispatch<string | null>
    isAdmin?: boolean
}

export const NavMenuList: React.FC<INavMenuList> = ({ menuItems, closeMenu, expandedSubmenu, setExpandedSubmenu, isAdmin }) => {
    return (
        <>
            <NavSearchBar isMobile afterSubmit={closeMenu} />
            <ul className={classNames('idsk-header-web__nav-list')}>
                {menuItems?.map((menuItem) => {
                    return (
                        <NavMenuItem
                            key={menuItem.title}
                            item={menuItem}
                            closeMenu={closeMenu}
                            expandedSubmenu={expandedSubmenu}
                            setExpandedSubmenu={setExpandedSubmenu}
                            isAdmin={isAdmin}
                        />
                    )
                })}
            </ul>
        </>
    )
}
