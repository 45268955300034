import { PaginatorWrapper, Table } from '@isdd/idsk-ui-kit/index'
import { ColumnSort } from '@isdd/idsk-ui-kit/types'
import { ColumnDef, Row, RowSelectionState } from '@tanstack/react-table'
import React, { SetStateAction, useRef, useState } from 'react'

import { ActionsOverTable, BASE_PAGE_NUMBER, BASE_PAGE_SIZE, HiddenButtons, QueryFeedback } from '@isdd/metais-common/index'
import { DEFAULT_PAGESIZE_OPTIONS } from '@isdd/metais-common/constants'

type ITableWithPagination<T> = {
    tableData: Array<T>
    tableColumns: ColumnDef<T>[]
    sort?: ColumnSort[]
    setSort?: React.Dispatch<SetStateAction<ColumnSort[]>>
    isLoading?: boolean
    isError?: boolean
    hiddenButtons?: Partial<HiddenButtons>
    getRowId?: (originalRow: T, index: number, parent?: Row<T> | undefined) => string
    rowSelection?: RowSelectionState
    onRowSelectionChange?: React.Dispatch<React.SetStateAction<RowSelectionState>>
    enableRowSelection?: boolean | ((row: Row<T>) => boolean)
    enableMultiRowSelection?: boolean
}

export const TableWithPagination: <T>({
    tableData,
    sort,
    tableColumns,
    hiddenButtons,
}: ITableWithPagination<T>) => React.ReactElement<ITableWithPagination<T>> = ({
    tableData,
    sort,
    tableColumns,
    isLoading,
    isError,
    setSort,
    hiddenButtons,
    getRowId,
    rowSelection,
    onRowSelectionChange,
    enableRowSelection,
    enableMultiRowSelection = true,
}) => {
    const [pageSize, setPageSize] = useState<number>(BASE_PAGE_SIZE)
    const [pageNumber, setPageNumber] = useState<number>(BASE_PAGE_NUMBER)
    const dataStart = pageNumber * pageSize - pageSize
    const dataEnd = pageNumber * pageSize
    const data = tableData.slice(dataStart, dataEnd)
    const tableRef = useRef<HTMLTableElement>(null)

    return (
        <div>
            <ActionsOverTable
                entityName=""
                handlePagingSelect={(newPageSize) => setPageSize(Number(newPageSize))}
                hiddenButtons={hiddenButtons}
                pagingOptions={DEFAULT_PAGESIZE_OPTIONS}
                pagination={{
                    pageNumber: pageNumber || BASE_PAGE_NUMBER,
                    pageSize: pageSize || BASE_PAGE_SIZE,
                    dataLength: 0,
                }}
            />
            <QueryFeedback loading={!!isLoading} error={!!isError}>
                <Table
                    tableRef={tableRef}
                    columns={tableColumns}
                    data={data}
                    sort={sort ?? []}
                    onSortingChange={setSort}
                    getRowId={getRowId}
                    rowSelection={rowSelection}
                    onRowSelectionChange={onRowSelectionChange}
                    enableRowSelection={enableRowSelection}
                    enableMultiRowSelection={enableMultiRowSelection}
                />
            </QueryFeedback>
            <PaginatorWrapper
                pageNumber={pageNumber}
                pageSize={pageSize}
                dataLength={tableData?.length ?? 0}
                handlePageChange={(filter) => {
                    setPageNumber(filter?.pageNumber ?? BASE_PAGE_NUMBER)
                    tableRef.current?.scrollIntoView({ behavior: 'smooth' })
                }}
            />
        </div>
    )
}
