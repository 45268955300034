import { ISelectColumnType } from '@isdd/idsk-ui-kit'
import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useMemo } from 'react'

import { useGetColumnData } from '@isdd/metais-common/api/hooks/containers/containerHelpers'
import { FavoriteCiType } from '@isdd/metais-common/api/generated/user-config-swagger'
import { ISimpleTableSelectParams } from '@isdd/metais-common/components/actions-over-table'
import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'

type CustomColumnsReturnType<T> = {
    simpleTableColumnsSelect?: ISimpleTableSelectParams | undefined
    filteredColumns: Array<ColumnDef<T>>
    saveColumnOrder: (columnsArr: FavoriteCiType) => void
    isLoading: boolean
    isError: boolean
}
type CustomColumnsProps<T> = {
    columns: Array<ColumnDef<T>>
    columnsKeys: Array<keyof T | string>
    disabledColumnKeys?: Array<keyof T | string>
    defaultColumnKeys?: Array<keyof T | string>
    ignoreColumnKeys?: Array<keyof T | string>
    entityName: string
}

export const useCustomColumns = <T>({
    columns,
    entityName,
    defaultColumnKeys,
    columnsKeys,
    disabledColumnKeys,
    ignoreColumnKeys,
}: CustomColumnsProps<T>): CustomColumnsReturnType<T> => {
    const { t } = useTranslation()
    const { columnListData, saveColumnSelection, isLoading: isColumnsLoading, isError: isColumnsError } = useGetColumnData(entityName)

    const saveColumnOrder = (columnsArr: FavoriteCiType) => {
        saveColumnSelection(columnsArr)
    }

    const resetColumns = () => {
        saveColumnSelection({ attributes: defaultColumnKeys?.map((k, index) => ({ name: k.toString(), order: index })) })
    }

    const saveFilteredColumns = (columnsArr: ISelectColumnType[]) => {
        saveColumnSelection({
            attributes: columnsArr
                .filter((c) => c.selected)
                .map((c, index) => {
                    return {
                        ...c,
                        order: columnListData.attributes?.find((a) => a.name == c.technicalName)?.order ?? columnsArr.length + index,
                        name: c.technicalName,
                    }
                }),
        })
    }

    const filteredColumns = columns
        .filter((c) => !!columnListData.attributes?.find((a) => a.name === c.id) && !ignoreColumnKeys?.includes(c.id ?? ''))
        .sort((a, b) => {
            const aOrder = columnListData.attributes?.find((att) => att.name === a.id)?.order ?? 0
            const bOrder = columnListData.attributes?.find((att) => att.name === b.id)?.order ?? 0

            return aOrder - bOrder
        })

    const getCustomDefaultColumns = useCallback(() => {
        return (
            columnListData.attributes?.filter((a) => a.name !== ATTRIBUTE_NAME.Gen_Profil_nazov && a.name !== ATTRIBUTE_NAME.Gen_Profil_kod_metais) ??
            []
        )
    }, [columnListData.attributes])

    const hasSavedColumns = useMemo(
        () => columnListData.attributes && getCustomDefaultColumns().length > 0,
        [columnListData.attributes, getCustomDefaultColumns],
    )

    useEffect(() => {
        if (!hasSavedColumns && !isColumnsLoading) {
            resetColumns()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasSavedColumns, isColumnsLoading])
    return {
        simpleTableColumnsSelect: {
            saveSelectedColumns(saveColumns) {
                saveFilteredColumns(saveColumns)
            },
            resetSelectedColumns: resetColumns,
            selectedColumns: columnsKeys
                .map((k) => ({
                    name: t(`${entityName}.${k.toString()}`),
                    technicalName: k.toString(),
                    selected: !!columnListData.attributes?.find((a) => a.name == k),
                    disabled: disabledColumnKeys?.includes(k),
                }))
                .filter((k) => !ignoreColumnKeys?.includes(k.technicalName ?? '')),
        },
        filteredColumns,
        saveColumnOrder,
        isLoading: isColumnsLoading,
        isError: isColumnsError,
    }
}
