import {
    AttributeUi,
    ConfigurationItemUi,
    GraphRequestUi,
    RelationshipUi,
    useReadCiList1,
    useReadCiList1Hook,
    useReadRelationships,
    useStoreGraph,
} from '@isdd/metais-common/api/generated/cmdb-swagger'
import {
    ApiContact,
    ApiDescription,
    ApiReferenceRegister,
    useCreateReferenceRegister1,
    useUpdateReferenceRegister,
    useUpdateReferenceRegisterAccessData,
    useUpdateReferenceRegisterContact,
} from '@isdd/metais-common/api/generated/reference-registers-swagger'
import { Attribute, useGenerateCodeHook, useGetAttributeProfile } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { transformAttributes } from '@isdd/metais-common/api/hooks/containers/containerHelpers'
import { Group, useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { useAttributesHook } from '@isdd/metais-common/hooks/useAttributes.hook'
import { useUserInfo } from '@isdd/metais-common/hooks/useUserInfo'
import { ATTRIBUTE_NAME, Gui_Profil_RR, QueryKeysByEntity } from '@isdd/metais-common/index'
import { useCallback, useMemo, useState } from 'react'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { NavigationSubRoutes } from '@isdd/metais-common/navigation/routeNames'
import { useNavigate } from 'react-router-dom'
import { useGetStatus } from '@isdd/metais-common/hooks/useGetRequestStatus'
import { useQueryClient } from '@tanstack/react-query'
import { getErrorTranslateKey } from '@isdd/metais-common/utils/errorMapper'
import { intersection } from 'lodash'
import {
    DRAFT,
    ENTITY_ATTRIBUT_OBJEKTU_EVIDENCIE,
    INVALIDATED,
    Referencny_register_ma_objekt_evidencie,
    Referencny_register_ma_referencny_udaj,
} from '@isdd/metais-common/constants'
import { RR_MANAGER } from '@isdd/metais-common/hooks/permissions/useUserAbility'
import { v4 } from 'uuid'
import {
    useInvalidateCiListFilteredCache,
    useInvalidateCiItemCache,
    useInvalidateCiHistoryListCache,
    useInvalidateRelationsCountCache,
    useInvalidateDerivedRelationsCountCache,
    useInvalidateRelationsForCiCache,
} from '@isdd/metais-common/hooks/invalidate-cache'

import { useRefRegisterHook } from '@/hooks/useRefRegister.hook'
import { EvidenceObjectsRefRegForm, IRefRegisterCreateView } from '@/components/views/refregisters/createView/RefRegisterCreateView'

interface IView extends IRefRegisterCreateView {
    guiAttributes: Attribute[]
    referenceRegisterData: ApiReferenceRegister | undefined
    isLoading: boolean
    isError: boolean
    errorMessage?: string
    isTooManyFetchesError: boolean
    isProcessedError: boolean
}
interface ICreateRefRegisterContainer {
    entityName: string
    entityId?: string
    View: React.FC<IView>
}

const POFilter = {
    sortBy: 'Gen_Profil_nazov',
    sortType: 'ASC',
    filter: {
        type: ['PO'],
        metaAttributes: {
            state: ['APPROVED_BY_OWNER', 'DRAFT'],
        },
        attributes: [
            {
                name: 'EA_Profil_PO_kategoria_osoby',
                filterValue: [
                    {
                        value: 'c_kategoria_osoba.4',
                        equality: 'EQUAL',
                    },
                ],
            },
        ],
    },
}

const useGetGraphRequestUiFromOEForm = (configurationItemId?: string) => {
    const {
        state: { user },
    } = useAuth()

    const {
        data: readRelationShipsData,
        isLoading: isReadRelationshipsLoading,
        isError: isReadRelationshipsError,
    } = useReadRelationships(configurationItemId ?? '', undefined, {
        query: {
            enabled: !!configurationItemId,
        },
    })

    const generateRelCode = useGenerateCodeHook()
    const getCilist = useReadCiList1Hook()
    const updateCi = async (ciUuid: string, attributes: Record<string, unknown>): Promise<ConfigurationItemUi> => {
        const ci = await getCilist({
            filter: {
                type: [ENTITY_ATTRIBUT_OBJEKTU_EVIDENCIE],
                uuid: [ciUuid],
            },
        })
        const ciAttributes = ci.configurationItemSet?.[0]?.attributes ?? {}

        return {
            attributes: [
                ...Object.keys(ciAttributes).map((key) => ({ name: key, value: ciAttributes[key] })),
                ...Object.keys(attributes).map((key) => ({ name: key, value: attributes[key] })),
            ],
            uuid: ciUuid,
            type: ENTITY_ATTRIBUT_OBJEKTU_EVIDENCIE,
        }
    }

    const getGraphRequestUiFromOEForm = async (oeFormData: EvidenceObjectsRefRegForm, refUuid: string, creator: string): Promise<GraphRequestUi> => {
        const relationshipsData = readRelationShipsData?.startRelationshipSet

        const createRels: RelationshipUi[] = []
        const updateCis: ConfigurationItemUi[] = []
        const recycleRels: string[] = []
        const invalidateRels: RelationshipUi[] = []

        const owner = `${user?.groupData.find((g) => g.orgId === creator)?.roles.find((r) => r.roleName === RR_MANAGER)?.roleUuid}-${creator}`

        const oeKeys = new Map(
            relationshipsData?.filter((rel) => rel.type === Referencny_register_ma_objekt_evidencie).map((rel) => [rel.endUuid, rel]),
        )
        const aoeKeys = new Map(
            relationshipsData?.filter((rel) => rel.type === Referencny_register_ma_referencny_udaj).map((rel) => [rel.endUuid, rel]),
        )

        const pushInvalidateRel = (rel: RelationshipUi) => {
            invalidateRels.push({
                uuid: rel.uuid,
                type: rel.type,
                startUuid: rel.startUuid,
                endUuid: rel.endUuid,
                attributes: rel.attributes,
                owner: rel.metaAttributes?.owner,
            })
        }

        const pushCreateRel = async (endUuid: string, type: string) => {
            const relCode = await generateRelCode(type)
            createRels.push({
                uuid: v4(),
                type,
                attributes: [{ name: ATTRIBUTE_NAME.Gen_Profil_Rel_kod_metais, value: relCode.code as unknown as AttributeUi }],
                startUuid: refUuid,
                endUuid,
                owner,
            })
        }

        //iterate over evidence object that were selected
        for (const key in oeFormData) {
            //if rel to evidence object exists and is invalidated recycle it and remove from oeKeyMap ->
            // -> left over keys means they were not selected by user thus must be invalidated,
            //if rel does not exist create it
            const relToCreateOrRecycle = relationshipsData?.find((rel) => rel.endUuid === key)
            if (relToCreateOrRecycle) {
                if (relToCreateOrRecycle.metaAttributes?.state == INVALIDATED) {
                    recycleRels.push(relToCreateOrRecycle.uuid ?? '')
                }
                oeKeys.delete(key)
            } else {
                await pushCreateRel(key, Referencny_register_ma_objekt_evidencie)
            }

            //do the same process for every aoe key
            for (const subKey in oeFormData?.[key]?.aoeRels ?? []) {
                const isCheckedAsReferentialData = oeFormData[key].aoeRels[subKey].isChecked
                const aoeCiAttributes = oeFormData[key].aoeRels[subKey].attributes
                if (!isCheckedAsReferentialData) continue

                const AOErelToCreateOrRecycle = relationshipsData?.find((rel) => rel.endUuid === subKey)
                if (AOErelToCreateOrRecycle) {
                    if (AOErelToCreateOrRecycle.metaAttributes?.state == INVALIDATED) {
                        recycleRels.push(AOErelToCreateOrRecycle.uuid ?? '')
                    }
                    aoeKeys.delete(subKey)

                    const ciToUpdate = await updateCi(subKey, aoeCiAttributes)
                    updateCis.push(ciToUpdate)
                } else {
                    await pushCreateRel(subKey, Referencny_register_ma_referencny_udaj)
                    const ciToUpdate = await updateCi(subKey, aoeCiAttributes)
                    updateCis.push(ciToUpdate)
                }
            }
        }

        //loop over leftover oe keys and invalidate them
        for (const [, rel] of oeKeys) {
            if (rel.metaAttributes?.state == INVALIDATED) continue
            pushInvalidateRel(rel)
        }
        //loop over leftover aoe keys and invalidate them
        for (const [, aoeRel] of aoeKeys) {
            if (aoeRel.metaAttributes?.state == INVALIDATED) continue
            pushInvalidateRel(aoeRel)
        }

        return {
            storeSet: {
                relationshipSet: createRels.length > 0 ? createRels : undefined,
                configurationItemSet: updateCis.length > 0 ? updateCis : undefined,
            },
            recycleSet: {
                relIdSet: recycleRels.length > 0 ? recycleRels : undefined,
            },
            invalidateSet: {
                relationshipSet: invalidateRels.length > 0 ? invalidateRels : undefined,
            },
        }
    }

    return { getGraphRequestUiFromOEForm, isLoading: isReadRelationshipsLoading, isError: isReadRelationshipsError }
}

export const CreateRefRegisterContainer = ({ entityName, entityId, View }: ICreateRefRegisterContainer) => {
    const { user } = useUserInfo()

    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { getGraphRequestUiFromOEForm } = useGetGraphRequestUiFromOEForm(entityId)
    const { invalidate: invalidateCilistFilteredCache } = useInvalidateCiListFilteredCache()
    const { invalidate: invalidateCiByUuidCache } = useInvalidateCiItemCache()
    const { invalidate: invalidateCiHistoryList } = useInvalidateCiHistoryListCache()
    const { invalidate: invalidateRelCount } = useInvalidateRelationsCountCache()
    const { invalidate: invalidatedDerivedRel } = useInvalidateDerivedRelationsCountCache()
    const { invalidate: invalidateRels } = useInvalidateRelationsForCiCache()

    const { referenceRegisterData, guiAttributes, isLoading: isRefLoading, isError: isRefError } = useRefRegisterHook(entityId)
    const { ciTypeData, isLoading: isAttributesLoading, isError: isAttributesError } = useAttributesHook(entityName)
    const orgIdsWithRights = user?.groupData
        .filter((group: Group) => {
            return (
                intersection(
                    group.roles.map((role) => role.roleName),
                    ciTypeData?.roleList,
                ).length > 0
            )
        })
        .map((org) => org.orgId)
    const userGroupsFilter = {
        filter: {
            metaAttributes: {
                state: [DRAFT],
            },
            uuid: orgIdsWithRights,
        },
    }
    const {
        data: userGroupData,
        isLoading: isUserGroupDataLoading,
        isError,
    } = useReadCiList1({ ...userGroupsFilter }, { query: { enabled: orgIdsWithRights && orgIdsWithRights.length > 0 } })
    const { data: guiData } = useGetAttributeProfile(Gui_Profil_RR)
    const { data: POData } = useReadCiList1({ ...POFilter })

    const [createError, setCreateError] = useState<string | undefined>()
    const { getRequestStatus, isProcessedError, isError: isRedirectError, isLoading: isRedirectLoading, isTooManyFetchesError } = useGetStatus()
    const { setIsActionSuccess } = useActionSuccess()

    const { mutateAsync: saveRefRegAsync, isError: saveMutationIsError } = useCreateReferenceRegister1({
        mutation: {
            onError: (error) => {
                setCreateError(getErrorTranslateKey(error))
            },
        },
    })

    const onUpdateSuccess = useCallback(
        (uuidOfCiWihtRelsToInvalidate?: string[]) => {
            uuidOfCiWihtRelsToInvalidate?.forEach((i) => {
                invalidateCiHistoryList(i)
                invalidateCiByUuidCache(i)
                invalidateRelCount(i)
                invalidatedDerivedRel(i)
                invalidateRels(i)
            })

            invalidateCiHistoryList(entityId ?? '')
            invalidateCiByUuidCache(entityId ?? '')
            invalidateRelCount(entityId ?? '')
            invalidatedDerivedRel(entityId ?? '')
            invalidateRels(entityId ?? '')

            invalidateCilistFilteredCache({ ciType: ENTITY_ATTRIBUT_OBJEKTU_EVIDENCIE })
            queryClient.invalidateQueries(['referenceRegisterData', entityId])
            queryClient.invalidateQueries([QueryKeysByEntity.REFERENCE_REGISTERS])
            setIsActionSuccess({
                value: true,
                path: `${NavigationSubRoutes.REFERENCE_REGISTER}/${entityId}`,
                additionalInfo: { type: 'update' },
            })
            navigate(`${NavigationSubRoutes.REFERENCE_REGISTER}/${entityId}`)
        },
        [
            entityId,
            navigate,
            queryClient,
            setIsActionSuccess,
            invalidateCiByUuidCache,
            invalidateCiHistoryList,
            invalidateCilistFilteredCache,
            invalidateRelCount,
            invalidateRels,
            invalidatedDerivedRel,
        ],
    )

    const {
        mutateAsync: updateRefRegAsync,
        isError: updateMutationIsError,
        isLoading: isUpdating,
    } = useUpdateReferenceRegister({
        mutation: {
            onSuccess: (res) => {
                getRequestStatus(res?.requestId ?? '', onUpdateSuccess)
            },
        },
    })

    const onCreateSuccess = useCallback(() => {
        setIsActionSuccess({
            value: true,
            path: `${NavigationSubRoutes.REFERENCE_REGISTER}`,
            additionalInfo: { type: 'create' },
        })
        queryClient.invalidateQueries([QueryKeysByEntity.REFERENCE_REGISTERS])
        navigate(`${NavigationSubRoutes.REFERENCE_REGISTER}`)
    }, [navigate, queryClient, setIsActionSuccess])

    const { mutateAsync: storeGraph } = useStoreGraph()

    const processRels = async (oeFormData: EvidenceObjectsRefRegForm, refUuid: string, creator: string, onSuccess: () => void) => {
        const data: GraphRequestUi = await getGraphRequestUiFromOEForm(oeFormData, refUuid, creator)
        const result = await storeGraph({
            data: data,
        })
        getRequestStatus(result.requestId ?? '', onSuccess)
    }

    const saveRefRegister = async (formData: ApiReferenceRegister, oeFormData: EvidenceObjectsRefRegForm) => {
        const response = await saveRefRegAsync({
            data: formData,
        })

        getRequestStatus(response?.requestId ?? '', async () =>
            processRels(oeFormData, formData.uuid ?? '', formData.creatorUuid ?? '', onCreateSuccess),
        )
    }

    const updateRefRegister = async (referenceRegisterUuid: string, formData: ApiReferenceRegister) => {
        await updateRefRegAsync({ referenceRegisterUuid, data: formData })
    }

    const {
        mutateAsync: updateContactAsync,
        isError: updateContactMutationIsError,
        isLoading: isContactUpdating,
    } = useUpdateReferenceRegisterContact()
    const updateContact = async (referenceRegisterUuid: string, data: ApiContact, refRegCreator: string, oeFormData: EvidenceObjectsRefRegForm) => {
        const status = await updateContactAsync({ referenceRegisterUuid, data })
        if (status) {
            const oeKeys = Object.keys(oeFormData)
            const aoeKeys: string[] = []

            oeKeys.forEach((key) => {
                const cAoeKeys = Object.keys(oeFormData?.[key]?.aoeRels ?? [])
                cAoeKeys.forEach((aoeK) => aoeKeys.push(aoeK))
            })
            getRequestStatus(status.requestId ?? '', async () =>
                processRels(oeFormData, referenceRegisterUuid, refRegCreator, () => onUpdateSuccess([...oeKeys, ...aoeKeys])),
            )
        }
    }

    const {
        mutateAsync: updateAccessDataAsync,
        isError: updateAccessDataMutationIsError,
        isLoading: isAccessDataUpdating,
    } = useUpdateReferenceRegisterAccessData()
    const updateAccessData = async (referenceRegisterUuid: string, data: ApiDescription) => {
        updateAccessDataAsync({ referenceRegisterUuid, data }).then((res) => {
            getRequestStatus(res?.requestId ?? '', onUpdateSuccess)
        })
    }

    const isLoading = [
        isAttributesLoading,
        isRefLoading,
        isUserGroupDataLoading,
        isRedirectLoading,
        isUpdating,
        isContactUpdating,
        isAccessDataUpdating,
    ].some((item) => item)

    const isMutationError = [
        saveMutationIsError,
        updateMutationIsError,
        updateContactMutationIsError,
        updateAccessDataMutationIsError,
        isAttributesError,
        isRefError,
        isError,
        isRedirectError,
    ].some((item) => item)

    const transformedAttributes = useMemo(
        () => transformAttributes([...(ciTypeData?.attributes ?? []), ...(guiData?.attributes ?? [])]),
        [ciTypeData?.attributes, guiData?.attributes],
    )

    return (
        <View
            userGroupData={userGroupData}
            isLoading={isLoading}
            isError={isMutationError}
            POData={POData}
            referenceRegisterData={referenceRegisterData}
            renamedAttributes={transformedAttributes}
            guiAttributes={guiAttributes}
            isTooManyFetchesError={isTooManyFetchesError}
            isProcessedError={isProcessedError}
            saveRefRegister={saveRefRegister}
            updateRefRegister={updateRefRegister}
            updateContact={updateContact}
            updateAccessData={updateAccessData}
            errorMessage={createError}
        />
    )
}
