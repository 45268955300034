import classNames from 'classnames'
import React, { ReactElement, useCallback, useId } from 'react'
import { useTranslation } from 'react-i18next'
import ReactSelect, { GroupBase, MenuPosition, OptionProps, OptionsOrGroups, SelectInstance, createFilter } from 'react-select'
import { MODAL_TOOLTIP_ZINDEX } from '@isdd/metais-common/constants'

import styles from './select.module.scss'
import { IOption } from './Select'
import { useGetLocalMessages } from './useGetLocalMessages'
import { useKeyEvents } from './useKeyEvents'

import { Tooltip } from '@isdd/idsk-ui-kit/tooltip/Tooltip'
import { GreenCheckMarkIcon } from '@isdd/idsk-ui-kit/assets/images'
import { Control, Menu, Option as ReactSelectDefaultOptionComponent, getExtendedAriaLabel, selectStyles } from '@isdd/idsk-ui-kit/common/SelectCommon'

export interface GroupedOption {
    label: string | ReactElement
    options?: IOption<string>[]
}

interface ISelectProps {
    id?: string
    label: string
    ariaLabel?: string
    name: string
    options: OptionsOrGroups<GroupedOption | undefined, GroupBase<GroupedOption | undefined>>
    option?: (props: OptionProps<IOption<string>>) => JSX.Element
    onChange: (newValue: IOption<string>) => void
    placeholder?: string
    className?: string
    defaultValue?: (IOption<string> | undefined)[]
    value?: IOption<string> | IOption<string>[] | null
    error?: string
    info?: string
    correct?: boolean
    isMulti?: boolean
    disabled?: boolean
    onBlur?: React.FocusEventHandler<HTMLInputElement>
    isClearable?: boolean
    isSearchable?: boolean
    menuPosition?: MenuPosition
    required?: boolean
    focus?: boolean
    tabIndex?: number
    isInModal?: boolean
}

export const SelectWithGroupedOptions = ({
    label,
    ariaLabel,
    name,
    options,
    value,
    defaultValue,
    onChange,
    placeholder,
    className,
    id,
    error,
    info,
    correct,
    disabled,
    onBlur,
    isClearable = true,
    isSearchable = true,
    menuPosition = 'fixed',
    required,
    focus = false,
    tabIndex,
    isInModal = false,
}: ISelectProps) => {
    const { t } = useTranslation()
    const localMessages = useGetLocalMessages()
    const keyEvents = useKeyEvents()

    const filterConfig = {
        ignoreCase: true,
        ignoreAccents: true,
        stringify: (item: IOption<string>) => `${item.label}`,
        trim: true,
    }
    const uniqueId = useId()
    const inputId = id ?? `input-${uniqueId}` // NVDA has problems with ID starting with `useID` `::`, needs to be prefixed
    const errorId = `${inputId}-error`
    const callbackRef = useCallback(
        (inputElement: SelectInstance<GroupedOption | undefined> | null) => {
            if (focus && inputElement) {
                inputElement.focus()
            }
        },
        [focus],
    )

    return (
        <div className={classNames('govuk-form-group', className, { 'govuk-form-group--error': !!error })}>
            <div className={styles.labelDiv}>
                {label && (
                    <label className="govuk-label" htmlFor={id}>
                        {label} {required && t('input.requiredField')}
                    </label>
                )}
                {info && (
                    <Tooltip
                        descriptionElement={info}
                        contentStyle={isInModal ? { zIndex: MODAL_TOOLTIP_ZINDEX } : undefined}
                        altText={t('tooltip', { tooltip: label })}
                    />
                )}
            </div>
            {!!error && (
                <span id={errorId} className="govuk-error-message">
                    {error}
                </span>
            )}
            <div className={styles.inputWrapper}>
                <ReactSelect<GroupedOption | undefined>
                    key={options.length}
                    inputId={inputId}
                    ref={callbackRef}
                    name={name}
                    value={value}
                    defaultValue={defaultValue}
                    placeholder={placeholder || ''}
                    className={classNames('govuk-select', styles.reactSelect, { [styles.disabled]: disabled })}
                    classNames={{ menuList: () => styles.reactSelectMenuList }}
                    components={{ Option: ReactSelectDefaultOptionComponent, Menu, Control }}
                    options={options}
                    filterOption={createFilter(filterConfig)}
                    styles={selectStyles()}
                    unstyled
                    menuPosition={menuPosition}
                    onBlur={onBlur}
                    isClearable={disabled ? false : isClearable}
                    isSearchable={disabled ? false : isSearchable}
                    menuIsOpen={disabled ? false : undefined}
                    onChange={(val) => {
                        onChange(val as IOption<string>)
                    }}
                    aria-label={getExtendedAriaLabel(label, t, true, ariaLabel)}
                    aria-errormessage={errorId}
                    noOptionsMessage={localMessages.noOptionsMessage}
                    ariaLiveMessages={localMessages.ariaLiveMessages}
                    screenReaderStatus={localMessages.screenReaderStatus}
                    loadingMessage={localMessages.loadingMessage}
                    tabIndex={tabIndex}
                    openMenuOnFocus={false}
                    {...keyEvents}
                />
                {correct && <img src={GreenCheckMarkIcon} className={isClearable ? styles.isCorrectWithIcon : styles.isCorrect} alt={t('valid')} />}
            </div>
        </div>
    )
}
