import { TextHeading, BaseModal } from '@isdd/idsk-ui-kit/index'
import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { ConfigurationItemUi, useReadNeighboursConfigurationItems } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { ModalButtons, QueryFeedback } from '@isdd/metais-common/index'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCiHook } from '@isdd/metais-common/hooks/useCi.hook'
import { useAttributesHook } from '@isdd/metais-common/hooks/useAttributes.hook'

import { CiInformationAccordion } from '@/components/entities/accordion/CiInformationAccordion'

type BulkListSectionProps = {
    uuid: string
}

export const BulkListSection: React.FC<BulkListSectionProps> = ({ uuid }) => {
    const { t, i18n } = useTranslation()
    const { data, isLoading, isError } = useReadNeighboursConfigurationItems(uuid)
    const [detailCi, setDetailCi] = useState<ConfigurationItemUi>()
    const { ciItemData, gestorData, isLoading: isCiItemLoading, isError: isCiItemError } = useCiHook(detailCi?.uuid)
    const { constraintsData, ciTypeData, unitsData, isLoading: isAttLoading, isError: isAttError } = useAttributesHook(detailCi?.type)

    return (
        <>
            <QueryFeedback loading={isLoading} error={isError} errorProps={{ errorMessage: t('bulkList.errorRels') }}>
                {data?.fromCiSet && data.fromCiSet.length > 0 && (
                    <>
                        <TextHeading size="M">{t('bulkList.fromCiSet')}</TextHeading>
                        <ul>
                            {data?.fromCiSet?.map((i) => (
                                <li key={i.uuid}>
                                    <a onClick={() => setDetailCi(i)} style={{ cursor: 'pointer' }} className="govuk-body govuk-link">
                                        {i.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] ??
                                            i.attributes?.find(
                                                (att: { name: string; value: string }) => att?.name === ATTRIBUTE_NAME.Gen_Profil_nazov,
                                            )?.value}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </>
                )}

                {data?.toCiSet && data.toCiSet.length > 0 && (
                    <>
                        <TextHeading size="M">{t('bulkList.toCiSet')}</TextHeading>
                        <ul>
                            {data?.toCiSet?.map((i) => (
                                <li key={i.uuid}>
                                    <a onClick={() => setDetailCi(i)} style={{ cursor: 'pointer' }} className="govuk-body govuk-link">
                                        {i.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] ??
                                            i.attributes?.find(
                                                (att: { name: string; value: string }) => att?.name === ATTRIBUTE_NAME.Gen_Profil_nazov,
                                            )?.value}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </>
                )}
            </QueryFeedback>
            <BaseModal
                isOpen={!!detailCi}
                close={() => {
                    setDetailCi(undefined)
                }}
            >
                <div>
                    {detailCi && (
                        <>
                            <TextHeading size="L">{`${
                                detailCi?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] ??
                                detailCi?.attributes?.find((att: { name: string; value: string }) => att?.name === ATTRIBUTE_NAME.Gen_Profil_nazov)
                                    ?.value
                            } (${i18n.language == 'sk' ? ciTypeData?.name : ciTypeData?.engName})`}</TextHeading>
                            <CiInformationAccordion
                                data={{ ciItemData, gestorData, constraintsData, ciTypeData, unitsData }}
                                isError={isAttError || isCiItemError}
                                isLoading={isAttLoading || isCiItemLoading}
                            />
                        </>
                    )}
                </div>
                <ModalButtons
                    onClose={() => {
                        setDetailCi(undefined)
                    }}
                />
            </BaseModal>
        </>
    )
}
