import { decodeHtmlEntities } from '@isdd/metais-common/utils/utils'
import classNames from 'classnames'
import React, { DetailedHTMLProps, forwardRef, useId } from 'react'
import { MODAL_TOOLTIP_ZINDEX } from '@isdd/metais-common/constants'
import { useTranslation } from 'react-i18next'

import styles from './radioButton.module.scss'

import { Tooltip } from '@isdd/idsk-ui-kit/tooltip/Tooltip'

interface IRadioButtonProps extends DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    id?: string
    name: string
    value: string | number
    disabled?: boolean
    className?: string
    label: string | React.ReactNode
    info?: string
    isInModal?: boolean
}

export const RadioButton = forwardRef<HTMLInputElement, IRadioButtonProps>(
    ({ id, label, name, disabled, value, className, info, isInModal = false, ...rest }, ref) => {
        const { t } = useTranslation()
        const uId = useId()
        const elId = id ?? `input-${uId}` // NVDA has problems with ID starting with `useID` `::`, needs to be prefixed

        return (
            <div className={classNames('govuk-radios__item', styles.labelDiv, className)}>
                <input className="govuk-radios__input" id={elId} name={name} type="radio" value={value} readOnly={disabled} ref={ref} {...rest} />
                {label && (
                    <label className="govuk-label govuk-radios__label" htmlFor={elId}>
                        {label}
                    </label>
                )}
                {info && (
                    <Tooltip
                        descriptionElement={<div className="tooltipWidth500">{decodeHtmlEntities(info)}</div>}
                        altText={t('tooltip', { tooltip: label })}
                        contentStyle={isInModal ? { zIndex: MODAL_TOOLTIP_ZINDEX } : undefined}
                    />
                )}
            </div>
        )
    },
)
