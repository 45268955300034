import {
    AccordionContainer,
    Button,
    ButtonGroupRow,
    ButtonLink,
    ButtonPopup,
    CheckBox,
    GridCol,
    GridRow,
    SimpleSelect,
} from '@isdd/idsk-ui-kit/index'
import { useExport6Hook, useLoadFileHook } from '@isdd/metais-common/api/generated/impexp-cmdb-swagger'
import { KrisToBeRights, KrisUi, NoteVersionUi } from '@isdd/metais-common/api/generated/kris-swagger'
import { downloadBlobAsFile } from '@isdd/metais-common/componentHelpers/download/downloadHelper'
import { InformationGridRow } from '@isdd/metais-common/components/info-grid-row/InformationGridRow'
import { FileExtensionEnum, MutationFeedback, QueryFeedback, formatDateTimeForDefaultValue } from '@isdd/metais-common/index'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetProgress } from '@isdd/metais-common/hooks/useGetRequestProgress'
import { handleTooManyRequests } from '@isdd/metais-common/utils/utils'

import { EContainerType } from '@/components/containers/CiEvaluationContainer'
import { ExportModal } from '@/components/views/ci/kris/Modals/ExportModal'
import { BasicEvaluationAccordion } from '@/components/views/evaluation/components/BasicEvaluationAccordion'
import { GoalsEvaluationAccordion } from '@/components/views/evaluation/components/GoalsEvaluationAccordion'
import { IsvsEvaluationAccordion } from '@/components/views/evaluation/components/IsvsEvaluationAccordion'
import { ProjectEvaluationAccordion } from '@/components/views/evaluation/components/ProjectEvaluationAccordion'
import { KSEvaluationAccordion } from '@/components/views/evaluation/components/KSEvaluationAccordion'
import { SuggestionEvaluationAccordion } from '@/components/views/evaluation/components/SuggestionEvaluationAccordion'
import styles from '@/components/views/evaluation/evaluationView.module.scss'

export interface IResultCall {
    isSuccess: boolean
    message: string
}

interface IOpenModalExport {
    isOpen: boolean
    type: EContainerType
}

interface IEvaluationView {
    versionData?: NoteVersionUi[]
    dataRights?: KrisToBeRights
    krisData?: KrisUi
    entityId?: string
    isLoading: boolean
    isError: boolean
    entityName: string
    resultSuccessApiCall: IResultCall
    resetResultSuccessApiCall: () => void
    onApprove: (approve: boolean) => void
    onApproveGoals: (approve: boolean, note: string, type: EContainerType, refetchData: () => void) => void
    onResponseGoals: (note: string, refetchData: () => void) => void
}

export const EvaluationView: React.FC<IEvaluationView> = ({
    entityId,
    isError,
    isLoading,
    krisData,
    versionData,
    dataRights,
    resultSuccessApiCall,
    onApprove,
    onApproveGoals,
    onResponseGoals,
    resetResultSuccessApiCall,
}) => {
    const { t } = useTranslation()
    const [selectedVersion, setSelectedVersion] = useState<NoteVersionUi>()
    const [openExport, setOpenExport] = useState<IOpenModalExport>({ isOpen: false, type: EContainerType.KRIS })
    const [changeValidateAll, setChangeValidateAll] = useState<boolean>()
    const [isLoadingApi, setIsLoadingApi] = useState<boolean>(false)
    const [isErrorApi, setIsErrorApi] = useState<boolean>(false)
    const isGlobalAllowed = krisData?.approved ?? false
    const [errorMessage, setErrorMessage] = useState<string>()

    const exportHook = useExport6Hook()
    const { getRequestStatus, isLoading: isGettingProgress } = useGetProgress('EXPORT')
    const fileLoad = useLoadFileHook()
    const [exportSuccess, setExportSuccess] = useState(false)

    const downloadFile = (res: Blob, type: string, entity: string) => {
        downloadBlobAsFile(new Blob([res]), `${entity}-${new Date().toISOString()}.${type}`, false)
    }

    useEffect(() => {
        versionData?.length && setSelectedVersion(versionData[0])
    }, [versionData])

    const exportToFile = async (fileType: FileExtensionEnum, selectedPo: string[]) => {
        try {
            exportHook(fileType, openExport.type === EContainerType.ISVS ? { ids: selectedPo } : {}, {
                type: openExport.type,
                version: selectedVersion?.versionNumber ?? 0,
                uuid: entityId ?? '',
            })
                .then(async (result) => {
                    await getRequestStatus(
                        result.id ?? '',
                        async (validationResult) => {
                            const blob = await fileLoad(validationResult?.fileName ?? '')
                            if (blob) {
                                downloadFile(blob, validationResult?.fileExtension ?? '', openExport.type)
                                setExportSuccess(true)
                            } else {
                                setIsErrorApi(true)
                            }
                        },
                        () => {
                            setIsErrorApi(true)
                        },
                    )
                })
                .catch((e) => {
                    handleTooManyRequests(
                        e.message,
                        () => {
                            setErrorMessage(t('exportItemsOrRelations.exportErrorTooManyRequests'))
                            setIsErrorApi(true)
                        },
                        () => {
                            setIsErrorApi(true)
                        },
                    )
                })
        } catch (error) {
            setIsErrorApi(true)
        } finally {
            setIsLoadingApi(false)
        }
    }

    return (
        <QueryFeedback loading={isLoading || isLoadingApi || isGettingProgress} error={isError || isErrorApi} withChildren>
            {versionData?.length ? (
                <>
                    <MutationFeedback
                        success={resultSuccessApiCall.isSuccess || exportSuccess}
                        successMessage={exportSuccess ? t('exportItemsOrRelations.exportSuccess') : resultSuccessApiCall.message}
                        onMessageClose={resetResultSuccessApiCall}
                        errorMessage={errorMessage}
                    />
                    <GridRow className={styles.heading}>
                        <GridCol setWidth="one-half">
                            <InformationGridRow
                                hideIcon
                                key={'evaluatedBy'}
                                label={t('evaluation.evaluatedBy')}
                                value={selectedVersion?.evaluatedBy}
                            />
                        </GridCol>
                        <GridCol setWidth="one-half">
                            <InformationGridRow
                                hideIcon
                                key={'created'}
                                label={t('evaluation.created')}
                                value={selectedVersion?.created ? formatDateTimeForDefaultValue(selectedVersion?.created, 'dd.MM.yyyy HH:mm') : ''}
                            />
                        </GridCol>
                    </GridRow>
                    <GridRow className={styles.heading}>
                        <GridCol setWidth="one-half">
                            <InformationGridRow
                                hideIcon
                                key={'versionRow'}
                                label={t('evaluation.version')}
                                value={
                                    <SimpleSelect
                                        isClearable={false}
                                        label={''}
                                        name={'version'}
                                        onChange={(value) => {
                                            setSelectedVersion(versionData?.find((item) => item.versionNumber == value))
                                        }}
                                        defaultValue={versionData?.[0]?.versionNumber?.toString() ?? ''}
                                        options={
                                            versionData?.map((item) => ({
                                                label: item.versionNumber?.toString() ?? '',
                                                value: item.versionNumber?.toString() ?? '',
                                            })) ?? []
                                        }
                                    />
                                }
                            />
                        </GridCol>
                        <GridCol setWidth="one-half">
                            <InformationGridRow
                                hideIcon
                                key={'key4'}
                                label={t('evaluation.evaluated')}
                                value={
                                    selectedVersion?.evaluated ? formatDateTimeForDefaultValue(selectedVersion?.evaluated, 'dd.MM.yyyy HH:mm') : ''
                                }
                            />
                        </GridCol>
                        <GridCol>
                            <div className="govuk-checkboxes govuk-checkboxes--small">
                                <CheckBox
                                    disabled={!changeValidateAll}
                                    label={t('evaluation.evaluatAll')}
                                    id={'evaluatAll'}
                                    name={'evaluatAll'}
                                    checked={isGlobalAllowed}
                                    onChange={(val) => {
                                        setChangeValidateAll(false)
                                        onApprove(val.target.checked)
                                    }}
                                />
                            </div>
                            {dataRights?.inEvaluation &&
                                (!changeValidateAll ? (
                                    <Button
                                        label={t('evaluation.changeBtn')}
                                        onClick={() => {
                                            setChangeValidateAll(!changeValidateAll)
                                        }}
                                    />
                                ) : (
                                    <Button
                                        variant="secondary"
                                        label={t('evaluation.cancelBtn')}
                                        onClick={() => {
                                            setChangeValidateAll(!changeValidateAll)
                                        }}
                                    />
                                ))}
                        </GridCol>
                    </GridRow>
                    <ButtonGroupRow>
                        <ButtonPopup
                            buttonClassName={styles.noWrap}
                            buttonLabel={t('evaluation.exportBtn')}
                            popupHorizontalPosition="right"
                            popupContent={() => {
                                return (
                                    <div className={styles.buttonLinksDiv}>
                                        <div className={styles.buttonPopupItem}>
                                            <ButtonLink
                                                onClick={() => {
                                                    setOpenExport({ isOpen: true, type: EContainerType.KRIS })
                                                }}
                                                label={t('evaluation.exportKris')}
                                                aria={{ 'aria-haspopup': 'dialog' }}
                                            />
                                        </div>
                                        <div className={styles.buttonPopupItem}>
                                            <ButtonLink
                                                onClick={() => {
                                                    setOpenExport({ isOpen: true, type: EContainerType.ISVS })
                                                }}
                                                label={t('evaluation.exportIsvs')}
                                                aria={{ 'aria-haspopup': 'dialog' }}
                                            />
                                        </div>
                                        <div>
                                            <ButtonLink
                                                onClick={() => {
                                                    setOpenExport({ isOpen: true, type: EContainerType.KS })
                                                }}
                                                label={t('evaluation.exportKs')}
                                                aria={{ 'aria-haspopup': 'dialog' }}
                                            />
                                        </div>
                                    </div>
                                )
                            }}
                        />
                    </ButtonGroupRow>
                    <AccordionContainer
                        sections={[
                            {
                                title: t('evaluation.accordion.goals'),
                                content: (
                                    <GoalsEvaluationAccordion
                                        entityId={entityId ?? ''}
                                        versionData={versionData}
                                        onApproveGoals={onApproveGoals}
                                        onResponseGoals={onResponseGoals}
                                        dataRights={dataRights}
                                        isGlobalAllowed={isGlobalAllowed}
                                    />
                                ),
                            },
                            {
                                title: t('evaluation.accordion.suggestion'),
                                content: (
                                    <SuggestionEvaluationAccordion
                                        versionData={versionData}
                                        onApproveGoals={onApproveGoals}
                                        dataRights={dataRights}
                                        entityId={entityId ?? ''}
                                        isGlobalAllowed={isGlobalAllowed}
                                    />
                                ),
                            },
                            {
                                title: t('evaluation.accordion.isvs'),
                                content: (
                                    <IsvsEvaluationAccordion dataRights={dataRights} entityId={entityId ?? ''} isGlobalAllowed={isGlobalAllowed} />
                                ),
                            },
                            {
                                title: t('evaluation.accordion.services'),
                                content: (
                                    <KSEvaluationAccordion dataRights={dataRights} entityId={entityId ?? ''} isGlobalAllowed={isGlobalAllowed} />
                                ),
                            },
                            {
                                title: t('evaluation.accordion.projects'),
                                content: (
                                    <ProjectEvaluationAccordion dataRights={dataRights} entityId={entityId ?? ''} isGlobalAllowed={isGlobalAllowed} />
                                ),
                            },
                            {
                                title: t('evaluation.accordion.krit'),
                                content: (
                                    <BasicEvaluationAccordion dataRights={dataRights} entityId={entityId ?? ''} isGlobalAllowed={isGlobalAllowed} />
                                ),
                            },
                        ]}
                    />
                    <ExportModal
                        open={openExport.isOpen}
                        type={openExport.type}
                        uuid={entityId ?? ''}
                        onClose={() => {
                            setOpenExport({ isOpen: false, type: EContainerType.KRIS })
                        }}
                        onExport={async (type: FileExtensionEnum, selectedPo: string[]) => {
                            setOpenExport({ isOpen: false, type: EContainerType.KRIS })
                            setIsLoadingApi(true)
                            exportToFile(type, selectedPo)
                        }}
                    />
                </>
            ) : (
                <></>
            )}
        </QueryFeedback>
    )
}
