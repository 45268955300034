import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { InformationGridRow } from '@isdd/metais-common/components/info-grid-row/InformationGridRow'
import { Languages } from '@isdd/metais-common/localization/languages'
import { useTranslation } from 'react-i18next'
import { Table, TextHeading } from '@isdd/idsk-ui-kit/index'
import { ConfigurationItemUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { ColumnDef } from '@tanstack/react-table'
import { Spacer } from '@isdd/metais-common/components/spacer/Spacer'

import { RefIdentifierDetailInfoViewProps } from '@/components/containers/ref-identifiers/RefIdentifierDetailContainer'
import { getNameByAttribute } from '@/components/views/codeLists/CodeListDetailUtils'

export const RefOntologyInfoView: React.FC<RefIdentifierDetailInfoViewProps> = ({
    ciItemData,
    attributes,
    registrationState,
    gestorName,
    ciList,
}) => {
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const infoAttributeList = [ATTRIBUTE_NAME.Gen_Profil_nazov, ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov]

    const stateItem = registrationState?.enumItems?.find(
        (item) => item.code === ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_RefID_stav_registracie],
    )
    const stateValue = language === Languages.SLOVAK ? stateItem?.value : stateItem?.engValue

    const columns: ColumnDef<ConfigurationItemUi>[] = [
        {
            id: 'name',
            header: t('ontologyVersion.tableHeaders.name'),
            cell: (row) => {
                return <span>{row.row.original.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov]}</span>
            },
        },
        {
            id: 'uri',
            header: t('ontologyVersion.tableHeaders.uri'),
            cell: (row) => {
                return <span>{row.row.original.attributes?.[ATTRIBUTE_NAME.Profil_VerziaOntologie_uri_distribucie]}</span>
            },
        },
        {
            id: 'state',
            header: t('ontologyVersion.tableHeaders.state'),
            cell: (row) => {
                return (
                    <span>
                        {
                            registrationState?.enumItems?.find(
                                (item) => item.code === row.row.original.attributes?.[ATTRIBUTE_NAME.Gen_Profil_RefID_stav_registracie],
                            )?.value
                        }
                    </span>
                )
            },
        },
        {
            id: 'validFrom',
            header: t('ontologyVersion.tableHeaders.validFrom'),
            cell: (row) => {
                return <span>{t('dateTime', { date: row.row.original.attributes?.[ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_od] })}</span>
            },
        },
        {
            id: 'validTo',
            header: t('ontologyVersion.tableHeaders.validTo'),
            cell: (row) => {
                return <span>{t('dateTime', { date: row.row.original.attributes?.[ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_do] })}</span>
            },
        },
    ]

    return (
        <>
            {infoAttributeList.map(
                (technicalName) =>
                    ciItemData?.attributes?.[technicalName] !== '' && (
                        <InformationGridRow
                            key={technicalName}
                            hideIcon
                            label={getNameByAttribute(
                                language,
                                attributes?.find((item) => item.technicalName === technicalName),
                            )}
                            value={ciItemData?.attributes?.[technicalName]}
                        />
                    ),
            )}
            <InformationGridRow
                hideIcon
                label={t('refIdentifiers.detail.uri')}
                value={ciItemData?.attributes?.[ATTRIBUTE_NAME.Profil_Ontologia_uri_pre_ontologiu]}
            />
            <InformationGridRow hideIcon label={t('refIdentifiers.detail.gestor')} value={gestorName} lang={Languages.SLOVAK} />
            <InformationGridRow hideIcon label={t('refIdentifiers.detail.type')} value={t(`refIdentifiers.type.${ciItemData?.type}`)} />
            <InformationGridRow
                hideIcon
                label={getNameByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_RefID_stav_registracie),
                )}
                value={stateValue}
            />
            <InformationGridRow
                hideIcon
                label={getNameByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_popis),
                )}
                value={ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_popis]}
            />
            <InformationGridRow
                hideIcon
                label={t('refIdentifiers.detail.historicalCode')}
                value={ciItemData?.attributes?.[ATTRIBUTE_NAME.Profil_Ontologia_historicky_kod]}
            />

            <InformationGridRow
                hideIcon
                label={t('refIdentifiers.detail.createdAt')}
                value={t('date', { date: ciItemData?.metaAttributes?.createdAt })}
            />
            <InformationGridRow hideIcon label={t('refIdentifiers.detail.createdBy')} value={ciItemData?.metaAttributes?.createdBy} />
            {ciItemData?.metaAttributes?.lastModifiedAt && (
                <InformationGridRow
                    hideIcon
                    label={t('refIdentifiers.detail.lastModifiedAt')}
                    value={t('date', { date: ciItemData?.metaAttributes?.lastModifiedAt })}
                />
            )}
            {ciItemData?.metaAttributes?.lastModifiedBy && (
                <InformationGridRow hideIcon label={t('refIdentifiers.detail.modifiedBy')} value={ciItemData?.metaAttributes?.lastModifiedBy} />
            )}
            <Spacer vertical />
            <TextHeading size="L">{t('refIdentifiers.detail.ontologyVersions')}</TextHeading>
            <Table columns={columns} data={ciList} />
        </>
    )
}
