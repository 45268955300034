import { IStep } from '@isdd/idsk-ui-kit/index'
import {
    ConfigurationItemUi,
    HistoryVersionsListUiConfigurationItemUi,
    useReadCiHistoryVersions,
    useReadCiHistoryVersionsActionsList,
} from '@isdd/metais-common/api/generated/cmdb-swagger'
import { EnumItem, EnumType, useGetValidEnum } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import {
    ACTION_CREATE,
    ACTION_UPDATE,
    CANCELED_STATE,
    CANCELED_STATE_AGILE,
    FINISHED_STATE,
    FINISHED_STATE_AGILE,
    METODA_RIADENIA_PROJEKTU,
    NOT_APPROVED_STATE,
    NOT_APPROVED_STATE_AGILE,
    PROJECT_STATUS,
    RATED_STATE,
    RATED_STATE_AGILE,
    RETURNED_STATE,
    RETURNED_STATE_AGILE,
    RE_RATED_STATE,
    RE_RATED_STATE_AGILE,
    STAV_PROJEKTU,
    STAV_PROJEKTU_AGILE,
} from '@isdd/metais-common/constants'
import { ATTRIBUTE_NAME, formatDateForDefaultValue, formatDateTimeForDefaultValue } from '@isdd/metais-common/index'
import React from 'react'

export interface IView {
    steps: IStep[]
    currentStep: number
    isLoading: boolean
}

interface IProjectStateContainer {
    configurationItemId: string
    ciData: ConfigurationItemUi
    View: React.FC<IView>
}

const mapHistory = (data?: HistoryVersionsListUiConfigurationItemUi, defaultProjectStates?: EnumItem[]) => {
    const uniqueValues = new Set<string>()

    return data?.historyVersions
        ?.filter((obj) => {
            const code: string = Object.values(obj.item?.attributes ?? {}).find((attr) => attr.name == PROJECT_STATUS)?.value
            if (!uniqueValues.has(code) && (obj.actions?.includes(ACTION_CREATE) || obj.actions?.includes(ACTION_UPDATE))) {
                uniqueValues.add(code)
                return true
            }
            return false
        })
        .sort((a, b) => new Date(a.actionTime ?? '').getTime() - new Date(b.actionTime ?? '').getTime())
        .map((i) => {
            return {
                date: formatDateTimeForDefaultValue(i.actionTime ?? ''),
                state: defaultProjectStates?.find(
                    (st) => st.code == Object.values(i.item?.attributes ?? {}).find((attr) => attr.name == PROJECT_STATUS)?.value,
                ),
            }
        })
}

export const ProjectStateContainer: React.FC<IProjectStateContainer> = ({ configurationItemId, ciData, View }) => {
    const { data: projectStates, isLoading: isStatesLoading } = useGetValidEnum(STAV_PROJEKTU)
    const { data: agileProjectStates, isLoading: isAgileStatesLoading } = useGetValidEnum(STAV_PROJEKTU_AGILE)

    let defaultProjectStates: EnumType | undefined
    let CANCELLED: string
    let RETURNED: string
    let NOT_APPROVED: string
    let FINISHED: string
    let RATED: string
    let RE_RATED: string

    if (ciData.attributes?.[ATTRIBUTE_NAME.EA_Profil_Projekt_metoda_riadenia_projektu] === METODA_RIADENIA_PROJEKTU.waterfall) {
        defaultProjectStates = projectStates
        CANCELLED = CANCELED_STATE
        RETURNED = RETURNED_STATE
        FINISHED = FINISHED_STATE
        RATED = RATED_STATE
        RE_RATED = RE_RATED_STATE
        NOT_APPROVED = NOT_APPROVED_STATE
    } else {
        defaultProjectStates = agileProjectStates
        CANCELLED = CANCELED_STATE_AGILE
        RETURNED = RETURNED_STATE_AGILE
        FINISHED = FINISHED_STATE_AGILE
        RATED = RATED_STATE_AGILE
        RE_RATED = RE_RATED_STATE_AGILE
        NOT_APPROVED = NOT_APPROVED_STATE_AGILE
    }

    const projectsStates = defaultProjectStates?.enumItems?.sort((a, b) => {
        if (a.orderList === b.orderList) {
            return a.code?.localeCompare(b.code ?? '') ?? 0
        }
        return (a.orderList ?? 0) - (b.orderList ?? 0)
    })

    const { data: actions, isLoading: isActionsLoading } = useReadCiHistoryVersionsActionsList(configurationItemId)
    const { data: historyData, isLoading: isHistoryLoading } = useReadCiHistoryVersions(configurationItemId, {
        page: 1,
        perPage: 1000,
        action: actions?.filter((a) => a == ACTION_CREATE || a == ACTION_UPDATE),
    })

    const mappedHistory = mapHistory(historyData, projectsStates)

    const fullSteps: IStep[] =
        projectsStates
            ?.filter((ps) => !(ps.code === CANCELLED && ciData?.attributes?.EA_Profil_Projekt_status !== CANCELLED))
            .map((ps) => {
                if (ps.code == FINISHED && ciData?.attributes?.EA_Profil_Projekt_termin_ukoncenia) {
                    return {
                        name: ps.value ?? '',
                        date: formatDateForDefaultValue(ciData?.attributes?.EA_Profil_Projekt_termin_ukoncenia, 'dd.MM.yyyy'),
                    }
                }
                if (ps.code == RATED && ciData?.attributes?.EA_Profil_Projekt_status == RE_RATED) {
                    return {
                        name: ps.value ?? '',
                        date: mappedHistory?.find((mp) => mp.state == ps.value)?.date,
                        description: projectsStates?.find((ps1) => ps1.code == RE_RATED)?.value,
                    }
                }
                if (ps.code == RATED && ciData?.attributes?.EA_Profil_Projekt_status == RETURNED) {
                    return {
                        name: ps.value ?? '',
                        date: mappedHistory?.find((mp) => mp.state == ps.value)?.date,
                        description: projectsStates?.find((ps2) => ps2.code == RETURNED)?.value,
                    }
                }
                return { name: ps.value ?? '', date: mappedHistory?.find((mp) => mp.state == ps.value)?.date }
            }) ?? []
    const steps = fullSteps.filter(
        (fs) =>
            fs.name != defaultProjectStates?.enumItems?.find((e) => e.code == RETURNED)?.value &&
            fs.name != defaultProjectStates?.enumItems?.find((e) => e.code == RE_RATED)?.value,
    )

    let currentStep = steps.indexOf(
        steps.find(
            (s) => s.name == defaultProjectStates?.enumItems?.find((dps) => dps.code == ciData?.attributes?.EA_Profil_Projekt_status)?.value,
        ) ?? steps[0],
    )

    if (ciData?.attributes?.EA_Profil_Projekt_status == RE_RATED || ciData?.attributes?.EA_Profil_Projekt_status == RETURNED) {
        currentStep = steps.indexOf(steps.find((s) => s.name == defaultProjectStates?.enumItems?.find((d) => d.code == RATED)?.value) ?? steps[0])
    }
    const removedSteps = steps.splice(-2)
    if (ciData?.attributes?.EA_Profil_Projekt_status == NOT_APPROVED) {
        steps.splice(3, steps.length)

        removedSteps[removedSteps.length - 1] && steps.push({ ...removedSteps[removedSteps.length - 1], isRed: true })
        currentStep = steps.length
    }
    return (
        <View
            steps={steps ?? []}
            currentStep={currentStep ?? 0}
            isLoading={isStatesLoading || isActionsLoading || isHistoryLoading || isAgileStatesLoading}
        />
    )
}
