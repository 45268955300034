import { MongoAbility } from '@casl/ability'
import { ButtonLink, ButtonPopup, CheckBox } from '@isdd/idsk-ui-kit/index'
import { CHECKBOX_CELL, DELETE_CELL } from '@isdd/idsk-ui-kit/table/constants'
import { GROUP_ROLES, KSISVS_ROLES } from '@isdd/metais-common/constants'
import { Can } from '@isdd/metais-common/hooks/permissions/useAbilityContext'
import { Actions } from '@isdd/metais-common/hooks/permissions/useUserAbility'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import { TFunction } from 'i18next'
import React from 'react'
import { GroupPermissionSubject } from '@isdd/metais-common/hooks/permissions/useGroupsPermissions'
import { useTranslation } from 'react-i18next'
import { RowSelection } from '@isdd/metais-common/hooks/useRowSelection'

import { DEFAULT_KSISVS_ROLES, DEFAULT_ROLES } from './defaultRoles'
import styles from './styles.module.scss'

import { IdentityToEdit, TableData } from '@/components/containers/standardization/groups/GroupDetailContainer'

export interface IActionButtonPopup {
    ctx: CellContext<TableData, unknown>
    setIdentityToDelete: React.Dispatch<React.SetStateAction<string | undefined>>
    setIdentityToEdit: React.Dispatch<React.SetStateAction<IdentityToEdit | undefined>>
    rowActionFocusTriggerRef: React.MutableRefObject<(() => void) | undefined>
}

export interface TableMeta {
    isAnyModalOpened: boolean
}

export const ActionButtonPopup: React.FC<IActionButtonPopup> = ({ ctx, setIdentityToDelete, setIdentityToEdit, rowActionFocusTriggerRef }) => {
    const { t } = useTranslation()

    return (
        <ButtonPopup
            key={ctx.row?.index}
            buttonLabel={t('groups.action')}
            popupHorizontalPosition="right"
            buttonClassName={styles.marginBottom0}
            manuallyHandledFocusAfterClose
            onClose={() => {
                const meta = ctx.table.options.meta as TableMeta
                if (!meta.isAnyModalOpened) {
                    rowActionFocusTriggerRef.current?.()
                }
            }}
            onOpen={(focusTriggerEl) => {
                rowActionFocusTriggerRef.current = focusTriggerEl
            }}
            popupContent={() => {
                return (
                    <div className={styles.actions}>
                        <ButtonLink
                            type="button"
                            onClick={() => {
                                setIdentityToEdit({ row: ctx.row })
                            }}
                            label={t('groups.edit')}
                        />
                        <ButtonLink
                            className={styles.redText}
                            type="button"
                            onClick={() => {
                                setIdentityToDelete(ctx.row.original.uuid)
                            }}
                            label={t('groups.delete')}
                        />
                    </div>
                )
            }}
        />
    )
}

export const sendBatchEmail = (rowSelection: RowSelection<TableData>) => {
    const emails = rowSelection.list.map((item) => item.email)
    if (emails.length === 0) return

    const emailAddresses = emails.join(';')
    const mailtoLink = `mailto:${emailAddresses}`

    window.open(mailtoLink, 'blank')
}

export const buildColumns = ({
    tableData,
    t,
    ability,
    isUserLogged,
    roles,
    setIdentityToDelete,
    setIdentityToEdit,
    rowActionFocusTriggerRef,
}: {
    tableData: TableData[] | undefined
    t: TFunction<'translation', undefined, 'translation'>
    ability: MongoAbility
    isUserLogged: boolean
    roles: string[] | undefined
    setIdentityToDelete: React.Dispatch<React.SetStateAction<string | undefined>>
    setIdentityToEdit: React.Dispatch<React.SetStateAction<IdentityToEdit | undefined>>
    rowActionFocusTriggerRef: React.MutableRefObject<(() => void) | undefined>
}) => {
    const selectableColumnsSpec: ColumnDef<TableData>[] = [
        {
            id: 'lastName_firstName',
            header: t('groups.name'),
            accessorKey: 'lastName_firstName',
            enableSorting: true,
            size: 180,
        },
        { id: 'orgName', header: t('groups.organization'), accessorKey: 'orgName', enableSorting: true, size: 480 },
    ]

    if (ability.can(Actions.CREATE, GroupPermissionSubject.SEND_EMAIL) || isUserLogged) {
        selectableColumnsSpec.unshift({
            header: ({ table }) => {
                return (
                    <div className="govuk-checkboxes govuk-checkboxes--small">
                        <CheckBox
                            label=""
                            name="checkbox"
                            id="checkbox_all"
                            value="true"
                            onChange={table.getToggleAllRowsSelectedHandler()}
                            checked={table.getIsAllRowsSelected()}
                            aria-label={t('table.selectAllItems')}
                        />
                    </div>
                )
            },
            id: CHECKBOX_CELL,
            cell: ({ row }) => (
                <div className="govuk-checkboxes govuk-checkboxes--small">
                    <CheckBox
                        label=""
                        name="checkbox"
                        id={`checkbox_${row.id}`}
                        value="true"
                        onChange={row.getToggleSelectedHandler()}
                        checked={row.getIsSelected()}
                        aria-label={t('table.selectItem', { itemName: row.original.lastName_firstName })}
                    />
                </div>
            ),
            size: 20,
        })
    }
    if (ability.can(Actions.READ, GroupPermissionSubject.GROUP_MEMBER_EMAIL)) {
        selectableColumnsSpec.push({ header: t('groups.email'), id: 'email', accessorKey: 'email', size: 200 })
    }

    selectableColumnsSpec.push({
        header: t('groups.role'),
        id: 'roleName',
        accessorKey: 'roleName',
        enableSorting: true,
        cell: ({ row }) =>
            [...DEFAULT_KSISVS_ROLES, ...DEFAULT_ROLES].find((role) => {
                return role.code == row.original.roleName
            })?.value,
    })

    if (ability.can(Actions.EDIT, GroupPermissionSubject.GROUPS)) {
        selectableColumnsSpec.push({
            header: t('groups.action'),
            id: DELETE_CELL,
            size: 50,
            cell: (ctx) => {
                const userIsKSPre = roles?.find((role) => role === KSISVS_ROLES.STD_KSPRE) !== undefined
                const countPreItems =
                    tableData?.filter((item) => item.roleName === KSISVS_ROLES.STD_KSPRE || item.roleName === GROUP_ROLES.STD_PSPRE).length || 0

                return (userIsKSPre && countPreItems > 1) || userIsKSPre ? (
                    <ActionButtonPopup
                        ctx={ctx}
                        setIdentityToDelete={setIdentityToDelete}
                        setIdentityToEdit={setIdentityToEdit}
                        rowActionFocusTriggerRef={rowActionFocusTriggerRef}
                    />
                ) : (
                    <Can I={Actions.EDIT} a={GroupPermissionSubject.GROUP_MASTER}>
                        <ActionButtonPopup
                            ctx={ctx}
                            setIdentityToDelete={setIdentityToDelete}
                            setIdentityToEdit={setIdentityToEdit}
                            rowActionFocusTriggerRef={rowActionFocusTriggerRef}
                        />
                    </Can>
                )
            },
        })
    }
    return selectableColumnsSpec
}
