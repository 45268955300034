import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonLink, CheckBox, ConfirmationModal, PaginatorWrapper, Table } from '@isdd/idsk-ui-kit'
import { ColumnDef, Table as ITable, Row, CellContext } from '@tanstack/react-table'
import { SortType } from '@isdd/idsk-ui-kit/types'
import classNames from 'classnames'
import { Link, useLocation, Location } from 'react-router-dom'
import { CHECKBOX_CELL } from '@isdd/idsk-ui-kit/table/constants'
import { useQueryClient } from '@tanstack/react-query'

import styles from './favoritesNotifications.module.scss'

import { NotificationBlackIcon } from '@isdd/metais-common/assets/images'
import actionsOverTableStyles from '@isdd/metais-common/components/actions-over-table/actionsOverTable.module.scss'
import { useRemoveFavorite } from '@isdd/metais-common/components/views/user-profile/user-notifications/hooks/useRemoveFavorite'
import {
    FollowedItem,
    FollowedItemItemType,
    FollowedItemList,
    getGetFollowedItemsQueryKey,
    useGetFollowedItems,
    useUpdateFollowedItem,
} from '@isdd/metais-common/api/generated/user-config-swagger'
import { QueryFeedback } from '@isdd/metais-common/components/query-feedback/QueryFeedback'
import { MutationFeedback } from '@isdd/metais-common/components/mutation-feedback/MutationFeedback'
import { BASE_PAGE_NUMBER, BASE_PAGE_SIZE, CI_TYPES_QUERY_KEY, DEFAULT_PAGESIZE_OPTIONS } from '@isdd/metais-common/constants'
import { ActionsOverTable, BulkPopup } from '@isdd/metais-common/components/actions-over-table'
import { NavigationSubRoutes } from '@isdd/metais-common/navigation/routeNames'
import { ConfigurationItemSetUi, useReadCiList1 } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { CiTypePreview, useListCiTypes } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { useRowSelection } from '@isdd/metais-common/hooks/useRowSelection'

const getLink = (item: FollowedItem, location: Location, ciList: ConfigurationItemSetUi, ciTypesMap?: Record<string, CiTypePreview>) => {
    let to = ''
    let name = item.name
    if (item.itemType === FollowedItemItemType.CI) {
        const ciItem = ciList.configurationItemSet?.find((ci) => ci.uuid === item.itemId)
        to = `/ci/${ciItem?.type}/${item.itemId}`
        if (ciItem?.type && ciTypesMap && ciTypesMap[ciItem?.type]) {
            name += ` (${ciTypesMap[ciItem?.type].name})`
        }
    }
    if (item.itemType === FollowedItemItemType.CODELIST) {
        to = `${NavigationSubRoutes.CODELIST}/${item.itemId}`
    }
    if (item.itemType === FollowedItemItemType.REFID) {
        to = `${NavigationSubRoutes.REF_IDENTIFIERS_LIST}/${item.itemId}`
    }
    if (item.itemType === FollowedItemItemType.REF_REGISTER) {
        to = `${NavigationSubRoutes.REFERENCE_REGISTER}/${item.itemId}`
    }

    return (
        <Link to={to} state={{ from: location }}>
            {name}
        </Link>
    )
}

const getTypeIds = (data: FollowedItemList, type: FollowedItemItemType) => {
    return data?.items?.filter((item) => item.itemType === type).map((item) => item.itemId ?? '') ?? []
}

export const UserFavoritesNotifications = () => {
    const location = useLocation()
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const { i18n } = useTranslation()
    const rowSelection = useRowSelection()

    const [pagination, setPagination] = useState({
        pageNumber: BASE_PAGE_NUMBER,
        pageSize: BASE_PAGE_SIZE,
        order: 'name',
        ascending: false,
    })
    const [removeConfirmData, setRemoveConfirmData] = useState<{ isOpen: boolean; removeId?: number[] }>({
        isOpen: false,
        removeId: undefined,
    })

    const { data: listOfCiTypes } = useListCiTypes({ filter: {} }, { query: { queryKey: [CI_TYPES_QUERY_KEY, i18n.language] } })
    const ciTypesMap = useMemo(
        () =>
            listOfCiTypes?.results?.reduce<Record<string, CiTypePreview>>((prev, curr) => {
                if (!curr.technicalName) {
                    return prev
                }
                return {
                    ...prev,
                    [curr.technicalName]: curr,
                }
            }, {}),
        [listOfCiTypes?.results],
    )

    const {
        data: listData,
        isLoading: isLoadingList,
        isError: isErrorList,
        refetch,
        isRefetching,
    } = useGetFollowedItems(
        {
            paging: {
                page: pagination.pageNumber ?? BASE_PAGE_NUMBER,
                perPage: pagination.pageSize,
                sortBy: pagination.order,
                ascending: pagination.ascending,
            },
        },
        { query: { enabled: true } },
    )

    // if pageNumber is not first page and there are no items on that page, go to the first page
    useEffect(() => {
        if (pagination.pageNumber > BASE_PAGE_NUMBER && listData?.items?.length === 0) {
            setPagination({ ...pagination, pageNumber: BASE_PAGE_NUMBER })
        }
    }, [listData?.items?.length, pagination])

    const ciIds = getTypeIds(listData ?? {}, FollowedItemItemType.CI)
    const {
        data: ciListData,
        isFetching: isLoadingCiListData,
        isError: isErrorCiListData,
    } = useReadCiList1({ filter: { uuid: ciIds }, perpage: 999, page: 1 }, { query: { enabled: ciIds.length > 0 } })

    const {
        mutate: updateMutation,
        isLoading: isLoadingUpdateMutation,
        isError: isErrorUpdateMutation,
        isSuccess: isSuccessUpdate,
        reset: resetUpdateState,
    } = useUpdateFollowedItem()
    const {
        removeFavorite,
        removedCount,
        resetState: resetRemoveState,
        isLoading: isLoadingRemove,
        isSuccess: isSuccessRemove,
        isError: isErrorRemove,
    } = useRemoveFavorite()

    const handleCheckboxChange = ({
        isPortalChecked,
        isEmailChecked,
        item,
    }: {
        item: FollowedItem
        isPortalChecked?: boolean
        isEmailChecked?: boolean
    }) => {
        resetRemoveState()
        resetUpdateState()

        const updatedItem = {
            ...item,
            ...(isPortalChecked !== undefined ? { portal: isPortalChecked } : null),
            ...(isEmailChecked !== undefined ? { email: isEmailChecked } : null),
        }

        updateMutation(
            {
                id: Number(item.id),
                data: updatedItem,
            },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries([getGetFollowedItemsQueryKey({})[0]])
                },
            },
        )
    }

    const handleRemoveItems = () => {
        if (!removeConfirmData.removeId || removeConfirmData.removeId.length === 0) {
            return
        }

        removeFavorite(removeConfirmData.removeId)
        rowSelection.clearSelectedRows()
        setRemoveConfirmData({ isOpen: false, removeId: undefined })
    }

    const columns: Array<ColumnDef<FollowedItem>> = [
        {
            header: ({ table }: { table: ITable<FollowedItem> }) => (
                <div className="govuk-checkboxes govuk-checkboxes--small">
                    <CheckBox
                        label=""
                        aria-label={t('table.selectAllItems')}
                        name="checkbox"
                        id="checkbox-all"
                        value="checkbox-all"
                        onChange={table.getToggleAllRowsSelectedHandler()}
                        checked={table.getIsAllRowsSelected()}
                        onClick={(event) => event.stopPropagation()}
                    />
                </div>
            ),
            id: CHECKBOX_CELL,
            cell: ({ row }: { row: Row<FollowedItem> }) => (
                <div className="govuk-checkboxes govuk-checkboxes--small">
                    <CheckBox
                        label=""
                        aria-label={t('table.selectItem', { itemName: row.original.name })}
                        name="checkbox"
                        id={`checkbox_${row.id}`}
                        value="true"
                        onChange={row.getToggleSelectedHandler()}
                        checked={row.getIsSelected()}
                        onClick={(event) => event.stopPropagation()}
                    />
                </div>
            ),
        },
        {
            header: t('userProfile.notifications.table.name'),
            id: 'name',
            enableSorting: true,
            accessorFn: (row) => row,
            meta: {
                getCellContext: (ctx: CellContext<FollowedItem, unknown>) => ctx?.getValue?.(),
            },
            cell: (ctx) => getLink(ctx.getValue() as FollowedItem, location, ciListData ?? {}, ciTypesMap),
        },
        {
            header: t('userProfile.notifications.table.settings'),
            id: 'settings',
            accessorFn: (row) => row,
            cell: (ctx) => {
                const item = ctx.cell.getValue() as FollowedItem
                return (
                    <div className={classNames('govuk-checkboxes', 'govuk-checkboxes--small', styles.buttonContainer)}>
                        <CheckBox
                            id={'t' + (item.itemId ?? '')}
                            name={item.itemId ?? ''}
                            label={t('userProfile.notifications.table.portal')}
                            checked={item.portal}
                            onChange={(event) => handleCheckboxChange({ isPortalChecked: event.target.checked, item })}
                        />
                        <CheckBox
                            id={item.itemId ?? ''}
                            name={item.itemId ?? ''}
                            label={t('userProfile.notifications.table.email')}
                            checked={item.email}
                            onChange={(event) => handleCheckboxChange({ isEmailChecked: event.target.checked, item })}
                        />
                        <ButtonLink
                            className={classNames(styles.removeButton, 'govuk-link')}
                            label={t('userProfile.notifications.table.delete')}
                            onClick={() => {
                                setRemoveConfirmData({ isOpen: true, removeId: [Number(item.id)] })
                            }}
                        />
                    </div>
                )
            },
        },
    ]

    useEffect(() => {
        refetch()
    }, [pagination, refetch])

    const isLoading = [isLoadingList, isLoadingCiListData, isLoadingUpdateMutation, isLoadingRemove, isRefetching].some((item) => item)
    const isError = [isErrorList, isErrorCiListData].some((item) => item)
    const isErrorMutation = [isErrorUpdateMutation, isErrorRemove].some((item) => item)
    const isSuccessMutation = [isSuccessUpdate, isSuccessRemove].some((item) => item)
    const successMessage =
        removedCount > 1 ? t('userProfile.notifications.feedback.removeSuccess') : t('userProfile.notifications.feedback.removeSuccessSingle')

    return (
        <QueryFeedback loading={isLoading} error={isError} withChildren>
            <MutationFeedback
                success={isSuccessMutation}
                error={isErrorMutation}
                errorMessage={t('userProfile.notifications.feedback.error')}
                successMessage={isSuccessRemove ? successMessage : undefined}
                onMessageClose={() => {
                    resetRemoveState()
                    resetUpdateState()
                }}
            />
            <ActionsOverTable
                pagingOptions={DEFAULT_PAGESIZE_OPTIONS}
                handleFilterChange={(filter) => {
                    setPagination({ ...pagination, pageNumber: BASE_PAGE_NUMBER, pageSize: filter.pageSize ?? BASE_PAGE_SIZE })
                }}
                pagination={{ ...pagination, dataLength: listData?.items?.length ?? 0 }}
                hiddenButtons={{ SELECT_COLUMNS: true }}
                selectedRowsCount={rowSelection.count}
                bulkPopup={({ selectedRowsCount }) => (
                    <BulkPopup
                        checkedRowItems={selectedRowsCount}
                        items={() => [
                            <ButtonLink
                                inPopup
                                key={'remove'}
                                label={t('userProfile.notifications.table.delete')}
                                icon={NotificationBlackIcon}
                                className={actionsOverTableStyles.buttonLinkWithIcon}
                                onClick={() => {
                                    setRemoveConfirmData({ isOpen: true, removeId: rowSelection.ids.map((item) => Number(item)) })
                                }}
                            />,
                        ]}
                    />
                )}
                entityName={''}
            />
            <Table<FollowedItem>
                columns={columns}
                data={listData?.items}
                sort={[{ orderBy: pagination.order, sortDirection: pagination.ascending ? SortType.ASC : SortType.DESC }]}
                onSortingChange={(newSort) => {
                    setPagination({ ...pagination, order: newSort?.[0]?.orderBy, ascending: newSort?.[0]?.sortDirection === SortType.ASC })
                    rowSelection.clearSelectedRows()
                }}
                getRowId={(originalRow) => `${originalRow.id}`}
                rowSelection={rowSelection.rowSelection}
                onRowSelectionChange={rowSelection.getOnRowSelectionChange()}
                pagination={{ pageIndex: pagination.pageNumber - 1, pageSize: pagination.pageSize }}
            />
            <PaginatorWrapper
                pageSize={pagination.pageSize}
                pageNumber={pagination.pageNumber}
                dataLength={listData?.paging?.totalCount || 0}
                handlePageChange={(page) => setPagination({ ...pagination, pageNumber: page.pageNumber ?? BASE_PAGE_NUMBER })}
            />
            <ConfirmationModal
                isOpen={removeConfirmData.isOpen}
                onClose={() => setRemoveConfirmData({ isOpen: false, removeId: undefined })}
                onConfirm={() => handleRemoveItems()}
                title={t('userProfile.notifications.confirmRemove')}
            />
        </QueryFeedback>
    )
}
