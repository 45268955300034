import { IFilter, SortType } from '@isdd/idsk-ui-kit/types'
import { IFilterParams, useFilterParams } from '@isdd/metais-common/hooks/useFilter'
import React from 'react'
import { FieldValues } from 'react-hook-form'
import { ListMonitoringOverviewRequest, useListMonitoredItems } from '@isdd/metais-common/api/generated/monitoring-swagger'
import { BASE_PAGE_NUMBER } from '@isdd/metais-common/constants'
import { BASE_PAGE_SIZE } from '@isdd/metais-common/api'
import { formatDateTimeForAPI } from '@isdd/metais-common/index'
export interface MonitoringFilterData extends IFilterParams, FieldValues, IFilterParams, IFilter {
    intervalStart?: string
    intervalEnd?: string
    serviceType?: string
    liableEntity?: string
    project?: string
    isvs?: string
    service?: string
    metaisCode?: string
    serviceName?: string
}
export interface IView {
    data?: ListMonitoringOverviewRequest
    filterParams: MonitoringFilterData
    defaultFilterValues: MonitoringFilterData
    handleFilterChange: (filter: IFilter) => void
    isLoading: boolean
    isError: boolean
}

interface IMonitoringServiceListContainer {
    View: React.FC<IView>
    defaultFilterValues: MonitoringFilterData
}

export const MonitoringServiceListContainer: React.FC<IMonitoringServiceListContainer> = ({ View, defaultFilterValues }) => {
    const { filter, handleFilterChange } = useFilterParams<MonitoringFilterData>({
        sort: [
            {
                orderBy: 'lastValueDate',
                sortDirection: SortType.DESC,
            },
        ],
        ...defaultFilterValues,
    })

    const {
        data: dataAS,
        isLoading: isLoadingAs,
        isError: isErrorAs,
        isFetching: isFetchingAs,
    } = useListMonitoredItems(
        {
            serviceTypes: [filter.serviceType ?? defaultFilterValues.serviceType ?? 'AS'],
            pagination: { page: filter.pageNumber ?? BASE_PAGE_NUMBER, perPageSize: filter.pageSize ?? BASE_PAGE_SIZE },
            intervalStart: formatDateTimeForAPI(filter.intervalStart ?? ''),
            intervalEnd: formatDateTimeForAPI(filter.intervalEnd ?? ''),
            isvsUuid: filter.isvs ?? '',
            metaisCode: filter.metaisCode ?? '',
            serviceName: filter.serviceName ?? '',
            liableEntity: filter.liableEntity ?? '',
            sorting: { direction: filter.sort?.[0]?.sortDirection ?? SortType.DESC, sortAttr: filter.sort?.[0]?.orderBy ?? 'lastValueDate' },
            includePoName: true,
        },
        {
            query: {
                enabled: true,
                queryKey: [filter],
            },
        },
    )

    const isLoading = [isLoadingAs, isFetchingAs].some((item) => item)
    const isError = [isErrorAs].some((item) => item)

    return (
        <View
            filterParams={filter}
            defaultFilterValues={defaultFilterValues}
            handleFilterChange={handleFilterChange}
            isLoading={isLoading}
            isError={isError}
            data={dataAS}
        />
    )
}
