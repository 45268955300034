import { ENTITY_ATTRIBUT_OBJEKTU_EVIDENCIE, ENTITY_ISVS, ENTITY_OBJEKT_EVIDENCIE, ENTITY_PROJECT, ENTITY_ZC } from '@isdd/metais-common/constants'
import { IKeyToDisplay } from '@isdd/metais-common/hooks/useEntityRelationsTypesCount'

type CompareRelationsFnType = (a: IKeyToDisplay, b: IKeyToDisplay) => number

interface IRelationSorterConfig {
    default: CompareRelationsFnType
    [key: string]: CompareRelationsFnType
}

const relationSorterConfig: IRelationSorterConfig = {
    [ENTITY_PROJECT]: (a, b) => {
        if (a.count === b.count && a.technicalName === ENTITY_ISVS) {
            return -1
        }
        return a.count > b.count ? -1 : 1
    },
    [ENTITY_ZC]: (a, b) => {
        if (a.technicalName === ENTITY_ZC) {
            return -1
        }
        return a.count > b.count ? -1 : 1
    },
    [ENTITY_OBJEKT_EVIDENCIE]: (a, b) => {
        if (a.technicalName === ENTITY_ATTRIBUT_OBJEKTU_EVIDENCIE) {
            return -1
        }
        return a.count > b.count ? -1 : 1
    },
    default: (a, b) => (a.count > b.count ? -1 : 1),
}

export const getRelationsSorter = (technicalName: string): CompareRelationsFnType => {
    return relationSorterConfig[technicalName] ? relationSorterConfig[technicalName] : relationSorterConfig.default
}
