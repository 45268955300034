import { BaseModal, TextBody, TextHeading } from '@isdd/idsk-ui-kit/index'
import { useDeleteGroupRelationHook, useFindRelatedIdentitiesAndCountHook } from '@isdd/metais-common/api/generated/iam-swagger'
import { GROUP_ROLES } from '@isdd/metais-common/constants'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { useInvalidateGroupMembersCache } from '@isdd/metais-common/hooks/invalidate-cache'
import { ModalButtons, QueryFeedback } from '@isdd/metais-common/index'
import { NavigationSubRoutes } from '@isdd/metais-common/navigation/routeNames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface DeleteGroupMemberModalProps {
    uuid?: string
    groupUuid: string
    isOpen: boolean
    onClose: () => void
}

const DeleteGroupMemberModal: React.FC<DeleteGroupMemberModalProps> = ({ isOpen, onClose, uuid, groupUuid }) => {
    const { t } = useTranslation()

    const [deletingMember, setDeletingMember] = useState(false)
    const [lastChairmanError, setLastChairmanError] = useState(false)
    const deleteRelationHook = useDeleteGroupRelationHook()
    const relatedIdentitiesAndCountHook = useFindRelatedIdentitiesAndCountHook()
    const invalidateGroupMembersCache = useInvalidateGroupMembersCache(groupUuid ?? '')
    const { setIsActionSuccess } = useActionSuccess()

    const handleOnDeleteClick = async () => {
        setDeletingMember(true)
        const chairmans = await relatedIdentitiesAndCountHook(groupUuid, { role: GROUP_ROLES.STD_PSPRE })
        if (chairmans.count === 1 && chairmans.list?.[0]?.identity?.uuid === uuid) {
            setLastChairmanError(true)
            setDeletingMember(false)
            return
        }
        await deleteRelationHook(uuid ?? '', groupUuid ?? '')
        setDeletingMember(false)
        invalidateGroupMembersCache.invalidate()
        onClose()
        setIsActionSuccess({
            value: true,
            path: `${NavigationSubRoutes.PRACOVNA_SKUPINA_DETAIL}/${groupUuid}`,
            additionalInfo: { entity: 'member', type: 'delete' },
        })
    }

    useEffect(() => {
        isOpen && setLastChairmanError(false)
    }, [isOpen])

    return (
        <>
            <BaseModal isOpen={isOpen} close={onClose}>
                <QueryFeedback
                    error={lastChairmanError}
                    loading={deletingMember}
                    indicatorProps={{ layer: 'dialog', label: t('groups.removingMember') }}
                    errorProps={{ errorMessage: t('groups.lastChairmanError') }}
                    withChildren
                >
                    <TextHeading size="L">{t('groups.removeGroupMember')}</TextHeading>
                    <TextBody>{t('groups.sureRemoveMember')}</TextBody>
                    <ModalButtons
                        submitButtonLabel={t('radioButton.yes')}
                        onSubmit={handleOnDeleteClick}
                        closeButtonLabel={t('form.cancel')}
                        onClose={onClose}
                    />
                </QueryFeedback>
            </BaseModal>
        </>
    )
}
export default DeleteGroupMemberModal
