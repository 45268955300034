import React, { useState } from 'react'
import { useGetCurrentSystemState } from '@isdd/metais-common/api/generated/monitoring-swagger'
import { Languages } from '@isdd/metais-common/localization/languages'
import { SHOW_SYSTEM_STATE_BAR } from '@isdd/metais-common/api'
import { useTranslation } from 'react-i18next'

import { InformationBar } from '@isdd/idsk-ui-kit/index'

export const SystemStatusBar = () => {
    const { i18n } = useTranslation()
    const { data: currentSystemState } = useGetCurrentSystemState()
    const [showInfoBar, setShowInfoBar] = useState(sessionStorage.getItem(SHOW_SYSTEM_STATE_BAR) !== 'false')
    const closeInfoBar = () => {
        setShowInfoBar(false)
        sessionStorage.setItem(SHOW_SYSTEM_STATE_BAR, 'false')
    }

    return !!currentSystemState?.text && showInfoBar ? (
        <InformationBar
            color={currentSystemState?.systemStateColor?.value}
            text={currentSystemState?.text}
            status={
                i18n.language == Languages.SLOVAK
                    ? currentSystemState?.systemState?.description ?? ''
                    : currentSystemState?.systemState?.engDescription ?? ''
            }
            onClose={() => closeInfoBar()}
        />
    ) : (
        <></>
    )
}
