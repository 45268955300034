import React from 'react'
import { useTranslation } from 'react-i18next'
import { setDocumentTitle } from '@isdd/metais-common/utils/utils'

import { VoteComposeFormView } from '@/components/views/standardization/votes/VoteComposeForm/VoteComposeFormView'
import { VoteCreateEditContainer } from '@/components/containers/standardization/votes/VoteCreateEditContainer'
import { getPageTitle } from '@/components/views/standardization/votes/VoteComposeForm/functions/voteEditFunc'

const VoteCreatePage: React.FC = () => {
    const { t } = useTranslation()
    setDocumentTitle(getPageTitle(true, t))
    return (
        <VoteCreateEditContainer
            isNewVote
            View={(props) => (
                <VoteComposeFormView
                    user={props.user}
                    existingVoteDataToEdit={props.existingVoteDataToEdit}
                    allStandardRequestData={props.allStandardRequestData}
                    groupWithIdentitiesData={props.groupWithIdentitiesData}
                    isSubmitLoading={props.isSubmitLoading}
                    isSubmitError={props.isSubmitError}
                    isIdentifiersLoading={props.isIdentifiersLoading}
                    createVote={props.createVote}
                    updateVote={props.updateVote}
                    onCancel={props.onCancel}
                    existingFilesProcessRef={props.existingFilesProcessRef}
                    fileUploadRef={props.fileUploadRef}
                    handleDeleteSuccess={props.handleDeleteSuccess}
                    handleUploadSuccess={props.handleUploadSuccess}
                    voteId={props.voteId}
                />
            )}
        />
    )
}

export default VoteCreatePage
