import { BreadCrumbs, HomeIcon } from '@isdd/idsk-ui-kit/index'
import { RouteNames, RouterRoutes } from '@isdd/metais-common/navigation/routeNames'
import { useTranslation } from 'react-i18next'
import { setDocumentTitle } from '@isdd/metais-common/utils/utils'

import { OlaContractSaveView } from '@/components/views/ola-contract-list/OlaContractSaveView'
import { OlaContractAddContainer } from '@/components/containers/OlaContractAddContainer'

export const OlaContractCreate = () => {
    const { t } = useTranslation()
    setDocumentTitle(t('olaContracts.headingAdd'))
    return (
        <>
            <BreadCrumbs
                withWidthContainer
                links={[
                    { label: t('breadcrumbs.home'), href: RouteNames.HOME, icon: HomeIcon },
                    { label: t('olaContracts.heading'), href: RouterRoutes.OLA_CONTRACT_LIST },
                    { label: t('olaContracts.headingAdd'), href: RouterRoutes.OLA_CONTRACT_CREATE },
                ]}
            />
            <OlaContractAddContainer View={(props) => <OlaContractSaveView {...props} />} />
        </>
    )
}
