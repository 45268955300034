import { ATTRIBUTE_PROFILE_NAME, ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { CiType } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { cloneDeep } from 'lodash'

export function updateProjectFinanceConstraints(ciTypeData: CiType | undefined, minValue: number | undefined, maxValue: number | undefined) {
    const newCiTypeData = cloneDeep(ciTypeData)

    newCiTypeData?.attributeProfiles
        ?.find((p) => p.technicalName === ATTRIBUTE_PROFILE_NAME.Financny_Profil_Projekt)
        ?.attributes?.forEach((a) => {
            const isSumaVydavkov = a.technicalName === ATTRIBUTE_NAME.Financny_Profil_Projekt_suma_vydavkov

            if (isSumaVydavkov) {
                a.constraints = [{ type: 'interval', minValue: minValue, maxValue: maxValue }]
            }
        })

    return newCiTypeData
}
