import {
    BreadCrumbs,
    Button,
    ButtonGroupRow,
    ButtonLink,
    ButtonPopup,
    ConfirmationModal,
    HomeIcon,
    TextWarning,
    LoadingIndicator,
    Tab,
    Tabs,
    TextHeading,
} from '@isdd/idsk-ui-kit/index'
import { Can, useAbilityContextWithFeedback } from '@isdd/metais-common/hooks/permissions/useAbilityContext'
import { Actions, Subjects } from '@isdd/metais-common/hooks/permissions/useCodeListPermissions'
import { MutationFeedback, QueryFeedback } from '@isdd/metais-common/index'
import { NavigationSubRoutes, RouteNames } from '@isdd/metais-common/navigation/routeNames'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { useScroll } from '@isdd/metais-common/hooks/useScroll'
import headerStyles from '@isdd/metais-common/components/entity-header/ciEntityHeader.module.scss'
import { setDocumentTitle } from '@isdd/metais-common/utils/utils'

import styles from './codeList.module.scss'
import { CodeListDetailItemsWrapper } from './CodeListDetailItemsWrapper'
import { getAllWorkingLanguages, selectBasedOnLanguageAndDate } from './CodeListDetailUtils'
import { ExportCodeListModal } from './components/modals/ExportCodeListModal/ExportCodeListModal'
import { ImportCodeListModal } from './components/modals/ImportCodeListModal/ImportCodeListModal'
import { NewLanguageVersionModal } from './components/modals/NewLanguageVersionModal/NewLanguageVersionModal'
import { BasicInfoTabView } from './components/tabs/BasicInfoTabView'
import { GestorTabView } from './components/tabs/GestorTabView'
import { HistoryTabView } from './components/tabs/HistoryTabView'

import { MainContentWrapper } from '@/components/MainContentWrapper'
import { CodeListDetailWrapperProps } from '@/components/containers/CodeListDetailContainer'
import { CodeListDetailHistoryContainer } from '@/components/containers/CodeListDetailHistoryContainer'

export enum IsSuccessActions {
    IMPORT = 'import',
}

export const CodeListDetailWrapper: React.FC<CodeListDetailWrapperProps> = ({
    data,
    isLoading,
    isLoadingMutation,
    isError,
    actionsErrorMessages,
    isSuccessMutation,
    successMessage,
    workingLanguage,
    setWorkingLanguage,
    invalidateCodeListDetailCache,
    invalidateCodeListDetailItemsCache,
    handleAllItemsReadyToPublish,
    handleSendToIsvs,
    handlePublishCodeList,
    handleReturnToMainGestor,
    handleSendToSzzc,
    handleDiscardChanges,
    handleRemoveLock,
    handleEdit,
    itemsFilter,
    handleItemsFilterChange,
    handleMarkForPublish,
    handleSetDates,
    isErrorItemsTable,
    isLoadingItemAction,
    isLoadingItemsTable,
    isSuccessItemActionMutation,
    itemActionErrors,
    onItemsModalOpen,
    itemsData,
    attributeProfile,
    rowSelection,
}) => {
    const { t } = useTranslation()
    const { isLoading: isAbilityLoading, isError: isAbilityError } = useAbilityContextWithFeedback()
    const focusTriggerRef = useRef<() => void>()

    const {
        isActionSuccess: { value: isSuccessEdit, additionalInfo: isSuccessAdditionalInfo },
    } = useActionSuccess()

    const [isExportModalOpen, setIsExportModalOpen] = useState(false)
    const [isImportModalOpen, setIsImportModalOpen] = useState(false)
    const [isNewLanguageVersionModalOpen, setIsNewLanguageVersionModalOpen] = useState(false)
    const [isMarkForPublishItemsModalOpen, setIsMarkForPublishItemsModalOpen] = useState(false)
    const [isSendToIsvsOpen, setIsSendToIsvsOpen] = useState(false)
    const [isPublishCodeListOpen, setIsPublishCodeListOpen] = useState(false)
    const [isReturnToMainGestorOpen, setIsReturnToMainGestorOpen] = useState(false)
    const [isSendToSzzcOpen, setIsSendToSzzcOpen] = useState(false)
    const [isDiscardOpened, setIsDiscardOpen] = useState(false)
    const [isRemoveLockOpened, setIsRemoveLockOpened] = useState(false)
    const [isMutationSuccess, setIsMutationSuccess] = useState(false)

    const code = data.codeList?.code ?? ''
    const mainSuccessMessage = isSuccessAdditionalInfo?.action === IsSuccessActions.IMPORT ? t('codeListDetail.feedback.importDone') : successMessage

    const tabs: Tab[] = [
        {
            id: 'basic-info',
            title: t('codeListDetail.title.tab.info'),
            content: (
                <BasicInfoTabView
                    codeList={data.codeList ?? {}}
                    gestorList={data.gestors}
                    attributeProfile={data.attributeProfile ?? {}}
                    workingLanguage={workingLanguage}
                    showDateIntervals
                />
            ),
        },
        {
            id: 'gestor',
            title: t('codeListDetail.title.tab.gestor'),
            content: <GestorTabView codeList={data.codeList ?? {}} attributeProfile={data.attributeProfile ?? {}} />,
        },
    ]
    const tabsWithHistory: Tab[] = [
        ...tabs,
        {
            id: 'history',
            title: t('codeListDetail.title.tab.history'),
            content: (
                <CodeListDetailHistoryContainer
                    code={code}
                    View={(props) => (
                        <HistoryTabView
                            isError={props.isError}
                            isLoading={props.isLoading}
                            data={props.data}
                            filter={props.filter}
                            handleFilterChange={props.handleFilterChange}
                        />
                    )}
                />
            ),
        },
    ]

    const title = selectBasedOnLanguageAndDate(data.codeList?.codelistNames, workingLanguage) ?? ''
    const { wrapperRef, scrollToMutationFeedback } = useScroll()

    useEffect(() => {
        if (isSuccessMutation || isSuccessEdit || actionsErrorMessages.length > 0) {
            scrollToMutationFeedback()
        }
    }, [actionsErrorMessages.length, isSuccessEdit, isSuccessMutation, scrollToMutationFeedback])

    const breadcrumbs = (
        <BreadCrumbs
            withWidthContainer
            links={[
                { label: t('codeList.breadcrumbs.home'), href: RouteNames.HOME, icon: HomeIcon },
                { label: t('codeList.breadcrumbs.dataObjects'), href: RouteNames.HOW_TO_DATA_OBJECTS },
                { label: t('codeList.breadcrumbs.codeLists'), href: RouteNames.HOW_TO_CODELIST },
                { label: t('codeList.breadcrumbs.codeListsList'), href: NavigationSubRoutes.CODELIST },
                { label: String(title), href: `${NavigationSubRoutes.CODELIST}/${data.codeList?.id}` },
            ]}
        />
    )

    if (isError && !code) {
        return (
            <>
                {breadcrumbs}
                <MainContentWrapper>
                    <QueryFeedback error={isError} loading={false} />
                </MainContentWrapper>
            </>
        )
    }

    setDocumentTitle(`${t('titles.codeListDetail')} ${String(title)}`)

    const isAnyModalOpened = () =>
        [
            isExportModalOpen,
            isImportModalOpen,
            isSendToIsvsOpen,
            isPublishCodeListOpen,
            isMarkForPublishItemsModalOpen,
            isSendToIsvsOpen,
            isPublishCodeListOpen,
            isMarkForPublishItemsModalOpen,
            isSendToSzzcOpen,
            isReturnToMainGestorOpen,
            isNewLanguageVersionModalOpen,
            isRemoveLockOpened,
            isDiscardOpened,
        ].includes(true)

    return (
        <>
            {breadcrumbs}
            <MainContentWrapper>
                <MutationFeedback
                    success={isMutationSuccess}
                    successMessage={t('codeListDetail.feedback.translationCreated')}
                    onMessageClose={() => setIsMutationSuccess(false)}
                />
                {isMutationSuccess && <TextWarning>{t('codeListDetail.feedback.translationWarning')}</TextWarning>}
                <QueryFeedback loading={isLoading || !!isAbilityLoading} error={false} withChildren>
                    {isLoadingMutation && <LoadingIndicator label={t('feedback.saving')} />}
                    <div className={headerStyles.headerDiv}>
                        <TextHeading size="XL">{title}</TextHeading>
                        <ButtonGroupRow className={styles.buttonGroupRow}>
                            <ButtonPopup
                                buttonLabel={t('codeListDetail.button.language')}
                                popupContent={() => {
                                    return (
                                        <div className={headerStyles.buttonLinksDiv}>
                                            {getAllWorkingLanguages(data.codeList).map((language) => (
                                                <ButtonLink
                                                    key={language}
                                                    label={
                                                        t(`codeListDetail.languages.${language}`) +
                                                        (language === workingLanguage ? t('codeListDetail.selectedSuffix') : '')
                                                    }
                                                    onClick={() => setWorkingLanguage(language)}
                                                    aria-current={language === workingLanguage}
                                                />
                                            ))}
                                        </div>
                                    )
                                }}
                            />
                            <Can I={Actions.EDIT} a={Subjects.DETAIL}>
                                <Button label={t('codeListDetail.button.edit')} onClick={handleEdit} />
                            </Can>
                            <ButtonPopup
                                buttonLabel={t('codeListDetail.button.more')}
                                popupHorizontalPosition="right"
                                manuallyHandledFocusAfterClose
                                onClose={() => {
                                    if (!isAnyModalOpened()) {
                                        focusTriggerRef.current?.()
                                    }
                                }}
                                popupContent={(_, focusTriggerEl) => {
                                    focusTriggerRef.current = focusTriggerEl

                                    return (
                                        <div className={headerStyles.buttonLinksDiv}>
                                            <Can I={Actions.EXPORT} a={Subjects.DETAIL}>
                                                <ButtonLink
                                                    key={'export'}
                                                    label={t('codeListDetail.button.exportCodelist')}
                                                    onClick={() => setIsExportModalOpen(true)}
                                                    aria={{ 'aria-haspopup': 'dialog' }}
                                                />
                                            </Can>
                                            <Can I={Actions.IMPORT} a={Subjects.DETAIL}>
                                                <ButtonLink
                                                    key={'import'}
                                                    label={t('codeListDetail.button.importCodelist')}
                                                    onClick={() => setIsImportModalOpen(true)}
                                                    aria={{ 'aria-haspopup': 'dialog' }}
                                                />
                                            </Can>
                                            <Can I={Actions.SEND_TO} a={Subjects.DETAIL} field="isvs">
                                                <ButtonLink
                                                    key={'sendToIsvs'}
                                                    label={t('codeListDetail.button.sendToIsvs')}
                                                    onClick={() => setIsSendToIsvsOpen(true)}
                                                    aria={{ 'aria-haspopup': 'dialog' }}
                                                />
                                            </Can>
                                            <Can I={Actions.PUBLISH} a={Subjects.DETAIL}>
                                                <ButtonLink
                                                    key={'publishCodelist'}
                                                    label={t('codeListDetail.button.publishCodelist')}
                                                    onClick={() => setIsPublishCodeListOpen(true)}
                                                    aria={{ 'aria-haspopup': 'dialog' }}
                                                />
                                            </Can>
                                            <Can I={Actions.PUBLISH} a={Subjects.ITEM} field="all">
                                                <ButtonLink
                                                    key={'publishCodelistItems'}
                                                    label={t('codeListDetail.button.publishCodelistItems')}
                                                    onClick={() => setIsMarkForPublishItemsModalOpen(true)}
                                                    aria={{ 'aria-haspopup': 'dialog' }}
                                                />
                                            </Can>
                                            <Can I={Actions.SEND_TO} a={Subjects.DETAIL} field="szzc">
                                                <ButtonLink
                                                    key={'sendToSzzc'}
                                                    label={t('codeListDetail.button.sendToSzzc')}
                                                    onClick={() => setIsSendToSzzcOpen(true)}
                                                    aria={{ 'aria-haspopup': 'dialog' }}
                                                />
                                            </Can>
                                            <Can I={Actions.SEND_TO} a={Subjects.DETAIL} field="mainGestor">
                                                <ButtonLink
                                                    key={'returnToMainGestor'}
                                                    label={t('codeListDetail.button.returnToMainGestor')}
                                                    onClick={() => setIsReturnToMainGestorOpen(true)}
                                                    aria={{ 'aria-haspopup': 'dialog' }}
                                                />
                                            </Can>
                                            <Can I={Actions.CREATE} a={Subjects.DETAIL} field="languageVersion">
                                                <ButtonLink
                                                    key={'addLanguage'}
                                                    label={t('codeListDetail.button.addLanguage')}
                                                    onClick={() => setIsNewLanguageVersionModalOpen(true)}
                                                    aria={{ 'aria-haspopup': 'dialog' }}
                                                />
                                            </Can>
                                            <Can I={Actions.UNLOCK} a={Subjects.DETAIL}>
                                                <ButtonLink
                                                    key={'removeLock'}
                                                    label={t('codeListDetail.button.removeLock')}
                                                    onClick={() => setIsRemoveLockOpened(true)}
                                                    aria={{ 'aria-haspopup': 'dialog' }}
                                                />
                                            </Can>
                                            <Can I={Actions.DISCARD} a={Subjects.DETAIL}>
                                                <ButtonLink
                                                    key={'discardUpdating'}
                                                    label={t('codeListDetail.button.discardUpdating')}
                                                    onClick={() => setIsDiscardOpen(true)}
                                                    aria={{ 'aria-haspopup': 'dialog' }}
                                                />
                                            </Can>
                                        </div>
                                    )
                                }}
                            />
                        </ButtonGroupRow>
                    </div>
                    <QueryFeedback error={isError || isAbilityError} loading={false} />
                    <div ref={wrapperRef}>
                        <MutationFeedback success={isSuccessMutation || isSuccessEdit} successMessage={mainSuccessMessage} />
                        {actionsErrorMessages.map((errorMessage, index) => (
                            <MutationFeedback key={index} error errorMessage={errorMessage && t(errorMessage)} />
                        ))}
                    </div>
                    {data.codeList?.temporal && data.codeList.locked && (
                        <TextWarning>
                            {t('codeListDetail.warning.itemLocked', {
                                user: data.codeList.lockedBy,
                                date: t('date', { date: data.codeList.lockedFrom }),
                            })}
                        </TextWarning>
                    )}
                    {data.codeList?.temporal && !data.codeList.locked && <TextWarning>{t('codeListDetail.warning.workVersion')}</TextWarning>}
                    <Can not I={Actions.READ} a={Subjects.DETAIL} field="history">
                        <Tabs tabList={tabs} />
                    </Can>
                    <Can I={Actions.READ} a={Subjects.DETAIL} field="history">
                        <Tabs tabList={tabsWithHistory} />
                    </Can>
                    <CodeListDetailItemsWrapper
                        code={code}
                        itemsFilter={itemsFilter}
                        handleItemsFilterChange={handleItemsFilterChange}
                        handleMarkForPublish={handleMarkForPublish}
                        handleSetDates={handleSetDates}
                        invalidateCodeListDetailItemsCache={invalidateCodeListDetailItemsCache}
                        isErrorItemsTable={isErrorItemsTable}
                        isLoadingItemAction={isLoadingItemAction}
                        itemActionErrors={itemActionErrors}
                        onItemsModalOpen={onItemsModalOpen}
                        workingLanguage={workingLanguage}
                        itemsData={itemsData}
                        attributeProfile={attributeProfile}
                        isLoadingItemsTable={isLoadingItemsTable}
                        isSuccessItemActionMutation={isSuccessItemActionMutation}
                        isCodelistAutoincrementValid={data.codeList?.autoincrement?.valid}
                        codelistPrefix={data.codeList?.prefix}
                        codelistRefId={data.codeList?.uri}
                        codelistCharCount={data.codeList?.autoincrement?.charCount}
                        rowSelection={rowSelection}
                        codelistId={data.codeList?.id ?? 0}
                    />
                    <ExportCodeListModal
                        code={code}
                        isOpen={isExportModalOpen}
                        onClose={() => {
                            setIsExportModalOpen(false)
                            focusTriggerRef.current?.()
                        }}
                    />
                    <ImportCodeListModal
                        code={code}
                        id={Number(data.codeList?.id)}
                        isOpen={isImportModalOpen}
                        onClose={() => {
                            setIsImportModalOpen(false)
                            focusTriggerRef.current?.()
                        }}
                    />
                    <ConfirmationModal
                        isOpen={isMarkForPublishItemsModalOpen}
                        onClose={() => {
                            setIsMarkForPublishItemsModalOpen(false)
                            focusTriggerRef.current?.()
                        }}
                        onConfirm={() =>
                            handleAllItemsReadyToPublish(() => {
                                setIsMarkForPublishItemsModalOpen(false)
                                focusTriggerRef.current?.()
                            })
                        }
                        title={t('codeListDetail.modal.title.markForPublishAllItems')}
                        okButtonLabel={t('codeListDetail.modal.button.confirm')}
                    />
                    <ConfirmationModal
                        isOpen={isSendToIsvsOpen}
                        onClose={() => {
                            setIsSendToIsvsOpen(false)
                            focusTriggerRef.current?.()
                        }}
                        onConfirm={() =>
                            handleSendToIsvs(() => {
                                setIsSendToIsvsOpen(false)
                                focusTriggerRef.current?.()
                            })
                        }
                        title={t('codeListDetail.modal.title.sendToIsvs')}
                        okButtonLabel={t('codeListDetail.modal.button.confirm')}
                    />
                    <ConfirmationModal
                        isOpen={isPublishCodeListOpen}
                        onClose={() => {
                            setIsPublishCodeListOpen(false)
                            focusTriggerRef.current?.()
                        }}
                        onConfirm={() =>
                            handlePublishCodeList(() => {
                                setIsPublishCodeListOpen(false)
                                focusTriggerRef.current?.()
                            })
                        }
                        title={t('codeListDetail.modal.title.publishCodeList')}
                        okButtonLabel={t('codeListDetail.modal.button.confirm')}
                    />
                    <ConfirmationModal
                        isOpen={isReturnToMainGestorOpen}
                        onClose={() => {
                            setIsReturnToMainGestorOpen(false)
                            focusTriggerRef.current?.()
                        }}
                        onConfirm={() => {
                            handleReturnToMainGestor(() => {
                                setIsReturnToMainGestorOpen(false)
                                focusTriggerRef.current?.()
                            })
                        }}
                        title={t('codeListDetail.modal.title.returnToMainGestor')}
                        okButtonLabel={t('codeListDetail.modal.button.confirm')}
                    />
                    <ConfirmationModal
                        isOpen={isSendToSzzcOpen}
                        onClose={() => {
                            setIsSendToSzzcOpen(false)
                            focusTriggerRef.current?.()
                        }}
                        onConfirm={() => {
                            handleSendToSzzc(() => {
                                setIsSendToSzzcOpen(false)
                                focusTriggerRef.current?.()
                            })
                        }}
                        title={t('codeListDetail.modal.title.sendToSzzc')}
                        okButtonLabel={t('codeListDetail.modal.button.confirm')}
                    />
                    <ConfirmationModal
                        isOpen={isDiscardOpened}
                        onClose={() => {
                            setIsDiscardOpen(false)
                            focusTriggerRef.current?.()
                        }}
                        onConfirm={() => {
                            handleDiscardChanges(() => {
                                setIsDiscardOpen(false)
                                focusTriggerRef.current?.()
                            })
                        }}
                        title={t('codeListDetail.modal.title.discardUpdating')}
                        okButtonLabel={t('codeListDetail.modal.button.confirm')}
                    />
                    <ConfirmationModal
                        isOpen={isRemoveLockOpened}
                        onClose={() => {
                            setIsRemoveLockOpened(false)
                            focusTriggerRef.current?.()
                        }}
                        onConfirm={() => {
                            handleRemoveLock(() => {
                                setIsRemoveLockOpened(false)
                                focusTriggerRef.current?.()
                            })
                        }}
                        title={t('codeListDetail.modal.title.removeLock')}
                        okButtonLabel={t('codeListDetail.modal.button.confirm')}
                    />
                    <NewLanguageVersionModal
                        code={code}
                        isOpen={isNewLanguageVersionModalOpen}
                        onClose={() => {
                            setIsNewLanguageVersionModalOpen(false)
                            focusTriggerRef.current?.()
                        }}
                        onSuccess={() => {
                            setIsMutationSuccess(true)
                            setIsNewLanguageVersionModalOpen(false)
                            invalidateCodeListDetailCache()
                            focusTriggerRef.current?.()
                        }}
                    />
                </QueryFeedback>
            </MainContentWrapper>
        </>
    )
}
