import { Option } from '@isdd/idsk-ui-kit/common/SelectCommon'
import { DEFAULT_LAZY_LOAD_PER_PAGE, ILoadOptionsResponse, SelectLazyLoading } from '@isdd/idsk-ui-kit/index'
import { SortType } from '@isdd/idsk-ui-kit/types'
import React, { useCallback, useState } from 'react'
import { UseFormClearErrors, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { OptionProps } from 'react-select'

import { RequestFormFields, UserRequestRightsForm } from './UserProfileRequestRightsModal'

import { mapToOption, SelectFilterOrganizationOptionType } from '@isdd/metais-common/components/select-organization/SelectFilterOrganization'
import { PO } from '@isdd/metais-common/constants'
import { AddressObjectUi, useReadCiList1Hook } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { ATTRIBUTE_NAME, QueryFeedback } from '@isdd/metais-common/index'

export type SelectFilterOrganizationHierarchyOptionType = {
    poUUID: string
    poName: string
    address: AddressObjectUi
}

interface SelectFilterOrganizationHierarchyProps {
    setValue: UseFormSetValue<UserRequestRightsForm>
    error?: string
    clearErrors?: UseFormClearErrors<UserRequestRightsForm>
    requiredString?: string
}

const formatOption = (props: OptionProps<SelectFilterOrganizationOptionType>) => {
    return (
        <Option {...props}>
            <div>{props.data.name}</div>
            <span>
                <small>{props.data.address}</small>
            </span>
        </Option>
    )
}
export const SelectImplicitHierarchy: React.FC<SelectFilterOrganizationHierarchyProps> = ({ setValue, error, clearErrors, requiredString }) => {
    const { t } = useTranslation()

    const [defaultValue] = useState<SelectFilterOrganizationOptionType | undefined>(undefined)
    const readCiListHook = useReadCiList1Hook()

    // const loadOptions = useCallback(

    const loadOptions = useCallback(
        async (searchQuery: string, additional: { page: number } | undefined): Promise<ILoadOptionsResponse<SelectFilterOrganizationOptionType>> => {
            const page = !additional?.page ? 1 : (additional?.page || 0) + 1
            const response = await readCiListHook({
                filter: {
                    searchFields: [ATTRIBUTE_NAME.Gen_Profil_nazov, ATTRIBUTE_NAME.Gen_Profil_kod_metais],
                    fullTextSearch: searchQuery,
                    type: [PO],
                },
                sortBy: 'Gen_Profil_nazov',
                sortType: SortType.ASC,
                page,
                perpage: DEFAULT_LAZY_LOAD_PER_PAGE,
            })

            return {
                options: mapToOption(response.configurationItemSet),
                hasMore: page < (response.pagination?.totalPages ?? 0),
                additional: {
                    page: page,
                },
            }
        },
        [readCiListHook],
    )

    return (
        <>
            <SelectLazyLoading
                key={RequestFormFields.PO}
                placeholder={t('userProfile.requests.placeholderPO')}
                getOptionLabel={(item) => item.name}
                getOptionValue={(item) => item.uuid}
                loadOptions={(searchQuery, _prevOptions, additional) => loadOptions(searchQuery, additional)}
                label={t('userProfile.requests.po') + requiredString}
                name={RequestFormFields.PO}
                option={(ctx) => formatOption(ctx)}
                setValue={setValue}
                defaultValue={defaultValue}
                error={error}
                clearErrors={clearErrors}
                isClearable={false}
            />
            <QueryFeedback loading={false} errorProps={{ errorMessage: t('userManagement.error.query') }} />
        </>
    )
}
