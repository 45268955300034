import { CheckBox, Table } from '@isdd/idsk-ui-kit/index'
import { CHECKBOX_CELL } from '@isdd/idsk-ui-kit/table/constants'
import { IFilter } from '@isdd/idsk-ui-kit/types'
import { ApiCodelistItem, ApiCodelistItemName } from '@isdd/metais-common/api/generated/codelist-repo-swagger'
import { AttributeProfile } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { useAbilityContext } from '@isdd/metais-common/hooks/permissions/useAbilityContext'
import { Actions, Subjects } from '@isdd/metais-common/hooks/permissions/useCodeListPermissions'
import { ColumnDef, ExpandedState, Row } from '@tanstack/react-table'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RowSelection } from '@isdd/metais-common/hooks/useRowSelection'

import { CodeListDetailItemsTableExpandedRow } from './CodeListDetailItemsTableExpandedRow'
import { selectBasedOnLanguageAndDate } from './CodeListDetailUtils'

import { CodeListItemState } from '@/componentHelpers/codeList'

export interface TableCols extends ApiCodelistItem {
    codelistItemName?: string
    isEffective?: boolean
    selected?: boolean
}
export interface CodeListDetailItemsTableProps {
    items: { codelistsItemCount?: number; codelistsItems?: TableCols[] }
    attributeProfile?: AttributeProfile
    filter: IFilter
    workingLanguage: string
    handleOpenEditItem: (item: TableCols) => void
    handleFilterChange: (filter: IFilter) => void
    handleMarkForPublish: (itemCodes: string[]) => void
    rowSelection: RowSelection<TableCols>
}

const enableRowSelection = (row: Row<TableCols>) =>
    row.original.codelistItemState !== CodeListItemState.PUBLISHED && row.original.codelistItemState !== CodeListItemState.READY_TO_PUBLISH

export const CodeListDetailItemsTable: React.FC<CodeListDetailItemsTableProps> = ({
    items,
    attributeProfile,
    filter,
    workingLanguage,
    handleOpenEditItem,
    handleFilterChange,
    handleMarkForPublish,
    rowSelection,
}) => {
    const { t } = useTranslation()
    const [expandedState, setExpandedState] = useState<ExpandedState>({})

    const ability = useAbilityContext()
    const columns: Array<ColumnDef<TableCols>> = []

    if (ability.can(Actions.BULK_ACTIONS, Subjects.ITEM)) {
        columns.push({
            id: CHECKBOX_CELL,
            header: ({ table }) => {
                const rowsWithoutDisabled = table.getRowModel().rows.filter(enableRowSelection)
                return (
                    <div className="govuk-checkboxes govuk-checkboxes--small">
                        <CheckBox
                            label=""
                            name="checkbox"
                            id="checkbox-all"
                            onChange={table.getToggleAllRowsSelectedHandler()}
                            disabled={rowsWithoutDisabled.length === 0}
                            checked={table.getIsAllRowsSelected()}
                            aria-label={t('table.selectAllItems')}
                        />
                    </div>
                )
            },
            cell: ({ row }) => (
                <div className="govuk-checkboxes govuk-checkboxes--small">
                    <CheckBox
                        label=""
                        name="checkbox"
                        id={`checkbox_${row.id}`}
                        value="true"
                        onClick={(event) => event.stopPropagation()}
                        onChange={row.getToggleSelectedHandler()}
                        checked={row.getIsSelected()}
                        disabled={!row.getCanSelect()}
                        aria-label={t('table.selectItem', {
                            itemName: selectBasedOnLanguageAndDate(row.original.codelistItemNames as ApiCodelistItemName[], workingLanguage),
                        })}
                    />
                </div>
            ),
        })
    }

    columns.push(
        {
            id: 'codelistNames',
            header: t('codeListDetail.table.name'),
            size: 150,
            accessorFn: (row) => row.codelistItemName,
            enableSorting: true,
        },
        {
            id: 'itemCode',
            header: t('codeListDetail.table.code'),
            accessorFn: (row) => row.itemCode,
            enableSorting: true,
        },
    )

    if (ability.can(Actions.BULK_ACTIONS, Subjects.ITEM)) {
        columns.push(
            {
                id: 'state',
                header: t('codeListDetail.table.state'),
                accessorFn: (row) => t(`codeListDetail.state.${row.codelistItemState}`),
            },
            {
                id: 'readyForPublish',
                header: t('codeListDetail.table.readyToPublish'),
                accessorFn: (row) => row.codelistItemState,
                cell: (row) => (row.getValue() === 'READY_TO_PUBLISH' ? t('radioButton.yes') : t('radioButton.no')),
            },
            {
                id: 'effective',
                header: t('codeListDetail.table.effective'),
                accessorFn: (row) => row.isEffective,
                cell: (row) => {
                    return row.getValue() ? t('radioButton.yes') : t('radioButton.no')
                },
            },
        )
    }

    return (
        <Table<TableCols>
            data={items?.codelistsItems}
            columns={columns}
            sort={filter.sort ?? []}
            expandedRowsState={expandedState}
            onExpandedChange={setExpandedState}
            getExpandedRow={(row) => {
                return (
                    <CodeListDetailItemsTableExpandedRow
                        workingLanguage={workingLanguage}
                        codelistItem={items?.codelistsItems?.find((item) => item.id === row.original.id)}
                        attributeProfile={attributeProfile}
                        handleOpenEditItem={handleOpenEditItem}
                        handleMarkForPublish={handleMarkForPublish}
                    />
                )
            }}
            onSortingChange={(columnSort) => {
                handleFilterChange({ sort: columnSort })
                rowSelection.clearSelectedRows()
            }}
            enableRowSelection={enableRowSelection}
            getRowId={(originalRow) => `${originalRow.id}`}
            rowSelection={rowSelection.rowSelection}
            onRowSelectionChange={rowSelection.getOnRowSelectionChange({
                pageNumber: filter.pageNumber,
                mapper: (id) => items.codelistsItems?.find((codeListItem) => String(codeListItem.id) === id),
            })}
        />
    )
}
