import { Button, ButtonGroupRow, CheckBox, InfoIconWithText, Table, TableHandle, TextArea, TextHeading } from '@isdd/idsk-ui-kit/index'
import {
    KrisToBeActivity,
    KrisToBeProject,
    KrisToBeRights,
    NewNoteUi,
    NoteItemUi,
    StateValueUi,
    SubItemNewNoteUi,
    SubItemNoteUi,
    SubItemsNewNotesUi,
    SubItemsNotesUi,
    useAddEvaluationHook,
    useAddResponseHook,
    useAddSubItemEvaluationsHook,
    useAddSubItemResponseHook,
    useGetEvaluations,
    useGetSubItemEvaluations,
} from '@isdd/metais-common/api/generated/kris-swagger'
import { AttributeProfile } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { MutationFeedback, QueryFeedback } from '@isdd/metais-common/index'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ColumnDef, Row } from '@tanstack/react-table'

import { IResultCall } from '@/components/views/evaluation/EvaluationView'
import styles from '@/components/views/evaluation/evaluationView.module.scss'

interface IProjectEvaluationActivitieRowProps {
    uuid?: string
    entityId: string
    activities?: KrisToBeActivity[]
    attributes?: AttributeProfile
    dataRights?: KrisToBeRights
    project: KrisToBeProject
    isGlobalAllowed: boolean
}

interface IDetailISVSColumn {
    id: string
    name: string
    tooltip: string
    lastEvaluation?: string
    createdBy?: string
    isApproved: boolean
    evaluation?: string
    response?: string
}

export const ProjectEvaluationActivitieRow: React.FC<IProjectEvaluationActivitieRowProps> = ({
    uuid,
    entityId,
    attributes,
    activities,
    dataRights,
    isGlobalAllowed,
}) => {
    const ACTIVITY_ATTRIBUTE_NAME = 'Profil_KRIS_TO_BE_ACTIVITY_common'
    const { t } = useTranslation()
    const { data: evalData, isError, isLoading, refetch, isFetching } = useGetEvaluations(entityId, uuid ?? '', 'PROJECT')
    const {
        data: activityAttributes,
        isError: isErrorAct,
        isLoading: isLoadingAct,
        refetch: refetchAct,
        isFetching: isFetchingAct,
    } = useGetSubItemEvaluations(entityId, uuid ?? '', 'PROJECT', 'ACTIVITY')

    const { register, handleSubmit, setValue, getValues } = useForm<Array<IDetailISVSColumn>>()
    const {
        register: registerActi,
        handleSubmit: handleSubmitAct,
        setValue: setValueAct,
        getValues: getValuesAct,
    } = useForm<Array<IDetailISVSColumn>>()

    const tableHandle = useRef<TableHandle>(null)
    const [rowSelection, setRowSelection] = useState<Array<string>>([])
    const [rowSelectionAct, setRowSelectionAct] = useState<Array<string>>([])
    const [isLoadingAddData, setLoadingAddData] = useState<boolean>(false)
    const [isLoadingAddDataAct, setLoadingAddDataAct] = useState<boolean>(false)
    const [isErrorAddData, setErrorAddData] = useState<boolean>(false)
    const [isErrorAddDataAct, setErrorAddDataAct] = useState<boolean>(false)
    const [isEditRow, setEditRow] = useState<boolean>(false)
    const [isEditActivitiesRow, setEditActivitiesRow] = useState<boolean>(false)
    const saveHook = useAddEvaluationHook()
    const saveActivityHook = useAddSubItemEvaluationsHook()
    const saveResponseHook = useAddResponseHook()
    const saveResponseActivityHook = useAddSubItemResponseHook()
    const [resultSuccessApiCall, setResultSuccessApiCall] = useState<IResultCall>({ isSuccess: false, message: '' })
    const [resultSuccessApiCallAkt, setResultSuccessApiCallAkt] = useState<IResultCall>({ isSuccess: false, message: '' })

    const onSubmit = (formData: IDetailISVSColumn[]) => {
        setEditRow(false)
        setLoadingAddData(true)
        const convertData = Object.values(formData)
        if (dataRights?.inEvaluation) {
            const fetchData: NewNoteUi = {
                values: convertData?.map((item: IDetailISVSColumn) => {
                    return { name: item.id, value: item.evaluation }
                }),
                state: {
                    values: convertData?.map((item: IDetailISVSColumn) => {
                        return { name: item.id, value: item.isApproved }
                    }),
                },
            }
            saveHook(entityId, uuid ?? '', 'PROJECT', fetchData)
                .then(() => {
                    refetch()
                    setResultSuccessApiCall({ isSuccess: true, message: t('evaluation.saveSuccess') })
                })
                .catch(() => {
                    setErrorAddData(false)
                })
                .finally(() => {
                    setLoadingAddData(false)
                })
        } else {
            const fetchData: NewNoteUi = {
                values: convertData?.map((item: IDetailISVSColumn) => {
                    return { name: item.id, value: item.response }
                }),
            }

            saveResponseHook(entityId, uuid ?? '', 'PROJECT', fetchData)
                .then(() => {
                    refetch()
                    setResultSuccessApiCall({ isSuccess: true, message: t('evaluation.saveSuccess') })
                })
                .catch(() => {
                    setErrorAddData(false)
                })
                .finally(() => {
                    setLoadingAddData(false)
                })
        }
    }

    const onSubmitAct = (formData: IDetailISVSColumn[]) => {
        setEditActivitiesRow(false)
        setLoadingAddDataAct(true)
        const convertData = Object.values(formData)
        if (dataRights?.inEvaluation) {
            const fetchData: SubItemsNewNotesUi = {
                itemNotes: convertData?.map((item: IDetailISVSColumn) => {
                    return {
                        itemId: item.id,
                        values: [{ name: ACTIVITY_ATTRIBUTE_NAME, value: item.evaluation }],
                        state: {
                            values: convertData?.map((itemState: IDetailISVSColumn) => {
                                return { name: ACTIVITY_ATTRIBUTE_NAME, value: itemState.isApproved } as StateValueUi
                            }),
                        },
                    } as SubItemNewNoteUi
                }),
            }
            saveActivityHook(entityId, uuid ?? '', 'PROJECT', 'ACTIVITY', fetchData)
                .then(() => {
                    refetchAct()
                    setResultSuccessApiCallAkt({ isSuccess: true, message: t('evaluation.saveSuccess') })
                })
                .catch(() => {
                    setErrorAddDataAct(true)
                })
                .finally(() => {
                    setLoadingAddDataAct(false)
                })
        } else {
            const fetchData: SubItemsNotesUi = {
                itemNotes: convertData?.map((item: IDetailISVSColumn) => {
                    return { itemId: item.id, values: [{ name: ACTIVITY_ATTRIBUTE_NAME, value: item.response }] } as SubItemNoteUi
                }),
            }

            saveResponseActivityHook(entityId, uuid ?? '', 'PROJECT', 'ACTIVITY', fetchData)
                .then(() => {
                    refetchAct()
                    setResultSuccessApiCallAkt({ isSuccess: true, message: t('evaluation.saveSuccess') })
                })
                .catch(() => {
                    setErrorAddDataAct(true)
                })
                .finally(() => {
                    setLoadingAddDataAct(false)
                })
        }
    }

    const handleCheckboxChange = useCallback(
        (row: Row<IDetailISVSColumn>) => {
            if (row.original.id) {
                if (rowSelection.includes(row.original.id)) {
                    setRowSelection((prev) => prev.filter((id) => id !== row.original.id))
                    setValue(`${row?.index}.isApproved`, false)
                } else {
                    setRowSelection((prev) => [...prev, row.original.id || ''])
                    setValue(`${row?.index}.isApproved`, true)
                }
            }
        },
        [rowSelection, setValue],
    )

    const handleCheckboxActChange = useCallback(
        (row: Row<IDetailISVSColumn>) => {
            if (row.original.id) {
                if (rowSelectionAct.includes(row.original.id)) {
                    setRowSelectionAct((prev) => prev.filter((id) => id !== row.original.id))
                    setValueAct(`${row?.index}.isApproved`, false)
                } else {
                    setRowSelectionAct((prev) => [...prev, row.original.id || ''])
                    setValueAct(`${row?.index}.isApproved`, true)
                }
            }
        },
        [rowSelectionAct, setValueAct],
    )

    const handleAllCheckboxChange = () => {
        if (!evalData) return

        const values = Object.values(getValues())

        const checkedAll = values?.every((i) => i.isApproved === true)

        if (checkedAll) {
            let count = 0
            values.map(() => {
                setValue(`${count++}.isApproved`, false)
            })
            setRowSelection([])
            return
        }
        let count = 0
        values.map(() => {
            setValue(`${count++}.isApproved`, true)
        })
        const customRows = values?.map((row) => row.id || '') || []
        setRowSelection(customRows)
    }

    const handleAllCheckboxActChange = () => {
        if (!activityAttributes) return

        const values = Object.values(getValuesAct())

        const checkedAll = values?.every((i) => i.isApproved === true)

        if (checkedAll) {
            let count = 0
            values.map(() => {
                setValueAct(`${count++}.isApproved`, false)
            })
            setRowSelectionAct([])
            return
        }
        let count = 0
        values.map(() => {
            setValueAct(`${count++}.isApproved`, true)
        })
        const customRows = values?.map((row) => row.id || '') || []
        setRowSelectionAct(customRows)
    }

    useEffect(() => {
        const rowsAct = activityAttributes?.items?.filter((i) => i.state?.values?.filter((e) => e.value)).map((a) => a.itemId || '') ?? []
        const rows = evalData?.state?.values?.filter((i) => i.value).map((i) => i.name || '') ?? []
        setRowSelectionAct(rowsAct)
        setRowSelection(rows)
    }, [evalData, activityAttributes])

    const columnsDetail: Array<ColumnDef<IDetailISVSColumn>> = [
        {
            accessorFn: (row) => row?.name,
            header: t('evaluation.projectTable.activitiesName'),
            id: 'name',
            cell: ({ row }) => {
                return (
                    <div>
                        {row?.original?.name}
                        <input readOnly hidden {...registerActi(`${row?.index}.id`)} key={row?.original?.id} value={row?.original?.id} />
                    </div>
                )
            },
        },
        {
            accessorFn: (row) => row?.response,
            header: t('evaluation.projectTable.createdBy'),
            id: 'createdBy',
            cell: (ctx) => {
                return !isEditActivitiesRow || dataRights?.inEvaluation ? (
                    <>{ctx.row?.original?.response}</>
                ) : (
                    <TextArea rows={3} defaultValue={ctx.row?.original?.response} {...registerActi(`${ctx.row?.index}.response`)} />
                )
            },
        },
        {
            accessorFn: (row) => row?.isApproved,
            id: 'isApproved',
            header: () => {
                const checkedAll = Object.values(getValuesAct())?.every((row) => rowSelectionAct.includes(row.id || ''))

                return (
                    <div className="govuk-checkboxes govuk-checkboxes--small">
                        <CheckBox
                            label=""
                            disabled={!isEditActivitiesRow || !dataRights?.inEvaluation}
                            name="checkbox"
                            id="checkbox-all"
                            value="checkbox-all"
                            onChange={() => handleAllCheckboxActChange()}
                            checked={checkedAll}
                            title={t('table.selectAllItems')}
                        />
                    </div>
                )
            },
            cell: ({ row }) => {
                return (
                    <div className="govuk-checkboxes govuk-checkboxes--small">
                        <CheckBox
                            {...registerActi(`${row?.index}.isApproved`)}
                            label=""
                            disabled={!isEditActivitiesRow || !dataRights?.inEvaluation}
                            id={`${row?.index}.isApproved`}
                            onChange={() => handleCheckboxActChange(row)}
                            checked={rowSelectionAct.includes(row.original.id ?? '')}
                        />
                    </div>
                )
            },
        },
        {
            accessorFn: (row) => row?.evaluation,
            header: () => {
                return (
                    <div className={styles.customHEader}>
                        {t('evaluation.detailTable.evaluation')}
                        {!isGlobalAllowed &&
                            (!isEditActivitiesRow ? (
                                <Button
                                    className={styles.headerBtn}
                                    label={t('evaluation.changeBtn')}
                                    onClick={() => setEditActivitiesRow(!isEditActivitiesRow)}
                                />
                            ) : (
                                <ButtonGroupRow>
                                    <Button label={t('evaluation.saveBtn')} className={styles.headerBtn} type="submit" />
                                    <Button
                                        variant="secondary"
                                        className={styles.headerBtn}
                                        label={t('evaluation.cancelBtn')}
                                        onClick={() => setEditActivitiesRow(false)}
                                    />
                                </ButtonGroupRow>
                            ))}
                    </div>
                )
            },
            id: 'evaluation',
            cell: ({ row }) => {
                return isEditActivitiesRow && dataRights?.inEvaluation ? (
                    <TextArea
                        {...registerActi(`${row?.index}.evaluation`)}
                        key={row?.original?.id}
                        rows={3}
                        defaultValue={row?.original?.evaluation}
                    />
                ) : (
                    <>{row?.original?.evaluation}</>
                )
            },
        },
    ]

    const columnsAttDetail: Array<ColumnDef<IDetailISVSColumn>> = [
        {
            accessorFn: (row) => row?.name,
            header: '',
            id: 'name',
            cell: ({ row }) => {
                return (
                    <InfoIconWithText key={row?.original?.name} tooltip={row?.original?.tooltip} label={row?.original?.name}>
                        {row?.original?.name}
                        <input readOnly hidden {...register(`${row?.index}.id`)} key={row?.original?.id} value={row?.original?.id} />
                    </InfoIconWithText>
                )
            },
        },
        {
            accessorFn: (row) => row?.lastEvaluation,
            header: t('evaluation.detailTable.lastEvaluation'),
            id: 'lastEvaluation',
            cell: (ctx) => ctx.row?.original?.lastEvaluation,
        },
        {
            accessorFn: (row) => row?.response,
            header: t('evaluation.detailTable.createdBy'),
            id: 'createdBy',
            cell: (ctx) => {
                return !isEditRow || dataRights?.inEvaluation ? (
                    ctx.row?.original?.response
                ) : (
                    <TextArea rows={3} defaultValue={ctx.row?.original?.response} {...register(`${ctx.row?.index}.response`)} />
                )
            },
        },
        {
            accessorFn: (row) => row?.isApproved,
            id: 'isApproved',
            header: () => {
                const checkedAll = Object.values(getValues())?.every((row) => rowSelection.includes(row.id || ''))

                return (
                    <div className="govuk-checkboxes govuk-checkboxes--small">
                        <CheckBox
                            label=""
                            disabled={!isEditRow || !dataRights?.inEvaluation}
                            name="checkbox"
                            id="checkbox-all"
                            value="checkbox-all"
                            onChange={() => handleAllCheckboxChange()}
                            checked={checkedAll}
                            title={t('table.selectAllItems')}
                        />
                    </div>
                )
            },
            cell: ({ row }) => {
                return (
                    <div className="govuk-checkboxes govuk-checkboxes--small">
                        <CheckBox
                            {...register(`${row?.index}.isApproved`)}
                            label=""
                            disabled={!isEditRow || !dataRights?.inEvaluation}
                            id={`${row?.index}.isApproved`}
                            onChange={() => handleCheckboxChange(row)}
                            checked={rowSelection.includes(row.original.id)}
                        />
                    </div>
                )
            },
        },
        {
            accessorFn: (row) => row?.evaluation,
            header: () => {
                return (
                    <div className={styles.customHEader}>
                        {t('evaluation.detailTable.evaluation')}
                        {!isGlobalAllowed &&
                            (!isEditRow ? (
                                <Button className={styles.headerBtn} label={t('evaluation.changeBtn')} onClick={() => setEditRow(!isEditRow)} />
                            ) : (
                                <ButtonGroupRow>
                                    <Button label={t('evaluation.saveBtn')} className={styles.headerBtn} type="submit" />
                                    <Button
                                        variant="secondary"
                                        className={styles.headerBtn}
                                        label={t('evaluation.cancelBtn')}
                                        onClick={() => setEditRow(false)}
                                    />
                                </ButtonGroupRow>
                            ))}
                    </div>
                )
            },
            id: 'evaluation',
            cell: ({ row }) => {
                return isEditRow && dataRights?.inEvaluation ? (
                    <TextArea {...register(`${row?.index}.evaluation`)} rows={3} defaultValue={row?.original?.evaluation} />
                ) : (
                    <>{row?.original?.evaluation}</>
                )
            },
        },
    ]

    const mappedData = (apiData?: NoteItemUi): Array<IDetailISVSColumn> => {
        return (
            apiData?.state?.values?.map((item) => {
                return {
                    id: item.name,
                    name: attributes?.attributes?.find((att) => att.technicalName === item.name)?.name ?? '',
                    tooltip: attributes?.attributes?.find((att) => att.technicalName === item.name)?.description ?? '',
                    lastEvaluation: apiData?.evaluations?.[apiData?.evaluations?.length - 1]?.values?.find((e) => e.name === item.name)?.value ?? '',
                    createdBy: apiData?.evaluations?.[apiData?.evaluations?.length - 1]?.noteVersionUi?.createdBy ?? '',
                    isApproved: apiData?.state?.values?.find((e) => e.name === item.name)?.value,
                    evaluation: apiData?.evaluations?.[apiData?.evaluations?.length - 1]?.values?.find((e) => e.name === item.name)?.value ?? '',
                    response: apiData?.responses?.[apiData?.responses?.length - 1]?.values?.find((e) => e.name === item.name)?.value ?? '',
                } as IDetailISVSColumn
            }) ?? []
        )
    }

    const mappedDataAct = (): Array<IDetailISVSColumn> => {
        const apiActivitiesDataAtt = activityAttributes?.items
        return (
            activities?.map((activity) => {
                return {
                    id: activity.uuid,
                    name: activity.name,
                    lastEvaluation: apiActivitiesDataAtt?.find((att) => att.itemId === activity.uuid)?.evaluations,
                    isApproved:
                        apiActivitiesDataAtt
                            ?.find((att) => att.itemId === activity.uuid)
                            ?.state?.values?.find((e) => e.name === ACTIVITY_ATTRIBUTE_NAME)?.value ?? false,
                    evaluation: apiActivitiesDataAtt
                        ?.find((att) => att.itemId === activity.uuid)
                        ?.evaluations?.map((e) => e.values?.find((v) => v.name === ACTIVITY_ATTRIBUTE_NAME)?.value ?? ''),
                    response: apiActivitiesDataAtt
                        ?.find((att) => att.itemId === activity.uuid)
                        ?.responses?.map((e) => e.values?.find((v) => v.name === ACTIVITY_ATTRIBUTE_NAME)?.value ?? ''),
                } as IDetailISVSColumn
            }) ?? []
        )
    }

    useEffect(() => {
        tableHandle.current?.refreshColumns()
    }, [isEditRow, rowSelection])

    return (
        <QueryFeedback
            loading={isLoading || isLoadingAddData || isLoadingAct || isFetching || isFetchingAct}
            error={isError || isErrorAddData}
            withChildren
        >
            <MutationFeedback
                success={resultSuccessApiCall.isSuccess}
                successMessage={resultSuccessApiCall.message}
                onMessageClose={() => setResultSuccessApiCall({ isSuccess: false, message: '' })}
            />
            <div className={styles.expandableRowContent}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Table columns={columnsAttDetail} data={mappedData(evalData)} handleRef={tableHandle} />
                </form>
                <TextHeading size="M">{t('navMenu.lists.aktivita')}</TextHeading>
                <MutationFeedback
                    success={resultSuccessApiCallAkt.isSuccess}
                    successMessage={resultSuccessApiCallAkt.message}
                    onMessageClose={() => setResultSuccessApiCallAkt({ isSuccess: false, message: '' })}
                />
                <QueryFeedback loading={isLoadingAddDataAct} error={isErrorAct || isErrorAddDataAct} withChildren />
                <form onSubmit={handleSubmitAct(onSubmitAct)} noValidate>
                    {activities?.length ? <Table columns={columnsDetail} data={mappedDataAct()} /> : <>{t('evaluation.noDataFind')}</>}
                </form>
            </div>
        </QueryFeedback>
    )
}
