import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@isdd/idsk-ui-kit/button/Button'

import styles from '@isdd/metais-common/components/actions-over-table/actionsOverTable.module.scss'
export interface ICreateEntityButtonProps {
    onClick: () => void
    label?: string
    ciTypeName?: string
    disabled?: boolean
}

export const CreateEntityButton: React.FC<ICreateEntityButtonProps> = ({ onClick, label, ciTypeName = '', disabled }) => {
    const { t } = useTranslation()
    return (
        <Button
            className={(styles.mobileOrder1, 'marginBottom0')}
            onClick={onClick}
            label={
                <>
                    <span aria-hidden="true">+ </span>
                    {label ?? t('actionOverTable.addItem', { ciTypeName })}
                </>
            }
            disabled={disabled}
        />
    )
}
