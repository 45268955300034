import { DateTime } from 'luxon'
import { TFunction } from 'i18next'
import { isValidElement } from 'react'
import jwtDecode, { JwtPayload } from 'jwt-decode'

import { Attribute } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { META_IS_ADMIN_TITLE, META_IS_TITLE } from '@isdd/metais-common/constants'
import { ConfigurationItemUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { CreatableOptionType } from '@isdd/metais-common/components/ci-lazy-select-creatable/CiLazySelectCreatable'
export const isObjectEmpty = (obj: unknown) => {
    if (obj != null && typeof obj == 'object') {
        return Object.keys(obj).length == 0
    }
    return false
}

export const cleanFileName = (fileName: string) =>
    fileName.replace(/[\u2000-\u2009\u200A-\u200D\u2060-\u2063\u180E\uFEFF\u202F\u205F\u3000]+/gu, '').replace(/[/\\?%*:|"<>]/g, '')

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const cleanObjectValues = (obj: any) => {
    for (const key in obj) {
        if (obj[key] && typeof obj[key] === 'object') {
            // Recursively call the function for nested objects
            cleanObjectValues(obj[key])

            // If the nested object becomes empty after removal, delete the key
            if (Object.keys(obj[key]).length === 0) {
                delete obj[key]
            }
        } else if (obj[key] === undefined || obj[key] === '') {
            // Delete the key if the value is undefined or an empty string
            delete obj[key]
        }
    }

    return obj
}

export const formatNumberWithSpaces = (value: string) => {
    const formatted = value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    return formatted
}

export const isDate = (value: unknown): value is Date => {
    return value instanceof Date && DateTime.fromJSDate(new Date(value)).isValid
}

export const isFalsyStringValue = (value: unknown): boolean => {
    return value === '0' || value === 'false' || value === 'FALSE'
}

export const getRoleUuidFromGid = (gid: string) => {
    return gid.substring(0, 36)
}

export const getOrgIdFromGid = (gid: string) => {
    return gid.substring(37)
}

export const removeNullPropertiesFromRecord = (obj: Record<string, unknown>) =>
    Object.fromEntries(
        Object.entries(obj).filter(([, value]) => {
            return !(value == null || value === '' || (Array.isArray(value) && value.length === 0) || isObjectEmpty(value))
        }),
    )

export const replaceDotForUnderscore = (string: string) => {
    return string.replaceAll('.', '_')
}

export const roundUpToTwo = (num: number) => {
    return Math.ceil(num * 100) / 100
}

export const decodeHtmlEntities = (encodedString: string): string => {
    // This technique leverages the browser's built-in HTML decoding capabilities.
    const textarea = document.createElement('textarea')
    textarea.innerHTML = encodedString
    return textarea.value
}

export const removeNonDigitCharacters = (value?: number | string) => {
    return value?.toString().replace(/[^\d]+/g, '') ?? 0
}

export const isOwnershipOnPoSide = (ownerGid: string, poUuid: string) => {
    if (ownerGid == null) {
        return false
    }
    if (ownerGid.indexOf(poUuid) != -1) {
        return true
    }
    return false
}

export const splitList = (firstList: string[], secondList: string[]) => {
    const notInList1: string[] = []
    const notInList2: string[] = []

    firstList.forEach((item) => {
        if (!secondList.includes(item)) {
            notInList2.push(item)
        }
    })

    secondList.forEach((item) => {
        if (!firstList.includes(item)) {
            notInList1.push(item)
        }
    })

    return { notInList1, notInList2 }
}

export const setDocumentTitle = (title: string, isAdmin = false) => {
    document.title = `${title} ${isAdmin ? META_IS_ADMIN_TITLE : META_IS_TITLE}`
}

export const findCommonStrings = (array1: string[], array2: string[]) => {
    const commonStrings: string[] = []
    array1.forEach((element) => {
        if (array2.includes(element)) {
            commonStrings.push(element)
        }
    })
    return commonStrings
}

export const sanitizeFileName = (fileName: string): string => {
    // Remove special characters and replace spaces with underscores
    const sanitizedFileName = fileName.replace(/[^\w\s.-]/g, '').replace(/\s+/g, '_')

    const maxLength = 100
    const truncatedFileName = sanitizedFileName.substring(0, maxLength)

    return truncatedFileName
}

export const isConfigurationItemUi = (data: unknown): data is ConfigurationItemUi => {
    return typeof data === 'object' && data !== null && 'attributes' in data
}

export const isCreatableOptionType = (data: unknown): data is CreatableOptionType => {
    return typeof data === 'object' && data !== null && 'label' in data
}

export const ensureSlashAtEnd = (str: string) => {
    if (str.endsWith('/')) {
        return str
    } else {
        return `${str}/`
    }
}
export const getHowToTranslate = (howToType: string, t: TFunction) => {
    const baseTranslate = 'breadcrumbs.wiki.'

    return t(`${baseTranslate}${howToType}`)
}
export const transformJsonToArray = (jsonData = {}) => Object.entries(jsonData).map(([key, value]) => ({ name: key, value }))

export type CustomPagination = {
    startOfList: number
    endOfList: number
    pageNumber: number
    pageSize: number
    dataLength: number
}

export const getPagination = (pageNumber: number, pageSize: number, dataLength: number): CustomPagination => {
    const startOfList = pageNumber * pageSize - pageSize
    const endOfList = pageNumber * pageSize

    return {
        startOfList,
        endOfList,
        pageNumber,
        pageSize,
        dataLength,
    }
}

export const hasArraysEqualValues = (arr1: string[], arr2: string[]): boolean => {
    const set1 = new Set(arr1)
    const set2 = new Set(arr2)

    if (set1.size !== set2.size) {
        return false
    }

    for (const item of set1) {
        if (!set2.has(item)) {
            return false
        }
    }

    return true
}

export const sortByName = <T extends { name?: string }>(arr: T[]): T[] => {
    return arr.sort((a, b) => {
        const nameA: string = a.name ?? ''
        const nameB: string = b.name ?? ''
        return nameA.localeCompare(nameB)
    })
}

export const reduceToObjectByUuid = <T extends { uuid?: string }>(arr: T[]): { [key: string]: T } => {
    const newObj: Record<string, T> = {}
    arr.forEach((i) => {
        if (i?.uuid) {
            newObj[i.uuid] = i
        }
    })
    return newObj
}

export const getLongerArray = <T>(arr1: T[], arr2: T[]): T[] => {
    if (arr1.length >= arr2.length) {
        return arr1
    } else {
        return arr2
    }
}

export const getPaginatedData = <T>(arr: T[], startOfList: number, endOfList: number): T[] => {
    return arr.slice(startOfList, endOfList)
}

export const sortByOrder = <T extends { order: number }>(arr: T[]): T[] => {
    return arr?.sort((a, b) => a.order - b.order)
}

export const getUseLightFontBasedOnContrast = (hex: string) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    if (!result) return false

    const rgb = {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
    }

    return Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000) <= 128
}

export const scrollToTargetAdjusted = <T extends HTMLElement>(ref: React.RefObject<T>, offset: number) => {
    if (!ref.current) return
    const elementPosition = ref.current.getBoundingClientRect().top
    const offsetPosition = elementPosition + (window.pageYOffset || document.documentElement.scrollTop) - offset

    // setTimeout is used to not clash with `ScrollToTop` component and scroll after page navigation change
    setTimeout(() => {
        window.scrollTo({
            top: offsetPosition,
        })
    }, 100)
}

export const handleTooManyRequests = (message: string, onTooManyRequestsError: () => void, onOtherErrors: () => void) => {
    if ((JSON.parse(message) as { error: string; status: number }).status == 429) {
        onTooManyRequestsError()
    } else {
        onOtherErrors()
    }
}

export const handleEntityTooLarge = (message: string, onEntityTooLargeError: () => void, onOtherErrors: () => void) => {
    if ((JSON.parse(message) as { error: string; status: number }).status == 413) {
        onEntityTooLargeError()
    } else {
        onOtherErrors()
    }
}

export const sanitizeErrorTranslateString = (val: string): string => val.replace(/[^a-zA-Z0-9]/g, '')

export const getNodeText = (node: React.ReactNode): string => {
    // If the node is a string or number, return it as a string
    if (typeof node === 'string' || typeof node === 'number') {
        return node.toString()
    }

    // If the node is an array, recursively call reactNodeToText on each child and join the results
    if (Array.isArray(node)) {
        return node.map(getNodeText).join('')
    }

    // If the node is a valid React element, recursively call reactNodeToText on its children
    if (isValidElement(node)) {
        const element = node as React.ReactElement
        return getNodeText(element.props.children)
    }

    // For all other cases (e.g., null, undefined, booleans), return an empty string
    return ''
}

export function isTokenExpired(token: string) {
    const { exp } = jwtDecode<JwtPayload>(token)
    const expired = Date.now() >= (exp ?? 0) * 1000
    return expired
}

export const transformArrayToQueryString = (names: string[], values: string[]): string => {
    return (
        '?' +
        names
            .map((item, index) => {
                const trimmedName = item.trim()
                const trimmedValue = values?.[index]?.trim()
                if (trimmedName && trimmedValue) {
                    return `${trimmedName}=${trimmedValue}`
                }
                return ''
            })
            .filter((item) => item)
            .join('&')
    )
}

export interface DateRangeObject {
    name: string
    startDate?: string
    endDate?: string
}

export const isCurrentDateBetween = (start: string, end?: string): boolean => {
    const startDate = new Date(start)
    const endDate = end ? new Date(end) : new Date('9999-12-31T23:59')
    const currentDate = new Date()
    return currentDate >= startDate && currentDate <= endDate
}

export const findCurrentDateObjects = (objects: DateRangeObject[]): DateRangeObject | undefined => {
    const currentInRangeObjects = objects.filter((obj) => {
        if (obj.startDate) {
            return isCurrentDateBetween(obj.startDate, obj.endDate)
        }
        return false
    })

    if (currentInRangeObjects.length === 0) {
        return undefined
    }

    const currentDate = new Date()
    let closestObject = currentInRangeObjects[0]
    let closestStartDateDiff = Math.abs(new Date(closestObject.startDate ?? '').getTime() - currentDate.getTime())

    for (let i = 1; i < currentInRangeObjects.length; i++) {
        const obj = currentInRangeObjects[i]
        const startDateDiff = Math.abs(new Date(obj.startDate ?? '').getTime() - currentDate.getTime())

        if (startDateDiff < closestStartDateDiff) {
            closestStartDateDiff = startDateDiff
            closestObject = obj
        }
    }

    return closestObject
}

export const filterAndSortAttributesByTechnicalName = (items: Attribute[], technicalNames: string[]) => {
    const filteredItems = items.filter((item) => technicalNames.includes(item.technicalName ?? ''))

    const sortedItems = filteredItems.sort((a, b) => {
        return technicalNames.indexOf(a.technicalName ?? '') - technicalNames.indexOf(b.technicalName ?? '')
    })

    return sortedItems
}
