import {
    BreadCrumbs,
    Filter,
    GridCol,
    GridRow,
    HomeIcon,
    PaginatorWrapper,
    RadioButton,
    RadioGroup,
    SimpleSelect,
    Table,
    TextHeading,
} from '@isdd/idsk-ui-kit/index'
import { ColumnSort, Pagination } from '@isdd/idsk-ui-kit/types'
import { Task, TaskList } from '@isdd/metais-common/api/generated/tasks-swagger'
import { IFilterParams } from '@isdd/metais-common/hooks/useFilter'
import { MutationFeedback, QueryFeedback } from '@isdd/metais-common/index'
import { EnumType } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { ColumnDef } from '@tanstack/react-table'
import React, { SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FlexColumnReverseWrapper } from '@isdd/metais-common/components/flex-column-reverse-wrapper/FlexColumnReverseWrapper'
import { RouteNames } from '@isdd/metais-common/navigation/routeNames'
import { DateInput } from '@isdd/idsk-ui-kit/date-input/DateInput'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { useScroll } from '@isdd/metais-common/hooks/useScroll'

import { MainContentWrapper } from '@/components/MainContentWrapper'

enum TaskFilterState {
    ACTIVE = 'ACTIVE',
    ALL = 'ALL',
    DONE = 'DONE',
}

export interface TasksFilter extends IFilterParams {
    state: string
    appId?: string
    createdFrom?: string
    createdTo?: string
}

interface ITasksListView {
    defaultFilterValues: TasksFilter
    filterParams: TasksFilter
    appIds: EnumType | undefined
    tasksData: TaskList | undefined
    isLoading: boolean
    isError: boolean
    isIdle: boolean
    columns: ColumnDef<Task>[]
    pagination: { pageNumber: number; pageSize: number }
    setPagination: React.Dispatch<SetStateAction<Pagination>>
    sort: ColumnSort[]
    setSort: React.Dispatch<SetStateAction<ColumnSort[]>>
    isWrapped?: boolean
    hideTypeFilter?: boolean
}

export const TasksListView: React.FC<ITasksListView> = ({
    defaultFilterValues,
    filterParams,
    appIds,
    tasksData,
    isLoading,
    isError,
    isIdle,
    columns,
    pagination,
    setPagination,
    sort,
    setSort,
    isWrapped,
    hideTypeFilter,
}) => {
    const { t } = useTranslation()

    const getTotalNumberOfTasks = (): number => {
        switch (filterParams.state) {
            case TaskFilterState.ALL:
                return tasksData?.tasksCount ?? 0
            case TaskFilterState.DONE:
                return tasksData?.tasksCountDone ?? 0
            default:
                return (tasksData?.tasksCountCreated ?? 0) + (tasksData?.tasksCountInProgress ?? 0)
        }
    }

    const { isActionSuccess } = useActionSuccess()
    const { wrapperRef, scrollToMutationFeedback } = useScroll()
    useEffect(() => {
        if (isActionSuccess.value) {
            scrollToMutationFeedback()
        }
    }, [isActionSuccess, scrollToMutationFeedback])

    return (
        <>
            {!isWrapped && (
                <BreadCrumbs
                    withWidthContainer
                    links={[
                        { label: t('tasks.home'), href: '/', icon: HomeIcon },
                        { label: t('tasks.tasks'), href: RouteNames.TASKS },
                    ]}
                />
            )}
            <MainContentWrapper noSideMenu={isWrapped}>
                <FlexColumnReverseWrapper>
                    {!isWrapped && <TextHeading size="L">{t('tasks.tasks')}</TextHeading>}
                    <QueryFeedback loading={false} error={isError} />
                </FlexColumnReverseWrapper>
                <MutationFeedback success={isActionSuccess.value} />
                <div ref={wrapperRef} />
                <Filter<TasksFilter>
                    defaultFilterValues={defaultFilterValues}
                    form={({ register, setValue, control, isOpen }) => {
                        return (
                            <div>
                                {!hideTypeFilter && (
                                    <SimpleSelect
                                        options={[
                                            { label: t('tasks.all'), value: 'ALL' },
                                            ...(appIds?.enumItems?.map((enumItem) => {
                                                return { value: `${enumItem.value}`, label: t(`tasks.${enumItem.code}`) }
                                            }) ?? [{ value: '', label: '' }]),
                                        ]}
                                        label={t('tasks.selectType')}
                                        id="appId"
                                        name="appId"
                                        setValue={setValue}
                                        tabIndex={isOpen ? undefined : -1}
                                    />
                                )}
                                <GridRow>
                                    <GridCol setWidth="one-half">
                                        <DateInput
                                            label={t('tasks.createdFrom')}
                                            id="createdFrom"
                                            {...register('createdFrom')}
                                            control={control}
                                            setValue={setValue}
                                        />
                                    </GridCol>
                                    <GridCol setWidth="one-half">
                                        <DateInput
                                            label={t('tasks.createdTo')}
                                            id="createdTo"
                                            {...register('createdTo')}
                                            control={control}
                                            setValue={setValue}
                                        />
                                    </GridCol>
                                </GridRow>
                                <RadioGroup inline legend={`${t('tasks.state')}:`}>
                                    <RadioButton
                                        {...register('state')}
                                        value="ALL"
                                        label={`${t('tasks.all')} (${tasksData?.tasksCount ?? 0})`}
                                        id="allRadioBtn"
                                        name="allRadioBtn"
                                        onChange={(val) => setValue('state', val.target.value)}
                                    />
                                    <RadioButton
                                        {...register('state')}
                                        value="ACTIVE"
                                        label={`${t('tasks.active')} (${
                                            (tasksData?.tasksCountCreated ?? 0) + (tasksData?.tasksCountInProgress ?? 0)
                                        })`}
                                        id="activeRadioBtn"
                                        name="activeRadioBtn"
                                        onChange={(val) => {
                                            setValue('state', val.target.value)
                                        }}
                                    />
                                    <RadioButton
                                        {...register('state')}
                                        value="DONE"
                                        label={`${t('tasks.done')} (${tasksData?.tasksCountDone ?? 0})`}
                                        id="doneRadioBtn"
                                        name="doneRadioBtn"
                                        onChange={(val) => setValue('state', val.target.value)}
                                    />
                                </RadioGroup>
                            </div>
                        )
                    }}
                />

                <QueryFeedback loading={isLoading || isIdle} error={false} withChildren>
                    <Table<Task>
                        columns={columns}
                        isLoading={isLoading}
                        error={isError}
                        data={tasksData?.tasks}
                        sort={sort}
                        onSortingChange={(newSort) => {
                            setSort(newSort)
                        }}
                    />
                </QueryFeedback>
                <PaginatorWrapper
                    pageNumber={pagination.pageNumber}
                    pageSize={pagination.pageSize}
                    dataLength={getTotalNumberOfTasks()}
                    handlePageChange={(filter) => {
                        setPagination({ ...pagination, ...filter, dataLength: getTotalNumberOfTasks() })
                    }}
                />
            </MainContentWrapper>
        </>
    )
}
