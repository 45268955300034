import { ENTITY_PROJECT, PO_PO } from '@isdd/metais-common/constants'
import { useAttributesHook } from '@isdd/metais-common/hooks/useAttributes.hook'
import { useCiHook } from '@isdd/metais-common/hooks/useCi.hook'
import { useGetProjectPhaseAndStatus } from '@isdd/metais-common/hooks/useGetProjectPhaseAndStatus'
import { useTranslation } from 'react-i18next'
import { getOwnerLabel } from '@isdd/metais-common/componentHelpers/ci/getCiDefaultMetaAttributes'
import { InformationGridRow } from '@isdd/metais-common/components/info-grid-row/InformationGridRow'
import { ATTRIBUTE_NAME } from '@isdd/metais-common/index'
import { Link } from 'react-router-dom'

import { ObjektEvidencieInformationAccordion } from '@/components/entities/accordion/ObjektEvidencieInformationAccordion'
import { updateAttributeConstraints } from '@/componentHelpers/ci/updateAttributeConstraints'
import { useGetEntityParamsFromUrl } from '@/componentHelpers/ci'

const ObjektEvidencieInformation = () => {
    const { entityId, entityName } = useGetEntityParamsFromUrl()

    const { t } = useTranslation()
    const { ciItemData, gestorData, isLoading: isCiItemLoading, isError: isCiItemError } = useCiHook(entityId)
    const { constraintsData, ciTypeData, unitsData, isLoading: isAttLoading, isError: isAttError } = useAttributesHook(entityName)
    const { stavProjektu, stavProjektuAgile, fazaProjektu, fazaProjektuAgile } = useGetProjectPhaseAndStatus()

    const mergedConstraints = [...constraintsData, stavProjektu, stavProjektuAgile, fazaProjektu, fazaProjektuAgile]

    if (entityName === ENTITY_PROJECT) {
        updateAttributeConstraints(ciTypeData, ciItemData)
    }

    return (
        <ObjektEvidencieInformationAccordion
            data={{
                ciItemData,
                gestorData,
                constraintsData: mergedConstraints,
                ciTypeData,
                unitsData,
            }}
            isError={[isCiItemError, isAttError].some((item) => item)}
            isLoading={[isCiItemLoading, isAttLoading].some((item) => item)}
            additionalBasicInformation={{
                bottom: (
                    <InformationGridRow
                        label={getOwnerLabel(t, entityName)}
                        tooltip={getOwnerLabel(t, entityName)}
                        value={
                            <Link to={`/ci/${PO_PO}/${gestorData?.[0]?.configurationItemUi?.uuid}`} target="_blank">
                                {gestorData?.[0]?.configurationItemUi?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov]}
                            </Link>
                        }
                    />
                ),
            }}
        />
    )
}

export default ObjektEvidencieInformation
