import { AbilityBuilder, createMongoAbility } from '@casl/ability'
import { intersection, reduce, uniq } from 'lodash'

import { Actions } from './useUserAbility'

import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { useListRelatedCiTypes } from '@isdd/metais-common/api/generated/types-repo-swagger'

export const useRelationForCiPermissions = (entityName: string) => {
    const {
        state: { user },
    } = useAuth()
    const { data } = useListRelatedCiTypes(entityName)

    const { can, build } = new AbilityBuilder(createMongoAbility)
    const myRoles = user?.roles ?? []
    const allRelationTypes = [...(data?.cisAsSources ?? []), ...(data?.cisAsTargets ?? [])]
    const allCIs = uniq(allRelationTypes.map((r) => r.ciTypeTechnicalName))

    reduce(
        allCIs,
        (result, curr) => {
            const relationRoles = uniq(allRelationTypes.filter((rel) => rel.ciTypeTechnicalName === curr).flatMap((r) => r.relationshipRoleList))
            const ciRoles = uniq(allRelationTypes.filter((rel) => rel.ciTypeTechnicalName === curr).flatMap((r) => r.relationshipRoleList))
            if (intersection(relationRoles, myRoles).length > 0) can(Actions.CREATE, `ci.create.newRelation.${curr ?? ''}`)
            if (intersection(ciRoles, myRoles).length > 0 && intersection(relationRoles, myRoles).length > 0)
                can(Actions.CREATE, `ci.create.newRelationAndCi.${curr ?? ''}`)
            return result
        },
        {},
    )

    return build()
}

export const useRelationTypePermissions = (entityName: string, relationType: string) => {
    const {
        state: { user },
    } = useAuth()
    const { data } = useListRelatedCiTypes(entityName)

    const { can, build } = new AbilityBuilder(createMongoAbility)
    const myRoles = user?.roles ?? []
    const allRelationTypes = [...(data?.cisAsSources ?? []), ...(data?.cisAsTargets ?? [])]

    const relation = allRelationTypes.find((rel) => rel.relationshipTypeTechnicalName === relationType)
    if (relation && intersection(relation.relationshipRoleList, myRoles)) can(Actions.CREATE, `ci.create.newRelationType.${relationType}`)

    return build()
}
