import { AccordionContainer } from '@isdd/idsk-ui-kit/accordion/Accordion'
import { SafeHtmlComponent } from '@isdd/idsk-ui-kit/save-html-component/SafeHtmlComponent'
import { ConfigurationItemUi, RoleParticipantUI } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { EnumType } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { CiType } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { setLangForAttribute } from '@isdd/metais-common/componentHelpers/englishAttributeLang'
import { DefinitionList } from '@isdd/metais-common/components/definition-list/DefinitionList'
import { DESCRIPTION, HTML_TYPE } from '@isdd/metais-common/constants'
import { QueryFeedback, pairEnumsToEnumValues } from '@isdd/metais-common/index'
import { Languages } from '@isdd/metais-common/localization/languages'
import { InformationGridRow } from '@isdd/metais-common/components/info-grid-row/InformationGridRow'
import { useGetCiTypeConstraintsData } from '@isdd/metais-common/hooks/useGetCiTypeConstraintsData'
import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

interface CiInformationData {
    data: {
        ciItemData: ConfigurationItemUi | undefined
        ciTypeData: CiType | undefined
        constraintsData: (EnumType | undefined)[]
        unitsData?: EnumType | undefined
        gestorData: RoleParticipantUI[] | undefined
    }
    isError: boolean
    isLoading: boolean
    additionalBasicInformation?: {
        top?: React.ReactNode
        bottom?: React.ReactNode
    }
    additionalProfileInformation?: [
        {
            technicalName: string
            node: ReactNode
        },
    ]
    withoutDescription?: boolean
}

export const CiInformationAccordion: React.FC<CiInformationData> = ({
    data: { ciItemData, ciTypeData, constraintsData, unitsData },
    isLoading,
    isError,
    additionalBasicInformation,
    additionalProfileInformation,
    withoutDescription = false,
}) => {
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const {
        isLoading: isCiConstraintLoading,
        isError: isCiConstraintError,
        uuidsToMatchedCiItemsMap,
    } = useGetCiTypeConstraintsData(ciTypeData, [ciItemData ?? {}])

    const currentEntityCiTypeConstraintsData = uuidsToMatchedCiItemsMap[ciItemData?.uuid ?? '']

    const tabsFromApi =
        ciTypeData?.attributeProfiles
            ?.filter((p) => p.valid)
            .map((attributesProfile) => {
                return {
                    title:
                        (language === Languages.SLOVAK
                            ? attributesProfile.description || attributesProfile.name
                            : attributesProfile.engDescription || attributesProfile.name) || '',
                    content: (
                        <DefinitionList>
                            {attributesProfile?.attributes
                                ?.filter((atr) => atr.valid === true && atr.invisible !== true)
                                .sort((atr1, atr2) => (atr1.order || 0) - (atr2.order || 0))
                                .map((attribute) => {
                                    const formattedRowValue = pairEnumsToEnumValues({
                                        attribute,
                                        ciItemData,
                                        constraintsData,
                                        t,
                                        unitsData,
                                        matchedAttributeNamesToCiItem: currentEntityCiTypeConstraintsData,
                                        withDescription: !withoutDescription,
                                    })
                                    const isHTML = attribute.type === HTML_TYPE
                                    return (
                                        !attribute?.invisible && (
                                            <InformationGridRow
                                                key={attribute?.technicalName}
                                                label={(language === Languages.SLOVAK ? attribute.name : attribute.engName) ?? ''}
                                                value={isHTML ? <SafeHtmlComponent dirtyHtml={formattedRowValue as string} /> : formattedRowValue}
                                                tooltip={attribute?.description}
                                                lang={setLangForAttribute({ language, technicalName: attribute.technicalName ?? '' })}
                                            />
                                        )
                                    )
                                })}
                            {additionalProfileInformation?.find((p) => p.technicalName == attributesProfile.technicalName)?.node}
                        </DefinitionList>
                    ),
                }
            }) ?? []

    return (
        <QueryFeedback
            loading={isLoading || isCiConstraintLoading}
            error={isError || isCiConstraintError}
            showSupportEmail
            errorProps={{ errorMessage: isCiConstraintError ? t('ciInformationAccordion.error') : undefined }}
            withChildren
        >
            <AccordionContainer
                sections={[
                    {
                        title: t('ciInformationAccordion.basicInformation'),
                        onLoadOpen: true,
                        content: (
                            <DefinitionList>
                                {additionalBasicInformation?.top}
                                {ciTypeData?.attributes?.map((attribute) => {
                                    const rowValue = pairEnumsToEnumValues({
                                        attribute,
                                        ciItemData,
                                        constraintsData,
                                        t,
                                        unitsData,
                                        matchedAttributeNamesToCiItem: currentEntityCiTypeConstraintsData,
                                        withDescription: !withoutDescription,
                                    })
                                    const isHTML = attribute.type === HTML_TYPE || attribute.name == DESCRIPTION

                                    return (
                                        <InformationGridRow
                                            key={attribute?.technicalName}
                                            label={(language === Languages.SLOVAK ? attribute.name : attribute.engName) ?? ''}
                                            value={isHTML ? <SafeHtmlComponent dirtyHtml={(rowValue as string)?.replace(/\n/g, '<br>')} /> : rowValue}
                                            tooltip={attribute?.description}
                                            lang={setLangForAttribute({
                                                language,
                                                technicalName: attribute.technicalName ?? '',
                                                schemaAttribute: attribute,
                                            })}
                                        />
                                    )
                                })}
                                {additionalBasicInformation?.bottom}
                            </DefinitionList>
                        ),
                    },
                    ...tabsFromApi,
                ]}
            />
        </QueryFeedback>
    )
}
