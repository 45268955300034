import { SelectPublicAuthorityAndRole } from '@isdd/metais-common/common/SelectPublicAuthorityAndRole'
import React from 'react'
import { Button } from '@isdd/idsk-ui-kit/index'
import { HierarchyRightsUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { GidRoleData } from '@isdd/metais-common/api/generated/iam-swagger'
import { useTranslation } from 'react-i18next'

import { useGetEntityParamsFromUrl } from '@/componentHelpers/ci'
import { useCiCreateEntityHook } from '@/hooks/useCiCreateEntity.hook'

interface IKSSelectPO {
    onNext: () => void
    setSelectedPublicAuthority: React.Dispatch<React.SetStateAction<HierarchyRightsUi | null>>
    setSelectedRole: React.Dispatch<React.SetStateAction<GidRoleData | null>>
    omitDocumentTile?: boolean
}

export const SelectPOView: React.FC<IKSSelectPO> = ({ onNext, setSelectedPublicAuthority, setSelectedRole, omitDocumentTile }) => {
    const { entityName } = useGetEntityParamsFromUrl()
    const { t } = useTranslation()
    const {
        roleState,
        publicAuthorityState,
        data: {
            attributesData: { ciTypeData },
        },
    } = useCiCreateEntityHook({ entityName: entityName ?? '', omitDocumentTile })

    return (
        <>
            {publicAuthorityState && roleState && (
                <SelectPublicAuthorityAndRole
                    key={publicAuthorityState.selectedPublicAuthority?.poUUID}
                    selectedRole={roleState.selectedRole ?? {}}
                    onChangeAuthority={(val) => {
                        publicAuthorityState.setSelectedPublicAuthority(val)
                        setSelectedPublicAuthority(val)
                    }}
                    onChangeRole={(val) => {
                        roleState.setSelectedRole(val)
                        setSelectedRole(val)
                    }}
                    selectedOrg={publicAuthorityState.selectedPublicAuthority}
                    ciRoles={ciTypeData?.roleList ?? []}
                />
            )}
            <Button label={t('form.next')} onClick={onNext} />
        </>
    )
}
