import { LoadingIndicator, RadioGroup } from '@isdd/idsk-ui-kit'
import { Button } from '@isdd/idsk-ui-kit/button/Button'
import { BaseModal } from '@isdd/idsk-ui-kit/modal/BaseModal'
import { RadioButton } from '@isdd/idsk-ui-kit/radio-button/RadioButton'
import { TextHeading } from '@isdd/idsk-ui-kit/typography/TextHeading'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MutationFeedback } from '../mutation-feedback/MutationFeedback'
import { ModalButtons } from '../modal-buttons/ModalButtons'

import styles from './exportItemsOrRelations.module.scss'

import { ExportIcon } from '@isdd/metais-common/assets/images'
import { FileExtensionEnum } from '@isdd/metais-common/components/actions-over-table/actions-default/ExportButton'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'

interface IExportItemsOrRelationsProps {
    isOpen: boolean
    className?: string
    isLoading?: boolean
    close: () => void
    onExportStart: (exportValue: string, extension: FileExtensionEnum) => void
    isError?: boolean
    isSuccess?: boolean
    hideRadioBtns?: boolean
    customHeader?: string
    errorMessage?: string
}

export const ExportItemsOrRelations: React.FC<IExportItemsOrRelationsProps> = ({
    isOpen,
    isLoading,
    close,
    onExportStart,
    isError,
    hideRadioBtns,
    customHeader,
    errorMessage,
    isSuccess,
}) => {
    const { t } = useTranslation()
    const [exportValue, setExportValue] = useState('')
    const { isActionSuccess } = useActionSuccess()

    useEffect(() => {
        isOpen && setExportValue('items')
    }, [isOpen])

    const startExport = (extension: FileExtensionEnum) => {
        if (!exportValue && !hideRadioBtns) return
        onExportStart(exportValue, extension)
    }

    return (
        <BaseModal isOpen={isOpen} close={close}>
            {isLoading && <LoadingIndicator label={t('exportItemsOrRelations.loading')} />}
            <MutationFeedback
                success={isActionSuccess.value || isSuccess}
                error={isError}
                successMessage={t('exportItemsOrRelations.exportSuccess')}
                errorMessage={errorMessage}
            />
            <div className={styles.modalContainer}>
                <div className={styles.content}>
                    <div className={styles.icon}>
                        <img className={styles.iconWidth} src={ExportIcon} alt="" />
                    </div>
                    <TextHeading size={'L'} className={styles.heading}>
                        {customHeader ?? t('exportItemsOrRelations.header')}
                    </TextHeading>
                    {!hideRadioBtns && (
                        <RadioGroup inline small legend={customHeader ?? t('exportItemsOrRelations.header')} hideLegend>
                            <RadioButton
                                id={'id1'}
                                name={'RadioButton'}
                                value={'RadioButton'}
                                label={t('exportItemsOrRelations.items')}
                                onChange={() => setExportValue('items')}
                                defaultChecked
                            />
                            <RadioButton
                                id={'id2'}
                                name={'RadioButton'}
                                value={'RadioButton'}
                                label={t('exportItemsOrRelations.relations')}
                                onChange={() => setExportValue('relations')}
                            />
                        </RadioGroup>
                    )}
                    <div className={styles.buttonGroup}>
                        <Button
                            label={t('exportItemsOrRelations.buttonXML')}
                            variant="secondary"
                            className={styles.buttons}
                            onClick={() => startExport(FileExtensionEnum.XML)}
                        />
                        <Button
                            label={t('exportItemsOrRelations.buttonCSV')}
                            variant="secondary"
                            className={styles.buttons}
                            onClick={() => startExport(FileExtensionEnum.CSV)}
                        />
                        <Button
                            label={t('exportItemsOrRelations.buttonXLSX')}
                            variant="secondary"
                            className={styles.buttons}
                            onClick={() => startExport(FileExtensionEnum.EXCEL)}
                        />
                    </div>
                </div>
            </div>
            <ModalButtons onClose={close} />
        </BaseModal>
    )
}
