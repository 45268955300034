import { BaseModal } from '@isdd/idsk-ui-kit/modal/BaseModal'
import { TextHeading } from '@isdd/idsk-ui-kit/typography/TextHeading'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '@isdd/idsk-ui-kit/table/Table'
import { PaginatorWrapper } from '@isdd/idsk-ui-kit/paginatorWrapper/PaginatorWrapper'
import { CellContext, ColumnDef, RowSelectionState } from '@tanstack/react-table'
import {
    ActionsOverTable,
    BASE_PAGE_NUMBER,
    BASE_PAGE_SIZE,
    ModalButtons,
    QueryFeedback,
    formatDateTimeForDefaultValue,
} from '@isdd/metais-common/index'
import { ApiStandardRequestPreview, useGetAllStandardRequests } from '@isdd/metais-common/api/generated/standards-swagger'
import { Filter } from '@isdd/idsk-ui-kit/filter/Filter'
import { IFilterParams, useFilterParams } from '@isdd/metais-common/hooks/useFilter'
import { CheckBox, TextLinkExternal } from '@isdd/idsk-ui-kit/index'
import { CHECKBOX_CELL } from '@isdd/idsk-ui-kit/table/constants'
import { latiniseString } from '@isdd/metais-common/componentHelpers/filter/feFilters'
import { DEFAULT_PAGESIZE_OPTIONS } from '@isdd/metais-common/constants'
import { NavigationSubRoutes } from '@isdd/metais-common/navigation/routeNames'
import { IFilter, Pagination } from '@isdd/idsk-ui-kit/types'
import { useRowSelection } from '@isdd/metais-common/hooks/useRowSelection'

import styles from './meetingProposalsModal.module.scss'

interface IMeetingProposalsModalProps {
    isOpen: boolean
    className?: string
    close: () => void
    setSelectedProposals: React.Dispatch<string[]>
    selectedProposals: string[]
}

export interface FilterMeetingData extends IFilterParams {
    name: string
}
const defaultPagination: Pagination = {
    pageNumber: BASE_PAGE_NUMBER,
    pageSize: BASE_PAGE_SIZE,
    dataLength: 0,
}

export const MeetingProposalsModal: React.FC<IMeetingProposalsModalProps> = ({ isOpen, close, setSelectedProposals, selectedProposals }) => {
    const { t } = useTranslation()

    const [pagination, setPagination] = useState(defaultPagination)
    const rowSelection = useRowSelection()

    const myHandleFilterChange = (myFilter: IFilter) => {
        setPagination({
            ...pagination,
            pageSize: myFilter.pageSize ?? BASE_PAGE_SIZE,
            pageNumber: myFilter.pageNumber ?? defaultPagination.pageNumber,
        })
    }
    const defaultFilterValues = {
        sort: [],
        pageNumber: BASE_PAGE_NUMBER,
        pageSize: BASE_PAGE_SIZE,
        fullTextSearch: '',
        name: '',
    }
    const { filter, handleFilterChange } = useFilterParams<FilterMeetingData>(defaultFilterValues)
    const { data, isLoading, isError } = useGetAllStandardRequests()

    const filteredData = useMemo(() => {
        return (
            data?.standardRequests?.filter((request) => latiniseString(request.name ?? '').includes(latiniseString(filter.fullTextSearch ?? ''))) ||
            []
        )
    }, [data?.standardRequests, filter.fullTextSearch])

    const tableData = useMemo(() => {
        const startOfList = pagination.pageNumber * pagination.pageSize - pagination.pageSize
        const endOfList = pagination.pageNumber * pagination.pageSize
        return filteredData?.slice(startOfList, endOfList) || []
    }, [filteredData, pagination.pageNumber, pagination.pageSize])

    const handleClose = () => {
        handleFilterChange(defaultFilterValues)
        close()
    }
    useEffect(() => {
        const state = selectedProposals.reduce<RowSelectionState>((acc, id) => {
            acc[id] = true
            return acc
        }, {})
        rowSelection.setRowSelection(state)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProposals])

    const columns: Array<ColumnDef<ApiStandardRequestPreview>> = [
        {
            header: ({ table }) => (
                <div className="govuk-checkboxes govuk-checkboxes--small">
                    <CheckBox
                        label=""
                        name="checkbox"
                        id="checkbox-all"
                        value="checkbox-all"
                        checked={table.getIsAllRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                        aria-label={t('table.selectAllItems')}
                    />
                </div>
            ),
            id: CHECKBOX_CELL,
            cell: ({ row }) => (
                <div className="govuk-checkboxes govuk-checkboxes--small">
                    <CheckBox
                        label=""
                        name="checkbox"
                        id={`checkbox_${row.id}`}
                        value="true"
                        onChange={row.getToggleSelectedHandler()}
                        checked={row.getIsSelected()}
                        aria-label={t('table.selectItem', { itemName: row.original.name })}
                    />
                </div>
            ),
        },
        {
            header: t('meetings.proposals.name'),
            accessorFn: (row) => row?.name,
            enableSorting: true,
            id: 'name',
            meta: {
                getCellContext: (ctx: CellContext<ApiStandardRequestPreview, unknown>) => ctx?.getValue?.(),
            },
            cell: (ctx) => (
                <TextLinkExternal
                    href={NavigationSubRoutes.ZOZNAM_NAVRHOV + '/' + ctx.row.original.id}
                    newTab
                    title={ctx.getValue() as string}
                    textLink={ctx.getValue() as string}
                >
                    {ctx.getValue() as string}
                </TextLinkExternal>
            ),
        },
        {
            header: t('meetings.proposals.createdAt'),
            accessorFn: (row) => formatDateTimeForDefaultValue(row?.createdAt ?? '', 'dd.MM.yyyy, HH:mm'),
            enableSorting: true,
            id: 'createdAt',
            meta: {
                getCellContext: (ctx: CellContext<ApiStandardRequestPreview, unknown>) => ctx?.getValue?.(),
            },
            cell: (ctx) => <span>{ctx?.getValue?.() as string}</span>,
        },
        {
            header: t('meetings.proposals.requestChannel'),
            accessorFn: (row) => row?.requestChannel,
            enableSorting: true,
            id: 'requestChannel',
            meta: {
                getCellContext: (ctx: CellContext<ApiStandardRequestPreview, unknown>) => ctx?.getValue?.(),
            },
            cell: (ctx) =>
                ctx.row?.original?.requestChannel ? (
                    <span>{t(`meetings.proposals.requestChannelValue.${ctx.row?.original?.requestChannel}`)}</span>
                ) : (
                    <span>{ctx?.getValue?.() as string}</span>
                ),
        },
        {
            header: t('meetings.proposals.standardRequestState'),
            accessorFn: (row) => row?.standardRequestState,
            enableSorting: true,
            id: 'standardRequestState',
            meta: {
                getCellContext: (ctx: CellContext<ApiStandardRequestPreview, unknown>) => ctx?.getValue?.(),
            },
            cell: (ctx) =>
                ctx.row?.original?.standardRequestState ? (
                    <span>{t(`meetings.proposals.standardRequestStateValue.${ctx.row?.original?.standardRequestState}`)}</span>
                ) : (
                    <span>{ctx?.getValue?.() as string}</span>
                ),
        },
    ]
    return (
        <BaseModal isOpen={isOpen} close={handleClose}>
            <div className={styles.modalContainer}>
                <div className={styles.content}>
                    <TextHeading size={'L'} className={styles.heading}>
                        {t('meetings.form.heading.proposals')}
                    </TextHeading>
                    <Filter defaultFilterValues={filter} onlySearch form={() => <></>} />
                    <ActionsOverTable
                        pagination={{ ...pagination, dataLength: filteredData?.length ?? 0 }}
                        entityName={''}
                        hiddenButtons={{ SELECT_COLUMNS: true }}
                        pagingOptions={DEFAULT_PAGESIZE_OPTIONS}
                        handleFilterChange={myHandleFilterChange}
                    />
                    <QueryFeedback loading={isLoading} error={isError} withChildren>
                        <Table
                            data={tableData}
                            columns={columns}
                            isLoading={isLoading}
                            error={isError}
                            getRowId={(originalRow) => `${originalRow.id}`}
                            rowSelection={rowSelection.rowSelection}
                            onRowSelectionChange={rowSelection.getOnRowSelectionChange()}
                        />
                        <PaginatorWrapper
                            dataLength={filteredData.length ?? 0}
                            pageNumber={pagination.pageNumber}
                            pageSize={pagination.pageSize}
                            handlePageChange={(page) => setPagination({ ...pagination, pageNumber: page.pageNumber ?? defaultPagination.pageNumber })}
                        />
                    </QueryFeedback>
                </div>
            </div>
            <ModalButtons
                submitButtonLabel={t('button.saveChanges')}
                onSubmit={() => {
                    setSelectedProposals(rowSelection.ids)
                    handleClose()
                }}
                closeButtonLabel={t('button.cancel')}
                onClose={handleClose}
            />
        </BaseModal>
    )
}
