import { CheckBox } from '@isdd/idsk-ui-kit/index'
import { CHECKBOX_CELL } from '@isdd/idsk-ui-kit/table/constants'
import { Notification } from '@isdd/metais-common/api/generated/notifications-swagger'
import { useTranslation } from 'react-i18next'
import { ColumnDef } from '@tanstack/react-table'
export const firstLetterToLowerCase = (str: string): string => {
    return str.charAt(0).toLocaleLowerCase() + str.slice(1)
}

export const SelectableColumnsSpec = (columns: ColumnDef<Notification>[]): ColumnDef<Notification>[] => {
    const { t } = useTranslation()

    return [
        {
            header: ({ table }) => {
                return (
                    <div className="govuk-checkboxes govuk-checkboxes--small">
                        <CheckBox
                            label=""
                            name="checkbox"
                            id="checkbox_all"
                            value="true"
                            onChange={table.getToggleAllRowsSelectedHandler()}
                            checked={table.getIsAllRowsSelected()}
                            aria-label={t('table.selectAllItems')}
                        />
                    </div>
                )
            },
            size: 45,
            id: CHECKBOX_CELL,
            cell: ({ row }) => (
                <div className="govuk-checkboxes govuk-checkboxes--small">
                    <CheckBox
                        label=""
                        name="checkbox"
                        id={`checkbox_${row.id}`}
                        value="true"
                        onChange={row.getToggleSelectedHandler()}
                        checked={row.getIsSelected()}
                        aria-label={t('table.selectItem', { itemName: row.original.messagePerex })}
                    />
                </div>
            ),
        },
        ...columns,
    ]
}
