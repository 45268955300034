import styles from '../create-entity/createEntity.module.scss'

export enum AttributesConfigTechNames {
    METAIS_CODE = 'Gen_Profil_kod_metais',
    REFERENCE_ID = 'Gen_Profil_ref_id',
    EFF_DATE_FROM = 'Profil_Osobitny_Postup_datum_ucinnosti_od',
    EFF_DATE_TO = 'Profil_Osobitny_Postup_datum_ucinnosti_do',
    EA_Profil_Projekt_prijimatel = 'EA_Profil_Projekt_prijimatel',
    Profil_AtributObjektuEvidencie_paragraf = 'Profil_AtributObjektuEvidencie_paragraf',
    Profil_AtributObjektuEvidencie_rok = 'Profil_AtributObjektuEvidencie_rok',
    Profil_AtributObjektuEvidencie_cislo = 'Profil_AtributObjektuEvidencie_cislo',
    Profil_AtributObjektuEvidencie_jednotny_URI = 'Profil_AtributObjektuEvidencie_jednotny_URI',
    Profil_AtributObjektuEvidencie_eu_ref_id = 'Profil_AtributObjektuEvidencie_eu_ref_id',
    Profil_AtributObjektuEvidencie_nazov = 'Profil_AtributObjektuEvidencie_nazov',
    Profil_AtributObjektuEvidencie_odsek = 'Profil_AtributObjektuEvidencie_odsek',
    Profil_AtributObjektuEvidencie_pismeno = 'Profil_AtributObjektuEvidencie_pismeno',
    Profil_AtributObjektuEvidencie_bod = 'Profil_AtributObjektuEvidencie_bod',
    Profil_AtributObjektuEvidencie_zdroj = 'Profil_AtributObjektuEvidencie_zdroj',
    Profil_AtributObjektuEvidencie_dovod = 'Profil_AtributObjektuEvidencie_dovod',
    Profil_AtributObjektuEvidencie_pravny_ucel = 'Profil_AtributObjektuEvidencie_pravny_ucel',
    Profil_AtributObjektuEvidencie_eli_uri = 'Profil_AtributObjektuEvidencie_eli_uri',
}

interface AttConfig {
    attributes: {
        [key: string]: {
            className: string
        }
    }
}

export const attClassNameConfig: AttConfig = {
    attributes: {
        [AttributesConfigTechNames.METAIS_CODE]: {
            className: styles.halfWidth,
        },
        [AttributesConfigTechNames.REFERENCE_ID]: {
            className: styles.halfWidth,
        },
        [AttributesConfigTechNames.EFF_DATE_FROM]: {
            className: styles.halfWidth,
        },
        [AttributesConfigTechNames.EFF_DATE_TO]: {
            className: styles.halfWidth,
        },
        [AttributesConfigTechNames.Profil_AtributObjektuEvidencie_paragraf]: {
            className: styles.flexGrow30,
        },
        [AttributesConfigTechNames.Profil_AtributObjektuEvidencie_rok]: {
            className: styles.flexGrow30,
        },
        [AttributesConfigTechNames.Profil_AtributObjektuEvidencie_cislo]: {
            className: styles.flexGrow30,
        },
        [AttributesConfigTechNames.Profil_AtributObjektuEvidencie_jednotny_URI]: {
            className: styles.flexGrow100,
        },
        [AttributesConfigTechNames.Profil_AtributObjektuEvidencie_nazov]: {
            className: styles.flexGrow100,
        },
        [AttributesConfigTechNames.Profil_AtributObjektuEvidencie_odsek]: {
            className: styles.flexGrow25,
        },
        [AttributesConfigTechNames.Profil_AtributObjektuEvidencie_eu_ref_id]: {
            className: styles.flexGrow100,
        },
        [AttributesConfigTechNames.Profil_AtributObjektuEvidencie_pismeno]: {
            className: styles.flexGrow25,
        },
        [AttributesConfigTechNames.Profil_AtributObjektuEvidencie_bod]: {
            className: styles.flexGrow25,
        },
        [AttributesConfigTechNames.Profil_AtributObjektuEvidencie_zdroj]: {
            className: styles.flexGrow25,
        },
        [AttributesConfigTechNames.Profil_AtributObjektuEvidencie_dovod]: {
            className: styles.flexGrow100,
        },
        [AttributesConfigTechNames.Profil_AtributObjektuEvidencie_pravny_ucel]: {
            className: styles.flexGrow100,
        },
        [AttributesConfigTechNames.Profil_AtributObjektuEvidencie_eli_uri]: {
            className: styles.flexGrow100,
        },
    },
}
