import React, { PropsWithChildren } from 'react'

import styles from './styles.module.scss'

interface DelProps extends PropsWithChildren {
    isInvalid?: boolean
}

export const Del = ({ children, isInvalid = false }: DelProps) => {
    return isInvalid ? <del className={styles.del}>{children}</del> : <>{children}</>
}
