import { useQueries, useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

export type ApiModuleInformationType = {
    app?: { version?: string }
    build?: { commit?: { ref?: string; sha?: string; tag?: string } }
    chart?: { appversion?: string; release?: string; version?: string }
}

const fetchApiModuleInfomation = (moduleUrl: string): Promise<ApiModuleInformationType> => fetch(moduleUrl).then((response) => response.json())

export const useApiModuleInformation = (moduleUrl: string) => {
    const { data, isLoading, isError } = useQuery<unknown, unknown, ApiModuleInformationType>({
        queryFn: () => fetchApiModuleInfomation(moduleUrl),
        queryKey: ['apiModuleInformation', moduleUrl],
    })

    return { data, isLoading, isError }
}

export const useApiModulesInformation = (moduleUrls: string[]) => {
    const [data, setData] = useState<{ data: ApiModuleInformationType | undefined; isLoading: boolean; isError: boolean }[]>()
    const [loadingCount, setLoadingCount] = useState(0)

    const response = useQueries({
        queries: moduleUrls.map((moduleUrl) => {
            return {
                queryKey: ['apiModuleInformation', moduleUrl],
                queryFn: () => fetchApiModuleInfomation(moduleUrl),
                enabled: !!moduleUrl,
            }
        }),
    })

    useEffect(() => {
        const responseLoadingCount = response?.filter((r) => r.isLoading).length
        if (loadingCount != responseLoadingCount) {
            setLoadingCount(responseLoadingCount)
            setData(
                response.map((result) => ({
                    data: result.data,
                    isLoading: result.isLoading,
                    isError: result.isError,
                })),
            )
        }
    }, [loadingCount, response])

    return { data, loadingCount }
}
