import { SystemStatusBar } from '@isdd/idsk-ui-kit/system-status-bar/SystemStatusBar'
import { NavBarHeader } from '@isdd/metais-common/components/navbar/navbar-header/NavBarHeader'
import { NavBarMain } from '@isdd/metais-common/components/navbar/navbar-main/NavBarMain'
import { NavMenu } from '@isdd/metais-common/components/navbar/navmenu/NavMenu'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { useWindowWidthBreakpoints } from '@isdd/metais-common/hooks/window-size/useWindowWidthBreakpoints'
import { RouteNames } from '@isdd/metais-common/navigation/routeNames'
import React, { useId, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getPortalNavigationItems } from './navigationItems'

import { NotificationsPopup } from '@/components/notifications-popup/NotificationsPopup'
import { TasksPopup } from '@/components/tasks-popup/TasksPopup'
import { useOtherEntityHook } from '@/hooks/useOtherEntity.hook'
export const Navbar: React.FC = () => {
    const { t } = useTranslation()
    const {
        state: { user },
    } = useAuth()
    const [isMenuExpanded, setIsMenuExpanded] = useState<boolean>(false)
    const [showDropDown, setShowDropDown] = useState<boolean>(false)
    const windowWidthBreakpoints = useWindowWidthBreakpoints()
    const menuId = useId()
    const { ciOtherEntityItems } = useOtherEntityHook()

    const iconGroupItems: React.FC[] = [TasksPopup, NotificationsPopup]
    const isMobileMenu = !windowWidthBreakpoints?.desktop
    const topMenuPortalRoutes = getPortalNavigationItems(t, user, ciOtherEntityItems, isMobileMenu)
    const topMenuWithoutPOAndMonitoring = topMenuPortalRoutes.filter((item) => item.path != RouteNames.HOW_TO_KRIS_STUDIES_PROJECTS)

    return (
        <>
            <a href="#main-content" className="govuk-skip-link">
                {t('navbar.skip')}
            </a>
            <header className="idsk-header-web " data-module="idsk-header-web">
                <div className="idsk-header-web__scrolling-wrapper">
                    <div className="idsk-header-web__tricolor" />

                    <NavBarHeader setShowDropDown={setShowDropDown} showDropDown={showDropDown} />

                    <NavBarMain
                        menuId={menuId}
                        isMenuExpanded={isMenuExpanded}
                        setIsMenuExpanded={setIsMenuExpanded}
                        iconGroupItems={iconGroupItems}
                    />

                    <div className="idsk-header-web__nav--divider" />
                    <NavMenu
                        id={menuId}
                        isMenuExpanded={isMenuExpanded}
                        setIsMenuExpanded={setIsMenuExpanded}
                        navItems={topMenuWithoutPOAndMonitoring}
                    />
                    <SystemStatusBar />
                </div>
            </header>
        </>
    )
}
