import classNames from 'classnames'
import React, { useState } from 'react'
import { useWindowWidthBreakpoints } from '@isdd/metais-common/hooks/window-size/useWindowWidthBreakpoints'
import { useTranslation } from 'react-i18next'

import styles from './styles.module.scss'

import { ArrowDownIcon, LoadingIndicator, TextBody, TransparentButtonWrapper } from '@isdd/idsk-ui-kit/'

export enum PROGRESS_STATE {
    FINISHED = 'finished',
    CURRENT = 'current',
    FUTURE = 'future',
}

export interface IStep {
    keyNumber?: number
    name: string
    date?: string
    description?: string
    state?: PROGRESS_STATE
    firstItem?: boolean
    lastItem?: boolean
    isRed?: boolean
    isExpanded?: boolean
}

export interface IProgressStepper {
    steps: IStep[]
    currentStep: number
    isLoading: boolean
}

export const Step: React.FC<IStep> = ({ keyNumber, name, date, description, state, isRed }) => {
    return (
        <li
            key={keyNumber}
            className={styles.step}
            aria-current={state == PROGRESS_STATE.CURRENT ? 'step' : undefined}
            data-completed={state == PROGRESS_STATE.FINISHED}
        >
            <TextBody
                className={classNames([styles.textWrapper, styles.noWrap], {
                    [styles.greenText]: state == PROGRESS_STATE.FINISHED,
                    [styles.greyText]: state == PROGRESS_STATE.FUTURE,
                    [styles.redText]: isRed,
                })}
            >
                {keyNumber + '. ' + name}
            </TextBody>
            <TextBody size="S" className={styles.textWrapper}>
                {date}
            </TextBody>
            <TextBody size="S" className={styles.textWrapper}>
                {description}
            </TextBody>
        </li>
    )
}

export const MobileStep: React.FC<IStep> = ({ keyNumber, name, date, description, state, isRed }) => {
    return (
        <div className={styles.mobileColumnWrapper}>
            <div className={styles.mobileTextDate}>
                <TextBody
                    className={classNames([styles.mobileTextWrapper, styles.noWrap], {
                        [styles.greenText]: state == PROGRESS_STATE.FINISHED,
                        [styles.greyText]: state == PROGRESS_STATE.FUTURE,
                        [styles.redText]: isRed,
                    })}
                >
                    {keyNumber + '. ' + name}
                </TextBody>

                <TextBody size="S" className={styles.mobileDateWrapper}>
                    {date}
                </TextBody>
            </div>
            <div className={styles.descriptionWrapper}>
                <TextBody size="S" className={styles.mobileTextWrapper}>
                    {description}
                </TextBody>
            </div>
        </div>
    )
}

const resolveState = (index: number, currentStep: number) =>
    index == currentStep ? PROGRESS_STATE.CURRENT : index < currentStep ? PROGRESS_STATE.FINISHED : PROGRESS_STATE.FUTURE

export const ProgressStepper: React.FC<IProgressStepper> = ({ steps, currentStep, isLoading }) => {
    const windowWidthBreakpoints = useWindowWidthBreakpoints()
    const [isExpanded, setExpanded] = useState(false)
    const { t } = useTranslation()
    return windowWidthBreakpoints?.tablet || !windowWidthBreakpoints ? (
        <nav aria-label={t('ciType.project.evidenceState')} className={classNames(styles.mainWrapper, { [styles.positionRelative]: isLoading })}>
            {isLoading ? (
                <div className={styles.paddingLoading}>
                    <LoadingIndicator transparentMask />
                </div>
            ) : (
                <ol className={styles.stepsWrapper}>
                    {steps.map((step, index) => {
                        return (
                            <Step
                                key={index}
                                keyNumber={index + 1}
                                {...step}
                                state={resolveState(index, currentStep)}
                                firstItem={index == 0}
                                lastItem={index == steps.length - 1}
                            />
                        )
                    })}
                </ol>
            )}
        </nav>
    ) : (
        <nav aria-label={t('ciType.project.evidenceState')} className={styles.mobileMainWrapper}>
            {isLoading ? (
                <div className={styles.paddingLoading}>
                    <LoadingIndicator transparentMask />
                </div>
            ) : (
                <>
                    <TransparentButtonWrapper
                        onClick={() => setExpanded(!isExpanded)}
                        aria-label={isExpanded ? t('progressStepper.collapse') : t('progressStepper.expand')}
                        aria-expanded={isExpanded ? 'true' : 'false'}
                        aria-controls="stateList"
                        btnWrapperClassName={styles.customFocus}
                    >
                        <img height={10} className={classNames({ [styles.rotate180]: isExpanded })} src={ArrowDownIcon} />
                    </TransparentButtonWrapper>
                    {isExpanded ? (
                        <ol className={styles.mobileStepsWrapper} id="stateList">
                            {steps.map((step, index) => {
                                return (
                                    <li
                                        key={index + 1}
                                        className={styles.mobileStep}
                                        aria-current={resolveState(index, currentStep) == PROGRESS_STATE.CURRENT ? 'step' : undefined}
                                        data-completed={resolveState(index, currentStep) == PROGRESS_STATE.FINISHED}
                                    >
                                        <MobileStep
                                            isExpanded={isExpanded}
                                            key={index}
                                            keyNumber={index + 1}
                                            {...step}
                                            state={resolveState(index, currentStep)}
                                            firstItem={index == 0}
                                            lastItem={index == steps.length - 1}
                                        />
                                    </li>
                                )
                            })}
                        </ol>
                    ) : (
                        <div className={classNames(styles.mobileStepsWrapper, styles.marginY)} id="stateList">
                            {steps.map((step, index) => {
                                if (resolveState(index, currentStep) == PROGRESS_STATE.CURRENT) {
                                    return (
                                        <div
                                            className={styles.mobileStep}
                                            key={index + 1}
                                            aria-current={resolveState(index, currentStep) == PROGRESS_STATE.CURRENT ? 'step' : undefined}
                                            data-completed={resolveState(index, currentStep) == PROGRESS_STATE.FINISHED}
                                        >
                                            <MobileStep
                                                isExpanded={isExpanded}
                                                key={index}
                                                keyNumber={index + 1}
                                                {...step}
                                                state={resolveState(index, currentStep)}
                                                firstItem={index == 0}
                                                lastItem={index == steps.length - 1}
                                            />
                                        </div>
                                    )
                                }
                                return
                            })}
                        </div>
                    )}
                </>
            )}
        </nav>
    )
}
