//@ts-nocheck
/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * MetaIS Kris
 * OpenAPI spec version: latest
 */
import { useQuery, useMutation } from '@tanstack/react-query'
import type { UseQueryOptions, UseMutationOptions, QueryFunction, MutationFunction, UseQueryResult, QueryKey } from '@tanstack/react-query'
import { useKRISSwaggerClient } from '../hooks/useKRISSwaggerClient'
export type GetRequiredDocuments200Item = { [key: string]: { [key: string]: any } }

export type FindKontrolaParams = {
    entityType: string
    page?: number
    pageSize?: number
    sortAttr?: string
    sortAsc?: boolean
}

export type FindInvalidEntitiesParams = {
    entityUuid: string
    kontrolaId: number
}

export type FindKontrolaResultParams = {
    entityUuid: string
    page?: number
    pageSize?: number
    sortAttr?: string
    sortAsc?: boolean
}

export type FindKontrolaResultDetailParams = {
    kontrolaResultId: number
    page?: number
    pageSize?: number
    sortAttr?: string
    sortAsc?: boolean
}

export type GetIsvsDirection = (typeof GetIsvsDirection)[keyof typeof GetIsvsDirection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetIsvsDirection = {
    ASC: 'ASC',
    DESC: 'DESC',
} as const

export type GetIsvsOrder = (typeof GetIsvsOrder)[keyof typeof GetIsvsOrder]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetIsvsOrder = {
    NAME: 'NAME',
    CODE: 'CODE',
    ISVS_STATE: 'ISVS_STATE',
    ISVS_TYPE: 'ISVS_TYPE',
    ISVS_MODULE: 'ISVS_MODULE',
} as const

export type GetIsvsParams = {
    page: number
    count: number
    order: GetIsvsOrder
    direction: GetIsvsDirection
}

export type GetKsDirection = (typeof GetKsDirection)[keyof typeof GetKsDirection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetKsDirection = {
    ASC: 'ASC',
    DESC: 'DESC',
} as const

export type GetKsOrder = (typeof GetKsOrder)[keyof typeof GetKsOrder]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetKsOrder = {
    NAME: 'NAME',
    CODE: 'CODE',
    FAZA_NAME: 'FAZA_NAME',
} as const

export type GetKsParams = {
    page: number
    count: number
    order: GetKsOrder
    direction: GetKsDirection
}

export type GetProjectsDirection = (typeof GetProjectsDirection)[keyof typeof GetProjectsDirection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetProjectsDirection = {
    ASC: 'ASC',
    DESC: 'DESC',
} as const

export type GetProjectsOrder = (typeof GetProjectsOrder)[keyof typeof GetProjectsOrder]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetProjectsOrder = {
    NAME: 'NAME',
    CODE: 'CODE',
} as const

export type GetProjectsParams = {
    page: number
    count: number
    order: GetProjectsOrder
    direction: GetProjectsDirection
}

export type HasPermissionParams = {
    type: string
}

export type CheckOperationPermissionByMetaisCodeParams = {
    operation: string
}

export type AgendaCezPo200 = { [key: string]: any }

export type AgendyCezProjekty200 = { [key: string]: any }

export type AplikacneSluzbyCezIsvs200 = { [key: string]: any }

export type AsCezProjekty200 = { [key: string]: any }

export type BazyDatCezIsvs200 = { [key: string]: any }

export type BazyDatCezProjekty200 = { [key: string]: any }

export type FindByStateParams = {
    state: string
}

export type FindByDocumentGroupIdParams = {
    documentGroupId: number
}

export type IsvsCezPo200 = { [key: string]: any }

export type IsvsCezProjekty200 = { [key: string]: any }

export type RunKontrolaResultParams = {
    kontrolaName: string
    'kontrolaIds[]': number[]
    entityUuid: string
}

export type ExportNoteResponseParams = {
    page: number
    count: number
    version: number
}

export type KoncoveSluzbyCezIsvs200 = { [key: string]: any }

export type KsCezPo200 = { [key: string]: any }

export type KsCezProjekty200 = { [key: string]: any }

export type PlatformyCezProjekty200 = { [key: string]: any }

export type FindByParams1Params = {
    programUuid: string
    projectType: string
    projectManagementMethod: string
    financialValue?: number
}

export type FindByParamsParams = {
    programUuid: string
    projectType: string
    projectManagementMethod: string
}

export type ProjektyCezIsvs200 = { [key: string]: any }

export type ProjektyCezPo200 = { [key: string]: any }

export type UsekyverejnejSpravyCezIsvs200 = { [key: string]: any }

export type UvsCezProjekty200 = { [key: string]: any }

export type ZivotneSituacieCezIsvs200 = { [key: string]: any }

export type ZsCezPo200 = { [key: string]: any }

export type ZsCezProjekty200 = { [key: string]: any }

export interface PaginationResponseData {
    page?: number
    perPage?: number
    totalPages?: number
    totalItems?: number
}

export interface ApiKontrolaResult {
    id?: number
    entityUuid?: string
    kontrolaName?: string
    kontrolaRunAt?: string
    kontrolaRunBy?: string
    errorCount?: number
}

export interface ApiKontrolaResultList {
    results?: ApiKontrolaResult[]
    pagination?: PaginationResponseData
}

export interface ApiKontrola {
    id?: number
    code?: string
    entityType?: string
    resultEntityType?: string
    errorDescription?: string
    description?: string
    relevance?: string
    query?: string
}

export interface ApiKontrolaList {
    results?: ApiKontrola[]
    pagination?: PaginationResponseData
}

export interface ApiKontrolaResultDetail {
    id?: number
    kontrola?: ApiKontrola
    kontrolaResult?: ApiKontrolaResult
    entityUuid?: string
    entityName?: string
}

export interface ApiKontrolaResultDetailList {
    results?: ApiKontrolaResultDetail[]
    pagination?: PaginationResponseData
}

export interface KrisToBeIsvs {
    uuid?: string
    name?: string
    code?: string
    isvsState?: string
    isvsType?: string
    modulUuid?: string
    modulName?: string
    validated?: boolean
}

export interface PageKrisToBeIsvs {
    totalElements?: number
    totalPages?: number
    size?: number
    content?: KrisToBeIsvs[]
    number?: number
    sort?: SortObject
    numberOfElements?: number
    pageable?: PageableObject
    first?: boolean
    last?: boolean
    empty?: boolean
}

export interface NoteSubItemUi {
    itemId?: string
    state?: StateUi
    evaluations?: NoteUi[]
    responses?: NoteUi[]
}

export interface SubItemListUi {
    items?: NoteSubItemUi[]
}

export interface NoteItemUi {
    state?: StateUi
    evaluations?: NoteUi[]
    responses?: NoteUi[]
}

export interface KrisToBeKs {
    uuid?: string
    name?: string
    code?: string
    fazaUuid?: string
    fazaName?: string
    validated?: boolean
}

export interface SortObject {
    empty?: boolean
    sorted?: boolean
    unsorted?: boolean
}

export interface PageableObject {
    offset?: number
    sort?: SortObject
    pageNumber?: number
    pageSize?: number
    unpaged?: boolean
    paged?: boolean
}

export interface PageKrisToBeKs {
    totalElements?: number
    totalPages?: number
    size?: number
    content?: KrisToBeKs[]
    number?: number
    sort?: SortObject
    numberOfElements?: number
    pageable?: PageableObject
    first?: boolean
    last?: boolean
    empty?: boolean
}

export interface KrisToBeActivity {
    uuid?: string
    name?: string
    code?: string
    validated?: boolean
}

export interface KrisToBeProject {
    uuid?: string
    name?: string
    code?: string
    activities?: KrisToBeActivity[]
    validated?: boolean
}

export interface PageKrisToBeProject {
    totalElements?: number
    totalPages?: number
    size?: number
    content?: KrisToBeProject[]
    number?: number
    sort?: SortObject
    numberOfElements?: number
    pageable?: PageableObject
    first?: boolean
    last?: boolean
    empty?: boolean
}

export interface KrisToBeRights {
    inEvaluation?: boolean
    inProgress?: boolean
    hasVersions?: boolean
    municipality?: boolean
    evaluator?: boolean
    creator?: boolean
}

export interface ApiProgramCi {
    programUuid: string
    name?: string
}

export interface Phase {
    name?: string
    states?: string[]
}

export interface ApiPermissionResult {
    hasPermission?: boolean
}

export interface DocumentGroup {
    id?: number
    name?: string
    nameEng?: string
    description?: string
    descriptionEng?: string
    state: string
    position?: number
}

export interface Document {
    id?: number
    name?: string
    nameEng?: string
    description?: string
    descriptionEng?: string
    required: boolean
    confluence: boolean
    type: string
    position?: number
    documentGroup: DocumentGroup
    templateUuid?: string
}

export type FillRefAttributesTaskStatus = (typeof FillRefAttributesTaskStatus)[keyof typeof FillRefAttributesTaskStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FillRefAttributesTaskStatus = {
    CREATED: 'CREATED',
    IN_PROGRESS_PREPARE_DATA: 'IN_PROGRESS_PREPARE_DATA',
    IN_PROGRESS_UPDATE_METADATA_IN_DMS: 'IN_PROGRESS_UPDATE_METADATA_IN_DMS',
    FINISHED: 'FINISHED',
    CANCELLED: 'CANCELLED',
} as const

export type DocumentInfoState = (typeof DocumentInfoState)[keyof typeof DocumentInfoState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentInfoState = {
    ERROR: 'ERROR',
    INCONSISTENT: 'INCONSISTENT',
    NOT_FOUND: 'NOT_FOUND',
    SOFT_DELETED: 'SOFT_DELETED',
    STATE_MISSING: 'STATE_MISSING',
    UPDATED: 'UPDATED',
} as const

export interface DocumentInfo {
    id?: string
    state?: DocumentInfoState
    errorMessage?: string
}

export interface FillRefAttributesTask {
    id?: string
    totalCount?: number
    startedAt?: string
    endedAt?: string
    status?: FillRefAttributesTaskStatus
    successUuids?: string[]
    failedUuids?: DocumentInfo[]
    successCount?: number
    failedCount?: number
    failedCountInconsistent?: number
    failedCountNotFound?: number
    failedCountSoftDeleted?: number
    failedCountStateMissing?: number
    taskBriefInfo?: string
    failedCountError?: number
}

export interface RecycleSetUi {
    relIdSet?: string[]
    ciIdSet?: string[]
}

export interface MetaAttributesUi {
    owner?: string
    state?: string
    createdBy?: string
    createdAt?: string
    lastModifiedBy?: string
    lastModifiedAt?: string
}

export interface ConfigurationItemInvalidateUi {
    type?: string
    uuid?: string
    owner?: string
    attributes?: AttributeUi[]
    metaAttributes?: MetaAttributesUi
    invalidateReason?: InvalidateReason
}

export interface RelationshipUi {
    type?: string
    uuid?: string
    owner?: string
    startUuid?: string
    startType?: string
    startTypeName?: string
    startName?: string
    startKodMetaIS?: string
    endUuid?: string
    endType?: string
    endTypeName?: string
    endName?: string
    endKodMetaIS?: string
    attributes?: AttributeUi[]
    metaAttributes?: MetaAttributesUi
}

export interface InvalidateReason {
    comment?: string
}

export interface StoreSetUi {
    configurationItemSet?: ConfigurationItemUi[]
    relationshipSet?: RelationshipUi[]
    invalidateReason?: InvalidateReason
}

export interface CustomMessageUi {
    messageSuccessType?: string
    successPlaceholderValues?: string[]
    messageFailType?: string
    failPlaceholderValues?: string[]
}

export interface GraphRequestUi {
    storeSet?: StoreSetUi
    invalidateSet?: InvalidateSetUi
    changeOwnerSet?: ChangeOwnerSetUi
    recycleSet?: RecycleSetUi
    customMessage?: CustomMessageUi
}

export type ConfigurationItemUiAttributes = { [key: string]: any }

export interface ConfigurationItemUi {
    type?: string
    uuid?: string
    owner?: string
    attributes?: ConfigurationItemUiAttributes
    metaAttributes?: MetaAttributesUi
}

export interface InvalidateSetUi {
    configurationItemSet?: ConfigurationItemUi[]
    relationshipSet?: RelationshipUi[]
    invalidateReason?: InvalidateReason
}

export type ChangeOwnerDataUiChangeType = (typeof ChangeOwnerDataUiChangeType)[keyof typeof ChangeOwnerDataUiChangeType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChangeOwnerDataUiChangeType = {
    changeCmdbItemAndRelatedCmdbItems: 'changeCmdbItemAndRelatedCmdbItems',
    changeCmdbItemAndRelatedCmdbItemsWithSameOwner: 'changeCmdbItemAndRelatedCmdbItemsWithSameOwner',
    changeCmdbItem: 'changeCmdbItem',
} as const

export interface ChangeOwnerDataUi {
    newOwner?: string
    changeReason?: string
    changeDescription?: string
    changeType?: ChangeOwnerDataUiChangeType
}

export interface ChangeOwnerSetUi {
    configurationItemSet?: ConfigurationItemUi[]
    relationshipSet?: RelationshipUi[]
    invalidateReason?: InvalidateReason
    changeOwnerData?: ChangeOwnerDataUi
}

export type AttributeUiValue = { [key: string]: any }

export interface AttributeUi {
    name?: string
    value?: AttributeUiValue
}

export interface SubItemNewNoteUi {
    itemId?: string
    values?: NoteValueUi[]
    state?: StateUi
}

export interface SubItemsNewNotesUi {
    itemNotes?: SubItemNewNoteUi[]
}

export interface SubItemNoteUi {
    itemId?: string
    values?: NoteValueUi[]
}

export interface SubItemsNotesUi {
    itemNotes?: SubItemNoteUi[]
}

export interface ExportNoteUi {
    code?: string
    evaluation?: NoteUi
    response?: NoteUi
}

export interface ExportNoteResponse {
    page?: ExportNoteUi[]
    lastPage?: boolean
}

export interface ExportFilter {
    ids?: string[]
}

export interface StateValueUi {
    name?: string
    value?: boolean
}

export interface StateUi {
    values?: StateValueUi[]
}

export interface NoteVersionUi {
    created?: string
    createdBy?: string
    evaluated?: string
    evaluatedBy?: string
    noteState?: string
    versionNumber?: number
}

export interface NoteValueUi {
    name?: string
    value?: string
}

export interface NewNoteUi {
    values?: NoteValueUi[]
    state?: StateUi
}

export interface NoteUi {
    values?: NoteValueUi[]
    noteVersionUi?: NoteVersionUi
}

export interface ApiApprovalProcess {
    id: number
    name?: string
    nameEng?: string
    description?: string
    descriptionEng?: string
    technicalName: string
}

export interface ApiProgram {
    id?: number
    programUuid?: string
    projectType: string
    projectManagementMethod: string
    default?: boolean
}

export interface ApiProgramPartFinance {
    id?: number
    approvalProcess: ApiApprovalProcess
    financialGroup?: string
    min?: number
    max?: number
    position?: number
    apiProgram: ApiProgram
}

export interface RequestIdUi {
    requestId?: string
}

export interface KrisRequestNew {
    mainPerson?: string
    showInvalidated?: boolean
    includeSubPersons?: boolean
}

export interface KrisRequest {
    data?: string[]
    showInvalidated?: boolean
}

export interface KrisUi {
    uuid?: string
    approved?: boolean
    manualApproved?: boolean
}

export type ApiErrorData = { [key: string]: any }

export interface ApiError {
    type?: string
    message?: string
    data?: ApiErrorData
    logToken?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const useUpdateManualApprovementHook = () => {
    const updateManualApprovement = useKRISSwaggerClient<void>()

    return (uuid: string, krisUi: KrisUi) => {
        return updateManualApprovement({
            url: `/kris-to-be/kris/${uuid}/approve`,
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: krisUi,
        })
    }
}

export const useUpdateManualApprovementMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateManualApprovementHook>>>,
        TError,
        { uuid: string; data: KrisUi },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateManualApprovementHook>>>, TError, { uuid: string; data: KrisUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateManualApprovement = useUpdateManualApprovementHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateManualApprovementHook>>>, { uuid: string; data: KrisUi }> = (
        props,
    ) => {
        const { uuid, data } = props ?? {}

        return updateManualApprovement(uuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateManualApprovementMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateManualApprovementHook>>>>
export type UpdateManualApprovementMutationBody = KrisUi
export type UpdateManualApprovementMutationError = ApiError

export const useUpdateManualApprovement = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateManualApprovementHook>>>,
        TError,
        { uuid: string; data: KrisUi },
        TContext
    >
}) => {
    const mutationOptions = useUpdateManualApprovementMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary zsCezProjekty
 */
export const useZsCezProjektyHook = () => {
    const zsCezProjekty = useKRISSwaggerClient<ZsCezProjekty200>()

    return (krisRequest: KrisRequest) => {
        return zsCezProjekty({ url: `/zs-cez-projekty`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: krisRequest })
    }
}

export const useZsCezProjektyMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useZsCezProjektyHook>>>, TError, { data: KrisRequest }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useZsCezProjektyHook>>>, TError, { data: KrisRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const zsCezProjekty = useZsCezProjektyHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useZsCezProjektyHook>>>, { data: KrisRequest }> = (props) => {
        const { data } = props ?? {}

        return zsCezProjekty(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ZsCezProjektyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useZsCezProjektyHook>>>>
export type ZsCezProjektyMutationBody = KrisRequest
export type ZsCezProjektyMutationError = ApiError

/**
 * @summary zsCezProjekty
 */
export const useZsCezProjekty = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useZsCezProjektyHook>>>, TError, { data: KrisRequest }, TContext>
}) => {
    const mutationOptions = useZsCezProjektyMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary zsCezPo
 */
export const useZsCezPoHook = () => {
    const zsCezPo = useKRISSwaggerClient<ZsCezPo200>()

    return (krisRequestNew: KrisRequestNew) => {
        return zsCezPo({ url: `/zs-cez-po`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: krisRequestNew })
    }
}

export const useZsCezPoMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useZsCezPoHook>>>, TError, { data: KrisRequestNew }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useZsCezPoHook>>>, TError, { data: KrisRequestNew }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const zsCezPo = useZsCezPoHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useZsCezPoHook>>>, { data: KrisRequestNew }> = (props) => {
        const { data } = props ?? {}

        return zsCezPo(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ZsCezPoMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useZsCezPoHook>>>>
export type ZsCezPoMutationBody = KrisRequestNew
export type ZsCezPoMutationError = ApiError

/**
 * @summary zsCezPo
 */
export const useZsCezPo = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useZsCezPoHook>>>, TError, { data: KrisRequestNew }, TContext>
}) => {
    const mutationOptions = useZsCezPoMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary zivotneSituacieCezIsvs
 */
export const useZivotneSituacieCezIsvsHook = () => {
    const zivotneSituacieCezIsvs = useKRISSwaggerClient<ZivotneSituacieCezIsvs200>()

    return (krisRequest: KrisRequest) => {
        return zivotneSituacieCezIsvs({ url: `/zs-cez-isvs`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: krisRequest })
    }
}

export const useZivotneSituacieCezIsvsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useZivotneSituacieCezIsvsHook>>>, TError, { data: KrisRequest }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useZivotneSituacieCezIsvsHook>>>, TError, { data: KrisRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const zivotneSituacieCezIsvs = useZivotneSituacieCezIsvsHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useZivotneSituacieCezIsvsHook>>>, { data: KrisRequest }> = (props) => {
        const { data } = props ?? {}

        return zivotneSituacieCezIsvs(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ZivotneSituacieCezIsvsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useZivotneSituacieCezIsvsHook>>>>
export type ZivotneSituacieCezIsvsMutationBody = KrisRequest
export type ZivotneSituacieCezIsvsMutationError = ApiError

/**
 * @summary zivotneSituacieCezIsvs
 */
export const useZivotneSituacieCezIsvs = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useZivotneSituacieCezIsvsHook>>>, TError, { data: KrisRequest }, TContext>
}) => {
    const mutationOptions = useZivotneSituacieCezIsvsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary uvsCezProjekty
 */
export const useUvsCezProjektyHook = () => {
    const uvsCezProjekty = useKRISSwaggerClient<UvsCezProjekty200>()

    return (krisRequest: KrisRequest) => {
        return uvsCezProjekty({ url: `/uvs-cez-projekty`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: krisRequest })
    }
}

export const useUvsCezProjektyMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUvsCezProjektyHook>>>, TError, { data: KrisRequest }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUvsCezProjektyHook>>>, TError, { data: KrisRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const uvsCezProjekty = useUvsCezProjektyHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUvsCezProjektyHook>>>, { data: KrisRequest }> = (props) => {
        const { data } = props ?? {}

        return uvsCezProjekty(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UvsCezProjektyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUvsCezProjektyHook>>>>
export type UvsCezProjektyMutationBody = KrisRequest
export type UvsCezProjektyMutationError = ApiError

/**
 * @summary uvsCezProjekty
 */
export const useUvsCezProjekty = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUvsCezProjektyHook>>>, TError, { data: KrisRequest }, TContext>
}) => {
    const mutationOptions = useUvsCezProjektyMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary usekyverejnejSpravyCezIsvs
 */
export const useUsekyverejnejSpravyCezIsvsHook = () => {
    const usekyverejnejSpravyCezIsvs = useKRISSwaggerClient<UsekyverejnejSpravyCezIsvs200>()

    return (krisRequest: KrisRequest) => {
        return usekyverejnejSpravyCezIsvs({
            url: `/uvs-cez-isvs`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: krisRequest,
        })
    }
}

export const useUsekyverejnejSpravyCezIsvsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUsekyverejnejSpravyCezIsvsHook>>>, TError, { data: KrisRequest }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUsekyverejnejSpravyCezIsvsHook>>>, TError, { data: KrisRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const usekyverejnejSpravyCezIsvs = useUsekyverejnejSpravyCezIsvsHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUsekyverejnejSpravyCezIsvsHook>>>, { data: KrisRequest }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return usekyverejnejSpravyCezIsvs(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UsekyverejnejSpravyCezIsvsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUsekyverejnejSpravyCezIsvsHook>>>>
export type UsekyverejnejSpravyCezIsvsMutationBody = KrisRequest
export type UsekyverejnejSpravyCezIsvsMutationError = ApiError

/**
 * @summary usekyverejnejSpravyCezIsvs
 */
export const useUsekyverejnejSpravyCezIsvs = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUsekyverejnejSpravyCezIsvsHook>>>, TError, { data: KrisRequest }, TContext>
}) => {
    const mutationOptions = useUsekyverejnejSpravyCezIsvsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useRecycleConfigurationItemBiznisMdulesHook = () => {
    const recycleConfigurationItemBiznisMdules = useKRISSwaggerClient<RequestIdUi>()

    return (uuid: string) => {
        return recycleConfigurationItemBiznisMdules({ url: `/recycle/ci/${uuid}`, method: 'post' })
    }
}

export const useRecycleConfigurationItemBiznisMdulesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRecycleConfigurationItemBiznisMdulesHook>>>,
        TError,
        { uuid: string },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRecycleConfigurationItemBiznisMdulesHook>>>, TError, { uuid: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const recycleConfigurationItemBiznisMdules = useRecycleConfigurationItemBiznisMdulesHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRecycleConfigurationItemBiznisMdulesHook>>>, { uuid: string }> = (
        props,
    ) => {
        const { uuid } = props ?? {}

        return recycleConfigurationItemBiznisMdules(uuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type RecycleConfigurationItemBiznisMdulesMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useRecycleConfigurationItemBiznisMdulesHook>>>
>

export type RecycleConfigurationItemBiznisMdulesMutationError = ApiError

export const useRecycleConfigurationItemBiznisMdules = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRecycleConfigurationItemBiznisMdulesHook>>>,
        TError,
        { uuid: string },
        TContext
    >
}) => {
    const mutationOptions = useRecycleConfigurationItemBiznisMdulesMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary projektyCezPo
 */
export const useProjektyCezPoHook = () => {
    const projektyCezPo = useKRISSwaggerClient<ProjektyCezPo200>()

    return (krisRequestNew: KrisRequestNew) => {
        return projektyCezPo({ url: `/projekty-cez-po`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: krisRequestNew })
    }
}

export const useProjektyCezPoMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useProjektyCezPoHook>>>, TError, { data: KrisRequestNew }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useProjektyCezPoHook>>>, TError, { data: KrisRequestNew }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const projektyCezPo = useProjektyCezPoHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useProjektyCezPoHook>>>, { data: KrisRequestNew }> = (props) => {
        const { data } = props ?? {}

        return projektyCezPo(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ProjektyCezPoMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useProjektyCezPoHook>>>>
export type ProjektyCezPoMutationBody = KrisRequestNew
export type ProjektyCezPoMutationError = ApiError

/**
 * @summary projektyCezPo
 */
export const useProjektyCezPo = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useProjektyCezPoHook>>>, TError, { data: KrisRequestNew }, TContext>
}) => {
    const mutationOptions = useProjektyCezPoMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary projektyCezIsvs
 */
export const useProjektyCezIsvsHook = () => {
    const projektyCezIsvs = useKRISSwaggerClient<ProjektyCezIsvs200>()

    return (krisRequest: KrisRequest) => {
        return projektyCezIsvs({ url: `/projekty-cez-isvs`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: krisRequest })
    }
}

export const useProjektyCezIsvsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useProjektyCezIsvsHook>>>, TError, { data: KrisRequest }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useProjektyCezIsvsHook>>>, TError, { data: KrisRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const projektyCezIsvs = useProjektyCezIsvsHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useProjektyCezIsvsHook>>>, { data: KrisRequest }> = (props) => {
        const { data } = props ?? {}

        return projektyCezIsvs(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ProjektyCezIsvsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useProjektyCezIsvsHook>>>>
export type ProjektyCezIsvsMutationBody = KrisRequest
export type ProjektyCezIsvsMutationError = ApiError

/**
 * @summary projektyCezIsvs
 */
export const useProjektyCezIsvs = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useProjektyCezIsvsHook>>>, TError, { data: KrisRequest }, TContext>
}) => {
    const mutationOptions = useProjektyCezIsvsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const usePreviousStateHook = () => {
    const previousState = useKRISSwaggerClient<void>()

    return (uuid: string) => {
        return previousState({ url: `/projects/${uuid}/previous-state`, method: 'post' })
    }
}

export const usePreviousStateMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePreviousStateHook>>>, TError, { uuid: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePreviousStateHook>>>, TError, { uuid: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const previousState = usePreviousStateHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePreviousStateHook>>>, { uuid: string }> = (props) => {
        const { uuid } = props ?? {}

        return previousState(uuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type PreviousStateMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePreviousStateHook>>>>

export type PreviousStateMutationError = ApiError

export const usePreviousState = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePreviousStateHook>>>, TError, { uuid: string }, TContext>
}) => {
    const mutationOptions = usePreviousStateMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useNextStateOverwrittenHook = () => {
    const nextStateOverwritten = useKRISSwaggerClient<void>()

    return (uuid: string, nextState: string) => {
        return nextStateOverwritten({ url: `/projects/${uuid}/next-state/${nextState}`, method: 'post' })
    }
}

export const useNextStateOverwrittenMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useNextStateOverwrittenHook>>>,
        TError,
        { uuid: string; nextState: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useNextStateOverwrittenHook>>>,
    TError,
    { uuid: string; nextState: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const nextStateOverwritten = useNextStateOverwrittenHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useNextStateOverwrittenHook>>>, { uuid: string; nextState: string }> = (
        props,
    ) => {
        const { uuid, nextState } = props ?? {}

        return nextStateOverwritten(uuid, nextState)
    }

    return { mutationFn, ...mutationOptions }
}

export type NextStateOverwrittenMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useNextStateOverwrittenHook>>>>

export type NextStateOverwrittenMutationError = ApiError

export const useNextStateOverwritten = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useNextStateOverwrittenHook>>>,
        TError,
        { uuid: string; nextState: string },
        TContext
    >
}) => {
    const mutationOptions = useNextStateOverwrittenMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useFindByParamsHook = () => {
    const findByParams = useKRISSwaggerClient<ApiProgram>()

    return (params: FindByParamsParams, signal?: AbortSignal) => {
        return findByParams({ url: `/programs`, method: 'get', params, signal })
    }
}

export const getFindByParamsQueryKey = (params: FindByParamsParams) => [`/programs`, ...(params ? [params] : [])] as const

export const useFindByParamsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindByParamsHook>>>, TError = ApiError>(
    params: FindByParamsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByParamsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByParamsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindByParamsQueryKey(params)

    const findByParams = useFindByParamsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindByParamsHook>>>> = ({ signal }) => findByParams(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindByParamsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindByParamsHook>>>>
export type FindByParamsQueryError = ApiError

export const useFindByParams = <TData = Awaited<ReturnType<ReturnType<typeof useFindByParamsHook>>>, TError = ApiError>(
    params: FindByParamsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByParamsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindByParamsQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useSaveHook = () => {
    const save = useKRISSwaggerClient<ApiProgram[]>()

    return (apiProgram: ApiProgram[]) => {
        return save({ url: `/programs`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: apiProgram })
    }
}

export const useSaveMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSaveHook>>>, TError, { data: ApiProgram[] }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSaveHook>>>, TError, { data: ApiProgram[] }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const save = useSaveHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSaveHook>>>, { data: ApiProgram[] }> = (props) => {
        const { data } = props ?? {}

        return save(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type SaveMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSaveHook>>>>
export type SaveMutationBody = ApiProgram[]
export type SaveMutationError = ApiError

export const useSave = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSaveHook>>>, TError, { data: ApiProgram[] }, TContext>
}) => {
    const mutationOptions = useSaveMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateHook = () => {
    const update = useKRISSwaggerClient<ApiProgram[]>()

    return (apiProgram: ApiProgram[]) => {
        return update({ url: `/programs`, method: 'patch', headers: { 'Content-Type': 'application/json' }, data: apiProgram })
    }
}

export const useUpdateMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateHook>>>, TError, { data: ApiProgram[] }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateHook>>>, TError, { data: ApiProgram[] }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const update = useUpdateHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateHook>>>, { data: ApiProgram[] }> = (props) => {
        const { data } = props ?? {}

        return update(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateHook>>>>
export type UpdateMutationBody = ApiProgram[]
export type UpdateMutationError = ApiError

export const useUpdate = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateHook>>>, TError, { data: ApiProgram[] }, TContext>
}) => {
    const mutationOptions = useUpdateMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useFindByParams1Hook = () => {
    const findByParams1 = useKRISSwaggerClient<ApiProgramPartFinance[]>()

    return (params: FindByParams1Params, signal?: AbortSignal) => {
        return findByParams1({ url: `/program-part-finance`, method: 'get', params, signal })
    }
}

export const getFindByParams1QueryKey = (params: FindByParams1Params) => [`/program-part-finance`, ...(params ? [params] : [])] as const

export const useFindByParams1QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindByParams1Hook>>>, TError = ApiError>(
    params: FindByParams1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByParams1Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByParams1Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindByParams1QueryKey(params)

    const findByParams1 = useFindByParams1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindByParams1Hook>>>> = ({ signal }) => findByParams1(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindByParams1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindByParams1Hook>>>>
export type FindByParams1QueryError = ApiError

export const useFindByParams1 = <TData = Awaited<ReturnType<ReturnType<typeof useFindByParams1Hook>>>, TError = ApiError>(
    params: FindByParams1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByParams1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindByParams1QueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useSave1Hook = () => {
    const save1 = useKRISSwaggerClient<ApiProgramPartFinance[]>()

    return (apiProgramPartFinance: ApiProgramPartFinance[]) => {
        return save1({ url: `/program-part-finance`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: apiProgramPartFinance })
    }
}

export const useSave1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSave1Hook>>>, TError, { data: ApiProgramPartFinance[] }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSave1Hook>>>, TError, { data: ApiProgramPartFinance[] }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const save1 = useSave1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSave1Hook>>>, { data: ApiProgramPartFinance[] }> = (props) => {
        const { data } = props ?? {}

        return save1(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type Save1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSave1Hook>>>>
export type Save1MutationBody = ApiProgramPartFinance[]
export type Save1MutationError = ApiError

export const useSave1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSave1Hook>>>, TError, { data: ApiProgramPartFinance[] }, TContext>
}) => {
    const mutationOptions = useSave1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdate1Hook = () => {
    const update1 = useKRISSwaggerClient<ApiProgramPartFinance[]>()

    return (apiProgramPartFinance: ApiProgramPartFinance[]) => {
        return update1({
            url: `/program-part-finance`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json' },
            data: apiProgramPartFinance,
        })
    }
}

export const useUpdate1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdate1Hook>>>, TError, { data: ApiProgramPartFinance[] }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdate1Hook>>>, TError, { data: ApiProgramPartFinance[] }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const update1 = useUpdate1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdate1Hook>>>, { data: ApiProgramPartFinance[] }> = (props) => {
        const { data } = props ?? {}

        return update1(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type Update1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdate1Hook>>>>
export type Update1MutationBody = ApiProgramPartFinance[]
export type Update1MutationError = ApiError

export const useUpdate1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdate1Hook>>>, TError, { data: ApiProgramPartFinance[] }, TContext>
}) => {
    const mutationOptions = useUpdate1MutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary platformyCezProjekty
 */
export const usePlatformyCezProjektyHook = () => {
    const platformyCezProjekty = useKRISSwaggerClient<PlatformyCezProjekty200>()

    return (krisRequest: KrisRequest) => {
        return platformyCezProjekty({
            url: `/platformy-cez-projekty`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: krisRequest,
        })
    }
}

export const usePlatformyCezProjektyMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePlatformyCezProjektyHook>>>, TError, { data: KrisRequest }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePlatformyCezProjektyHook>>>, TError, { data: KrisRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const platformyCezProjekty = usePlatformyCezProjektyHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof usePlatformyCezProjektyHook>>>, { data: KrisRequest }> = (props) => {
        const { data } = props ?? {}

        return platformyCezProjekty(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type PlatformyCezProjektyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePlatformyCezProjektyHook>>>>
export type PlatformyCezProjektyMutationBody = KrisRequest
export type PlatformyCezProjektyMutationError = ApiError

/**
 * @summary platformyCezProjekty
 */
export const usePlatformyCezProjekty = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof usePlatformyCezProjektyHook>>>, TError, { data: KrisRequest }, TContext>
}) => {
    const mutationOptions = usePlatformyCezProjektyMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary ksCezProjekty
 */
export const useKsCezProjektyHook = () => {
    const ksCezProjekty = useKRISSwaggerClient<KsCezProjekty200>()

    return (krisRequest: KrisRequest) => {
        return ksCezProjekty({ url: `/ks-cez-projekty`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: krisRequest })
    }
}

export const useKsCezProjektyMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useKsCezProjektyHook>>>, TError, { data: KrisRequest }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useKsCezProjektyHook>>>, TError, { data: KrisRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const ksCezProjekty = useKsCezProjektyHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useKsCezProjektyHook>>>, { data: KrisRequest }> = (props) => {
        const { data } = props ?? {}

        return ksCezProjekty(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type KsCezProjektyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useKsCezProjektyHook>>>>
export type KsCezProjektyMutationBody = KrisRequest
export type KsCezProjektyMutationError = ApiError

/**
 * @summary ksCezProjekty
 */
export const useKsCezProjekty = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useKsCezProjektyHook>>>, TError, { data: KrisRequest }, TContext>
}) => {
    const mutationOptions = useKsCezProjektyMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @deprecated
 * @summary ksCezPo
 */
export const useKsCezPoHook = () => {
    const ksCezPo = useKRISSwaggerClient<KsCezPo200>()

    return (krisRequestNew: KrisRequestNew) => {
        return ksCezPo({ url: `/ks-cez-po`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: krisRequestNew })
    }
}

export const useKsCezPoMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useKsCezPoHook>>>, TError, { data: KrisRequestNew }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useKsCezPoHook>>>, TError, { data: KrisRequestNew }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const ksCezPo = useKsCezPoHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useKsCezPoHook>>>, { data: KrisRequestNew }> = (props) => {
        const { data } = props ?? {}

        return ksCezPo(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type KsCezPoMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useKsCezPoHook>>>>
export type KsCezPoMutationBody = KrisRequestNew
export type KsCezPoMutationError = ApiError

/**
 * @deprecated
 * @summary ksCezPo
 */
export const useKsCezPo = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useKsCezPoHook>>>, TError, { data: KrisRequestNew }, TContext>
}) => {
    const mutationOptions = useKsCezPoMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary koncoveSluzbyCezIsvs
 */
export const useKoncoveSluzbyCezIsvsHook = () => {
    const koncoveSluzbyCezIsvs = useKRISSwaggerClient<KoncoveSluzbyCezIsvs200>()

    return (krisRequest: KrisRequest) => {
        return koncoveSluzbyCezIsvs({ url: `/ks-cez-isvs`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: krisRequest })
    }
}

export const useKoncoveSluzbyCezIsvsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useKoncoveSluzbyCezIsvsHook>>>, TError, { data: KrisRequest }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useKoncoveSluzbyCezIsvsHook>>>, TError, { data: KrisRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const koncoveSluzbyCezIsvs = useKoncoveSluzbyCezIsvsHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useKoncoveSluzbyCezIsvsHook>>>, { data: KrisRequest }> = (props) => {
        const { data } = props ?? {}

        return koncoveSluzbyCezIsvs(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type KoncoveSluzbyCezIsvsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useKoncoveSluzbyCezIsvsHook>>>>
export type KoncoveSluzbyCezIsvsMutationBody = KrisRequest
export type KoncoveSluzbyCezIsvsMutationError = ApiError

/**
 * @summary koncoveSluzbyCezIsvs
 */
export const useKoncoveSluzbyCezIsvs = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useKoncoveSluzbyCezIsvsHook>>>, TError, { data: KrisRequest }, TContext>
}) => {
    const mutationOptions = useKoncoveSluzbyCezIsvsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useAddResponseHook = () => {
    const addResponse = useKRISSwaggerClient<void>()

    return (kris: string, uuid: string, type: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY', noteUi: NoteUi) => {
        return addResponse({
            url: `/kris-to-be/kris/${kris}/${uuid}/${type}/response`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: noteUi,
        })
    }
}

export const useAddResponseMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddResponseHook>>>,
        TError,
        { kris: string; uuid: string; type: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'; data: NoteUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddResponseHook>>>,
    TError,
    { kris: string; uuid: string; type: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'; data: NoteUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const addResponse = useAddResponseHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useAddResponseHook>>>,
        { kris: string; uuid: string; type: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'; data: NoteUi }
    > = (props) => {
        const { kris, uuid, type, data } = props ?? {}

        return addResponse(kris, uuid, type, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type AddResponseMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddResponseHook>>>>
export type AddResponseMutationBody = NoteUi
export type AddResponseMutationError = ApiError

export const useAddResponse = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddResponseHook>>>,
        TError,
        { kris: string; uuid: string; type: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'; data: NoteUi },
        TContext
    >
}) => {
    const mutationOptions = useAddResponseMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useAddEvaluationHook = () => {
    const addEvaluation = useKRISSwaggerClient<void>()

    return (kris: string, uuid: string, type: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY', newNoteUi: NewNoteUi) => {
        return addEvaluation({
            url: `/kris-to-be/kris/${kris}/${uuid}/${type}/evaluation`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: newNoteUi,
        })
    }
}

export const useAddEvaluationMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddEvaluationHook>>>,
        TError,
        { kris: string; uuid: string; type: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'; data: NewNoteUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddEvaluationHook>>>,
    TError,
    { kris: string; uuid: string; type: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'; data: NewNoteUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const addEvaluation = useAddEvaluationHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useAddEvaluationHook>>>,
        { kris: string; uuid: string; type: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'; data: NewNoteUi }
    > = (props) => {
        const { kris, uuid, type, data } = props ?? {}

        return addEvaluation(kris, uuid, type, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type AddEvaluationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddEvaluationHook>>>>
export type AddEvaluationMutationBody = NewNoteUi
export type AddEvaluationMutationError = ApiError

export const useAddEvaluation = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddEvaluationHook>>>,
        TError,
        { kris: string; uuid: string; type: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'; data: NewNoteUi },
        TContext
    >
}) => {
    const mutationOptions = useAddEvaluationMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExportNoteResponseHook = () => {
    const exportNoteResponse = useKRISSwaggerClient<ExportNoteResponse>()

    return (
        kris: string,
        type: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY',
        exportFilter: ExportFilter,
        params: ExportNoteResponseParams,
    ) => {
        return exportNoteResponse({
            url: `/kris-to-be/kris/${kris}/${type}/export`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: exportFilter,
            params,
        })
    }
}

export const useExportNoteResponseMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportNoteResponseHook>>>,
        TError,
        {
            kris: string
            type: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'
            data: ExportFilter
            params: ExportNoteResponseParams
        },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportNoteResponseHook>>>,
    TError,
    {
        kris: string
        type: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'
        data: ExportFilter
        params: ExportNoteResponseParams
    },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const exportNoteResponse = useExportNoteResponseHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExportNoteResponseHook>>>,
        {
            kris: string
            type: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'
            data: ExportFilter
            params: ExportNoteResponseParams
        }
    > = (props) => {
        const { kris, type, data, params } = props ?? {}

        return exportNoteResponse(kris, type, data, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type ExportNoteResponseMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExportNoteResponseHook>>>>
export type ExportNoteResponseMutationBody = ExportFilter
export type ExportNoteResponseMutationError = ApiError

export const useExportNoteResponse = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportNoteResponseHook>>>,
        TError,
        {
            kris: string
            type: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'
            data: ExportFilter
            params: ExportNoteResponseParams
        },
        TContext
    >
}) => {
    const mutationOptions = useExportNoteResponseMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useAddSubItemResponseHook = () => {
    const addSubItemResponse = useKRISSwaggerClient<void>()

    return (
        kris: string,
        parentUuid: string,
        parentType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY',
        childType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY',
        subItemsNotesUi: SubItemsNotesUi,
    ) => {
        return addSubItemResponse({
            url: `/kris-to-be/kris/${kris}/${parentUuid}/${parentType}/${childType}/response`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: subItemsNotesUi,
        })
    }
}

export const useAddSubItemResponseMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddSubItemResponseHook>>>,
        TError,
        {
            kris: string
            parentUuid: string
            parentType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'
            childType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'
            data: SubItemsNotesUi
        },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddSubItemResponseHook>>>,
    TError,
    {
        kris: string
        parentUuid: string
        parentType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'
        childType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'
        data: SubItemsNotesUi
    },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const addSubItemResponse = useAddSubItemResponseHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useAddSubItemResponseHook>>>,
        {
            kris: string
            parentUuid: string
            parentType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'
            childType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'
            data: SubItemsNotesUi
        }
    > = (props) => {
        const { kris, parentUuid, parentType, childType, data } = props ?? {}

        return addSubItemResponse(kris, parentUuid, parentType, childType, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type AddSubItemResponseMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddSubItemResponseHook>>>>
export type AddSubItemResponseMutationBody = SubItemsNotesUi
export type AddSubItemResponseMutationError = ApiError

export const useAddSubItemResponse = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddSubItemResponseHook>>>,
        TError,
        {
            kris: string
            parentUuid: string
            parentType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'
            childType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'
            data: SubItemsNotesUi
        },
        TContext
    >
}) => {
    const mutationOptions = useAddSubItemResponseMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useAddSubItemEvaluationsHook = () => {
    const addSubItemEvaluations = useKRISSwaggerClient<void>()

    return (
        kris: string,
        parentUuid: string,
        parentType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY',
        childType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY',
        subItemsNewNotesUi: SubItemsNewNotesUi,
    ) => {
        return addSubItemEvaluations({
            url: `/kris-to-be/kris/${kris}/${parentUuid}/${parentType}/${childType}/evaluation`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: subItemsNewNotesUi,
        })
    }
}

export const useAddSubItemEvaluationsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddSubItemEvaluationsHook>>>,
        TError,
        {
            kris: string
            parentUuid: string
            parentType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'
            childType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'
            data: SubItemsNewNotesUi
        },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddSubItemEvaluationsHook>>>,
    TError,
    {
        kris: string
        parentUuid: string
        parentType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'
        childType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'
        data: SubItemsNewNotesUi
    },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const addSubItemEvaluations = useAddSubItemEvaluationsHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useAddSubItemEvaluationsHook>>>,
        {
            kris: string
            parentUuid: string
            parentType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'
            childType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'
            data: SubItemsNewNotesUi
        }
    > = (props) => {
        const { kris, parentUuid, parentType, childType, data } = props ?? {}

        return addSubItemEvaluations(kris, parentUuid, parentType, childType, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type AddSubItemEvaluationsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddSubItemEvaluationsHook>>>>
export type AddSubItemEvaluationsMutationBody = SubItemsNewNotesUi
export type AddSubItemEvaluationsMutationError = ApiError

export const useAddSubItemEvaluations = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddSubItemEvaluationsHook>>>,
        TError,
        {
            kris: string
            parentUuid: string
            parentType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'
            childType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY'
            data: SubItemsNewNotesUi
        },
        TContext
    >
}) => {
    const mutationOptions = useAddSubItemEvaluationsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStoreRequestHook = () => {
    const storeRequest = useKRISSwaggerClient<RequestIdUi>()

    return (graphRequestUi: GraphRequestUi) => {
        return storeRequest({ url: `/kris-to-be/kris/store`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: graphRequestUi })
    }
}

export const useStoreRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreRequestHook>>>, TError, { data: GraphRequestUi }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreRequestHook>>>, TError, { data: GraphRequestUi }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const storeRequest = useStoreRequestHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStoreRequestHook>>>, { data: GraphRequestUi }> = (props) => {
        const { data } = props ?? {}

        return storeRequest(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type StoreRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStoreRequestHook>>>>
export type StoreRequestMutationBody = GraphRequestUi
export type StoreRequestMutationError = ApiError

export const useStoreRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStoreRequestHook>>>, TError, { data: GraphRequestUi }, TContext>
}) => {
    const mutationOptions = useStoreRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useRunKontrolaResultHook = () => {
    const runKontrolaResult = useKRISSwaggerClient<void>()

    return (params: RunKontrolaResultParams) => {
        return runKontrolaResult({ url: `/kontrola/run`, method: 'post', params })
    }
}

export const useRunKontrolaResultMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRunKontrolaResultHook>>>,
        TError,
        { params: RunKontrolaResultParams },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRunKontrolaResultHook>>>, TError, { params: RunKontrolaResultParams }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const runKontrolaResult = useRunKontrolaResultHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRunKontrolaResultHook>>>, { params: RunKontrolaResultParams }> = (
        props,
    ) => {
        const { params } = props ?? {}

        return runKontrolaResult(params)
    }

    return { mutationFn, ...mutationOptions }
}

export type RunKontrolaResultMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRunKontrolaResultHook>>>>

export type RunKontrolaResultMutationError = ApiError

export const useRunKontrolaResult = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRunKontrolaResultHook>>>,
        TError,
        { params: RunKontrolaResultParams },
        TContext
    >
}) => {
    const mutationOptions = useRunKontrolaResultMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary isvsCezProjekty
 */
export const useIsvsCezProjektyHook = () => {
    const isvsCezProjekty = useKRISSwaggerClient<IsvsCezProjekty200>()

    return (krisRequest: KrisRequest) => {
        return isvsCezProjekty({ url: `/isvs-cez-projekty`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: krisRequest })
    }
}

export const useIsvsCezProjektyMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsvsCezProjektyHook>>>, TError, { data: KrisRequest }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsvsCezProjektyHook>>>, TError, { data: KrisRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const isvsCezProjekty = useIsvsCezProjektyHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useIsvsCezProjektyHook>>>, { data: KrisRequest }> = (props) => {
        const { data } = props ?? {}

        return isvsCezProjekty(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type IsvsCezProjektyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useIsvsCezProjektyHook>>>>
export type IsvsCezProjektyMutationBody = KrisRequest
export type IsvsCezProjektyMutationError = ApiError

/**
 * @summary isvsCezProjekty
 */
export const useIsvsCezProjekty = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsvsCezProjektyHook>>>, TError, { data: KrisRequest }, TContext>
}) => {
    const mutationOptions = useIsvsCezProjektyMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @deprecated
 * @summary isvsCezPo
 */
export const useIsvsCezPoHook = () => {
    const isvsCezPo = useKRISSwaggerClient<IsvsCezPo200>()

    return (krisRequestNew: KrisRequestNew) => {
        return isvsCezPo({ url: `/isvs-cez-po`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: krisRequestNew })
    }
}

export const useIsvsCezPoMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsvsCezPoHook>>>, TError, { data: KrisRequestNew }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsvsCezPoHook>>>, TError, { data: KrisRequestNew }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const isvsCezPo = useIsvsCezPoHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useIsvsCezPoHook>>>, { data: KrisRequestNew }> = (props) => {
        const { data } = props ?? {}

        return isvsCezPo(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type IsvsCezPoMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useIsvsCezPoHook>>>>
export type IsvsCezPoMutationBody = KrisRequestNew
export type IsvsCezPoMutationError = ApiError

/**
 * @deprecated
 * @summary isvsCezPo
 */
export const useIsvsCezPo = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsvsCezPoHook>>>, TError, { data: KrisRequestNew }, TContext>
}) => {
    const mutationOptions = useIsvsCezPoMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useInvalidateConfigurationItemBiznisMdulesHook = () => {
    const invalidateConfigurationItemBiznisMdules = useKRISSwaggerClient<RequestIdUi>()

    return (configurationItemInvalidateUi: ConfigurationItemInvalidateUi) => {
        return invalidateConfigurationItemBiznisMdules({
            url: `/invalidate/ci`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: configurationItemInvalidateUi,
        })
    }
}

export const useInvalidateConfigurationItemBiznisMdulesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useInvalidateConfigurationItemBiznisMdulesHook>>>,
        TError,
        { data: ConfigurationItemInvalidateUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useInvalidateConfigurationItemBiznisMdulesHook>>>,
    TError,
    { data: ConfigurationItemInvalidateUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const invalidateConfigurationItemBiznisMdules = useInvalidateConfigurationItemBiznisMdulesHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useInvalidateConfigurationItemBiznisMdulesHook>>>,
        { data: ConfigurationItemInvalidateUi }
    > = (props) => {
        const { data } = props ?? {}

        return invalidateConfigurationItemBiznisMdules(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type InvalidateConfigurationItemBiznisMdulesMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useInvalidateConfigurationItemBiznisMdulesHook>>>
>
export type InvalidateConfigurationItemBiznisMdulesMutationBody = ConfigurationItemInvalidateUi
export type InvalidateConfigurationItemBiznisMdulesMutationError = ApiError

export const useInvalidateConfigurationItemBiznisMdules = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useInvalidateConfigurationItemBiznisMdulesHook>>>,
        TError,
        { data: ConfigurationItemInvalidateUi },
        TContext
    >
}) => {
    const mutationOptions = useInvalidateConfigurationItemBiznisMdulesMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStartTaskHook = () => {
    const startTask = useKRISSwaggerClient<FillRefAttributesTask>()

    return (id: string) => {
        return startTask({ url: `/fillRefAttributes/startTask/${id}`, method: 'post' })
    }
}

export const useStartTaskMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStartTaskHook>>>, TError, { id: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStartTaskHook>>>, TError, { id: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const startTask = useStartTaskHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStartTaskHook>>>, { id: string }> = (props) => {
        const { id } = props ?? {}

        return startTask(id)
    }

    return { mutationFn, ...mutationOptions }
}

export type StartTaskMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStartTaskHook>>>>

export type StartTaskMutationError = ApiError

export const useStartTask = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStartTaskHook>>>, TError, { id: string }, TContext>
}) => {
    const mutationOptions = useStartTaskMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useFindByDocumentGroupIdHook = () => {
    const findByDocumentGroupId = useKRISSwaggerClient<Document[]>()

    return (params: FindByDocumentGroupIdParams, signal?: AbortSignal) => {
        return findByDocumentGroupId({ url: `/documents`, method: 'get', params, signal })
    }
}

export const getFindByDocumentGroupIdQueryKey = (params: FindByDocumentGroupIdParams) => [`/documents`, ...(params ? [params] : [])] as const

export const useFindByDocumentGroupIdQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindByDocumentGroupIdHook>>>, TError = ApiError>(
    params: FindByDocumentGroupIdParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByDocumentGroupIdHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByDocumentGroupIdHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindByDocumentGroupIdQueryKey(params)

    const findByDocumentGroupId = useFindByDocumentGroupIdHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindByDocumentGroupIdHook>>>> = ({ signal }) =>
        findByDocumentGroupId(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindByDocumentGroupIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindByDocumentGroupIdHook>>>>
export type FindByDocumentGroupIdQueryError = ApiError

export const useFindByDocumentGroupId = <TData = Awaited<ReturnType<ReturnType<typeof useFindByDocumentGroupIdHook>>>, TError = ApiError>(
    params: FindByDocumentGroupIdParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByDocumentGroupIdHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindByDocumentGroupIdQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useSave2Hook = () => {
    const save2 = useKRISSwaggerClient<Document>()

    return (document: Document) => {
        return save2({ url: `/documents`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: document })
    }
}

export const useSave2MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSave2Hook>>>, TError, { data: Document }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSave2Hook>>>, TError, { data: Document }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const save2 = useSave2Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSave2Hook>>>, { data: Document }> = (props) => {
        const { data } = props ?? {}

        return save2(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type Save2MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSave2Hook>>>>
export type Save2MutationBody = Document
export type Save2MutationError = ApiError

export const useSave2 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSave2Hook>>>, TError, { data: Document }, TContext>
}) => {
    const mutationOptions = useSave2MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdate2Hook = () => {
    const update2 = useKRISSwaggerClient<Document>()

    return (document: Document) => {
        return update2({ url: `/documents`, method: 'patch', headers: { 'Content-Type': 'application/json' }, data: document })
    }
}

export const useUpdate2MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdate2Hook>>>, TError, { data: Document }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdate2Hook>>>, TError, { data: Document }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const update2 = useUpdate2Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdate2Hook>>>, { data: Document }> = (props) => {
        const { data } = props ?? {}

        return update2(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type Update2MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdate2Hook>>>>
export type Update2MutationBody = Document
export type Update2MutationError = ApiError

export const useUpdate2 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdate2Hook>>>, TError, { data: Document }, TContext>
}) => {
    const mutationOptions = useUpdate2MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useFindByStateHook = () => {
    const findByState = useKRISSwaggerClient<DocumentGroup[]>()

    return (params: FindByStateParams, signal?: AbortSignal) => {
        return findByState({ url: `/document-groups`, method: 'get', params, signal })
    }
}

export const getFindByStateQueryKey = (params: FindByStateParams) => [`/document-groups`, ...(params ? [params] : [])] as const

export const useFindByStateQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindByStateHook>>>, TError = ApiError>(
    params: FindByStateParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByStateHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByStateHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindByStateQueryKey(params)

    const findByState = useFindByStateHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindByStateHook>>>> = ({ signal }) => findByState(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindByStateQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindByStateHook>>>>
export type FindByStateQueryError = ApiError

export const useFindByState = <TData = Awaited<ReturnType<ReturnType<typeof useFindByStateHook>>>, TError = ApiError>(
    params: FindByStateParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByStateHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindByStateQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useSave3Hook = () => {
    const save3 = useKRISSwaggerClient<DocumentGroup>()

    return (documentGroup: DocumentGroup) => {
        return save3({ url: `/document-groups`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: documentGroup })
    }
}

export const useSave3MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSave3Hook>>>, TError, { data: DocumentGroup }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSave3Hook>>>, TError, { data: DocumentGroup }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const save3 = useSave3Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSave3Hook>>>, { data: DocumentGroup }> = (props) => {
        const { data } = props ?? {}

        return save3(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type Save3MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSave3Hook>>>>
export type Save3MutationBody = DocumentGroup
export type Save3MutationError = ApiError

export const useSave3 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSave3Hook>>>, TError, { data: DocumentGroup }, TContext>
}) => {
    const mutationOptions = useSave3MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdate3Hook = () => {
    const update3 = useKRISSwaggerClient<DocumentGroup>()

    return (documentGroup: DocumentGroup) => {
        return update3({ url: `/document-groups`, method: 'patch', headers: { 'Content-Type': 'application/json' }, data: documentGroup })
    }
}

export const useUpdate3MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdate3Hook>>>, TError, { data: DocumentGroup }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdate3Hook>>>, TError, { data: DocumentGroup }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const update3 = useUpdate3Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdate3Hook>>>, { data: DocumentGroup }> = (props) => {
        const { data } = props ?? {}

        return update3(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type Update3MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdate3Hook>>>>
export type Update3MutationBody = DocumentGroup
export type Update3MutationError = ApiError

export const useUpdate3 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdate3Hook>>>, TError, { data: DocumentGroup }, TContext>
}) => {
    const mutationOptions = useUpdate3MutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary bazyDatCezProjekty
 */
export const useBazyDatCezProjektyHook = () => {
    const bazyDatCezProjekty = useKRISSwaggerClient<BazyDatCezProjekty200>()

    return (krisRequest: KrisRequest) => {
        return bazyDatCezProjekty({
            url: `/bazy-dat-cez-projekty`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: krisRequest,
        })
    }
}

export const useBazyDatCezProjektyMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useBazyDatCezProjektyHook>>>, TError, { data: KrisRequest }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useBazyDatCezProjektyHook>>>, TError, { data: KrisRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const bazyDatCezProjekty = useBazyDatCezProjektyHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useBazyDatCezProjektyHook>>>, { data: KrisRequest }> = (props) => {
        const { data } = props ?? {}

        return bazyDatCezProjekty(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type BazyDatCezProjektyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useBazyDatCezProjektyHook>>>>
export type BazyDatCezProjektyMutationBody = KrisRequest
export type BazyDatCezProjektyMutationError = ApiError

/**
 * @summary bazyDatCezProjekty
 */
export const useBazyDatCezProjekty = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useBazyDatCezProjektyHook>>>, TError, { data: KrisRequest }, TContext>
}) => {
    const mutationOptions = useBazyDatCezProjektyMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary bazyDatCezIsvs
 */
export const useBazyDatCezIsvsHook = () => {
    const bazyDatCezIsvs = useKRISSwaggerClient<BazyDatCezIsvs200>()

    return (krisRequest: KrisRequest) => {
        return bazyDatCezIsvs({ url: `/bazy-dat-cez-isvs`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: krisRequest })
    }
}

export const useBazyDatCezIsvsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useBazyDatCezIsvsHook>>>, TError, { data: KrisRequest }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useBazyDatCezIsvsHook>>>, TError, { data: KrisRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const bazyDatCezIsvs = useBazyDatCezIsvsHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useBazyDatCezIsvsHook>>>, { data: KrisRequest }> = (props) => {
        const { data } = props ?? {}

        return bazyDatCezIsvs(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type BazyDatCezIsvsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useBazyDatCezIsvsHook>>>>
export type BazyDatCezIsvsMutationBody = KrisRequest
export type BazyDatCezIsvsMutationError = ApiError

/**
 * @summary bazyDatCezIsvs
 */
export const useBazyDatCezIsvs = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useBazyDatCezIsvsHook>>>, TError, { data: KrisRequest }, TContext>
}) => {
    const mutationOptions = useBazyDatCezIsvsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary asCezProjekty
 */
export const useAsCezProjektyHook = () => {
    const asCezProjekty = useKRISSwaggerClient<AsCezProjekty200>()

    return (krisRequest: KrisRequest) => {
        return asCezProjekty({ url: `/as-cez-projekty`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: krisRequest })
    }
}

export const useAsCezProjektyMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAsCezProjektyHook>>>, TError, { data: KrisRequest }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAsCezProjektyHook>>>, TError, { data: KrisRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const asCezProjekty = useAsCezProjektyHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAsCezProjektyHook>>>, { data: KrisRequest }> = (props) => {
        const { data } = props ?? {}

        return asCezProjekty(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type AsCezProjektyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAsCezProjektyHook>>>>
export type AsCezProjektyMutationBody = KrisRequest
export type AsCezProjektyMutationError = ApiError

/**
 * @summary asCezProjekty
 */
export const useAsCezProjekty = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAsCezProjektyHook>>>, TError, { data: KrisRequest }, TContext>
}) => {
    const mutationOptions = useAsCezProjektyMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary asCezIsvs
 */
export const useAplikacneSluzbyCezIsvsHook = () => {
    const aplikacneSluzbyCezIsvs = useKRISSwaggerClient<AplikacneSluzbyCezIsvs200>()

    return (krisRequest: KrisRequest) => {
        return aplikacneSluzbyCezIsvs({ url: `/as-cez-isvs`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: krisRequest })
    }
}

export const useAplikacneSluzbyCezIsvsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAplikacneSluzbyCezIsvsHook>>>, TError, { data: KrisRequest }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAplikacneSluzbyCezIsvsHook>>>, TError, { data: KrisRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const aplikacneSluzbyCezIsvs = useAplikacneSluzbyCezIsvsHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAplikacneSluzbyCezIsvsHook>>>, { data: KrisRequest }> = (props) => {
        const { data } = props ?? {}

        return aplikacneSluzbyCezIsvs(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type AplikacneSluzbyCezIsvsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAplikacneSluzbyCezIsvsHook>>>>
export type AplikacneSluzbyCezIsvsMutationBody = KrisRequest
export type AplikacneSluzbyCezIsvsMutationError = ApiError

/**
 * @summary asCezIsvs
 */
export const useAplikacneSluzbyCezIsvs = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAplikacneSluzbyCezIsvsHook>>>, TError, { data: KrisRequest }, TContext>
}) => {
    const mutationOptions = useAplikacneSluzbyCezIsvsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary agendyCezProjekty
 */
export const useAgendyCezProjektyHook = () => {
    const agendyCezProjekty = useKRISSwaggerClient<AgendyCezProjekty200>()

    return (krisRequest: KrisRequest) => {
        return agendyCezProjekty({ url: `/agendy-cez-projekty`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: krisRequest })
    }
}

export const useAgendyCezProjektyMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAgendyCezProjektyHook>>>, TError, { data: KrisRequest }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAgendyCezProjektyHook>>>, TError, { data: KrisRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const agendyCezProjekty = useAgendyCezProjektyHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAgendyCezProjektyHook>>>, { data: KrisRequest }> = (props) => {
        const { data } = props ?? {}

        return agendyCezProjekty(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type AgendyCezProjektyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAgendyCezProjektyHook>>>>
export type AgendyCezProjektyMutationBody = KrisRequest
export type AgendyCezProjektyMutationError = ApiError

/**
 * @summary agendyCezProjekty
 */
export const useAgendyCezProjekty = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAgendyCezProjektyHook>>>, TError, { data: KrisRequest }, TContext>
}) => {
    const mutationOptions = useAgendyCezProjektyMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary agendaCezPo
 */
export const useAgendaCezPoHook = () => {
    const agendaCezPo = useKRISSwaggerClient<AgendaCezPo200>()

    return (krisRequestNew: KrisRequestNew) => {
        return agendaCezPo({ url: `/agenda-cez-po`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: krisRequestNew })
    }
}

export const useAgendaCezPoMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAgendaCezPoHook>>>, TError, { data: KrisRequestNew }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAgendaCezPoHook>>>, TError, { data: KrisRequestNew }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const agendaCezPo = useAgendaCezPoHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAgendaCezPoHook>>>, { data: KrisRequestNew }> = (props) => {
        const { data } = props ?? {}

        return agendaCezPo(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type AgendaCezPoMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAgendaCezPoHook>>>>
export type AgendaCezPoMutationBody = KrisRequestNew
export type AgendaCezPoMutationError = ApiError

/**
 * @summary agendaCezPo
 */
export const useAgendaCezPo = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAgendaCezPoHook>>>, TError, { data: KrisRequestNew }, TContext>
}) => {
    const mutationOptions = useAgendaCezPoMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useCheckOperationPermissionByMetaisCodeHook = () => {
    const checkOperationPermissionByMetaisCode = useKRISSwaggerClient<ApiPermissionResult>()

    return (suMetaisCode: string, params: CheckOperationPermissionByMetaisCodeParams, signal?: AbortSignal) => {
        return checkOperationPermissionByMetaisCode({ url: `/su/metaiscode/${suMetaisCode}/permissions`, method: 'get', params, signal })
    }
}

export const getCheckOperationPermissionByMetaisCodeQueryKey = (suMetaisCode: string, params: CheckOperationPermissionByMetaisCodeParams) =>
    [`/su/metaiscode/${suMetaisCode}/permissions`, ...(params ? [params] : [])] as const

export const useCheckOperationPermissionByMetaisCodeQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useCheckOperationPermissionByMetaisCodeHook>>>,
    TError = ApiError,
>(
    suMetaisCode: string,
    params: CheckOperationPermissionByMetaisCodeParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckOperationPermissionByMetaisCodeHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckOperationPermissionByMetaisCodeHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getCheckOperationPermissionByMetaisCodeQueryKey(suMetaisCode, params)

    const checkOperationPermissionByMetaisCode = useCheckOperationPermissionByMetaisCodeHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useCheckOperationPermissionByMetaisCodeHook>>>> = ({ signal }) =>
        checkOperationPermissionByMetaisCode(suMetaisCode, params, signal)

    return { queryKey, queryFn, enabled: !!suMetaisCode, ...queryOptions }
}

export type CheckOperationPermissionByMetaisCodeQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useCheckOperationPermissionByMetaisCodeHook>>>
>
export type CheckOperationPermissionByMetaisCodeQueryError = ApiError

export const useCheckOperationPermissionByMetaisCode = <
    TData = Awaited<ReturnType<ReturnType<typeof useCheckOperationPermissionByMetaisCodeHook>>>,
    TError = ApiError,
>(
    suMetaisCode: string,
    params: CheckOperationPermissionByMetaisCodeParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckOperationPermissionByMetaisCodeHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useCheckOperationPermissionByMetaisCodeQueryOptions(suMetaisCode, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetNextStatesHook = () => {
    const getNextStates = useKRISSwaggerClient<string[]>()

    return (uuid: string, signal?: AbortSignal) => {
        return getNextStates({ url: `/projects/${uuid}/next-state`, method: 'get', signal })
    }
}

export const getGetNextStatesQueryKey = (uuid: string) => [`/projects/${uuid}/next-state`] as const

export const useGetNextStatesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetNextStatesHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNextStatesHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNextStatesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetNextStatesQueryKey(uuid)

    const getNextStates = useGetNextStatesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetNextStatesHook>>>> = ({ signal }) => getNextStates(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type GetNextStatesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetNextStatesHook>>>>
export type GetNextStatesQueryError = ApiError

export const useGetNextStates = <TData = Awaited<ReturnType<ReturnType<typeof useGetNextStatesHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetNextStatesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetNextStatesQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useHasPermissionHook = () => {
    const hasPermission = useKRISSwaggerClient<boolean>()

    return (uuid: string, params: HasPermissionParams, signal?: AbortSignal) => {
        return hasPermission({ url: `/projects/${uuid}/has-permission`, method: 'get', params, signal })
    }
}

export const getHasPermissionQueryKey = (uuid: string, params: HasPermissionParams) =>
    [`/projects/${uuid}/has-permission`, ...(params ? [params] : [])] as const

export const useHasPermissionQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useHasPermissionHook>>>, TError = ApiError>(
    uuid: string,
    params: HasPermissionParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useHasPermissionHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useHasPermissionHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getHasPermissionQueryKey(uuid, params)

    const hasPermission = useHasPermissionHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useHasPermissionHook>>>> = ({ signal }) => hasPermission(uuid, params, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type HasPermissionQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useHasPermissionHook>>>>
export type HasPermissionQueryError = ApiError

export const useHasPermission = <TData = Awaited<ReturnType<ReturnType<typeof useHasPermissionHook>>>, TError = ApiError>(
    uuid: string,
    params: HasPermissionParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useHasPermissionHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useHasPermissionQueryOptions(uuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetPhasesHook = () => {
    const getPhases = useKRISSwaggerClient<Phase[]>()

    return (projectManagementMethod: string, signal?: AbortSignal) => {
        return getPhases({ url: `/projects/phases/${projectManagementMethod}`, method: 'get', signal })
    }
}

export const getGetPhasesQueryKey = (projectManagementMethod: string) => [`/projects/phases/${projectManagementMethod}`] as const

export const useGetPhasesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetPhasesHook>>>, TError = ApiError>(
    projectManagementMethod: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPhasesHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPhasesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetPhasesQueryKey(projectManagementMethod)

    const getPhases = useGetPhasesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPhasesHook>>>> = ({ signal }) =>
        getPhases(projectManagementMethod, signal)

    return { queryKey, queryFn, enabled: !!projectManagementMethod, ...queryOptions }
}

export type GetPhasesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetPhasesHook>>>>
export type GetPhasesQueryError = ApiError

export const useGetPhases = <TData = Awaited<ReturnType<ReturnType<typeof useGetPhasesHook>>>, TError = ApiError>(
    projectManagementMethod: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPhasesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetPhasesQueryOptions(projectManagementMethod, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindByIdHook = () => {
    const findById = useKRISSwaggerClient<ApiProgram>()

    return (id: number, signal?: AbortSignal) => {
        return findById({ url: `/programs/${id}`, method: 'get', signal })
    }
}

export const getFindByIdQueryKey = (id: number) => [`/programs/${id}`] as const

export const useFindByIdQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindByIdHook>>>, TError = ApiError>(
    id: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByIdHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByIdHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindByIdQueryKey(id)

    const findById = useFindByIdHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindByIdHook>>>> = ({ signal }) => findById(id, signal)

    return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type FindByIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindByIdHook>>>>
export type FindByIdQueryError = ApiError

export const useFindById = <TData = Awaited<ReturnType<ReturnType<typeof useFindByIdHook>>>, TError = ApiError>(
    id: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByIdHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindByIdQueryOptions(id, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetCisHook = () => {
    const getCis = useKRISSwaggerClient<ApiProgramCi[]>()

    return (signal?: AbortSignal) => {
        return getCis({ url: `/programs/ci`, method: 'get', signal })
    }
}

export const getGetCisQueryKey = () => [`/programs/ci`] as const

export const useGetCisQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetCisHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCisHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCisHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetCisQueryKey()

    const getCis = useGetCisHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCisHook>>>> = ({ signal }) => getCis(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetCisQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCisHook>>>>
export type GetCisQueryError = ApiError

export const useGetCis = <TData = Awaited<ReturnType<ReturnType<typeof useGetCisHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCisHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetCisQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindById1Hook = () => {
    const findById1 = useKRISSwaggerClient<ApiProgramPartFinance>()

    return (id: number, signal?: AbortSignal) => {
        return findById1({ url: `/program-part-finance/${id}`, method: 'get', signal })
    }
}

export const getFindById1QueryKey = (id: number) => [`/program-part-finance/${id}`] as const

export const useFindById1QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindById1Hook>>>, TError = ApiError>(
    id: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindById1Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindById1Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindById1QueryKey(id)

    const findById1 = useFindById1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindById1Hook>>>> = ({ signal }) => findById1(id, signal)

    return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type FindById1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindById1Hook>>>>
export type FindById1QueryError = ApiError

export const useFindById1 = <TData = Awaited<ReturnType<ReturnType<typeof useFindById1Hook>>>, TError = ApiError>(
    id: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindById1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindById1QueryOptions(id, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetRightsHook = () => {
    const getRights = useKRISSwaggerClient<KrisToBeRights>()

    return (uuid: string, signal?: AbortSignal) => {
        return getRights({ url: `/kris-to-be/rights/${uuid}`, method: 'get', signal })
    }
}

export const getGetRightsQueryKey = (uuid: string) => [`/kris-to-be/rights/${uuid}`] as const

export const useGetRightsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetRightsHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRightsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRightsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetRightsQueryKey(uuid)

    const getRights = useGetRightsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetRightsHook>>>> = ({ signal }) => getRights(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type GetRightsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetRightsHook>>>>
export type GetRightsQueryError = ApiError

export const useGetRights = <TData = Awaited<ReturnType<ReturnType<typeof useGetRightsHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRightsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetRightsQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetProjectsHook = () => {
    const getProjects = useKRISSwaggerClient<PageKrisToBeProject>()

    return (uuid: string, params: GetProjectsParams, signal?: AbortSignal) => {
        return getProjects({ url: `/kris-to-be/project/${uuid}`, method: 'get', params, signal })
    }
}

export const getGetProjectsQueryKey = (uuid: string, params: GetProjectsParams) =>
    [`/kris-to-be/project/${uuid}`, ...(params ? [params] : [])] as const

export const useGetProjectsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectsHook>>>, TError = ApiError>(
    uuid: string,
    params: GetProjectsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetProjectsQueryKey(uuid, params)

    const getProjects = useGetProjectsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProjectsHook>>>> = ({ signal }) => getProjects(uuid, params, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type GetProjectsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetProjectsHook>>>>
export type GetProjectsQueryError = ApiError

export const useGetProjects = <TData = Awaited<ReturnType<ReturnType<typeof useGetProjectsHook>>>, TError = ApiError>(
    uuid: string,
    params: GetProjectsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProjectsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetProjectsQueryOptions(uuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetKsHook = () => {
    const getKs = useKRISSwaggerClient<PageKrisToBeKs>()

    return (uuid: string, params: GetKsParams, signal?: AbortSignal) => {
        return getKs({ url: `/kris-to-be/ks/${uuid}`, method: 'get', params, signal })
    }
}

export const getGetKsQueryKey = (uuid: string, params: GetKsParams) => [`/kris-to-be/ks/${uuid}`, ...(params ? [params] : [])] as const

export const useGetKsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetKsHook>>>, TError = ApiError>(
    uuid: string,
    params: GetKsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetKsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetKsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetKsQueryKey(uuid, params)

    const getKs = useGetKsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetKsHook>>>> = ({ signal }) => getKs(uuid, params, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type GetKsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetKsHook>>>>
export type GetKsQueryError = ApiError

export const useGetKs = <TData = Awaited<ReturnType<ReturnType<typeof useGetKsHook>>>, TError = ApiError>(
    uuid: string,
    params: GetKsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetKsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetKsQueryOptions(uuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetKrisHook = () => {
    const getKris = useKRISSwaggerClient<KrisUi>()

    return (uuid: string, signal?: AbortSignal) => {
        return getKris({ url: `/kris-to-be/kris/${uuid}`, method: 'get', signal })
    }
}

export const getGetKrisQueryKey = (uuid: string) => [`/kris-to-be/kris/${uuid}`] as const

export const useGetKrisQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetKrisHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetKrisHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetKrisHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetKrisQueryKey(uuid)

    const getKris = useGetKrisHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetKrisHook>>>> = ({ signal }) => getKris(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type GetKrisQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetKrisHook>>>>
export type GetKrisQueryError = ApiError

export const useGetKris = <TData = Awaited<ReturnType<ReturnType<typeof useGetKrisHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetKrisHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetKrisQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetVersionsHook = () => {
    const getVersions = useKRISSwaggerClient<NoteVersionUi[]>()

    return (uuid: string, signal?: AbortSignal) => {
        return getVersions({ url: `/kris-to-be/kris/${uuid}/versions`, method: 'get', signal })
    }
}

export const getGetVersionsQueryKey = (uuid: string) => [`/kris-to-be/kris/${uuid}/versions`] as const

export const useGetVersionsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetVersionsHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVersionsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVersionsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetVersionsQueryKey(uuid)

    const getVersions = useGetVersionsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetVersionsHook>>>> = ({ signal }) => getVersions(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type GetVersionsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetVersionsHook>>>>
export type GetVersionsQueryError = ApiError

export const useGetVersions = <TData = Awaited<ReturnType<ReturnType<typeof useGetVersionsHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVersionsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetVersionsQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetEvaluationsHook = () => {
    const getEvaluations = useKRISSwaggerClient<NoteItemUi>()

    return (kris: string, uuid: string, type: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY', signal?: AbortSignal) => {
        return getEvaluations({ url: `/kris-to-be/kris/${kris}/${uuid}/${type}/notes`, method: 'get', signal })
    }
}

export const getGetEvaluationsQueryKey = (kris: string, uuid: string, type: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY') =>
    [`/kris-to-be/kris/${kris}/${uuid}/${type}/notes`] as const

export const useGetEvaluationsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetEvaluationsHook>>>, TError = ApiError>(
    kris: string,
    uuid: string,
    type: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY',
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEvaluationsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEvaluationsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetEvaluationsQueryKey(kris, uuid, type)

    const getEvaluations = useGetEvaluationsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetEvaluationsHook>>>> = ({ signal }) =>
        getEvaluations(kris, uuid, type, signal)

    return { queryKey, queryFn, enabled: !!(kris && uuid && type), ...queryOptions }
}

export type GetEvaluationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetEvaluationsHook>>>>
export type GetEvaluationsQueryError = ApiError

export const useGetEvaluations = <TData = Awaited<ReturnType<ReturnType<typeof useGetEvaluationsHook>>>, TError = ApiError>(
    kris: string,
    uuid: string,
    type: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY',
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEvaluationsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetEvaluationsQueryOptions(kris, uuid, type, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetSubItemEvaluationsHook = () => {
    const getSubItemEvaluations = useKRISSwaggerClient<SubItemListUi>()

    return (
        kris: string,
        parentUuid: string,
        parentType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY',
        childType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY',
        signal?: AbortSignal,
    ) => {
        return getSubItemEvaluations({ url: `/kris-to-be/kris/${kris}/${parentUuid}/${parentType}/${childType}/notes`, method: 'get', signal })
    }
}

export const getGetSubItemEvaluationsQueryKey = (
    kris: string,
    parentUuid: string,
    parentType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY',
    childType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY',
) => [`/kris-to-be/kris/${kris}/${parentUuid}/${parentType}/${childType}/notes`] as const

export const useGetSubItemEvaluationsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetSubItemEvaluationsHook>>>, TError = ApiError>(
    kris: string,
    parentUuid: string,
    parentType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY',
    childType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY',
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSubItemEvaluationsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSubItemEvaluationsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetSubItemEvaluationsQueryKey(kris, parentUuid, parentType, childType)

    const getSubItemEvaluations = useGetSubItemEvaluationsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSubItemEvaluationsHook>>>> = ({ signal }) =>
        getSubItemEvaluations(kris, parentUuid, parentType, childType, signal)

    return { queryKey, queryFn, enabled: !!(kris && parentUuid && parentType && childType), ...queryOptions }
}

export type GetSubItemEvaluationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetSubItemEvaluationsHook>>>>
export type GetSubItemEvaluationsQueryError = ApiError

export const useGetSubItemEvaluations = <TData = Awaited<ReturnType<ReturnType<typeof useGetSubItemEvaluationsHook>>>, TError = ApiError>(
    kris: string,
    parentUuid: string,
    parentType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY',
    childType: 'COMMON' | 'GOALS' | 'KRIS' | 'KS' | 'ISVS' | 'PROJECT' | 'ACTIVITY',
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSubItemEvaluationsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetSubItemEvaluationsQueryOptions(kris, parentUuid, parentType, childType, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetIsvsHook = () => {
    const getIsvs = useKRISSwaggerClient<PageKrisToBeIsvs>()

    return (uuid: string, params: GetIsvsParams, signal?: AbortSignal) => {
        return getIsvs({ url: `/kris-to-be/isvs/${uuid}`, method: 'get', params, signal })
    }
}

export const getGetIsvsQueryKey = (uuid: string, params: GetIsvsParams) => [`/kris-to-be/isvs/${uuid}`, ...(params ? [params] : [])] as const

export const useGetIsvsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetIsvsHook>>>, TError = ApiError>(
    uuid: string,
    params: GetIsvsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIsvsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIsvsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetIsvsQueryKey(uuid, params)

    const getIsvs = useGetIsvsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetIsvsHook>>>> = ({ signal }) => getIsvs(uuid, params, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type GetIsvsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetIsvsHook>>>>
export type GetIsvsQueryError = ApiError

export const useGetIsvs = <TData = Awaited<ReturnType<ReturnType<typeof useGetIsvsHook>>>, TError = ApiError>(
    uuid: string,
    params: GetIsvsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIsvsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetIsvsQueryOptions(uuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindKontrolaResultDetailHook = () => {
    const findKontrolaResultDetail = useKRISSwaggerClient<ApiKontrolaResultDetailList>()

    return (params: FindKontrolaResultDetailParams, signal?: AbortSignal) => {
        return findKontrolaResultDetail({ url: `/kontrola/resultDetail/find`, method: 'get', params, signal })
    }
}

export const getFindKontrolaResultDetailQueryKey = (params: FindKontrolaResultDetailParams) =>
    [`/kontrola/resultDetail/find`, ...(params ? [params] : [])] as const

export const useFindKontrolaResultDetailQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindKontrolaResultDetailHook>>>,
    TError = ApiError,
>(
    params: FindKontrolaResultDetailParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindKontrolaResultDetailHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindKontrolaResultDetailHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindKontrolaResultDetailQueryKey(params)

    const findKontrolaResultDetail = useFindKontrolaResultDetailHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindKontrolaResultDetailHook>>>> = ({ signal }) =>
        findKontrolaResultDetail(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindKontrolaResultDetailQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindKontrolaResultDetailHook>>>>
export type FindKontrolaResultDetailQueryError = ApiError

export const useFindKontrolaResultDetail = <TData = Awaited<ReturnType<ReturnType<typeof useFindKontrolaResultDetailHook>>>, TError = ApiError>(
    params: FindKontrolaResultDetailParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindKontrolaResultDetailHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindKontrolaResultDetailQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindKontrolaResultHook = () => {
    const findKontrolaResult = useKRISSwaggerClient<ApiKontrolaResultList>()

    return (params: FindKontrolaResultParams, signal?: AbortSignal) => {
        return findKontrolaResult({ url: `/kontrola/result/find`, method: 'get', params, signal })
    }
}

export const getFindKontrolaResultQueryKey = (params: FindKontrolaResultParams) => [`/kontrola/result/find`, ...(params ? [params] : [])] as const

export const useFindKontrolaResultQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindKontrolaResultHook>>>, TError = ApiError>(
    params: FindKontrolaResultParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindKontrolaResultHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindKontrolaResultHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindKontrolaResultQueryKey(params)

    const findKontrolaResult = useFindKontrolaResultHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindKontrolaResultHook>>>> = ({ signal }) =>
        findKontrolaResult(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindKontrolaResultQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindKontrolaResultHook>>>>
export type FindKontrolaResultQueryError = ApiError

export const useFindKontrolaResult = <TData = Awaited<ReturnType<ReturnType<typeof useFindKontrolaResultHook>>>, TError = ApiError>(
    params: FindKontrolaResultParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindKontrolaResultHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindKontrolaResultQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindInvalidEntitiesHook = () => {
    const findInvalidEntities = useKRISSwaggerClient<string[]>()

    return (params: FindInvalidEntitiesParams, signal?: AbortSignal) => {
        return findInvalidEntities({ url: `/kontrola/invalidEntities/find`, method: 'get', params, signal })
    }
}

export const getFindInvalidEntitiesQueryKey = (params: FindInvalidEntitiesParams) =>
    [`/kontrola/invalidEntities/find`, ...(params ? [params] : [])] as const

export const useFindInvalidEntitiesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindInvalidEntitiesHook>>>, TError = ApiError>(
    params: FindInvalidEntitiesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindInvalidEntitiesHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindInvalidEntitiesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindInvalidEntitiesQueryKey(params)

    const findInvalidEntities = useFindInvalidEntitiesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindInvalidEntitiesHook>>>> = ({ signal }) =>
        findInvalidEntities(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindInvalidEntitiesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindInvalidEntitiesHook>>>>
export type FindInvalidEntitiesQueryError = ApiError

export const useFindInvalidEntities = <TData = Awaited<ReturnType<ReturnType<typeof useFindInvalidEntitiesHook>>>, TError = ApiError>(
    params: FindInvalidEntitiesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindInvalidEntitiesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindInvalidEntitiesQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindKontrolaHook = () => {
    const findKontrola = useKRISSwaggerClient<ApiKontrolaList>()

    return (params: FindKontrolaParams, signal?: AbortSignal) => {
        return findKontrola({ url: `/kontrola/find`, method: 'get', params, signal })
    }
}

export const getFindKontrolaQueryKey = (params: FindKontrolaParams) => [`/kontrola/find`, ...(params ? [params] : [])] as const

export const useFindKontrolaQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindKontrolaHook>>>, TError = ApiError>(
    params: FindKontrolaParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindKontrolaHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindKontrolaHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindKontrolaQueryKey(params)

    const findKontrola = useFindKontrolaHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindKontrolaHook>>>> = ({ signal }) => findKontrola(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindKontrolaQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindKontrolaHook>>>>
export type FindKontrolaQueryError = ApiError

export const useFindKontrola = <TData = Awaited<ReturnType<ReturnType<typeof useFindKontrolaHook>>>, TError = ApiError>(
    params: FindKontrolaParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindKontrolaHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindKontrolaQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetTasksHook = () => {
    const getTasks = useKRISSwaggerClient<FillRefAttributesTask[]>()

    return (signal?: AbortSignal) => {
        return getTasks({ url: `/fillRefAttributes/tasks`, method: 'get', signal })
    }
}

export const getGetTasksQueryKey = () => [`/fillRefAttributes/tasks`] as const

export const useGetTasksQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTasksQueryKey()

    const getTasks = useGetTasksHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>> = ({ signal }) => getTasks(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetTasksQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>>
export type GetTasksQueryError = ApiError

export const useGetTasks = <TData = Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetTasksQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetTaskHook = () => {
    const getTask = useKRISSwaggerClient<FillRefAttributesTask>()

    return (id: string, signal?: AbortSignal) => {
        return getTask({ url: `/fillRefAttributes/tasks/${id}`, method: 'get', signal })
    }
}

export const getGetTaskQueryKey = (id: string) => [`/fillRefAttributes/tasks/${id}`] as const

export const useGetTaskQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetTaskHook>>>, TError = ApiError>(
    id: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTaskQueryKey(id)

    const getTask = useGetTaskHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTaskHook>>>> = ({ signal }) => getTask(id, signal)

    return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type GetTaskQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTaskHook>>>>
export type GetTaskQueryError = ApiError

export const useGetTask = <TData = Awaited<ReturnType<ReturnType<typeof useGetTaskHook>>>, TError = ApiError>(
    id: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetTaskQueryOptions(id, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetTaskBriefInfosHook = () => {
    const getTaskBriefInfos = useKRISSwaggerClient<string[]>()

    return (signal?: AbortSignal) => {
        return getTaskBriefInfos({ url: `/fillRefAttributes/tasks/briefInfos`, method: 'get', signal })
    }
}

export const getGetTaskBriefInfosQueryKey = () => [`/fillRefAttributes/tasks/briefInfos`] as const

export const useGetTaskBriefInfosQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfosHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfosHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfosHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTaskBriefInfosQueryKey()

    const getTaskBriefInfos = useGetTaskBriefInfosHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfosHook>>>> = ({ signal }) => getTaskBriefInfos(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetTaskBriefInfosQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfosHook>>>>
export type GetTaskBriefInfosQueryError = ApiError

export const useGetTaskBriefInfos = <TData = Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfosHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfosHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetTaskBriefInfosQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetTaskBriefInfoHook = () => {
    const getTaskBriefInfo = useKRISSwaggerClient<string>()

    return (id: string, signal?: AbortSignal) => {
        return getTaskBriefInfo({ url: `/fillRefAttributes/tasks/briefInfos/${id}`, method: 'get', signal })
    }
}

export const getGetTaskBriefInfoQueryKey = (id: string) => [`/fillRefAttributes/tasks/briefInfos/${id}`] as const

export const useGetTaskBriefInfoQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfoHook>>>, TError = ApiError>(
    id: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfoHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfoHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTaskBriefInfoQueryKey(id)

    const getTaskBriefInfo = useGetTaskBriefInfoHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfoHook>>>> = ({ signal }) => getTaskBriefInfo(id, signal)

    return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type GetTaskBriefInfoQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfoHook>>>>
export type GetTaskBriefInfoQueryError = ApiError

export const useGetTaskBriefInfo = <TData = Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfoHook>>>, TError = ApiError>(
    id: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfoHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetTaskBriefInfoQueryOptions(id, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindById2Hook = () => {
    const findById2 = useKRISSwaggerClient<Document>()

    return (id: number, signal?: AbortSignal) => {
        return findById2({ url: `/documents/${id}`, method: 'get', signal })
    }
}

export const getFindById2QueryKey = (id: number) => [`/documents/${id}`] as const

export const useFindById2QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindById2Hook>>>, TError = ApiError>(
    id: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindById2Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindById2Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindById2QueryKey(id)

    const findById2 = useFindById2Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindById2Hook>>>> = ({ signal }) => findById2(id, signal)

    return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type FindById2QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindById2Hook>>>>
export type FindById2QueryError = ApiError

export const useFindById2 = <TData = Awaited<ReturnType<ReturnType<typeof useFindById2Hook>>>, TError = ApiError>(
    id: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindById2Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindById2QueryOptions(id, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useDeleteByIdHook = () => {
    const deleteById = useKRISSwaggerClient<void>()

    return (id: number) => {
        return deleteById({ url: `/documents/${id}`, method: 'delete' })
    }
}

export const useDeleteByIdMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteByIdHook>>>, TError, { id: number }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteByIdHook>>>, TError, { id: number }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteById = useDeleteByIdHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteByIdHook>>>, { id: number }> = (props) => {
        const { id } = props ?? {}

        return deleteById(id)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteByIdMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteByIdHook>>>>

export type DeleteByIdMutationError = ApiError

export const useDeleteById = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteByIdHook>>>, TError, { id: number }, TContext>
}) => {
    const mutationOptions = useDeleteByIdMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useFindAllHook = () => {
    const findAll = useKRISSwaggerClient<Document[]>()

    return (signal?: AbortSignal) => {
        return findAll({ url: `/documents/all`, method: 'get', signal })
    }
}

export const getFindAllQueryKey = () => [`/documents/all`] as const

export const useFindAllQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindAllHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAllHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAllHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindAllQueryKey()

    const findAll = useFindAllHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindAllHook>>>> = ({ signal }) => findAll(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindAllQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindAllHook>>>>
export type FindAllQueryError = ApiError

export const useFindAll = <TData = Awaited<ReturnType<ReturnType<typeof useFindAllHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAllHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindAllQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindById3Hook = () => {
    const findById3 = useKRISSwaggerClient<DocumentGroup>()

    return (id: number, signal?: AbortSignal) => {
        return findById3({ url: `/document-groups/${id}`, method: 'get', signal })
    }
}

export const getFindById3QueryKey = (id: number) => [`/document-groups/${id}`] as const

export const useFindById3QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindById3Hook>>>, TError = ApiError>(
    id: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindById3Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindById3Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindById3QueryKey(id)

    const findById3 = useFindById3Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindById3Hook>>>> = ({ signal }) => findById3(id, signal)

    return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type FindById3QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindById3Hook>>>>
export type FindById3QueryError = ApiError

export const useFindById3 = <TData = Awaited<ReturnType<ReturnType<typeof useFindById3Hook>>>, TError = ApiError>(
    id: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindById3Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindById3QueryOptions(id, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useDeleteById1Hook = () => {
    const deleteById1 = useKRISSwaggerClient<void>()

    return (id: number) => {
        return deleteById1({ url: `/document-groups/${id}`, method: 'delete' })
    }
}

export const useDeleteById1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteById1Hook>>>, TError, { id: number }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteById1Hook>>>, TError, { id: number }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteById1 = useDeleteById1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteById1Hook>>>, { id: number }> = (props) => {
        const { id } = props ?? {}

        return deleteById1(id)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteById1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteById1Hook>>>>

export type DeleteById1MutationError = ApiError

export const useDeleteById1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteById1Hook>>>, TError, { id: number }, TContext>
}) => {
    const mutationOptions = useDeleteById1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetRequiredDocumentsHook = () => {
    const getRequiredDocuments = useKRISSwaggerClient<GetRequiredDocuments200Item[]>()

    return (signal?: AbortSignal) => {
        return getRequiredDocuments({ url: `/document-groups/documents/required`, method: 'get', signal })
    }
}

export const getGetRequiredDocumentsQueryKey = () => [`/document-groups/documents/required`] as const

export const useGetRequiredDocumentsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetRequiredDocumentsHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRequiredDocumentsHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRequiredDocumentsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetRequiredDocumentsQueryKey()

    const getRequiredDocuments = useGetRequiredDocumentsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetRequiredDocumentsHook>>>> = ({ signal }) => getRequiredDocuments(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetRequiredDocumentsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetRequiredDocumentsHook>>>>
export type GetRequiredDocumentsQueryError = ApiError

export const useGetRequiredDocuments = <TData = Awaited<ReturnType<ReturnType<typeof useGetRequiredDocumentsHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRequiredDocumentsHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetRequiredDocumentsQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindAll1Hook = () => {
    const findAll1 = useKRISSwaggerClient<DocumentGroup[]>()

    return (signal?: AbortSignal) => {
        return findAll1({ url: `/document-groups/all`, method: 'get', signal })
    }
}

export const getFindAll1QueryKey = () => [`/document-groups/all`] as const

export const useFindAll1QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindAll1Hook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll1Hook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll1Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindAll1QueryKey()

    const findAll1 = useFindAll1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindAll1Hook>>>> = ({ signal }) => findAll1(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindAll1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindAll1Hook>>>>
export type FindAll1QueryError = ApiError

export const useFindAll1 = <TData = Awaited<ReturnType<ReturnType<typeof useFindAll1Hook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll1Hook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindAll1QueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindAll2Hook = () => {
    const findAll2 = useKRISSwaggerClient<ApiApprovalProcess[]>()

    return (signal?: AbortSignal) => {
        return findAll2({ url: `/approval-processes`, method: 'get', signal })
    }
}

export const getFindAll2QueryKey = () => [`/approval-processes`] as const

export const useFindAll2QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindAll2Hook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll2Hook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll2Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindAll2QueryKey()

    const findAll2 = useFindAll2Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindAll2Hook>>>> = ({ signal }) => findAll2(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindAll2QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindAll2Hook>>>>
export type FindAll2QueryError = ApiError

export const useFindAll2 = <TData = Awaited<ReturnType<ReturnType<typeof useFindAll2Hook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll2Hook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindAll2QueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCancelTaskHook = () => {
    const cancelTask = useKRISSwaggerClient<FillRefAttributesTask>()

    return (id: string) => {
        return cancelTask({ url: `/fillRefAttributes/cancelTask/${id}`, method: 'delete' })
    }
}

export const useCancelTaskMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelTaskHook>>>, TError, { id: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelTaskHook>>>, TError, { id: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const cancelTask = useCancelTaskHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCancelTaskHook>>>, { id: string }> = (props) => {
        const { id } = props ?? {}

        return cancelTask(id)
    }

    return { mutationFn, ...mutationOptions }
}

export type CancelTaskMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCancelTaskHook>>>>

export type CancelTaskMutationError = ApiError

export const useCancelTask = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelTaskHook>>>, TError, { id: string }, TContext>
}) => {
    const mutationOptions = useCancelTaskMutationOptions(options)

    return useMutation(mutationOptions)
}
