import React, { PropsWithChildren, forwardRef } from 'react'
import classNames from 'classnames'
import { InfoIcon } from '@isdd/metais-common/assets/images'

import styles from './stepper.module.scss'

export const SectionWarning = forwardRef<HTMLDivElement, PropsWithChildren>(({ children }, ref) => {
    return (
        <div className={classNames('govuk-warning-text', styles.sectionWarning)} ref={ref}>
            <img src={InfoIcon} />
            <strong className={classNames('govuk-warning-text__text', styles.sectionWarningText)}>{children}</strong>
        </div>
    )
})
