import { BaseModal, Table, TextBody, TextHeading, TextWarning } from '@isdd/idsk-ui-kit/index'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ModalButtons } from '@isdd/metais-common/index'
import { DateInput } from '@isdd/idsk-ui-kit/date-input/DateInput'
import { CellContext } from '@tanstack/react-table'

import { IItemForm, RequestItemFormEnum } from '@/components/views/requestLists/components/modalItem/ModalItem'
import { useItemDateSchema } from '@/components/views/requestLists/useRequestSchemas'

export interface IItemDates {
    effectiveFrom: Date
    validDate: Date
}

export interface DateModalItemProps {
    isOpen: boolean
    rowSelection: IItemForm[]
    close: () => void
    onSubmit: (dates: IItemDates, itemCodes: string[]) => void
}

export const DateModalItem: React.FC<DateModalItemProps> = ({ isOpen, close, onSubmit, rowSelection }) => {
    const { t } = useTranslation()
    const { schema: schemaEdit } = useItemDateSchema()

    const { register, handleSubmit, formState, control, setValue, watch } = useForm<IItemDates>({
        resolver: yupResolver(schemaEdit),
    })

    const handleDateChange = (date: Date | null, name: string) => {
        setValue(name as keyof IItemDates, date ?? new Date())
    }

    const itemCodes = rowSelection.map((row) => row.codeItem)

    return (
        <BaseModal isOpen={isOpen} close={close}>
            <TextHeading size="M">{t(`codeListDetail.modal.title.setDates`)}</TextHeading>
            {Object.keys(rowSelection).length > 0 ? (
                <form onSubmit={handleSubmit((formData) => onSubmit(formData, itemCodes))} noValidate>
                    <TextBody>{t('codeListDetail.modal.text.willBeChanged')}</TextBody>
                    <Table
                        data={rowSelection}
                        columns={[
                            {
                                id: 'name',
                                header: t('codeListDetail.table.name'),
                                meta: { getCellContext: (ctx: CellContext<IItemForm, unknown>) => ctx?.getValue?.() },
                                accessorFn: (row) => row.codeName,
                            },
                            {
                                id: 'code',
                                header: t('codeListDetail.table.code'),
                                accessorFn: (row) => row.codeItem,
                            },
                        ]}
                    />
                    <DateInput
                        required
                        label={t('codeListDetail.modal.form.validFrom')}
                        id={RequestItemFormEnum.VALIDDATE}
                        {...register(RequestItemFormEnum.VALIDDATE)}
                        error={formState.errors[RequestItemFormEnum.VALIDDATE]?.message}
                        control={control}
                        handleDateChange={handleDateChange}
                        setValue={setValue}
                    />
                    <DateInput
                        required
                        label={t('codeListDetail.modal.form.effectiveFrom')}
                        id={RequestItemFormEnum.STARTDATE}
                        {...register(RequestItemFormEnum.STARTDATE)}
                        error={formState.errors[RequestItemFormEnum.STARTDATE]?.message}
                        control={control}
                        handleDateChange={handleDateChange}
                        setValue={setValue}
                    />
                    <ModalButtons
                        submitButtonLabel={t('codeListDetail.modal.button.confirm')}
                        closeButtonLabel={t('evaluation.cancelBtn')}
                        onClose={close}
                        disabled={!watch(RequestItemFormEnum.VALIDDATE) || !watch(RequestItemFormEnum.STARTDATE)}
                    />
                </form>
            ) : (
                <>
                    <TextWarning>{t('codeListDetail.modal.text.nothingSelected')}</TextWarning>
                    <ModalButtons onClose={close} />
                </>
            )}
        </BaseModal>
    )
}
