import { Table, TableHandle } from '@isdd/idsk-ui-kit'
import { CheckBox } from '@isdd/idsk-ui-kit/checkbox/CheckBox'
import { PaginatorWrapper } from '@isdd/idsk-ui-kit/paginatorWrapper/PaginatorWrapper'
import { CHECKBOX_CELL } from '@isdd/idsk-ui-kit/table/constants'
import { ColumnSort, IFilter, Pagination } from '@isdd/idsk-ui-kit/types'
import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { ConfigurationItemUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { setLangForAttribute } from '@isdd/metais-common/componentHelpers/englishAttributeLang'
import { LangWrapper } from '@isdd/metais-common/components/lang-wrapper/LangWrapper'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { IListData } from '@isdd/metais-common/types/list'
import { CellContext, ColumnDef, ColumnOrderState, Table as ITable, Row, Updater } from '@tanstack/react-table'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { isAttributeRelation } from '@isdd/metais-common/componentHelpers'
import { RowSelection } from '@isdd/metais-common/hooks/useRowSelection'

import styles from './ciTable.module.scss'

import {
    ColumnsOutputDefinition,
    IStoreColumnSelection,
    getMetaAttributeName,
    getOrderCiColumns,
    getRelationName,
    isMetaAttribute,
    mapTableData,
    reduceAttributesByTechnicalName,
    sortAndMergeCiColumns,
    useGetColumnsFromApiCellContent,
} from '@/componentHelpers/ci/ciTableHelpers'

interface ICiTable {
    data: IListData
    pagination: Pagination
    handleFilterChange: (filter: IFilter) => void
    storeUserSelectedColumns?: (columnSelection: IStoreColumnSelection) => void
    sort: ColumnSort[]
    isLoading: boolean
    isError: boolean
    rowSelection?: RowSelection<ColumnsOutputDefinition>
    uuidsToMatchedCiItemsMap?: Record<string, Record<string, ConfigurationItemUi | ConfigurationItemUi[]>>
    linkToNewTab?: boolean
    baseHref?: string
    itemUuidsWithoutCheckboxes?: string[]
    enableSorting?: boolean
    entityName?: string
}

export const CiTable: React.FC<ICiTable> = ({
    data,
    pagination,
    handleFilterChange,
    storeUserSelectedColumns,
    sort,
    isLoading,
    isError,
    rowSelection,
    uuidsToMatchedCiItemsMap,
    linkToNewTab,
    baseHref,
    itemUuidsWithoutCheckboxes,
    enableSorting = true,
    entityName,
}) => {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const { getColumnsFromApiCellContent } = useGetColumnsFromApiCellContent()
    const {
        state: { user },
    } = useAuth()
    const isUserLogged = !!user
    const tableRef = useRef<HTMLTableElement>(null)
    const tableHandle = useRef<TableHandle>(null)

    const schemaAttributes = reduceAttributesByTechnicalName(data?.entityStructure)

    const tableData = mapTableData(
        data.tableData?.configurationItemSet,
        schemaAttributes,
        t,
        data.unitsData,
        data.constraintsData,
        uuidsToMatchedCiItemsMap,
    )

    const columnsAttributes = sortAndMergeCiColumns(data?.columnListData)

    const columnsFromApi =
        columnsAttributes?.map((attribute, index) => {
            const technicalName = attribute.name ?? ''
            const isRelation = isAttributeRelation(technicalName)
            const attributeHeader = isMetaAttribute(technicalName)
                ? getMetaAttributeName(t, technicalName, entityName)
                : isRelation
                ? getRelationName(t, technicalName, entityName)
                : schemaAttributes[technicalName]?.name

            return {
                accessorFn: (row: ColumnsOutputDefinition) => row?.attributes?.[technicalName] ?? row?.metaAttributes?.[technicalName],
                header: () => attributeHeader,
                id: technicalName ?? '',
                size: 200,
                cell: (ctx: CellContext<ColumnsOutputDefinition, unknown>) => {
                    return (
                        <LangWrapper lang={setLangForAttribute({ technicalName, schemaAttribute: schemaAttributes[technicalName], language })}>
                            {getColumnsFromApiCellContent({
                                index,
                                ctx,
                                technicalName,
                                schemaAttributes,
                                data,
                                linkToNewTab,
                                baseHref,
                            })}
                        </LangWrapper>
                    )
                },
                meta: {
                    getCellContext: (ctx: CellContext<ColumnsOutputDefinition, unknown>) =>
                        getColumnsFromApiCellContent({
                            index,
                            ctx,
                            technicalName,
                            schemaAttributes,
                            data,
                            tooltip: true,
                        }),
                },
                enableSorting: !isRelation ? enableSorting : false,
            }
        }) ?? []

    const columns: Array<ColumnDef<ColumnsOutputDefinition>> = [
        ...(isUserLogged && rowSelection?.rowSelection
            ? [
                  {
                      header: ({ table }: { table: ITable<ColumnsOutputDefinition> }) => (
                          <div className="govuk-checkboxes govuk-checkboxes--small">
                              <CheckBox
                                  label=""
                                  name="checkbox"
                                  id="checkbox-all"
                                  value="checkbox-all"
                                  onChange={table.getToggleAllRowsSelectedHandler()}
                                  checked={table.getIsAllRowsSelected()}
                                  containerClassName={styles.marginBottom15}
                                  aria-label={t('table.selectAllItems')}
                              />
                          </div>
                      ),
                      id: CHECKBOX_CELL,
                      cell: ({ row }: { row: Row<ColumnsOutputDefinition> }) => (
                          <div className="govuk-checkboxes govuk-checkboxes--small" id={`checkbox_cell_${row.id}`}>
                              <CheckBox
                                  label=""
                                  aria-label={t('table.selectItem', { itemName: row.original.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov] })}
                                  name="checkbox"
                                  id={`checkbox_${row.id}`}
                                  value="true"
                                  onChange={row.getToggleSelectedHandler()}
                                  checked={row.getIsSelected()}
                                  containerClassName={styles.marginBottom15}
                                  disabled={!row.getCanSelect()}
                              />
                          </div>
                      ),
                  },
              ]
            : []),
        ...columnsFromApi,
    ]

    useEffect(() => {
        tableHandle.current?.refreshColumns()
    }, [isLoading])

    return (
        <>
            <Table<ColumnsOutputDefinition>
                tableRef={tableRef}
                columns={columns}
                data={tableData}
                onSortingChange={(newSort) => {
                    handleFilterChange({ sort: newSort })
                    rowSelection?.clearSelectedRows()
                }}
                onColumnOrderChange={(updaterOrValue: Updater<ColumnOrderState>) =>
                    storeUserSelectedColumns && storeUserSelectedColumns(getOrderCiColumns(data?.columnListData, updaterOrValue as ColumnOrderState))
                }
                canDrag
                sort={sort}
                isLoading={isLoading}
                error={isError}
                linkToNewTab={linkToNewTab}
                getRowId={(originalRow) => `${originalRow.uuid}`}
                rowSelection={rowSelection?.rowSelection}
                onRowSelectionChange={rowSelection?.getOnRowSelectionChange({
                    pageNumber: pagination.pageNumber,
                    mapper: (item) => data?.tableData?.configurationItemSet.find((d: ConfigurationItemUi) => d.uuid === item) ?? null,
                })}
                handleRef={tableHandle}
                enableRowSelection={(row) => {
                    return !itemUuidsWithoutCheckboxes?.includes(row.original.uuid ?? '') ?? true
                }}
            />
            <PaginatorWrapper
                {...pagination}
                handlePageChange={(filter) => {
                    handleFilterChange(filter)
                    tableRef.current?.scrollIntoView({ behavior: 'smooth' })
                }}
            />
        </>
    )
}
