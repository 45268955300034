import { useTranslation } from 'react-i18next'

export const useGetTranslates = () => {
    const { t } = useTranslation()

    return {
        timeCaption: t('input.datePicker.time'),
        timeInputLabel: t('input.datePicker.time'),
        previousMonthAriaLabel: t('input.datePicker.previousMonthButtonLabel'),
        previousMonthButtonLabel: t('input.datePicker.previousMonthButtonLabel'),
        nextMonthAriaLabel: t('input.datePicker.nextMonthButtonLabel'),
        nextMonthButtonLabel: t('input.datePicker.nextMonthButtonLabel'),
        previousYearAriaLabel: t('input.datePicker.previousYearButtonLabel'),
        previousYearButtonLabel: t('input.datePicker.previousYearButtonLabel'),
        nextYearAriaLabel: t('input.datePicker.nextYearButtonLabel'),
        nextYearButtonLabel: t('input.datePicker.nextYearButtonLabel'),
        chooseDayAriaLabelPrefix: t('input.datePicker.choose'),
        weekAriaLabelPrefix: t('input.datePicker.week'),
        monthAriaLabelPrefix: t('input.datePicker.month'),
        disabledDayAriaLabelPrefix: t('input.datePicker.disabled'),
    }
}
