import { ConfigurationItemUiAttributes, CiWithRelsUi, ConfigurationItemUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { SelectPublicAuthorityAndRole } from '@isdd/metais-common/common/SelectPublicAuthorityAndRole'
import React, { useEffect, useState } from 'react'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Attribute } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { ATTRIBUTE_NAME, Gen_Profil, QueryFeedback, RELATION_TYPE, ZbierkaZakonov, attributesFilter } from '@isdd/metais-common/index'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ButtonGroupRow, Button } from '@isdd/idsk-ui-kit/index'
import { NavigationSubRoutes } from '@isdd/metais-common/navigation/routeNames'
import { MultiValue } from 'react-select'
import { ENTITY_DATAVY_PRVOK } from '@isdd/metais-common/constants'
import classNames from 'classnames'

import styles from './styles.module.scss'
import { getEliUri } from './EvidenceObjectAttributeUtils'
import { NON_EU_URI_ATTRIBUTES, useEvidenceObjectAttribute } from './useEvidenceObjectAttribute'

import { IRelationshipSetState } from '@/components/views/ci/ITVSExceptions/ITVSExceptionsCreateView'
import { CreateEntitySection } from '@/components/create-entity/CreateEntitySection'
import { CreateEntityData } from '@/components/create-entity/CreateEntity'
import { PublicAuthorityState, RoleState } from '@/hooks/usePublicAuthorityAndRole.hook'
import { INewCiRelationData } from '@/hooks/useNewCiRelation.hook'
import { useRolesForPO } from '@/hooks/useRolesForPO'
import { AttributesConfigTechNames } from '@/components/attribute-input/attributeDisplaySettings'
import { SelectCiItem } from '@/components/select-ci-item/SelectCiItem'
import { EvidenceObjectRelationSelect } from '@/components/containers/evidence-object-attribute/EvidenceObjectRelationSelect'

interface Props {
    data: CreateEntityData
    relationData: INewCiRelationData
    onSubmit: (formData: FieldValues, relationshipsToRemove: CiWithRelsUi[]) => void
    defaultItemAttributeValues?: ConfigurationItemUiAttributes | undefined
    updateCiItemId?: string
    isProcessing: boolean
    isLoading: boolean
    isError: boolean
    publicAuthorityState?: PublicAuthorityState
    roleState?: RoleState
    relationshipSetState: IRelationshipSetState
    uploadError: boolean
    allCIsInRelations: CiWithRelsUi[]
    configurationItemId: string
}

export const EvidenceObjectAttributeCreateView: React.FC<Props> = ({
    data,
    relationData,
    updateCiItemId,
    publicAuthorityState,
    roleState,
    isError,
    isLoading,
    isProcessing,
    defaultItemAttributeValues,
    onSubmit,
    relationshipSetState,
    allCIsInRelations,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const { attributesData, generatedEntityId } = data
    const { constraintsData, ciTypeData, unitsData } = attributesData
    const { readRelationShipsData: existingRelations, relationTypeData: relationSchema } = relationData

    const genProfilTechName = Gen_Profil
    const [hasReset, setHasReset] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [isEUUri, setIsEUURI] = useState(false)
    const [selectedUnifiedUri, setSelectedUnifiedUri] = useState<ConfigurationItemUi | MultiValue<ConfigurationItemUi> | null>(null)
    const [defaultRelations, setDefaultRelations] = useState<CiWithRelsUi[]>([])
    const [relationshipsToRemove, setRelationshipsToRemove] = useState<CiWithRelsUi[]>([])

    const referenceIdValue = generatedEntityId?.ciurl?.split('/').pop()
    const metaIsCodeValue = generatedEntityId?.cicode

    const { rolesForPO } = useRolesForPO(
        updateCiItemId ? data.ownerId ?? '' : publicAuthorityState?.selectedPublicAuthority?.poUUID ?? '',
        ciTypeData?.roleList ?? [],
    )

    const zbierkaZakonov = constraintsData.find((constr) => constr?.code === 'ZDROJ_PRAVNEHO_ZAKLADU')?.enumItems?.[0]

    const { aoeAttrProfile, genProfileAttrs, defaultValues, schema } = useEvidenceObjectAttribute(
        ciTypeData?.attributeProfiles ?? [],
        ciTypeData?.attributes ?? [],
        isEUUri,
        updateCiItemId,
        defaultItemAttributeValues,
        referenceIdValue,
        metaIsCodeValue,
        roleState?.selectedRole,
        ciTypeData?.roleList,
    )

    const [relationSchemaCombinedAttributes, setRelationSchemaCombinedAttributest] = useState<(Attribute | undefined)[]>([])
    useEffect(() => {
        setRelationSchemaCombinedAttributest([
            ...(relationSchema?.attributes ?? []),
            ...(relationSchema?.attributeProfiles?.map((profile) => profile.attributes?.map((att) => att)).flat() ?? []),
        ])
    }, [relationSchema])

    const methods = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    })
    const { handleSubmit, setValue, watch } = methods
    const EURefId = watch(ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_eu_ref_id)

    const eliUriPreview = getEliUri(watch(), zbierkaZakonov)
    useEffect(() => {
        if (!isEUUri) {
            setValue(ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_eli_uri, getEliUri(watch(), zbierkaZakonov, true))
            setValue(ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_zdroj, ZbierkaZakonov)
            setValue(ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_bod, '')
        } else {
            setValue(ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_eli_uri, '')
            NON_EU_URI_ATTRIBUTES.forEach((a) => setValue(a, null))
        }
    }, [eliUriPreview, isEUUri, setValue, watch, zbierkaZakonov])

    useEffect(() => {
        setIsEUURI(EURefId)
    }, [EURefId])

    useEffect(() => {
        setDefaultRelations(allCIsInRelations?.filter((ciRel) => ciRel.rels?.[0].type === RELATION_TYPE.Objekt_evidencie_ma_atribut_evidencie))
    }, [allCIsInRelations])

    useEffect(() => {
        setValue(AttributesConfigTechNames.REFERENCE_ID, referenceIdValue)
        setValue(AttributesConfigTechNames.METAIS_CODE, metaIsCodeValue)
    }, [metaIsCodeValue, referenceIdValue, setValue])

    return (
        <>
            <QueryFeedback loading={isLoading || isProcessing} error={isError}>
                <FormProvider {...methods}>
                    <form noValidate onSubmit={handleSubmit((formData) => onSubmit(formData, relationshipsToRemove))}>
                        <CreateEntitySection
                            sectionId={genProfilTechName}
                            attributes={genProfileAttrs ?? []}
                            constraintsData={constraintsData}
                            generatedEntityId={generatedEntityId ?? { cicode: '', ciurl: '' }}
                            unitsData={unitsData}
                            defaultItemAttributeValues={defaultItemAttributeValues}
                            hasResetState={{ hasReset, setHasReset }}
                            updateCiItemId={updateCiItemId}
                            sectionRoles={ciTypeData?.roleList ?? []}
                            selectedRole={roleState?.selectedRole ?? null}
                            rolesForPO={rolesForPO ?? []}
                            maxTextInputLength={2000}
                        />
                        {publicAuthorityState && roleState && (
                            <SelectPublicAuthorityAndRole
                                selectedRole={roleState.selectedRole ?? {}}
                                onChangeAuthority={publicAuthorityState.setSelectedPublicAuthority}
                                onChangeRole={roleState.setSelectedRole}
                                selectedOrg={publicAuthorityState.selectedPublicAuthority}
                                ciRoles={ciTypeData?.roleList ?? []}
                                hideRoleSelect
                                publicAuthorityLabel={t('atributObjektuEvidencie.provider')}
                                disablePOSelect={!!updateCiItemId}
                                publicAuthorityRequired
                            />
                        )}
                        <EvidenceObjectRelationSelect
                            ciType="ObjektEvidencie"
                            relationSchemaCombinedAttributes={relationSchemaCombinedAttributes}
                            methods={methods}
                            hasResetState={{ hasReset, setHasReset }}
                            constraintsData={relationData?.constraintsData ?? []}
                            unitsData={unitsData}
                            relationType="Objekt_evidencie_ma_atribut_evidencie"
                            relationshipSetState={relationshipSetState}
                            label={t('atributObjektuEvidencie.ObjektEvidencie')}
                            existingRelations={existingRelations}
                            defaultData={defaultRelations}
                            setRelationshipsToRemove={setRelationshipsToRemove}
                        />
                        <fieldset>
                            <legend className="govuk-heading-m">{t('atributObjektuEvidencie.jednotnyUri')}</legend>
                            <SelectCiItem
                                ciType={ENTITY_DATAVY_PRVOK}
                                onChangeSelectedCiItem={(val) => {
                                    setSelectedUnifiedUri(val)
                                    setValue(
                                        ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_jednotny_URI,
                                        Array.isArray(val)
                                            ? val[0].attributes?.[ATTRIBUTE_NAME.Gen_Profil_ref_id]
                                            : (val as ConfigurationItemUi)?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_ref_id],
                                    )
                                }}
                                onCloseModal={() => setIsOpen(false)}
                                onOpenModal={() => setIsOpen(true)}
                                existingRelations={existingRelations}
                                label={t('refIdentifiers.type.DatovyPrvok')}
                                isOpen={isOpen}
                                selectedItems={selectedUnifiedUri}
                                isMulti={false}
                                labelDescriptionAttr={ATTRIBUTE_NAME.Gen_Profil_ref_id}
                                attributesFilter={attributesFilter}
                            />
                            <span className="govuk-hint">
                                <u>{`${
                                    watch(ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_jednotny_URI)
                                        ? watch(ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_jednotny_URI)
                                        : defaultValues[ATTRIBUTE_NAME.Profil_AtributObjektuEvidencie_jednotny_URI] ?? ''
                                }`}</u>
                            </span>
                        </fieldset>
                        <fieldset className={styles.uriFieldset}>
                            <legend className="govuk-heading-m">{t('atributObjektuEvidencie.pravnyPredpis')}</legend>
                            <CreateEntitySection
                                sectionId={aoeAttrProfile?.technicalName ?? ''}
                                attributes={aoeAttrProfile?.attributes ?? []}
                                constraintsData={constraintsData}
                                generatedEntityId={generatedEntityId ?? { cicode: '', ciurl: '' }}
                                unitsData={unitsData}
                                defaultItemAttributeValues={defaultItemAttributeValues}
                                hasResetState={{ hasReset, setHasReset }}
                                updateCiItemId={updateCiItemId}
                                sectionRoles={ciTypeData?.roleList ?? []}
                                selectedRole={roleState?.selectedRole ?? null}
                                rolesForPO={rolesForPO ?? []}
                                maxTextInputLength={2000}
                            />
                            {!EURefId && (
                                <div className={classNames(['govuk-hint', styles.wrapAnywhere])}>
                                    <u>{`${t('atributObjektuEvidencie.uriInsight')}: ${eliUriPreview}`}</u>
                                </div>
                            )}
                        </fieldset>

                        <ButtonGroupRow>
                            <Button
                                label={t('button.cancel')}
                                type="reset"
                                variant="secondary"
                                onClick={() => {
                                    navigate(`${NavigationSubRoutes.ATRIBUTY_OBJEKTY_EVIDENCIE}`)
                                }}
                            />
                            <Button type="submit" label={t('button.saveChanges')} />
                        </ButtonGroupRow>
                    </form>
                </FormProvider>
            </QueryFeedback>
        </>
    )
}
