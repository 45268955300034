import { TextBody } from '@isdd/idsk-ui-kit/typography/TextBody'
import React, { useEffect, useId } from 'react'
import { Uppy } from '@uppy/core'
import { FileInput } from '@uppy/react'
import DropTarget from '@uppy/drop-target'
import { useTranslation } from 'react-i18next'

import styles from './FileImport.module.scss'
import './FileImport.scss'

interface IFileImportDragDrop {
    uppy: Uppy
    hideNoSelectedFileToImport?: boolean
}

export const FileImportDragDrop: React.FC<IFileImportDragDrop> = ({ uppy, hideNoSelectedFileToImport }) => {
    const { t } = useTranslation()
    const id = useId()
    const dropZoneId = `dropZone${id}`.replaceAll(':', '')

    useEffect(() => {
        if (!uppy.getPlugin('DropTarget')) {
            uppy.use(DropTarget, {
                target: `#${dropZoneId}`,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={styles.dragDropWrapper} id={dropZoneId}>
            <TextBody>
                <strong>{t('fileImport.dropHere')}</strong>
            </TextBody>
            <TextBody size="S">
                <i>{t('fileImport.or')}</i>
            </TextBody>
            <div>
                <div className={styles.fileInputButtonDiv}>
                    <FileInput uppy={uppy} locale={{ strings: { chooseFiles: t('fileImport.chooseFile') } }} />
                </div>
                {!hideNoSelectedFileToImport && <TextBody size="S">{t('fileImport.browse')}</TextBody>}
            </div>
        </div>
    )
}
