import { NavigationItem, RouteNames } from '@isdd/metais-common/navigation/routeNames'

export const decideIfPartOfUrlEqualsNavItemPath = (locationPathname: string, navItemPath: string) => {
    const slash = '/'
    const pureNaveItemPath = navItemPath.split('?')[0]
    const splitLocationPath = locationPathname.split(slash)
    const splitNavItemPath = pureNaveItemPath.split(slash)
    const arr = []
    for (let i = 0; i < splitLocationPath.length; i++) {
        if (splitNavItemPath[i] === splitLocationPath[i]) {
            arr.push(splitLocationPath[i])
        }
    }

    if (arr.join(slash) === pureNaveItemPath) {
        return true
    }

    return false
}

export type FoundNavItem = {
    path: string
    index: number
}

const getSubItemsWithTopLevelItem = (navItem: NavigationItem) => {
    if (navItem.subItems?.length && navItem.subItems?.length > 0) {
        const hasSubItemWithSamePath = navItem.subItems.some((subItem) => subItem.path === navItem.path)
        return hasSubItemWithSamePath
            ? navItem.subItems
            : [{ path: navItem.path, title: navItem.title, icon: navItem.icon }, ...(navItem.subItems ?? [])]
    } else {
        return []
    }
}

export const findDefaultOpenedNavItems = (
    menuItems: NavigationItem[],
    targetPath: string,
    currentItem: FoundNavItem[] = [],
): FoundNavItem[] | null => {
    if (location.pathname == RouteNames.HOME) return null
    for (let i = 0; i < menuItems.length; i++) {
        const currentPath = [...currentItem, { path: menuItems[i].path, index: i }]

        if (decideIfPartOfUrlEqualsNavItemPath(targetPath, menuItems[i].path)) {
            return currentPath
        }
        if (menuItems[i].subItems && menuItems[i].subItems?.length != 0) {
            const subItemsWithTopLevelItem = getSubItemsWithTopLevelItem(menuItems[i])
            const subIndex = findDefaultOpenedNavItems(subItemsWithTopLevelItem ?? [], targetPath, currentPath)
            if (subIndex) {
                return subIndex
            }
        }
    }
    return null
}
