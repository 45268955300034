import {
    CiListFilterContainerUi,
    ConfigurationItemUi,
    useGetRoleParticipantBulk,
    useReadCiList1,
    useReadRelationshipList,
} from '@isdd/metais-common/api/generated/cmdb-swagger'
import { useFilterForCiList, useGetColumnData, usePagination } from '@isdd/metais-common/api/hooks/containers/containerHelpers'
import { getMetaAttributesForCiFilter, getSearchFields } from '@isdd/metais-common/componentHelpers/ci/filter'
import { mapFilterParamsToApi } from '@isdd/metais-common/componentHelpers/filter'
import { useUserPreferences } from '@isdd/metais-common/contexts/userPreferences/userPreferencesContext'
import { useAttributesHook } from '@isdd/metais-common/hooks/useAttributes.hook'
import { IFilterParams } from '@isdd/metais-common/hooks/useFilter'
import React, { useEffect, useState } from 'react'
import { FieldValues } from 'react-hook-form'
import { RELATION_TYPE } from '@isdd/metais-common/api'
import { RELATION_FILTER_PREFIX, OPERATOR_SEPARATOR } from '@isdd/metais-common/constants'
import { Attribute } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { useTranslation } from 'react-i18next'
import { useRowSelection } from '@isdd/metais-common/hooks/useRowSelection'

import { ICiListContainerView } from '@/components/containers/CiListContainer'
import { ColumnsOutputDefinition } from '@/componentHelpers/ci/ciTableHelpers'

interface EvidenceObjectAttributeListContainer<T> {
    entityName: string
    POType?: string
    ListComponent: React.FC<ICiListContainerView<T>>
    defaultFilterValues: T
    defaultFilterOperators?: T
    OEuuid?: string
    hideHeader?: boolean
}

const EvidenceObjectAttributeListContainer = <T extends FieldValues & IFilterParams>({
    entityName,
    POType,
    ListComponent,
    defaultFilterValues,
    defaultFilterOperators,
    OEuuid,
    hideHeader,
}: EvidenceObjectAttributeListContainer<T>) => {
    const rowSelection = useRowSelection<ColumnsOutputDefinition>()

    const {
        columnListData,
        saveColumnSelection,
        resetColumns,
        isLoading: isColumnsLoading,
        isError: isColumnsError,
    } = useGetColumnData(!OEuuid ? entityName : `${entityName}_${OEuuid}`)
    const { currentPreferences } = useUserPreferences()
    const { t } = useTranslation()

    const defaultRequestApi = {
        filter: {
            type: [entityName],
        },
    }

    const { filterToNeighborsApi, filterParams, handleFilterChange, reset } = useFilterForCiList<T, CiListFilterContainerUi>(
        defaultFilterValues,
        defaultRequestApi,
    )

    const [tableData, setTableData] = useState<ConfigurationItemUi[]>([])

    useEffect(() => {
        reset()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityName])

    const {
        attributeProfiles,
        constraintsData,
        unitsData,
        ciTypeData,
        attributes,
        isError: isAttributesError,
        isLoading: isAttributesLoading,
    } = useAttributesHook(entityName)

    const {
        data: tableDataRes,
        isLoading: isReadCiListLoading,
        isFetching: isReadCiListFetching,
        isError: isReadCiListError,
        refetch,
    } = useReadCiList1({
        ...filterToNeighborsApi,
        filter: {
            ...filterToNeighborsApi.filter,
            fullTextSearch: filterParams.fullTextSearch || '',
            searchFields: getSearchFields(columnListData, ciTypeData ?? {}),
            attributes: mapFilterParamsToApi({ ...filterParams, evidence_status: undefined }, defaultFilterOperators),
            metaAttributes: getMetaAttributesForCiFilter({
                myPO: currentPreferences.myPO,
                showInvalidatedItems: currentPreferences.showInvalidatedItems,
                evidenceStatus: filterParams.evidence_status,
                metaAttributeFilters: filterParams.metaAttributeFilters,
            }),
            relTypeFilters: filterParams.relationFilters ?? [],
        },
    })
    const { mutateAsync, data: relations, isLoading: isLoadingRelations, isError: isRelationsError } = useReadRelationshipList()

    useEffect(() => {
        mutateAsync({
            data: {
                perpage: 999,
                relFilter: {
                    metaAttributes: {
                        state: ['DRAFT'],
                    },
                    type: [
                        RELATION_TYPE.Objekt_evidencie_ma_atribut_evidencie,
                        RELATION_TYPE.ISVS_je_poskytovatelom_AOE,
                        RELATION_TYPE.PO_je_poskytovatelom_AOE,
                    ],
                    endCiUuid: tableDataRes?.configurationItemSet?.map((ci) => ci.uuid ?? ''),
                },
            },
        })
    }, [mutateAsync, tableDataRes])

    useEffect(() => {
        setTableData(
            tableDataRes?.configurationItemSet?.map((ci) => {
                return {
                    ...ci,
                    attributes: {
                        ...ci.attributes,
                        [`${RELATION_FILTER_PREFIX}${OPERATOR_SEPARATOR}${RELATION_TYPE.Objekt_evidencie_ma_atribut_evidencie}`]:
                            relations?.relationshipList
                                ?.filter((rel) => rel.endUuid === ci.uuid && rel.type === RELATION_TYPE.Objekt_evidencie_ma_atribut_evidencie)
                                .map((filteredRel) => filteredRel.startName),
                        [`${RELATION_FILTER_PREFIX}${OPERATOR_SEPARATOR}${RELATION_TYPE.ISVS_je_poskytovatelom_AOE}`]: relations?.relationshipList
                            ?.filter((rel) => rel.endUuid === ci.uuid && rel.type === RELATION_TYPE.ISVS_je_poskytovatelom_AOE)
                            .map((filteredRel) => filteredRel.startName),
                        [`${RELATION_FILTER_PREFIX}${OPERATOR_SEPARATOR}${RELATION_TYPE.PO_je_poskytovatelom_AOE}`]: relations?.relationshipList
                            ?.filter((rel) => rel.endUuid === ci.uuid && rel.type === RELATION_TYPE.PO_je_poskytovatelom_AOE)
                            .map((filteredRel) => filteredRel.startName),
                    },
                }
            }) ?? [],
        )
    }, [tableDataRes, relations])
    const relationAttributes: Attribute[] = [
        {
            technicalName: `${RELATION_FILTER_PREFIX}${OPERATOR_SEPARATOR}${RELATION_TYPE.Objekt_evidencie_ma_atribut_evidencie}`,
            name: t('navMenu.lists.evidenceObjects'),
        },
        {
            technicalName: `${RELATION_FILTER_PREFIX}${OPERATOR_SEPARATOR}${RELATION_TYPE.ISVS_je_poskytovatelom_AOE}`,
            name: t(`${RELATION_FILTER_PREFIX}${OPERATOR_SEPARATOR}${RELATION_TYPE.ISVS_je_poskytovatelom_AOE}`),
        },
        {
            technicalName: `${RELATION_FILTER_PREFIX}${OPERATOR_SEPARATOR}${RELATION_TYPE.PO_je_poskytovatelom_AOE}`,
            name: t(`${RELATION_FILTER_PREFIX}${OPERATOR_SEPARATOR}${RELATION_TYPE.PO_je_poskytovatelom_AOE}`),
        },
    ]
    const ownerGids = new Set(tableDataRes?.configurationItemSet?.map((item) => item.metaAttributes?.owner ?? ''))
    const {
        data: gestorsData,
        isLoading: isGestorsLoading,
        isError: isGestorsError,
        fetchStatus,
    } = useGetRoleParticipantBulk({ gids: [...ownerGids] }, { query: { enabled: !!tableDataRes && ownerGids && [...ownerGids]?.length > 0 } })

    const pagination = usePagination(tableDataRes, filterParams)

    const isGestorsLoadingCombined = isGestorsLoading && fetchStatus != 'idle'
    const isLoading = [
        isReadCiListLoading,
        isReadCiListFetching,
        isColumnsLoading,
        isGestorsLoadingCombined,
        isAttributesLoading,
        isLoadingRelations,
    ].some((item) => item)
    const isError = [isReadCiListError, isColumnsError, isGestorsError, isAttributesError, isRelationsError].some((item) => item)

    return (
        <ListComponent
            defaultFilterValues={defaultFilterValues}
            pagination={pagination}
            handleFilterChange={handleFilterChange}
            resetUserSelectedColumns={resetColumns}
            storeUserSelectedColumns={saveColumnSelection}
            refetch={refetch}
            sort={filterParams?.sort ?? []}
            isLoading={isLoading}
            isError={isError}
            columnListData={{
                ...columnListData,
            }}
            gestorsData={gestorsData}
            tableData={{
                configurationItemSet: tableData,
                incidentRelationshipSet: tableDataRes?.incidentRelationshipSet,
                pagination: tableDataRes?.pagination,
            }}
            attributeProfiles={attributeProfiles}
            attributes={attributes}
            constraintsData={constraintsData}
            unitsData={unitsData}
            ciTypeData={ciTypeData}
            entityName={entityName}
            POType={POType ?? ''}
            rowSelection={rowSelection}
            relationAttributes={relationAttributes}
            hideHeader={hideHeader}
            baseHref={`/ci/${entityName}`}
        />
    )
}

export default EvidenceObjectAttributeListContainer
