import { BreadCrumbs, HomeIcon, TextBody, TextHeading } from '@isdd/idsk-ui-kit/index'
import { DefinitionList } from '@isdd/metais-common/components/definition-list/DefinitionList'
import { InformationGridRow } from '@isdd/metais-common/components/info-grid-row/InformationGridRow'
import { RouteNames } from '@isdd/metais-common/navigation/routeNames'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import styles from './contact.module.scss'

import { MainContentWrapper } from '@/components/MainContentWrapper'

export const Contact = () => {
    const { t } = useTranslation()
    const KONTAKT_MAIL = 'kontakt@mirri.gov.sk'
    const ICO = '50349287'
    const DIC = '2120287004'
    const STREET = 'Pribinova 25'
    const CITY = '811 09 Bratislava'

    return (
        <>
            <BreadCrumbs
                withWidthContainer
                links={[
                    { label: t('breadcrumbs.home'), href: '/', icon: HomeIcon },
                    { label: t('footer.contact'), href: RouteNames.CONTACT },
                ]}
            />
            <MainContentWrapper>
                <TextHeading size="XL">{t('footer.contact')}</TextHeading>
                <TextBody>{t('contact.orgName')}</TextBody>
                <TextBody>{STREET}</TextBody>
                <TextBody>{CITY}</TextBody>
                <DefinitionList className={styles.dl}>
                    <InformationGridRow label={t('contact.ico')} value={ICO} />
                    <InformationGridRow label={t('contact.dic')} value={DIC} />
                </DefinitionList>
                <TextBody className={styles.link}>
                    <Link key="link" to={`mailto:${KONTAKT_MAIL}`} className="govuk-link">
                        {KONTAKT_MAIL}
                    </Link>
                </TextBody>
            </MainContentWrapper>
        </>
    )
}
