import { IFilter, Pagination } from '@isdd/idsk-ui-kit/types'
import {
    HistoryVersionsListUiConfigurationItemUi,
    useReadCiHistoryModifiedByList,
    useReadCiHistoryVersions,
    useReadCiHistoryVersionsActionsList,
} from '@isdd/metais-common/api/generated/cmdb-swagger'
import { mapFilterToHistoryVersionsApi } from '@isdd/metais-common/componentHelpers'
import { BASE_PAGE_NUMBER, BASE_PAGE_SIZE } from '@isdd/metais-common/constants'
import { useFilterParams } from '@isdd/metais-common/hooks/useFilter'
import React from 'react'
import { RowSelection, useRowSelection } from '@isdd/metais-common/hooks/useRowSelection'

import { mapConfigurationItemHistoryListToPagination } from '@/componentHelpers/pagination'

export interface IView {
    data?: HistoryVersionsListUiConfigurationItemUi
    pagination: Pagination
    handleFilterChange: (filter: IFilter) => void
    isLoading: boolean
    isError: boolean
    filterActions?: string[]
    filterModifiedBy?: string[]
    rowSelection: RowSelection<object>
}

export interface HistoryFilter extends IFilter {
    action?: string[]
    lastModifiedBy?: string[]
    fromDate?: string
    toDate?: string
}

export const defaultHistoryFilter = {
    pageNumber: BASE_PAGE_NUMBER,
    pageSize: BASE_PAGE_SIZE,
    action: [],
    lastModifiedBy: [],
    fromDate: '',
    toDate: '',
}

interface IDocumentsListContainer {
    configurationItemId: string
    View: React.FC<IView>
}

export const ConfigurationItemHistoryListContainer: React.FC<IDocumentsListContainer> = ({ configurationItemId, View }) => {
    const { filter, handleFilterChange } = useFilterParams<HistoryFilter>(defaultHistoryFilter)

    const { isError, isFetching, data: historyList } = useReadCiHistoryVersions(configurationItemId ?? '', mapFilterToHistoryVersionsApi(filter))

    const { data: filterActions, isFetching: isActionsLoading } = useReadCiHistoryVersionsActionsList(configurationItemId)
    const { data: filterModifiedBy, isFetching: isModifiedByLoading } = useReadCiHistoryModifiedByList(configurationItemId)

    const pagination = mapConfigurationItemHistoryListToPagination(filter, historyList)
    const rowSelection = useRowSelection<object>()

    return (
        <View
            filterActions={filterActions}
            filterModifiedBy={filterModifiedBy}
            data={historyList}
            pagination={pagination}
            handleFilterChange={handleFilterChange}
            isLoading={isFetching || isActionsLoading || isModifiedByLoading}
            isError={isError}
            rowSelection={rowSelection}
        />
    )
}
