import React from 'react'
import { Row, flexRender } from '@tanstack/react-table'
import classNames from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import { MODAL_TOOLTIP_ZINDEX } from '@isdd/metais-common/constants'

import styles from './table.module.scss'
import { getListCellValue } from './tableUtils'
import { CHECKBOX_CELL, EXPANDABLE_CELL, TOOLTIP_TEXT_BREAKER } from './constants'

import { sanitizedHtml } from '@isdd/idsk-ui-kit/save-html-component/SafeHtmlComponent'
import { Tooltip } from '@isdd/idsk-ui-kit/tooltip/Tooltip'
import { Del } from '@isdd/idsk-ui-kit/typography/Del'
import { TextBody } from '@isdd/idsk-ui-kit/typography/TextBody'

interface ITableRowProps<T> {
    row: Row<T>
    isRowBold?: (row: Row<T>) => boolean
    isRowDanger?: (row: Row<T>) => boolean
    onRowClick?: (row: Row<T>) => void
    rowHref?: (row: Row<T>) => string
    linkToNewTab?: boolean
    isInvalidated: boolean
    isInModal?: boolean
    automaticHeaderColumn?: boolean
}

export const TableRow = <T,>({
    row,
    isRowBold,
    isRowDanger,
    onRowClick,
    rowHref,
    isInvalidated,
    linkToNewTab,
    isInModal,
    automaticHeaderColumn,
}: ITableRowProps<T>): JSX.Element => {
    const navigate = useNavigate()
    const location = useLocation()
    const hasCheckbox = row.getVisibleCells().find((cell) => cell.column.id === CHECKBOX_CELL)
    const isExpandable = row.getCanExpand()

    return (
        <tr
            className={classNames(
                'idsk-table__row',
                { [styles.fontWeightBolder]: isRowBold && isRowBold(row) },
                styles.tableRow,
                styles.tableRowMinHeight,
                {
                    [styles.danger]: isRowDanger && isRowDanger(row),
                    [styles.checkBoxRow]: hasCheckbox,
                    [styles.expandableRow]: isExpandable,
                },
            )}
            onClick={() => {
                if (rowHref) {
                    if (linkToNewTab) {
                        window.open(rowHref(row), '_blank')
                    } else {
                        navigate(rowHref(row), { state: { from: location } })
                    }
                }
                onRowClick?.(row)
            }}
        >
            {row.getVisibleCells().map((cell, index) => {
                const columnDef = cell.column.columnDef
                const isArray = Array.isArray(cell.getValue())
                const tooltipText = columnDef?.meta?.getCellContext?.(cell.getContext())
                const cellValue = flexRender(columnDef.cell, cell.getContext())
                const shortString = isArray ? true : typeof tooltipText === 'string' && tooltipText.length >= TOOLTIP_TEXT_BREAKER
                const showArrayTooltip = isArray && (cell.getValue() as string[])?.length > 3

                const hideTooltip =
                    cell.column.id === CHECKBOX_CELL ||
                    cell.getValue() === '' ||
                    cell.getValue() === undefined ||
                    cell.getValue() === null ||
                    !shortString

                const useHeader = automaticHeaderColumn ? (index === 0 && cell.column.id !== CHECKBOX_CELL) || index === 1 : false
                const cellContent =
                    hideTooltip && !showArrayTooltip ? (
                        cellValue
                    ) : (
                        <Tooltip
                            contentStyle={isInModal ? { zIndex: MODAL_TOOLTIP_ZINDEX } : undefined}
                            position={'bottom center'}
                            disabled={
                                cell.column.id === CHECKBOX_CELL ||
                                cell.getValue() === '' ||
                                cell.getValue() === undefined ||
                                cell.getValue() === null ||
                                !shortString ||
                                (isArray && !showArrayTooltip)
                            }
                            on={['hover', 'focus']}
                            descriptionElement={
                                //dangerouslySetInnerHTML because of tooltip for attribute of type HTML
                                isArray ? (
                                    getListCellValue(cell.getValue() as string[], true)
                                ) : (
                                    <div className={styles.tooltipWidth500} dangerouslySetInnerHTML={{ __html: sanitizedHtml(tooltipText) }} />
                                )
                            }
                            tooltipContent={() => (
                                <div className={classNames({ [styles.tooltipTextWrapper]: !isArray })}>
                                    {isArray ? getListCellValue(cell.getValue() as string[]) : cellValue}
                                </div>
                            )}
                        />
                    )
                return useHeader ? (
                    <th
                        scope="row"
                        className={classNames('idsk-table__cell', styles.fontWeightNormal, {
                            [styles.fontWeightBolder]: isRowBold && isRowBold(row),
                            [styles.checkBoxCell]: cell.column.id === CHECKBOX_CELL,
                            [styles.rowSelected]: row.getIsSelected(),
                        })}
                        style={columnDef.size ? { width: columnDef.size } : { width: 'auto' }}
                        key={cell.id}
                        id={cell.column.id}
                    >
                        <TextBody size="S" className={'marginBottom0'}>
                            <Del isInvalid={isInvalidated}>{cellContent}</Del>
                        </TextBody>
                    </th>
                ) : (
                    <td
                        className={classNames('idsk-table__cell', {
                            [styles.checkBoxCell]: cell.column.id === CHECKBOX_CELL,
                            [styles.rowSelected]: row.getIsSelected(),
                        })}
                        style={columnDef.size ? { width: columnDef.size } : { width: 'auto' }}
                        key={cell.id}
                    >
                        {cell.column.id === EXPANDABLE_CELL || cell.column.id === CHECKBOX_CELL ? (
                            <Del isInvalid={isInvalidated}>{cellContent}</Del>
                        ) : (
                            <TextBody size="S" className={'marginBottom0'}>
                                <Del isInvalid={isInvalidated}>{cellContent}</Del>
                            </TextBody>
                        )}
                    </td>
                )
            })}
        </tr>
    )
}
