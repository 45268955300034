import React, { useContext } from 'react'
import { InformationGridRow } from '@isdd/metais-common/components/info-grid-row/InformationGridRow'
import { ApiStandardRequest } from '@isdd/metais-common/api/generated/standards-swagger'
import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { useTranslation } from 'react-i18next'
import { getInfoGuiProfilStandardRequest, getLabelGuiProfilStandardRequest } from '@isdd/metais-common/api/hooks/containers/containerHelpers'
import { Group } from '@isdd/metais-common/api/generated/iam-swagger'
import { useStateMachine } from '@isdd/metais-common/components/state-machine/hooks/useStateMachine'
import { Attribute } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { Languages } from '@isdd/metais-common/localization/languages'

import { DraftListRelatedDocuments } from '@/components/entities/draftslist/detail/DraftListRelatedDocuments'
import { StandardDraftsStateMachine } from '@/pages/standardization/draftslist/[entityId]/form'

interface Props {
    data: {
        requestData?: ApiStandardRequest
        guiAttributes?: Attribute[]
        workGroup?: Group
    }
}
export const DraftListDetailMainSection: React.FC<Props> = ({ data }) => {
    const { requestData, guiAttributes, workGroup } = data
    const { t, i18n } = useTranslation()
    const stateContext = useContext(StandardDraftsStateMachine)
    const stateMachine = useStateMachine({ stateContext })

    const currentState = stateMachine?.getCurrentState()

    const showWorkGroup = currentState === 'ASSIGNED'
    return (
        <div>
            <InformationGridRow
                key={ATTRIBUTE_NAME.standardRequestState}
                label={getLabelGuiProfilStandardRequest(i18n.language, ATTRIBUTE_NAME.standardRequestState, guiAttributes) ?? ''}
                value={t(`DraftsList.filter.state.${currentState}`)}
                tooltip={getInfoGuiProfilStandardRequest(i18n.language, ATTRIBUTE_NAME.standardRequestState, guiAttributes) ?? ''}
            />
            <InformationGridRow
                key={ATTRIBUTE_NAME.actionDescription}
                label={getLabelGuiProfilStandardRequest(i18n.language, ATTRIBUTE_NAME.actionDescription, guiAttributes) ?? ''}
                value={data.requestData?.actionDescription}
                tooltip={getInfoGuiProfilStandardRequest(i18n.language, ATTRIBUTE_NAME.actionDescription, guiAttributes) ?? ''}
            />
            <InformationGridRow
                key={ATTRIBUTE_NAME.requestChannel}
                label={getLabelGuiProfilStandardRequest(i18n.language, ATTRIBUTE_NAME.requestChannel, guiAttributes) ?? ''}
                value={requestData?.requestChannel ? t(`DraftsList.filter.draftType.${requestData?.requestChannel}`) : ''}
                tooltip={getInfoGuiProfilStandardRequest(i18n.language, ATTRIBUTE_NAME.requestChannel, guiAttributes) ?? ''}
            />
            <InformationGridRow
                key={ATTRIBUTE_NAME.createdAt}
                label={getLabelGuiProfilStandardRequest(i18n.language, ATTRIBUTE_NAME.createdAt, guiAttributes) ?? ''}
                value={requestData?.createdAt ? t('date', { date: requestData?.createdAt }) : null}
                tooltip={getInfoGuiProfilStandardRequest(i18n.language, ATTRIBUTE_NAME.createdAt, guiAttributes) ?? ''}
            />
            {showWorkGroup && (
                <InformationGridRow
                    label={t('DraftsList.detail.assignedToGroup')}
                    value={`${workGroup?.shortName} - ${workGroup?.name}`}
                    hideIcon
                    lang={Languages.SLOVAK}
                />
            )}
            <InformationGridRow
                key={ATTRIBUTE_NAME.email}
                label={getLabelGuiProfilStandardRequest(i18n.language, ATTRIBUTE_NAME.email, guiAttributes) ?? ''}
                value={requestData?.email}
                tooltip={getInfoGuiProfilStandardRequest(i18n.language, ATTRIBUTE_NAME.email, guiAttributes) ?? ''}
            />
            <InformationGridRow
                key={ATTRIBUTE_NAME.fullName}
                label={getLabelGuiProfilStandardRequest(i18n.language, ATTRIBUTE_NAME.fullName, guiAttributes) ?? ''}
                value={requestData?.fullName}
                tooltip={getInfoGuiProfilStandardRequest(i18n.language, ATTRIBUTE_NAME.fullName, guiAttributes) ?? ''}
            />
            <DraftListRelatedDocuments data={data} />
        </div>
    )
}
