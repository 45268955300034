import { useReadRelationships } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { useGetRelationshipTypeWrapper } from '@isdd/metais-common/hooks/useRelationshipType.hook'
import { useDetailData } from '@isdd/metais-common/hooks/useDetailData'
import { Referencny_register_ma_objekt_evidencie, Referencny_register_ma_referencny_udaj } from '@isdd/metais-common/constants'

type Props = {
    configurationItemId: string
}

export const useRelationData = ({ configurationItemId }: Props) => {
    const {
        data: refRegMaObjektEvidencieData,
        isLoading: isRegRegMaObjektEvidencieLoading,
        isError: isRegRegMaObjektEvidencieError,
    } = useGetRelationshipTypeWrapper(Referencny_register_ma_objekt_evidencie)
    const { data: refRegMaRefUdajData } = useGetRelationshipTypeWrapper(Referencny_register_ma_referencny_udaj)

    const {
        data: readRelationShipsData,
        isLoading: isReadRelationshipsLoading,
        isError: isReadRelationshipsError,
    } = useReadRelationships(configurationItemId ?? '', undefined, {
        query: {
            enabled: !!configurationItemId,
        },
    })

    const {
        isLoading: isDetailDataLoading,
        isError: isDetailDataError,
        constraintsData,
        unitsData,
    } = useDetailData({
        entityStructure: refRegMaObjektEvidencieData,
        isEntityStructureLoading: isRegRegMaObjektEvidencieLoading,
        isEntityStructureError: isRegRegMaObjektEvidencieError,
    })

    return {
        data: {
            refRegMaRefUdajData,
            refRegMaObjektEvidencieData,
            readRelationShipsData,
            constraintsData,
            unitsData,
        },
        isLoading: isDetailDataLoading || isReadRelationshipsLoading,
        isError: isDetailDataError || isReadRelationshipsError,
    }
}
