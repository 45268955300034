import { Button, ButtonGroupRow, SelectLazyLoading } from '@isdd/idsk-ui-kit/index'
import { SortBy, SortType } from '@isdd/idsk-ui-kit/types'
import { ATTRIBUTE_NAME, ATTRIBUTE_PROFILE_NAME } from '@isdd/metais-common/api'
import { ConfigurationItemUi, useReadCiList1Hook } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { DRAFT, ENTITY_KS } from '@isdd/metais-common/constants'
import { OPERATOR_OPTIONS } from '@isdd/metais-common/hooks/useFilter'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MultiValue } from 'react-select'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'

import { PublishType } from './KSHelperView'

interface ISelectKSService {
    onNext: () => void
    onPrevious: () => void
    onSelect: (value: ConfigurationItemUi) => void
    selectedType: PublishType
}

export const getCiName = (item: ConfigurationItemUi) => {
    return (item?.attributes && `${item?.attributes[ATTRIBUTE_NAME.Gen_Profil_nazov]}`) ?? ''
}

export const SelectKSService: React.FC<ISelectKSService> = ({ onNext, onPrevious, onSelect, selectedType }) => {
    const { t } = useTranslation()
    const readCiList = useReadCiList1Hook()
    const [nextDisabled, setNextDisabled] = useState(true)
    const {
        state: { user },
    } = useAuth()

    const loadOptions = async (searchQuery: string, additional: { page: number } | undefined) => {
        try {
            const page = !additional?.page ? 1 : (additional?.page || 0) + 1
            const options = await readCiList({
                sortBy: SortBy.LAST_MODIFIED_AT,
                sortType: SortType.DESC,
                filter: {
                    metaAttributes: {
                        state: [DRAFT],
                        liableEntity: selectedType === PublishType.GENERIC ? undefined : user?.groupData.map((item) => item.orgId),
                        liableEntityByHierarchy: PublishType.GENERIC ? undefined : true,
                    },
                    type: [ENTITY_KS],
                    ...(!!searchQuery && { searchFields: [ATTRIBUTE_NAME.Gen_Profil_nazov, ATTRIBUTE_NAME.Gen_Profil_kod_metais] }),
                    fullTextSearch: searchQuery,
                    attributes: [
                        {
                            name: ATTRIBUTE_PROFILE_NAME.Profil_UPVS_je_genericka,
                            filterValue: [
                                {
                                    value: selectedType === PublishType.GENERIC ? 'true' : 'false',
                                    equality: OPERATOR_OPTIONS.EQUAL,
                                },
                            ],
                        },
                    ],
                },
                page: page,
                perpage: 50,
            })
            return {
                options: options.configurationItemSet || [],
                hasMore: options.configurationItemSet?.length == 50 ? true : false,
                additional: {
                    page: page,
                },
            }
        } catch {
            return {
                options: [],
                hasMore: false,
                additional: {
                    page: 1,
                },
            }
        }
    }

    const handleConfigurationItemChange = (value: ConfigurationItemUi | MultiValue<ConfigurationItemUi> | null) => {
        setNextDisabled(false)
        const ci = Array.isArray(value) ? value[0] : value
        onSelect(ci)
    }

    const extraLabel = selectedType === PublishType.GENERIC ? t('KSPublishHelper.listKsLabelGeneric') : t('KSPublishHelper.listKsLabelSelf')

    return (
        <>
            <SelectLazyLoading<ConfigurationItemUi>
                getOptionLabel={(item) => getCiName(item)}
                getOptionValue={(item) => item.uuid ?? ''}
                loadOptions={(searchQuery, __, additional) => loadOptions(searchQuery, additional)}
                label={`${t('selectCiItem.label')} ${t('input.requiredField')} - ${extraLabel}`}
                name="isvsUuid"
                onChange={handleConfigurationItemChange}
            />
            <ButtonGroupRow>
                <Button label={t('form.back')} onClick={onPrevious} variant="secondary" />
                <Button label={t('form.next')} onClick={onNext} disabled={nextDisabled} />
            </ButtonGroupRow>
        </>
    )
}
