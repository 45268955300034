import React, { useId } from 'react'
import classNames from 'classnames'
import { UseFormClearErrors, UseFormSetValue } from 'react-hook-form'
import { GroupBase, MenuPosition, MultiValue, OptionProps, OptionsOrGroups, PropsValue } from 'react-select'
import { AsyncPaginate } from 'react-select-async-paginate'
import { useTranslation } from 'react-i18next'
import { PopupPosition } from 'reactjs-popup/dist/types'
import sanitizeHtml from 'sanitize-html'
import { decodeHtmlEntities } from '@isdd/metais-common/utils/utils'
import { MODAL_TOOLTIP_ZINDEX } from '@isdd/metais-common/constants'

import styles from './selectLazyLoading.module.scss'

import {
    Control,
    Menu,
    getMultiValueRemove,
    Option as ReactSelectDefaultOptionComponent,
    selectStyles,
    getExtendedAriaLabel,
} from '@isdd/idsk-ui-kit/common/SelectCommon'
import { Tooltip } from '@isdd/idsk-ui-kit/tooltip/Tooltip'
import { useGetLocalMessages } from '@isdd/idsk-ui-kit/select/useGetLocalMessages'
import { useKeyEvents } from '@isdd/idsk-ui-kit/select/useKeyEvents'

export interface ILoadOptionsResponse<T> {
    options: T[]
    hasMore: boolean
    additional: {
        page: number
    }
}

export const DEFAULT_LAZY_LOAD_PER_PAGE = 20

export interface ISelectProps<T> {
    id?: string
    value?: T | MultiValue<T> | null
    onChange?: (val: T | MultiValue<T> | null) => void
    label: string
    ariaLabel?: string
    info?: string
    name: string
    getOptionValue: (item: T) => string
    getOptionLabel: (item: T) => string
    option?: (props: OptionProps<T>) => JSX.Element
    placeholder?: string
    isMulti?: boolean
    error?: string
    defaultValue?: PropsValue<T>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValue?: UseFormSetValue<any>
    loadOptions: (
        searchQuery: string,
        prevOptions: OptionsOrGroups<T, GroupBase<T>>,
        additional: { page: number } | undefined,
    ) => Promise<ILoadOptionsResponse<T>>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clearErrors?: UseFormClearErrors<any>
    isClearable?: boolean
    menuPosition?: MenuPosition
    disabled?: boolean
    required?: boolean
    tooltipPosition?: PopupPosition | PopupPosition[]
    hint?: string
    isInModal?: boolean
    tabIndex?: number
}

export const SelectLazyLoading = <T,>({
    value,
    onChange,
    label,
    ariaLabel,
    name,
    hint,
    info,
    getOptionValue,
    getOptionLabel,
    defaultValue,
    option,
    placeholder,
    loadOptions,
    isMulti = false,
    error,
    id,
    setValue,
    clearErrors,
    isClearable = true,
    menuPosition = 'fixed',
    disabled,
    required,
    tooltipPosition,
    isInModal = false,
    tabIndex,
}: ISelectProps<T>): JSX.Element => {
    const { t } = useTranslation()
    const uniqueId = useId()
    const inputId = id ?? uniqueId
    const errorId = `${inputId}-error`
    const hintId = `${inputId}-hint`
    const localMessages = useGetLocalMessages()
    const keyEvents = useKeyEvents()

    const Option = (props: OptionProps<T>) => {
        return option ? option(props) : ReactSelectDefaultOptionComponent(props)
    }
    const handleOnChange = (selectedValue: MultiValue<T> | T | null) => {
        if (onChange) {
            onChange(selectedValue)
        } else if (setValue) {
            if (isMulti) {
                if (Array.isArray(selectedValue) && selectedValue.length) {
                    setValue(
                        name,
                        selectedValue.map((val) => getOptionValue(val)),
                    )
                } else {
                    setValue(name, undefined)
                }
            } else {
                const val = selectedValue ? getOptionValue(Array.isArray(selectedValue) ? selectedValue[0] : selectedValue) : undefined
                setValue(name, val)
            }
        }
        const val = Array.isArray(selectedValue) ? selectedValue.length : selectedValue
        val && clearErrors && clearErrors(name)
    }

    return (
        <div className={classNames('govuk-form-group', { 'govuk-form-group--error': !!error })}>
            <div className={styles.labelDiv}>
                {label && (
                    <label className="govuk-label" htmlFor={inputId}>
                        {label} {required && t('input.requiredField')}
                    </label>
                )}
                {info && (
                    <Tooltip
                        descriptionElement={
                            <div className="tooltipWidth500">
                                {
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: sanitizeHtml(decodeHtmlEntities(info)),
                                        }}
                                    />
                                }
                            </div>
                        }
                        contentStyle={isInModal ? { zIndex: MODAL_TOOLTIP_ZINDEX } : undefined}
                        position={tooltipPosition}
                        altText={t('tooltip', { tooltip: label })}
                    />
                )}
            </div>
            <span id={errorId} className={classNames({ 'govuk-visually-hidden': !error, 'govuk-error-message': !!error })}>
                {error && <span className="govuk-visually-hidden">{t('error')}</span>}
                {error}
            </span>
            <span id={hintId} className={classNames({ 'govuk-visually-hidden': !hint, 'govuk-hint': !!hint })}>
                {hint}
            </span>
            <AsyncPaginate<T, GroupBase<T>, { page: number } | undefined, boolean>
                inputId={inputId}
                name={name}
                value={value}
                loadOptions={loadOptions}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                classNames={{ menuList: () => styles.reactSelectMenuList }}
                placeholder={placeholder || ''}
                components={{ Option, Menu, Control, MultiValueRemove: getMultiValueRemove(t) }}
                isMulti={isMulti}
                menuPosition={menuPosition}
                defaultValue={defaultValue}
                className={classNames('govuk-select', styles.selectLazyLoading, { [styles.reactSelectError]: !!error })}
                styles={selectStyles<T>()}
                isClearable={isClearable}
                unstyled
                onChange={handleOnChange}
                isDisabled={disabled}
                aria-label={getExtendedAriaLabel(label, t, isMulti, ariaLabel)}
                aria-invalid={!!error}
                aria-describedby={errorId}
                aria-errormessage={errorId}
                noOptionsMessage={localMessages.noOptionsMessage}
                ariaLiveMessages={localMessages.ariaLiveMessages}
                screenReaderStatus={localMessages.screenReaderStatus}
                loadingMessage={localMessages.loadingMessage}
                required={required}
                openMenuOnFocus={false}
                tabIndex={tabIndex}
                {...keyEvents}
            />
        </div>
    )
}
