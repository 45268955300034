import {
    ApiCodelistItemList,
    ApiCodelistPreview,
    useDeleteTemporalCodelistHeader,
    useGetCodelistHeader,
    useGetCodelistItems,
    useGetOriginalCodelistHeader,
    useGetUnlockTemporalCodelistHeader,
    useProcessAllItemsAction,
    useProcessHeaderAction,
    useProcessItemAction,
} from '@isdd/metais-common/api/generated/codelist-repo-swagger'
import { RoleParticipantUI, useGetRoleParticipantBulk } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { AttributeProfile, useGetAttributeProfile } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { NavigationSubRoutes } from '@isdd/metais-common/navigation/routeNames'
import { useInvalidateCodeListCache } from '@isdd/metais-common/hooks/invalidate-cache'
import { useCodeListWorkingLanguage } from '@isdd/metais-common/contexts/codeListWorkingLanguage/codeListWorkingLanguageContext'
import { ErrorTranslateKeyType, getErrorTranslateKeys } from '@isdd/metais-common/utils/errorMapper'
import { IFilter, SortType } from '@isdd/idsk-ui-kit/types'
import { IFilterParams, useFilterParams } from '@isdd/metais-common/hooks/useFilter'
import { PromiseStatus } from '@isdd/metais-common/types/api'
import { formatDateForDefaultValue } from '@isdd/metais-common/componentHelpers/formatting/formatDateUtils'
import { BASE_PAGE_NUMBER, BASE_PAGE_SIZE } from '@isdd/metais-common/api'
import { RowSelection, useRowSelection } from '@isdd/metais-common/hooks/useRowSelection'

import { TableCols } from '../views/codeLists/CodeListDetailItemsTable'

import { getAllWorkingLanguages, isEffective, selectBasedOnLanguageAndDate } from '@/components/views/codeLists/CodeListDetailUtils'
import { ApiCodeListActions, ApiCodeListItemsActions } from '@/componentHelpers/codeList'

export interface CodeListDetailData {
    codeList?: ApiCodelistPreview
    codeListOriginal?: ApiCodelistPreview
    attributeProfile?: AttributeProfile
    gestors?: RoleParticipantUI[]
}
export interface itemActionError {
    itemCode: string
    message: string
}

export interface CodeListDetailItemsViewProps {
    code: string
    codelistId: number
    itemsData?: ApiCodelistItemList
    attributeProfile?: AttributeProfile
    isLoadingItemsTable: boolean
    isLoadingItemAction: boolean
    isErrorItemsTable: boolean
    itemActionErrors: itemActionError[]
    isSuccessItemActionMutation: boolean
    itemsFilter: IFilter
    workingLanguage: string
    invalidateCodeListDetailItemsCache: () => void
    onItemsModalOpen: () => void
    handleItemsFilterChange: (filter: IFilter) => void
    handleMarkForPublish: (itemCodes: string[], onSuccess?: () => void) => void
    handleSetDates: (itemCodes: string[], validFrom: string, effectiveFrom: string, onSuccess?: () => void) => void
    isCodelistAutoincrementValid?: boolean
    codelistPrefix?: string
    codelistRefId?: string
    codelistCharCount?: number
    rowSelection: RowSelection<TableCols>
}
export interface CodeListDetailWrapperProps {
    data: CodeListDetailData
    isLoading: boolean
    isLoadingMutation: boolean
    isError: boolean
    actionsErrorMessages: ErrorTranslateKeyType[]
    isSuccessMutation: boolean
    successMessage?: string
    workingLanguage: string
    setWorkingLanguage: (language: string) => void
    invalidateCodeListDetailCache: () => void
    invalidateCodeListDetailItemsCache: () => void
    handleAllItemsReadyToPublish: (close: () => void) => void
    handleSendToIsvs: (close: () => void) => void
    handlePublishCodeList: (close: () => void) => void
    handleSendToSzzc: (close: () => void) => void
    handleReturnToMainGestor: (close: () => void) => void
    handleRemoveLock: (close: () => void) => void
    handleDiscardChanges: (close: () => void) => void
    handleEdit: () => void
    itemsFilter: IFilter
    handleItemsFilterChange: (filter: IFilter) => void
    handleMarkForPublish: (itemCodes: string[], onSuccess?: () => void) => void
    handleSetDates: (itemCodes: string[], validFrom: string, effectiveFrom: string, onSuccess?: () => void) => void
    isErrorItemsTable: boolean
    isLoadingItemsTable: boolean
    isLoadingItemAction: boolean
    isSuccessItemActionMutation: boolean
    itemActionErrors: itemActionError[]
    onItemsModalOpen: () => void
    itemsData?: ApiCodelistItemList
    attributeProfile?: AttributeProfile
    rowSelection: RowSelection<TableCols>
}

export interface CodeListDetailItemsContainerProps {
    code: string
    workingLanguage: string
    invalidateCodeListDetailCache: () => void
    invalidateCodeListDetailItemsCache: () => void
    View: React.FC<CodeListDetailItemsViewProps>
}

export enum CodeListFilterEffective {
    TRUE = 'TRUE',
    FALSE = 'FALSE',
}

export const defaultFilterValues = {
    state: undefined,
    effective: CodeListFilterEffective.TRUE,
}

export interface CodeListDetailFilterData extends IFilterParams, IFilter {
    state?: string
    effective?: string
}

export interface CodeListDetailItemsFilterData extends IFilterParams, IFilter {
    action?: string[]
    lastModifiedBy?: string[]
    fromDate?: string
    toDate?: string
}
interface CodeListDetailContainerProps {
    id?: string
    View: React.FC<CodeListDetailWrapperProps>
}

export const CodeListDetailContainer: React.FC<CodeListDetailContainerProps> = ({ id, View }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const navigate = useNavigate()
    const { setIsActionSuccess } = useActionSuccess()
    const { workingLanguage, setWorkingLanguage } = useCodeListWorkingLanguage()
    const { invalidateCodelists, invalidateCodeItems } = useInvalidateCodeListCache()
    const [successMessage, setSuccessMessage] = useState<string>('')
    const rowSelection = useRowSelection<TableCols>()

    const {
        isFetching: isLoadingAttributeProfile,
        isError: isErrorAttributeProfile,
        data: attributeProfile,
    } = useGetAttributeProfile('Gui_Profil_ZC')
    const { isFetching: isLoadingData, isError: isErrorData, data: codeListData } = useGetCodelistHeader(Number(id))
    const {
        isFetching: isLoadingOriginal,
        isError: isErrorOriginal,
        data: codeListOriginalData,
    } = useGetOriginalCodelistHeader(codeListData?.code ?? '', { query: { enabled: !!codeListData } })

    const requestGestorGids = [
        ...(codeListData?.mainCodelistManagers?.map((gestor) => gestor.value || '') || []),
        ...(codeListData?.codelistManagers?.map((gestor) => gestor.value || '') || []),
    ]

    const {
        isFetching: isLoadingRoleParticipants,
        isError: isErrorRoleParticipants,
        data: roleParticipantsData,
    } = useGetRoleParticipantBulk(
        { gids: requestGestorGids },
        {
            query: { enabled: requestGestorGids.length > 0 },
        },
    )
    const unlockTemporalMutation = useGetUnlockTemporalCodelistHeader()
    const deleteTemporalMutation = useDeleteTemporalCodelistHeader()

    const data = {
        codeList: codeListData,
        codeListOriginal: codeListOriginalData,
        attributeProfile: attributeProfile,
        gestors: roleParticipantsData,
    }

    const itemsActionMutation = useProcessAllItemsAction()
    const codelistActionMutation = useProcessHeaderAction()

    useEffect(() => {
        const languages = getAllWorkingLanguages(codeListData)
        if (!languages.includes(workingLanguage)) {
            setWorkingLanguage('sk')
        }
    }, [codeListData, setWorkingLanguage, workingLanguage])

    const invalidateCodeListDetailCache = () => {
        invalidateCodelists(data.codeList?.code ?? '', Number(id))
    }

    const invalidateCodeListDetailItemsCache = () => {
        invalidateCodeItems(data.codeList?.code ?? '')
    }

    const handleAllItemsReadyToPublish = (close: () => void) => {
        close()
        const code = data.codeList?.code
        if (!code) return
        itemsActionMutation.mutate(
            { code, params: { action: ApiCodeListActions.CODELIST_ITEMS_TO_PUBLISH } },
            {
                onSuccess: () => {
                    invalidateCodeListDetailCache()
                    setSuccessMessage(t('codeListDetail.feedback.publishCodeListItems'))
                },
            },
        )
    }

    const handleSendToIsvs = (close: () => void) => {
        close()
        data.codeList?.code &&
            codelistActionMutation.mutate(
                { code: data.codeList?.code, params: { action: ApiCodeListActions.TEMPORAL_CODELIST_TO_ISVS_PROCESSING } },
                {
                    onSuccess: () => {
                        invalidateCodeListDetailCache()
                        const path = NavigationSubRoutes.CODELIST
                        setIsActionSuccess({ value: true, path })
                        navigate(path, { state: { from: location } })
                    },
                },
            )
    }

    const handlePublishCodeList = (close: () => void) => {
        close()
        data.codeList?.code &&
            codelistActionMutation.mutate(
                { code: data.codeList?.code, params: { action: ApiCodeListActions.TEMPORAL_CODELIST_TO_PUBLISHED } },
                {
                    onSuccess: () => {
                        invalidateCodeListDetailCache()
                        const path = NavigationSubRoutes.CODELIST
                        setIsActionSuccess({ value: true, path })
                        navigate(path, { state: { from: location } })
                    },
                },
            )
    }

    const handleSendToSzzc = (close: () => void) => {
        close()
        data.codeList?.code &&
            codelistActionMutation.mutate(
                { code: data.codeList?.code, params: { action: ApiCodeListActions.TEMPORAL_CODELIST_TO_READY_TO_PUBLISH } },
                {
                    onSuccess: () => {
                        invalidateCodeListDetailCache()
                        setSuccessMessage(t('codeListDetail.feedback.sendToSzzc'))
                    },
                },
            )
    }

    const handleReturnToMainGestor = (close: () => void) => {
        close()
        data.codeList?.code &&
            codelistActionMutation.mutate(
                { code: data.codeList?.code, params: { action: ApiCodeListActions.TEMPORAL_CODELIST_TO_UPDATING } },
                {
                    onSuccess: () => {
                        invalidateCodeListDetailCache()
                        setSuccessMessage(t('codeListDetail.feedback.returnToMainGestor'))
                    },
                },
            )
    }

    const handleRemoveLock = (close: () => void) => {
        close()
        unlockTemporalMutation.mutate(
            { code: codeListData?.code ?? '' },
            {
                onSuccess: () => {
                    invalidateCodelists(codeListData?.code ?? '', Number(codeListData?.id))
                    const path = `${NavigationSubRoutes.CODELIST}/${codeListData?.id}`
                    setIsActionSuccess({ value: true, path })
                    navigate(path)
                },
            },
        )
    }

    const handleDiscardChanges = (close: () => void) => {
        close()
        deleteTemporalMutation.mutate(
            { code: codeListData?.code ?? '' },
            {
                onSuccess: () => {
                    invalidateCodelists(codeListData?.code ?? '', Number(codeListData?.id))
                    const path = `${NavigationSubRoutes.CODELIST}`
                    setIsActionSuccess({ value: true, path })
                    navigate(path)
                },
            },
        )
    }

    const handleEdit = () => {
        navigate(`${NavigationSubRoutes.CODELIST}/${codeListData?.id}/edit`)
    }

    const isLoading = [isLoadingRoleParticipants, isLoadingAttributeProfile, isLoadingData, isLoadingOriginal].some((item) => item)
    const isError = [isErrorRoleParticipants, isErrorAttributeProfile, isErrorData, isErrorOriginal].some((item) => item)
    const isLoadingMutation = [codelistActionMutation, itemsActionMutation, unlockTemporalMutation, deleteTemporalMutation].some(
        (item) => item.isLoading,
    )
    const isSuccessMutation = [codelistActionMutation, itemsActionMutation].some((item) => item.isSuccess)
    const actionsErrorMessages = getErrorTranslateKeys(
        [codelistActionMutation, itemsActionMutation, unlockTemporalMutation, deleteTemporalMutation].map(
            (item) => item.error as { message: string },
        ),
    )

    const code = data.codeList?.code ?? ''

    const { filter: itemsFilter, handleFilterChange: handleItemsFilterChange } = useFilterParams<CodeListDetailFilterData>({
        ...defaultFilterValues,
    })
    const [itemsMutationErrors, setItemsMutationErrors] = useState<itemActionError[]>([])
    const [isSuccessItemActionMutation, setIsItemsMutationSuccess] = useState<boolean>(false)

    const {
        isFetching: isLoadingItems,
        isError: isErrorItems,
        data: itemsData,
    } = useGetCodelistItems(
        code ?? '',
        {
            language: 'sk',
            pageNumber: itemsFilter.pageNumber ?? BASE_PAGE_NUMBER,
            perPage: itemsFilter.pageSize ?? BASE_PAGE_SIZE,
            ...(itemsFilter.state && { state: itemsFilter.state }),
            ...(itemsFilter.effective === CodeListFilterEffective.TRUE && { effective: true }),
            sortBy: itemsFilter.sort?.[0]?.orderBy ?? 'itemCode',
            ascending: itemsFilter.sort?.[0]?.sortDirection === SortType.ASC,
        },
        {
            query: {
                select: (codelistData) => ({
                    codelistsItemCount: codelistData.codelistsItemCount,
                    codelistsItems: codelistData.codelistsItems?.map((item) => ({
                        ...item,
                        codelistItemName: selectBasedOnLanguageAndDate(item.codelistItemNames, workingLanguage),
                        isEffective: isEffective(item.codelistItemValidities ?? []),
                    })),
                }),
            },
        },
    )

    const mutationItemAction = useProcessItemAction()

    const onItemsModalOpen = () => {
        setIsItemsMutationSuccess(false)
        setItemsMutationErrors([])
    }

    const handleMarkForPublish = (itemCodes: string[], onSuccess?: () => void) => {
        Promise.allSettled(
            itemCodes.map(async (itemCode) => {
                return mutationItemAction.mutateAsync({
                    code,
                    itemCode,
                    params: {
                        action: ApiCodeListItemsActions.CODELIST_ITEM_TO_READY_TO_PUBLISH,
                    },
                })
            }),
        ).then((results) => {
            const errors = results
                .filter((result) => result.status === PromiseStatus.REJECTED)
                .map((error, index) => {
                    return { itemCode: itemCodes[index], message: JSON.parse((error as PromiseRejectedResult).reason.message).message }
                })
            setItemsMutationErrors(errors)
            if (!errors.length) {
                setIsItemsMutationSuccess(true)
                invalidateCodeListDetailCache()
                onSuccess?.()
            }
        })
    }

    const handleSetDates = async (itemCodes: string[], validFrom: string, effectiveFrom: string, onSuccess?: () => void) => {
        Promise.allSettled(
            itemCodes.map(async (itemCode) => {
                return mutationItemAction.mutateAsync({
                    code,
                    itemCode,
                    params: {
                        action: ApiCodeListItemsActions.SET_DATES,
                        effectiveFrom: formatDateForDefaultValue(validFrom, 'dd.MM.yyyy'),
                        validFrom: formatDateForDefaultValue(effectiveFrom, 'dd.MM.yyyy'),
                    },
                })
            }),
        ).then((results) => {
            const errors = results
                .filter((result) => result.status === PromiseStatus.REJECTED)
                .map((error, index) => {
                    return { itemCode: itemCodes[index], message: JSON.parse((error as PromiseRejectedResult).reason.message).message }
                })
            setItemsMutationErrors(errors)
            if (!errors.length) {
                setIsItemsMutationSuccess(true)
                invalidateCodeListDetailCache()
                onSuccess?.()
            }
        })
    }

    const isLoadingItemsTable = [isLoadingItems, isLoadingAttributeProfile].some((item) => item)
    const isErrorItemsTable = [isErrorItems, isErrorAttributeProfile].some((item) => item)

    return (
        <View
            data={data}
            isLoading={isLoading}
            isLoadingMutation={isLoadingMutation}
            isError={isError}
            actionsErrorMessages={actionsErrorMessages}
            isSuccessMutation={isSuccessMutation}
            successMessage={successMessage}
            workingLanguage={workingLanguage}
            setWorkingLanguage={setWorkingLanguage}
            invalidateCodeListDetailCache={invalidateCodeListDetailCache}
            invalidateCodeListDetailItemsCache={invalidateCodeListDetailItemsCache}
            handleAllItemsReadyToPublish={handleAllItemsReadyToPublish}
            handleSendToIsvs={handleSendToIsvs}
            handlePublishCodeList={handlePublishCodeList}
            handleSendToSzzc={handleSendToSzzc}
            handleReturnToMainGestor={handleReturnToMainGestor}
            handleRemoveLock={handleRemoveLock}
            handleDiscardChanges={handleDiscardChanges}
            handleEdit={handleEdit}
            itemsFilter={itemsFilter}
            handleItemsFilterChange={handleItemsFilterChange}
            handleMarkForPublish={handleMarkForPublish}
            handleSetDates={handleSetDates}
            isErrorItemsTable={isErrorItemsTable}
            isLoadingItemAction={mutationItemAction.isLoading}
            isLoadingItemsTable={isLoadingItemsTable}
            isSuccessItemActionMutation={isSuccessItemActionMutation}
            itemActionErrors={itemsMutationErrors}
            onItemsModalOpen={onItemsModalOpen}
            itemsData={itemsData}
            attributeProfile={attributeProfile}
            rowSelection={rowSelection}
        />
    )
}
