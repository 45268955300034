import { BreadCrumbs, Filter, HomeIcon, Input } from '@isdd/idsk-ui-kit/index'
import { IFilterParams } from '@isdd/metais-common/hooks/useFilter'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MetainformationColumns } from '@isdd/metais-common/componentHelpers/ci/getCiDefaultMetaAttributes'
import { DynamicFilterAttributes, ExtendedAttribute } from '@isdd/metais-common/components/dynamicFilterAttributes/DynamicFilterAttributes'
import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'

import { getCiHowToBreadCrumb, useGetEntityParamsFromUrl } from '@/componentHelpers/ci'
import { MainContentWrapper } from '@/components/MainContentWrapper'
import { CiListContainer } from '@/components/containers/CiListContainer'
import { CustomFilterProps, ListWrapper } from '@/components/list-wrapper/ListWrapper'
import { CiListWizard } from '@/components/wizards/CiListWizard'

export interface CIFilterData extends IFilterParams {
    Gen_Profil_nazov?: string
    Gen_Profil_kod_metais?: string
}

const TrainingsFilter: React.FC<CustomFilterProps> = ({ defaultFilterValues, entityName, attributes, attributeProfiles, constraintsData }) => {
    const { t } = useTranslation()
    return (
        <Filter<CIFilterData>
            defaultFilterValues={defaultFilterValues}
            form={({ register, filter, setValue, isOpen }) => {
                return (
                    <div>
                        <Input
                            id="name"
                            label={t(`filter.${entityName}.name`)}
                            placeholder={t(`filter.namePlaceholder`)}
                            {...register('Gen_Profil_nazov')}
                        />

                        <DynamicFilterAttributes
                            setValue={setValue}
                            defaults={defaultFilterValues}
                            filterData={{
                                attributeFilters: filter.attributeFilters ?? {},
                                metaAttributeFilters: filter.metaAttributeFilters ?? {},
                            }}
                            attributes={attributes as ExtendedAttribute[]}
                            attributeProfiles={attributeProfiles}
                            constraintsData={constraintsData}
                            isFocusable={isOpen}
                            ignoreInputNames={[MetainformationColumns.OWNER]}
                        />
                    </div>
                )
            }}
        />
    )
}

export const TrainingListPage: React.FC = () => {
    const { entityName: ciType } = useGetEntityParamsFromUrl()
    const { t } = useTranslation()

    const defaultFilterValues: CIFilterData = { Gen_Profil_nazov: '', Gen_Profil_kod_metais: '' }

    return (
        <>
            <CiListContainer<CIFilterData>
                entityName={ciType ?? ''}
                defaultFilterValues={defaultFilterValues}
                ListComponent={(props) => (
                    <>
                        <BreadCrumbs
                            withWidthContainer
                            links={[
                                { label: t('breadcrumbs.home'), href: '/', icon: HomeIcon },
                                ...getCiHowToBreadCrumb(props.entityName, t),
                                {
                                    label: t('titles.ciList', { ci: props.ciTypeData?.name }) ?? '',
                                    href: `/ci/${ciType}`,
                                },
                            ]}
                        />
                        {!props.isLoading && <CiListWizard />}
                        <MainContentWrapper>
                            <ListWrapper
                                customFilter={(filterProps) => <TrainingsFilter {...filterProps} />}
                                {...props}
                                columnListData={{
                                    ...props.columnListData,
                                    attributes: props.columnListData?.attributes?.filter((a) => a.name !== ATTRIBUTE_NAME.Gen_Profil_kod_metais),
                                    metaAttributes: props.columnListData?.metaAttributes?.filter((ma) => ma.name !== 'owner'),
                                }}
                                attributes={props.attributes?.filter((a) => a.technicalName !== ATTRIBUTE_NAME.Gen_Profil_kod_metais)}
                            />
                        </MainContentWrapper>
                    </>
                )}
            />
        </>
    )
}
