import { ENTITY_ACTIVITY, P_REALIZUJE_AKT } from '@isdd/metais-common/constants'
import React from 'react'

import { useGetEntityParamsFromUrl } from '@/componentHelpers/ci'
import { ProjectActivitiesAndGoalsListContainer } from '@/components/containers/ProjectActivitiesAndGoalsListContainer'
import { ProjectActivitiesAndGoalsView } from '@/components/views/ci/activities/ProjectActivitiesAndGoalsView'

const ActivitiesListPage: React.FC = () => {
    const { entityId } = useGetEntityParamsFromUrl()

    return (
        <ProjectActivitiesAndGoalsListContainer
            ciType={ENTITY_ACTIVITY}
            relType={P_REALIZUJE_AKT}
            configurationItemId={entityId}
            View={(props) => {
                return <ProjectActivitiesAndGoalsView {...props} />
            }}
        />
    )
}

export default ActivitiesListPage
