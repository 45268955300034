import { ATTRIBUTE_PROFILE_NAME, ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { ConfigurationItemUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { CiType } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { METODA_RIADENIA_PROJEKTU, FAZA_PROJEKTU_AGILE, FAZA_PROJEKTU, STAV_PROJEKTU_AGILE, STAV_PROJEKTU } from '@isdd/metais-common/constants'

export function updateAttributeConstraints(ciTypeData: CiType | undefined, ciItemData: ConfigurationItemUi | undefined) {
    ciTypeData?.attributeProfiles
        ?.find((p) => p.technicalName === ATTRIBUTE_PROFILE_NAME.EA_Profil_Projekt)
        ?.attributes?.forEach((a) => {
            const isFazaProjeku = a.technicalName === ATTRIBUTE_NAME.EA_Profil_Projekt_faza_projektu
            const isStatusProjektu = a.technicalName === ATTRIBUTE_NAME.EA_Profil_Projekt_status
            const isAgile = ciItemData?.attributes?.[ATTRIBUTE_NAME.EA_Profil_Projekt_metoda_riadenia_projektu] === METODA_RIADENIA_PROJEKTU.agile

            if (isFazaProjeku) {
                a.constraints = [{ enumCode: isAgile ? FAZA_PROJEKTU_AGILE : FAZA_PROJEKTU, type: 'enum' }]
            } else if (isStatusProjektu) {
                a.constraints = [{ enumCode: isAgile ? STAV_PROJEKTU_AGILE : STAV_PROJEKTU, type: 'enum' }]
            }
        })
}
