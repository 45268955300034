import React, { PropsWithChildren, forwardRef } from 'react'
import classNames from 'classnames'

import styles from './styles.module.scss'

interface IButton extends PropsWithChildren {
    onClick: () => void
    type?: 'button' | 'submit' | 'reset'
    ariaHidden?: boolean
    tabindex?: number
    btnWrapperClassName?: string
}

export const TransparentButtonWrapper = forwardRef<HTMLButtonElement, IButton>(
    ({ children, onClick, type, ariaHidden, btnWrapperClassName, ...rest }, ref) => {
        return (
            <button
                {...ref}
                {...rest}
                onClick={onClick}
                className={classNames([styles.transparentButton, btnWrapperClassName])}
                type={type ?? 'button'}
                aria-hidden={ariaHidden}
            >
                {children}
            </button>
        )
    },
)
