import React from 'react'
import sanitizeHtml, { defaults } from 'sanitize-html'

import styles from './styles.module.scss'

type SafeHtmlProps = {
    dirtyHtml: string
}

export const sanitizedHtml = (dirtyHtml: string) =>
    sanitizeHtml(dirtyHtml, {
        allowedTags: defaults.allowedTags.concat(['img']),
        allowedAttributes: {
            ...defaults.allowedAttributes,
            img: ['src', 'alt', 'title'],
        },
    })

export const SafeHtmlComponent: React.FC<SafeHtmlProps> = ({ dirtyHtml }) => {
    return <span className={styles.fontFamily} dangerouslySetInnerHTML={{ __html: sanitizedHtml(dirtyHtml) }} />
}
