import React, { useEffect } from 'react'
import { GridCol, GridRow } from '@isdd/idsk-ui-kit/index'
import { BASE_PAGE_NUMBER, BASE_PAGE_SIZE } from '@isdd/metais-common/api'
import { useParams } from 'react-router-dom'
import { useCustomPagination } from '@isdd/metais-common/hooks/useCustomPagination'
import { getPaginatedData, sortByName } from '@isdd/metais-common/utils/utils'
import { useTranslation } from 'react-i18next'

import styles from './historyCompare.module.scss'

import { DataTableWithPagination } from '@/components/table-with-pagination/TableWithPagination'
import { getDifferent, getIsDiff, getRelationDiffColumns, IRelationItem } from '@/componentHelpers/ci/relationsCompare'

interface IRelationCompareItemViewProps {
    label: string
    tooltip: string
    dataRelationFirst?: IRelationItem[]
    dataRelationSecond?: IRelationItem[]
    isSimple?: boolean
    withoutCompare?: boolean
    showOnlyChanges?: boolean
}

export const RelationCompareItemView: React.FC<IRelationCompareItemViewProps> = ({
    showOnlyChanges,
    dataRelationFirst,
    dataRelationSecond,
    isSimple,
}) => {
    const { entityId } = useParams()
    const { t } = useTranslation()

    const sortedFirstRel = sortByName([...(dataRelationFirst ?? [])])
    const sortedSecondRel = sortByName([...(dataRelationSecond ?? [])])

    const [diffFirstArr, diffSecArr] = getDifferent(sortedFirstRel, sortedSecondRel, entityId ?? '')
    const isDiff = getIsDiff(dataRelationFirst ?? [], dataRelationSecond ?? [], entityId ?? '')
    const showSection = !showOnlyChanges || (isDiff && showOnlyChanges)
    const { columns, columnsSec } = getRelationDiffColumns(diffFirstArr, diffSecArr, entityId ?? '', !!isSimple, t)

    const { pageNumber, pageSize, dataLength, startOfList, endOfList, setPagination: setFirstPagination } = useCustomPagination(sortedFirstRel.length)
    const {
        pageNumber: secPageNumber,
        pageSize: secPageSize,
        dataLength: secDataLength,
        startOfList: secStartOfList,
        endOfList: secEndOfList,
        setPagination: setSecPagination,
    } = useCustomPagination(sortedSecondRel.length)

    //on change of showOnlyChanges reset pagination with diff data length
    useEffect(() => {
        const defaultPagination = {
            pageNumber: BASE_PAGE_NUMBER,
            pageSize: BASE_PAGE_SIZE,
        }

        if (!showOnlyChanges) {
            setFirstPagination({
                ...defaultPagination,
                dataLength: sortedFirstRel.length,
            })
            setSecPagination({
                ...defaultPagination,
                dataLength: sortedSecondRel.length,
            })
        } else {
            setFirstPagination({
                ...defaultPagination,
                dataLength: diffFirstArr.length,
            })
            setSecPagination({
                ...defaultPagination,
                dataLength: diffSecArr.length,
            })
        }
    }, [diffFirstArr.length, diffSecArr.length, setFirstPagination, setSecPagination, showOnlyChanges, sortedFirstRel.length, sortedSecondRel.length])

    return (
        <>
            {showSection && (
                <GridRow>
                    <GridCol setWidth={isSimple ? 'full' : 'one-half'} className={styles.oneHalf}>
                        <DataTableWithPagination
                            data={getPaginatedData(showOnlyChanges ? diffFirstArr : sortedFirstRel, startOfList, endOfList)}
                            columns={columns}
                            pageNumber={pageNumber}
                            pageSize={pageSize}
                            dataLength={dataLength}
                            handlePageChange={(paging) =>
                                setFirstPagination((prev) => (paging.pageNumber ? { ...prev, pageNumber: paging.pageNumber } : prev))
                            }
                            width="one-half"
                        />
                    </GridCol>
                    {!isSimple && (
                        <GridCol setWidth="one-half" className={styles.oneHalf}>
                            <DataTableWithPagination
                                data={getPaginatedData(showOnlyChanges ? diffSecArr : sortedSecondRel, secStartOfList, secEndOfList)}
                                columns={columnsSec}
                                pageNumber={secPageNumber}
                                pageSize={secPageSize}
                                dataLength={secDataLength}
                                handlePageChange={(paging) =>
                                    setSecPagination((prev) => (paging.pageNumber ? { ...prev, pageNumber: paging.pageNumber } : prev))
                                }
                                width="one-half"
                            />
                        </GridCol>
                    )}
                </GridRow>
            )}
        </>
    )
}
