import { Languages } from '@isdd/metais-common/localization/languages'
import { useInvalidateLangChange } from '@isdd/metais-common/hooks/invalidate-cache'
import { LANGUAGE_STORE_KEY } from '@isdd/metais-common/localization/i18next'
import classnames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { STICKY_HEADER_Z_INDEX } from '@isdd/metais-common/constants'

import { ButtonPopup } from '@isdd/idsk-ui-kit'

interface ILanguageItem {
    handleClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, lng: Languages, closeHandler: () => void) => void
    value: Languages
    lang?: Languages
    closeHandler: () => void
}

export const LanguageItem: React.FC<ILanguageItem> = ({ handleClick, value, lang, closeHandler }) => {
    const { t, i18n } = useTranslation()
    return (
        <li className="idsk-header-web__brand-language-list-item">
            <a
                className={classnames({
                    'govuk-link idsk-header-web__brand-language-list-item-link': true,
                    'idsk-header-web__brand-language-list-item-link--selected': i18n.language === value,
                })}
                title={t(`language.${value}`) ?? ''}
                href="#"
                aria-current={value === i18n.language}
                lang={lang}
                onClick={(event) => handleClick(event, value, closeHandler)}
            >
                {t(`language.${value}`)}
            </a>
        </li>
    )
}

export const LanguageSelector: React.FC = () => {
    const { i18n, t } = useTranslation()
    const { invalidate } = useInvalidateLangChange()
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, lng: Languages, closeHandler: () => void) => {
        document.documentElement.setAttribute('lang', lng)
        localStorage.setItem(LANGUAGE_STORE_KEY, lng)
        event.preventDefault()

        i18n.changeLanguage(lng)
        closeHandler()
    }

    useEffect(() => {
        invalidate()
    }, [i18n.language, invalidate])

    const [popupPosition, setPopupPosition] = useState<'left' | 'right'>('left')

    const setProperPopupPosition = useCallback((windowSize: number) => {
        const POSITION_BREAKPOINT_PX = 641
        if (windowSize <= POSITION_BREAKPOINT_PX && popupPosition !== 'right') {
            setPopupPosition('right')
        } else {
            setPopupPosition('left')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setProperPopupPosition(window.innerWidth)
        addEventListener('resize', () => {
            setProperPopupPosition(window.innerWidth)
        })
        return () => {
            removeEventListener('resize', () => {
                setProperPopupPosition(window.innerWidth)
            })
        }
    }, [setProperPopupPosition])

    return (
        <div className={classnames({ 'idsk-header-web__brand-language': true })}>
            <ButtonPopup
                contentStyle={{ zIndex: STICKY_HEADER_Z_INDEX }}
                popupHorizontalPosition={popupPosition}
                customTrigger={({ isExpanded }) => (
                    <button
                        className={classnames({
                            'idsk-header-web__brand-language-button': true,
                            'idsk-header-web__brand-language--active': isExpanded,
                        })}
                        aria-label={isExpanded ? t('languageSelector.close') ?? '' : t('languageSelector.open') ?? ''}
                    >
                        {t(`language.${i18n.language}`)}
                        <span className={classnames('idsk-header-web__link-arrow')} />
                    </button>
                )}
                contentClassNameReplacement="idsk-header-web__brand-language--active"
                popupContent={(closePopup) => (
                    <ul className="idsk-header-web__brand-language-list">
                        <LanguageItem handleClick={handleClick} value={Languages.ENGLISH} lang={Languages.ENGLISH} closeHandler={closePopup} />
                        <LanguageItem handleClick={handleClick} value={Languages.SLOVAK} lang={Languages.SLOVAK} closeHandler={closePopup} />
                        {import.meta.env.VITE_LOCALES_SHOW_KEYS == 'true' && (
                            <LanguageItem handleClick={handleClick} value={Languages.CI_MODE} closeHandler={closePopup} />
                        )}
                    </ul>
                )}
            />
        </div>
    )
}
