//@ts-nocheck
/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * MetaIS Enums Repo
 * OpenAPI spec version: latest
 */
import { useQuery, useMutation } from '@tanstack/react-query'
import type { UseQueryOptions, UseMutationOptions, QueryFunction, MutationFunction, UseQueryResult, QueryKey } from '@tanstack/react-query'
import { useEnumsRepoSwaggerClient } from '../hooks/useEnumsRepoSwaggerClient'
export type ListValidEnumsWithValueParams = {
    value: string
    description: string
}

export type GetValidEnumParams = {
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: string[]
}

export interface EnumTypePreview {
    id?: number
    code?: string
    name?: string
    version?: string
    description?: string
    valid?: boolean
    category?: string
}

export interface EnumTypePreviewList {
    results?: EnumTypePreview[]
}

export interface EnumItem {
    id?: number
    code?: string
    value?: string
    valid?: boolean
    description?: string
    orderList?: number
    engValue?: string
    engDescription?: string
}

export interface EnumType {
    id?: number
    code?: string
    name?: string
    description?: string
    valid?: boolean
    category?: string
    enumItems?: EnumItem[]
}

export type ApiErrorData = { [key: string]: any }

export interface ApiError {
    type?: string
    message?: string
    data?: ApiErrorData
    logToken?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const useUpdateEnumTypeHook = () => {
    const updateEnumType = useEnumsRepoSwaggerClient<void>()

    return (enumType: EnumType) => {
        return updateEnumType({ url: `/enums/enum`, method: 'put', headers: { 'Content-Type': 'application/json' }, data: enumType })
    }
}

export const useUpdateEnumTypeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateEnumTypeHook>>>, TError, { data: EnumType }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateEnumTypeHook>>>, TError, { data: EnumType }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateEnumType = useUpdateEnumTypeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateEnumTypeHook>>>, { data: EnumType }> = (props) => {
        const { data } = props ?? {}

        return updateEnumType(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateEnumTypeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateEnumTypeHook>>>>
export type UpdateEnumTypeMutationBody = EnumType
export type UpdateEnumTypeMutationError = ApiError

export const useUpdateEnumType = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateEnumTypeHook>>>, TError, { data: EnumType }, TContext>
}) => {
    const mutationOptions = useUpdateEnumTypeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useInsertEnumTypeHook = () => {
    const insertEnumType = useEnumsRepoSwaggerClient<void>()

    return (enumType: EnumType) => {
        return insertEnumType({ url: `/enums/enum`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: enumType })
    }
}

export const useInsertEnumTypeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useInsertEnumTypeHook>>>, TError, { data: EnumType }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useInsertEnumTypeHook>>>, TError, { data: EnumType }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const insertEnumType = useInsertEnumTypeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useInsertEnumTypeHook>>>, { data: EnumType }> = (props) => {
        const { data } = props ?? {}

        return insertEnumType(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type InsertEnumTypeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useInsertEnumTypeHook>>>>
export type InsertEnumTypeMutationBody = EnumType
export type InsertEnumTypeMutationError = ApiError

export const useInsertEnumType = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useInsertEnumTypeHook>>>, TError, { data: EnumType }, TContext>
}) => {
    const mutationOptions = useInsertEnumTypeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetValidEnumHook = () => {
    const getValidEnum = useEnumsRepoSwaggerClient<EnumType>()

    return (code: string, params?: GetValidEnumParams, signal?: AbortSignal) => {
        return getValidEnum({ url: `/enums/enum/valid/${code}`, method: 'get', params, signal })
    }
}

export const getGetValidEnumQueryKey = (code: string, params?: GetValidEnumParams) =>
    [`/enums/enum/valid/${code}`, ...(params ? [params] : [])] as const

export const useGetValidEnumQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetValidEnumHook>>>, TError = ApiError>(
    code: string,
    params?: GetValidEnumParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetValidEnumHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetValidEnumHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetValidEnumQueryKey(code, params)

    const getValidEnum = useGetValidEnumHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetValidEnumHook>>>> = ({ signal }) => getValidEnum(code, params, signal)

    return { queryKey, queryFn, enabled: !!code, ...queryOptions }
}

export type GetValidEnumQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetValidEnumHook>>>>
export type GetValidEnumQueryError = ApiError

export const useGetValidEnum = <TData = Awaited<ReturnType<ReturnType<typeof useGetValidEnumHook>>>, TError = ApiError>(
    code: string,
    params?: GetValidEnumParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetValidEnumHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetValidEnumQueryOptions(code, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useValidEnumTypeHook = () => {
    const validEnumType = useEnumsRepoSwaggerClient<void>()

    return (code: string) => {
        return validEnumType({ url: `/enums/enum/valid/${code}`, method: 'put' })
    }
}

export const useValidEnumTypeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useValidEnumTypeHook>>>, TError, { code: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useValidEnumTypeHook>>>, TError, { code: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const validEnumType = useValidEnumTypeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useValidEnumTypeHook>>>, { code: string }> = (props) => {
        const { code } = props ?? {}

        return validEnumType(code)
    }

    return { mutationFn, ...mutationOptions }
}

export type ValidEnumTypeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useValidEnumTypeHook>>>>

export type ValidEnumTypeMutationError = ApiError

export const useValidEnumType = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useValidEnumTypeHook>>>, TError, { code: string }, TContext>
}) => {
    const mutationOptions = useValidEnumTypeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateEnumItemHook = () => {
    const updateEnumItem = useEnumsRepoSwaggerClient<void>()

    return (enumItem: EnumItem) => {
        return updateEnumItem({ url: `/enumitems/enumitem`, method: 'put', headers: { 'Content-Type': 'application/json' }, data: enumItem })
    }
}

export const useUpdateEnumItemMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateEnumItemHook>>>, TError, { data: EnumItem }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateEnumItemHook>>>, TError, { data: EnumItem }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateEnumItem = useUpdateEnumItemHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateEnumItemHook>>>, { data: EnumItem }> = (props) => {
        const { data } = props ?? {}

        return updateEnumItem(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateEnumItemMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateEnumItemHook>>>>
export type UpdateEnumItemMutationBody = EnumItem
export type UpdateEnumItemMutationError = ApiError

export const useUpdateEnumItem = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateEnumItemHook>>>, TError, { data: EnumItem }, TContext>
}) => {
    const mutationOptions = useUpdateEnumItemMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useValidEnumItemHook = () => {
    const validEnumItem = useEnumsRepoSwaggerClient<void>()

    return (code: string) => {
        return validEnumItem({ url: `/enumitems/enumitem/valid/${code}`, method: 'put' })
    }
}

export const useValidEnumItemMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useValidEnumItemHook>>>, TError, { code: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useValidEnumItemHook>>>, TError, { code: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const validEnumItem = useValidEnumItemHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useValidEnumItemHook>>>, { code: string }> = (props) => {
        const { code } = props ?? {}

        return validEnumItem(code)
    }

    return { mutationFn, ...mutationOptions }
}

export type ValidEnumItemMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useValidEnumItemHook>>>>

export type ValidEnumItemMutationError = ApiError

export const useValidEnumItem = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useValidEnumItemHook>>>, TError, { code: string }, TContext>
}) => {
    const mutationOptions = useValidEnumItemMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useInsertEnumItemHook = () => {
    const insertEnumItem = useEnumsRepoSwaggerClient<void>()

    return (codeEnumType: string, enumItem: EnumItem) => {
        return insertEnumItem({
            url: `/enumitems/enumitem/${codeEnumType}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: enumItem,
        })
    }
}

export const useInsertEnumItemMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useInsertEnumItemHook>>>,
        TError,
        { codeEnumType: string; data: EnumItem },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useInsertEnumItemHook>>>, TError, { codeEnumType: string; data: EnumItem }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const insertEnumItem = useInsertEnumItemHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useInsertEnumItemHook>>>, { codeEnumType: string; data: EnumItem }> = (
        props,
    ) => {
        const { codeEnumType, data } = props ?? {}

        return insertEnumItem(codeEnumType, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type InsertEnumItemMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useInsertEnumItemHook>>>>
export type InsertEnumItemMutationBody = EnumItem
export type InsertEnumItemMutationError = ApiError

export const useInsertEnumItem = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useInsertEnumItemHook>>>,
        TError,
        { codeEnumType: string; data: EnumItem },
        TContext
    >
}) => {
    const mutationOptions = useInsertEnumItemMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetJobListHook = () => {
    const getJobList = useEnumsRepoSwaggerClient<string[]>()

    return (signal?: AbortSignal) => {
        return getJobList({ url: `/scheduler/jobList`, method: 'get', signal })
    }
}

export const getGetJobListQueryKey = () => [`/scheduler/jobList`] as const

export const useGetJobListQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetJobListHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobListHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobListHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetJobListQueryKey()

    const getJobList = useGetJobListHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetJobListHook>>>> = ({ signal }) => getJobList(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetJobListQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetJobListHook>>>>
export type GetJobListQueryError = ApiError

export const useGetJobList = <TData = Awaited<ReturnType<ReturnType<typeof useGetJobListHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJobListHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetJobListQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFireJobNowHook = () => {
    const fireJobNow = useEnumsRepoSwaggerClient<boolean>()

    return (jobName: string, signal?: AbortSignal) => {
        return fireJobNow({ url: `/scheduler/fire/${jobName}`, method: 'get', signal })
    }
}

export const getFireJobNowQueryKey = (jobName: string) => [`/scheduler/fire/${jobName}`] as const

export const useFireJobNowQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFireJobNowHook>>>, TError = ApiError>(
    jobName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFireJobNowHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFireJobNowHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFireJobNowQueryKey(jobName)

    const fireJobNow = useFireJobNowHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFireJobNowHook>>>> = ({ signal }) => fireJobNow(jobName, signal)

    return { queryKey, queryFn, enabled: !!jobName, ...queryOptions }
}

export type FireJobNowQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFireJobNowHook>>>>
export type FireJobNowQueryError = ApiError

export const useFireJobNow = <TData = Awaited<ReturnType<ReturnType<typeof useFireJobNowHook>>>, TError = ApiError>(
    jobName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFireJobNowHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFireJobNowQueryOptions(jobName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useListEnumsHook = () => {
    const listEnums = useEnumsRepoSwaggerClient<EnumTypePreviewList>()

    return (signal?: AbortSignal) => {
        return listEnums({ url: `/enums/list`, method: 'get', signal })
    }
}

export const getListEnumsQueryKey = () => [`/enums/list`] as const

export const useListEnumsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListEnumsHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListEnumsHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListEnumsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListEnumsQueryKey()

    const listEnums = useListEnumsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListEnumsHook>>>> = ({ signal }) => listEnums(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type ListEnumsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListEnumsHook>>>>
export type ListEnumsQueryError = ApiError

export const useListEnums = <TData = Awaited<ReturnType<ReturnType<typeof useListEnumsHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListEnumsHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListEnumsQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useListLicenseEnumsHook = () => {
    const listLicenseEnums = useEnumsRepoSwaggerClient<EnumTypePreviewList>()

    return (signal?: AbortSignal) => {
        return listLicenseEnums({ url: `/enums/list/license`, method: 'get', signal })
    }
}

export const getListLicenseEnumsQueryKey = () => [`/enums/list/license`] as const

export const useListLicenseEnumsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useListLicenseEnumsHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListLicenseEnumsHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListLicenseEnumsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListLicenseEnumsQueryKey()

    const listLicenseEnums = useListLicenseEnumsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListLicenseEnumsHook>>>> = ({ signal }) => listLicenseEnums(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type ListLicenseEnumsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListLicenseEnumsHook>>>>
export type ListLicenseEnumsQueryError = ApiError

export const useListLicenseEnums = <TData = Awaited<ReturnType<ReturnType<typeof useListLicenseEnumsHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListLicenseEnumsHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListLicenseEnumsQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useListValidEnumsHook = () => {
    const listValidEnums = useEnumsRepoSwaggerClient<EnumTypePreviewList>()

    return (signal?: AbortSignal) => {
        return listValidEnums({ url: `/enums/list/all`, method: 'get', signal })
    }
}

export const getListValidEnumsQueryKey = () => [`/enums/list/all`] as const

export const useListValidEnumsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListValidEnumsHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListValidEnumsHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListValidEnumsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListValidEnumsQueryKey()

    const listValidEnums = useListValidEnumsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListValidEnumsHook>>>> = ({ signal }) => listValidEnums(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type ListValidEnumsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListValidEnumsHook>>>>
export type ListValidEnumsQueryError = ApiError

export const useListValidEnums = <TData = Awaited<ReturnType<ReturnType<typeof useListValidEnumsHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListValidEnumsHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListValidEnumsQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useListValidEnumsWithValueHook = () => {
    const listValidEnumsWithValue = useEnumsRepoSwaggerClient<EnumTypePreviewList>()

    return (params: ListValidEnumsWithValueParams, signal?: AbortSignal) => {
        return listValidEnumsWithValue({ url: `/enums/list/allWithValue`, method: 'get', params, signal })
    }
}

export const getListValidEnumsWithValueQueryKey = (params: ListValidEnumsWithValueParams) =>
    [`/enums/list/allWithValue`, ...(params ? [params] : [])] as const

export const useListValidEnumsWithValueQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useListValidEnumsWithValueHook>>>,
    TError = ApiError,
>(
    params: ListValidEnumsWithValueParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListValidEnumsWithValueHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListValidEnumsWithValueHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListValidEnumsWithValueQueryKey(params)

    const listValidEnumsWithValue = useListValidEnumsWithValueHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListValidEnumsWithValueHook>>>> = ({ signal }) =>
        listValidEnumsWithValue(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type ListValidEnumsWithValueQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListValidEnumsWithValueHook>>>>
export type ListValidEnumsWithValueQueryError = ApiError

export const useListValidEnumsWithValue = <TData = Awaited<ReturnType<ReturnType<typeof useListValidEnumsWithValueHook>>>, TError = ApiError>(
    params: ListValidEnumsWithValueParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListValidEnumsWithValueHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListValidEnumsWithValueQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetEnumHook = () => {
    const getEnum = useEnumsRepoSwaggerClient<EnumType>()

    return (code: string, signal?: AbortSignal) => {
        return getEnum({ url: `/enums/enum/all/${code}`, method: 'get', signal })
    }
}

export const getGetEnumQueryKey = (code: string) => [`/enums/enum/all/${code}`] as const

export const useGetEnumQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetEnumHook>>>, TError = ApiError>(
    code: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEnumHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEnumHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetEnumQueryKey(code)

    const getEnum = useGetEnumHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetEnumHook>>>> = ({ signal }) => getEnum(code, signal)

    return { queryKey, queryFn, enabled: !!code, ...queryOptions }
}

export type GetEnumQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetEnumHook>>>>
export type GetEnumQueryError = ApiError

export const useGetEnum = <TData = Awaited<ReturnType<ReturnType<typeof useGetEnumHook>>>, TError = ApiError>(
    code: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEnumHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetEnumQueryOptions(code, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useDeleteEnumTypeHook = () => {
    const deleteEnumType = useEnumsRepoSwaggerClient<void>()

    return (code: string) => {
        return deleteEnumType({ url: `/enums/enum/${code}`, method: 'delete' })
    }
}

export const useDeleteEnumTypeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteEnumTypeHook>>>, TError, { code: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteEnumTypeHook>>>, TError, { code: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteEnumType = useDeleteEnumTypeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteEnumTypeHook>>>, { code: string }> = (props) => {
        const { code } = props ?? {}

        return deleteEnumType(code)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteEnumTypeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteEnumTypeHook>>>>

export type DeleteEnumTypeMutationError = ApiError

export const useDeleteEnumType = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteEnumTypeHook>>>, TError, { code: string }, TContext>
}) => {
    const mutationOptions = useDeleteEnumTypeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteEnumItemHook = () => {
    const deleteEnumItem = useEnumsRepoSwaggerClient<void>()

    return (code: string) => {
        return deleteEnumItem({ url: `/enumitems/enumitem/${code}`, method: 'delete' })
    }
}

export const useDeleteEnumItemMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteEnumItemHook>>>, TError, { code: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteEnumItemHook>>>, TError, { code: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteEnumItem = useDeleteEnumItemHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteEnumItemHook>>>, { code: string }> = (props) => {
        const { code } = props ?? {}

        return deleteEnumItem(code)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteEnumItemMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteEnumItemHook>>>>

export type DeleteEnumItemMutationError = ApiError

export const useDeleteEnumItem = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteEnumItemHook>>>, TError, { code: string }, TContext>
}) => {
    const mutationOptions = useDeleteEnumItemMutationOptions(options)

    return useMutation(mutationOptions)
}
