import {
    AttributeUiValue,
    ConfigurationItemUiAttributes,
    useReadCiNeighboursWithAllRelsHook,
    useRecycleInvalidatedRelsHook,
    useStoreConfigurationItem,
    useStoreRelationship,
} from '@isdd/metais-common/api/generated/cmdb-swagger'
import { EnumType } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { CiCode, CiType, useGenerateCodeHook } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { ElementToScrollTo } from '@isdd/metais-common/components/element-to-scroll-to/ElementToScrollTo'
import { DRAFT, INVALIDATED, PO } from '@isdd/metais-common/constants'
import { useGetStatus } from '@isdd/metais-common/hooks/useGetRequestStatus'
import { ATTRIBUTE_NAME, MutationFeedback, QueryFeedback, RELATION_TYPE } from '@isdd/metais-common/index'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'

import { CreateKSEntityForm } from './CreateKSEntityForm'
import { useCiCreateEditOnStatusSuccess, useCiCreateUpdateOnSubmit } from './createEntityHelpers'

import { useKSChannel } from '@/hooks/useChannelKS'
import { PublicAuthorityState, RoleState } from '@/hooks/usePublicAuthorityAndRole.hook'
import { useRolesForPO } from '@/hooks/useRolesForPO'

export interface AttributesData {
    ciTypeData: CiType | undefined
    constraintsData: (EnumType | undefined)[]
    unitsData?: EnumType
}

export interface CreateEntityData {
    attributesData: AttributesData
    generatedEntityId: CiCode | undefined
    ownerId?: string
    poUUID?: string
}

interface ICreateKSEntity {
    entityName: string
    data: CreateEntityData
    roleState?: RoleState
    publicAuthorityState?: PublicAuthorityState
    updateCiItemId?: string
    defaultItemAttributeValues?: ConfigurationItemUiAttributes
    isLoading: boolean
    withCancelButton?: boolean
    submitInModal?: boolean
    isSuccess: boolean
    setIsSuccess: (val: boolean) => void
}

export const CreateKSEntity: React.FC<ICreateKSEntity> = ({
    data,
    entityName,
    updateCiItemId,
    defaultItemAttributeValues,
    roleState,
    publicAuthorityState,
    isLoading,
    withCancelButton = true,
    submitInModal = false,
    isSuccess,
    setIsSuccess,
}) => {
    const { t } = useTranslation()
    const isUpdate = !!updateCiItemId

    const { attributesData, generatedEntityId, ownerId, poUUID } = data
    const { constraintsData, ciTypeData, unitsData } = attributesData
    const onStatusSuccess = useCiCreateEditOnStatusSuccess()
    const { createChannelForKS, isLoading: isSubmitLoading, isError: isSubmitError } = useKSChannel()
    const { rolesForPO, isRightsForPOError, isRightsForPOLoading } = useRolesForPO(
        updateCiItemId ? poUUID ?? '' : publicAuthorityState?.selectedPublicAuthority?.poUUID ?? '',
        ciTypeData?.roleList ?? [],
    )
    const { isError: isRedirectError, isLoading: isRedirectLoading, isProcessedError, getRequestStatus, isTooManyFetchesError } = useGetStatus()
    const { onSubmit, uploadError, setUploadError, configurationItemId, errorMessage } = useCiCreateUpdateOnSubmit(entityName, ciTypeData)

    const { mutateAsync: storeRel, isLoading: isStoreRelationLoading, isError: isStoreRelationError } = useStoreRelationship()
    const generateRelCode = useGenerateCodeHook()
    const readAllCiWithRels = useReadCiNeighboursWithAllRelsHook()
    const recycleRel = useRecycleInvalidatedRelsHook()

    const createPOPoskytovatelKSRel = async (itemId: string) => {
        try {
            const ciWithRels = await readAllCiWithRels(itemId, {
                ciTypes: [PO],
                relTypes: [RELATION_TYPE.PO_je_poskytovatelom_KS],
                state: [DRAFT, INVALIDATED],
            })
            const POPoskytovatelomKSRel = ciWithRels.ciWithRels
                ?.find((ci) => ci.ci?.uuid === poUUID)
                ?.rels?.find((rel) => rel.type === RELATION_TYPE.PO_je_poskytovatelom_KS)

            let requestID = ''

            if (POPoskytovatelomKSRel && POPoskytovatelomKSRel.metaAttributes?.state === DRAFT) {
                setIsSuccess(true)
                return
            } else if (POPoskytovatelomKSRel && POPoskytovatelomKSRel.metaAttributes?.state === INVALIDATED) {
                const status = await recycleRel({ relIdList: [POPoskytovatelomKSRel.uuid ?? ''] })
                requestID = status.requestId ?? ''
            } else {
                const code = await generateRelCode(RELATION_TYPE.PO_je_poskytovatelom_KS)
                const status = await storeRel({
                    data: {
                        type: RELATION_TYPE.PO_je_poskytovatelom_KS,
                        uuid: v4(),
                        startUuid: poUUID,
                        endUuid: itemId,
                        owner: ownerId,
                        attributes: [
                            {
                                name: ATTRIBUTE_NAME.Gen_Profil_Rel_kod_metais,
                                value: code.code as unknown as AttributeUiValue,
                            },
                        ],
                    },
                })
                requestID = status.requestId ?? ''
            }

            if (requestID != null) {
                await getRequestStatus(requestID, () => {
                    setIsSuccess(true)
                    onStatusSuccess({
                        configurationItemId,
                        isUpdate,
                        entityName,
                        withRedirect: false,
                    })
                })
            } else {
                setUploadError(true)
            }
        } catch {
            setUploadError(true)
        }
    }

    const storeConfigurationItem = useStoreConfigurationItem({
        mutation: {
            onError() {
                setUploadError(true)
            },
            async onSuccess(successData, variables) {
                if (successData.requestId != null) {
                    await getRequestStatus(successData.requestId, async () => {
                        await createChannelForKS({ ...variables.data, owner: variables.data.owner ?? ownerId }, () => {
                            onStatusSuccess({
                                configurationItemId,
                                isUpdate,
                                entityName,
                                withRedirect: false,
                            })
                        })
                    })
                    await createPOPoskytovatelKSRel(variables.data.uuid ?? '')
                } else {
                    setUploadError(true)
                }
            },
        },
    })

    return (
        <>
            <ElementToScrollTo
                trigger={isRedirectError || isProcessedError || isRedirectLoading || isTooManyFetchesError || uploadError || isSuccess}
                manualScroll
            >
                <MutationFeedback
                    error={uploadError}
                    errorMessage={errorMessage ? errorMessage : undefined}
                    mutationProcessingError={isProcessedError}
                    mutationTooLong={isTooManyFetchesError}
                    success={isSuccess}
                />
            </ElementToScrollTo>

            <QueryFeedback
                loading={isRedirectLoading || isSubmitLoading || isStoreRelationLoading}
                error={isRedirectError || isSubmitError || isRightsForPOError || isStoreRelationError}
                indicatorProps={{
                    label: isUpdate ? t('createEntity.redirectLoadingEdit') : t('createEntity.redirectLoading'),
                }}
                errorProps={{
                    errorMessage: isUpdate ? t('createEntity.redirectErrorEdit') : t('createEntity.redirectError'),
                }}
                withChildren
            >
                {!isSuccess && (
                    <CreateKSEntityForm
                        entityName={entityName}
                        ciTypeData={ciTypeData}
                        generatedEntityId={generatedEntityId ?? { cicode: '', ciurl: '' }}
                        constraintsData={constraintsData}
                        unitsData={unitsData}
                        uploadError={uploadError}
                        onSubmit={(formData) => {
                            setIsSuccess(false)
                            onSubmit({
                                formData,
                                updateCiItemId,
                                storeCiItem: storeConfigurationItem.mutateAsync,
                                ownerId: ownerId,
                                generatedEntityId,
                                publicAuthorityState,
                            })
                        }}
                        defaultItemAttributeValues={defaultItemAttributeValues}
                        updateCiItemId={updateCiItemId}
                        isProcessing={storeConfigurationItem.isLoading || isLoading || isRightsForPOLoading}
                        selectedOrg={publicAuthorityState?.selectedPublicAuthority}
                        selectedRole={roleState?.selectedRole ?? null}
                        rolesForPO={rolesForPO ?? []}
                        withCancelButton={withCancelButton}
                        submitInModal={submitInModal}
                    />
                )}
            </QueryFeedback>
        </>
    )
}
