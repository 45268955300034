import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollToTop } from '@isdd/metais-common/components/scroll-to-top/ScrollToTop'
import { setDocumentTitle } from '@isdd/metais-common/utils/utils'

import { useIdentityTerms } from './hooks/useIdentityTerms'

import { Router } from '@/navigation/Router'

export const App: React.FC = () => {
    useIdentityTerms()
    const { t } = useTranslation()
    setDocumentTitle(t('titles.mainPage'))
    return (
        <Suspense>
            <ScrollToTop />
            <Router />
        </Suspense>
    )
}
