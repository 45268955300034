import { TextHeading } from '@isdd/idsk-ui-kit'
import { Del } from '@isdd/idsk-ui-kit/typography/Del'
import styles from '@isdd/metais-common/components/entity-header/ciEntityHeader.module.scss'
import React from 'react'

interface Props {
    entityItemName: string
    isInvalidated: boolean
}

export const POEntityDetailHeader: React.FC<Props> = ({ entityItemName, isInvalidated }) => {
    return (
        <div className={styles.headerDiv}>
            <TextHeading size="XL">
                <Del isInvalid={isInvalidated}>{entityItemName}</Del>
            </TextHeading>
        </div>
    )
}
