import styles from '@isdd/metais-common/components/GridView.module.scss'
import { Sidebar } from '@isdd/metais-common/components/sidebar/Sidebar'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import classNames from 'classnames'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { GlobalSearchFilter } from './global-search-filter/GlobalSearchFilter'
import { getPortalNavigationItems } from './navbar/navigationItems'

import { useOtherEntityHook } from '@/hooks/useOtherEntity.hook'

type MainContentWrapperProps = React.PropsWithChildren & {
    globalSearch?: boolean
    noSideMenu?: boolean
}

export const MainContentWrapper: React.FC<MainContentWrapperProps> = ({ children, globalSearch, noSideMenu }) => {
    const { t } = useTranslation()
    const {
        state: { user },
    } = useAuth()

    const { ciOtherEntityItems } = useOtherEntityHook()

    const [isSidebarExpanded, setIsSidebarExpanded] = useState(true)
    const isSideMenu = true
    const sidebarSections = useMemo(
        () => getPortalNavigationItems(t, user, ciOtherEntityItems, isSideMenu),
        [ciOtherEntityItems, isSideMenu, t, user],
    )

    return (
        <div className={classNames({ [styles.container]: !noSideMenu, [styles.reverse]: globalSearch })}>
            {!globalSearch && !noSideMenu && (
                <Sidebar isSidebarExpanded={isSidebarExpanded} setIsSidebarExpanded={setIsSidebarExpanded} sections={sidebarSections} />
            )}
            {globalSearch && <GlobalSearchFilter />}

            <main
                id="main-content"
                className={classNames(
                    'govuk-main-wrapper govuk-main-wrapper--auto-spacing',
                    !noSideMenu && styles.content,
                    !isSidebarExpanded && styles.closedContent,
                )}
            >
                {children}
            </main>
        </div>
    )
}
