import { API_STANDARD_REQUEST_ATTRIBUTES } from '@isdd/metais-common/api'

export const customMainAttributesForVersion2 = [
    API_STANDARD_REQUEST_ATTRIBUTES.description,
    API_STANDARD_REQUEST_ATTRIBUTES.placementProposal,
    API_STANDARD_REQUEST_ATTRIBUTES.legislativeTextProposal,
]

export const customAttributesForVersion2 = [
    API_STANDARD_REQUEST_ATTRIBUTES.financialImpact,
    API_STANDARD_REQUEST_ATTRIBUTES.securityImpact,
    API_STANDARD_REQUEST_ATTRIBUTES.privacyImpact,
]
