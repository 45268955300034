import {
    baseWikiUrl,
    ENTITY_ISVS,
    ENTITY_KS,
    PAYMENT_TYPE_EKOLKY_1,
    PAYMENT_TYPE_EKOLKY_6,
    PO,
    Profil_Webove_Sidlo_typ_ISVS_APP_WEB,
    Profil_Webove_Sidlo_typ_rozhrania_list,
} from '@isdd/metais-common/constants'
import { FieldValues, UseFormSetValue, UseFormWatch } from 'react-hook-form'

interface ISpecialRulesConfig {
    [key: string]: {
        entityName: string[]
        affectedAttribute: string
        affectedAttributeValue: boolean | string
    }
}

export const specialFormValueConfig = (value?: string, watch?: UseFormWatch<FieldValues>): ISpecialRulesConfig => {
    return {
        KS_Profil_PO_UPVS_typ_spoplatnenia: {
            entityName: [PO, ENTITY_KS],
            affectedAttribute: 'KS_Profil_PO_UPVS_platba',
            affectedAttributeValue: value === PAYMENT_TYPE_EKOLKY_6 || value === PAYMENT_TYPE_EKOLKY_1 ? true : false,
        },
        EA_Profil_ISVS_typ_isvs: {
            entityName: [ENTITY_ISVS],
            affectedAttribute: 'Profil_Webove_Sidlo_url_vyhlasenie',
            affectedAttributeValue:
                Profil_Webove_Sidlo_typ_ISVS_APP_WEB.includes(value ?? '') &&
                Profil_Webove_Sidlo_typ_rozhrania_list.includes(watch?.('Profil_Webove_Sidlo_typ_rozhrania'))
                    ? `${baseWikiUrl}/page/render/isvs_vyhlasenie_pristupnost?isvsCode=${watch?.('Gen_Profil_kod_metais')}&cached=false`
                    : '',
        },
        Profil_Webove_Sidlo_typ_rozhrania: {
            entityName: [ENTITY_ISVS],
            affectedAttribute: 'Profil_Webove_Sidlo_url_vyhlasenie',
            affectedAttributeValue:
                Profil_Webove_Sidlo_typ_rozhrania_list.includes(value ?? '') &&
                Profil_Webove_Sidlo_typ_ISVS_APP_WEB.includes(watch?.('EA_Profil_ISVS_typ_isvs'))
                    ? `${baseWikiUrl}/page/render/isvs_vyhlasenie_pristupnost?isvsCode=${watch?.('Gen_Profil_kod_metais')}&cached=false`
                    : '',
        },
    }
}

interface IGetRuleProps {
    technicalName: string
    entityName?: string
    value?: string
    setValue: UseFormSetValue<FieldValues>
    watch?: UseFormWatch<FieldValues>
}

export const getSpecialValueProperty = ({ technicalName, entityName, value, setValue, watch }: IGetRuleProps) => {
    if (!entityName) {
        return
    }

    const ruleConfig = specialFormValueConfig(value, watch)[technicalName]
    const foundConfig = ruleConfig?.entityName?.includes(entityName)
    if (foundConfig) {
        setValue(ruleConfig.affectedAttribute, ruleConfig.affectedAttributeValue)
    }
}
