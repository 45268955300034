import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import styles from './paginatorWrapper.module.scss'

import { Paginator } from '@isdd/idsk-ui-kit/paginator/Paginator'
import { IFilter } from '@isdd/idsk-ui-kit/types'

interface IPaginatorWrapper {
    pageNumber: number
    pageSize: number
    dataLength: number
    totalPages?: number
    handlePageChange: (filter: IFilter) => void
    width?: 'one-half'
}

export const PaginatorWrapper: React.FC<IPaginatorWrapper> = ({ pageNumber, pageSize, dataLength, totalPages, handlePageChange, width }) => {
    const { t } = useTranslation()
    const start = (pageNumber - 1) * pageSize + 1
    const end = pageNumber * pageSize < dataLength ? pageNumber * pageSize : dataLength

    return (
        <div className={classnames(styles.paginationDiv, { [styles.oneHalf]: width === 'one-half' })}>
            <Paginator
                pageNumber={pageNumber}
                pageSize={pageSize}
                dataLength={dataLength}
                totalPages={totalPages}
                onPageChanged={(page) => handlePageChange({ pageNumber: page, pageSize })}
            />
            {dataLength === 0 ? (
                <p className={classnames('govuk-body-s', styles.text)}>{t('table.paginationSummaryEmpty')}</p>
            ) : (
                <p className={classnames('govuk-body-s', styles.text)}>
                    {t('table.paginationSummary', {
                        start,
                        end,
                        total: dataLength,
                    })}
                </p>
            )}
        </div>
    )
}
