import { BreadCrumbs, HomeIcon } from '@isdd/idsk-ui-kit/index'
import { ATTRIBUTE_NAME } from '@isdd/metais-common/api/constants'
import { ENTITY_PROJECT } from '@isdd/metais-common/constants'
import { useAttributesHook } from '@isdd/metais-common/hooks/useAttributes.hook'
import { useCiHook } from '@isdd/metais-common/hooks/useCi.hook'
import { Languages } from '@isdd/metais-common/localization/languages'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { setDocumentTitle } from '@isdd/metais-common/utils/utils'
import { useFindByParams1 } from '@isdd/metais-common/api/generated/kris-swagger'

import { MainContentWrapper } from '@/components/MainContentWrapper'
import { CiPermissionsWrapper } from '@/components/permissions/CiPermissionsWrapper'
import { EditProjectView } from '@/components/views/ci/project/EditProjectView'
import { getCiHowToBreadCrumb } from '@/componentHelpers/ci'
import { updateProjectFinanceConstraints } from '@/componentHelpers/ci/updateProjectFinanceConstraints'

const EditProjectPage = () => {
    const { t, i18n } = useTranslation()
    const { entityId } = useParams()
    const entityName = ENTITY_PROJECT

    const { ciItemData, isLoading: isCiItemLoading, isError: isCiItemError, gestorData } = useCiHook(entityId)
    const { constraintsData, ciTypeData, unitsData, isLoading: isAttLoading, isError: isAttError } = useAttributesHook(entityName)

    const currentName =
        i18n.language == Languages.SLOVAK
            ? ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov]
            : ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov]

    setDocumentTitle(
        t('titles.ciEdit', {
            ci: ciTypeData?.name,
            itemName: ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov],
        }),
    )

    const {
        data: programFinance,
        isFetching: isFinanceLoading,
        isError: isFinanceError,
    } = useFindByParams1(
        {
            programUuid: ciItemData?.attributes?.[ATTRIBUTE_NAME.EA_Profil_Projekt_program],
            projectType: ciItemData?.attributes?.[ATTRIBUTE_NAME.EA_Profil_Projekt_typ_investicie],
            projectManagementMethod: ciItemData?.attributes?.[ATTRIBUTE_NAME.EA_Profil_Projekt_metoda_riadenia_projektu],
            financialValue: ciItemData?.attributes?.[ATTRIBUTE_NAME.Financny_Profil_Projekt_suma_vydavkov],
        },
        {
            query: {
                enabled:
                    !!ciItemData?.attributes?.[ATTRIBUTE_NAME.EA_Profil_Projekt_program] &&
                    !!ciItemData?.attributes?.[ATTRIBUTE_NAME.EA_Profil_Projekt_typ_investicie] &&
                    !!ciItemData?.attributes?.[ATTRIBUTE_NAME.EA_Profil_Projekt_metoda_riadenia_projektu] &&
                    ciItemData?.attributes?.[ATTRIBUTE_NAME.Financny_Profil_Projekt_suma_vydavkov] !== undefined,
            },
        },
    )

    const minFinance = programFinance?.[0]?.min
    const maxFinance = programFinance?.[0]?.max

    //overwrite constraints for Financny_Profil_Projekt_rocne_naklady to be editable within finance bounds of financeGroup
    const updatedCiTypeData = updateProjectFinanceConstraints(ciTypeData, minFinance, maxFinance)

    return (
        <>
            <BreadCrumbs
                withWidthContainer
                links={[
                    { label: t('breadcrumbs.home'), href: '/', icon: HomeIcon },
                    ...getCiHowToBreadCrumb(entityName ?? '', t),
                    { label: t('titles.ciList', { ci: ciTypeData?.name }), href: `/ci/${entityName}` },
                    { label: currentName ? currentName : t('breadcrumbs.noName'), href: `/ci/${entityName}/${entityId}` },
                    { label: t('breadcrumbs.ciEdit', { itemName: currentName }), href: `/ci/${entityName}/${entityId}/edit` },
                ]}
            />
            <MainContentWrapper>
                <CiPermissionsWrapper entityName={entityName ?? ''} entityId={entityId ?? ''}>
                    <EditProjectView
                        ciTypeData={updatedCiTypeData}
                        constraintsData={constraintsData}
                        unitsData={unitsData}
                        ciItemData={ciItemData}
                        entityId={entityId ?? ''}
                        entityName={entityName ?? ''}
                        isError={[isAttError, isCiItemError, isFinanceError].some((item) => item)}
                        isLoading={[isAttLoading, isCiItemLoading, isFinanceLoading].some((item) => item)}
                        ownerId={gestorData?.[0].owner ?? ''}
                    />
                </CiPermissionsWrapper>
            </MainContentWrapper>
        </>
    )
}

export default EditProjectPage
