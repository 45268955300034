import React, { PropsWithChildren } from 'react'
import Modal, { Aria } from 'react-modal'
import { useTranslation } from 'react-i18next'

import styles from './baseModal.module.scss'

import { NavigationCloseIcon } from '@isdd/idsk-ui-kit/assets/images'

export interface IBaseModalProps extends PropsWithChildren {
    isOpen: boolean
    close: () => void
    widthInPx?: number
    minHeightInPx?: number
    aria?: Aria
}

export const BaseModal: React.FC<IBaseModalProps> = ({ isOpen, close, children, widthInPx, minHeightInPx, aria }) => {
    const { t } = useTranslation()
    Modal.setAppElement('#root')

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={close}
            className={styles.modalContent}
            overlayClassName={styles.customModalOverlay}
            style={{ content: { width: widthInPx, minHeight: minHeightInPx } }}
            aria={aria}
        >
            <button className={styles.closeButton} onClick={close} aria-label={t('close')}>
                <img src={NavigationCloseIcon} alt="" />
            </button>
            {children}
        </Modal>
    )
}
