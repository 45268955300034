import { ConfigurationItemUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { getGetHistoryQueryKey, getGetMeta1QueryKey } from '@isdd/metais-common/api/generated/dms-swagger'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { useScroll } from '@isdd/metais-common/hooks/useScroll'
import { FileHistoryModal, QueryFeedback } from '@isdd/metais-common/index'
import { useQueryClient } from '@tanstack/react-query'
import React, { useEffect } from 'react'

import { OlaContractDetailBasicInfo } from './OlaContractDetailBasicInfo'

import { IMutationErrors, IOlaContractDetailView } from '@/components/containers/OlaContractDetailContainer'

export const OlaContractDetailView: React.FC<IOlaContractDetailView & IMutationErrors> = ({
    isError,
    isLoading,
    olaContract,
    document,
    downloadVersionFile,
    showHistory,
    setShowHistory,
    statesEnum,
    isvsOfOlaContract,
}) => {
    const queryClient = useQueryClient()
    const dmsKey = getGetMeta1QueryKey(olaContract?.uuid ?? '')
    const fileHistoryKey = getGetHistoryQueryKey(olaContract?.uuid ?? '')

    const { isActionSuccess } = useActionSuccess()
    const { wrapperRef, scrollToMutationFeedback } = useScroll()
    useEffect(() => {
        if (isActionSuccess.value) {
            if (document) {
                queryClient.invalidateQueries(dmsKey)
                queryClient.invalidateQueries(fileHistoryKey)
            }

            scrollToMutationFeedback()
        }
    }, [dmsKey, document, fileHistoryKey, isActionSuccess, queryClient, scrollToMutationFeedback])

    return (
        <>
            {document && (
                <FileHistoryModal
                    isOpen={showHistory}
                    item={{ uuid: olaContract?.uuid, attributes: [{ name: 'Gen_Profil_nazov', value: olaContract?.name }] } as ConfigurationItemUi}
                    onClose={() => setShowHistory(false)}
                />
            )}

            <QueryFeedback loading={isLoading} error={isError} withChildren>
                <div ref={wrapperRef} />
                <OlaContractDetailBasicInfo
                    statesEnum={statesEnum}
                    downloadVersionFile={downloadVersionFile}
                    document={document}
                    olaContract={olaContract}
                    setShowHistory={setShowHistory}
                    isvsOfOlaContract={isvsOfOlaContract}
                />
            </QueryFeedback>
        </>
    )
}
