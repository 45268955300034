import { ButtonPopup } from '@isdd/idsk-ui-kit'
import classnames from 'classnames'
import React, { cloneElement } from 'react'
import { useTranslation } from 'react-i18next'

import styles from '@isdd/metais-common/components/actions-over-table/actionsOverTable.module.scss'

export interface IBulkPopupProps {
    checkedRowItems?: number
    label?: string
    disabled?: boolean
    items?: (closePopup: () => void, focusTrigger: () => void) => Array<React.ReactElement>
    popupPosition?: 'left' | 'right'
    manuallyHandledFocusAfterClose?: boolean
    onClose?: () => void
    onOpen?: (focusTrigger: () => void) => void
    limitWidthForMobile?: boolean
}

export const BulkPopup: React.FC<IBulkPopupProps> = ({
    checkedRowItems,
    label,
    disabled,
    items = () => [],
    popupPosition,
    manuallyHandledFocusAfterClose,
    onOpen,
    onClose,
    limitWidthForMobile = true,
}) => {
    const { t } = useTranslation()
    return (
        <div className={classnames(styles.mobileOrder3, styles.buttonPopup)} id="bulkActions">
            <ButtonPopup
                limitWidthForMobile={limitWidthForMobile}
                disabled={disabled ?? checkedRowItems === 0}
                disabledTooltip={t('actionOverTable.disabledTooltip')}
                buttonLabel={`${label ? label : t('actionOverTable.actions')} ${checkedRowItems ? '(' + checkedRowItems + ')' : ''}`}
                buttonClassName="marginBottom0"
                popupHorizontalPosition={popupPosition}
                manuallyHandledFocusAfterClose={manuallyHandledFocusAfterClose}
                onOpen={onOpen}
                onClose={onClose}
                popupContent={(closePopup, focusTrigger) => (
                    <div className={styles.popupActions} id="bulkActionsList" role="list">
                        {items(closePopup, focusTrigger)?.map((item) =>
                            cloneElement(item, {
                                role: 'listitem',
                                className: styles.buttonLinkWithIcon,
                            }),
                        )}
                    </div>
                )}
            />
        </div>
    )
}
