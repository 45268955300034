import { BaseModal, LoadingIndicator, TextHeading } from '@isdd/idsk-ui-kit'
import { Button } from '@isdd/idsk-ui-kit/button/Button'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FileExtensionEnum } from '@isdd/metais-common/components/actions-over-table/actions-default/ExportButton'
import styles from '@isdd/metais-common/components/export-items-or-relations/exportItemsOrRelations.module.scss'
import { useExport2Hook, useLoadFileHook } from '@isdd/metais-common/api/generated/impexp-cmdb-swagger'
import { ExportIcon } from '@isdd/metais-common/assets/images'
import { downloadBlobAsFile } from '@isdd/metais-common/componentHelpers/download/downloadHelper'
import { IconLabel } from '@isdd/metais-common/components/actions-over-table/icon-label/IconLabel'
import { ModalButtons } from '@isdd/metais-common/components/modal-buttons/ModalButtons'
import { MutationFeedback } from '@isdd/metais-common/components/mutation-feedback/MutationFeedback'
import { handleTooManyRequests, sanitizeFileName } from '@isdd/metais-common/utils/utils'
import { useGetProgress } from '@isdd/metais-common/hooks/useGetRequestProgress'

interface IExportButtonProps {
    trainingUuid: string
    trainingName: string
}

export const TrainingExportButton: React.FC<IExportButtonProps> = ({ trainingUuid, trainingName }) => {
    const [modalOpen, setModalOpen] = useState(false)
    const { t } = useTranslation()
    const { getRequestStatus, isLoading: isGettingProgress } = useGetProgress('EXPORT')
    const fileLoad = useLoadFileHook()

    const [isLoading, setLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState(false)

    const exportHook = useExport2Hook()
    const [exportSuccess, setExportSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>()

    const onExportStart = async (extension: FileExtensionEnum) => {
        setExportSuccess(false)
        try {
            exportHook(extension, { trainingUuid })
                .then(async (result) => {
                    await getRequestStatus(
                        result.id ?? '',
                        async (validationResult) => {
                            const blob = await fileLoad(validationResult?.fileName ?? '')
                            if (blob) {
                                downloadBlobAsFile(
                                    new Blob([blob]),
                                    `${sanitizeFileName(trainingName)}.${validationResult?.fileExtension?.toLowerCase()}`,
                                    false,
                                )
                                setExportSuccess(true)
                            } else {
                                setExportSuccess(false)
                                setIsError(true)
                            }
                        },
                        () => {
                            setExportSuccess(false)
                            setIsError(true)
                        },
                    )
                })
                .catch((e) => {
                    handleTooManyRequests(
                        e.message,
                        () => {
                            setErrorMessage(t('exportItemsOrRelations.exportErrorTooManyRequests'))
                            setIsError(true)
                        },
                        () => {
                            setIsError(true)
                        },
                    )
                })
        } catch (error) {
            setExportSuccess(false)
            setIsError(true)
        }
        setLoading(false)
    }

    const closeModal = () => {
        setModalOpen(false)
    }

    return (
        <>
            <Button
                onClick={() => setModalOpen(true)}
                className="marginBottom0"
                variant="secondary"
                aria-label={t('trainings.exportAria')}
                label={<IconLabel label={t('actionOverTable.export')} icon={ExportIcon} />}
            />
            <BaseModal isOpen={modalOpen} close={closeModal}>
                {(isLoading || isGettingProgress) && <LoadingIndicator label={t('exportItemsOrRelations.loading')} />}
                <MutationFeedback
                    success={exportSuccess}
                    error={isError}
                    successMessage={t('exportItemsOrRelations.exportSuccess')}
                    errorMessage={errorMessage}
                />
                <div className={styles.modalContainer}>
                    <div className={styles.content}>
                        <div className={styles.icon}>
                            <img className={styles.iconWidth} src={ExportIcon} alt="" />
                        </div>
                        <TextHeading size={'L'} className={styles.heading}>
                            {t('trainings.export-title')}
                        </TextHeading>
                        <div className={styles.buttonGroup}>
                            <Button
                                label={t('exportItemsOrRelations.buttonXML')}
                                variant="secondary"
                                className={styles.buttons}
                                onClick={() => onExportStart(FileExtensionEnum.XML)}
                            />
                            <Button
                                label={t('exportItemsOrRelations.buttonCSV')}
                                variant="secondary"
                                className={styles.buttons}
                                onClick={() => onExportStart(FileExtensionEnum.CSV)}
                            />
                            <Button
                                label={t('exportItemsOrRelations.buttonXLSX')}
                                variant="secondary"
                                className={styles.buttons}
                                onClick={() => onExportStart(FileExtensionEnum.EXCEL)}
                            />
                        </div>
                    </div>
                </div>
                <ModalButtons onClose={closeModal} />
            </BaseModal>
        </>
    )
}
