import { Button, Filter, GridCol, GridRow, PaginatorWrapper, SimpleSelect, Table, TextHeading } from '@isdd/idsk-ui-kit/index'
import { IFilter } from '@isdd/idsk-ui-kit/types'
import { BASE_PAGE_NUMBER, BASE_PAGE_SIZE } from '@isdd/metais-common/api/constants'
import { ApiError, ApiVotePreview, ApiVotePreviewList } from '@isdd/metais-common/api/generated/standards-swagger'
import { DEFAULT_PAGESIZE_OPTIONS } from '@isdd/metais-common/constants'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { useAbilityContext } from '@isdd/metais-common/hooks/permissions/useAbilityContext'
import { Actions } from '@isdd/metais-common/hooks/permissions/useVotesListPermissions'
import { IFilterParams } from '@isdd/metais-common/hooks/useFilter'
import { useScroll } from '@isdd/metais-common/hooks/useScroll'
import { ActionsOverTable, MutationFeedback, QueryFeedback } from '@isdd/metais-common/index'
import { NavigationSubRoutes } from '@isdd/metais-common/navigation/routeNames'
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { DateInput } from '@isdd/idsk-ui-kit/date-input/DateInput'

import styles from './voteList.module.scss'

import { VoteStateOptionEnum, getVoteStateExplanation } from '@/components/views/standardization/votes/voteProps'
import {
    voteListColumns,
    voteStateOptions,
    voteStateWithoutDate,
    votesTypeToShowOptions,
} from '@/components/views/standardization/votes/votesList/voteListProps'

export interface IVotesListFilterData extends IFilterParams, IFilter {
    votesTypeToShow: string
    voteState: string
    effectiveFrom: string
    effectiveTo: string
}

export interface VoteTableData extends ApiVotePreview {
    groupNames: string[] | undefined
    groupShortNames: string[] | undefined
}

export interface IVotesListView {
    isUserLogged: boolean
    votesListData: { votesCount: number; votes: VoteTableData[] } | undefined
    defaultFilterValues: IVotesListFilterData
    filter: IFilter
    isLoadingNextPage: boolean
    handleFilterChange: (filter: IFilter) => void
    getVotesRefetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
    ) => Promise<QueryObserverResult<ApiVotePreviewList, ApiError>>
}

export const VotesListView: React.FC<IVotesListView> = ({
    isUserLogged,
    votesListData,
    filter,
    defaultFilterValues,
    isLoadingNextPage,
    handleFilterChange,
    getVotesRefetch,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const {
        isActionSuccess: { value: isSuccess, additionalInfo: additionalInfo },
    } = useActionSuccess()
    const ability = useAbilityContext()
    const tableRef = useRef<HTMLTableElement>(null)

    const newVoteHandler = () => {
        navigate(`${NavigationSubRoutes.ZOZNAM_HLASOV_CREATE}`, { state: { from: location } })
    }

    const votesList = votesListData?.votes?.map((vote) => {
        const newVoteState = getVoteStateExplanation(vote.voteState, vote.effectiveFrom ?? '', vote.effectiveTo ?? '', t)
        return { ...vote, voteState: newVoteState }
    })

    const { wrapperRef, scrollToMutationFeedback } = useScroll()

    useEffect(() => {
        if (isSuccess) {
            scrollToMutationFeedback()
            getVotesRefetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess])

    return (
        <>
            <TextHeading size="XL">{t('votes.votesList.title')}</TextHeading>
            <div ref={wrapperRef}>
                <MutationFeedback
                    success={isSuccess}
                    successMessage={additionalInfo?.type == 'create' ? t('votes.voteDetail.created') : t('mutationFeedback.successfulUpdated')}
                />
            </div>
            <Filter<IVotesListFilterData>
                heading={t('votes.votesList.filter.title')}
                defaultFilterValues={defaultFilterValues}
                form={({ filter: listFilter, register, setValue, watch, control, isOpen }) => {
                    const voteState = watch('voteState')

                    return (
                        <div>
                            {isUserLogged && (
                                <SimpleSelect
                                    id="votesTypeToShow"
                                    label={t('votes.votesList.filter.votesTypeToShow')}
                                    options={votesTypeToShowOptions(t)}
                                    setValue={setValue}
                                    defaultValue={listFilter?.votesTypeToShow}
                                    name="votesTypeToShow"
                                    tabIndex={isOpen ? undefined : -1}
                                />
                            )}
                            <SimpleSelect
                                id="voteState"
                                label={t('votes.votesList.filter.voteState')}
                                options={voteStateOptions(t)}
                                setValue={setValue}
                                defaultValue={listFilter?.voteState}
                                name="voteState"
                                tabIndex={isOpen ? undefined : -1}
                            />
                            {!voteStateWithoutDate.includes(voteState as VoteStateOptionEnum) && (
                                <GridRow>
                                    <GridCol setWidth="one-half">
                                        <DateInput
                                            {...register('effectiveFrom')}
                                            label={t('votes.votesList.filter.fromDate')}
                                            control={control}
                                            setValue={setValue}
                                        />
                                    </GridCol>
                                    <GridCol setWidth="one-half">
                                        <DateInput
                                            {...register('effectiveTo')}
                                            label={t('votes.votesList.filter.toDate')}
                                            control={control}
                                            setValue={setValue}
                                        />
                                    </GridCol>
                                </GridRow>
                            )}
                        </div>
                    )
                }}
            />
            <ActionsOverTable
                pagination={{
                    pageNumber: filter.pageNumber || BASE_PAGE_NUMBER,
                    pageSize: filter.pageSize || BASE_PAGE_SIZE,
                    dataLength: votesListData?.votesCount || 0,
                }}
                pagingOptions={DEFAULT_PAGESIZE_OPTIONS}
                entityName=""
                handleFilterChange={handleFilterChange}
                hiddenButtons={{ SELECT_COLUMNS: true }}
            >
                {ability.can(Actions.CREATE, 'VOTE') && (
                    <Button className={styles.noMargin} type="submit" label={t('votes.voteDetail.newVote')} onClick={() => newVoteHandler()} />
                )}
            </ActionsOverTable>
            <QueryFeedback loading={isLoadingNextPage} withChildren>
                <Table
                    tableRef={tableRef}
                    data={votesList}
                    columns={voteListColumns(t, isUserLogged)}
                    sort={filter.sort ?? []}
                    onSortingChange={(columnSort) => {
                        handleFilterChange({ sort: columnSort })
                    }}
                />
            </QueryFeedback>
            <PaginatorWrapper
                pageNumber={filter.pageNumber || BASE_PAGE_NUMBER}
                pageSize={filter.pageSize || BASE_PAGE_SIZE}
                dataLength={votesListData?.votesCount || 0}
                handlePageChange={(filterValues) => {
                    handleFilterChange(filterValues)
                    tableRef.current?.scrollIntoView({ behavior: 'smooth' })
                }}
            />
        </>
    )
}
