import { DateInput } from '@isdd/idsk-ui-kit/date-input/DateInput'
import { Filter, GridCol, GridRow, PaginatorWrapper, SimpleSelect, Table, TableHandle } from '@isdd/idsk-ui-kit/index'
import { SafeHtmlComponent } from '@isdd/idsk-ui-kit/save-html-component/SafeHtmlComponent'
import { IFilter } from '@isdd/idsk-ui-kit/types/filter'
import { Group } from '@isdd/metais-common/api/generated/iam-swagger'
import { ApiMeetingRequestPreview } from '@isdd/metais-common/api/generated/standards-swagger'
import { BASE_PAGE_NUMBER, BASE_PAGE_SIZE, DEFAULT_PAGESIZE_OPTIONS } from '@isdd/metais-common/constants'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { Can } from '@isdd/metais-common/hooks/permissions/useAbilityContext'
import { Actions, Subject } from '@isdd/metais-common/hooks/permissions/useMeetingsListPermissions'
import { IFilterParams } from '@isdd/metais-common/hooks/useFilter'
import { ActionsOverTable, CreateEntityButton, MutationFeedback, QueryFeedback, formatDateTimeForDefaultValue } from '@isdd/metais-common/index'
import { NavigationSubRoutes } from '@isdd/metais-common/navigation/routeNames'
import { MeetingsExportButton } from '@isdd/metais-common/components/actions-over-table/actions-meetings/MeetingsExportButton'
import { useCustomColumns } from '@isdd/metais-common/hooks/useCustomColumns'
import { CellContext, ColumnDef, ColumnOrderState, Updater } from '@tanstack/react-table'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'

export enum SortType {
    ASC = 'ASC',
    DESC = 'DESC',
}

export enum MeetingStateEnum {
    PAST = 'PAST',
    CANCELED = 'CANCELED',
    FUTURE = 'FUTURE',
    SUMMARIZED = 'SUMMARIZED',
    NOW = 'NOW',
}

export interface MeetingsFilterData extends IFilterParams, IFilter {
    meetingOption?: string
    group?: string
    state?: string
    startDate?: string
    endDate?: string
    sortBy?: string
    ascending?: boolean
}
interface IMeetingsListView {
    meetings: ApiMeetingRequestPreview[] | undefined
    groups: Group[] | undefined
    meetingsCount: number
    defaultFilterValues: MeetingsFilterData
    filter: MeetingsFilterData
    handleFilterChange: (changedFilter: IFilter) => void
}

export enum MeetingFilter {
    MY_MEETINGS = 'my',
    ALL_MEETINGS = 'all',
}

type MeetingsColumnKeysType = ApiMeetingRequestPreview & {
    groupShortName: string
    groupName: string
}

export const MeetingsListView: React.FC<IMeetingsListView> = ({
    meetings,
    groups,
    meetingsCount,
    defaultFilterValues,
    filter,
    handleFilterChange,
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const { isActionSuccess } = useActionSuccess()

    const [meetingOption, setMeetingOption] = useState(defaultFilterValues.meetingOption)
    const [group, setGroup] = useState<string | null | undefined>(defaultFilterValues.group)
    const [state, setState] = useState<string | null | undefined>(defaultFilterValues.state)
    const tableRef = useRef<HTMLTableElement>(null)
    const tableHandle = useRef<TableHandle>(null)
    const keysOfApiMeetingRequestPreview: Array<keyof ApiMeetingRequestPreview> = [
        'name',
        'id',
        'beginDate',
        'endDate',
        'place',
        'description',
        'createdAt',
        'createdBy',
        'state',
        'summarizedAt',
        'summarizedBy',
        'summarizedLink',
        'groups',
        'actionDesription',
    ]
    const fullKeysWithGroup: Array<keyof MeetingsColumnKeysType> = [...keysOfApiMeetingRequestPreview, 'groupName', 'groupShortName']

    useEffect(() => {
        setMeetingOption(filter.meetingOption)
        setGroup(filter.group || null)
        setState(filter.state || null)
    }, [filter.group, filter.meetingOption, filter.state])

    const getMeetingListColumns = (): Array<ColumnDef<ApiMeetingRequestPreview>> => {
        const columns: Array<ColumnDef<ApiMeetingRequestPreview>> = keysOfApiMeetingRequestPreview.map((k) => {
            const defaultColumn: ColumnDef<ApiMeetingRequestPreview> = {
                header: t(`meetings.${k}`),
                id: k,
                accessorFn: (row) => row?.[k],
                enableSorting: true,
                meta: {
                    getCellContext: (ctx: CellContext<ApiMeetingRequestPreview, unknown>) => ctx?.getValue?.(),
                },
                cell: (ctx) => <span>{ctx?.getValue?.() as string}</span>,
                size: 200,
            }
            switch (k) {
                case 'name': {
                    return {
                        ...defaultColumn,
                        cell: (ctx) => (
                            <Link to={`${ctx?.row?.original?.id}`} state={{ from: location }}>
                                {ctx?.getValue?.() as string}
                            </Link>
                        ),
                    }
                }
                case 'endDate':
                case 'createdAt':
                case 'summarizedAt':
                case 'beginDate': {
                    return {
                        ...defaultColumn,
                        cell: (ctx) => formatDateTimeForDefaultValue(ctx.getValue() as string, 'dd.MM.yyyy, HH:mm'),
                    }
                }
                case 'description': {
                    return {
                        ...defaultColumn,
                        cell: (ctx) => <SafeHtmlComponent dirtyHtml={ctx.row?.original.description ?? ''} />,
                    }
                }
                case 'state': {
                    return {
                        ...defaultColumn,
                        cell: (ctx) => <span>{t(`meetings.stateValue.${ctx.row?.original?.state}`)}</span>,
                    }
                }
                default: {
                    return defaultColumn
                }
            }
        })
        return columns
    }

    const groupColumns: Array<ColumnDef<ApiMeetingRequestPreview>> = [
        {
            header: t('meetings.groupShortName'),
            accessorFn: (row) => {
                const meetingGroup = groups?.find((o) => row.groups?.includes(o.uuid || ''))
                return meetingGroup?.shortName || ''
            },
            enableSorting: false,
            id: 'groupShortName',
            meta: {
                getCellContext: (ctx: CellContext<ApiMeetingRequestPreview, unknown>) => ctx?.getValue?.(),
            },
            cell: (ctx) => <span>{ctx?.getValue?.() as string}</span>,

            size: 200,
        },
        {
            header: t('meetings.groupName'),
            accessorFn: (row) => {
                const meetingGroup = groups?.find((o) => row.groups?.includes(o.uuid || ''))
                return meetingGroup?.name || ''
            },
            enableSorting: false,
            id: 'groupName',
            meta: {
                getCellContext: (ctx: CellContext<ApiMeetingRequestPreview, unknown>) => ctx?.getValue?.(),
            },
            cell: (ctx) => <span>{ctx?.getValue?.() as string}</span>,
            size: 200,
        },
    ]
    const columns: Array<ColumnDef<ApiMeetingRequestPreview>> = [...getMeetingListColumns(), ...groupColumns]

    const { simpleTableColumnsSelect, filteredColumns, saveColumnOrder, isLoading, isError } = useCustomColumns({
        columns,
        columnsKeys: fullKeysWithGroup.sort((a, b) => a.localeCompare(b)),
        disabledColumnKeys: ['name'],
        defaultColumnKeys: ['name', 'beginDate', 'groupName', 'groupShortName', 'state'],
        ignoreColumnKeys: ['groups'],
        entityName: 'meetings',
    })

    useEffect(() => {
        tableHandle.current?.refreshColumns()
    }, [isLoading])

    return (
        <QueryFeedback loading={isLoading} error={isError} withChildren>
            <MutationFeedback success={isActionSuccess.value} successMessage={t('feedback.mutationCreateSuccessMessage')} />

            <Filter<MeetingsFilterData>
                onlyForm
                defaultFilterValues={defaultFilterValues}
                form={({ setValue, register, control, isOpen }) => {
                    return (
                        <div>
                            <SimpleSelect
                                label={t('meetings.filter.meetingOption')}
                                name="meetingOption"
                                id="meetingOption"
                                options={[
                                    { value: MeetingFilter.ALL_MEETINGS, label: t('meetings.filter.meetingOptionValue.all') }, //all
                                    { value: MeetingFilter.MY_MEETINGS, label: t('meetings.filter.meetingOptionValue.my') }, //my
                                ]}
                                value={meetingOption}
                                onChange={(val) => {
                                    setMeetingOption(val)
                                }}
                                setValue={setValue}
                                tabIndex={isOpen ? undefined : -1}
                            />
                            <SimpleSelect
                                label={t('meetings.filter.group')}
                                name="group"
                                id="group"
                                options={groups?.map((item) => ({ value: item.uuid ?? '', label: `${item.shortName} - ${item.name}` ?? '' })) ?? []}
                                value={group}
                                onChange={(val) => {
                                    setGroup(val)
                                }}
                                setValue={setValue}
                                tabIndex={isOpen ? undefined : -1}
                            />
                            <SimpleSelect
                                label={t('meetings.filter.state')}
                                name="state"
                                id="state"
                                options={[
                                    { value: MeetingStateEnum.CANCELED, label: t(`meetings.stateValue.${MeetingStateEnum.CANCELED}`) },
                                    { value: MeetingStateEnum.FUTURE, label: t(`meetings.stateValue.${MeetingStateEnum.FUTURE}`) },
                                    { value: MeetingStateEnum.NOW, label: t(`meetings.stateValue.${MeetingStateEnum.NOW}`) },
                                    { value: MeetingStateEnum.PAST, label: t(`meetings.stateValue.${MeetingStateEnum.PAST}`) },
                                    { value: MeetingStateEnum.SUMMARIZED, label: t(`meetings.stateValue.${MeetingStateEnum.SUMMARIZED}`) },
                                ]}
                                value={state}
                                onChange={(val) => {
                                    setState(val)
                                }}
                                setValue={setValue}
                                tabIndex={isOpen ? undefined : -1}
                            />
                            <GridRow>
                                <GridCol setWidth="one-half">
                                    <DateInput
                                        label={t('meetings.filter.startDate')}
                                        {...register('startDate')}
                                        control={control}
                                        setValue={setValue}
                                    />
                                </GridCol>
                                <GridCol setWidth="one-half">
                                    <DateInput label={t('meetings.filter.endDate')} {...register('endDate')} control={control} setValue={setValue} />
                                </GridCol>
                            </GridRow>
                        </div>
                    )
                }}
            />

            <ActionsOverTable
                pagination={{
                    pageNumber: filter.pageNumber ?? BASE_PAGE_NUMBER,
                    pageSize: filter.pageSize ?? BASE_PAGE_SIZE,
                    dataLength: meetings?.length ?? 0,
                }}
                handleFilterChange={handleFilterChange}
                pagingOptions={DEFAULT_PAGESIZE_OPTIONS}
                simpleTableColumnsSelect={simpleTableColumnsSelect}
                entityName=""
                exportButton={
                    <MeetingsExportButton
                        entityName={t('globalSearch.MEETING_REQUEST')}
                        filter={{
                            fieldsToExport: filteredColumns.map((c) => c.id ?? ''),
                            ...(filter.sort?.[0]?.orderBy && { sortAttribute: filter.sort?.[0]?.orderBy ?? 'beginDate' }),
                            ...(filter.sort?.[0]?.sortDirection && { ascending: filter.sort?.[0]?.sortDirection === SortType.ASC }),
                            ...(filter?.startDate && { fromDate: filter?.startDate }),
                            ...(filter?.endDate && { toDate: filter?.endDate }),
                            ...(filter?.meetingOption == MeetingFilter.MY_MEETINGS && { onlyMy: true }),
                            ...(filter?.group && { workGroupId: filter?.group }),
                            ...(filter?.state && { state: filter?.state }),
                        }}
                    />
                }
            >
                <Can I={Actions.CREATE} a={Subject.MEETING}>
                    <CreateEntityButton
                        label={t('meetings.addNewMeeting')}
                        onClick={() => navigate(`${NavigationSubRoutes.ZOZNAM_ZASADNUTI_CREATE}`)}
                    />
                </Can>
            </ActionsOverTable>

            <Table<ApiMeetingRequestPreview>
                tableRef={tableRef}
                handleRef={tableHandle}
                columns={filteredColumns}
                data={meetings}
                sort={filter.sort}
                onSortingChange={(columnSort) => {
                    handleFilterChange({ sort: columnSort })
                }}
                onColumnOrderChange={(updaterOrValue: Updater<ColumnOrderState>) =>
                    saveColumnOrder({
                        attributes: (updaterOrValue as ColumnOrderState).map((u, index) => ({ name: u, order: index })),
                    })
                }
                canDrag
                isLoading={isLoading}
                error={isError}
                notDraggableList={['name']}
            />
            <PaginatorWrapper
                pageSize={filter.pageSize ?? BASE_PAGE_SIZE}
                pageNumber={filter.pageNumber ?? BASE_PAGE_NUMBER}
                dataLength={meetingsCount}
                handlePageChange={(filterValues) => {
                    handleFilterChange(filterValues)
                    tableRef.current?.scrollIntoView({ behavior: 'smooth' })
                }}
            />
        </QueryFeedback>
    )
}
