import { useGetMetaHook } from '@isdd/metais-common/api/generated/dms-swagger'
import { formatBytes } from '@isdd/metais-common/components/file-import/fileImportUtils'
export const generateMetaString = (extension?: string, sizeTmp?: string) => {
    let size = undefined

    if (sizeTmp?.trim() != '0 bytes') {
        size = sizeTmp
    }
    if (extension && size) {
        return ' (' + extension.toUpperCase() + ', ' + size + ')'
    }

    if (size) {
        return ' (' + size + ')'
    }

    if (extension) {
        return ' (' + extension.toUpperCase() + ')'
    }
    return ''
}

export const useGetFileSize = () => {
    const getMeta = useGetMetaHook()

    const loadData = async (uuids: string[]) => {
        if (uuids.length > 0) {
            const data = await getMeta(uuids)
            return Object.entries(data).map((d) => ({
                uuid: d[0],
                size: formatBytes(d[1]?.['contentLength'] ?? 0),
                extension: d[1]?.['extension']?.toUpperCase() ?? '',
                metaComponent: generateMetaString(d[1]?.['extension'], formatBytes(d[1]?.['contentLength'] ?? 0)),
            }))
        } else {
            return []
        }
    }

    return { loadData }
}
