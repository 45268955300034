import { Button } from '@isdd/idsk-ui-kit'
import { Tooltip } from '@isdd/idsk-ui-kit/tooltip/Tooltip'
import classNames from 'classnames'
import QuillEditor, { DeltaStatic, Sources, RangeStatic } from 'quill'
import MagicUrl from 'quill-magic-url'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { UseFormClearErrors, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import sanitizeHtml from 'sanitize-html'
import normalizeUrl from 'normalize-url'

import './quill.scss'
import styles from './styles.module.scss'
import { LinkModal } from './LinkModal'

import { QuillBulletListIcon, QuillLinkIcon, QuillOrderedListIcon } from '@isdd/metais-common/assets/images'
import { decodeHtmlEntities } from '@isdd/metais-common/utils/utils'

export enum RichQuillButtons {
    HEADER_1 = 'HEADER_1',
    HEADER_2 = 'HEADER_2',
    HEADER_3 = 'HEADER_3',
    HEADER_4 = 'HEADER_4',
    HEADER_5 = 'HEADER_5',
    BOLD = 'BOLD',
    UNDERLINE = 'UNDERLINE',
    ITALIC = 'ITALIC',
    BULLET_LIST = 'BULLET_LIST',
    ORDERED_LIST = 'ORDERED_LIST',
    LINK = 'LINK',
}

const QUILL_CLASSNAME_PRESSED = 'ql-active'

type TextButtonsProps = {
    key: RichQuillButtons
    label: string | React.ReactNode
    className?: string
    value?: string
}

const RichTextButtons: TextButtonsProps[] = [
    { key: RichQuillButtons.HEADER_1, label: 'H1', className: 'ql-header', value: '1' },
    { key: RichQuillButtons.HEADER_2, label: 'H2', className: 'ql-header', value: '2' },
    { key: RichQuillButtons.HEADER_3, label: 'H3', className: 'ql-header', value: '3' },
    { key: RichQuillButtons.HEADER_4, label: 'H4', className: 'ql-header', value: '4' },
    { key: RichQuillButtons.HEADER_5, label: 'H5', className: 'ql-header', value: '5' },
    { key: RichQuillButtons.BOLD, label: 'B', className: 'ql-bold' },
    { key: RichQuillButtons.ITALIC, label: 'I', className: 'ql-italic' },
    { key: RichQuillButtons.UNDERLINE, label: 'U', className: 'ql-underline' },
    { key: RichQuillButtons.BULLET_LIST, label: 'bullet list', className: 'ql-list', value: 'bullet' },
    { key: RichQuillButtons.ORDERED_LIST, label: 'ordered list', className: 'ql-list', value: 'ordered' },
    { key: RichQuillButtons.LINK, label: 'link', className: 'ql-link' },
]

const icons = Quill.import('ui/icons')
icons['bold'] = '<strong>B</strong>'
icons['underline'] = '<u>U</u>'
icons['italic'] = '<i>I</i>'
icons['header']['1'] = 'H1'
icons['header']['2'] = 'H2'
icons['link'] = `<img alt="" src="${QuillLinkIcon}" />`
icons['list']['ordered'] = `<img alt="" src="${QuillOrderedListIcon}" />`
icons['list']['bullet'] = `<img alt="" src="${QuillBulletListIcon}" />`

export interface ITextAreaQuillProps {
    excludeOptions?: RichQuillButtons[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValue?: UseFormSetValue<any>
    name: string
    label?: string
    defaultValue?: ReactQuill.Value
    id: string
    info?: string
    isRequired?: boolean
    error?: string
    value?: string
    onChange?(value: string, delta: DeltaStatic, source: Sources, editor: ReactQuill.UnprivilegedEditor): void
    readOnly?: boolean
    ariaLabel?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clearErrors?: UseFormClearErrors<any>
    announceErrorToScreenReader?: boolean
}

export interface ICustomToolBarProps {
    excludeOptions?: RichQuillButtons[]
    onLinkClick: () => void
    id: string
    labelId: string
    error: boolean
    isFocused: boolean
}

Quill.register('modules/magicUrl', MagicUrl)

// https://github.com/quilljs/quill/issues/1328
//regex to check if value contains only empty lines
const regex = /^(<p><br><\/p>)+$/
const formats = ['header', 'bold', 'italic', 'underline', 'list', 'bullet', 'link']

const useMutationObservable = ({ target, callback }: { target: Node | null; callback: MutationCallback }) => {
    const [observer, setObserver] = useState<MutationObserver | null>(null)

    useEffect(() => {
        setObserver(new MutationObserver(callback))
    }, [callback, setObserver])

    useEffect(() => {
        if (!target || !observer) return
        observer.observe(target, { attributes: true, childList: true, subtree: true })
        return () => observer?.disconnect()
    }, [observer, target])
}

const formatLink = (link?: string) => {
    if (!link) return

    const mailRegularExpression = /([\w-.]+@[\w-.]+\.[\w-.]+)/gi
    const normalizeEmail = (mail: string) => `mailto:${mail}`

    if (link.match(mailRegularExpression)) {
        return normalizeEmail(link)
    }

    const options = {
        forceHttps: !link.startsWith('http:'),
    }
    return normalizeUrl(link, options)
}

const getLinkBoundariesAtSelection = (editor?: QuillEditor): RangeStatic | null => {
    const range = editor?.getSelection()
    if (!range) return null

    if (range.length > 0) {
        return range
    }

    const [leaf] = editor?.getLeaf(range?.index ?? 0) ?? []
    const blot = leaf && leaf.parent && leaf.parent.domNode.tagName === 'A' ? leaf.parent : leaf
    const blotIndex = editor?.getIndex(blot)
    const blotLength = leaf.length()
    return {
        index: blotIndex ?? 0,
        length: blotLength ?? 1,
    }
}

const getTextAtCursor = (editor?: QuillEditor) => {
    const range = getLinkBoundariesAtSelection(editor)
    return editor?.getText(range?.index, range?.length)
}

const getLinkAtCursor = (editor?: QuillEditor) => {
    const range = editor?.getSelection()
    if (!range) return null
    const format = range.length > 0 ? editor?.getFormat(range.index, range.length) : editor?.getFormat(range.index)
    return format?.link ?? null
}

const CustomToolbar: React.FC<ICustomToolBarProps> = ({ excludeOptions, id, labelId, error, isFocused }) => {
    const { t } = useTranslation()
    const wrapperRef = useRef(null)

    const onMutation = useCallback((mutationList: MutationRecord[]) => {
        mutationList.forEach((mutation) => {
            if (mutation.attributeName === 'class') {
                const button = mutation.target as HTMLButtonElement
                const hasPressedClassName = button.className.includes(QUILL_CLASSNAME_PRESSED)
                button.setAttribute('aria-pressed', String(hasPressedClassName))
            }
        })
    }, [])

    useMutationObservable({ target: wrapperRef.current, callback: onMutation })

    return (
        <div
            id={id}
            className={classNames(styles.customToolbar, { [styles.formError]: !!error && !isFocused })}
            ref={wrapperRef}
            role="list"
            aria-label={t('quill.ariaToolbar')}
            aria-labelledby={`${labelId} ${id}`}
        >
            {RichTextButtons.filter((item) => !excludeOptions?.includes(item.key)).map((item) => (
                <span role="listitem" key={item.key}>
                    <Button
                        key={item.key}
                        label={item.label}
                        className={classNames('idsk-button', item.className)}
                        variant="secondary"
                        value={item.value}
                        aria-label={t(`quill.buttonLabels.${item.key}`)}
                    />
                </span>
            ))}
        </div>
    )
}

export const RichTextQuill: React.FC<ITextAreaQuillProps> = ({
    id,
    setValue,
    name,
    defaultValue,
    info,
    isRequired,
    label,
    error,
    value,
    onChange,
    excludeOptions,
    readOnly,
    ariaLabel,
    clearErrors,
    announceErrorToScreenReader = false,
}) => {
    const { t, i18n } = useTranslation()
    const quillRef = useRef<ReactQuill & { editor: QuillEditor }>(null)
    const quillId = `${i18n.language}_${id}`
    const labelId = `${quillId}-label`
    const errorId = `${id}-error`
    const toolbarId = `${quillId}-toolbar`
    const requiredText = ` (${t('createEntity.required')})`
    const requiredLabel = `${isRequired ? requiredText : ''}`
    const [isLinkModalOpened, setIsLinkModalOpened] = useState<boolean>(false)
    const [linkModalData, setLinkModalData] = useState<{ text?: string; link?: string; isEdit?: boolean }>({})
    const [editorLinkSelection, setEditorLinkSelection] = useState<RangeStatic | null>()
    const [isFocused, setIsFocused] = useState(false)

    const onLinkClickHandler = () => {
        const editor = quillRef.current?.getEditor()
        const link = getLinkAtCursor(editor)
        const text = getTextAtCursor(editor)

        setEditorLinkSelection(getLinkBoundariesAtSelection(editor))
        setLinkModalData({ link, text: text ?? '', isEdit: !!link })
        setIsLinkModalOpened(true)
    }

    const modules = useMemo(
        () => ({
            keyboard: true,
            clipboard: { matchVisual: false },
            toolbar: {
                container: `#${toolbarId}`,
                handlers: {
                    link: onLinkClickHandler,
                },
            },
            magicUrl: true,
        }),
        [toolbarId],
    )

    const handleContentChange = (newValue: string, delta: DeltaStatic, source: Sources, editor: ReactQuill.UnprivilegedEditor) => {
        if (regex.test(newValue)) {
            newValue = ''
        }

        newValue.length && clearErrors && clearErrors(name)
        onChange && onChange(newValue, delta, source, editor)
        setValue && setValue(name, newValue)
    }

    useEffect(() => {
        const quillEditor = document.querySelector(`#${quillId}`)
        const editArea = quillEditor?.querySelector('.ql-editor')

        editArea?.setAttribute('aria-labelledby', `${labelId} ${errorId}`)
        editArea?.setAttribute('aria-multiline', 'true')
        editArea?.setAttribute('role', 'textbox')
    }, [quillId, errorId, labelId])

    const handleLinkSubmit = (link: string, text?: string, shouldCreateLink?: boolean) => {
        const editor = quillRef.current?.getEditor()
        const selection = editor?.getSelection()

        editor?.deleteText(editorLinkSelection?.index ?? 0, editorLinkSelection?.length ?? 0)
        if (shouldCreateLink) {
            editor?.insertText(editorLinkSelection?.index ?? 0, text ?? link, 'link', formatLink(link), 'user')
        } else {
            editor?.insertText(editorLinkSelection?.index ?? 0, text ?? link, 'text', 'user')
        }
        editor?.setSelection((selection?.index ?? 0) + (text?.length ?? 0), 0)

        setLinkModalData({})
        setIsLinkModalOpened(false)
    }

    const handleLinkModalClose = () => {
        setLinkModalData({})
        setIsLinkModalOpened(false)
    }

    useEffect(() => {
        if (setValue && value) {
            setValue(name, value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div
            className={classNames('govuk-form-group', styles.fieldset, { 'govuk-form-group--error': !!error })}
            aria-description={`${t('quill.description')} ${label ? label : ariaLabel}`}
        >
            <span
                id={errorId}
                role={announceErrorToScreenReader ? 'alert' : undefined}
                className={classNames({ 'govuk-visually-hidden': !error, 'govuk-error-message': !!error })}
            >
                {error && <span className="govuk-visually-hidden">{t('error')}</span>}
                {error}
            </span>
            <div className={styles.header}>
                {label && (
                    <p className="govuk-label" id={labelId} onClick={() => quillRef.current?.focus()}>
                        {label + requiredLabel}
                    </p>
                )}
                <div className={styles.infoDiv}>
                    {info && (
                        <Tooltip
                            altText={t('tooltip', { tooltip: label })}
                            descriptionElement={
                                <div className="tooltipWidth500">
                                    {
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: sanitizeHtml(decodeHtmlEntities(info)),
                                            }}
                                        />
                                    }
                                </div>
                            }
                        />
                    )}
                </div>
            </div>
            <div className={classNames({ 'govuk-input--error': !!error && !isFocused, [styles.focus]: isFocused })}>
                <CustomToolbar
                    excludeOptions={excludeOptions}
                    id={toolbarId}
                    onLinkClick={onLinkClickHandler}
                    labelId={labelId}
                    error={!!error}
                    isFocused={isFocused}
                />
                <div
                    onKeyDownCapture={(event) => {
                        if (event.key === 'Escape') {
                            event.stopPropagation()
                            quillRef.current?.blur()
                        }
                    }}
                >
                    <ReactQuill
                        key={i18n.language}
                        id={quillId}
                        value={value}
                        ref={quillRef}
                        className={classNames(styles.customEditor, { [styles.formError]: !!error && !isFocused })}
                        formats={formats}
                        modules={modules}
                        onChange={(newValue, newDelta, source, editor) => {
                            handleContentChange(newValue, newDelta, source, editor)
                        }}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        defaultValue={defaultValue}
                        readOnly={readOnly}
                        aria-errormessage={errorId}
                        aria-invalid={!!errorId}
                        aria-describedby={errorId}
                    />
                </div>

                <LinkModal
                    isOpen={isLinkModalOpened}
                    onSubmit={handleLinkSubmit}
                    onClose={handleLinkModalClose}
                    isEdit={linkModalData.isEdit}
                    defaultLink={linkModalData.link}
                    defaultText={linkModalData.text}
                />
            </div>
        </div>
    )
}
