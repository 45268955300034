import { PaginatorWrapper, Table } from '@isdd/idsk-ui-kit/index'
import { IFilter } from '@isdd/idsk-ui-kit/types'
import { ColumnDef } from '@tanstack/react-table'
import React from 'react'

interface DataTableWithPaginationProps<T> {
    data: T[]
    columns: Array<ColumnDef<T>>
    pageNumber: number
    pageSize: number
    dataLength: number
    handlePageChange: (filter: IFilter) => void
    width?: 'one-half'
}

export const DataTableWithPagination = <T,>({
    data,
    columns,
    pageNumber,
    pageSize,
    dataLength,
    handlePageChange,
    width,
}: DataTableWithPaginationProps<T>) => {
    return (
        <>
            <Table columns={columns} data={data} />
            <PaginatorWrapper pageNumber={pageNumber} pageSize={pageSize} dataLength={dataLength} handlePageChange={handlePageChange} width={width} />
        </>
    )
}
