import { PaginatorWrapper, Table, Tabs } from '@isdd/idsk-ui-kit/index'
import { ConfigurationItemUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { MetainformationColumns } from '@isdd/metais-common/componentHelpers/ci/getCiDefaultMetaAttributes'
import { setLangForAttribute } from '@isdd/metais-common/componentHelpers/englishAttributeLang'
import { LangWrapper } from '@isdd/metais-common/components/lang-wrapper/LangWrapper'
import { DEFAULT_PAGESIZE_OPTIONS, ENTITY_AGENDA, ENTITY_ZS } from '@isdd/metais-common/constants'
import { ATTRIBUTE_NAME, ActionsOverTable, BASE_PAGE_NUMBER, BASE_PAGE_SIZE, QueryFeedback } from '@isdd/metais-common/index'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { KrisData, KrisKeysToDisplayType, KrisRelatedPagination } from '@/components/containers/KrisRelatedContainer'

type Props = {
    handleFilterChange: (filter: KrisRelatedPagination) => void
    filter: KrisRelatedPagination
    isError: boolean
    isLoading: boolean
    isListLoading: boolean
    data: KrisData
    keysToDisplay: KrisKeysToDisplayType[]
    currentTab: string
}

export const KrisRelatedItemsView: React.FC<Props> = ({
    filter,
    isLoading,
    isError,
    handleFilterChange,
    data,
    keysToDisplay,
    currentTab,
    isListLoading,
}) => {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const { zsData, agendaData, ciListData } = data
    const startOfList = filter.pageNumber * filter.pageSize - filter.pageSize
    const endOfList = filter.pageNumber * filter.pageSize
    const tableRef = useRef<HTMLTableElement>(null)

    const columns: Array<ColumnDef<ConfigurationItemUi>> = [
        {
            id: ATTRIBUTE_NAME.Gen_Profil_nazov,
            accessorFn: (row) => row.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov],
            header: t('KRIS.table.name'),
            cell: (ctx: CellContext<ConfigurationItemUi, unknown>) => (
                <LangWrapper lang={setLangForAttribute({ language, technicalName: ATTRIBUTE_NAME.Gen_Profil_nazov })}>
                    <Link to={`/ci/${currentTab}/${ctx.row.original.uuid}`}>{ctx.getValue() as string}</Link>
                </LangWrapper>
            ),
            meta: {
                getCellContext: (ctx: CellContext<ConfigurationItemUi, unknown>) => {
                    ctx.getValue() as string
                },
            },
            enableSorting: true,
        },
        {
            id: ATTRIBUTE_NAME.Gen_Profil_kod_metais,
            accessorFn: (row) => row.attributes?.[ATTRIBUTE_NAME.Gen_Profil_kod_metais],
            header: t('KRIS.table.code'),
            cell: (ctx: CellContext<ConfigurationItemUi, unknown>) => ctx.getValue() as string,
            meta: {
                getCellContext: (ctx: CellContext<ConfigurationItemUi, unknown>) => {
                    ctx.getValue() as string
                },
            },
            enableSorting: true,
        },
        {
            id: MetainformationColumns.STATE,
            accessorFn: (row) => row.metaAttributes?.[MetainformationColumns.STATE],
            header: t('KRIS.table.state'),
            cell: (ctx: CellContext<ConfigurationItemUi, unknown>) => t(`metaAttributes.state.${ctx.getValue()}`),
            meta: {
                getCellContext: (ctx: CellContext<ConfigurationItemUi, unknown>) => {
                    t(`metaAttributes.state.${ctx.getValue()}`)
                },
            },
            enableSorting: true,
        },
    ]

    return (
        <QueryFeedback loading={isLoading} error={false}>
            <Tabs
                tabList={keysToDisplay?.map((key) => {
                    const isZs = key.technicalName === ENTITY_ZS
                    const isAgenda = key.technicalName === ENTITY_AGENDA

                    const dataForTable = isAgenda
                        ? agendaData?.slice(startOfList, endOfList)
                        : isZs
                        ? zsData?.slice(startOfList, endOfList)
                        : ciListData?.configurationItemSet

                    const totalItems = (isAgenda ? agendaData?.length : isZs ? zsData?.length : ciListData?.pagination?.totaltems) ?? 0
                    const totalPages = isAgenda
                        ? Math.ceil((agendaData?.length ?? 0) / filter.pageSize)
                        : isZs
                        ? Math.ceil((zsData?.length ?? 0) / filter.pageSize)
                        : ciListData?.pagination?.totalPages

                    return {
                        id: key.technicalName,
                        title: key?.title + `(${key.count ?? 0})`,
                        content: (
                            <QueryFeedback
                                loading={isListLoading}
                                error={isError}
                                errorProps={{ errorMessage: t('feedback.failedFetch') }}
                                withChildren
                            >
                                <ActionsOverTable
                                    entityName=""
                                    pagination={{ ...filter, dataLength: totalItems }}
                                    pagingOptions={DEFAULT_PAGESIZE_OPTIONS}
                                    hiddenButtons={{ SELECT_COLUMNS: true }}
                                    handleFilterChange={({ pageNumber, pageSize }) => {
                                        handleFilterChange({
                                            ...filter,
                                            pageNumber: pageNumber ?? BASE_PAGE_NUMBER,
                                            pageSize: pageSize ?? BASE_PAGE_SIZE,
                                            krisTabType: key.technicalName,
                                        })
                                    }}
                                    pageSize={filter.pageSize.toString()}
                                />
                                <Table
                                    tableRef={tableRef}
                                    isLoading={isListLoading}
                                    error={isError}
                                    columns={columns}
                                    data={dataForTable}
                                    sort={filter.sortBy ?? []}
                                    onSortingChange={(columnSort) => {
                                        handleFilterChange({ ...filter, krisTabType: key.technicalName, sortBy: columnSort })
                                    }}
                                />
                                <PaginatorWrapper
                                    pageNumber={filter.pageNumber}
                                    pageSize={filter.pageSize}
                                    dataLength={totalItems}
                                    totalPages={totalPages}
                                    handlePageChange={({ pageNumber }) => {
                                        handleFilterChange({ ...filter, pageNumber: pageNumber ?? BASE_PAGE_NUMBER, krisTabType: key.technicalName })
                                        tableRef.current?.scrollIntoView({ behavior: 'smooth' })
                                    }}
                                />
                            </QueryFeedback>
                        ),
                    }
                })}
                onSelect={(selected) => {
                    handleFilterChange({ ...filter, pageNumber: BASE_PAGE_NUMBER, pageSize: BASE_PAGE_SIZE, krisTabType: selected.id })
                }}
            />
        </QueryFeedback>
    )
}
