import classNames from 'classnames'
import React, { DetailedHTMLProps, forwardRef, useId } from 'react'
import { decodeHtmlEntities } from '@isdd/metais-common/utils/utils'
import { useTranslation } from 'react-i18next'
import { MODAL_TOOLTIP_ZINDEX } from '@isdd/metais-common/constants'

import styles from './checkbox.module.scss'

import { Tooltip } from '@isdd/idsk-ui-kit/tooltip/Tooltip'

interface ICheckBoxProps extends DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    id?: string
    label: string | React.ReactNode
    name: string
    value?: string
    disabled?: boolean
    labelClassName?: string
    containerClassName?: string
    error?: string
    info?: string
    title?: string
    htmlForDisabled?: boolean
    activeAndInactive?: boolean
    isInModal?: boolean
    announceErrorToScreenReader?: boolean
}

export const CheckBox = forwardRef<HTMLInputElement, ICheckBoxProps>(
    (
        {
            id,
            label,
            error,
            name,
            disabled,
            value,
            info,
            title,
            labelClassName,
            containerClassName,
            className,
            htmlForDisabled = false,
            activeAndInactive,
            isInModal = false,
            announceErrorToScreenReader = false,
            ...rest
        },
        ref,
    ) => {
        const { t } = useTranslation()
        const uId = useId()
        const inputId = id ?? `input_${uId}` // NVDA has problems with ID starting with `useID` `::`, needs to be prefixed
        const errorId = `${id}-error`
        return (
            <div className={classNames({ 'govuk-form-group--error': !!error })}>
                <span
                    id={errorId}
                    role={announceErrorToScreenReader ? 'alert' : undefined}
                    className={classNames({ 'govuk-visually-hidden': !error, 'govuk-error-message': !!error })}
                >
                    {error && <span className="govuk-visually-hidden">{t('error')}</span>}
                    {error}
                </span>
                <div className={classNames('govuk-checkboxes__item', containerClassName)}>
                    <div className={styles.checkboxWrap}>
                        <input
                            className={classNames('govuk-checkboxes__input', className, {
                                [styles.activeAndInactiveInput]: activeAndInactive,
                            })}
                            id={inputId}
                            name={name}
                            type="checkbox"
                            value={value}
                            readOnly={disabled}
                            ref={ref}
                            {...rest}
                            title={title}
                            aria-invalid={!!error}
                            aria-describedby={errorId}
                            aria-errormessage={errorId}
                            {...(disabled
                                ? {
                                      onClick: (e) => {
                                          e.preventDefault()
                                      },
                                  }
                                : {})}
                            {...(disabled
                                ? {
                                      onChange: (e) => {
                                          e.preventDefault()
                                      },
                                  }
                                : {})}
                        />

                        {label ? (
                            <label
                                className={classNames('govuk-label govuk-checkboxes__label', labelClassName, {
                                    [styles.activeAndInactiveLabel]: activeAndInactive,
                                })}
                                htmlFor={htmlForDisabled ? '' : inputId}
                            >
                                {label}
                            </label>
                        ) : (
                            // needed for render
                            <span className={classNames('govuk-label govuk-checkboxes__label', labelClassName)} />
                        )}
                        {info && (
                            <Tooltip
                                descriptionElement={<div className="tooltipWidth500">{decodeHtmlEntities(info)}</div>}
                                altText={t('tooltip', { tooltip: label })}
                                contentStyle={isInModal ? { zIndex: MODAL_TOOLTIP_ZINDEX } : undefined}
                            />
                        )}
                    </div>
                </div>
            </div>
        )
    },
)
