import { TextLinkExternal } from '@isdd/idsk-ui-kit/index'
import { ApiAttachment } from '@isdd/metais-common/api/generated/standards-swagger'
import { useGetFileSize } from '@isdd/metais-common/hooks/useGetFileSize'
import { useCallback, useEffect, useState } from 'react'

import styles from '@/components/views/standardization/votes/vote.module.scss'
const DMS_DOWNLOAD_FILE = `${import.meta.env.VITE_REST_CLIENT_DMS_TARGET_URL}/file/`

interface IAttachmentLink {
    attachments: ApiAttachment[] | undefined
}

export const AttachmentLinks: React.FC<IAttachmentLink> = ({ attachments }) => {
    const [filesSizes, setFilesSizes] = useState<{ uuid: string; size: string; extension: string; metaComponent: string }[]>([])

    const { loadData } = useGetFileSize()

    const loadSizeInfo = useCallback(async () => {
        const sizes = await loadData(attachments?.map((d) => d.attachmentId ?? '') ?? [])
        setFilesSizes(sizes)
    }, [attachments, loadData])

    useEffect(() => {
        if ((attachments?.length != 0 && filesSizes?.length == 0) || attachments?.length != filesSizes.length) {
            loadSizeInfo()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attachments, filesSizes?.length])

    return (
        <>
            {attachments?.map((attachment) => {
                return (
                    <div key={attachment.id} className={styles.linkAlign}>
                        <TextLinkExternal
                            key={attachment?.id}
                            title={`${attachment?.attachmentName} ${filesSizes.find((f) => f.uuid == attachment?.attachmentId)?.metaComponent ?? ''}`}
                            href={`${DMS_DOWNLOAD_FILE}${attachment?.attachmentId}`}
                            textLink={`${attachment?.attachmentName} ${
                                filesSizes.find((f) => f.uuid == attachment?.attachmentId)?.metaComponent ?? ''
                            }`}
                            newTab
                        />{' '}
                    </div>
                )
            })}
        </>
    )
}
