import { SortBy, SortType } from '@isdd/idsk-ui-kit/types'
import { ConfigurationItemUi, useReadCiList1 } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { GetContentParams, Metadata, useGetContentHook, useGetMeta1 } from '@isdd/metais-common/api/generated/dms-swagger'
import { EnumItem, useGetEnum } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { ApiError, useIsOwnerByGid } from '@isdd/metais-common/api/generated/iam-swagger'
import {
    ApiOlaContractData,
    ListOlaContractListParams,
    RequestIdUi,
    useGetOlaContract,
    useOlaContractTransition,
} from '@isdd/metais-common/api/generated/monitoring-swagger'
import { BASE_PAGE_NUMBER, ENTITY_ISVS, OLA_Kontrakt, ROLES, STAV_OLA_KONTRAKT } from '@isdd/metais-common/constants'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { useGetCiTypeWrapper } from '@isdd/metais-common/hooks/useCiType.hook'
import { useGetStatus } from '@isdd/metais-common/hooks/useGetRequestStatus'
import { RouterRoutes } from '@isdd/metais-common/navigation/routeNames'
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, UseMutateAsyncFunction } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { canEditOlaContract } from '@/components/views/ola-contract-list/helper'

export interface IAdditionalFilterField extends ListOlaContractListParams {
    liableEntities?: string[]
}

export interface IMutationErrors {
    isProcessedError?: boolean
    isTooLongError?: boolean
}

export interface IOlaContractDetailView {
    isLoading: boolean
    isError?: boolean
    olaContract?: ApiOlaContractData
    document?: Metadata
    downloadVersionFile: (uuid: string, params?: GetContentParams, signal?: AbortSignal) => Promise<Blob>
    refetch: <TPageData>(options?: RefetchOptions & RefetchQueryFilters<TPageData>) => Promise<QueryObserverResult<ApiOlaContractData, ApiError>>
    showHistory: boolean
    setShowHistory: React.Dispatch<React.SetStateAction<boolean>>
    isOwnerOfContract?: boolean
    canChange?: boolean
    canMoveState: boolean
    statesEnum?: EnumItem[]
    moveState: UseMutateAsyncFunction<
        RequestIdUi,
        ApiError,
        {
            olaContractUuid: string
            transition: string
        },
        unknown
    >
    isvsOfOlaContract?: ConfigurationItemUi[]
}

interface IOlaContractAddContainer {
    View: React.FC<IOlaContractDetailView & IMutationErrors>
}

export const OlaContractDetailContainer: React.FC<IOlaContractAddContainer> = ({ View }) => {
    const { entityId } = useParams()
    const { data: olaContract, isLoading: isOlaContractLoading, isError: isOlaContractError, refetch } = useGetOlaContract(entityId ?? '')
    const {
        data: olaContractDocument,
        isLoading: isOlaContractDocumentLoading,
        isError: isOlaContractDocumentError,
    } = useGetMeta1(entityId ?? '', {}, { query: { retry: 1 } })
    const downloadVersionFile = useGetContentHook()
    const [showHistory, setShowHistory] = useState(false)
    const { data: ciType, isLoading: isCiTypeLoading, isError: isCiTypeError } = useGetCiTypeWrapper(OLA_Kontrakt)
    const { getRequestStatus, isError: isGetStatusError, isTooManyFetchesError, isLoading: isGettingStatus, isProcessedError } = useGetStatus()
    const { setIsActionSuccess } = useActionSuccess()
    const {
        data: statesEnum,
        isLoading: isStatesLoading,
        isError: isStatesError,
    } = useGetEnum(STAV_OLA_KONTRAKT, { query: { select: (data) => data.enumItems } })

    const {
        mutateAsync: moveState,
        isLoading: isStateMoving,
        isError: isStateMovingError,
    } = useOlaContractTransition({
        mutation: {
            onSuccess: async (resp) => {
                await getRequestStatus(resp.requestId ?? '', () => {
                    refetch()
                    setIsActionSuccess({
                        value: true,
                        path: olaContract ? RouterRoutes.OLA_CONTRACT_LIST + '/' + olaContract.uuid : RouterRoutes.OLA_CONTRACT_LIST,
                        additionalInfo: { type: 'stateChanged' },
                    })
                })
            },
        },
    })

    const {
        data: isvsOfOlaContract,
        isLoading: isIsvsLoading,
        isError: isIsvsError,
    } = useReadCiList1(
        {
            filter: {
                type: [ENTITY_ISVS],
                uuid: olaContract?.contractorIsvsUuids?.map((item) => item.uuid ?? ''),
            },
            page: BASE_PAGE_NUMBER,
            perpage: 999,
            sortBy: SortBy.GEN_PROFIL_NAZOV,
            sortType: SortType.ASC,
        },
        { query: { enabled: (olaContract?.contractorIsvsUuids?.length || 0) > 0 } },
    )

    const {
        state: { user, token },
    } = useAuth()
    const isLoggedIn = !!user?.uuid
    const {
        data: isOwnerByGid,
        isFetching: isOwnerByGidLoading,
        isError: isOwnerByGidError,
    } = useIsOwnerByGid(
        {
            gids: [olaContract?.owner ?? ''],
            login: user?.login,
        },
        { query: { enabled: !isOlaContractLoading && token !== null && isLoggedIn } },
    )

    const isOwnerOfContract = isOwnerByGid?.isOwner?.[0]?.owner

    return (
        <View
            canChange={canEditOlaContract(user, ciType)}
            isOwnerOfContract={isOwnerOfContract}
            showHistory={showHistory}
            setShowHistory={setShowHistory}
            isLoading={
                isStatesLoading ||
                isGettingStatus ||
                isStateMoving ||
                isOlaContractLoading ||
                isOwnerByGidLoading ||
                isIsvsLoading ||
                isCiTypeLoading ||
                (!isOlaContractDocumentError && isOlaContractDocumentLoading)
            }
            isError={
                isStateMovingError ||
                isOlaContractError ||
                isOwnerByGidError ||
                isCiTypeError ||
                isGetStatusError ||
                isIsvsError ||
                isTooManyFetchesError ||
                isStatesError
            }
            isvsOfOlaContract={isvsOfOlaContract?.configurationItemSet}
            olaContract={olaContract}
            document={olaContractDocument}
            downloadVersionFile={downloadVersionFile}
            refetch={refetch}
            moveState={moveState}
            canMoveState={user?.roles.includes(ROLES.R_EGOV) ?? false}
            statesEnum={statesEnum}
            isProcessedError={isProcessedError}
        />
    )
}
