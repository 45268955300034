import { NeighbourPairUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { INVALIDATED } from '@isdd/metais-common/constants'
import {
    ColumnDef,
    ColumnOrderState,
    ExpandedState,
    OnChangeFn,
    PaginationState,
    Row,
    RowSelectionState,
    getCoreRowModel,
    getExpandedRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import classNames from 'classnames'
import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DraggableColumnHeader } from './DraggableColumnHeader'
import { ExpandableRowCellWrapper } from './ExpandableRowCellWrapper'
import { TableDragRow } from './TableDragRow'
import { TableInfoMessage } from './TableInfoMessage'
import { TableRow } from './TableRow'
import { TableRowExpanded } from './TableRowExpanded'
import { CHECKBOX_CELL, EXPANDABLE_CELL } from './constants'
import styles from './table.module.scss'
import {
    getExpandableRowContentId,
    hasMetaAttributesWithStateProperty,
    transformColumnSortToSortingState,
    transformSortingStateToColumnSort,
} from './tableUtils'

import { ColumnSort } from '@isdd/idsk-ui-kit/types'

const invalidStates = ['INVALIDATED', 'Zneplatnené', 'Invalidated']

export interface TableHandle {
    refreshColumns: () => void
}

export interface ITableProps<T> {
    data?: Array<T>
    columns: Array<ColumnDef<T>>
    canDrag?: boolean
    canDragRow?: boolean
    sort?: ColumnSort[]
    onSortingChange?: (sort: ColumnSort[]) => void
    columnOrder?: ColumnOrderState
    onColumnOrderChange?: OnChangeFn<ColumnOrderState>
    pagination?: PaginationState
    rowSelection?: RowSelectionState
    onRowSelectionChange?: React.Dispatch<React.SetStateAction<RowSelectionState>>
    onPaginationChange?: OnChangeFn<PaginationState> | undefined
    expandedRowsState?: ExpandedState
    onExpandedChange?: React.Dispatch<React.SetStateAction<ExpandedState>>
    getSubRows?: (row: T) => T[] | undefined
    /**
     * @deprecated
     */
    isRowSelected?: (row: Row<T>) => boolean
    isRowBold?: (row: Row<T>) => boolean
    isRowDanger?: (row: Row<T>) => boolean
    enableRowSelection?: boolean | ((row: Row<T>) => boolean) | undefined
    enableMultiRowSelection?: boolean | ((row: Row<T>) => boolean)
    isLoading?: boolean
    error?: boolean
    getExpandedRow?: (row: Row<T>) => JSX.Element | null
    onRowClick?: (row: Row<T>) => void
    rowHref?: (row: Row<T>) => string
    linkToNewTab?: boolean
    reorderRow?: (index: number, target: number) => void
    hideHeaders?: boolean
    manualSorting?: boolean
    manualPagination?: boolean
    tableRef?: React.RefObject<HTMLTableElement>
    handleRef?: React.RefObject<TableHandle>
    showEmptyTableMessage?: boolean
    notDraggableList?: string[]
    isInModal?: boolean
    getRowId?: (originalRow: T, index: number, parent?: Row<T> | undefined) => string
    automaticHeaderColumn?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tableMeta?: any
}

export const Table = <T,>({
    data,
    columns,
    canDrag = false,
    canDragRow = false,
    sort,
    onSortingChange,
    columnOrder,
    onColumnOrderChange,
    pagination,
    rowSelection,
    onRowSelectionChange,
    onPaginationChange,
    expandedRowsState,
    onExpandedChange,
    getSubRows,
    isRowBold,
    isRowDanger,
    isLoading = false,
    error = false,
    getExpandedRow,
    onRowClick,
    rowHref,
    linkToNewTab,
    reorderRow,
    hideHeaders,
    manualSorting = true,
    manualPagination = true,
    tableRef,
    handleRef,
    showEmptyTableMessage = true,
    notDraggableList,
    isInModal,
    automaticHeaderColumn = true,
    enableRowSelection,
    enableMultiRowSelection = true,
    getRowId,
    tableMeta: meta,
}: ITableProps<T>): JSX.Element => {
    const { t } = useTranslation()
    const wrapper1Ref = useRef<HTMLTableSectionElement>(null)
    const wrapper2Ref = useRef<HTMLTableSectionElement>(null)
    const [columnOrderState, setColumnOrderState] = useState<ColumnOrderState>(columnOrder || columns.map((d) => d.id || ''))
    const [seed, setSeed] = useState(1)

    const columnsAreEqual = (newCols: Array<ColumnDef<T>>, oldCols: Array<ColumnDef<T>>): boolean => {
        // expandable cell is added to memoized columns list inside of table, so we need to remove it from comparison
        const oldColumnsWithoutExpandableCell = oldCols.filter((col) => col.id !== EXPANDABLE_CELL)
        return (
            newCols.length === oldColumnsWithoutExpandableCell.length &&
            newCols.every((obj1) => oldColumnsWithoutExpandableCell.some((obj2) => obj1.id === obj2.id))
        )
    }

    const refreshColumns = () => {
        setSeed(Math.random())
    }

    useImperativeHandle(handleRef, () => ({
        refreshColumns,
    }))

    useEffect(() => {
        if (!canDrag) return
        if (!columnOrder) setColumnOrderState(columns.map((column) => column.id || ''))
        else setColumnOrderState(columnOrder)
    }, [columnOrder, columns, canDrag])

    const transformedSort = transformColumnSortToSortingState(sort)

    const tableColumns = useMemo(
        () => [
            ...columns,
            ...(getExpandedRow
                ? [
                      {
                          id: EXPANDABLE_CELL,
                          size: 20,
                          cell: ({ row }: { row: Row<T> }) => <ExpandableRowCellWrapper row={row} ariaControlsId={getExpandableRowContentId(row)} />,
                      },
                  ]
                : []),
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [seed],
    )

    useEffect(() => {
        // This solves changing visible columns in table
        if (!columnsAreEqual(columns, tableColumns)) {
            refreshColumns()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns])

    const table = useReactTable({
        data: data ?? [],
        columns: tableColumns,
        sortDescFirst: false,
        state: {
            ...(pagination && { pagination }),
            columnOrder: columnOrderState,
            sorting: transformedSort,
            expanded: expandedRowsState,
            rowSelection: rowSelection ?? {},
        },
        onRowSelectionChange,
        onSortingChange: (sortUpdater) => {
            if (typeof sortUpdater === 'function') {
                const columnSort = transformSortingStateToColumnSort(sortUpdater, transformedSort)
                onSortingChange?.(columnSort)
            }
        },
        getRowId: (originalRow: T, index: number, parent?: Row<T> | undefined) => getRowId?.(originalRow, index, parent) ?? `${index}`,
        manualSorting: manualSorting,
        getSortedRowModel: getSortedRowModel(),
        onColumnOrderChange: onColumnOrderChange || setColumnOrderState,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange,
        getExpandedRowModel: getExpandedRowModel(),
        onExpandedChange,
        getSubRows: getSubRows,
        enableRowSelection,
        enableMultiRowSelection,
        enableMultiSort: true,
        manualPagination: manualPagination,
        getRowCanExpand: getExpandedRow ? (row) => !!getExpandedRow(row) : undefined,
        defaultColumn: {
            size: 100,
        },
        meta,
    })

    const isEmptyRows = table.getRowModel().rows.length === 0

    const handleWrapper1Scroll = () => {
        if (wrapper1Ref.current && wrapper2Ref.current) {
            wrapper2Ref.current.scrollLeft = wrapper1Ref.current.scrollLeft
        }
    }

    const handleWrapper2Scroll = () => {
        if (wrapper1Ref.current && wrapper2Ref.current) {
            wrapper1Ref.current.scrollLeft = wrapper2Ref.current.scrollLeft
        }
    }

    const [selectedRow, setSelectedRow] = useState<number | null>(null)

    const sortableRowsSr = (
        <>
            <div id="operation" className="sr-only">
                {t('table.dragRow.srOperation')}
            </div>
            <div id="title" className="sr-only">
                {t('table.dragRow.srTitle')}
            </div>
        </>
    )

    return (
        <>
            {canDragRow && sortableRowsSr}

            <table
                ref={tableRef}
                className={classNames('idsk-table', [styles.displayBlock, styles.tableSticky, styles.initialOverflow])}
                aria-labelledby={canDragRow ? 'title' : undefined}
                aria-describedby={canDragRow ? 'operation' : undefined}
            >
                {!hideHeaders && (
                    <thead className={classNames('idsk-table__head', [styles.head])} onScroll={handleWrapper2Scroll} ref={wrapper2Ref}>
                        {table.getHeaderGroups().map((headerGroup) => {
                            const hasCheckbox = headerGroup.headers.find((cell) => cell.id === CHECKBOX_CELL)
                            const isExpandable = headerGroup.headers.find((cell) => cell.id === EXPANDABLE_CELL)
                            return (
                                <tr
                                    className={classNames('idsk-table__row', styles.headerRow, {
                                        [styles.checkBoxHeaderRow]: hasCheckbox,
                                        [styles.expandableHeaderRow]: isExpandable,
                                    })}
                                    key={headerGroup.id}
                                >
                                    {headerGroup.headers.map((header) => (
                                        <DraggableColumnHeader<T>
                                            key={header.id}
                                            header={header}
                                            table={table}
                                            canDrag={canDrag}
                                            notDraggableList={notDraggableList}
                                        />
                                    ))}
                                </tr>
                            )
                        })}
                    </thead>
                )}
                {!isLoading && isEmptyRows && showEmptyTableMessage && (
                    <tbody className={styles.displayFlex}>
                        <tr>
                            <td>
                                <TableInfoMessage error={error} isEmptyRows={isEmptyRows} key="info" />
                            </td>
                        </tr>
                    </tbody>
                )}

                <tbody
                    className={classNames('idsk-table__body', styles.body, styles.positionRelative, {
                        [styles.minHeight400]: isEmptyRows && isLoading,
                    })}
                    onScroll={handleWrapper1Scroll}
                    ref={wrapper1Ref}
                >
                    {table.getRowModel().rows.map((row, index) => {
                        const isInvalidated =
                            (hasMetaAttributesWithStateProperty(row) && row.original.metaAttributes?.state === INVALIDATED) ||
                            invalidStates.includes(
                                row
                                    .getAllCells()
                                    .find((cell) => cell.column.id == 'state')
                                    ?.getValue() as string,
                            ) ||
                            (row.original as NeighbourPairUi)?.relationship?.metaAttributes?.state === INVALIDATED

                        return (
                            <React.Fragment key={index}>
                                {canDragRow ? (
                                    <TableDragRow<T>
                                        isInModal={isInModal}
                                        row={row}
                                        key={index}
                                        isRowBold={isRowBold}
                                        isRowDanger={isRowDanger}
                                        onRowClick={onRowClick}
                                        rowHref={rowHref}
                                        reorderRow={reorderRow}
                                        isInvalidated={isInvalidated}
                                        linkToNewTab={linkToNewTab}
                                        selectedRow={selectedRow}
                                        setSelectedRow={setSelectedRow}
                                        isLoading={isLoading}
                                        dataLength={data?.length ?? 0}
                                        automaticHeaderColumn={automaticHeaderColumn}
                                    />
                                ) : (
                                    <TableRow<T>
                                        isInModal={isInModal}
                                        isInvalidated={isInvalidated}
                                        row={row}
                                        key={index}
                                        isRowBold={isRowBold}
                                        isRowDanger={isRowDanger}
                                        onRowClick={onRowClick}
                                        rowHref={rowHref}
                                        linkToNewTab={linkToNewTab}
                                        automaticHeaderColumn={automaticHeaderColumn}
                                    />
                                )}
                                {row.getIsExpanded() && getExpandedRow && (
                                    <TableRowExpanded row={row} id={getExpandableRowContentId(row)} getExpandedRow={getExpandedRow} />
                                )}
                            </React.Fragment>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}
