import { BreadCrumbs, HomeIcon } from '@isdd/idsk-ui-kit/index'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { ENTITY_AS } from '@isdd/metais-common/constants'
import { setDocumentTitle } from '@isdd/metais-common/utils/utils'

import { MainContentWrapper } from '@/components/MainContentWrapper'
import { CiCloneContainer } from '@/components/containers/CiCloneContainer'
import { CiPermissionsWrapper } from '@/components/permissions/CiPermissionsWrapper'
import { CloneCiEntityView } from '@/components/views/ci/clone/CloneCiEntityView'
import { getCiHowToBreadCrumb } from '@/componentHelpers/ci'

const CloneASPage = () => {
    const { t } = useTranslation()
    const { entityId } = useParams()
    const entityName = ENTITY_AS

    const relationTypeTechnicalNames = ['AS_ma_verziu_AS']

    return (
        <>
            <CiCloneContainer
                entityName={entityName ?? ''}
                configurationItemId={entityId ?? ''}
                technicalNames={relationTypeTechnicalNames}
                View={(props) => {
                    setDocumentTitle(
                        t('titles.ciClone', {
                            ci: props.ciTypeData?.name,
                            itemName: props.ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov],
                        }),
                    )

                    return (
                        <>
                            <BreadCrumbs
                                withWidthContainer
                                links={[
                                    { label: t('breadcrumbs.home'), href: '/', icon: HomeIcon },
                                    ...getCiHowToBreadCrumb(entityName ?? '', t),
                                    { label: t('titles.ciList', { ci: props.ciTypeData?.name }), href: `/ci/${entityName}` },
                                    { label: props.ciName ? props.ciName : t('breadcrumbs.noName'), href: `/ci/${entityName}/${entityId}` },
                                    { label: t('breadcrumbs.ciClone', { itemName: props.ciName }), href: `/ci/${entityName}/${entityId}/edit` },
                                ]}
                            />
                            <MainContentWrapper>
                                <CiPermissionsWrapper entityName={entityName ?? ''} entityId={entityId ?? ''}>
                                    <CloneCiEntityView {...props} />
                                </CiPermissionsWrapper>
                            </MainContentWrapper>
                        </>
                    )
                }}
            />
        </>
    )
}

export default CloneASPage
