import React, { ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonLink } from '@isdd/idsk-ui-kit/button-link/ButtonLink'
import classNames from 'classnames'
import { FieldValues, UseFormRegister } from 'react-hook-form'

import style from './dynamicElements.module.scss'

import { Trash } from '@isdd/metais-common/assets/images'

export interface RenderableComponentProps<T> {
    data?: T
    onChange?: (data: T) => void
    register?: UseFormRegister<FieldValues>
}

interface DynamicRowProps<T extends object> {
    index: number
    lastElement: boolean
    defaultRowData?: T
    doNotRemove?: boolean
    renderableComponent: (index: number | undefined, props: RenderableComponentProps<T>) => ReactNode
    onChange?: (data: T) => void
    remove?: (index: number) => void
    removeLabelSubject: string | React.ReactNode
    register?: UseFormRegister<FieldValues>
    id: string
}

export const DynamicRow: <T extends object>({
    index,
    renderableComponent,
    defaultRowData,
    doNotRemove,
    onChange,
    remove,
    register,
}: DynamicRowProps<T>) => ReactElement<DynamicRowProps<T>> = ({
    index,
    lastElement,
    defaultRowData,
    doNotRemove,
    renderableComponent,
    onChange,
    remove,
    removeLabelSubject,
    register,
    id,
}) => {
    const { t } = useTranslation()

    return (
        <fieldset className={classNames(style.inline, style.spaceVertical, style.fieldset)}>
            <legend className="sr-only">
                {t('dynamicElements.legend', { index: index + 1 })}
                &nbsp;
                {removeLabelSubject}
            </legend>
            <div className={style.stretch} tabIndex={-1} id={id}>
                {renderableComponent(index, { data: defaultRowData, onChange, register })}
            </div>
            {!doNotRemove && lastElement && (
                <ButtonLink
                    onClick={(e) => {
                        e.preventDefault()
                        remove?.(index)
                    }}
                    type="button"
                    className={style.trashIcon}
                    label={
                        <>
                            <span className="govuk-visually-hidden">
                                {t('dynamicElements.removeItem', { index: index + 1 })}
                                &nbsp;
                                {removeLabelSubject}
                            </span>
                            <img src={Trash} alt="" />
                        </>
                    }
                />
            )}
        </fieldset>
    )
}
