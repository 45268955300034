import ProtectedRoute from '@isdd/metais-common/fileBasedRouting/ProtectedRoute'
import React from 'react'
import { Route, Routes, matchPath } from 'react-router'
import { setDocumentTitle } from '@isdd/metais-common/utils/utils'
import { useLocation } from 'react-router-dom'

import { RouteConfig, RoutesConfig } from './routesConfig'

const IsCurrentPath = (path: string): boolean => {
    const location = useLocation()
    const match = matchPath(
        {
            path: path,
            end: true,
        },
        location.pathname,
    )

    return path.includes('*') ? false : !!match
}

const generateRoutes = (routes: RouteConfig[], subRoute = false, level = 0) =>
    routes.map((route, index) => {
        if (route.subRoutes) {
            return (
                <Route
                    key={subRoute ? `subRoute_${level}_${index}` : `route_${level}_${index}`}
                    path={route.path}
                    element={<ProtectedRoute element={<route.component />} slug={route.slug} />}
                >
                    {generateRoutes(route.subRoutes, true, level + 1)}
                </Route>
            )
        }

        if (route.path && IsCurrentPath(route.path)) {
            setDocumentTitle(route.pageDefaultTitle)
        }

        return (
            <Route
                key={subRoute ? `subRoute_${level}_${index}` : `route_${level}_${index}`}
                path={route.path}
                element={<ProtectedRoute element={<route.component />} slug={route.slug} />}
                index={!!route.index}
            />
        )
    })

export const Router: React.FC = () => <Routes>{generateRoutes(RoutesConfig())}</Routes>
