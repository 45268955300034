import { BaseModal, IBaseModalProps, TextWarning } from '@isdd/idsk-ui-kit'
import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './autoLogout.module.scss'

import { ModalButtons } from '@isdd/metais-common/index'
import { NARROW_MODAL_WIDTH_IN_PX } from '@isdd/metais-common/constants'

export const AfterLogoutModal: React.FC<IBaseModalProps> = ({ isOpen, close, widthInPx, ...rest }) => {
    const { t } = useTranslation()
    return (
        <BaseModal isOpen={isOpen} close={close} widthInPx={widthInPx ?? NARROW_MODAL_WIDTH_IN_PX} {...rest}>
            <div className={styles.messageDiv}>
                <TextWarning>{t('autoLogout.afterLogoutMessage')}</TextWarning>
            </div>
            <ModalButtons onClose={close} closeButtonLabel={t('autoLogout.close')} />
        </BaseModal>
    )
}
