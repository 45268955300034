//@ts-nocheck
/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * MetaIS ImpExp CMDB
 * OpenAPI spec version: latest
 */
import { useQuery, useMutation } from '@tanstack/react-query'
import type { UseQueryOptions, UseMutationOptions, QueryFunction, MutationFunction, UseQueryResult, QueryKey } from '@tanstack/react-query'
import { useImpexpCmdbSwaggerClient } from '../hooks/useImpexpCmdbSwaggerClient'
export type GetProgess200 = ExportResult | ImportResult

export type Export6Type = (typeof Export6Type)[keyof typeof Export6Type]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Export6Type = {
    COMMON: 'COMMON',
    GOALS: 'GOALS',
    KRIS: 'KRIS',
    KS: 'KS',
    ISVS: 'ISVS',
    PROJECT: 'PROJECT',
    ACTIVITY: 'ACTIVITY',
} as const

export type Export6Params = {
    uuid: string
    type: Export6Type
    version: number
}

export type Export4Params = {
    serviceType: string
    project: string
    intervalStart: string
    intervalEnd: string
    services?: string[]
    isvs?: string
}

export type Export2Params = {
    trainingUuid: string
}

export type ValidateContentBody = {
    file: Blob
}

export type ValidateContentParams = {
    ciType: string
    roleId?: string
    poId?: string
}

export type UpdateContentBody = {
    file: Blob
}

export type UpdateContentParams = {
    roleId?: string
    poId?: string
}

export type ResultResultType = (typeof ResultResultType)[keyof typeof ResultResultType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultResultType = {
    IMPORT_RESULT: 'IMPORT_RESULT',
    EXPORT_RESULT: 'EXPORT_RESULT',
} as const

export type ResultProcessingType = (typeof ResultProcessingType)[keyof typeof ResultProcessingType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultProcessingType = {
    IMPORT: 'IMPORT',
    VALIDATE: 'VALIDATE',
    EXPORT_DEFAULT_EXCEL: 'EXPORT_DEFAULT_EXCEL',
    EXPORT_DEFAULT_CSV: 'EXPORT_DEFAULT_CSV',
    EXPORT_DEFAULT_XML: 'EXPORT_DEFAULT_XML',
    EXPORT_DEFAULT_REL_EXCEL: 'EXPORT_DEFAULT_REL_EXCEL',
    EXPORT_DEFAULT_REL_CSV: 'EXPORT_DEFAULT_REL_CSV',
    EXPORT_DEFAULT_REL_XML: 'EXPORT_DEFAULT_REL_XML',
    EXPORT_DEFAULT_RELATIONS_EXCEL: 'EXPORT_DEFAULT_RELATIONS_EXCEL',
    EXPORT_DEFAULT_RELATIONS_CSV: 'EXPORT_DEFAULT_RELATIONS_CSV',
    EXPORT_DEFAULT_RELATIONS_XML: 'EXPORT_DEFAULT_RELATIONS_XML',
    EXPORT_KRIS_EXCEL: 'EXPORT_KRIS_EXCEL',
    EXPORT_KRIS_CSV: 'EXPORT_KRIS_CSV',
    EXPORT_KRIS_XML: 'EXPORT_KRIS_XML',
    EXPORT_MONITORING_EXCEL: 'EXPORT_MONITORING_EXCEL',
    EXPORT_MONITORING_CSV: 'EXPORT_MONITORING_CSV',
    EXPORT_MONITORING_XML: 'EXPORT_MONITORING_XML',
    EXPORT_REPORT_EXCEL: 'EXPORT_REPORT_EXCEL',
    EXPORT_REPORT_CSV: 'EXPORT_REPORT_CSV',
    EXPORT_REPORT_XML: 'EXPORT_REPORT_XML',
    EXPORT_TRAINEE_EXCEL: 'EXPORT_TRAINEE_EXCEL',
    EXPORT_TRAINEE_CSV: 'EXPORT_TRAINEE_CSV',
    EXPORT_TRAINEE_XML: 'EXPORT_TRAINEE_XML',
    EXPORT_MEETING_EXCEL: 'EXPORT_MEETING_EXCEL',
    EXPORT_MEETING_CSV: 'EXPORT_MEETING_CSV',
    EXPORT_MEETING_XML: 'EXPORT_MEETING_XML',
    EXPORT_VOTES_EXCEL: 'EXPORT_VOTES_EXCEL',
    EXPORT_VOTES_CSV: 'EXPORT_VOTES_CSV',
    EXPORT_VOTES_XML: 'EXPORT_VOTES_XML',
    EXPORT_GROUPS_EXCEL: 'EXPORT_GROUPS_EXCEL',
    EXPORT_GROUPS_CSV: 'EXPORT_GROUPS_CSV',
    EXPORT_GROUPS_XML: 'EXPORT_GROUPS_XML',
} as const

export type ResultState = (typeof ResultState)[keyof typeof ResultState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ResultState = {
    STARTED: 'STARTED',
    IN_PROGRESS: 'IN_PROGRESS',
    DONE: 'DONE',
    DONE_WITH_ERRORS: 'DONE_WITH_ERRORS',
} as const

export interface Result {
    id?: string
    state?: ResultState
    fileName?: string
    fileExtension?: string
    processingType?: ResultProcessingType
    errorMessage?: string
    errorClass?: string
    resultType?: ResultResultType
}

export interface ExportGroupsRequest {
    expression?: string
    identity?: string
    organization?: string
    sortBy?: string
    ascending?: boolean
    fieldsToExport: string[]
}

export interface ExportFilter {
    ids?: string[]
}

export interface ExportMeetingRequest {
    sortAttribute?: string
    ascending?: boolean
    state?: string
    createdBy?: string
    onlyMy?: boolean
    fromDate?: string
    toDate?: string
    workGroupId?: string
    fieldsToExport: string[]
}

export type ReportExecuteParameters = { [key: string]: { [key: string]: any } }

export interface ReportExecute {
    page?: number
    perPage?: number
    parameters?: ReportExecuteParameters
}

export interface ExportVotesRequest {
    sortAttribute?: string
    ascending?: boolean
    state?: string
    isSecret?: boolean
    createdBy?: string
    onlyMy?: boolean
    fromDate?: string
    toDate?: string
    fieldsToExport: string[]
    secret?: boolean
}

export type ExportResult = Result

export type UsageTypeFilterUiBlackListItem = (typeof UsageTypeFilterUiBlackListItem)[keyof typeof UsageTypeFilterUiBlackListItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsageTypeFilterUiBlackListItem = {
    system: 'system',
    application: 'application',
} as const

export type UsageTypeFilterUiWhiteListItem = (typeof UsageTypeFilterUiWhiteListItem)[keyof typeof UsageTypeFilterUiWhiteListItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UsageTypeFilterUiWhiteListItem = {
    system: 'system',
    application: 'application',
} as const

export interface UsageTypeFilterUi {
    whiteList?: UsageTypeFilterUiWhiteListItem[]
    blackList?: UsageTypeFilterUiBlackListItem[]
}

export interface RelTypeFilterUi {
    relType?: string
    relCiUuids?: string[]
    onlyValidRel?: boolean
    byHierarchy?: boolean
}

export interface FilterValueUi {
    equality?: string
    value?: string
}

export interface FilterMetaAttributesUi {
    owner?: string[]
    liableEntity?: string[]
    liableEntityByHierarchy?: boolean
    lastAction?: string[]
    state?: string[]
    createdBy?: string[]
    createdAtFrom?: string
    createdAtTo?: string
    lastModifiedBy?: string[]
    lastModifiedAtFrom?: string
    lastModifiedAtTo?: string
}

export interface FilterAttributesUi {
    name?: string
    filterValue?: FilterValueUi[]
}

export interface RelFilterUi {
    type?: string[]
    usageType?: string[]
    uuid?: string[]
    attributes?: FilterAttributesUi[]
    metaAttributes?: FilterMetaAttributesUi
    fullTextSearch?: string
    searchFields?: string[]
    mustExistAttributes?: string[]
    mustNotExistAttributes?: string[]
    poUuid?: string
    startCiTypeName?: string[]
    endCiTypeName?: string[]
    startCiUuid?: string[]
    endCiUuid?: string[]
    startOrEndCiUuid?: string[]
}

export interface RelListFilterContainerUi {
    page?: number
    perpage?: number
    sortBy?: string
    sortType?: string
    sortSource?: string
    sortByEndCi?: boolean
    getIncidentRelations?: boolean
    usageTypeFilter?: UsageTypeFilterUi
    relFilter?: RelFilterUi
}

export interface CiFilterUi {
    type?: string[]
    usageType?: string[]
    uuid?: string[]
    attributes?: FilterAttributesUi[]
    metaAttributes?: FilterMetaAttributesUi
    fullTextSearch?: string
    searchFields?: string[]
    mustExistAttributes?: string[]
    mustNotExistAttributes?: string[]
    relTypeFilters?: RelTypeFilterUi[]
    poUuid?: string
}

export interface CiListFilterContainerUi {
    page?: number
    perpage?: number
    sortBy?: string
    sortType?: string
    sortSource?: string
    sortByEndCi?: boolean
    getIncidentRelations?: boolean
    usageTypeFilter?: UsageTypeFilterUi
    filter?: CiFilterUi
    relFilter?: RelFilterUi
}

export type ImportResult = Result & ImportResultAllOf

export type ErrorMessageUiExtendedErrorType = (typeof ErrorMessageUiExtendedErrorType)[keyof typeof ErrorMessageUiExtendedErrorType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ErrorMessageUiExtendedErrorType = {
    OWNER: 'OWNER',
    OWNER_CREATE_ROLE: 'OWNER_CREATE_ROLE',
    CREATE_CI: 'CREATE_CI',
    UPDATE_CI: 'UPDATE_CI',
    CREATE_REL: 'CREATE_REL',
    UPDATE_REL: 'UPDATE_REL',
    UPDATE_INVALIDATE_CI: 'UPDATE_INVALIDATE_CI',
    CHANGE_ATTRIBUTE: 'CHANGE_ATTRIBUTE',
    LIMIT: 'LIMIT',
    ENUM: 'ENUM',
    EXPRESION: 'EXPRESION',
    ATTRIBUTE: 'ATTRIBUTE',
    MANDATORY: 'MANDATORY',
    REGEX: 'REGEX',
    NUMBER_FORMAT: 'NUMBER_FORMAT',
    MAX_STRING_SIZE: 'MAX_STRING_SIZE',
    INVALID_TYPE: 'INVALID_TYPE',
    GROUP_RIGHT: 'GROUP_RIGHT',
    VALIDATION_ERROR: 'VALIDATION_ERROR',
    PROCESSING_ERROR: 'PROCESSING_ERROR',
} as const

export type ErrorMessageUiExtendedItemType = (typeof ErrorMessageUiExtendedItemType)[keyof typeof ErrorMessageUiExtendedItemType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ErrorMessageUiExtendedItemType = {
    RELATIONSHIP: 'RELATIONSHIP',
    CONFIGURATION_ITEM: 'CONFIGURATION_ITEM',
} as const

export interface ErrorDetailUi {
    minConstrain?: number
    maxConstrain?: number
    enumConstrain?: string
    regex?: string
    notExistAttribute?: string
    notFilledAttribute?: string
    notNumber?: string
    notAllowedExpression?: string
    notAuthorizedGid?: string
    notAuthorizedRole?: string
    itemTechnicalTypeName?: string
    description?: string
    maxStringSize?: number
    invalidType?: string
}

export interface ErrorMessageUiExtended {
    itemType?: ErrorMessageUiExtendedItemType
    itemId?: string
    attributeName?: string
    errorDetail?: ErrorDetailUi
    errorType?: ErrorMessageUiExtendedErrorType
    itemName?: string
    itemCode?: string
}

export interface CMDBItem {
    uuid?: string
    code?: string
}

export type ImportResultAllOf = {
    count?: number
    items?: CMDBItem[]
    errorMessages?: ErrorMessageUiExtended[]
}

export type ApiErrorData = { [key: string]: any }

export interface ApiError {
    type?: string
    message?: string
    data?: ApiErrorData
    logToken?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const useUpdateContentHook = () => {
    const updateContent = useImpexpCmdbSwaggerClient<ImportResult>()

    return (updateContentBody: UpdateContentBody, params?: UpdateContentParams) => {
        const formData = new FormData()
        formData.append('file', updateContentBody.file)

        return updateContent({ url: `/import`, method: 'post', headers: { 'Content-Type': 'multipart/form-data' }, data: formData, params })
    }
}

export const useUpdateContentMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateContentHook>>>,
        TError,
        { data: UpdateContentBody; params?: UpdateContentParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateContentHook>>>,
    TError,
    { data: UpdateContentBody; params?: UpdateContentParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateContent = useUpdateContentHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateContentHook>>>,
        { data: UpdateContentBody; params?: UpdateContentParams }
    > = (props) => {
        const { data, params } = props ?? {}

        return updateContent(data, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateContentMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateContentHook>>>>
export type UpdateContentMutationBody = UpdateContentBody
export type UpdateContentMutationError = ApiError

export const useUpdateContent = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateContentHook>>>,
        TError,
        { data: UpdateContentBody; params?: UpdateContentParams },
        TContext
    >
}) => {
    const mutationOptions = useUpdateContentMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useValidateContentHook = () => {
    const validateContent = useImpexpCmdbSwaggerClient<ImportResult>()

    return (validateContentBody: ValidateContentBody, params: ValidateContentParams) => {
        const formData = new FormData()
        formData.append('file', validateContentBody.file)

        return validateContent({
            url: `/import/validate`,
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
            params,
        })
    }
}

export const useValidateContentMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useValidateContentHook>>>,
        TError,
        { data: ValidateContentBody; params: ValidateContentParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useValidateContentHook>>>,
    TError,
    { data: ValidateContentBody; params: ValidateContentParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const validateContent = useValidateContentHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useValidateContentHook>>>,
        { data: ValidateContentBody; params: ValidateContentParams }
    > = (props) => {
        const { data, params } = props ?? {}

        return validateContent(data, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type ValidateContentMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useValidateContentHook>>>>
export type ValidateContentMutationBody = ValidateContentBody
export type ValidateContentMutationError = ApiError

export const useValidateContent = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useValidateContentHook>>>,
        TError,
        { data: ValidateContentBody; params: ValidateContentParams },
        TContext
    >
}) => {
    const mutationOptions = useValidateContentMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteOldExportFilesHook = () => {
    const deleteOldExportFiles = useImpexpCmdbSwaggerClient<string[]>()

    return () => {
        return deleteOldExportFiles({ url: `/file/deleteOldExportFiles`, method: 'post' })
    }
}

export const useDeleteOldExportFilesMutationOptions = <TError = ApiError, TVariables = void, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteOldExportFilesHook>>>, TError, TVariables, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteOldExportFilesHook>>>, TError, TVariables, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteOldExportFiles = useDeleteOldExportFilesHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteOldExportFilesHook>>>, TVariables> = () => {
        return deleteOldExportFiles()
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteOldExportFilesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteOldExportFilesHook>>>>

export type DeleteOldExportFilesMutationError = ApiError

export const useDeleteOldExportFiles = <TError = ApiError, TVariables = void, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteOldExportFilesHook>>>, TError, TVariables, TContext>
}) => {
    const mutationOptions = useDeleteOldExportFilesMutationOptions(options)

    return useMutation(mutationOptions)
}

export const use_ExportHook = () => {
    const _export = useImpexpCmdbSwaggerClient<ExportResult>()

    return (fileType: 'EXCEL' | 'CSV' | 'XML', ciListFilterContainerUi: CiListFilterContainerUi) => {
        return _export({ url: `/export/${fileType}`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: ciListFilterContainerUi })
    }
}

export const useExportMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof use_ExportHook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: CiListFilterContainerUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof use_ExportHook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; data: CiListFilterContainerUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const _export = use_ExportHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof use_ExportHook>>>,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: CiListFilterContainerUi }
    > = (props) => {
        const { fileType, data } = props ?? {}

        return _export(fileType, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type _ExportMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof use_ExportHook>>>>
export type _ExportMutationBody = CiListFilterContainerUi
export type _ExportMutationError = ApiError

export const useExport = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof use_ExportHook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: CiListFilterContainerUi },
        TContext
    >
}) => {
    const mutationOptions = useExportMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExport1Hook = () => {
    const export1 = useImpexpCmdbSwaggerClient<ExportResult>()

    return (fileType: 'EXCEL' | 'CSV' | 'XML', exportVotesRequest: ExportVotesRequest) => {
        return export1({
            url: `/export/votes/${fileType}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: exportVotesRequest,
        })
    }
}

export const useExport1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport1Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportVotesRequest },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExport1Hook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportVotesRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const export1 = useExport1Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExport1Hook>>>,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportVotesRequest }
    > = (props) => {
        const { fileType, data } = props ?? {}

        return export1(fileType, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type Export1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExport1Hook>>>>
export type Export1MutationBody = ExportVotesRequest
export type Export1MutationError = ApiError

export const useExport1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport1Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportVotesRequest },
        TContext
    >
}) => {
    const mutationOptions = useExport1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExport2Hook = () => {
    const export2 = useImpexpCmdbSwaggerClient<ExportResult>()

    return (fileType: 'EXCEL' | 'CSV' | 'XML', params: Export2Params) => {
        return export2({ url: `/export/trainee/${fileType}`, method: 'post', params })
    }
}

export const useExport2MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport2Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; params: Export2Params },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExport2Hook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; params: Export2Params },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const export2 = useExport2Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExport2Hook>>>,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; params: Export2Params }
    > = (props) => {
        const { fileType, params } = props ?? {}

        return export2(fileType, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type Export2MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExport2Hook>>>>

export type Export2MutationError = ApiError

export const useExport2 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport2Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; params: Export2Params },
        TContext
    >
}) => {
    const mutationOptions = useExport2MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExport3Hook = () => {
    const export3 = useImpexpCmdbSwaggerClient<ExportResult>()

    return (fileType: 'EXCEL' | 'CSV' | 'XML', reportId: number, reportExecute: ReportExecute) => {
        return export3({
            url: `/export/report/${fileType}/${reportId}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: reportExecute,
        })
    }
}

export const useExport3MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport3Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; reportId: number; data: ReportExecute },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExport3Hook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; reportId: number; data: ReportExecute },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const export3 = useExport3Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExport3Hook>>>,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; reportId: number; data: ReportExecute }
    > = (props) => {
        const { fileType, reportId, data } = props ?? {}

        return export3(fileType, reportId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type Export3MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExport3Hook>>>>
export type Export3MutationBody = ReportExecute
export type Export3MutationError = ApiError

export const useExport3 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport3Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; reportId: number; data: ReportExecute },
        TContext
    >
}) => {
    const mutationOptions = useExport3MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExportByLookupKeyHook = () => {
    const exportByLookupKey = useImpexpCmdbSwaggerClient<ExportResult>()

    return (fileType: 'EXCEL' | 'CSV' | 'XML', lookupKey: string, reportExecute: ReportExecute) => {
        return exportByLookupKey({
            url: `/export/report/${fileType}/lookup/${lookupKey}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: reportExecute,
        })
    }
}

export const useExportByLookupKeyMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportByLookupKeyHook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; lookupKey: string; data: ReportExecute },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportByLookupKeyHook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; lookupKey: string; data: ReportExecute },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const exportByLookupKey = useExportByLookupKeyHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExportByLookupKeyHook>>>,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; lookupKey: string; data: ReportExecute }
    > = (props) => {
        const { fileType, lookupKey, data } = props ?? {}

        return exportByLookupKey(fileType, lookupKey, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ExportByLookupKeyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExportByLookupKeyHook>>>>
export type ExportByLookupKeyMutationBody = ReportExecute
export type ExportByLookupKeyMutationError = ApiError

export const useExportByLookupKey = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportByLookupKeyHook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; lookupKey: string; data: ReportExecute },
        TContext
    >
}) => {
    const mutationOptions = useExportByLookupKeyMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExportRelationsHook = () => {
    const exportRelations = useImpexpCmdbSwaggerClient<ExportResult>()

    return (fileType: 'EXCEL' | 'CSV' | 'XML', relListFilterContainerUi: RelListFilterContainerUi) => {
        return exportRelations({
            url: `/export/relations-export/${fileType}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: relListFilterContainerUi,
        })
    }
}

export const useExportRelationsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportRelationsHook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: RelListFilterContainerUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportRelationsHook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; data: RelListFilterContainerUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const exportRelations = useExportRelationsHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExportRelationsHook>>>,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: RelListFilterContainerUi }
    > = (props) => {
        const { fileType, data } = props ?? {}

        return exportRelations(fileType, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ExportRelationsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExportRelationsHook>>>>
export type ExportRelationsMutationBody = RelListFilterContainerUi
export type ExportRelationsMutationError = ApiError

export const useExportRelations = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportRelationsHook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: RelListFilterContainerUi },
        TContext
    >
}) => {
    const mutationOptions = useExportRelationsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExportRelHook = () => {
    const exportRel = useImpexpCmdbSwaggerClient<ExportResult>()

    return (fileType: 'EXCEL' | 'CSV' | 'XML', ciListFilterContainerUi: CiListFilterContainerUi) => {
        return exportRel({
            url: `/export/rel/${fileType}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: ciListFilterContainerUi,
        })
    }
}

export const useExportRelMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportRelHook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: CiListFilterContainerUi },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportRelHook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; data: CiListFilterContainerUi },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const exportRel = useExportRelHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExportRelHook>>>,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: CiListFilterContainerUi }
    > = (props) => {
        const { fileType, data } = props ?? {}

        return exportRel(fileType, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ExportRelMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExportRelHook>>>>
export type ExportRelMutationBody = CiListFilterContainerUi
export type ExportRelMutationError = ApiError

export const useExportRel = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportRelHook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: CiListFilterContainerUi },
        TContext
    >
}) => {
    const mutationOptions = useExportRelMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExport4Hook = () => {
    const export4 = useImpexpCmdbSwaggerClient<ExportResult>()

    return (fileType: 'EXCEL' | 'CSV' | 'XML', params: Export4Params) => {
        return export4({ url: `/export/monitoring/${fileType}`, method: 'post', params })
    }
}

export const useExport4MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport4Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; params: Export4Params },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExport4Hook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; params: Export4Params },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const export4 = useExport4Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExport4Hook>>>,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; params: Export4Params }
    > = (props) => {
        const { fileType, params } = props ?? {}

        return export4(fileType, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type Export4MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExport4Hook>>>>

export type Export4MutationError = ApiError

export const useExport4 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport4Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; params: Export4Params },
        TContext
    >
}) => {
    const mutationOptions = useExport4MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExport5Hook = () => {
    const export5 = useImpexpCmdbSwaggerClient<ExportResult>()

    return (fileType: 'EXCEL' | 'CSV' | 'XML', exportMeetingRequest: ExportMeetingRequest) => {
        return export5({
            url: `/export/meeting/${fileType}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: exportMeetingRequest,
        })
    }
}

export const useExport5MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport5Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportMeetingRequest },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExport5Hook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportMeetingRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const export5 = useExport5Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExport5Hook>>>,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportMeetingRequest }
    > = (props) => {
        const { fileType, data } = props ?? {}

        return export5(fileType, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type Export5MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExport5Hook>>>>
export type Export5MutationBody = ExportMeetingRequest
export type Export5MutationError = ApiError

export const useExport5 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport5Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportMeetingRequest },
        TContext
    >
}) => {
    const mutationOptions = useExport5MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExport6Hook = () => {
    const export6 = useImpexpCmdbSwaggerClient<ExportResult>()

    return (fileType: 'EXCEL' | 'CSV' | 'XML', exportFilter: ExportFilter, params: Export6Params) => {
        return export6({
            url: `/export/kris-to-be/${fileType}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: exportFilter,
            params,
        })
    }
}

export const useExport6MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport6Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportFilter; params: Export6Params },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExport6Hook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportFilter; params: Export6Params },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const export6 = useExport6Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExport6Hook>>>,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportFilter; params: Export6Params }
    > = (props) => {
        const { fileType, data, params } = props ?? {}

        return export6(fileType, data, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type Export6MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExport6Hook>>>>
export type Export6MutationBody = ExportFilter
export type Export6MutationError = ApiError

export const useExport6 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport6Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportFilter; params: Export6Params },
        TContext
    >
}) => {
    const mutationOptions = useExport6MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExport7Hook = () => {
    const export7 = useImpexpCmdbSwaggerClient<ExportResult>()

    return (fileType: 'EXCEL' | 'CSV' | 'XML', exportGroupsRequest: ExportGroupsRequest) => {
        return export7({
            url: `/export/groups/${fileType}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: exportGroupsRequest,
        })
    }
}

export const useExport7MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport7Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportGroupsRequest },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExport7Hook>>>,
    TError,
    { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportGroupsRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const export7 = useExport7Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExport7Hook>>>,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportGroupsRequest }
    > = (props) => {
        const { fileType, data } = props ?? {}

        return export7(fileType, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type Export7MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExport7Hook>>>>
export type Export7MutationBody = ExportGroupsRequest
export type Export7MutationError = ApiError

export const useExport7 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExport7Hook>>>,
        TError,
        { fileType: 'EXCEL' | 'CSV' | 'XML'; data: ExportGroupsRequest },
        TContext
    >
}) => {
    const mutationOptions = useExport7MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetProgessHook = () => {
    const getProgess = useImpexpCmdbSwaggerClient<GetProgess200>()

    return (taskType: 'IMPORT' | 'EXPORT', requestId: string, signal?: AbortSignal) => {
        return getProgess({ url: `/progress/${taskType}/${requestId}`, method: 'get', signal })
    }
}

export const getGetProgessQueryKey = (taskType: 'IMPORT' | 'EXPORT', requestId: string) => [`/progress/${taskType}/${requestId}`] as const

export const useGetProgessQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetProgessHook>>>, TError = ApiError>(
    taskType: 'IMPORT' | 'EXPORT',
    requestId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProgessHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProgessHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetProgessQueryKey(taskType, requestId)

    const getProgess = useGetProgessHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProgessHook>>>> = ({ signal }) => getProgess(taskType, requestId, signal)

    return { queryKey, queryFn, enabled: !!(taskType && requestId), ...queryOptions }
}

export type GetProgessQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetProgessHook>>>>
export type GetProgessQueryError = ApiError

export const useGetProgess = <TData = Awaited<ReturnType<ReturnType<typeof useGetProgessHook>>>, TError = ApiError>(
    taskType: 'IMPORT' | 'EXPORT',
    requestId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProgessHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetProgessQueryOptions(taskType, requestId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useLoadFileHook = () => {
    const loadFile = useImpexpCmdbSwaggerClient<Blob>()

    return (fileName: string, signal?: AbortSignal) => {
        return loadFile({ url: `/file/load/${fileName}`, method: 'get', responseType: 'blob', signal })
    }
}

export const getLoadFileQueryKey = (fileName: string) => [`/file/load/${fileName}`] as const

export const useLoadFileQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useLoadFileHook>>>, TError = ApiError>(
    fileName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useLoadFileHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useLoadFileHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getLoadFileQueryKey(fileName)

    const loadFile = useLoadFileHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useLoadFileHook>>>> = ({ signal }) => loadFile(fileName, signal)

    return { queryKey, queryFn, enabled: !!fileName, ...queryOptions }
}

export type LoadFileQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useLoadFileHook>>>>
export type LoadFileQueryError = ApiError

export const useLoadFile = <TData = Awaited<ReturnType<ReturnType<typeof useLoadFileHook>>>, TError = ApiError>(
    fileName: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useLoadFileHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useLoadFileQueryOptions(fileName, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}
