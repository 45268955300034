import { focusContent, TextHeading, useTabbing } from '@isdd/idsk-ui-kit/index'
import { NotificationBlackIcon, NotificationIcon } from '@isdd/metais-common/assets/images'
import { IconWithNotification } from '@isdd/metais-common/components/navbar/navbar-main/IconWithNotification'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { useGetNotificationsWithRefresh } from '@isdd/metais-common/hooks/useGetNotificationsWithRefresh'
import { useWindowWidthBreakpoints } from '@isdd/metais-common/hooks/window-size/useWindowWidthBreakpoints'
import { NavigationSubRoutes } from '@isdd/metais-common/navigation/routeNames'
import React, { useId, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { Popup } from 'reactjs-popup'
import { PopupActions } from 'reactjs-popup/dist/types'
import { BASE_PAGE_NUMBER, BASE_PAGE_SIZE } from '@isdd/metais-common/api'

import styles from './NotificationsPopup.module.scss'

export const NotificationsPopup: React.FC = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const contentRef = useRef(null)
    const triggerId = useId()
    const [isExpanded, setIsExpanded] = useState<boolean>(false)
    const [isFocused, setIsFocused] = useState<boolean>(false)
    const windowWidthBreakpoints = useWindowWidthBreakpoints()
    const {
        state: { user },
    } = useAuth()
    const isUserLogged = !!user

    const { data: notificationsData } = useGetNotificationsWithRefresh({
        filter: { perPage: BASE_PAGE_SIZE, pageNumber: BASE_PAGE_NUMBER, onlyUnread: true },
        enabled: isUserLogged,
    })

    const youHaveCountTitle = t('notifications.youHave', {
        count: notificationsData?.pagination?.totalUnreadedItems ?? 0,
    })

    const focusTrigger = () => {
        document.getElementById(triggerId)?.focus()
    }

    // popup library was automatically focusing content, desired behavior is:
    // - trigger opens popup, focus is kept on trigger
    // - pressing TAB while popup is opened focuses content and locks TAB inside of content
    // if it does not behave like this, check if patch is applied (Windows problem)
    // -> patches/reactjs-popup+2.0.5.patch
    const handleTriggerKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
        if (event.code === 'Tab' && isExpanded) {
            event.preventDefault()
            focusContent(contentRef)
            setIsFocused(true)
        }
    }

    const popupRef = useRef<PopupActions>(null)
    const popupTrigger = (
        <IconWithNotification
            id={triggerId}
            iconActive={NotificationBlackIcon}
            iconInactive={NotificationIcon}
            count={notificationsData?.pagination?.totalUnreadedItems ?? 0}
            path=""
            showAsLink={false}
            aria={{
                'aria-label': `${t('navbar.notifications')}: ${t('notifications.youHave', {
                    count: notificationsData?.pagination?.totalUnreadedItems ?? 0,
                })}`,
            }}
            className={styles.notificationsPopupBtn}
            onKeyDown={handleTriggerKeyDown}
        />
    )

    useTabbing(contentRef, isFocused)

    const mobileIconWithNotification = (
        <IconWithNotification
            id={triggerId}
            iconActive={NotificationBlackIcon}
            iconInactive={NotificationIcon}
            count={notificationsData?.pagination?.totalUnreadedItems ?? 0}
            path={NavigationSubRoutes.NOTIFICATIONS}
            showAsLink
            aria={{
                'aria-label': `${t('navbar.notifications')}: ${t('notifications.youHave', {
                    count: notificationsData?.pagination?.totalUnreadedItems ?? 0,
                })}`,
            }}
        />
    )

    const buttonWithPopup = (
        <Popup
            trigger={popupTrigger}
            arrow={false}
            ref={popupRef}
            position="bottom right"
            onOpen={() => {
                setIsExpanded(true)
            }}
            keepTooltipInside
            onClose={() => {
                setIsExpanded(false)
                focusTrigger()
            }}
        >
            <div className={styles.notificationsPopupContent} ref={contentRef}>
                <TextHeading size="S">{youHaveCountTitle}</TextHeading>
                <ul className={styles.notificationsList}>
                    {notificationsData?.notifications?.map((notification) => {
                        return (
                            <li key={notification.id}>
                                <Link
                                    to={`${NavigationSubRoutes.NOTIFICATIONS}/${notification.id}`}
                                    state={{ from: location }}
                                    onClick={() => popupRef.current?.close()}
                                >
                                    {notification.messagePerex}
                                </Link>
                            </li>
                        )
                    })}
                </ul>
                <Link to={`${NavigationSubRoutes.NOTIFICATIONS}`} state={{ from: location }} onClick={() => popupRef.current?.close()}>
                    {t('notifications.showAll')}
                </Link>
            </div>
        </Popup>
    )

    return windowWidthBreakpoints?.desktop ? buttonWithPopup : mobileIconWithNotification
}
