import { Button, ButtonLink, ButtonPopup, Tab, Tabs, TextHeading } from '@isdd/idsk-ui-kit/index'
import { ConfigurationItemUi, getReadCiNeighboursWithAllRelsQueryKey } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { ApiError, useIsOwnerByGid } from '@isdd/metais-common/api/generated/iam-swagger'
import { CiType } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { CheckInACircleIcon, CrossInACircleIcon } from '@isdd/metais-common/assets/images'
import stylesPopup from '@isdd/metais-common/components/actions-over-table/actionsOverTable.module.scss'
import { ElementToScrollTo } from '@isdd/metais-common/components/element-to-scroll-to/ElementToScrollTo'
import { FlexColumnReverseWrapper } from '@isdd/metais-common/components/flex-column-reverse-wrapper/FlexColumnReverseWrapper'
import { INVALIDATED, OLA_CONTRACT_STATES, OLA_CONTRACT_STATE_ACTIONS, ROLES, ciInformationTab } from '@isdd/metais-common/constants'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { useAbilityContextWithFeedback } from '@isdd/metais-common/hooks/permissions/useAbilityContext'
import { ATTRIBUTE_NAME, MutationFeedback, QueryFeedback } from '@isdd/metais-common/index'
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQueryClient } from '@tanstack/react-query'
import classNames from 'classnames'
import { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useOlaContractTransition } from '@isdd/metais-common/api/generated/monitoring-swagger'
import { RouterRoutes } from '@isdd/metais-common/navigation/routeNames'
import { useGetStatus } from '@isdd/metais-common/hooks/useGetRequestStatus'

import { canEditOlaContract } from '@/components/views/ola-contract-list/helper'
import { OlaContractInvalidateModal } from '@/components/views/ola-contract-list/OlaContractInvalidateModal'
import { OlaContractRevalidateModal } from '@/components/views/ola-contract-list/OlaContractRevalidateModal'
import styles from '@/components/views/ci/ola/olaContract.module.scss'
import { RelationsListContainer } from '@/components/containers/RelationsListContainer'

type Props = {
    isLoading: boolean
    isError: boolean
    entityName: string
    entityId: string
    ciItemData: ConfigurationItemUi | undefined
    ciTypeData: CiType | undefined
    tabList: Tab[]
    ciItemDataRefetch: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
    ) => Promise<QueryObserverResult<ConfigurationItemUi, ApiError>>
}

export const OlaDetailView: FC<Props> = ({ isLoading, isError, entityName, entityId, ciItemData, ciTypeData, tabList, ciItemDataRefetch }) => {
    const { t } = useTranslation()
    const { isError: isAbilityError, isLoading: isAbilityLoading } = useAbilityContextWithFeedback()
    const navigate = useNavigate()
    const [selectedTab, setSelectedTab] = useState<string>()
    const isInvalid = ciItemData?.metaAttributes?.state === INVALIDATED
    const [invalidateShow, setInvalidateShow] = useState(false)
    const [revalidateShow, setRevalidateShow] = useState(false)
    const { getRequestStatus, isError: isGetStatusError, isTooManyFetchesError, isLoading: isGettingStatus } = useGetStatus()
    const { isActionSuccess, setIsActionSuccess } = useActionSuccess()
    const queryClient = useQueryClient()
    const key = getReadCiNeighboursWithAllRelsQueryKey(entityId ?? '')
    const {
        state: { user, token },
    } = useAuth()
    const isLoggedIn = !!user?.uuid
    const canMoveState = user?.roles.includes(ROLES.R_EGOV) ?? false
    const canChange = canEditOlaContract(user, ciTypeData)
    const focusTriggerRef = useRef<() => void>()

    const {
        mutateAsync: moveState,
        isLoading: isStateMoving,
        isError: isStateMovingError,
    } = useOlaContractTransition({
        mutation: {
            onSuccess: async (resp) => {
                await getRequestStatus(resp.requestId ?? '', () => {
                    ciItemDataRefetch()
                    setIsActionSuccess({
                        value: true,
                        path: RouterRoutes.OLA_CONTRACT_LIST + '/' + entityId,
                        additionalInfo: { type: 'stateChanged' },
                    })
                })
            },
        },
    })

    const {
        data: isOwnerByGid,
        isFetching: isOwnerByGidLoading,
        isError: isOwnerByGidError,
    } = useIsOwnerByGid(
        {
            gids: [ciItemData?.metaAttributes?.owner ?? ''],
            login: user?.login,
        },
        { query: { enabled: !isLoading && token !== null && isLoggedIn } },
    )

    const isOwnerOfContract = isOwnerByGid?.isOwner?.[0]?.owner

    const getDefaultActions = () => [
        <ButtonLink
            disabled={isInvalid}
            key={'buttonBlock'}
            icon={CrossInACircleIcon}
            label={t('ciType.invalidateItem')}
            className={stylesPopup.buttonLinkWithIcon}
            onClick={() => {
                setInvalidateShow(true)
            }}
            aria={{ 'aria-haspopup': 'dialog' }}
        />,
        <ButtonLink
            disabled={!isInvalid}
            key={'buttonUnblock'}
            icon={CheckInACircleIcon}
            label={t('ciType.revalidateItem')}
            className={stylesPopup.buttonLinkWithIcon}
            onClick={() => {
                setRevalidateShow(true)
            }}
            aria={{ 'aria-haspopup': 'dialog' }}
        />,
    ]

    const getStateActions = () => {
        if (!canMoveState || isInvalid) return []
        const currentState = ciItemData?.attributes?.Profil_OLA_Kontrakt_status ?? OLA_CONTRACT_STATES.PLANNED
        switch (currentState) {
            case OLA_CONTRACT_STATES.PLANNED:
                return [
                    <ButtonLink
                        key={'APPROVE'}
                        label={t('olaContracts.stateActions.approve')}
                        className={stylesPopup.buttonLinkWithIcon}
                        onClick={() => {
                            moveState({ olaContractUuid: ciItemData?.uuid ?? '', transition: OLA_CONTRACT_STATE_ACTIONS.APPROVE })
                        }}
                    />,
                    <ButtonLink
                        key={'RETURN'}
                        label={t('olaContracts.stateActions.return')}
                        className={stylesPopup.buttonLinkWithIcon}
                        onClick={() => {
                            moveState({ olaContractUuid: ciItemData?.uuid ?? '', transition: OLA_CONTRACT_STATE_ACTIONS.RETURN })
                        }}
                    />,
                ]
            case OLA_CONTRACT_STATES.RETURNED:
                return [
                    <ButtonLink
                        key={'PLAN'}
                        label={t('olaContracts.stateActions.plan')}
                        className={stylesPopup.buttonLinkWithIcon}
                        onClick={() => {
                            moveState({ olaContractUuid: ciItemData?.uuid ?? '', transition: OLA_CONTRACT_STATE_ACTIONS.PLAN })
                        }}
                    />,
                ]
            case OLA_CONTRACT_STATES.APPROVED:
                return [
                    <ButtonLink
                        key={'CONTRACT'}
                        label={t('olaContracts.stateActions.contract')}
                        className={stylesPopup.buttonLinkWithIcon}
                        onClick={() => {
                            moveState({ olaContractUuid: ciItemData?.uuid ?? '', transition: OLA_CONTRACT_STATE_ACTIONS.CONTRACT })
                        }}
                    />,
                ]

            default:
                return []
        }
    }

    return (
        <>
            <QueryFeedback loading={isLoading || !!isAbilityLoading || isGettingStatus || isStateMoving || isOwnerByGidLoading}>
                <FlexColumnReverseWrapper>
                    <div className={styles.headerDiv}>
                        <TextHeading size="L" className={classNames({ [styles.invalidated]: isInvalid })}>
                            {t('olaContracts.detail.title', { name: ciItemData?.attributes?.Gen_Profil_nazov })}
                        </TextHeading>
                        {user && canChange && isOwnerOfContract && (
                            <div className={styles.flexRow}>
                                <Button
                                    label={t('ciType.editButton')}
                                    disabled={isInvalid}
                                    onClick={() => navigate('./edit', { relative: 'path' })}
                                />
                                <div>
                                    <div className={classNames(stylesPopup.mobileOrder3, stylesPopup.buttonPopup)} id="bulkActions">
                                        <ButtonPopup
                                            buttonLabel={t('olaContracts.detail.moreButton')}
                                            popupHorizontalPosition="right"
                                            manuallyHandledFocusAfterClose
                                            onClose={() => {
                                                if (![invalidateShow, revalidateShow].includes(true)) {
                                                    focusTriggerRef.current?.()
                                                }
                                            }}
                                            onOpen={(focusTriggerEl) => {
                                                focusTriggerRef.current = focusTriggerEl
                                            }}
                                            popupContent={() => (
                                                <div className={stylesPopup.popupActions} id="bulkActionsList" role="list">
                                                    {[...getDefaultActions(), ...getStateActions()]}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <QueryFeedback
                        loading={false}
                        error={isError || isAbilityError || isGetStatusError || isTooManyFetchesError || isStateMovingError || isOwnerByGidError}
                    />
                    <ElementToScrollTo trigger={isActionSuccess.value}>
                        <MutationFeedback
                            success={isActionSuccess.value && isActionSuccess?.additionalInfo?.type == 'stateChanged'}
                            error={undefined}
                            successMessage={t('olaContracts.detail.stateChanged')}
                        />

                        <MutationFeedback
                            success={
                                isActionSuccess.value &&
                                (isActionSuccess.additionalInfo?.type == 'create' || isActionSuccess.additionalInfo?.type == 'edit')
                            }
                            successMessage={
                                isActionSuccess.additionalInfo?.type === 'create'
                                    ? t('mutationFeedback.successfulCreated')
                                    : t('mutationFeedback.successfulUpdated')
                            }
                            error={!isActionSuccess.value && isActionSuccess.additionalInfo?.type === 'createRelationError'}
                            errorMessage={t('ciType.messages.errorRelationsCreation')}
                        />
                    </ElementToScrollTo>
                </FlexColumnReverseWrapper>

                <Tabs tabList={tabList} onSelect={(selected) => setSelectedTab(selected.id)} />

                {selectedTab === ciInformationTab && (
                    <RelationsListContainer
                        entityId={entityId ?? ''}
                        technicalName={entityName ?? ''}
                        showOnlyTabsWithRelations
                        hideButtons
                        entityName={ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov]}
                    />
                )}
            </QueryFeedback>
            <OlaContractInvalidateModal
                olaContract={{ name: ciItemData?.attributes?.Gen_Profil_nazov, uuid: entityId, code: ciItemData?.attributes?.Gen_Profil_kod_metais }}
                open={invalidateShow}
                close={() => setInvalidateShow(false)}
                onInvalidated={async () => {
                    setInvalidateShow(false)
                    await queryClient.invalidateQueries(key)
                    ciItemDataRefetch().then(() => {
                        setIsActionSuccess({
                            value: true,
                            path: RouterRoutes.OLA_CONTRACT_LIST + '/' + entityId,
                            additionalInfo: { type: 'edit' },
                        })
                    })
                }}
            />
            <OlaContractRevalidateModal
                olaContract={{ name: ciItemData?.attributes?.Gen_Profil_nazov, uuid: entityId, code: ciItemData?.attributes?.Gen_Profil_kod_metais }}
                open={revalidateShow}
                close={() => setRevalidateShow(false)}
                onRevalidated={async () => {
                    setRevalidateShow(false)
                    await queryClient.invalidateQueries(key)
                    ciItemDataRefetch().then(() => {
                        setIsActionSuccess({
                            value: true,
                            path: RouterRoutes.OLA_CONTRACT_LIST + '/' + entityId,
                            additionalInfo: { type: 'edit' },
                        })
                    })
                }}
            />
        </>
    )
}
