import { yupResolver } from '@hookform/resolvers/yup'
import { Button, ButtonGroupRow, IOption, Input, SimpleSelect, TextArea } from '@isdd/idsk-ui-kit/index'
import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { ConfigurationItemUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { Attribute } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { CiLazySelect } from '@isdd/metais-common/components/ci-lazy-select/CiLazySelect'
import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { getDescriptionByAttribute, getNameByAttribute, getRequiredByAttribute } from '@/components/views/codeLists/CodeListDetailUtils'
import { REF_PORTAL_SUBMIT_ID } from '@/components/views/ref-identifiers/RefIdentifierCreateView'
import {
    RefCatalogFormTypeEnum,
    RefOntologyFormType,
    RefOntologyFormTypeEnum,
    refIdentifierCreateOntologySchema,
} from '@/components/views/ref-identifiers/forms/refCreateSchema'

interface RefOntologyFormPropsType {
    onSubmit: (data: RefOntologyFormType, send: boolean) => void
    onCancel: () => void
    clearUriExist: () => void
    isUpdate?: boolean
    isUriExist: boolean
    isDisabled?: boolean
    ciItemData?: ConfigurationItemUi
    attributes: Attribute[] | undefined
    ownerOptions: IOption<string>[]
    defaultPo?: string
}

export const RefOntologyForm: React.FC<RefOntologyFormPropsType> = ({
    onSubmit,
    onCancel,
    clearUriExist,
    isUriExist,
    isUpdate,
    isDisabled,
    ciItemData,
    attributes,
    ownerOptions,
    defaultPo,
}) => {
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const attributeList = [
        ATTRIBUTE_NAME.Gen_Profil_nazov,
        ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov,
        ATTRIBUTE_NAME.Gen_Profil_popis,
        ATTRIBUTE_NAME.Profil_Ontologia_uri_pre_ontologiu,
        ATTRIBUTE_NAME.Profil_Ontologia_historicky_kod,
    ]

    const attributesDefaultValues = Object.fromEntries(attributeList.map((item) => [item, ciItemData?.attributes?.[item]]))

    const methods = useForm<RefOntologyFormType>({
        defaultValues: {
            [RefOntologyFormTypeEnum.OWNER]: ownerOptions?.at(0)?.value,
            [RefOntologyFormTypeEnum.PO]: defaultPo,
            attributes: attributesDefaultValues,
        },
        mode: 'onChange',
        resolver: yupResolver(refIdentifierCreateOntologySchema(t, language, attributesDefaultValues, attributes)),
    })

    const { register, formState, handleSubmit, setError, setValue, clearErrors, trigger } = methods
    const { errors } = formState

    const buttonRefId = document.getElementById(REF_PORTAL_SUBMIT_ID)

    useEffect(() => {
        if (isUriExist) {
            setError(`attributes.${ATTRIBUTE_NAME.Profil_Ontologia_uri_pre_ontologiu}`, { message: t('refIdentifiers.create.uriAlreadyExist') })
        }
    }, [isUriExist, setError, t])

    useEffect(() => {
        formState.isSubmitted && trigger()
    }, [formState.isSubmitted, t, trigger])

    useEffect(() => {
        if (defaultPo) setValue(RefOntologyFormTypeEnum.PO, defaultPo)
    }, [defaultPo, setValue])

    return (
        <form noValidate>
            {!isUpdate && (
                <SimpleSelect
                    label={t('refIdentifiers.create.ownerUser')}
                    options={ownerOptions ?? []}
                    setValue={setValue}
                    name={RefCatalogFormTypeEnum.OWNER}
                    isClearable={false}
                    clearErrors={clearErrors}
                    defaultValue={ownerOptions?.at(0)?.value}
                    error={errors?.[RefCatalogFormTypeEnum.OWNER]?.message}
                />
            )}

            <Input
                required={getRequiredByAttribute(
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Ontologia_uri_pre_ontologiu),
                )}
                disabled={isDisabled}
                label={getNameByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Ontologia_uri_pre_ontologiu),
                )}
                info={getDescriptionByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Ontologia_uri_pre_ontologiu),
                )}
                {...register(`attributes.${ATTRIBUTE_NAME.Profil_Ontologia_uri_pre_ontologiu}`)}
                onBlur={clearUriExist}
                error={errors?.attributes?.[ATTRIBUTE_NAME.Profil_Ontologia_uri_pre_ontologiu]?.message}
            />
            <Input
                required={getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_nazov))}
                disabled={isDisabled}
                label={getNameByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_nazov),
                )}
                info={getDescriptionByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_nazov),
                )}
                {...register(`attributes.${ATTRIBUTE_NAME.Gen_Profil_nazov}`)}
                error={errors?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov]?.message}
            />

            <Input
                required
                disabled={isDisabled}
                label={getNameByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov),
                )}
                info={getDescriptionByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov),
                )}
                {...register(`attributes.${ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov}`)}
                error={errors?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov]?.message}
            />

            <Input
                required={getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Ontologia_historicky_kod))}
                disabled={isDisabled}
                label={getNameByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Ontologia_historicky_kod),
                )}
                info={getDescriptionByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Ontologia_historicky_kod),
                )}
                {...register(`attributes.${ATTRIBUTE_NAME.Profil_Ontologia_historicky_kod}`)}
                error={errors?.attributes?.[ATTRIBUTE_NAME.Profil_Ontologia_historicky_kod]?.message}
            />

            <TextArea
                rows={3}
                required={getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_popis))}
                label={getNameByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_popis),
                )}
                info={getDescriptionByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_popis),
                )}
                {...register(`attributes.${ATTRIBUTE_NAME.Gen_Profil_popis}`)}
                error={errors?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_popis]?.message}
            />
            <CiLazySelect
                ciType="PO"
                label={t('refIdentifiers.detail.gestor')}
                setValue={setValue}
                clearErrors={clearErrors}
                name={RefOntologyFormTypeEnum.PO}
                defaultValue={defaultPo}
                metaAttributes={{
                    state: ['DRAFT'],
                }}
                required
                error={errors?.[RefOntologyFormTypeEnum.PO]?.message}
            />

            {buttonRefId &&
                createPortal(
                    <ButtonGroupRow>
                        <Button onClick={handleSubmit((data) => onSubmit(data, true))} label={t('refIdentifiers.create.finishRequest')} />
                        <Button onClick={handleSubmit((data) => onSubmit(data, false))} label={t('refIdentifiers.create.saveRequest')} />
                        <Button variant="secondary" onClick={onCancel} label={t('refIdentifiers.create.cancelRequest')} />
                    </ButtonGroupRow>,
                    buttonRefId,
                )}
        </form>
    )
}
