import { TextHeading, Button, TransparentButtonWrapper } from '@isdd/idsk-ui-kit/index'
import React, { useId } from 'react'
import { TooltipRenderProps } from 'react-joyride'
import { useTranslation } from 'react-i18next'

import styles from './wizardTooltip.module.scss'

import { CloseIcon } from '@isdd/metais-common/assets/images'

export const WizardTooltip: React.FC<TooltipRenderProps> = ({
    backProps,
    index,
    isLastStep,
    primaryProps,
    step,
    tooltipProps,
    size,
    closeProps,
}: TooltipRenderProps) => {
    const { t } = useTranslation()
    const headerId = useId()
    const contentId = useId()

    return (
        <div {...tooltipProps} className={styles.tooltipWrapper} aria-labelledby={headerId} aria-describedby={contentId}>
            <div className={styles.tooltipHeader} id={headerId}>
                <TextHeading size="L">
                    <span className="govuk-visually-hidden">{t('wizard.ariaDescription')}</span>
                    {index + 1}/{size} {step.title}
                </TextHeading>
                <TransparentButtonWrapper
                    {...{ ...closeProps, title: undefined }}
                    onClick={closeProps.onClick as () => void}
                    aria-label={t('wizard.ariaCloseTutorial')}
                >
                    <img src={CloseIcon} alt="" className={styles.cursorPointer} />
                </TransparentButtonWrapper>
            </div>
            <div id={contentId}>{step.content}</div>
            <Button
                disabled={index === 0}
                {...backProps}
                className={styles.left15Margin}
                label={backProps.title}
                aria-label={t('wizard.ariaBack')}
                variant="secondary"
                tabIndex={1}
                title={undefined}
            />
            {!isLastStep ? (
                <Button disabled={isLastStep} {...primaryProps} label={primaryProps.title} title={undefined} />
            ) : (
                <Button {...closeProps} label={closeProps.title} aria-label={t('wizard.ariaFinishTutorial')} title={undefined} />
            )}
        </div>
    )
}
