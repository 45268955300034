import { useEffect, useState } from 'react'

import {
    ErrorMessageUiExtended,
    ExportResult,
    GetProgess200,
    ImportResult,
    useGetProgessHook,
} from '@isdd/metais-common/api/generated/impexp-cmdb-swagger'
import { API_CALL_RETRY_COUNT } from '@isdd/metais-common/constants'

export const useGetProgress = (taskType: 'IMPORT' | 'EXPORT', awaitForStatus?: string, abortGetProgress?: boolean) => {
    const requestStatus = useGetProgessHook()
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [errorMessages, setErrorMessages] = useState<string>()

    useEffect(() => {
        if (abortGetProgress) {
            setIsLoading(false)
        }
    }, [abortGetProgress])

    const [isError, setIsError] = useState(false)
    const [isProcessedError, setIsProcessedError] = useState(false)
    const [isTooManyFetchesError, setIsTooManyFetchesError] = useState(false)
    const delays = [1000, 3000, 5000, 10000, 20000, 40000, 60000]
    const callStatusInCycles = async (requestId: string) => {
        let errors: string | ErrorMessageUiExtended[] | undefined
        let result: GetProgess200 | undefined
        for (let index = 0; index < API_CALL_RETRY_COUNT; index++) {
            result = await requestStatus(taskType, requestId)
            if (result.state == 'DONE' || (awaitForStatus && result.state == awaitForStatus)) {
                errors = undefined
                break
            } else if (result.state === 'DONE_WITH_ERRORS' || (result.errorMessage?.length ?? 0) > 0) {
                switch (taskType) {
                    case 'IMPORT':
                        errors =
                            ((result as ImportResult).errorMessages?.length ?? 0) > 0
                                ? (result as ImportResult).errorMessages
                                : (result as ImportResult).errorMessage
                        break

                    case 'EXPORT':
                        errors = (result as ExportResult).errorMessage

                        break
                }
                setErrorMessages(result.errorMessage)
                setIsProcessedError(true)
                setIsLoading(false)
                break
            }

            if (index < API_CALL_RETRY_COUNT - 1 && abortGetProgress !== true) {
                const delay = index > 6 ? delays[6] : delays[index]
                await new Promise((resolve) => setTimeout(resolve, delay))
            }
        }
        return { result, errors }
    }

    const resetErrorMessages = () => {
        setErrorMessages(undefined)
    }

    const getRequestStatus = async (
        requestId: string,
        onSuccess?: (result?: GetProgess200) => void,
        onError?: (error?: string | ErrorMessageUiExtended[]) => void,
    ) => {
        setIsSuccess(false)
        setIsError(false)
        setIsLoading(true)
        setIsTooManyFetchesError(false)
        setIsProcessedError(false)

        try {
            const { result, errors } = await callStatusInCycles(requestId)
            if (errors == undefined) {
                setIsSuccess(true)
                setIsLoading(false)
                onSuccess?.(result)
                return
            } else {
                setIsTooManyFetchesError(true)
                setIsLoading(false)
                onError?.(errors)
            }
        } catch (error) {
            onError?.()
            setIsError(true)
            setIsLoading(false)
            return
        }
    }

    const resetSuccess = () => {
        setIsSuccess(false)
    }

    const resetError = () => {
        setIsError(false)
        setIsProcessedError(false)
        setIsTooManyFetchesError(false)
    }

    return {
        getRequestStatus,
        callStatusInCycles,
        isError,
        isLoading,
        isSuccess,
        isProcessedError,
        isTooManyFetchesError,
        resetSuccess,
        resetError,
        errorMessages,
        resetErrorMessages,
    }
}
