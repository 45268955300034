import React from 'react'

import { KSHelperView } from '@/components/views/ks-helper/KSHelperView'
import { MainContentWrapper } from '@/components/MainContentWrapper'

export const KSHelperContainer: React.FC = () => {
    return (
        <MainContentWrapper>
            <KSHelperView />
        </MainContentWrapper>
    )
}
