import classnames from 'classnames'
import React, { useId } from 'react'
import { useTranslation } from 'react-i18next'
import { Attribute } from '@isdd/metais-common/api/generated/types-repo-swagger'

import styles from './stepper.module.scss'

import { AlertTriangleIcon, InfoIcon } from '@isdd/idsk-ui-kit/assets/images'
import { TextHeading } from '@isdd/idsk-ui-kit/typography/TextHeading'
import { ErrorBlock } from '@isdd/idsk-ui-kit/error-block-list/ErrorBlockList'
import { AriaSectionErrorList } from '@isdd/idsk-ui-kit/aria-section-error-list/AriaSectionErrorList'

export interface IStepLabel {
    label: string
    variant: 'circle' | 'no-outline'
}

export interface ISection {
    id: string
    title: string
    sectionWarning?: React.ReactNode
    last?: boolean
    isTitle?: boolean
    stepLabel?: IStepLabel
    content?: React.ReactNode
    error?: boolean
    errorMessages?: ErrorBlock[]
    change?: boolean
    isOpen?: boolean
    hide?: boolean
    attributes?: Attribute[]
}

interface IStepperSection {
    section: ISection
    index: number
    textHeadingSize?: 'S' | 'M' | 'L' | 'XL'
    handleSectionOpen?: (id: string) => void
}

export const StepperSection: React.FC<IStepperSection> = ({ section, textHeadingSize, handleSectionOpen }) => {
    const { t } = useTranslation()
    const uniqueId = `expand-section${useId()}`
    const errorId = `error-${useId()}`
    const headingId = `subsection-${useId()}`

    return (
        <>
            {!section.hide && (
                <div
                    className={classnames({
                        'idsk-stepper__section': true,
                        'idsk-stepper__section--last-item': section.last,
                        'idsk-stepper__section--expanded': section.isOpen,
                    })}
                >
                    <div className="idsk-stepper__section-header">
                        {section.stepLabel && (
                            <span
                                className={classnames(styles.circleZIndex, {
                                    'idsk-stepper__circle': true,
                                    'idsk-stepper__circle--letter': section.stepLabel.variant === 'no-outline',
                                    'idsk-stepper__circle--number': section.stepLabel.variant === 'circle',
                                })}
                            >
                                <span className="idsk-stepper__circle-inner">
                                    <span className="idsk-stepper__circle-background">
                                        <span className="idsk-stepper__circle-step-label">{section.stepLabel.label}</span>
                                    </span>
                                </span>
                            </span>
                        )}
                        <div className={styles.headerDiv}>
                            <TextHeading
                                id={headingId}
                                className={classnames('idsk-stepper__section-heading', styles.heading)}
                                size={textHeadingSize ?? 'L'}
                            >
                                {handleSectionOpen ? (
                                    <>
                                        <button
                                            type="button"
                                            aria-controls={uniqueId}
                                            className={classnames('idsk-stepper__section-button', styles.smallMarginBottom)}
                                            aria-expanded={section.isOpen}
                                            onClick={() => section.id && handleSectionOpen(section.id)}
                                            aria-describedby={errorId}
                                        >
                                            {section.title}
                                            <span
                                                className={classnames('idsk-stepper__icon', { 'idsk-stepper__icon_plus': !section.isOpen })}
                                                aria-hidden
                                            />
                                        </button>
                                    </>
                                ) : (
                                    <>{section.title}</>
                                )}
                            </TextHeading>

                            <div className={styles.icons}>
                                <span id={errorId}>{section.error && <AriaSectionErrorList section={section} />}</span>
                                {section.error && <img src={AlertTriangleIcon} alt={t('stepper.sectionError')} />}
                                {section.change && <img src={InfoIcon} alt={t('stepper.sectionChange')} />}
                            </div>
                        </div>
                        <div>{section.sectionWarning}</div>
                    </div>
                    <div id={uniqueId} className="idsk-stepper__section-content" aria-labelledby={headingId}>
                        {section.content}
                    </div>
                </div>
            )}
        </>
    )
}
