import React from 'react'
import { useTranslation } from 'react-i18next'
import { InformationGridRow } from '@isdd/metais-common/components/info-grid-row/InformationGridRow'
import { QueryFeedback } from '@isdd/metais-common/index'

import { ApiModuleInformationType } from '@/hooks/useApiModuleInformation.hook'

interface ApiModuleInformationProps {
    data?: ApiModuleInformationType
    moduleName: string
    isLoading: boolean
}

const ApiModuleInformation: React.FC<ApiModuleInformationProps> = ({ data: apiModule, isLoading, moduleName }) => {
    const { t } = useTranslation()

    return (
        <QueryFeedback loading={isLoading} withChildren>
            <InformationGridRow
                label={t('aboutApp.version', { apiModul: moduleName })}
                value={
                    apiModule?.chart?.version && apiModule?.app?.version
                        ? `${apiModule.app.version} (${apiModule.chart.version})`
                        : t('aboutApp.undefinedVersion')
                }
                hideIcon
            />
        </QueryFeedback>
    )
}

export default ApiModuleInformation
