//@ts-nocheck
/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * MetaIS Standards
 * OpenAPI spec version: latest
 */
import { useQuery, useMutation } from '@tanstack/react-query'
import type { UseQueryOptions, UseMutationOptions, QueryFunction, MutationFunction, UseQueryResult, QueryKey } from '@tanstack/react-query'
import { useStandardsSwaggerClient } from '../hooks/useStandardsSwaggerClient'
export type AcceptMeetingRequestParams = {
    token: string
}

export type RejectMeetingRequestParams = {
    token: string
}

export type CancelMeetingRequest200 = { [key: string]: any }

export type ParticipateMeetingRequest200 = { [key: string]: any }

export type ParticipateMeetingRequestParams = {
    participation: string
}

export type GetMeetingRequestsParams = {
    pageNumber: number
    perPage: number
    sortBy?: string
    ascending?: boolean
    state?: string
    createdBy?: string
    onlyMy?: boolean
    fromDate?: string
    toDate?: string
    workGroupId?: string
}

export type Reindex2200 = { [key: string]: any }

export type Reindex1200 = { [key: string]: any }

export type Reindex200 = { [key: string]: any }

export type CreateStandardRequestUpload200 = { [key: string]: any }

export type CreateStandardRequestUploadBody = {
    file?: Blob
}

export type CreateStandardRequestUploadParams = {
    request: string
}

export type GetFOPStandardRequestsRequestChannel = (typeof GetFOPStandardRequestsRequestChannel)[keyof typeof GetFOPStandardRequestsRequestChannel]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetFOPStandardRequestsRequestChannel = {
    WEB: 'WEB',
    RI: 'RI',
    RR: 'RR',
    ZC_Request: 'ZC_Request',
    ZC_Header: 'ZC_Header',
} as const

export type GetFOPStandardRequestsParams = {
    pageNumber: number
    perPage: number
    sortBy?: string
    ascending?: boolean
    state?: string
    createdBy?: string
    name?: string
    workGroupId?: string
    requestChannel?: GetFOPStandardRequestsRequestChannel
    fromDate?: string
    toDate?: string
    draftName?: string
}

export type UpdateVoteRole1200 = { [key: string]: any }

export type CancelVote200 = { [key: string]: any }

export type SummarizeVote200 = { [key: string]: any }

export type CastVote1200 = { [key: string]: any }

export type VetoVote1200 = { [key: string]: any }

export type VoteNote200 = { [key: string]: any }

export type GetVotesParams = {
    pageNumber: number
    perPage: number
    sortBy?: string
    ascending?: boolean
    isSecret?: boolean
    state?: string
    createdBy?: string
    fromDate?: string
    toDate?: string
    onlyMy?: boolean
}

export type SummarizeMeetingRequest200 = { [key: string]: any }

export type UpdateSummarizeData200 = { [key: string]: any }

export type AddUserToActiveVotes200 = { [key: string]: any }

export type AddUserToActiveVotesBody = { [key: string]: string }

export type ActionStandardRequest200 = { [key: string]: any }

export type ActionStandardRequestParams = {
    action: string
}

export type AssignStandardRequest200 = { [key: string]: any }

export type UpdateActorsVote200 = { [key: string]: any }

export interface ApiMeetingRequestPreview {
    id?: number
    name?: string
    beginDate?: string
    endDate?: string
    place?: string
    description?: string
    createdAt?: string
    createdBy?: string
    state?: string
    summarizedAt?: string
    summarizedBy?: string
    summarizedLink?: string
    groups?: string[]
    actionDesription?: string
    lastModifiedAt?: string
}

export interface ApiMeetingRequestPreviewList {
    meetingRequestsCount?: number
    meetingRequests?: ApiMeetingRequestPreview[]
}

export type ApiStandardRequestPreviewRequestChannel =
    (typeof ApiStandardRequestPreviewRequestChannel)[keyof typeof ApiStandardRequestPreviewRequestChannel]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApiStandardRequestPreviewRequestChannel = {
    WEB: 'WEB',
    RI: 'RI',
    RR: 'RR',
    ZC_Request: 'ZC_Request',
    ZC_Header: 'ZC_Header',
} as const

export interface ApiStandardRequestPreview {
    email?: string
    fullName?: string
    name?: string
    workGroupId?: string
    createdAt?: string
    createdBy?: string
    standardRequestState?: string
    requestChannel?: ApiStandardRequestPreviewRequestChannel
    actionDescription?: string
    id?: number
}

export interface ApiStandardRequestPreviewList {
    standardRequestsCount?: number
    standardRequests?: ApiStandardRequestPreview[]
}

export interface ApiVoteChoiceResult {
    id?: number
    value?: string
    description?: string
    votedActorsCount?: number
}

export interface ApiVoteActorResult {
    userId?: string
    groupId?: string
    substitutorIds?: string[]
    userRoleId?: string
    userOrgId?: string
    userName?: string
    userRoleName?: string
    userRoleDesc?: string
    userOrgName?: string
    groupName?: string
    groupShortName?: string
    token?: string
    sendNotification?: boolean
    voteDescription?: string
    veto?: boolean
    votedChoiceId?: number
    votedChoiceValue?: string
    votedAt?: string
    votedBy?: string
}

export interface ApiVoteUserActorResults {
    loggedUserActorResult?: ApiVoteActorResult
    substitutedByLoggedUserActorResults?: ApiVoteActorResult[]
}

export interface ApiVoteResult {
    invitedActorsCount?: number
    votedActorsCount?: number
    choiceResults?: ApiVoteChoiceResult[]
    actorResults?: ApiVoteActorResult[]
    voteActorPendingChanges?: ApiVoteActorPendingChange[]
    vetoedBy?: string
    vetoedDescription?: string
}

export interface ApiVotePreview {
    id?: number
    name?: string
    description?: string
    createdAt?: string
    effectiveFrom?: string
    effectiveTo?: string
    voteState?: string
    secret?: boolean
    veto?: boolean
    createdBy?: string
    actionDesription?: string
    standardRequestId?: number
    canCast?: boolean
    hasCast?: string
    groups?: ApiGroup[]
}

export interface ApiVotePreviewList {
    votesCount?: number
    votes?: ApiVotePreview[]
}

export interface Group {
    uuid?: string
    shortName?: string
    name?: string
    description?: string
}

export interface GroupMeetings {
    group?: Group
    lastMeetingDate?: string
    nextMeetingDate?: string
}

export type FillRefAttributesTaskStatus = (typeof FillRefAttributesTaskStatus)[keyof typeof FillRefAttributesTaskStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FillRefAttributesTaskStatus = {
    CREATED: 'CREATED',
    IN_PROGRESS_PREPARE_DATA: 'IN_PROGRESS_PREPARE_DATA',
    IN_PROGRESS_UPDATE_METADATA_IN_DMS: 'IN_PROGRESS_UPDATE_METADATA_IN_DMS',
    FINISHED: 'FINISHED',
    CANCELLED: 'CANCELLED',
} as const

export type DocumentInfoState = (typeof DocumentInfoState)[keyof typeof DocumentInfoState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DocumentInfoState = {
    ERROR: 'ERROR',
    INCONSISTENT: 'INCONSISTENT',
    NOT_FOUND: 'NOT_FOUND',
    SOFT_DELETED: 'SOFT_DELETED',
    STATE_MISSING: 'STATE_MISSING',
    UPDATED: 'UPDATED',
} as const

export interface DocumentInfo {
    id?: string
    state?: DocumentInfoState
    errorMessage?: string
}

export interface FillRefAttributesTask {
    id?: string
    totalCount?: number
    startedAt?: string
    endedAt?: string
    status?: FillRefAttributesTaskStatus
    successUuids?: string[]
    failedUuids?: DocumentInfo[]
    taskBriefInfo?: string
    failedCountError?: number
    successCount?: number
    failedCount?: number
    failedCountInconsistent?: number
    failedCountNotFound?: number
    failedCountSoftDeleted?: number
    failedCountStateMissing?: number
}

export interface UpdateVoteRequest {
    userIdLogin?: string
    newRoleUuid?: string
    newRoleName?: string
    newRoleDesc?: string
}

export interface ApiVoteNote {
    note?: string
}

export interface ApiMeetingResult {
    linkUrl?: string
}

export interface ApiMeetingExternalActor {
    id?: number
    name?: string
    email?: string
    description?: string
    participation?: string
}

export interface ApiMeetingActor {
    userId?: string
    groupId?: string
    userRoleId?: string
    userOrgId?: string
    userName?: string
    userRoleName?: string
    userRoleDesc?: string
    userOrgName?: string
    groupName?: string
    groupShortName?: string
    participation?: string
}

export interface ApiMeetingRequest {
    id?: number
    name?: string
    beginDate?: string
    endDate?: string
    place?: string
    description?: string
    createdAt?: string
    createdBy?: string
    state?: string
    summarizedAt?: string
    summarizedBy?: string
    summarizedLink?: string
    groups?: string[]
    actionDesription?: string
    lastModifiedAt?: string
    meetingActors?: ApiMeetingActor[]
    addedActors?: ApiMeetingActor[]
    removedActors?: ApiMeetingActor[]
    meetingExternalActors?: ApiMeetingExternalActor[]
    addedExternalActors?: ApiMeetingExternalActor[]
    removedExternalActors?: ApiMeetingExternalActor[]
    meetingAttachments?: ApiAttachment[]
    meetingLinks?: ApiLink[]
    standardRequestIds?: number[]
    standardRequestsNames?: string[]
    groupNames?: string[]
    descriptionOfChange?: string
    notifNewUsers?: boolean
    ignorePersonalSettings?: boolean
}

export interface ApiDescription {
    description?: string
}

export type ApiStandardRequestRequestChannel = (typeof ApiStandardRequestRequestChannel)[keyof typeof ApiStandardRequestRequestChannel]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApiStandardRequestRequestChannel = {
    WEB: 'WEB',
    RI: 'RI',
    RR: 'RR',
    ZC_Request: 'ZC_Request',
    ZC_Header: 'ZC_Header',
} as const

export interface ApiAttribute {
    attributeName?: string
    attributeValue?: string
}

export interface ApiVoteChoice {
    id?: number
    value?: string
    description?: string
}

export interface ApiVoteActorPendingChange {
    userId?: string
    groupId?: string
    userRoleId?: string
    userOrgId?: string
    changeAction?: string
    changeActionDescription?: string
    changeDate?: string
    userName?: string
    userRoleName?: string
    userRoleDesc?: string
    userOrgName?: string
    groupName?: string
    groupShortName?: string
}

export interface ApiVoteActor {
    userId?: string
    groupId?: string
    substitutorIds?: string[]
    userRoleId?: string
    userOrgId?: string
    userName?: string
    userRoleName?: string
    userRoleDesc?: string
    userOrgName?: string
    groupName?: string
    groupShortName?: string
    token?: string
    sendNotification?: boolean
}

export interface ApiLink {
    id?: number
    url?: string
    name?: string
    type?: string
    linkType?: string
    linkSize?: string
    linkDescription?: string
}

export interface ApiGroup {
    id?: string
    shortName?: string
    name?: string
}

export interface ApiAttachment {
    id?: number
    attachmentId?: string
    attachmentName?: string
    attachmentDescription?: string
}

export interface ApiStandardRequest {
    email?: string
    fullName?: string
    name?: string
    workGroupId?: string
    createdAt?: string
    createdBy?: string
    standardRequestState?: string
    requestChannel?: ApiStandardRequestRequestChannel
    actionDescription?: string
    description?: string
    placementProposal?: string
    legislativeTextProposal?: string
    financialImpact?: string
    securityImpact?: string
    privacyImpact?: string
    attachments?: ApiAttachment[]
    links?: ApiLink[]
    requestChannelAttributes?: ApiAttribute[]
    id?: number
}

export interface ApiVote {
    id?: number
    name?: string
    description?: string
    createdAt?: string
    effectiveFrom?: string
    effectiveTo?: string
    voteState?: string
    secret?: boolean
    veto?: boolean
    createdBy?: string
    actionDesription?: string
    standardRequestId?: number
    canCast?: boolean
    hasCast?: string
    groups?: ApiGroup[]
    voteActors?: ApiVoteActor[]
    voteActorPendingChanges?: ApiVoteActorPendingChange[]
    voteChoices?: ApiVoteChoice[]
    attachments?: ApiAttachment[]
    links?: ApiLink[]
    descriptionOfChange?: string
}

export type ApiErrorData = { [key: string]: any }

export interface ApiError {
    type?: string
    message?: string
    data?: ApiErrorData
    logToken?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const useGetVoteDetailHook = () => {
    const getVoteDetail = useStandardsSwaggerClient<ApiVote>()

    return (voteId: number, signal?: AbortSignal) => {
        return getVoteDetail({ url: `/standards/votes/${voteId}`, method: 'get', signal })
    }
}

export const getGetVoteDetailQueryKey = (voteId: number) => [`/standards/votes/${voteId}`] as const

export const useGetVoteDetailQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetVoteDetailHook>>>, TError = ApiError>(
    voteId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVoteDetailHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVoteDetailHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetVoteDetailQueryKey(voteId)

    const getVoteDetail = useGetVoteDetailHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetVoteDetailHook>>>> = ({ signal }) => getVoteDetail(voteId, signal)

    return { queryKey, queryFn, enabled: !!voteId, ...queryOptions }
}

export type GetVoteDetailQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetVoteDetailHook>>>>
export type GetVoteDetailQueryError = ApiError

export const useGetVoteDetail = <TData = Awaited<ReturnType<ReturnType<typeof useGetVoteDetailHook>>>, TError = ApiError>(
    voteId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVoteDetailHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetVoteDetailQueryOptions(voteId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUpdateVoteHook = () => {
    const updateVote = useStandardsSwaggerClient<ApiVote>()

    return (voteId: number, apiVote: ApiVote) => {
        return updateVote({ url: `/standards/votes/${voteId}`, method: 'put', headers: { 'Content-Type': 'application/json' }, data: apiVote })
    }
}

export const useUpdateVoteMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateVoteHook>>>, TError, { voteId: number; data: ApiVote }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateVoteHook>>>, TError, { voteId: number; data: ApiVote }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateVote = useUpdateVoteHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateVoteHook>>>, { voteId: number; data: ApiVote }> = (props) => {
        const { voteId, data } = props ?? {}

        return updateVote(voteId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateVoteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateVoteHook>>>>
export type UpdateVoteMutationBody = ApiVote
export type UpdateVoteMutationError = ApiError

export const useUpdateVote = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateVoteHook>>>, TError, { voteId: number; data: ApiVote }, TContext>
}) => {
    const mutationOptions = useUpdateVoteMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateActorsVoteHook = () => {
    const updateActorsVote = useStandardsSwaggerClient<UpdateActorsVote200>()

    return (voteId: number, apiVote: ApiVote) => {
        return updateActorsVote({
            url: `/standards/votes/${voteId}/actors`,
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: apiVote,
        })
    }
}

export const useUpdateActorsVoteMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateActorsVoteHook>>>,
        TError,
        { voteId: number; data: ApiVote },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateActorsVoteHook>>>, TError, { voteId: number; data: ApiVote }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateActorsVote = useUpdateActorsVoteHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateActorsVoteHook>>>, { voteId: number; data: ApiVote }> = (
        props,
    ) => {
        const { voteId, data } = props ?? {}

        return updateActorsVote(voteId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateActorsVoteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateActorsVoteHook>>>>
export type UpdateActorsVoteMutationBody = ApiVote
export type UpdateActorsVoteMutationError = ApiError

export const useUpdateActorsVote = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateActorsVoteHook>>>,
        TError,
        { voteId: number; data: ApiVote },
        TContext
    >
}) => {
    const mutationOptions = useUpdateActorsVoteMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetStandardRequestDetailHook = () => {
    const getStandardRequestDetail = useStandardsSwaggerClient<ApiStandardRequest>()

    return (standardRequestId: number, signal?: AbortSignal) => {
        return getStandardRequestDetail({ url: `/standards/requests/${standardRequestId}`, method: 'get', signal })
    }
}

export const getGetStandardRequestDetailQueryKey = (standardRequestId: number) => [`/standards/requests/${standardRequestId}`] as const

export const useGetStandardRequestDetailQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetStandardRequestDetailHook>>>,
    TError = ApiError,
>(
    standardRequestId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetStandardRequestDetailHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetStandardRequestDetailHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetStandardRequestDetailQueryKey(standardRequestId)

    const getStandardRequestDetail = useGetStandardRequestDetailHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetStandardRequestDetailHook>>>> = ({ signal }) =>
        getStandardRequestDetail(standardRequestId, signal)

    return { queryKey, queryFn, enabled: !!standardRequestId, ...queryOptions }
}

export type GetStandardRequestDetailQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetStandardRequestDetailHook>>>>
export type GetStandardRequestDetailQueryError = ApiError

export const useGetStandardRequestDetail = <TData = Awaited<ReturnType<ReturnType<typeof useGetStandardRequestDetailHook>>>, TError = ApiError>(
    standardRequestId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetStandardRequestDetailHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetStandardRequestDetailQueryOptions(standardRequestId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUpdateStandardRequestHook = () => {
    const updateStandardRequest = useStandardsSwaggerClient<ApiStandardRequest>()

    return (standardRequestId: number, apiStandardRequest: ApiStandardRequest) => {
        return updateStandardRequest({
            url: `/standards/requests/${standardRequestId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: apiStandardRequest,
        })
    }
}

export const useUpdateStandardRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateStandardRequestHook>>>,
        TError,
        { standardRequestId: number; data: ApiStandardRequest },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateStandardRequestHook>>>,
    TError,
    { standardRequestId: number; data: ApiStandardRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateStandardRequest = useUpdateStandardRequestHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateStandardRequestHook>>>,
        { standardRequestId: number; data: ApiStandardRequest }
    > = (props) => {
        const { standardRequestId, data } = props ?? {}

        return updateStandardRequest(standardRequestId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateStandardRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateStandardRequestHook>>>>
export type UpdateStandardRequestMutationBody = ApiStandardRequest
export type UpdateStandardRequestMutationError = ApiError

export const useUpdateStandardRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateStandardRequestHook>>>,
        TError,
        { standardRequestId: number; data: ApiStandardRequest },
        TContext
    >
}) => {
    const mutationOptions = useUpdateStandardRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useAssignStandardRequestHook = () => {
    const assignStandardRequest = useStandardsSwaggerClient<AssignStandardRequest200>()

    return (standardRequestId: number, groupId: string) => {
        return assignStandardRequest({ url: `/standards/requests/${standardRequestId}/assign/${groupId}`, method: 'put' })
    }
}

export const useAssignStandardRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAssignStandardRequestHook>>>,
        TError,
        { standardRequestId: number; groupId: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAssignStandardRequestHook>>>,
    TError,
    { standardRequestId: number; groupId: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const assignStandardRequest = useAssignStandardRequestHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useAssignStandardRequestHook>>>,
        { standardRequestId: number; groupId: string }
    > = (props) => {
        const { standardRequestId, groupId } = props ?? {}

        return assignStandardRequest(standardRequestId, groupId)
    }

    return { mutationFn, ...mutationOptions }
}

export type AssignStandardRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAssignStandardRequestHook>>>>

export type AssignStandardRequestMutationError = ApiError

export const useAssignStandardRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAssignStandardRequestHook>>>,
        TError,
        { standardRequestId: number; groupId: string },
        TContext
    >
}) => {
    const mutationOptions = useAssignStandardRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useActionStandardRequestHook = () => {
    const actionStandardRequest = useStandardsSwaggerClient<ActionStandardRequest200>()

    return (standardRequestId: number, apiDescription: ApiDescription, params: ActionStandardRequestParams) => {
        return actionStandardRequest({
            url: `/standards/requests/${standardRequestId}/action`,
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: apiDescription,
            params,
        })
    }
}

export const useActionStandardRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useActionStandardRequestHook>>>,
        TError,
        { standardRequestId: number; data: ApiDescription; params: ActionStandardRequestParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useActionStandardRequestHook>>>,
    TError,
    { standardRequestId: number; data: ApiDescription; params: ActionStandardRequestParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const actionStandardRequest = useActionStandardRequestHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useActionStandardRequestHook>>>,
        { standardRequestId: number; data: ApiDescription; params: ActionStandardRequestParams }
    > = (props) => {
        const { standardRequestId, data, params } = props ?? {}

        return actionStandardRequest(standardRequestId, data, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type ActionStandardRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useActionStandardRequestHook>>>>
export type ActionStandardRequestMutationBody = ApiDescription
export type ActionStandardRequestMutationError = ApiError

export const useActionStandardRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useActionStandardRequestHook>>>,
        TError,
        { standardRequestId: number; data: ApiDescription; params: ActionStandardRequestParams },
        TContext
    >
}) => {
    const mutationOptions = useActionStandardRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useAddUserToActiveVotesHook = () => {
    const addUserToActiveVotes = useStandardsSwaggerClient<AddUserToActiveVotes200>()

    return (groupShortName: string, userId: string, addUserToActiveVotesBody: AddUserToActiveVotesBody) => {
        return addUserToActiveVotes({
            url: `/standards/addToActiveVotes/${groupShortName}/${userId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: addUserToActiveVotesBody,
        })
    }
}

export const useAddUserToActiveVotesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddUserToActiveVotesHook>>>,
        TError,
        { groupShortName: string; userId: string; data: AddUserToActiveVotesBody },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddUserToActiveVotesHook>>>,
    TError,
    { groupShortName: string; userId: string; data: AddUserToActiveVotesBody },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const addUserToActiveVotes = useAddUserToActiveVotesHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useAddUserToActiveVotesHook>>>,
        { groupShortName: string; userId: string; data: AddUserToActiveVotesBody }
    > = (props) => {
        const { groupShortName, userId, data } = props ?? {}

        return addUserToActiveVotes(groupShortName, userId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type AddUserToActiveVotesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddUserToActiveVotesHook>>>>
export type AddUserToActiveVotesMutationBody = AddUserToActiveVotesBody
export type AddUserToActiveVotesMutationError = ApiError

export const useAddUserToActiveVotes = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddUserToActiveVotesHook>>>,
        TError,
        { groupShortName: string; userId: string; data: AddUserToActiveVotesBody },
        TContext
    >
}) => {
    const mutationOptions = useAddUserToActiveVotesMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetMeetingRequestDetailHook = () => {
    const getMeetingRequestDetail = useStandardsSwaggerClient<ApiMeetingRequest>()

    return (meetingRequestId: number, signal?: AbortSignal) => {
        return getMeetingRequestDetail({ url: `/meetings/${meetingRequestId}`, method: 'get', signal })
    }
}

export const getGetMeetingRequestDetailQueryKey = (meetingRequestId: number) => [`/meetings/${meetingRequestId}`] as const

export const useGetMeetingRequestDetailQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestDetailHook>>>,
    TError = ApiError,
>(
    meetingRequestId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestDetailHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestDetailHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetMeetingRequestDetailQueryKey(meetingRequestId)

    const getMeetingRequestDetail = useGetMeetingRequestDetailHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestDetailHook>>>> = ({ signal }) =>
        getMeetingRequestDetail(meetingRequestId, signal)

    return { queryKey, queryFn, enabled: !!meetingRequestId, ...queryOptions }
}

export type GetMeetingRequestDetailQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestDetailHook>>>>
export type GetMeetingRequestDetailQueryError = ApiError

export const useGetMeetingRequestDetail = <TData = Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestDetailHook>>>, TError = ApiError>(
    meetingRequestId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestDetailHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetMeetingRequestDetailQueryOptions(meetingRequestId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUpdateMeetingRequestHook = () => {
    const updateMeetingRequest = useStandardsSwaggerClient<ApiMeetingRequest>()

    return (meetingRequestId: number, apiMeetingRequest: ApiMeetingRequest) => {
        return updateMeetingRequest({
            url: `/meetings/${meetingRequestId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: apiMeetingRequest,
        })
    }
}

export const useUpdateMeetingRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateMeetingRequestHook>>>,
        TError,
        { meetingRequestId: number; data: ApiMeetingRequest },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateMeetingRequestHook>>>,
    TError,
    { meetingRequestId: number; data: ApiMeetingRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateMeetingRequest = useUpdateMeetingRequestHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateMeetingRequestHook>>>,
        { meetingRequestId: number; data: ApiMeetingRequest }
    > = (props) => {
        const { meetingRequestId, data } = props ?? {}

        return updateMeetingRequest(meetingRequestId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateMeetingRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateMeetingRequestHook>>>>
export type UpdateMeetingRequestMutationBody = ApiMeetingRequest
export type UpdateMeetingRequestMutationError = ApiError

export const useUpdateMeetingRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateMeetingRequestHook>>>,
        TError,
        { meetingRequestId: number; data: ApiMeetingRequest },
        TContext
    >
}) => {
    const mutationOptions = useUpdateMeetingRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateSummarizeDataHook = () => {
    const updateSummarizeData = useStandardsSwaggerClient<UpdateSummarizeData200>()

    return (meetingRequestId: number, apiMeetingResult: ApiMeetingResult) => {
        return updateSummarizeData({
            url: `/meetings/${meetingRequestId}/summarize`,
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: apiMeetingResult,
        })
    }
}

export const useUpdateSummarizeDataMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateSummarizeDataHook>>>,
        TError,
        { meetingRequestId: number; data: ApiMeetingResult },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateSummarizeDataHook>>>,
    TError,
    { meetingRequestId: number; data: ApiMeetingResult },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateSummarizeData = useUpdateSummarizeDataHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateSummarizeDataHook>>>,
        { meetingRequestId: number; data: ApiMeetingResult }
    > = (props) => {
        const { meetingRequestId, data } = props ?? {}

        return updateSummarizeData(meetingRequestId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateSummarizeDataMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateSummarizeDataHook>>>>
export type UpdateSummarizeDataMutationBody = ApiMeetingResult
export type UpdateSummarizeDataMutationError = ApiError

export const useUpdateSummarizeData = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateSummarizeDataHook>>>,
        TError,
        { meetingRequestId: number; data: ApiMeetingResult },
        TContext
    >
}) => {
    const mutationOptions = useUpdateSummarizeDataMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useSummarizeMeetingRequestHook = () => {
    const summarizeMeetingRequest = useStandardsSwaggerClient<SummarizeMeetingRequest200>()

    return (meetingRequestId: number, apiMeetingResult: ApiMeetingResult) => {
        return summarizeMeetingRequest({
            url: `/meetings/${meetingRequestId}/summarize`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: apiMeetingResult,
        })
    }
}

export const useSummarizeMeetingRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSummarizeMeetingRequestHook>>>,
        TError,
        { meetingRequestId: number; data: ApiMeetingResult },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSummarizeMeetingRequestHook>>>,
    TError,
    { meetingRequestId: number; data: ApiMeetingResult },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const summarizeMeetingRequest = useSummarizeMeetingRequestHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useSummarizeMeetingRequestHook>>>,
        { meetingRequestId: number; data: ApiMeetingResult }
    > = (props) => {
        const { meetingRequestId, data } = props ?? {}

        return summarizeMeetingRequest(meetingRequestId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type SummarizeMeetingRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSummarizeMeetingRequestHook>>>>
export type SummarizeMeetingRequestMutationBody = ApiMeetingResult
export type SummarizeMeetingRequestMutationError = ApiError

export const useSummarizeMeetingRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSummarizeMeetingRequestHook>>>,
        TError,
        { meetingRequestId: number; data: ApiMeetingResult },
        TContext
    >
}) => {
    const mutationOptions = useSummarizeMeetingRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetVotesHook = () => {
    const getVotes = useStandardsSwaggerClient<ApiVotePreviewList>()

    return (params: GetVotesParams, signal?: AbortSignal) => {
        return getVotes({ url: `/standards/votes`, method: 'get', params, signal })
    }
}

export const getGetVotesQueryKey = (params: GetVotesParams) => [`/standards/votes`, ...(params ? [params] : [])] as const

export const useGetVotesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetVotesHook>>>, TError = ApiError>(
    params: GetVotesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVotesHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVotesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetVotesQueryKey(params)

    const getVotes = useGetVotesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetVotesHook>>>> = ({ signal }) => getVotes(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetVotesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetVotesHook>>>>
export type GetVotesQueryError = ApiError

export const useGetVotes = <TData = Awaited<ReturnType<ReturnType<typeof useGetVotesHook>>>, TError = ApiError>(
    params: GetVotesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVotesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetVotesQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCreateVoteHook = () => {
    const createVote = useStandardsSwaggerClient<ApiVote>()

    return (apiVote: ApiVote) => {
        return createVote({ url: `/standards/votes`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: apiVote })
    }
}

export const useCreateVoteMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateVoteHook>>>, TError, { data: ApiVote }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateVoteHook>>>, TError, { data: ApiVote }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const createVote = useCreateVoteHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateVoteHook>>>, { data: ApiVote }> = (props) => {
        const { data } = props ?? {}

        return createVote(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CreateVoteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateVoteHook>>>>
export type CreateVoteMutationBody = ApiVote
export type CreateVoteMutationError = ApiError

export const useCreateVote = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateVoteHook>>>, TError, { data: ApiVote }, TContext>
}) => {
    const mutationOptions = useCreateVoteMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useVetoVoteHook = () => {
    const vetoVote = useStandardsSwaggerClient<void>()

    return (voteId: number, token: string) => {
        return vetoVote({ url: `/standards/votes/${voteId}/userToken/${token}/veto`, method: 'post' })
    }
}

export const useVetoVoteMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useVetoVoteHook>>>, TError, { voteId: number; token: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useVetoVoteHook>>>, TError, { voteId: number; token: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const vetoVote = useVetoVoteHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useVetoVoteHook>>>, { voteId: number; token: string }> = (props) => {
        const { voteId, token } = props ?? {}

        return vetoVote(voteId, token)
    }

    return { mutationFn, ...mutationOptions }
}

export type VetoVoteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useVetoVoteHook>>>>

export type VetoVoteMutationError = ApiError

export const useVetoVote = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useVetoVoteHook>>>, TError, { voteId: number; token: string }, TContext>
}) => {
    const mutationOptions = useVetoVoteMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useVoteNoteHook = () => {
    const voteNote = useStandardsSwaggerClient<VoteNote200>()

    return (voteId: number, token: string, apiVoteNote: ApiVoteNote) => {
        return voteNote({
            url: `/standards/votes/${voteId}/userToken/${token}/note`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: apiVoteNote,
        })
    }
}

export const useVoteNoteMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useVoteNoteHook>>>,
        TError,
        { voteId: number; token: string; data: ApiVoteNote },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useVoteNoteHook>>>,
    TError,
    { voteId: number; token: string; data: ApiVoteNote },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const voteNote = useVoteNoteHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useVoteNoteHook>>>,
        { voteId: number; token: string; data: ApiVoteNote }
    > = (props) => {
        const { voteId, token, data } = props ?? {}

        return voteNote(voteId, token, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type VoteNoteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useVoteNoteHook>>>>
export type VoteNoteMutationBody = ApiVoteNote
export type VoteNoteMutationError = ApiError

export const useVoteNote = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useVoteNoteHook>>>,
        TError,
        { voteId: number; token: string; data: ApiVoteNote },
        TContext
    >
}) => {
    const mutationOptions = useVoteNoteMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useCastVoteHook = () => {
    const castVote = useStandardsSwaggerClient<void>()

    return (voteId: number, token: string, choiceId: number) => {
        return castVote({ url: `/standards/votes/${voteId}/userToken/${token}/cast/${choiceId}`, method: 'post' })
    }
}

export const useCastVoteMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCastVoteHook>>>,
        TError,
        { voteId: number; token: string; choiceId: number },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCastVoteHook>>>,
    TError,
    { voteId: number; token: string; choiceId: number },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const castVote = useCastVoteHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useCastVoteHook>>>,
        { voteId: number; token: string; choiceId: number }
    > = (props) => {
        const { voteId, token, choiceId } = props ?? {}

        return castVote(voteId, token, choiceId)
    }

    return { mutationFn, ...mutationOptions }
}

export type CastVoteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCastVoteHook>>>>

export type CastVoteMutationError = ApiError

export const useCastVote = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCastVoteHook>>>,
        TError,
        { voteId: number; token: string; choiceId: number },
        TContext
    >
}) => {
    const mutationOptions = useCastVoteMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useVetoVote1Hook = () => {
    const vetoVote1 = useStandardsSwaggerClient<VetoVote1200>()

    return (voteId: number, castedUserId: string, apiDescription: ApiDescription) => {
        return vetoVote1({
            url: `/standards/votes/${voteId}/user/${castedUserId}/veto`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: apiDescription,
        })
    }
}

export const useVetoVote1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useVetoVote1Hook>>>,
        TError,
        { voteId: number; castedUserId: string; data: ApiDescription },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useVetoVote1Hook>>>,
    TError,
    { voteId: number; castedUserId: string; data: ApiDescription },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const vetoVote1 = useVetoVote1Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useVetoVote1Hook>>>,
        { voteId: number; castedUserId: string; data: ApiDescription }
    > = (props) => {
        const { voteId, castedUserId, data } = props ?? {}

        return vetoVote1(voteId, castedUserId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type VetoVote1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useVetoVote1Hook>>>>
export type VetoVote1MutationBody = ApiDescription
export type VetoVote1MutationError = ApiError

export const useVetoVote1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useVetoVote1Hook>>>,
        TError,
        { voteId: number; castedUserId: string; data: ApiDescription },
        TContext
    >
}) => {
    const mutationOptions = useVetoVote1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useCastVote1Hook = () => {
    const castVote1 = useStandardsSwaggerClient<CastVote1200>()

    return (voteId: number, castedUserId: string, choiceId: number, apiDescription: ApiDescription) => {
        return castVote1({
            url: `/standards/votes/${voteId}/user/${castedUserId}/cast/${choiceId}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: apiDescription,
        })
    }
}

export const useCastVote1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCastVote1Hook>>>,
        TError,
        { voteId: number; castedUserId: string; choiceId: number; data: ApiDescription },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCastVote1Hook>>>,
    TError,
    { voteId: number; castedUserId: string; choiceId: number; data: ApiDescription },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const castVote1 = useCastVote1Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useCastVote1Hook>>>,
        { voteId: number; castedUserId: string; choiceId: number; data: ApiDescription }
    > = (props) => {
        const { voteId, castedUserId, choiceId, data } = props ?? {}

        return castVote1(voteId, castedUserId, choiceId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CastVote1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCastVote1Hook>>>>
export type CastVote1MutationBody = ApiDescription
export type CastVote1MutationError = ApiError

export const useCastVote1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCastVote1Hook>>>,
        TError,
        { voteId: number; castedUserId: string; choiceId: number; data: ApiDescription },
        TContext
    >
}) => {
    const mutationOptions = useCastVote1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useSummarizeVoteHook = () => {
    const summarizeVote = useStandardsSwaggerClient<SummarizeVote200>()

    return (voteId: number, apiDescription: ApiDescription) => {
        return summarizeVote({
            url: `/standards/votes/${voteId}/summarize`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: apiDescription,
        })
    }
}

export const useSummarizeVoteMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSummarizeVoteHook>>>,
        TError,
        { voteId: number; data: ApiDescription },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSummarizeVoteHook>>>, TError, { voteId: number; data: ApiDescription }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const summarizeVote = useSummarizeVoteHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSummarizeVoteHook>>>, { voteId: number; data: ApiDescription }> = (
        props,
    ) => {
        const { voteId, data } = props ?? {}

        return summarizeVote(voteId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type SummarizeVoteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSummarizeVoteHook>>>>
export type SummarizeVoteMutationBody = ApiDescription
export type SummarizeVoteMutationError = ApiError

export const useSummarizeVote = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSummarizeVoteHook>>>,
        TError,
        { voteId: number; data: ApiDescription },
        TContext
    >
}) => {
    const mutationOptions = useSummarizeVoteMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useCancelVoteHook = () => {
    const cancelVote = useStandardsSwaggerClient<CancelVote200>()

    return (voteId: number, apiDescription: ApiDescription) => {
        return cancelVote({
            url: `/standards/votes/${voteId}/cancel`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: apiDescription,
        })
    }
}

export const useCancelVoteMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCancelVoteHook>>>,
        TError,
        { voteId: number; data: ApiDescription },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelVoteHook>>>, TError, { voteId: number; data: ApiDescription }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const cancelVote = useCancelVoteHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCancelVoteHook>>>, { voteId: number; data: ApiDescription }> = (
        props,
    ) => {
        const { voteId, data } = props ?? {}

        return cancelVote(voteId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CancelVoteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCancelVoteHook>>>>
export type CancelVoteMutationBody = ApiDescription
export type CancelVoteMutationError = ApiError

export const useCancelVote = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCancelVoteHook>>>,
        TError,
        { voteId: number; data: ApiDescription },
        TContext
    >
}) => {
    const mutationOptions = useCancelVoteMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateVoteRoleHook = () => {
    const updateVoteRole = useStandardsSwaggerClient<void>()

    return (updateVoteRequest: UpdateVoteRequest) => {
        return updateVoteRole({
            url: `/standards/votes/updateVoteRole`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: updateVoteRequest,
        })
    }
}

export const useUpdateVoteRoleMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateVoteRoleHook>>>, TError, { data: UpdateVoteRequest }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateVoteRoleHook>>>, TError, { data: UpdateVoteRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateVoteRole = useUpdateVoteRoleHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateVoteRoleHook>>>, { data: UpdateVoteRequest }> = (props) => {
        const { data } = props ?? {}

        return updateVoteRole(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateVoteRoleMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateVoteRoleHook>>>>
export type UpdateVoteRoleMutationBody = UpdateVoteRequest
export type UpdateVoteRoleMutationError = ApiError

export const useUpdateVoteRole = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateVoteRoleHook>>>, TError, { data: UpdateVoteRequest }, TContext>
}) => {
    const mutationOptions = useUpdateVoteRoleMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @deprecated
 */
export const useUpdateVoteRole1Hook = () => {
    const updateVoteRole1 = useStandardsSwaggerClient<UpdateVoteRole1200>()

    return (userIdLogin: string, newRoleUuid: string, newRoleName: string, newRoleDesc: string) => {
        return updateVoteRole1({
            url: `/standards/votes/updateVoteRole/userIdLogin/${userIdLogin}/newRoleUuid/${newRoleUuid}/newRoleName/${newRoleName}/newRoleDesc/${newRoleDesc}`,
            method: 'post',
        })
    }
}

export const useUpdateVoteRole1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateVoteRole1Hook>>>,
        TError,
        { userIdLogin: string; newRoleUuid: string; newRoleName: string; newRoleDesc: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateVoteRole1Hook>>>,
    TError,
    { userIdLogin: string; newRoleUuid: string; newRoleName: string; newRoleDesc: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateVoteRole1 = useUpdateVoteRole1Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateVoteRole1Hook>>>,
        { userIdLogin: string; newRoleUuid: string; newRoleName: string; newRoleDesc: string }
    > = (props) => {
        const { userIdLogin, newRoleUuid, newRoleName, newRoleDesc } = props ?? {}

        return updateVoteRole1(userIdLogin, newRoleUuid, newRoleName, newRoleDesc)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateVoteRole1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateVoteRole1Hook>>>>

export type UpdateVoteRole1MutationError = ApiError

/**
 * @deprecated
 */
export const useUpdateVoteRole1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateVoteRole1Hook>>>,
        TError,
        { userIdLogin: string; newRoleUuid: string; newRoleName: string; newRoleDesc: string },
        TContext
    >
}) => {
    const mutationOptions = useUpdateVoteRole1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetFOPStandardRequestsHook = () => {
    const getFOPStandardRequests = useStandardsSwaggerClient<ApiStandardRequestPreviewList>()

    return (params: GetFOPStandardRequestsParams, signal?: AbortSignal) => {
        return getFOPStandardRequests({ url: `/standards/requests`, method: 'get', params, signal })
    }
}

export const getGetFOPStandardRequestsQueryKey = (params: GetFOPStandardRequestsParams) =>
    [`/standards/requests`, ...(params ? [params] : [])] as const

export const useGetFOPStandardRequestsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetFOPStandardRequestsHook>>>,
    TError = ApiError,
>(
    params: GetFOPStandardRequestsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFOPStandardRequestsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFOPStandardRequestsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetFOPStandardRequestsQueryKey(params)

    const getFOPStandardRequests = useGetFOPStandardRequestsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetFOPStandardRequestsHook>>>> = ({ signal }) =>
        getFOPStandardRequests(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetFOPStandardRequestsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetFOPStandardRequestsHook>>>>
export type GetFOPStandardRequestsQueryError = ApiError

export const useGetFOPStandardRequests = <TData = Awaited<ReturnType<ReturnType<typeof useGetFOPStandardRequestsHook>>>, TError = ApiError>(
    params: GetFOPStandardRequestsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFOPStandardRequestsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetFOPStandardRequestsQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCreateStandardRequestHook = () => {
    const createStandardRequest = useStandardsSwaggerClient<ApiStandardRequest>()

    return (apiStandardRequest: ApiStandardRequest) => {
        return createStandardRequest({
            url: `/standards/requests`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: apiStandardRequest,
        })
    }
}

export const useCreateStandardRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateStandardRequestHook>>>,
        TError,
        { data: ApiStandardRequest },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateStandardRequestHook>>>, TError, { data: ApiStandardRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const createStandardRequest = useCreateStandardRequestHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateStandardRequestHook>>>, { data: ApiStandardRequest }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return createStandardRequest(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CreateStandardRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateStandardRequestHook>>>>
export type CreateStandardRequestMutationBody = ApiStandardRequest
export type CreateStandardRequestMutationError = ApiError

export const useCreateStandardRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateStandardRequestHook>>>,
        TError,
        { data: ApiStandardRequest },
        TContext
    >
}) => {
    const mutationOptions = useCreateStandardRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useCreateStandardRequestUploadHook = () => {
    const createStandardRequestUpload = useStandardsSwaggerClient<CreateStandardRequestUpload200>()

    return (createStandardRequestUploadBody: CreateStandardRequestUploadBody, params: CreateStandardRequestUploadParams) => {
        const formData = new FormData()
        if (createStandardRequestUploadBody.file !== undefined) {
            formData.append('file', createStandardRequestUploadBody.file)
        }

        return createStandardRequestUpload({
            url: `/standards/requests/upload`,
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData,
            params,
        })
    }
}

export const useCreateStandardRequestUploadMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateStandardRequestUploadHook>>>,
        TError,
        { data: CreateStandardRequestUploadBody; params: CreateStandardRequestUploadParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateStandardRequestUploadHook>>>,
    TError,
    { data: CreateStandardRequestUploadBody; params: CreateStandardRequestUploadParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const createStandardRequestUpload = useCreateStandardRequestUploadHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useCreateStandardRequestUploadHook>>>,
        { data: CreateStandardRequestUploadBody; params: CreateStandardRequestUploadParams }
    > = (props) => {
        const { data, params } = props ?? {}

        return createStandardRequestUpload(data, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type CreateStandardRequestUploadMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateStandardRequestUploadHook>>>>
export type CreateStandardRequestUploadMutationBody = CreateStandardRequestUploadBody
export type CreateStandardRequestUploadMutationError = ApiError

export const useCreateStandardRequestUpload = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCreateStandardRequestUploadHook>>>,
        TError,
        { data: CreateStandardRequestUploadBody; params: CreateStandardRequestUploadParams },
        TContext
    >
}) => {
    const mutationOptions = useCreateStandardRequestUploadMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useReindexHook = () => {
    const reindex = useStandardsSwaggerClient<Reindex200>()

    return () => {
        return reindex({ url: `/standards/reindex/votes`, method: 'post' })
    }
}

export const useReindexMutationOptions = <TError = ApiError, TVariables = void, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindexHook>>>, TError, TVariables, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindexHook>>>, TError, TVariables, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const reindex = useReindexHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReindexHook>>>, TVariables> = () => {
        return reindex()
    }

    return { mutationFn, ...mutationOptions }
}

export type ReindexMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReindexHook>>>>

export type ReindexMutationError = ApiError

export const useReindex = <TError = ApiError, TVariables = void, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindexHook>>>, TError, TVariables, TContext>
}) => {
    const mutationOptions = useReindexMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useReindex1Hook = () => {
    const reindex1 = useStandardsSwaggerClient<Reindex1200>()

    return () => {
        return reindex1({ url: `/standards/reindex/requests`, method: 'post' })
    }
}

export const useReindex1MutationOptions = <TError = ApiError, TVariables = void, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindex1Hook>>>, TError, TVariables, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindex1Hook>>>, TError, TVariables, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const reindex1 = useReindex1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReindex1Hook>>>, TVariables> = () => {
        return reindex1()
    }

    return { mutationFn, ...mutationOptions }
}

export type Reindex1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReindex1Hook>>>>

export type Reindex1MutationError = ApiError

export const useReindex1 = <TError = ApiError, TVariables = void, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindex1Hook>>>, TError, TVariables, TContext>
}) => {
    const mutationOptions = useReindex1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useReindex2Hook = () => {
    const reindex2 = useStandardsSwaggerClient<Reindex2200>()

    return () => {
        return reindex2({ url: `/reindex/meetings`, method: 'post' })
    }
}

export const useReindex2MutationOptions = <TError = ApiError, TVariables = void, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindex2Hook>>>, TError, TVariables, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindex2Hook>>>, TError, TVariables, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const reindex2 = useReindex2Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReindex2Hook>>>, TVariables> = () => {
        return reindex2()
    }

    return { mutationFn, ...mutationOptions }
}

export type Reindex2MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReindex2Hook>>>>

export type Reindex2MutationError = ApiError

export const useReindex2 = <TError = ApiError, TVariables = void, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReindex2Hook>>>, TError, TVariables, TContext>
}) => {
    const mutationOptions = useReindex2MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetMeetingRequestsHook = () => {
    const getMeetingRequests = useStandardsSwaggerClient<ApiMeetingRequestPreviewList>()

    return (params: GetMeetingRequestsParams, signal?: AbortSignal) => {
        return getMeetingRequests({ url: `/meetings`, method: 'get', params, signal })
    }
}

export const getGetMeetingRequestsQueryKey = (params: GetMeetingRequestsParams) => [`/meetings`, ...(params ? [params] : [])] as const

export const useGetMeetingRequestsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestsHook>>>, TError = ApiError>(
    params: GetMeetingRequestsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetMeetingRequestsQueryKey(params)

    const getMeetingRequests = useGetMeetingRequestsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestsHook>>>> = ({ signal }) =>
        getMeetingRequests(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetMeetingRequestsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestsHook>>>>
export type GetMeetingRequestsQueryError = ApiError

export const useGetMeetingRequests = <TData = Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestsHook>>>, TError = ApiError>(
    params: GetMeetingRequestsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMeetingRequestsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetMeetingRequestsQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCreateMeetingHook = () => {
    const createMeeting = useStandardsSwaggerClient<ApiMeetingRequest>()

    return (apiMeetingRequest: ApiMeetingRequest) => {
        return createMeeting({ url: `/meetings`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: apiMeetingRequest })
    }
}

export const useCreateMeetingMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateMeetingHook>>>, TError, { data: ApiMeetingRequest }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateMeetingHook>>>, TError, { data: ApiMeetingRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const createMeeting = useCreateMeetingHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateMeetingHook>>>, { data: ApiMeetingRequest }> = (props) => {
        const { data } = props ?? {}

        return createMeeting(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CreateMeetingMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateMeetingHook>>>>
export type CreateMeetingMutationBody = ApiMeetingRequest
export type CreateMeetingMutationError = ApiError

export const useCreateMeeting = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateMeetingHook>>>, TError, { data: ApiMeetingRequest }, TContext>
}) => {
    const mutationOptions = useCreateMeetingMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useParticipateMeetingRequestHook = () => {
    const participateMeetingRequest = useStandardsSwaggerClient<ParticipateMeetingRequest200>()

    return (meetingRequestId: number, params: ParticipateMeetingRequestParams) => {
        return participateMeetingRequest({ url: `/meetings/${meetingRequestId}/participate`, method: 'post', params })
    }
}

export const useParticipateMeetingRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useParticipateMeetingRequestHook>>>,
        TError,
        { meetingRequestId: number; params: ParticipateMeetingRequestParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useParticipateMeetingRequestHook>>>,
    TError,
    { meetingRequestId: number; params: ParticipateMeetingRequestParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const participateMeetingRequest = useParticipateMeetingRequestHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useParticipateMeetingRequestHook>>>,
        { meetingRequestId: number; params: ParticipateMeetingRequestParams }
    > = (props) => {
        const { meetingRequestId, params } = props ?? {}

        return participateMeetingRequest(meetingRequestId, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type ParticipateMeetingRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useParticipateMeetingRequestHook>>>>

export type ParticipateMeetingRequestMutationError = ApiError

export const useParticipateMeetingRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useParticipateMeetingRequestHook>>>,
        TError,
        { meetingRequestId: number; params: ParticipateMeetingRequestParams },
        TContext
    >
}) => {
    const mutationOptions = useParticipateMeetingRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useCancelMeetingRequestHook = () => {
    const cancelMeetingRequest = useStandardsSwaggerClient<CancelMeetingRequest200>()

    return (meetingRequestId: number, apiDescription: ApiDescription) => {
        return cancelMeetingRequest({
            url: `/meetings/${meetingRequestId}/cancel`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: apiDescription,
        })
    }
}

export const useCancelMeetingRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCancelMeetingRequestHook>>>,
        TError,
        { meetingRequestId: number; data: ApiDescription },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCancelMeetingRequestHook>>>,
    TError,
    { meetingRequestId: number; data: ApiDescription },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const cancelMeetingRequest = useCancelMeetingRequestHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useCancelMeetingRequestHook>>>,
        { meetingRequestId: number; data: ApiDescription }
    > = (props) => {
        const { meetingRequestId, data } = props ?? {}

        return cancelMeetingRequest(meetingRequestId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CancelMeetingRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCancelMeetingRequestHook>>>>
export type CancelMeetingRequestMutationBody = ApiDescription
export type CancelMeetingRequestMutationError = ApiError

export const useCancelMeetingRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useCancelMeetingRequestHook>>>,
        TError,
        { meetingRequestId: number; data: ApiDescription },
        TContext
    >
}) => {
    const mutationOptions = useCancelMeetingRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useStartTaskHook = () => {
    const startTask = useStandardsSwaggerClient<FillRefAttributesTask>()

    return (id: string) => {
        return startTask({ url: `/fillRefAttributes/startTask/${id}`, method: 'post' })
    }
}

export const useStartTaskMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStartTaskHook>>>, TError, { id: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStartTaskHook>>>, TError, { id: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const startTask = useStartTaskHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useStartTaskHook>>>, { id: string }> = (props) => {
        const { id } = props ?? {}

        return startTask(id)
    }

    return { mutationFn, ...mutationOptions }
}

export type StartTaskMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStartTaskHook>>>>

export type StartTaskMutationError = ApiError

export const useStartTask = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useStartTaskHook>>>, TError, { id: string }, TContext>
}) => {
    const mutationOptions = useStartTaskMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGroupsWithMeetingsHook = () => {
    const groupsWithMeetings = useStandardsSwaggerClient<GroupMeetings[]>()

    return (signal?: AbortSignal) => {
        return groupsWithMeetings({ url: `/user-groups/meetings`, method: 'get', signal })
    }
}

export const getGroupsWithMeetingsQueryKey = () => [`/user-groups/meetings`] as const

export const useGroupsWithMeetingsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGroupsWithMeetingsHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGroupsWithMeetingsHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGroupsWithMeetingsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGroupsWithMeetingsQueryKey()

    const groupsWithMeetings = useGroupsWithMeetingsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGroupsWithMeetingsHook>>>> = ({ signal }) => groupsWithMeetings(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GroupsWithMeetingsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGroupsWithMeetingsHook>>>>
export type GroupsWithMeetingsQueryError = ApiError

export const useGroupsWithMeetings = <TData = Awaited<ReturnType<ReturnType<typeof useGroupsWithMeetingsHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGroupsWithMeetingsHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGroupsWithMeetingsQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetVoteResultHook = () => {
    const getVoteResult = useStandardsSwaggerClient<ApiVoteResult>()

    return (voteId: number, signal?: AbortSignal) => {
        return getVoteResult({ url: `/standards/votes/${voteId}/result`, method: 'get', signal })
    }
}

export const getGetVoteResultQueryKey = (voteId: number) => [`/standards/votes/${voteId}/result`] as const

export const useGetVoteResultQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetVoteResultHook>>>, TError = ApiError>(
    voteId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVoteResultHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVoteResultHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetVoteResultQueryKey(voteId)

    const getVoteResult = useGetVoteResultHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetVoteResultHook>>>> = ({ signal }) => getVoteResult(voteId, signal)

    return { queryKey, queryFn, enabled: !!voteId, ...queryOptions }
}

export type GetVoteResultQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetVoteResultHook>>>>
export type GetVoteResultQueryError = ApiError

export const useGetVoteResult = <TData = Awaited<ReturnType<ReturnType<typeof useGetVoteResultHook>>>, TError = ApiError>(
    voteId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVoteResultHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetVoteResultQueryOptions(voteId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetUserVoteActorResultsHook = () => {
    const getUserVoteActorResults = useStandardsSwaggerClient<ApiVoteUserActorResults>()

    return (voteId: number, signal?: AbortSignal) => {
        return getUserVoteActorResults({ url: `/standards/votes/${voteId}/actorresults`, method: 'get', signal })
    }
}

export const getGetUserVoteActorResultsQueryKey = (voteId: number) => [`/standards/votes/${voteId}/actorresults`] as const

export const useGetUserVoteActorResultsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetUserVoteActorResultsHook>>>,
    TError = ApiError,
>(
    voteId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVoteActorResultsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVoteActorResultsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetUserVoteActorResultsQueryKey(voteId)

    const getUserVoteActorResults = useGetUserVoteActorResultsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserVoteActorResultsHook>>>> = ({ signal }) =>
        getUserVoteActorResults(voteId, signal)

    return { queryKey, queryFn, enabled: !!voteId, ...queryOptions }
}

export type GetUserVoteActorResultsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetUserVoteActorResultsHook>>>>
export type GetUserVoteActorResultsQueryError = ApiError

export const useGetUserVoteActorResults = <TData = Awaited<ReturnType<ReturnType<typeof useGetUserVoteActorResultsHook>>>, TError = ApiError>(
    voteId: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVoteActorResultsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetUserVoteActorResultsQueryOptions(voteId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetVoteActorResultHook = () => {
    const getVoteActorResult = useStandardsSwaggerClient<ApiVoteActorResult>()

    return (voteId: number, castedUserId: string, signal?: AbortSignal) => {
        return getVoteActorResult({ url: `/standards/votes/${voteId}/actorresult/${castedUserId}`, method: 'get', signal })
    }
}

export const getGetVoteActorResultQueryKey = (voteId: number, castedUserId: string) =>
    [`/standards/votes/${voteId}/actorresult/${castedUserId}`] as const

export const useGetVoteActorResultQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetVoteActorResultHook>>>, TError = ApiError>(
    voteId: number,
    castedUserId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVoteActorResultHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVoteActorResultHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetVoteActorResultQueryKey(voteId, castedUserId)

    const getVoteActorResult = useGetVoteActorResultHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetVoteActorResultHook>>>> = ({ signal }) =>
        getVoteActorResult(voteId, castedUserId, signal)

    return { queryKey, queryFn, enabled: !!(voteId && castedUserId), ...queryOptions }
}

export type GetVoteActorResultQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetVoteActorResultHook>>>>
export type GetVoteActorResultQueryError = ApiError

export const useGetVoteActorResult = <TData = Awaited<ReturnType<ReturnType<typeof useGetVoteActorResultHook>>>, TError = ApiError>(
    voteId: number,
    castedUserId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVoteActorResultHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetVoteActorResultQueryOptions(voteId, castedUserId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetAllStandardRequestsHook = () => {
    const getAllStandardRequests = useStandardsSwaggerClient<ApiStandardRequestPreviewList>()

    return (signal?: AbortSignal) => {
        return getAllStandardRequests({ url: `/standards/requests/all`, method: 'get', signal })
    }
}

export const getGetAllStandardRequestsQueryKey = () => [`/standards/requests/all`] as const

export const useGetAllStandardRequestsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetAllStandardRequestsHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllStandardRequestsHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllStandardRequestsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetAllStandardRequestsQueryKey()

    const getAllStandardRequests = useGetAllStandardRequestsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllStandardRequestsHook>>>> = ({ signal }) =>
        getAllStandardRequests(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetAllStandardRequestsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllStandardRequestsHook>>>>
export type GetAllStandardRequestsQueryError = ApiError

export const useGetAllStandardRequests = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetAllStandardRequestsHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllStandardRequestsHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetAllStandardRequestsQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useRejectMeetingRequestHook = () => {
    const rejectMeetingRequest = useStandardsSwaggerClient<void>()

    return (id: number, params: RejectMeetingRequestParams) => {
        return rejectMeetingRequest({ url: `/meetings/participate/${id}/reject`, method: 'get', params })
    }
}

export const useRejectMeetingRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRejectMeetingRequestHook>>>,
        TError,
        { id: number; params: RejectMeetingRequestParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRejectMeetingRequestHook>>>,
    TError,
    { id: number; params: RejectMeetingRequestParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const rejectMeetingRequest = useRejectMeetingRequestHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useRejectMeetingRequestHook>>>,
        { id: number; params: RejectMeetingRequestParams }
    > = (props) => {
        const { id, params } = props ?? {}

        return rejectMeetingRequest(id, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type RejectMeetingRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRejectMeetingRequestHook>>>>

export type RejectMeetingRequestMutationError = ApiError

export const useRejectMeetingRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRejectMeetingRequestHook>>>,
        TError,
        { id: number; params: RejectMeetingRequestParams },
        TContext
    >
}) => {
    const mutationOptions = useRejectMeetingRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useAcceptMeetingRequestHook = () => {
    const acceptMeetingRequest = useStandardsSwaggerClient<void>()

    return (id: number, params: AcceptMeetingRequestParams) => {
        return acceptMeetingRequest({ url: `/meetings/participate/${id}/accept`, method: 'get', params })
    }
}

export const useAcceptMeetingRequestMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAcceptMeetingRequestHook>>>,
        TError,
        { id: number; params: AcceptMeetingRequestParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAcceptMeetingRequestHook>>>,
    TError,
    { id: number; params: AcceptMeetingRequestParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const acceptMeetingRequest = useAcceptMeetingRequestHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useAcceptMeetingRequestHook>>>,
        { id: number; params: AcceptMeetingRequestParams }
    > = (props) => {
        const { id, params } = props ?? {}

        return acceptMeetingRequest(id, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type AcceptMeetingRequestMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAcceptMeetingRequestHook>>>>

export type AcceptMeetingRequestMutationError = ApiError

export const useAcceptMeetingRequest = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAcceptMeetingRequestHook>>>,
        TError,
        { id: number; params: AcceptMeetingRequestParams },
        TContext
    >
}) => {
    const mutationOptions = useAcceptMeetingRequestMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetTasksHook = () => {
    const getTasks = useStandardsSwaggerClient<FillRefAttributesTask[]>()

    return (signal?: AbortSignal) => {
        return getTasks({ url: `/fillRefAttributes/tasks`, method: 'get', signal })
    }
}

export const getGetTasksQueryKey = () => [`/fillRefAttributes/tasks`] as const

export const useGetTasksQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTasksQueryKey()

    const getTasks = useGetTasksHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>> = ({ signal }) => getTasks(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetTasksQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>>
export type GetTasksQueryError = ApiError

export const useGetTasks = <TData = Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTasksHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetTasksQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetTaskHook = () => {
    const getTask = useStandardsSwaggerClient<FillRefAttributesTask>()

    return (id: string, signal?: AbortSignal) => {
        return getTask({ url: `/fillRefAttributes/tasks/${id}`, method: 'get', signal })
    }
}

export const getGetTaskQueryKey = (id: string) => [`/fillRefAttributes/tasks/${id}`] as const

export const useGetTaskQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetTaskHook>>>, TError = ApiError>(
    id: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTaskQueryKey(id)

    const getTask = useGetTaskHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTaskHook>>>> = ({ signal }) => getTask(id, signal)

    return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type GetTaskQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTaskHook>>>>
export type GetTaskQueryError = ApiError

export const useGetTask = <TData = Awaited<ReturnType<ReturnType<typeof useGetTaskHook>>>, TError = ApiError>(
    id: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetTaskQueryOptions(id, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetTaskBriefInfosHook = () => {
    const getTaskBriefInfos = useStandardsSwaggerClient<string[]>()

    return (signal?: AbortSignal) => {
        return getTaskBriefInfos({ url: `/fillRefAttributes/tasks/briefInfos`, method: 'get', signal })
    }
}

export const getGetTaskBriefInfosQueryKey = () => [`/fillRefAttributes/tasks/briefInfos`] as const

export const useGetTaskBriefInfosQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfosHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfosHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfosHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTaskBriefInfosQueryKey()

    const getTaskBriefInfos = useGetTaskBriefInfosHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfosHook>>>> = ({ signal }) => getTaskBriefInfos(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetTaskBriefInfosQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfosHook>>>>
export type GetTaskBriefInfosQueryError = ApiError

export const useGetTaskBriefInfos = <TData = Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfosHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfosHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetTaskBriefInfosQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetTaskBriefInfoHook = () => {
    const getTaskBriefInfo = useStandardsSwaggerClient<string>()

    return (id: string, signal?: AbortSignal) => {
        return getTaskBriefInfo({ url: `/fillRefAttributes/tasks/briefInfos/${id}`, method: 'get', signal })
    }
}

export const getGetTaskBriefInfoQueryKey = (id: string) => [`/fillRefAttributes/tasks/briefInfos/${id}`] as const

export const useGetTaskBriefInfoQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfoHook>>>, TError = ApiError>(
    id: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfoHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfoHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetTaskBriefInfoQueryKey(id)

    const getTaskBriefInfo = useGetTaskBriefInfoHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfoHook>>>> = ({ signal }) => getTaskBriefInfo(id, signal)

    return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type GetTaskBriefInfoQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfoHook>>>>
export type GetTaskBriefInfoQueryError = ApiError

export const useGetTaskBriefInfo = <TData = Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfoHook>>>, TError = ApiError>(
    id: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTaskBriefInfoHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetTaskBriefInfoQueryOptions(id, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCancelTaskHook = () => {
    const cancelTask = useStandardsSwaggerClient<FillRefAttributesTask>()

    return (id: string) => {
        return cancelTask({ url: `/fillRefAttributes/cancelTask/${id}`, method: 'delete' })
    }
}

export const useCancelTaskMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelTaskHook>>>, TError, { id: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelTaskHook>>>, TError, { id: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const cancelTask = useCancelTaskHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCancelTaskHook>>>, { id: string }> = (props) => {
        const { id } = props ?? {}

        return cancelTask(id)
    }

    return { mutationFn, ...mutationOptions }
}

export type CancelTaskMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCancelTaskHook>>>>

export type CancelTaskMutationError = ApiError

export const useCancelTask = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelTaskHook>>>, TError, { id: string }, TContext>
}) => {
    const mutationOptions = useCancelTaskMutationOptions(options)

    return useMutation(mutationOptions)
}
