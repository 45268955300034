import { mapFilterToRefRegisters } from '@isdd/metais-common/api/filter/filterApi'
import {
    useCreateReferenceRegisterItem,
    useDeleteReferenceRegisterItem,
    useGetFOPReferenceRegisterItems,
    useUpdateReferenceRegisterItem,
} from '@isdd/metais-common/api/generated/reference-registers-swagger'
import { transformColumnsMap } from '@isdd/metais-common/api/hooks/containers/containerHelpers'
import { BASE_PAGE_NUMBER, BASE_PAGE_SIZE } from '@isdd/metais-common/index'
import { useEffect, useMemo, useState } from 'react'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { useGetCiTypeWrapper } from '@isdd/metais-common/hooks/useCiType.hook'
import { Pagination } from '@isdd/idsk-ui-kit/types'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { RouteNames } from '@isdd/metais-common/navigation/routeNames'
import { useGetStatus } from '@isdd/metais-common/hooks/useGetRequestStatus'

import { IRefRegisterItemsView } from '@/types/views'

interface IRefRegisterItemsContainer {
    entityId: string
    View: React.FC<IRefRegisterItemsView>
}

export enum RefRegItemEventType {
    CREATE = 'create',
    UPDATE = 'update',
    DELETE = 'delete',
}

export const RefRegisterItemsContainer = ({ entityId, View }: IRefRegisterItemsContainer) => {
    const {
        state: { user },
    } = useAuth()

    const { setIsActionSuccess } = useActionSuccess()
    const { getRequestStatus, isProcessedError, isError: isStatusError, isLoading: isStatusLoading, isTooManyFetchesError } = useGetStatus()

    const [pagination, setPagination] = useState<Pagination>({
        pageNumber: BASE_PAGE_NUMBER,
        pageSize: BASE_PAGE_SIZE,
        dataLength: 0,
    })
    const {
        data: refRegisterItems,
        isLoading: refRegisterItemsLoading,
        isFetching: refRegisterItemsFetching,
        isError: refRegisterItemsError,
        refetch: refetchItems,
    } = useGetFOPReferenceRegisterItems(entityId, mapFilterToRefRegisters({ pageNumber: pagination.pageNumber, pageSize: pagination.pageSize }, user))

    const { mutateAsync: updateRefRegisterItem, isLoading: isUpdating } = useUpdateReferenceRegisterItem({
        mutation: {
            onSuccess: (res) => {
                getRequestStatus(res.requestId ?? '', () => {
                    refetchItems()
                    setIsActionSuccess({
                        value: true,
                        path: `${RouteNames.REFERENCE_REGISTERS}/${entityId}`,
                        additionalInfo: { type: 'RefRegisterItem', event: RefRegItemEventType.UPDATE },
                    })
                })
            },
        },
    })
    const { mutateAsync: deleteRefRegisterItem, isLoading: isDeleting } = useDeleteReferenceRegisterItem({
        mutation: {
            onSuccess: (res) => {
                getRequestStatus(res.requestId ?? '', () => {
                    refetchItems()
                    setIsActionSuccess({
                        value: true,
                        path: `${RouteNames.REFERENCE_REGISTERS}/${entityId}`,
                        additionalInfo: { type: 'RefRegisterItem', event: RefRegItemEventType.DELETE },
                    })
                })
            },
        },
    })

    const { data: ciTypeData, isLoading: isCiTypeDataLoading, isError: isCiTypeDataError } = useGetCiTypeWrapper('ReferenceRegisterItem')
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
    const createRefRegisterItem = useCreateReferenceRegisterItem({
        mutation: {
            onSuccess: (res) => {
                getRequestStatus(res.requestId ?? '', () => {
                    refetchItems()
                    setIsCreateModalOpen(false)
                    setIsActionSuccess({
                        value: true,
                        path: `${RouteNames.REFERENCE_REGISTERS}/${entityId}`,
                        additionalInfo: { type: 'RefRegisterItem', event: RefRegItemEventType.CREATE },
                    })
                })
            },
        },
    })

    useEffect(() => {
        setPagination((prevPagination: Pagination) => {
            return { ...prevPagination, dataLength: refRegisterItems?.apiReferenceRegisterItemsCount ?? 0 }
        })
    }, [refRegisterItems])

    const referenceRegisterItemAttributes = useMemo(() => {
        return (
            ciTypeData?.attributes?.map((attr) => ({
                ...attr,
                technicalName: transformColumnsMap?.get(attr?.technicalName ?? '') ?? attr?.technicalName,
            })) ?? []
        )
    }, [ciTypeData])

    const isLoading = [refRegisterItemsLoading, refRegisterItemsFetching, isCiTypeDataLoading, isDeleting, isUpdating, isStatusLoading].some(
        (item) => item,
    )
    const isError = [refRegisterItemsError, isCiTypeDataError, isProcessedError, isTooManyFetchesError, isStatusError].some((item) => item)

    return (
        <View
            data={{
                refRegisterItems,
                referenceRegisterItemAttributes,
            }}
            pagination={pagination}
            isLoading={isLoading}
            isError={isError}
            refRegUuid={entityId}
            isCreateModalOpen={isCreateModalOpen}
            createRefRegisterItem={createRefRegisterItem}
            setIsCreateModalOpen={setIsCreateModalOpen}
            setPagination={setPagination}
            updateRefRegisterItem={updateRefRegisterItem}
            deleteRefRegisterItem={deleteRefRegisterItem}
        />
    )
}
