import { Button, ButtonGroupRow, RadioButton, RadioGroup } from '@isdd/idsk-ui-kit/index'
import React, { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { PublishType } from './KSHelperView'

interface ISelectPublishType {
    onNext: () => void
    onPrevious: () => void
    onSelect: Dispatch<SetStateAction<PublishType>>
}

export const SelectPublishType: React.FC<ISelectPublishType> = ({ onNext, onPrevious, onSelect }) => {
    const { t } = useTranslation()

    return (
        <>
            <RadioGroup
                onChange={(value) => onSelect(value.target.value as unknown as PublishType)}
                legend={t('monitoringServices.table.serviceType')}
                hideLegend
            >
                <RadioButton label={t('publish.selfService')} value={PublishType.SELF} id="" name="publishType" defaultChecked />
                <RadioButton label={t('publish.genericService')} value={PublishType.GENERIC} id="" name="publishType" />
            </RadioGroup>
            <ButtonGroupRow>
                <Button label={t('form.back')} onClick={onPrevious} variant="secondary" />
                <Button label={t('form.next')} onClick={onNext} />
            </ButtonGroupRow>
        </>
    )
}
