import React, { PropsWithChildren, useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { AutoLogoutWarningModal } from './AutoLogoutWarningModal'
import { AfterLogoutModal } from './AfterLogoutModal'

import { QueryFeedback } from '@isdd/metais-common/components/query-feedback/QueryFeedback'
import { useHandleLogout } from '@isdd/metais-common/hooks/useHandleLogout'
import { useCountdown } from '@isdd/metais-common/hooks/useCountdown'
import { useWarnAndLogoutOnUserInactivity } from '@isdd/metais-common/hooks/useWarnAndLogoutOnUserInactivity'
import { INACTIVE_LOGOUT_TIME, INACTIVE_WARNING_TIME, LOCAL_STORAGE_LOGOUT_POPUP_OPENED } from '@isdd/metais-common/constants'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'

export const AutoLogout: React.FC<PropsWithChildren> = ({ children }) => {
    const { t } = useTranslation()
    const { logoutUser, isLoading } = useHandleLogout()

    const warnTime = INACTIVE_WARNING_TIME
    const logoutTime = INACTIVE_LOGOUT_TIME
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false)
    const [isAfterLogoutModalOpen, setIsAfterLogoutModalOpen] = useState(false)

    const handleLogout = useCallback(() => {
        logoutUser(false)
        setIsWarningModalOpen(false)
        setIsAfterLogoutModalOpen(true)
    }, [logoutUser])

    const showWarningModal = useCallback(() => {
        localStorage.setItem(LOCAL_STORAGE_LOGOUT_POPUP_OPENED, String(true))
        setIsWarningModalOpen(true)
    }, [])

    const closeWarningModal = useCallback(() => {
        localStorage.removeItem(LOCAL_STORAGE_LOGOUT_POPUP_OPENED)
        setIsWarningModalOpen(false)
    }, [])

    useWarnAndLogoutOnUserInactivity({ handleLogout, showWarningModal, warnTime, logoutTime })
    const { countDown } = useCountdown({
        shouldCount: isWarningModalOpen,
        onCountDownEnd: handleLogout,
        timeCountInSeconds: warnTime / 1000,
    })

    useEffect(() => {
        const getLogoutPopupState = () => {
            const isLogoutPopupOpened = localStorage.getItem(LOCAL_STORAGE_LOGOUT_POPUP_OPENED)
            if (!isLogoutPopupOpened) {
                setIsWarningModalOpen(false)
            }
        }

        window.addEventListener('storage', getLogoutPopupState)
        return () => {
            window.removeEventListener('storage', getLogoutPopupState)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const {
        state: { user },
    } = useAuth()

    return (
        <QueryFeedback loading={isLoading} indicatorProps={{ label: t('autoLogout.loading') }} withChildren>
            {children}
            <AutoLogoutWarningModal isOpen={isWarningModalOpen && !!user} countDown={countDown} onClose={closeWarningModal} />
            <AfterLogoutModal isOpen={isAfterLogoutModalOpen && !!user} close={() => setIsAfterLogoutModalOpen(false)} />
        </QueryFeedback>
    )
}
