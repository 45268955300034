import { SimpleSelect } from '@isdd/idsk-ui-kit/index'
import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { Attribute } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { METODA_RIADENIA_PROJEKTU } from '@isdd/metais-common/constants'
import { Languages } from '@isdd/metais-common/localization/languages'
import { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useGetProjectPhaseAndStatus } from '@isdd/metais-common/hooks/useGetProjectPhaseAndStatus'

import { createOptions, getDefaultValue, getDefaultValueForSimple } from './attributeInputHelpers'

import { getAttributeInputErrorMessage } from '@/components/create-entity/createEntityHelpers'

type BlackListAttributeInputProps = {
    entityName: string
    attribute: Attribute
    disabled: boolean
    isUpdate: boolean
    defaultValueFromCiItem: string | number | boolean | string[] | undefined
}

export const BlackListAttributeInput = ({ attribute, disabled, isUpdate, defaultValueFromCiItem }: BlackListAttributeInputProps) => {
    const { t, i18n } = useTranslation()
    const { formState, setValue, control, watch } = useFormContext()
    const { errors, isSubmitted } = formState
    const isAgile = watch(ATTRIBUTE_NAME.EA_Profil_Projekt_metoda_riadenia_projektu) === METODA_RIADENIA_PROJEKTU.agile

    const { stavProjektu, fazaProjektu, stavProjektuAgile, fazaProjektuAgile } = useGetProjectPhaseAndStatus()

    const error = getAttributeInputErrorMessage(attribute, errors)
    const requiredText = ` (${t('createEntity.required')})`

    const isCorrect = !error && isSubmitted

    const isFazaProjeku = attribute.technicalName === ATTRIBUTE_NAME.EA_Profil_Projekt_faza_projektu
    const isStatusProjektu = attribute.technicalName === ATTRIBUTE_NAME.EA_Profil_Projekt_status

    const getConstraints = () => {
        if (isFazaProjeku) {
            return isAgile ? fazaProjektuAgile : fazaProjektu
        } else if (isStatusProjektu) {
            return isAgile ? stavProjektuAgile : stavProjektu
        }
    }
    const getAttDefaultValue = () => {
        if (isFazaProjeku) {
            return isAgile ? fazaProjektuAgile?.enumItems?.[0].code : fazaProjektu?.enumItems?.[0].code
        } else if (isStatusProjektu) {
            return isAgile ? stavProjektuAgile?.enumItems?.[0].code : stavProjektu?.enumItems?.[0].code
        }
    }

    const [seed, setSeed] = useState(Math.random())

    useEffect(() => {
        if (!isUpdate) {
            setValue(attribute.technicalName ?? '', getAttDefaultValue())
            setSeed(Math.random())
        } else {
            setValue(attribute.technicalName ?? '', defaultValueFromCiItem)
            setSeed(Math.random())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAgile, isUpdate])

    if (isFazaProjeku || isStatusProjektu) {
        const constraints = getConstraints() ?? {}
        return (
            <SimpleSelect
                key={seed}
                id={attribute.technicalName ?? ''}
                label={`${i18n.language === Languages.SLOVAK ? attribute.name : attribute.engName} ${requiredText}`}
                error={error?.message?.toString()}
                info={attribute.description}
                correct={isCorrect}
                options={createOptions(constraints, isUpdate)}
                disabled={attribute.readOnly || disabled}
                control={control}
                name={attribute.technicalName ?? ''}
                placeholder={t('createEntity.select')}
                menuPosition="absolute"
                defaultValue={getDefaultValueForSimple(
                    constraints,
                    getDefaultValue(watch(attribute.technicalName ?? ''), defaultValueFromCiItem, isUpdate),
                )}
            />
        )
    }
    return <></>
}
