//@ts-nocheck
/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * MetaIS Monitoring
 * OpenAPI spec version: latest
 */
import { useQuery, useMutation } from '@tanstack/react-query'
import type { UseQueryOptions, UseMutationOptions, QueryFunction, MutationFunction, UseQueryResult, QueryKey } from '@tanstack/react-query'
import { useMonitoringSwaggerClient } from '../hooks/useMonitoringSwaggerClient'
export type FindActiveMonitoringCfgParams = {
    isvsUuid?: string
    page?: number
    pageSize?: number
}

export type FindActiveMonitoringLogByInterval1Params = {
    isvsUuid: string
    intervalStart: string
    intervalEnd: string
}

export type FindActiveMonitoringLogByIntervalParams = {
    intervalStart: string
    intervalEnd: string
}

export type FindActiveMonitoringLogParams = {
    activeMonitoringCfgId: number
    page?: number
    pageSize?: number
}

export type FindActiveMonitoringLogFailNoteParams = {
    activeMonitoringLogId: number
    page?: number
    pageSize?: number
}

export type ListOlaContractListParams = {
    contractCode?: string
    name?: string
    contractorIsvsUuid?: string
    metaIsCode?: string
    validityStart?: string
    validityEnd?: string
    liableEntities?: string[]
    page?: number
    perPageSize?: number
    sortBy?: string
    ascending?: boolean
    onlyDraftState?: boolean
}

export type ListRelatedParameterTypesParams = {
    ignoreNoDateIntersection?: boolean
    page?: number
    perPageSize?: number
    sortBy?: string
    ascending?: boolean
}

export type CheckRequiredSlaParametersParams = {
    slaContractUuid: string
    parameterTypeCategory: string
    page?: number
    perPageSize?: number
    sortBy?: string
    ascending?: boolean
}

export type ListCreateContractCandidatesParams = {
    provProjects?: string[]
    provIsvss?: string[]
    consProjects?: string[]
    consIsvsNameLike?: string
    provIsvsNameLike?: string
    page?: number
    perPageSize?: number
    sortBy?: string
    ascending?: boolean
}

export type ListSlaContractsParams = {
    name?: string
    phase?: string
    consumerProjectUuid?: string
    consumerServiceUuid?: string
    consumerMainPersonUuid?: string
    providerProjectUuid?: string
    providerServiceUuid?: string
    providerMainPersonUuid?: string
    intervalStart?: string
    intervalEnd?: string
    statusFilter?: string
    page?: number
    perPageSize?: number
    sortBy?: string
    ascending?: boolean
    onlyDraftState?: boolean
}

export type CheckOperationPermissionByMetaisCodeParams = {
    operation: string
}

export type ListParameterTypesParams = {
    serviceType: string
    paramType?: string
    valueType?: string
    services?: string[]
    ignoreNonActual?: boolean
    page?: number
    perPageSize?: number
    sortBy?: string
    ascending?: boolean
}

export type Echo200 = { [key: string]: any }

export type EchoParams = {
    param?: string
}

export type GetAnyDouble200 = { [key: string]: any }

export type GetAnyList200 = { [key: string]: any }

export type GetAnyObject200 = { [key: string]: any }

export type GetAnyString200 = { [key: string]: any }

export type GetCurrentDate200 = { [key: string]: any }

export type ImportReportBody = {
    file: Blob
}

export type ImportMonitorParametersBody = {
    file: Blob
}

export type ValidateMonitorParametersBody = {
    file: Blob
}

export type ValidateReportBody = {
    file: Blob
}

export type ListParameterTypes1Params = {
    category?: string
    ignoreNonActual?: boolean
    filterRequiredOnly?: boolean
    page?: number
    perPageSize?: number
}

export type ExportParameterValuesParams = {
    filter: ExportParameterValuesFilter
}

export interface ApiActiveMonitoringResult {
    status?: string
    body?: string
    cfgErrorMessage?: string
    statusOk?: boolean
    bodyOk?: boolean
}

export interface ApiActiveMonitoringCfgList {
    results?: ApiActiveMonitoringCfg[]
    pagination?: PaginationResponseData
}

export type ApiActiveMonitoringExtendedLogStatus = (typeof ApiActiveMonitoringExtendedLogStatus)[keyof typeof ApiActiveMonitoringExtendedLogStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApiActiveMonitoringExtendedLogStatus = {
    ALIVE: 'ALIVE',
    DEAD: 'DEAD',
} as const

export interface ApiActiveMonitoringExtendedLog {
    id?: number
    intervalStart?: string
    intervalEnd?: string
    status?: ApiActiveMonitoringExtendedLogStatus
    httpUrl?: string
}

export interface ApiActiveMonitoringAvaliability {
    items?: ApiActiveMonitoringExtendedLog[]
}

export type ApiActiveMonitoringLogStatus = (typeof ApiActiveMonitoringLogStatus)[keyof typeof ApiActiveMonitoringLogStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApiActiveMonitoringLogStatus = {
    ALIVE: 'ALIVE',
    DEAD: 'DEAD',
} as const

export interface ApiActiveMonitoringLog {
    id?: number
    intervalStart?: string
    intervalEnd?: string
    status?: ApiActiveMonitoringLogStatus
}

export interface ApiActiveMonitoringLogList {
    results?: ApiActiveMonitoringLog[]
    pagination?: PaginationResponseData
}

export interface ApiActiveMonitoringLogFailNote {
    id?: number
    date?: string
    note?: string
}

export interface ApiActiveMonitoringLogFailNoteList {
    results?: ApiActiveMonitoringLogFailNote[]
    pagination?: PaginationResponseData
}

export interface ApiOlaContractDataList {
    results?: ApiOlaContractData[]
    pagination?: PaginationResponseData
}

export interface ApiParameterTypesList {
    results?: ApiParameterType[]
    pagination?: PaginationResponseData
}

export interface ApiSlaParameter {
    type?: string
}

export interface ApiService {
    uuid?: string
    code?: string
    name?: string
    slaParameters?: ApiSlaParameter[]
}

export interface ApiServicesSlaParameters {
    results?: ApiService[]
    pagination?: PaginationResponseData
}

export interface ApiMonObjectInfoLight {
    owner?: string
    type?: string
    uuid?: string
    name?: string
    code?: string
    state?: string
}

export interface ApiContractCreateObjectCandidates {
    provProject?: ApiMonObjectInfoLight
    provIsvs?: ApiMonObjectInfoLight
    consProject?: ApiMonObjectInfoLight
    consIsvs?: ApiMonObjectInfoLight
}

export interface ApiContractCreateObjectCandidatesList {
    results?: ApiContractCreateObjectCandidates[]
    pagination?: PaginationResponseData
}

export interface ApiPermissionResult {
    hasPermission?: boolean
}

export interface ApiSlaContractReadMainPersonInfo {
    uuid?: string
    name?: string
}

export interface ApiSlaContractReadIsvsInfo {
    uuid?: string
    name?: string
    abbreviation?: string
    code?: string
}

export interface ApiSlaParameterReadServiceInfo {
    uuid?: string
    name?: string
}

export interface ApiSlaContractParameterRead {
    uuid?: string
    slaParamTypeCode?: string
    value?: string
    valueType?: string
    penaltyDescription?: string
    serviceInfo?: ApiSlaParameterReadServiceInfo
    measurementMethod?: string
    evaluationMethod?: string
    note?: string
    isValid?: boolean
    owner?: string
}

export interface ApiSlaContractRead {
    uuid?: string
    state?: string
    name?: string
    nameEnglish?: string
    description?: string
    descriptionEnglish?: string
    note?: string
    source?: string
    code?: string
    referencingIdentifier?: string
    ciType?: string
    owner?: string
    type?: string
    validityStartDate?: string
    validityEndDate?: string
    subject?: string
    phase?: string
    indentificator?: string
    supportForm?: string
    supportInstructions?: string
    supportWorkingHours?: string
    supportMaximalResponseTime?: string
    supportFormOfContactOutsideWorkingHours?: string
    supportMaximalResponseTimeOutsideWorkingHours?: string
    supportType?: string
    supportEscalationDescription?: string
    supportInfo?: string
    providerIsvs?: ApiSlaContractReadIsvsInfo
    consumerIsvs?: ApiSlaContractReadIsvsInfo
    providerIsvsMainPerson?: ApiSlaContractReadMainPersonInfo
    consumerIsvsMainPerson?: ApiSlaContractReadMainPersonInfo
    isLocked?: boolean
    slaParameters?: ApiSlaContractParameterRead[]
}

export interface ApiSlaContractReadList {
    results?: ApiSlaContractRead[]
    pagination?: PaginationResponseData
}

export interface ApiSlaParameterRead {
    uuid?: string
    slaParamTypeCode?: string
    value?: string
    valueType?: string
    penaltyDescription?: string
    serviceInfo?: ApiSlaParameterReadServiceInfo
    measurementMethod?: string
    evaluationMethod?: string
    note?: string
    isValid?: boolean
    owner?: string
}

export interface ApiSlaParameterReadList {
    results?: ApiSlaParameterRead[]
    pagination?: PaginationResponseData
}

export type AsyncTaskDtoState = (typeof AsyncTaskDtoState)[keyof typeof AsyncTaskDtoState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AsyncTaskDtoState = {
    CREATED: 'CREATED',
    IN_PROGRESS: 'IN_PROGRESS',
    DONE: 'DONE',
    CANCELLED: 'CANCELLED',
    FAILED: 'FAILED',
} as const

export interface AsyncTaskDto {
    uuid?: string
    state?: AsyncTaskDtoState
    startAt?: string
    finishedAt?: string
    exception?: string
    processedItems?: number
}

export interface ApiActiveMonitoringReport {
    code?: string
    name?: string
    owner?: string
    cfgUrl?: string
    avaliability?: boolean
    lastCheck?: string
    projectCode?: string
    projectName?: string
    isvsCode?: string
    isvsName?: string
    externalIntegration?: string
    poOperator?: string
    entityIsvsType?: string
}

export interface ApiActiveMonitoringReportList {
    results?: ApiActiveMonitoringReport[]
    pagination?: PaginationResponseData
}

export interface ActiveMonitoringReportRequest {
    type?: string
    filtered?: string[]
    page?: number
    perPage?: number
}

export interface EnumItem {
    id?: number
    code?: string
    value?: string
    valid?: boolean
    description?: string
    orderList?: number
    engValue?: string
    engDescription?: string
}

export interface CurrentSystemState {
    id?: number
    createdAt?: string
    text?: string
    systemState?: EnumItem
    systemStateColor?: EnumItem
}

export interface CurrentSystemStateIn {
    text?: string
    systemState?: string
    systemStateColor?: string
}

export interface MainAttributes {
    uuid?: string
    name?: string
}

export interface ApiOlaContractISVS {
    uuid?: string
}

export interface ApiOlaContractData {
    uuid?: string
    state?: string
    name?: string
    nameEnglish?: string
    description?: string
    descriptionEnglish?: string
    note?: string
    source?: string
    code?: string
    referencingIdentifier?: string
    ciType?: string
    owner?: string
    contractCode?: string
    validityStartDate?: string
    validityEndDate?: string
    /** @deprecated */
    contractorIsvsUuid?: string
    contractorIsvsUuids?: ApiOlaContractISVS[]
    documentName?: string
    crzLink?: string
    profilState?: string
    vendorLock?: boolean
    administratorIsvs?: MainAttributes[]
}

export interface RequestIdUi {
    requestId?: string
}

export type ApiImportMonitoringReportsChannel = (typeof ApiImportMonitoringReportsChannel)[keyof typeof ApiImportMonitoringReportsChannel]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApiImportMonitoringReportsChannel = {
    FORMUAR: 'FORMUAR',
    UPLOAD: 'UPLOAD',
    EDESK: 'EDESK',
    WEBSERVICE: 'WEBSERVICE',
    ACTIVE_MONITORING: 'ACTIVE_MONITORING',
} as const

export interface ApiImportMonitoringReport {
    name?: string
    report?: string[]
}

export interface ApiImportMonitoringReports {
    reports?: ApiImportMonitoringReport[]
    channel?: ApiImportMonitoringReportsChannel
}

export interface XmlImportResult {
    reportName?: string
    errMessages?: string[]
    imported?: boolean
}

export interface PoItemDto {
    uuid?: string
    name?: string
}

export interface ListMonitoredIsvsRequest {
    serviceType?: string
    pagination?: Pagination
}

export interface ListMultipleServiceParameterValuesRequest {
    entityIds?: string[]
    intervalStart?: string
    intervalEnd?: string
    deleted?: boolean
}

export type SortingDirection = (typeof SortingDirection)[keyof typeof SortingDirection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SortingDirection = {
    ASC: 'ASC',
    DESC: 'DESC',
} as const

export interface Sorting {
    sortAttr?: string
    direction?: SortingDirection
}

export interface Pagination {
    page?: number
    perPageSize?: number
}

export interface ListMonitoringOverviewRequest {
    entityRef?: string
    serviceTypes?: string[]
    intervalStart?: string
    intervalEnd?: string
    liableEntity?: string
    isvsUuid?: string
    serviceName?: string
    metaisCode?: string
    includePoName?: boolean
    pagination?: Pagination
    sorting?: Sorting
}

export interface ListMonitoredValuesRequest {
    parameterTypeId?: number
    dateFrom?: string
    dateTo?: string
    pagination?: Pagination
    sorting?: Sorting
}

export interface MonitoredExportValue {
    poCode?: string
    projectCode?: string
    isvsCode?: string
    serviceType?: string
    serviceCode?: string
    serviceId?: string
    serviceExtIntegration?: string
    parameterType?: ApiParameterType
    value?: number
}

export interface MonitoredExportList {
    results?: MonitoredExportValue[]
}

export interface MonitoredExportRequest {
    project?: string
    isvs?: string
    serviceType?: string
    services?: string[]
    intervalStart?: string
    intervalEnd?: string
}

export type MonitorErrorType = (typeof MonitorErrorType)[keyof typeof MonitorErrorType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MonitorErrorType = {
    UN_KNOW_SERVICE_TYPE: 'UN_KNOW_SERVICE_TYPE',
    UN_KNOW_SERVICE: 'UN_KNOW_SERVICE',
    UN_KNOW_PARAMETER: 'UN_KNOW_PARAMETER',
    UN_KNOW_START_DATE: 'UN_KNOW_START_DATE',
    UN_KNOW_END_DATE: 'UN_KNOW_END_DATE',
    END_DATE_BEFORE_START: 'END_DATE_BEFORE_START',
    NOT_NUMBER: 'NOT_NUMBER',
    NEGATIVE_NUMBER: 'NEGATIVE_NUMBER',
    MISSING_VALUES: 'MISSING_VALUES',
    RIGHTS_ERROR: 'RIGHTS_ERROR',
    FILE_EMPTY: 'FILE_EMPTY',
    FILE_ERROR: 'FILE_ERROR',
} as const

export interface MonitorError {
    type?: MonitorErrorType
    value?: string
    line?: number
}

export interface ImportMonitoringResult {
    reportName?: string
    numberOfItems?: number
    createTime?: string
    errMessages?: MonitorError[]
    ok?: boolean
}

export interface XmlValidationResult {
    reportName?: string
    isvs?: string
    numberOfItems?: number
    createTime?: string
    errMessages?: string[]
    ok?: boolean
}

export interface ServiceDataHolder {
    serviceUuid?: string
    serviceName?: string
    serviceSlaUuid?: string
    serviceSlaValue?: string
    serviceSlaValueType?: string
}

export interface ApiSlaParameterWriteExtended {
    uuid?: string
    slaParamTypeCode?: string
    value?: string
    valueType?: string
    penaltyDescription?: string
    services?: ApiServiceData[]
    measurementMethod?: string
    evaluationMethod?: string
    note?: string
    isValid?: boolean
    servicesWithSla?: ServiceDataHolder[]
    overwriteSla?: boolean
}

export type ApiActiveMonitoringCfgHttpMethod = (typeof ApiActiveMonitoringCfgHttpMethod)[keyof typeof ApiActiveMonitoringCfgHttpMethod]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApiActiveMonitoringCfgHttpMethod = {
    POST: 'POST',
    GET: 'GET',
} as const

export interface ApiActiveMonitoringCfg {
    id?: number
    isvsUuid?: string
    entityType?: string
    isvsName?: string
    httpUrl?: string
    httpMethod?: ApiActiveMonitoringCfgHttpMethod
    httpRequestHeader?: string[][]
    httpRequestBody?: string
    httpResponseStatus?: string
    httpResponseBodyRegex?: string
    createdAt?: string
    lastExecutionTime?: string
    periodicity?: string
    enabled?: boolean
}

export interface PaginationResponseData {
    page?: number
    perPage?: number
    totalPages?: number
    totalItems?: number
}

export interface MonitoredItemDto {
    id?: number
    entityId?: string
    entityType?: string
    entityName?: string
    entityNameEng?: string
    metaisCode?: string
    lastValueDate?: string
    valuesDateFrom?: string
    valuesDateTo?: string
    poUuid?: string
    poName?: string
}

export interface ListMonitoredIsvsResponse {
    isvs?: MonitoredItemDto[]
    po?: PoItemDto[]
    pagination?: PaginationResponseData
}

export interface MonitoredItemListDto {
    items?: MonitoredItemDto[]
    pagination?: PaginationResponseData
}

export interface MetaData {
    createdUsingChannel?: string
    createdBy?: string
    createdAt?: string
    lastModifiedBy?: string
    lastModifiedAt?: string
}

export interface NewMonitoredValueDto {
    id?: number
    parameterTypeId?: number
    monitoredItemUuid?: string
    value?: string
    from?: string
    to?: string
}

export interface AddMonitoredValueRequest {
    values?: NewMonitoredValueDto[]
}

export interface ApiParameterTypeValueConstraint {
    minValue?: number
    maxValue?: number
    regularExpression?: string
    enumCode?: string
}

export interface ApiParameterTypeMetaData {
    createdBy?: string
    createdAt?: string
    lastModifiedBy?: string
    lastModifiedAt?: string
}

export type ApiParameterTypeValueType = (typeof ApiParameterTypeValueType)[keyof typeof ApiParameterTypeValueType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApiParameterTypeValueType = {
    BOOLEAN: 'BOOLEAN',
    NUMBER: 'NUMBER',
    STRING: 'STRING',
    ENUM: 'ENUM',
} as const

export interface ApiInterval {
    start?: string
    end?: string
}

export interface ApiParameterType {
    id?: number
    code?: string
    name?: string
    description?: string
    category?: string
    unit?: string
    valueType?: ApiParameterTypeValueType
    validity?: ApiInterval
    constraint?: ApiParameterTypeValueConstraint
    metaData?: ApiParameterTypeMetaData
    required?: boolean
}

export interface MonitoredValueDto {
    id?: number
    parameterType?: ApiParameterType
    monitoredItem?: MonitoredItemDto
    value?: string
    from?: string
    to?: string
    metaData?: MetaData
    deleted?: boolean
}

export interface MonitoredValueListDto {
    results?: MonitoredValueDto[]
    pagination?: PaginationResponseData
}

export interface ApiServiceData {
    uuid?: string
    owner?: string
}

export interface ApiSlaContractParameterWrite {
    uuid?: string
    slaParamTypeCode?: string
    value?: string
    valueType?: string
    penaltyDescription?: string
    services?: ApiServiceData[]
    measurementMethod?: string
    evaluationMethod?: string
    note?: string
    isValid?: boolean
}

export interface ApiSlaContractWrite {
    uuid?: string
    state?: string
    name?: string
    nameEnglish?: string
    description?: string
    descriptionEnglish?: string
    note?: string
    source?: string
    code?: string
    referencingIdentifier?: string
    ciType?: string
    owner?: string
    type?: string
    validityStartDate?: string
    validityEndDate?: string
    subject?: string
    phase?: string
    indentificator?: string
    supportEscalationDescription?: string
    supportForm?: string
    supportInstructions?: string
    supportWorkingHours?: string
    supportMaximalResponseTime?: string
    supportFormOfContactOutsideWorkingHours?: string
    supportMaximalResponseTimeOutsideWorkingHours?: string
    supportType?: string
    supportInfo?: string
    providerServiceUuid?: string
    consumerServiceUuid?: string
    slaParameters?: ApiSlaContractParameterWrite[]
}

export interface ApiSlaParameterWrite {
    uuid?: string
    slaParamTypeCode?: string
    value?: string
    valueType?: string
    penaltyDescription?: string
    services?: ApiServiceData[]
    measurementMethod?: string
    evaluationMethod?: string
    note?: string
    isValid?: boolean
}

export interface ExportParameterValuesResult {
    zipFile?: string[]
}

export interface ExportParameterValuesFilter {
    entityReferenceType?: string
    entityReferenceId?: string
    intervalStart?: string
    intervalEnd?: string
}

export type ApiErrorData = { [key: string]: any }

export interface ApiError {
    type?: string
    message?: string
    data?: ApiErrorData
    logToken?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const useExportParameterValuesHook = () => {
    const exportParameterValues = useMonitoringSwaggerClient<ExportParameterValuesResult>()

    return (params: ExportParameterValuesParams, signal?: AbortSignal) => {
        return exportParameterValues({ url: `/param-value/exportParameterValues`, method: 'get', params, signal })
    }
}

export const getExportParameterValuesQueryKey = (params: ExportParameterValuesParams) =>
    [`/param-value/exportParameterValues`, ...(params ? [params] : [])] as const

export const useExportParameterValuesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useExportParameterValuesHook>>>, TError = ApiError>(
    params: ExportParameterValuesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useExportParameterValuesHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useExportParameterValuesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getExportParameterValuesQueryKey(params)

    const exportParameterValues = useExportParameterValuesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useExportParameterValuesHook>>>> = ({ signal }) =>
        exportParameterValues(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type ExportParameterValuesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExportParameterValuesHook>>>>
export type ExportParameterValuesQueryError = ApiError

export const useExportParameterValues = <TData = Awaited<ReturnType<ReturnType<typeof useExportParameterValuesHook>>>, TError = ApiError>(
    params: ExportParameterValuesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useExportParameterValuesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useExportParameterValuesQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useExportParameterValues1Hook = () => {
    const exportParameterValues1 = useMonitoringSwaggerClient<ExportParameterValuesResult>()

    return (exportParameterValuesFilter: ExportParameterValuesFilter) => {
        return exportParameterValues1({
            url: `/param-value/exportParameterValues`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: exportParameterValuesFilter,
        })
    }
}

export const useExportParameterValues1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportParameterValues1Hook>>>,
        TError,
        { data: ExportParameterValuesFilter },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportParameterValues1Hook>>>,
    TError,
    { data: ExportParameterValuesFilter },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const exportParameterValues1 = useExportParameterValues1Hook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExportParameterValues1Hook>>>,
        { data: ExportParameterValuesFilter }
    > = (props) => {
        const { data } = props ?? {}

        return exportParameterValues1(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ExportParameterValues1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExportParameterValues1Hook>>>>
export type ExportParameterValues1MutationBody = ExportParameterValuesFilter
export type ExportParameterValues1MutationError = ApiError

export const useExportParameterValues1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportParameterValues1Hook>>>,
        TError,
        { data: ExportParameterValuesFilter },
        TContext
    >
}) => {
    const mutationOptions = useExportParameterValues1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateSlaParamsHook = () => {
    const updateSlaParams = useMonitoringSwaggerClient<void>()

    return (apiSlaParameterWrite: ApiSlaParameterWrite[]) => {
        return updateSlaParams({
            url: `/slaparams/updateslaparams`,
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: apiSlaParameterWrite,
        })
    }
}

export const useUpdateSlaParamsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateSlaParamsHook>>>, TError, { data: ApiSlaParameterWrite[] }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateSlaParamsHook>>>, TError, { data: ApiSlaParameterWrite[] }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateSlaParams = useUpdateSlaParamsHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateSlaParamsHook>>>, { data: ApiSlaParameterWrite[] }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return updateSlaParams(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateSlaParamsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateSlaParamsHook>>>>
export type UpdateSlaParamsMutationBody = ApiSlaParameterWrite[]
export type UpdateSlaParamsMutationError = ApiError

export const useUpdateSlaParams = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateSlaParamsHook>>>, TError, { data: ApiSlaParameterWrite[] }, TContext>
}) => {
    const mutationOptions = useUpdateSlaParamsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateSlaContractHook = () => {
    const updateSlaContract = useMonitoringSwaggerClient<void>()

    return (apiSlaContractWrite: ApiSlaContractWrite) => {
        return updateSlaContract({
            url: `/slacontract/update`,
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: apiSlaContractWrite,
        })
    }
}

export const useUpdateSlaContractMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateSlaContractHook>>>, TError, { data: ApiSlaContractWrite }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateSlaContractHook>>>, TError, { data: ApiSlaContractWrite }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateSlaContract = useUpdateSlaContractHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateSlaContractHook>>>, { data: ApiSlaContractWrite }> = (props) => {
        const { data } = props ?? {}

        return updateSlaContract(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateSlaContractMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateSlaContractHook>>>>
export type UpdateSlaContractMutationBody = ApiSlaContractWrite
export type UpdateSlaContractMutationError = ApiError

export const useUpdateSlaContract = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateSlaContractHook>>>, TError, { data: ApiSlaContractWrite }, TContext>
}) => {
    const mutationOptions = useUpdateSlaContractMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetParameterTypeHook = () => {
    const getParameterType = useMonitoringSwaggerClient<ApiParameterType>()

    return (parameterTypeCode: string, signal?: AbortSignal) => {
        return getParameterType({ url: `/paramtypes/${parameterTypeCode}`, method: 'get', signal })
    }
}

export const getGetParameterTypeQueryKey = (parameterTypeCode: string) => [`/paramtypes/${parameterTypeCode}`] as const

export const useGetParameterTypeQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetParameterTypeHook>>>, TError = ApiError>(
    parameterTypeCode: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetParameterTypeHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetParameterTypeHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetParameterTypeQueryKey(parameterTypeCode)

    const getParameterType = useGetParameterTypeHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetParameterTypeHook>>>> = ({ signal }) =>
        getParameterType(parameterTypeCode, signal)

    return { queryKey, queryFn, enabled: !!parameterTypeCode, ...queryOptions }
}

export type GetParameterTypeQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetParameterTypeHook>>>>
export type GetParameterTypeQueryError = ApiError

export const useGetParameterType = <TData = Awaited<ReturnType<ReturnType<typeof useGetParameterTypeHook>>>, TError = ApiError>(
    parameterTypeCode: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetParameterTypeHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetParameterTypeQueryOptions(parameterTypeCode, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUpdateParameterTypeHook = () => {
    const updateParameterType = useMonitoringSwaggerClient<void>()

    return (parameterTypeCode: string, apiParameterType: ApiParameterType) => {
        return updateParameterType({
            url: `/paramtypes/${parameterTypeCode}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: apiParameterType,
        })
    }
}

export const useUpdateParameterTypeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateParameterTypeHook>>>,
        TError,
        { parameterTypeCode: string; data: ApiParameterType },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateParameterTypeHook>>>,
    TError,
    { parameterTypeCode: string; data: ApiParameterType },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateParameterType = useUpdateParameterTypeHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateParameterTypeHook>>>,
        { parameterTypeCode: string; data: ApiParameterType }
    > = (props) => {
        const { parameterTypeCode, data } = props ?? {}

        return updateParameterType(parameterTypeCode, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateParameterTypeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateParameterTypeHook>>>>
export type UpdateParameterTypeMutationBody = ApiParameterType
export type UpdateParameterTypeMutationError = ApiError

export const useUpdateParameterType = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateParameterTypeHook>>>,
        TError,
        { parameterTypeCode: string; data: ApiParameterType },
        TContext
    >
}) => {
    const mutationOptions = useUpdateParameterTypeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteParameterTypeHook = () => {
    const deleteParameterType = useMonitoringSwaggerClient<void>()

    return (parameterTypeCode: string) => {
        return deleteParameterType({ url: `/paramtypes/${parameterTypeCode}`, method: 'delete' })
    }
}

export const useDeleteParameterTypeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteParameterTypeHook>>>, TError, { parameterTypeCode: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteParameterTypeHook>>>, TError, { parameterTypeCode: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteParameterType = useDeleteParameterTypeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteParameterTypeHook>>>, { parameterTypeCode: string }> = (
        props,
    ) => {
        const { parameterTypeCode } = props ?? {}

        return deleteParameterType(parameterTypeCode)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteParameterTypeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteParameterTypeHook>>>>

export type DeleteParameterTypeMutationError = ApiError

export const useDeleteParameterType = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteParameterTypeHook>>>, TError, { parameterTypeCode: string }, TContext>
}) => {
    const mutationOptions = useDeleteParameterTypeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useAddMonitoredValueHook = () => {
    const addMonitoredValue = useMonitoringSwaggerClient<MonitoredValueListDto>()

    return (addMonitoredValueRequest: AddMonitoredValueRequest) => {
        return addMonitoredValue({
            url: `/param-value/values`,
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: addMonitoredValueRequest,
        })
    }
}

export const useAddMonitoredValueMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddMonitoredValueHook>>>,
        TError,
        { data: AddMonitoredValueRequest },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddMonitoredValueHook>>>, TError, { data: AddMonitoredValueRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const addMonitoredValue = useAddMonitoredValueHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAddMonitoredValueHook>>>, { data: AddMonitoredValueRequest }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return addMonitoredValue(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type AddMonitoredValueMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddMonitoredValueHook>>>>
export type AddMonitoredValueMutationBody = AddMonitoredValueRequest
export type AddMonitoredValueMutationError = ApiError

export const useAddMonitoredValue = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddMonitoredValueHook>>>,
        TError,
        { data: AddMonitoredValueRequest },
        TContext
    >
}) => {
    const mutationOptions = useAddMonitoredValueMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateHook = () => {
    const update = useMonitoringSwaggerClient<ApiActiveMonitoringCfg>()

    return (apiActiveMonitoringCfg: ApiActiveMonitoringCfg) => {
        return update({
            url: `/activeMonitoring/cfg/update`,
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: apiActiveMonitoringCfg,
        })
    }
}

export const useUpdateMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateHook>>>, TError, { data: ApiActiveMonitoringCfg }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateHook>>>, TError, { data: ApiActiveMonitoringCfg }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const update = useUpdateHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateHook>>>, { data: ApiActiveMonitoringCfg }> = (props) => {
        const { data } = props ?? {}

        return update(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateHook>>>>
export type UpdateMutationBody = ApiActiveMonitoringCfg
export type UpdateMutationError = ApiError

export const useUpdate = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateHook>>>, TError, { data: ApiActiveMonitoringCfg }, TContext>
}) => {
    const mutationOptions = useUpdateMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useMigrateMonitoringValuesHook = () => {
    const migrateMonitoringValues = useMonitoringSwaggerClient<string>()

    return () => {
        return migrateMonitoringValues({ url: `/utils/migrate-monitoring-values`, method: 'post' })
    }
}

export const useMigrateMonitoringValuesMutationOptions = <TError = ApiError, TVariables = void, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useMigrateMonitoringValuesHook>>>, TError, TVariables, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useMigrateMonitoringValuesHook>>>, TError, TVariables, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const migrateMonitoringValues = useMigrateMonitoringValuesHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useMigrateMonitoringValuesHook>>>, TVariables> = () => {
        return migrateMonitoringValues()
    }

    return { mutationFn, ...mutationOptions }
}

export type MigrateMonitoringValuesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useMigrateMonitoringValuesHook>>>>

export type MigrateMonitoringValuesMutationError = ApiError

export const useMigrateMonitoringValues = <TError = ApiError, TVariables = void, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useMigrateMonitoringValuesHook>>>, TError, TVariables, TContext>
}) => {
    const mutationOptions = useMigrateMonitoringValuesMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useSaveSlaParameterHook = () => {
    const saveSlaParameter = useMonitoringSwaggerClient<void>()

    return (apiSlaParameterWriteExtended: ApiSlaParameterWriteExtended) => {
        return saveSlaParameter({
            url: `/slaparams/saveslaparam`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: apiSlaParameterWriteExtended,
        })
    }
}

export const useSaveSlaParameterMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSaveSlaParameterHook>>>,
        TError,
        { data: ApiSlaParameterWriteExtended },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSaveSlaParameterHook>>>, TError, { data: ApiSlaParameterWriteExtended }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const saveSlaParameter = useSaveSlaParameterHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSaveSlaParameterHook>>>, { data: ApiSlaParameterWriteExtended }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return saveSlaParameter(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type SaveSlaParameterMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSaveSlaParameterHook>>>>
export type SaveSlaParameterMutationBody = ApiSlaParameterWriteExtended
export type SaveSlaParameterMutationError = ApiError

export const useSaveSlaParameter = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSaveSlaParameterHook>>>,
        TError,
        { data: ApiSlaParameterWriteExtended },
        TContext
    >
}) => {
    const mutationOptions = useSaveSlaParameterMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary checkSlaParameter
 */
export const useCheckSlaParameterHook = () => {
    const checkSlaParameter = useMonitoringSwaggerClient<ServiceDataHolder[]>()

    return (apiSlaParameterWrite: ApiSlaParameterWrite) => {
        return checkSlaParameter({
            url: `/slaparams/checkslaparam`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: apiSlaParameterWrite,
        })
    }
}

export const useCheckSlaParameterMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCheckSlaParameterHook>>>, TError, { data: ApiSlaParameterWrite }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCheckSlaParameterHook>>>, TError, { data: ApiSlaParameterWrite }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const checkSlaParameter = useCheckSlaParameterHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCheckSlaParameterHook>>>, { data: ApiSlaParameterWrite }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return checkSlaParameter(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CheckSlaParameterMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCheckSlaParameterHook>>>>
export type CheckSlaParameterMutationBody = ApiSlaParameterWrite
export type CheckSlaParameterMutationError = ApiError

/**
 * @summary checkSlaParameter
 */
export const useCheckSlaParameter = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCheckSlaParameterHook>>>, TError, { data: ApiSlaParameterWrite }, TContext>
}) => {
    const mutationOptions = useCheckSlaParameterMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useSlaContractTransitionHook = () => {
    const slaContractTransition = useMonitoringSwaggerClient<void>()

    return (slaContractUuid: string, transition: string) => {
        return slaContractTransition({ url: `/slacontract/${slaContractUuid}/transitions/${transition}`, method: 'post' })
    }
}

export const useSlaContractTransitionMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSlaContractTransitionHook>>>,
        TError,
        { slaContractUuid: string; transition: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSlaContractTransitionHook>>>,
    TError,
    { slaContractUuid: string; transition: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const slaContractTransition = useSlaContractTransitionHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useSlaContractTransitionHook>>>,
        { slaContractUuid: string; transition: string }
    > = (props) => {
        const { slaContractUuid, transition } = props ?? {}

        return slaContractTransition(slaContractUuid, transition)
    }

    return { mutationFn, ...mutationOptions }
}

export type SlaContractTransitionMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSlaContractTransitionHook>>>>

export type SlaContractTransitionMutationError = ApiError

export const useSlaContractTransition = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSlaContractTransitionHook>>>,
        TError,
        { slaContractUuid: string; transition: string },
        TContext
    >
}) => {
    const mutationOptions = useSlaContractTransitionMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useSaveSlaContractHook = () => {
    const saveSlaContract = useMonitoringSwaggerClient<void>()

    return (apiSlaContractWrite: ApiSlaContractWrite) => {
        return saveSlaContract({
            url: `/slacontract/save`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: apiSlaContractWrite,
        })
    }
}

export const useSaveSlaContractMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSaveSlaContractHook>>>, TError, { data: ApiSlaContractWrite }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSaveSlaContractHook>>>, TError, { data: ApiSlaContractWrite }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const saveSlaContract = useSaveSlaContractHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSaveSlaContractHook>>>, { data: ApiSlaContractWrite }> = (props) => {
        const { data } = props ?? {}

        return saveSlaContract(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type SaveSlaContractMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSaveSlaContractHook>>>>
export type SaveSlaContractMutationBody = ApiSlaContractWrite
export type SaveSlaContractMutationError = ApiError

export const useSaveSlaContract = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSaveSlaContractHook>>>, TError, { data: ApiSlaContractWrite }, TContext>
}) => {
    const mutationOptions = useSaveSlaContractMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useListParameterTypes1Hook = () => {
    const listParameterTypes1 = useMonitoringSwaggerClient<ApiParameterTypesList>()

    return (params?: ListParameterTypes1Params, signal?: AbortSignal) => {
        return listParameterTypes1({ url: `/paramtypes`, method: 'get', params, signal })
    }
}

export const getListParameterTypes1QueryKey = (params?: ListParameterTypes1Params) => [`/paramtypes`, ...(params ? [params] : [])] as const

export const useListParameterTypes1QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListParameterTypes1Hook>>>, TError = ApiError>(
    params?: ListParameterTypes1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListParameterTypes1Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListParameterTypes1Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListParameterTypes1QueryKey(params)

    const listParameterTypes1 = useListParameterTypes1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListParameterTypes1Hook>>>> = ({ signal }) =>
        listParameterTypes1(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type ListParameterTypes1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListParameterTypes1Hook>>>>
export type ListParameterTypes1QueryError = ApiError

export const useListParameterTypes1 = <TData = Awaited<ReturnType<ReturnType<typeof useListParameterTypes1Hook>>>, TError = ApiError>(
    params?: ListParameterTypes1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListParameterTypes1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListParameterTypes1QueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useAddParameterTypeHook = () => {
    const addParameterType = useMonitoringSwaggerClient<void>()

    return (apiParameterType: ApiParameterType) => {
        return addParameterType({ url: `/paramtypes`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: apiParameterType })
    }
}

export const useAddParameterTypeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddParameterTypeHook>>>, TError, { data: ApiParameterType }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddParameterTypeHook>>>, TError, { data: ApiParameterType }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const addParameterType = useAddParameterTypeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAddParameterTypeHook>>>, { data: ApiParameterType }> = (props) => {
        const { data } = props ?? {}

        return addParameterType(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type AddParameterTypeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddParameterTypeHook>>>>
export type AddParameterTypeMutationBody = ApiParameterType
export type AddParameterTypeMutationError = ApiError

export const useAddParameterType = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddParameterTypeHook>>>, TError, { data: ApiParameterType }, TContext>
}) => {
    const mutationOptions = useAddParameterTypeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useAddRelatedParameterTypeHook = () => {
    const addRelatedParameterType = useMonitoringSwaggerClient<void>()

    return (parameterTypeCode: string, relatedParameterTypeType: 'SLA' | 'MONITORING', relatedParameterTypeCode: string) => {
        return addRelatedParameterType({
            url: `/paramtypes/${parameterTypeCode}/related/${relatedParameterTypeType}/${relatedParameterTypeCode}`,
            method: 'post',
        })
    }
}

export const useAddRelatedParameterTypeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddRelatedParameterTypeHook>>>,
        TError,
        { parameterTypeCode: string; relatedParameterTypeType: 'SLA' | 'MONITORING'; relatedParameterTypeCode: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddRelatedParameterTypeHook>>>,
    TError,
    { parameterTypeCode: string; relatedParameterTypeType: 'SLA' | 'MONITORING'; relatedParameterTypeCode: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const addRelatedParameterType = useAddRelatedParameterTypeHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useAddRelatedParameterTypeHook>>>,
        { parameterTypeCode: string; relatedParameterTypeType: 'SLA' | 'MONITORING'; relatedParameterTypeCode: string }
    > = (props) => {
        const { parameterTypeCode, relatedParameterTypeType, relatedParameterTypeCode } = props ?? {}

        return addRelatedParameterType(parameterTypeCode, relatedParameterTypeType, relatedParameterTypeCode)
    }

    return { mutationFn, ...mutationOptions }
}

export type AddRelatedParameterTypeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddRelatedParameterTypeHook>>>>

export type AddRelatedParameterTypeMutationError = ApiError

export const useAddRelatedParameterType = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddRelatedParameterTypeHook>>>,
        TError,
        { parameterTypeCode: string; relatedParameterTypeType: 'SLA' | 'MONITORING'; relatedParameterTypeCode: string },
        TContext
    >
}) => {
    const mutationOptions = useAddRelatedParameterTypeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteRelatedParameterTypeHook = () => {
    const deleteRelatedParameterType = useMonitoringSwaggerClient<void>()

    return (parameterTypeCode: string, relatedParameterTypeType: 'SLA' | 'MONITORING', relatedParameterTypeCode: string) => {
        return deleteRelatedParameterType({
            url: `/paramtypes/${parameterTypeCode}/related/${relatedParameterTypeType}/${relatedParameterTypeCode}`,
            method: 'delete',
        })
    }
}

export const useDeleteRelatedParameterTypeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteRelatedParameterTypeHook>>>,
        TError,
        { parameterTypeCode: string; relatedParameterTypeType: 'SLA' | 'MONITORING'; relatedParameterTypeCode: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteRelatedParameterTypeHook>>>,
    TError,
    { parameterTypeCode: string; relatedParameterTypeType: 'SLA' | 'MONITORING'; relatedParameterTypeCode: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteRelatedParameterType = useDeleteRelatedParameterTypeHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useDeleteRelatedParameterTypeHook>>>,
        { parameterTypeCode: string; relatedParameterTypeType: 'SLA' | 'MONITORING'; relatedParameterTypeCode: string }
    > = (props) => {
        const { parameterTypeCode, relatedParameterTypeType, relatedParameterTypeCode } = props ?? {}

        return deleteRelatedParameterType(parameterTypeCode, relatedParameterTypeType, relatedParameterTypeCode)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteRelatedParameterTypeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteRelatedParameterTypeHook>>>>

export type DeleteRelatedParameterTypeMutationError = ApiError

export const useDeleteRelatedParameterType = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteRelatedParameterTypeHook>>>,
        TError,
        { parameterTypeCode: string; relatedParameterTypeType: 'SLA' | 'MONITORING'; relatedParameterTypeCode: string },
        TContext
    >
}) => {
    const mutationOptions = useDeleteRelatedParameterTypeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useEditMonitoredValueHook = () => {
    const editMonitoredValue = useMonitoringSwaggerClient<MonitoredValueDto>()

    return (id: number, newMonitoredValueDto: NewMonitoredValueDto) => {
        return editMonitoredValue({
            url: `/param-value/values/${id}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: newMonitoredValueDto,
        })
    }
}

export const useEditMonitoredValueMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useEditMonitoredValueHook>>>,
        TError,
        { id: number; data: NewMonitoredValueDto },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useEditMonitoredValueHook>>>,
    TError,
    { id: number; data: NewMonitoredValueDto },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const editMonitoredValue = useEditMonitoredValueHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useEditMonitoredValueHook>>>,
        { id: number; data: NewMonitoredValueDto }
    > = (props) => {
        const { id, data } = props ?? {}

        return editMonitoredValue(id, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type EditMonitoredValueMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useEditMonitoredValueHook>>>>
export type EditMonitoredValueMutationBody = NewMonitoredValueDto
export type EditMonitoredValueMutationError = ApiError

export const useEditMonitoredValue = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useEditMonitoredValueHook>>>,
        TError,
        { id: number; data: NewMonitoredValueDto },
        TContext
    >
}) => {
    const mutationOptions = useEditMonitoredValueMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteMonitoredValueHook = () => {
    const deleteMonitoredValue = useMonitoringSwaggerClient<void>()

    return (id: number) => {
        return deleteMonitoredValue({ url: `/param-value/values/${id}`, method: 'delete' })
    }
}

export const useDeleteMonitoredValueMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteMonitoredValueHook>>>, TError, { id: number }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteMonitoredValueHook>>>, TError, { id: number }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteMonitoredValue = useDeleteMonitoredValueHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteMonitoredValueHook>>>, { id: number }> = (props) => {
        const { id } = props ?? {}

        return deleteMonitoredValue(id)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteMonitoredValueMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteMonitoredValueHook>>>>

export type DeleteMonitoredValueMutationError = ApiError

export const useDeleteMonitoredValue = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteMonitoredValueHook>>>, TError, { id: number }, TContext>
}) => {
    const mutationOptions = useDeleteMonitoredValueMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary validateReport
 */
export const useValidateReportHook = () => {
    const validateReport = useMonitoringSwaggerClient<XmlValidationResult>()

    return (validateReportBody: ValidateReportBody) => {
        return validateReport({
            url: `/param-value/validateReport`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: validateReportBody,
        })
    }
}

export const useValidateReportMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useValidateReportHook>>>, TError, { data: ValidateReportBody }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useValidateReportHook>>>, TError, { data: ValidateReportBody }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const validateReport = useValidateReportHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useValidateReportHook>>>, { data: ValidateReportBody }> = (props) => {
        const { data } = props ?? {}

        return validateReport(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ValidateReportMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useValidateReportHook>>>>
export type ValidateReportMutationBody = ValidateReportBody
export type ValidateReportMutationError = ApiError

/**
 * @summary validateReport
 */
export const useValidateReport = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useValidateReportHook>>>, TError, { data: ValidateReportBody }, TContext>
}) => {
    const mutationOptions = useValidateReportMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useValidateMonitorParametersHook = () => {
    const validateMonitorParameters = useMonitoringSwaggerClient<ImportMonitoringResult>()

    return (validateMonitorParametersBody: ValidateMonitorParametersBody) => {
        return validateMonitorParameters({
            url: `/param-value/v2/validateReport`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: validateMonitorParametersBody,
        })
    }
}

export const useValidateMonitorParametersMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useValidateMonitorParametersHook>>>,
        TError,
        { data: ValidateMonitorParametersBody },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useValidateMonitorParametersHook>>>,
    TError,
    { data: ValidateMonitorParametersBody },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const validateMonitorParameters = useValidateMonitorParametersHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useValidateMonitorParametersHook>>>,
        { data: ValidateMonitorParametersBody }
    > = (props) => {
        const { data } = props ?? {}

        return validateMonitorParameters(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ValidateMonitorParametersMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useValidateMonitorParametersHook>>>>
export type ValidateMonitorParametersMutationBody = ValidateMonitorParametersBody
export type ValidateMonitorParametersMutationError = ApiError

export const useValidateMonitorParameters = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useValidateMonitorParametersHook>>>,
        TError,
        { data: ValidateMonitorParametersBody },
        TContext
    >
}) => {
    const mutationOptions = useValidateMonitorParametersMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useImportMonitorParametersHook = () => {
    const importMonitorParameters = useMonitoringSwaggerClient<ImportMonitoringResult>()

    return (importMonitorParametersBody: ImportMonitorParametersBody) => {
        return importMonitorParameters({
            url: `/param-value/v2/importReport`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: importMonitorParametersBody,
        })
    }
}

export const useImportMonitorParametersMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useImportMonitorParametersHook>>>,
        TError,
        { data: ImportMonitorParametersBody },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useImportMonitorParametersHook>>>,
    TError,
    { data: ImportMonitorParametersBody },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const importMonitorParameters = useImportMonitorParametersHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useImportMonitorParametersHook>>>,
        { data: ImportMonitorParametersBody }
    > = (props) => {
        const { data } = props ?? {}

        return importMonitorParameters(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ImportMonitorParametersMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useImportMonitorParametersHook>>>>
export type ImportMonitorParametersMutationBody = ImportMonitorParametersBody
export type ImportMonitorParametersMutationError = ApiError

export const useImportMonitorParameters = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useImportMonitorParametersHook>>>,
        TError,
        { data: ImportMonitorParametersBody },
        TContext
    >
}) => {
    const mutationOptions = useImportMonitorParametersMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary listMultipleServiceParameterExportValues
 */
export const useListMultipleServiceParameterExportValuesHook = () => {
    const listMultipleServiceParameterExportValues = useMonitoringSwaggerClient<MonitoredExportList>()

    return (monitoredExportRequest: MonitoredExportRequest) => {
        return listMultipleServiceParameterExportValues({
            url: `/param-value/list-multiple-export`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: monitoredExportRequest,
        })
    }
}

export const useListMultipleServiceParameterExportValuesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useListMultipleServiceParameterExportValuesHook>>>,
        TError,
        { data: MonitoredExportRequest },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useListMultipleServiceParameterExportValuesHook>>>,
    TError,
    { data: MonitoredExportRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const listMultipleServiceParameterExportValues = useListMultipleServiceParameterExportValuesHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useListMultipleServiceParameterExportValuesHook>>>,
        { data: MonitoredExportRequest }
    > = (props) => {
        const { data } = props ?? {}

        return listMultipleServiceParameterExportValues(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ListMultipleServiceParameterExportValuesMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useListMultipleServiceParameterExportValuesHook>>>
>
export type ListMultipleServiceParameterExportValuesMutationBody = MonitoredExportRequest
export type ListMultipleServiceParameterExportValuesMutationError = ApiError

/**
 * @summary listMultipleServiceParameterExportValues
 */
export const useListMultipleServiceParameterExportValues = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useListMultipleServiceParameterExportValuesHook>>>,
        TError,
        { data: MonitoredExportRequest },
        TContext
    >
}) => {
    const mutationOptions = useListMultipleServiceParameterExportValuesMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useListMonitoredValuesHook = () => {
    const listMonitoredValues = useMonitoringSwaggerClient<MonitoredValueListDto>()

    return (monitoredEntityId: number, listMonitoredValuesRequest: ListMonitoredValuesRequest) => {
        return listMonitoredValues({
            url: `/param-value/items/${monitoredEntityId}/values`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: listMonitoredValuesRequest,
        })
    }
}

export const useListMonitoredValuesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useListMonitoredValuesHook>>>,
        TError,
        { monitoredEntityId: number; data: ListMonitoredValuesRequest },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useListMonitoredValuesHook>>>,
    TError,
    { monitoredEntityId: number; data: ListMonitoredValuesRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const listMonitoredValues = useListMonitoredValuesHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useListMonitoredValuesHook>>>,
        { monitoredEntityId: number; data: ListMonitoredValuesRequest }
    > = (props) => {
        const { monitoredEntityId, data } = props ?? {}

        return listMonitoredValues(monitoredEntityId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ListMonitoredValuesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListMonitoredValuesHook>>>>
export type ListMonitoredValuesMutationBody = ListMonitoredValuesRequest
export type ListMonitoredValuesMutationError = ApiError

export const useListMonitoredValues = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useListMonitoredValuesHook>>>,
        TError,
        { monitoredEntityId: number; data: ListMonitoredValuesRequest },
        TContext
    >
}) => {
    const mutationOptions = useListMonitoredValuesMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary listMultipleServiceParameterValues
 */
export const useListMultipleServiceParameterValuesHook = () => {
    const listMultipleServiceParameterValues = useMonitoringSwaggerClient<MonitoredValueListDto>()

    return (listMultipleServiceParameterValuesRequest: ListMultipleServiceParameterValuesRequest) => {
        return listMultipleServiceParameterValues({
            url: `/param-value/items/values`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: listMultipleServiceParameterValuesRequest,
        })
    }
}

export const useListMultipleServiceParameterValuesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useListMultipleServiceParameterValuesHook>>>,
        TError,
        { data: ListMultipleServiceParameterValuesRequest },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useListMultipleServiceParameterValuesHook>>>,
    TError,
    { data: ListMultipleServiceParameterValuesRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const listMultipleServiceParameterValues = useListMultipleServiceParameterValuesHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useListMultipleServiceParameterValuesHook>>>,
        { data: ListMultipleServiceParameterValuesRequest }
    > = (props) => {
        const { data } = props ?? {}

        return listMultipleServiceParameterValues(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ListMultipleServiceParameterValuesMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useListMultipleServiceParameterValuesHook>>>
>
export type ListMultipleServiceParameterValuesMutationBody = ListMultipleServiceParameterValuesRequest
export type ListMultipleServiceParameterValuesMutationError = ApiError

/**
 * @summary listMultipleServiceParameterValues
 */
export const useListMultipleServiceParameterValues = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useListMultipleServiceParameterValuesHook>>>,
        TError,
        { data: ListMultipleServiceParameterValuesRequest },
        TContext
    >
}) => {
    const mutationOptions = useListMultipleServiceParameterValuesMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useListMonitoredItemsHook = () => {
    const listMonitoredItems = useMonitoringSwaggerClient<MonitoredItemListDto>()

    return (listMonitoringOverviewRequest: ListMonitoringOverviewRequest) => {
        return listMonitoredItems({
            url: `/param-value/items/list`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: listMonitoringOverviewRequest,
        })
    }
}

export const getListMonitoredItemsQueryKey = (listMonitoringOverviewRequest: ListMonitoringOverviewRequest) =>
    [`/param-value/items/list`, listMonitoringOverviewRequest] as const

export const useListMonitoredItemsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListMonitoredItemsHook>>>, TError = ApiError>(
    listMonitoringOverviewRequest: ListMonitoringOverviewRequest,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListMonitoredItemsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListMonitoredItemsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListMonitoredItemsQueryKey(listMonitoringOverviewRequest)

    const listMonitoredItems = useListMonitoredItemsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListMonitoredItemsHook>>>> = () =>
        listMonitoredItems(listMonitoringOverviewRequest)

    return { queryKey, queryFn, ...queryOptions }
}

export type ListMonitoredItemsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListMonitoredItemsHook>>>>
export type ListMonitoredItemsQueryError = ApiError

export const useListMonitoredItems = <TData = Awaited<ReturnType<ReturnType<typeof useListMonitoredItemsHook>>>, TError = ApiError>(
    listMonitoringOverviewRequest: ListMonitoringOverviewRequest,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListMonitoredItemsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListMonitoredItemsQueryOptions(listMonitoringOverviewRequest, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useListMonitoredIsvsHook = () => {
    const listMonitoredIsvs = useMonitoringSwaggerClient<ListMonitoredIsvsResponse>()

    return (listMonitoredIsvsRequest: ListMonitoredIsvsRequest) => {
        return listMonitoredIsvs({
            url: `/param-value/isvs/list`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: listMonitoredIsvsRequest,
        })
    }
}

export const getListMonitoredIsvsQueryKey = (listMonitoredIsvsRequest: ListMonitoredIsvsRequest) =>
    [`/param-value/isvs/list`, listMonitoredIsvsRequest] as const

export const useListMonitoredIsvsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListMonitoredIsvsHook>>>, TError = ApiError>(
    listMonitoredIsvsRequest: ListMonitoredIsvsRequest,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListMonitoredIsvsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListMonitoredIsvsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListMonitoredIsvsQueryKey(listMonitoredIsvsRequest)

    const listMonitoredIsvs = useListMonitoredIsvsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListMonitoredIsvsHook>>>> = () => listMonitoredIsvs(listMonitoredIsvsRequest)

    return { queryKey, queryFn, ...queryOptions }
}

export type ListMonitoredIsvsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListMonitoredIsvsHook>>>>
export type ListMonitoredIsvsQueryError = ApiError

export const useListMonitoredIsvs = <TData = Awaited<ReturnType<ReturnType<typeof useListMonitoredIsvsHook>>>, TError = ApiError>(
    listMonitoredIsvsRequest: ListMonitoredIsvsRequest,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListMonitoredIsvsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListMonitoredIsvsQueryOptions(listMonitoredIsvsRequest, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useImportReportHook = () => {
    const importReport = useMonitoringSwaggerClient<XmlImportResult>()

    return (importReportBody: ImportReportBody) => {
        return importReport({
            url: `/param-value/importReport`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: importReportBody,
        })
    }
}

export const useImportReportMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useImportReportHook>>>, TError, { data: ImportReportBody }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useImportReportHook>>>, TError, { data: ImportReportBody }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const importReport = useImportReportHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useImportReportHook>>>, { data: ImportReportBody }> = (props) => {
        const { data } = props ?? {}

        return importReport(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ImportReportMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useImportReportHook>>>>
export type ImportReportMutationBody = ImportReportBody
export type ImportReportMutationError = ApiError

export const useImportReport = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useImportReportHook>>>, TError, { data: ImportReportBody }, TContext>
}) => {
    const mutationOptions = useImportReportMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useImportReport2Hook = () => {
    const importReport2 = useMonitoringSwaggerClient<XmlImportResult[]>()

    return (apiImportMonitoringReports: ApiImportMonitoringReports) => {
        return importReport2({
            url: `/param-value/importReport2`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: apiImportMonitoringReports,
        })
    }
}

export const useImportReport2MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useImportReport2Hook>>>,
        TError,
        { data: ApiImportMonitoringReports },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useImportReport2Hook>>>, TError, { data: ApiImportMonitoringReports }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const importReport2 = useImportReport2Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useImportReport2Hook>>>, { data: ApiImportMonitoringReports }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return importReport2(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ImportReport2MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useImportReport2Hook>>>>
export type ImportReport2MutationBody = ApiImportMonitoringReports
export type ImportReport2MutationError = ApiError

export const useImportReport2 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useImportReport2Hook>>>,
        TError,
        { data: ApiImportMonitoringReports },
        TContext
    >
}) => {
    const mutationOptions = useImportReport2MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useOlaContractTransitionHook = () => {
    const olaContractTransition = useMonitoringSwaggerClient<RequestIdUi>()

    return (olaContractUuid: string, transition: string) => {
        return olaContractTransition({ url: `/olacontract/${olaContractUuid}/transitions/${transition}`, method: 'post' })
    }
}

export const useOlaContractTransitionMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useOlaContractTransitionHook>>>,
        TError,
        { olaContractUuid: string; transition: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useOlaContractTransitionHook>>>,
    TError,
    { olaContractUuid: string; transition: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const olaContractTransition = useOlaContractTransitionHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useOlaContractTransitionHook>>>,
        { olaContractUuid: string; transition: string }
    > = (props) => {
        const { olaContractUuid, transition } = props ?? {}

        return olaContractTransition(olaContractUuid, transition)
    }

    return { mutationFn, ...mutationOptions }
}

export type OlaContractTransitionMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useOlaContractTransitionHook>>>>

export type OlaContractTransitionMutationError = ApiError

export const useOlaContractTransition = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useOlaContractTransitionHook>>>,
        TError,
        { olaContractUuid: string; transition: string },
        TContext
    >
}) => {
    const mutationOptions = useOlaContractTransitionMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateOlaContractHook = () => {
    const updateOlaContract = useMonitoringSwaggerClient<RequestIdUi>()

    return (apiOlaContractData: ApiOlaContractData) => {
        return updateOlaContract({
            url: `/olacontract/update`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: apiOlaContractData,
        })
    }
}

export const useUpdateOlaContractMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOlaContractHook>>>, TError, { data: ApiOlaContractData }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOlaContractHook>>>, TError, { data: ApiOlaContractData }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateOlaContract = useUpdateOlaContractHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateOlaContractHook>>>, { data: ApiOlaContractData }> = (props) => {
        const { data } = props ?? {}

        return updateOlaContract(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateOlaContractMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateOlaContractHook>>>>
export type UpdateOlaContractMutationBody = ApiOlaContractData
export type UpdateOlaContractMutationError = ApiError

export const useUpdateOlaContract = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOlaContractHook>>>, TError, { data: ApiOlaContractData }, TContext>
}) => {
    const mutationOptions = useUpdateOlaContractMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useSaveOlaContractHook = () => {
    const saveOlaContract = useMonitoringSwaggerClient<RequestIdUi>()

    return (apiOlaContractData: ApiOlaContractData) => {
        return saveOlaContract({
            url: `/olacontract/save`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: apiOlaContractData,
        })
    }
}

export const useSaveOlaContractMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSaveOlaContractHook>>>, TError, { data: ApiOlaContractData }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSaveOlaContractHook>>>, TError, { data: ApiOlaContractData }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const saveOlaContract = useSaveOlaContractHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSaveOlaContractHook>>>, { data: ApiOlaContractData }> = (props) => {
        const { data } = props ?? {}

        return saveOlaContract(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type SaveOlaContractMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSaveOlaContractHook>>>>
export type SaveOlaContractMutationBody = ApiOlaContractData
export type SaveOlaContractMutationError = ApiError

export const useSaveOlaContract = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSaveOlaContractHook>>>, TError, { data: ApiOlaContractData }, TContext>
}) => {
    const mutationOptions = useSaveOlaContractMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetCurrentSystemStateHook = () => {
    const getCurrentSystemState = useMonitoringSwaggerClient<CurrentSystemState>()

    return (signal?: AbortSignal) => {
        return getCurrentSystemState({ url: `/current-system-state`, method: 'get', signal })
    }
}

export const getGetCurrentSystemStateQueryKey = () => [`/current-system-state`] as const

export const useGetCurrentSystemStateQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetCurrentSystemStateHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCurrentSystemStateHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCurrentSystemStateHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetCurrentSystemStateQueryKey()

    const getCurrentSystemState = useGetCurrentSystemStateHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCurrentSystemStateHook>>>> = ({ signal }) => getCurrentSystemState(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetCurrentSystemStateQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCurrentSystemStateHook>>>>
export type GetCurrentSystemStateQueryError = ApiError

export const useGetCurrentSystemState = <TData = Awaited<ReturnType<ReturnType<typeof useGetCurrentSystemStateHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCurrentSystemStateHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetCurrentSystemStateQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useSetCurrentSystemStateHook = () => {
    const setCurrentSystemState = useMonitoringSwaggerClient<CurrentSystemState>()

    return (currentSystemStateIn: CurrentSystemStateIn) => {
        return setCurrentSystemState({
            url: `/current-system-state`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: currentSystemStateIn,
        })
    }
}

export const useSetCurrentSystemStateMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSetCurrentSystemStateHook>>>,
        TError,
        { data: CurrentSystemStateIn },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useSetCurrentSystemStateHook>>>, TError, { data: CurrentSystemStateIn }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const setCurrentSystemState = useSetCurrentSystemStateHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useSetCurrentSystemStateHook>>>, { data: CurrentSystemStateIn }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return setCurrentSystemState(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type SetCurrentSystemStateMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSetCurrentSystemStateHook>>>>
export type SetCurrentSystemStateMutationBody = CurrentSystemStateIn
export type SetCurrentSystemStateMutationError = ApiError

export const useSetCurrentSystemState = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useSetCurrentSystemStateHook>>>,
        TError,
        { data: CurrentSystemStateIn },
        TContext
    >
}) => {
    const mutationOptions = useSetCurrentSystemStateMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useClearCurrentSystemStateHook = () => {
    const clearCurrentSystemState = useMonitoringSwaggerClient<CurrentSystemState>()

    return () => {
        return clearCurrentSystemState({ url: `/current-system-state`, method: 'delete' })
    }
}

export const useClearCurrentSystemStateMutationOptions = <TError = ApiError, TVariables = void, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearCurrentSystemStateHook>>>, TError, TVariables, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearCurrentSystemStateHook>>>, TError, TVariables, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const clearCurrentSystemState = useClearCurrentSystemStateHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useClearCurrentSystemStateHook>>>, TVariables> = () => {
        return clearCurrentSystemState()
    }

    return { mutationFn, ...mutationOptions }
}

export type ClearCurrentSystemStateMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useClearCurrentSystemStateHook>>>>

export type ClearCurrentSystemStateMutationError = ApiError

export const useClearCurrentSystemState = <TError = ApiError, TVariables = void, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useClearCurrentSystemStateHook>>>, TError, TVariables, TContext>
}) => {
    const mutationOptions = useClearCurrentSystemStateMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary report
 */
export const useReportHook = () => {
    const report = useMonitoringSwaggerClient<ApiActiveMonitoringReportList>()

    return (activeMonitoringReportRequest: ActiveMonitoringReportRequest) => {
        return report({
            url: `/activeMonitoring/cfg/report`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: activeMonitoringReportRequest,
        })
    }
}

export const useReportMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReportHook>>>, TError, { data: ActiveMonitoringReportRequest }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReportHook>>>, TError, { data: ActiveMonitoringReportRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const report = useReportHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useReportHook>>>, { data: ActiveMonitoringReportRequest }> = (props) => {
        const { data } = props ?? {}

        return report(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ReportMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReportHook>>>>
export type ReportMutationBody = ActiveMonitoringReportRequest
export type ReportMutationError = ApiError

/**
 * @summary report
 */
export const useReport = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useReportHook>>>, TError, { data: ActiveMonitoringReportRequest }, TContext>
}) => {
    const mutationOptions = useReportMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useCreateHook = () => {
    const create = useMonitoringSwaggerClient<ApiActiveMonitoringCfg>()

    return (apiActiveMonitoringCfg: ApiActiveMonitoringCfg) => {
        return create({
            url: `/activeMonitoring/cfg/create`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: apiActiveMonitoringCfg,
        })
    }
}

export const useCreateMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateHook>>>, TError, { data: ApiActiveMonitoringCfg }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateHook>>>, TError, { data: ApiActiveMonitoringCfg }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const create = useCreateHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCreateHook>>>, { data: ApiActiveMonitoringCfg }> = (props) => {
        const { data } = props ?? {}

        return create(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CreateMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateHook>>>>
export type CreateMutationBody = ApiActiveMonitoringCfg
export type CreateMutationError = ApiError

export const useCreate = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCreateHook>>>, TError, { data: ApiActiveMonitoringCfg }, TContext>
}) => {
    const mutationOptions = useCreateMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useListAllReindexTasksHook = () => {
    const listAllReindexTasks = useMonitoringSwaggerClient<AsyncTaskDto[]>()

    return (signal?: AbortSignal) => {
        return listAllReindexTasks({ url: `/utils/async-tasks`, method: 'get', signal })
    }
}

export const getListAllReindexTasksQueryKey = () => [`/utils/async-tasks`] as const

export const useListAllReindexTasksQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useListAllReindexTasksHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListAllReindexTasksHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListAllReindexTasksHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListAllReindexTasksQueryKey()

    const listAllReindexTasks = useListAllReindexTasksHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListAllReindexTasksHook>>>> = ({ signal }) => listAllReindexTasks(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type ListAllReindexTasksQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListAllReindexTasksHook>>>>
export type ListAllReindexTasksQueryError = ApiError

export const useListAllReindexTasks = <TData = Awaited<ReturnType<ReturnType<typeof useListAllReindexTasksHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListAllReindexTasksHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListAllReindexTasksQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetReindexTaskStateByUuidHook = () => {
    const getReindexTaskStateByUuid = useMonitoringSwaggerClient<AsyncTaskDto>()

    return (uuid: string, signal?: AbortSignal) => {
        return getReindexTaskStateByUuid({ url: `/utils/async-tasks/${uuid}`, method: 'get', signal })
    }
}

export const getGetReindexTaskStateByUuidQueryKey = (uuid: string) => [`/utils/async-tasks/${uuid}`] as const

export const useGetReindexTaskStateByUuidQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetReindexTaskStateByUuidHook>>>,
    TError = ApiError,
>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReindexTaskStateByUuidHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReindexTaskStateByUuidHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetReindexTaskStateByUuidQueryKey(uuid)

    const getReindexTaskStateByUuid = useGetReindexTaskStateByUuidHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetReindexTaskStateByUuidHook>>>> = ({ signal }) =>
        getReindexTaskStateByUuid(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type GetReindexTaskStateByUuidQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetReindexTaskStateByUuidHook>>>>
export type GetReindexTaskStateByUuidQueryError = ApiError

export const useGetReindexTaskStateByUuid = <TData = Awaited<ReturnType<ReturnType<typeof useGetReindexTaskStateByUuidHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReindexTaskStateByUuidHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetReindexTaskStateByUuidQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCancelReindexTaskByUuidHook = () => {
    const cancelReindexTaskByUuid = useMonitoringSwaggerClient<void>()

    return (uuid: string) => {
        return cancelReindexTaskByUuid({ url: `/utils/async-tasks/${uuid}`, method: 'delete' })
    }
}

export const useCancelReindexTaskByUuidMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelReindexTaskByUuidHook>>>, TError, { uuid: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelReindexTaskByUuidHook>>>, TError, { uuid: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const cancelReindexTaskByUuid = useCancelReindexTaskByUuidHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCancelReindexTaskByUuidHook>>>, { uuid: string }> = (props) => {
        const { uuid } = props ?? {}

        return cancelReindexTaskByUuid(uuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type CancelReindexTaskByUuidMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCancelReindexTaskByUuidHook>>>>

export type CancelReindexTaskByUuidMutationError = ApiError

export const useCancelReindexTaskByUuid = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCancelReindexTaskByUuidHook>>>, TError, { uuid: string }, TContext>
}) => {
    const mutationOptions = useCancelReindexTaskByUuidMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetCurrentDateHook = () => {
    const getCurrentDate = useMonitoringSwaggerClient<GetCurrentDate200>()

    return (signal?: AbortSignal) => {
        return getCurrentDate({ url: `/test/getCurrentDate`, method: 'get', signal })
    }
}

export const getGetCurrentDateQueryKey = () => [`/test/getCurrentDate`] as const

export const useGetCurrentDateQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetCurrentDateHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCurrentDateHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCurrentDateHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetCurrentDateQueryKey()

    const getCurrentDate = useGetCurrentDateHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCurrentDateHook>>>> = ({ signal }) => getCurrentDate(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetCurrentDateQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCurrentDateHook>>>>
export type GetCurrentDateQueryError = ApiError

export const useGetCurrentDate = <TData = Awaited<ReturnType<ReturnType<typeof useGetCurrentDateHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCurrentDateHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetCurrentDateQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetAnyStringHook = () => {
    const getAnyString = useMonitoringSwaggerClient<GetAnyString200>()

    return (signal?: AbortSignal) => {
        return getAnyString({ url: `/test/getAnyString`, method: 'get', signal })
    }
}

export const getGetAnyStringQueryKey = () => [`/test/getAnyString`] as const

export const useGetAnyStringQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAnyStringHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAnyStringHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAnyStringHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetAnyStringQueryKey()

    const getAnyString = useGetAnyStringHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAnyStringHook>>>> = ({ signal }) => getAnyString(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetAnyStringQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAnyStringHook>>>>
export type GetAnyStringQueryError = ApiError

export const useGetAnyString = <TData = Awaited<ReturnType<ReturnType<typeof useGetAnyStringHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAnyStringHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetAnyStringQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetAnyObjectHook = () => {
    const getAnyObject = useMonitoringSwaggerClient<GetAnyObject200>()

    return (signal?: AbortSignal) => {
        return getAnyObject({ url: `/test/getAnyObject`, method: 'get', signal })
    }
}

export const getGetAnyObjectQueryKey = () => [`/test/getAnyObject`] as const

export const useGetAnyObjectQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAnyObjectHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAnyObjectHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAnyObjectHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetAnyObjectQueryKey()

    const getAnyObject = useGetAnyObjectHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAnyObjectHook>>>> = ({ signal }) => getAnyObject(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetAnyObjectQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAnyObjectHook>>>>
export type GetAnyObjectQueryError = ApiError

export const useGetAnyObject = <TData = Awaited<ReturnType<ReturnType<typeof useGetAnyObjectHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAnyObjectHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetAnyObjectQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetAnyListHook = () => {
    const getAnyList = useMonitoringSwaggerClient<GetAnyList200>()

    return (signal?: AbortSignal) => {
        return getAnyList({ url: `/test/getAnyList`, method: 'get', signal })
    }
}

export const getGetAnyListQueryKey = () => [`/test/getAnyList`] as const

export const useGetAnyListQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAnyListHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAnyListHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAnyListHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetAnyListQueryKey()

    const getAnyList = useGetAnyListHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAnyListHook>>>> = ({ signal }) => getAnyList(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetAnyListQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAnyListHook>>>>
export type GetAnyListQueryError = ApiError

export const useGetAnyList = <TData = Awaited<ReturnType<ReturnType<typeof useGetAnyListHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAnyListHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetAnyListQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetAnyDoubleHook = () => {
    const getAnyDouble = useMonitoringSwaggerClient<GetAnyDouble200>()

    return (signal?: AbortSignal) => {
        return getAnyDouble({ url: `/test/getAnyDouble`, method: 'get', signal })
    }
}

export const getGetAnyDoubleQueryKey = () => [`/test/getAnyDouble`] as const

export const useGetAnyDoubleQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAnyDoubleHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAnyDoubleHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAnyDoubleHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetAnyDoubleQueryKey()

    const getAnyDouble = useGetAnyDoubleHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAnyDoubleHook>>>> = ({ signal }) => getAnyDouble(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetAnyDoubleQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAnyDoubleHook>>>>
export type GetAnyDoubleQueryError = ApiError

export const useGetAnyDouble = <TData = Awaited<ReturnType<ReturnType<typeof useGetAnyDoubleHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAnyDoubleHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetAnyDoubleQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useEchoHook = () => {
    const echo = useMonitoringSwaggerClient<Echo200>()

    return (params?: EchoParams, signal?: AbortSignal) => {
        return echo({ url: `/test/echo`, method: 'get', params, signal })
    }
}

export const getEchoQueryKey = (params?: EchoParams) => [`/test/echo`, ...(params ? [params] : [])] as const

export const useEchoQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useEchoHook>>>, TError = ApiError>(
    params?: EchoParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useEchoHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useEchoHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getEchoQueryKey(params)

    const echo = useEchoHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useEchoHook>>>> = ({ signal }) => echo(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type EchoQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useEchoHook>>>>
export type EchoQueryError = ApiError

export const useEcho = <TData = Awaited<ReturnType<ReturnType<typeof useEchoHook>>>, TError = ApiError>(
    params?: EchoParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useEchoHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useEchoQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useListParameterTypesHook = () => {
    const listParameterTypes = useMonitoringSwaggerClient<ApiSlaParameterReadList>()

    return (params: ListParameterTypesParams, signal?: AbortSignal) => {
        return listParameterTypes({ url: `/slaparams`, method: 'get', params, signal })
    }
}

export const getListParameterTypesQueryKey = (params: ListParameterTypesParams) => [`/slaparams`, ...(params ? [params] : [])] as const

export const useListParameterTypesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListParameterTypesHook>>>, TError = ApiError>(
    params: ListParameterTypesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListParameterTypesHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListParameterTypesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListParameterTypesQueryKey(params)

    const listParameterTypes = useListParameterTypesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListParameterTypesHook>>>> = ({ signal }) =>
        listParameterTypes(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type ListParameterTypesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListParameterTypesHook>>>>
export type ListParameterTypesQueryError = ApiError

export const useListParameterTypes = <TData = Awaited<ReturnType<ReturnType<typeof useListParameterTypesHook>>>, TError = ApiError>(
    params: ListParameterTypesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListParameterTypesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListParameterTypesQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetSlaParameterHook = () => {
    const getSlaParameter = useMonitoringSwaggerClient<ApiSlaParameterRead>()

    return (slaParamUuid: string, signal?: AbortSignal) => {
        return getSlaParameter({ url: `/slaparams/${slaParamUuid}`, method: 'get', signal })
    }
}

export const getGetSlaParameterQueryKey = (slaParamUuid: string) => [`/slaparams/${slaParamUuid}`] as const

export const useGetSlaParameterQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetSlaParameterHook>>>, TError = ApiError>(
    slaParamUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSlaParameterHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSlaParameterHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetSlaParameterQueryKey(slaParamUuid)

    const getSlaParameter = useGetSlaParameterHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSlaParameterHook>>>> = ({ signal }) =>
        getSlaParameter(slaParamUuid, signal)

    return { queryKey, queryFn, enabled: !!slaParamUuid, ...queryOptions }
}

export type GetSlaParameterQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetSlaParameterHook>>>>
export type GetSlaParameterQueryError = ApiError

export const useGetSlaParameter = <TData = Awaited<ReturnType<ReturnType<typeof useGetSlaParameterHook>>>, TError = ApiError>(
    slaParamUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSlaParameterHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetSlaParameterQueryOptions(slaParamUuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetSlaContractHook = () => {
    const getSlaContract = useMonitoringSwaggerClient<ApiSlaContractRead>()

    return (slaContractUuid: string, signal?: AbortSignal) => {
        return getSlaContract({ url: `/slacontract/${slaContractUuid}`, method: 'get', signal })
    }
}

export const getGetSlaContractQueryKey = (slaContractUuid: string) => [`/slacontract/${slaContractUuid}`] as const

export const useGetSlaContractQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetSlaContractHook>>>, TError = ApiError>(
    slaContractUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSlaContractHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSlaContractHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetSlaContractQueryKey(slaContractUuid)

    const getSlaContract = useGetSlaContractHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSlaContractHook>>>> = ({ signal }) =>
        getSlaContract(slaContractUuid, signal)

    return { queryKey, queryFn, enabled: !!slaContractUuid, ...queryOptions }
}

export type GetSlaContractQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetSlaContractHook>>>>
export type GetSlaContractQueryError = ApiError

export const useGetSlaContract = <TData = Awaited<ReturnType<ReturnType<typeof useGetSlaContractHook>>>, TError = ApiError>(
    slaContractUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSlaContractHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetSlaContractQueryOptions(slaContractUuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetSlaContractDefaultSlaParametersHook = () => {
    const getSlaContractDefaultSlaParameters = useMonitoringSwaggerClient<ApiSlaParameterReadList>()

    return (slaContractUuid: string, signal?: AbortSignal) => {
        return getSlaContractDefaultSlaParameters({ url: `/slacontract/${slaContractUuid}/defaultSlaParameters`, method: 'get', signal })
    }
}

export const getGetSlaContractDefaultSlaParametersQueryKey = (slaContractUuid: string) =>
    [`/slacontract/${slaContractUuid}/defaultSlaParameters`] as const

export const useGetSlaContractDefaultSlaParametersQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetSlaContractDefaultSlaParametersHook>>>,
    TError = ApiError,
>(
    slaContractUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSlaContractDefaultSlaParametersHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSlaContractDefaultSlaParametersHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetSlaContractDefaultSlaParametersQueryKey(slaContractUuid)

    const getSlaContractDefaultSlaParameters = useGetSlaContractDefaultSlaParametersHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSlaContractDefaultSlaParametersHook>>>> = ({ signal }) =>
        getSlaContractDefaultSlaParameters(slaContractUuid, signal)

    return { queryKey, queryFn, enabled: !!slaContractUuid, ...queryOptions }
}

export type GetSlaContractDefaultSlaParametersQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetSlaContractDefaultSlaParametersHook>>>
>
export type GetSlaContractDefaultSlaParametersQueryError = ApiError

export const useGetSlaContractDefaultSlaParameters = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetSlaContractDefaultSlaParametersHook>>>,
    TError = ApiError,
>(
    slaContractUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSlaContractDefaultSlaParametersHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetSlaContractDefaultSlaParametersQueryOptions(slaContractUuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCheckOperationPermissionByMetaisCodeHook = () => {
    const checkOperationPermissionByMetaisCode = useMonitoringSwaggerClient<ApiPermissionResult>()

    return (metaisCode: string, params: CheckOperationPermissionByMetaisCodeParams, signal?: AbortSignal) => {
        return checkOperationPermissionByMetaisCode({ url: `/slacontract/metaiscode/${metaisCode}/permissions`, method: 'get', params, signal })
    }
}

export const getCheckOperationPermissionByMetaisCodeQueryKey = (metaisCode: string, params: CheckOperationPermissionByMetaisCodeParams) =>
    [`/slacontract/metaiscode/${metaisCode}/permissions`, ...(params ? [params] : [])] as const

export const useCheckOperationPermissionByMetaisCodeQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useCheckOperationPermissionByMetaisCodeHook>>>,
    TError = ApiError,
>(
    metaisCode: string,
    params: CheckOperationPermissionByMetaisCodeParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckOperationPermissionByMetaisCodeHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckOperationPermissionByMetaisCodeHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getCheckOperationPermissionByMetaisCodeQueryKey(metaisCode, params)

    const checkOperationPermissionByMetaisCode = useCheckOperationPermissionByMetaisCodeHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useCheckOperationPermissionByMetaisCodeHook>>>> = ({ signal }) =>
        checkOperationPermissionByMetaisCode(metaisCode, params, signal)

    return { queryKey, queryFn, enabled: !!metaisCode, ...queryOptions }
}

export type CheckOperationPermissionByMetaisCodeQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useCheckOperationPermissionByMetaisCodeHook>>>
>
export type CheckOperationPermissionByMetaisCodeQueryError = ApiError

export const useCheckOperationPermissionByMetaisCode = <
    TData = Awaited<ReturnType<ReturnType<typeof useCheckOperationPermissionByMetaisCodeHook>>>,
    TError = ApiError,
>(
    metaisCode: string,
    params: CheckOperationPermissionByMetaisCodeParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckOperationPermissionByMetaisCodeHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useCheckOperationPermissionByMetaisCodeQueryOptions(metaisCode, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useListSlaContractsHook = () => {
    const listSlaContracts = useMonitoringSwaggerClient<ApiSlaContractReadList>()

    return (params?: ListSlaContractsParams, signal?: AbortSignal) => {
        return listSlaContracts({ url: `/slacontract/list`, method: 'get', params, signal })
    }
}

export const getListSlaContractsQueryKey = (params?: ListSlaContractsParams) => [`/slacontract/list`, ...(params ? [params] : [])] as const

export const useListSlaContractsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListSlaContractsHook>>>, TError = ApiError>(
    params?: ListSlaContractsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListSlaContractsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListSlaContractsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListSlaContractsQueryKey(params)

    const listSlaContracts = useListSlaContractsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListSlaContractsHook>>>> = ({ signal }) => listSlaContracts(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type ListSlaContractsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListSlaContractsHook>>>>
export type ListSlaContractsQueryError = ApiError

export const useListSlaContracts = <TData = Awaited<ReturnType<ReturnType<typeof useListSlaContractsHook>>>, TError = ApiError>(
    params?: ListSlaContractsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListSlaContractsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListSlaContractsQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useListCreateContractCandidatesHook = () => {
    const listCreateContractCandidates = useMonitoringSwaggerClient<ApiContractCreateObjectCandidatesList>()

    return (params?: ListCreateContractCandidatesParams, signal?: AbortSignal) => {
        return listCreateContractCandidates({ url: `/slacontract/list-candidates`, method: 'get', params, signal })
    }
}

export const getListCreateContractCandidatesQueryKey = (params?: ListCreateContractCandidatesParams) =>
    [`/slacontract/list-candidates`, ...(params ? [params] : [])] as const

export const useListCreateContractCandidatesQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useListCreateContractCandidatesHook>>>,
    TError = ApiError,
>(
    params?: ListCreateContractCandidatesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListCreateContractCandidatesHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListCreateContractCandidatesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListCreateContractCandidatesQueryKey(params)

    const listCreateContractCandidates = useListCreateContractCandidatesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListCreateContractCandidatesHook>>>> = ({ signal }) =>
        listCreateContractCandidates(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type ListCreateContractCandidatesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListCreateContractCandidatesHook>>>>
export type ListCreateContractCandidatesQueryError = ApiError

export const useListCreateContractCandidates = <
    TData = Awaited<ReturnType<ReturnType<typeof useListCreateContractCandidatesHook>>>,
    TError = ApiError,
>(
    params?: ListCreateContractCandidatesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListCreateContractCandidatesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListCreateContractCandidatesQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCheckRequiredSlaParametersHook = () => {
    const checkRequiredSlaParameters = useMonitoringSwaggerClient<ApiServicesSlaParameters>()

    return (params: CheckRequiredSlaParametersParams, signal?: AbortSignal) => {
        return checkRequiredSlaParameters({ url: `/slacontract/check-required-sla-parameters`, method: 'get', params, signal })
    }
}

export const getCheckRequiredSlaParametersQueryKey = (params: CheckRequiredSlaParametersParams) =>
    [`/slacontract/check-required-sla-parameters`, ...(params ? [params] : [])] as const

export const useCheckRequiredSlaParametersQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useCheckRequiredSlaParametersHook>>>,
    TError = ApiError,
>(
    params: CheckRequiredSlaParametersParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckRequiredSlaParametersHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckRequiredSlaParametersHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getCheckRequiredSlaParametersQueryKey(params)

    const checkRequiredSlaParameters = useCheckRequiredSlaParametersHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useCheckRequiredSlaParametersHook>>>> = ({ signal }) =>
        checkRequiredSlaParameters(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type CheckRequiredSlaParametersQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCheckRequiredSlaParametersHook>>>>
export type CheckRequiredSlaParametersQueryError = ApiError

export const useCheckRequiredSlaParameters = <TData = Awaited<ReturnType<ReturnType<typeof useCheckRequiredSlaParametersHook>>>, TError = ApiError>(
    params: CheckRequiredSlaParametersParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCheckRequiredSlaParametersHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useCheckRequiredSlaParametersQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useListRelatedParameterTypesHook = () => {
    const listRelatedParameterTypes = useMonitoringSwaggerClient<ApiParameterTypesList>()

    return (
        parameterTypeCode: string,
        relatedParameterTypeType: 'SLA' | 'MONITORING',
        params?: ListRelatedParameterTypesParams,
        signal?: AbortSignal,
    ) => {
        return listRelatedParameterTypes({
            url: `/paramtypes/${parameterTypeCode}/related/${relatedParameterTypeType}`,
            method: 'get',
            params,
            signal,
        })
    }
}

export const getListRelatedParameterTypesQueryKey = (
    parameterTypeCode: string,
    relatedParameterTypeType: 'SLA' | 'MONITORING',
    params?: ListRelatedParameterTypesParams,
) => [`/paramtypes/${parameterTypeCode}/related/${relatedParameterTypeType}`, ...(params ? [params] : [])] as const

export const useListRelatedParameterTypesQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useListRelatedParameterTypesHook>>>,
    TError = ApiError,
>(
    parameterTypeCode: string,
    relatedParameterTypeType: 'SLA' | 'MONITORING',
    params?: ListRelatedParameterTypesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListRelatedParameterTypesHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListRelatedParameterTypesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListRelatedParameterTypesQueryKey(parameterTypeCode, relatedParameterTypeType, params)

    const listRelatedParameterTypes = useListRelatedParameterTypesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListRelatedParameterTypesHook>>>> = ({ signal }) =>
        listRelatedParameterTypes(parameterTypeCode, relatedParameterTypeType, params, signal)

    return { queryKey, queryFn, enabled: !!(parameterTypeCode && relatedParameterTypeType), ...queryOptions }
}

export type ListRelatedParameterTypesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListRelatedParameterTypesHook>>>>
export type ListRelatedParameterTypesQueryError = ApiError

export const useListRelatedParameterTypes = <TData = Awaited<ReturnType<ReturnType<typeof useListRelatedParameterTypesHook>>>, TError = ApiError>(
    parameterTypeCode: string,
    relatedParameterTypeType: 'SLA' | 'MONITORING',
    params?: ListRelatedParameterTypesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListRelatedParameterTypesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListRelatedParameterTypesQueryOptions(parameterTypeCode, relatedParameterTypeType, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetOlaContractHook = () => {
    const getOlaContract = useMonitoringSwaggerClient<ApiOlaContractData>()

    return (olaContractUuid: string, signal?: AbortSignal) => {
        return getOlaContract({ url: `/olacontract/${olaContractUuid}`, method: 'get', signal })
    }
}

export const getGetOlaContractQueryKey = (olaContractUuid: string) => [`/olacontract/${olaContractUuid}`] as const

export const useGetOlaContractQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetOlaContractHook>>>, TError = ApiError>(
    olaContractUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOlaContractHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOlaContractHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetOlaContractQueryKey(olaContractUuid)

    const getOlaContract = useGetOlaContractHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOlaContractHook>>>> = ({ signal }) =>
        getOlaContract(olaContractUuid, signal)

    return { queryKey, queryFn, enabled: !!olaContractUuid, ...queryOptions }
}

export type GetOlaContractQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOlaContractHook>>>>
export type GetOlaContractQueryError = ApiError

export const useGetOlaContract = <TData = Awaited<ReturnType<ReturnType<typeof useGetOlaContractHook>>>, TError = ApiError>(
    olaContractUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOlaContractHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetOlaContractQueryOptions(olaContractUuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useListOlaContractListHook = () => {
    const listOlaContractList = useMonitoringSwaggerClient<ApiOlaContractDataList>()

    return (params?: ListOlaContractListParams, signal?: AbortSignal) => {
        return listOlaContractList({ url: `/olacontract/list`, method: 'get', params, signal })
    }
}

export const getListOlaContractListQueryKey = (params?: ListOlaContractListParams) => [`/olacontract/list`, ...(params ? [params] : [])] as const

export const useListOlaContractListQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useListOlaContractListHook>>>, TError = ApiError>(
    params?: ListOlaContractListParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListOlaContractListHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListOlaContractListHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getListOlaContractListQueryKey(params)

    const listOlaContractList = useListOlaContractListHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListOlaContractListHook>>>> = ({ signal }) =>
        listOlaContractList(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type ListOlaContractListQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListOlaContractListHook>>>>
export type ListOlaContractListQueryError = ApiError

export const useListOlaContractList = <TData = Awaited<ReturnType<ReturnType<typeof useListOlaContractListHook>>>, TError = ApiError>(
    params?: ListOlaContractListParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListOlaContractListHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useListOlaContractListQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindActiveMonitoringLogFailNoteHook = () => {
    const findActiveMonitoringLogFailNote = useMonitoringSwaggerClient<ApiActiveMonitoringLogFailNoteList>()

    return (params: FindActiveMonitoringLogFailNoteParams, signal?: AbortSignal) => {
        return findActiveMonitoringLogFailNote({ url: `/activeMonitoring/logFailNote/find`, method: 'get', params, signal })
    }
}

export const getFindActiveMonitoringLogFailNoteQueryKey = (params: FindActiveMonitoringLogFailNoteParams) =>
    [`/activeMonitoring/logFailNote/find`, ...(params ? [params] : [])] as const

export const useFindActiveMonitoringLogFailNoteQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogFailNoteHook>>>,
    TError = ApiError,
>(
    params: FindActiveMonitoringLogFailNoteParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogFailNoteHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogFailNoteHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindActiveMonitoringLogFailNoteQueryKey(params)

    const findActiveMonitoringLogFailNote = useFindActiveMonitoringLogFailNoteHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogFailNoteHook>>>> = ({ signal }) =>
        findActiveMonitoringLogFailNote(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindActiveMonitoringLogFailNoteQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogFailNoteHook>>>>
export type FindActiveMonitoringLogFailNoteQueryError = ApiError

export const useFindActiveMonitoringLogFailNote = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogFailNoteHook>>>,
    TError = ApiError,
>(
    params: FindActiveMonitoringLogFailNoteParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogFailNoteHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindActiveMonitoringLogFailNoteQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindActiveMonitoringLogHook = () => {
    const findActiveMonitoringLog = useMonitoringSwaggerClient<ApiActiveMonitoringLogList>()

    return (params: FindActiveMonitoringLogParams, signal?: AbortSignal) => {
        return findActiveMonitoringLog({ url: `/activeMonitoring/log/find`, method: 'get', params, signal })
    }
}

export const getFindActiveMonitoringLogQueryKey = (params: FindActiveMonitoringLogParams) =>
    [`/activeMonitoring/log/find`, ...(params ? [params] : [])] as const

export const useFindActiveMonitoringLogQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogHook>>>,
    TError = ApiError,
>(
    params: FindActiveMonitoringLogParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindActiveMonitoringLogQueryKey(params)

    const findActiveMonitoringLog = useFindActiveMonitoringLogHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogHook>>>> = ({ signal }) =>
        findActiveMonitoringLog(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindActiveMonitoringLogQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogHook>>>>
export type FindActiveMonitoringLogQueryError = ApiError

export const useFindActiveMonitoringLog = <TData = Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogHook>>>, TError = ApiError>(
    params: FindActiveMonitoringLogParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindActiveMonitoringLogQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindActiveMonitoringLogByIntervalHook = () => {
    const findActiveMonitoringLogByInterval = useMonitoringSwaggerClient<ApiActiveMonitoringLogList>()

    return (id: number, params: FindActiveMonitoringLogByIntervalParams, signal?: AbortSignal) => {
        return findActiveMonitoringLogByInterval({ url: `/activeMonitoring/log/find/${id}/byInterval`, method: 'get', params, signal })
    }
}

export const getFindActiveMonitoringLogByIntervalQueryKey = (id: number, params: FindActiveMonitoringLogByIntervalParams) =>
    [`/activeMonitoring/log/find/${id}/byInterval`, ...(params ? [params] : [])] as const

export const useFindActiveMonitoringLogByIntervalQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogByIntervalHook>>>,
    TError = ApiError,
>(
    id: number,
    params: FindActiveMonitoringLogByIntervalParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogByIntervalHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogByIntervalHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindActiveMonitoringLogByIntervalQueryKey(id, params)

    const findActiveMonitoringLogByInterval = useFindActiveMonitoringLogByIntervalHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogByIntervalHook>>>> = ({ signal }) =>
        findActiveMonitoringLogByInterval(id, params, signal)

    return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type FindActiveMonitoringLogByIntervalQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogByIntervalHook>>>
>
export type FindActiveMonitoringLogByIntervalQueryError = ApiError

export const useFindActiveMonitoringLogByInterval = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogByIntervalHook>>>,
    TError = ApiError,
>(
    id: number,
    params: FindActiveMonitoringLogByIntervalParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogByIntervalHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindActiveMonitoringLogByIntervalQueryOptions(id, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindActiveMonitoringLogByInterval1Hook = () => {
    const findActiveMonitoringLogByInterval1 = useMonitoringSwaggerClient<ApiActiveMonitoringLogList>()

    return (params: FindActiveMonitoringLogByInterval1Params, signal?: AbortSignal) => {
        return findActiveMonitoringLogByInterval1({ url: `/activeMonitoring/log/find/byInterval`, method: 'get', params, signal })
    }
}

export const getFindActiveMonitoringLogByInterval1QueryKey = (params: FindActiveMonitoringLogByInterval1Params) =>
    [`/activeMonitoring/log/find/byInterval`, ...(params ? [params] : [])] as const

export const useFindActiveMonitoringLogByInterval1QueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogByInterval1Hook>>>,
    TError = ApiError,
>(
    params: FindActiveMonitoringLogByInterval1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogByInterval1Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogByInterval1Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindActiveMonitoringLogByInterval1QueryKey(params)

    const findActiveMonitoringLogByInterval1 = useFindActiveMonitoringLogByInterval1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogByInterval1Hook>>>> = ({ signal }) =>
        findActiveMonitoringLogByInterval1(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindActiveMonitoringLogByInterval1QueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogByInterval1Hook>>>
>
export type FindActiveMonitoringLogByInterval1QueryError = ApiError

export const useFindActiveMonitoringLogByInterval1 = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogByInterval1Hook>>>,
    TError = ApiError,
>(
    params: FindActiveMonitoringLogByInterval1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringLogByInterval1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindActiveMonitoringLogByInterval1QueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindAvaliabilityHook = () => {
    const findAvaliability = useMonitoringSwaggerClient<ApiActiveMonitoringAvaliability>()

    return (uuid: string, signal?: AbortSignal) => {
        return findAvaliability({ url: `/activeMonitoring/log/avaliability/${uuid}`, method: 'get', signal })
    }
}

export const getFindAvaliabilityQueryKey = (uuid: string) => [`/activeMonitoring/log/avaliability/${uuid}`] as const

export const useFindAvaliabilityQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindAvaliabilityHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAvaliabilityHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAvaliabilityHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindAvaliabilityQueryKey(uuid)

    const findAvaliability = useFindAvaliabilityHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindAvaliabilityHook>>>> = ({ signal }) => findAvaliability(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindAvaliabilityQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindAvaliabilityHook>>>>
export type FindAvaliabilityQueryError = ApiError

export const useFindAvaliability = <TData = Awaited<ReturnType<ReturnType<typeof useFindAvaliabilityHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAvaliabilityHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindAvaliabilityQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetHook = () => {
    const get = useMonitoringSwaggerClient<ApiActiveMonitoringCfg>()

    return (id: number, signal?: AbortSignal) => {
        return get({ url: `/activeMonitoring/cfg/get/${id}`, method: 'get', signal })
    }
}

export const getGetQueryKey = (id: number) => [`/activeMonitoring/cfg/get/${id}`] as const

export const useGetQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetHook>>>, TError = ApiError>(
    id: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetQueryKey(id)

    const get = useGetHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetHook>>>> = ({ signal }) => get(id, signal)

    return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type GetQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetHook>>>>
export type GetQueryError = ApiError

export const useGet = <TData = Awaited<ReturnType<ReturnType<typeof useGetHook>>>, TError = ApiError>(
    id: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetQueryOptions(id, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindActiveMonitoringCfgHook = () => {
    const findActiveMonitoringCfg = useMonitoringSwaggerClient<ApiActiveMonitoringCfgList>()

    return (params?: FindActiveMonitoringCfgParams, signal?: AbortSignal) => {
        return findActiveMonitoringCfg({ url: `/activeMonitoring/cfg/find`, method: 'get', params, signal })
    }
}

export const getFindActiveMonitoringCfgQueryKey = (params?: FindActiveMonitoringCfgParams) =>
    [`/activeMonitoring/cfg/find`, ...(params ? [params] : [])] as const

export const useFindActiveMonitoringCfgQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringCfgHook>>>,
    TError = ApiError,
>(
    params?: FindActiveMonitoringCfgParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringCfgHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringCfgHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindActiveMonitoringCfgQueryKey(params)

    const findActiveMonitoringCfg = useFindActiveMonitoringCfgHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringCfgHook>>>> = ({ signal }) =>
        findActiveMonitoringCfg(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindActiveMonitoringCfgQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringCfgHook>>>>
export type FindActiveMonitoringCfgQueryError = ApiError

export const useFindActiveMonitoringCfg = <TData = Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringCfgHook>>>, TError = ApiError>(
    params?: FindActiveMonitoringCfgParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindActiveMonitoringCfgHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindActiveMonitoringCfgQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCallIsvsEndpointHook = () => {
    const callIsvsEndpoint = useMonitoringSwaggerClient<ApiActiveMonitoringResult>()

    return (id: number, signal?: AbortSignal) => {
        return callIsvsEndpoint({ url: `/activeMonitoring/cfg/callIsvsEndpoint/${id}`, method: 'get', signal })
    }
}

export const getCallIsvsEndpointQueryKey = (id: number) => [`/activeMonitoring/cfg/callIsvsEndpoint/${id}`] as const

export const useCallIsvsEndpointQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useCallIsvsEndpointHook>>>, TError = ApiError>(
    id: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCallIsvsEndpointHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCallIsvsEndpointHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getCallIsvsEndpointQueryKey(id)

    const callIsvsEndpoint = useCallIsvsEndpointHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useCallIsvsEndpointHook>>>> = ({ signal }) => callIsvsEndpoint(id, signal)

    return { queryKey, queryFn, enabled: !!id, ...queryOptions }
}

export type CallIsvsEndpointQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCallIsvsEndpointHook>>>>
export type CallIsvsEndpointQueryError = ApiError

export const useCallIsvsEndpoint = <TData = Awaited<ReturnType<ReturnType<typeof useCallIsvsEndpointHook>>>, TError = ApiError>(
    id: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCallIsvsEndpointHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useCallIsvsEndpointQueryOptions(id, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const use_DeleteHook = () => {
    const _delete = useMonitoringSwaggerClient<void>()

    return (id: number) => {
        return _delete({ url: `/activeMonitoring/cfg/delete/${id}`, method: 'delete' })
    }
}

export const useDeleteMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof use_DeleteHook>>>, TError, { id: number }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof use_DeleteHook>>>, TError, { id: number }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const _delete = use_DeleteHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof use_DeleteHook>>>, { id: number }> = (props) => {
        const { id } = props ?? {}

        return _delete(id)
    }

    return { mutationFn, ...mutationOptions }
}

export type _DeleteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof use_DeleteHook>>>>

export type _DeleteMutationError = ApiError

export const useDelete = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof use_DeleteHook>>>, TError, { id: number }, TContext>
}) => {
    const mutationOptions = useDeleteMutationOptions(options)

    return useMutation(mutationOptions)
}
