import { TextHeading } from '@isdd/idsk-ui-kit/index'
import { FooterMetaList } from '@isdd/metais-common/components/footer/FooterMeta'
import { FooterItem } from '@isdd/metais-common/components/footer/FooterSection'
import { getPortalFooterMetaList, getPortalFooterUsefulList } from '@isdd/metais-common/components/footer/footerSections'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { NavigationItem, NavigationSubItem } from '@isdd/metais-common/navigation/routeNames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { getPortalNavigationItems } from '@/components/navbar/navigationItems'
import { useOtherEntityHook } from '@/hooks/useOtherEntity.hook'

export interface ISiteMapItem {
    label: string
    path: string
    subItems: ISiteMapItem[]
}

export const SiteMapView: React.FC = () => {
    const { t } = useTranslation()
    const {
        state: { user },
    } = useAuth()
    const { ciOtherEntityItems } = useOtherEntityHook()
    const leftMenu = getPortalNavigationItems(t, user, ciOtherEntityItems, true)
    const footerMenu = getPortalFooterMetaList(t, false)
    const usefulLinks = getPortalFooterUsefulList(t, !!user, false)

    const renderRoutes = (routes: NavigationItem[] | NavigationSubItem[]) => {
        return routes.map((route, index) => (
            <li key={index}>
                <Link className="govuk-link" to={route.path ?? '#'}>
                    {route.title}
                </Link>
                {route.subItems && <ul>{renderRoutes(route.subItems)}</ul>}
            </li>
        ))
    }

    const renderFooterRoutes = (routes: FooterMetaList[] | FooterItem[]) => {
        return routes.map((route, index) => (
            <li key={index}>
                <Link className="govuk-link" to={route.href ?? '#'}>
                    {route.label}
                </Link>
            </li>
        ))
    }

    return (
        <>
            <TextHeading size="XL">{t('footer.webResidencyMap')}</TextHeading>
            <ul className="govuk-list">
                {renderRoutes(leftMenu)}
                {renderFooterRoutes(footerMenu)}
            </ul>
            <TextHeading size="S">{t('footer.usefulLinks')}</TextHeading>
            <ul className="govuk-list">{renderFooterRoutes(usefulLinks)}</ul>
        </>
    )
}
