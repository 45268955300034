import { formatDateForDefaultValue, formatDateTimeForDefaultValue } from '@isdd/metais-common'
import { GreenCheckMarkIcon } from '@isdd/metais-common/assets/images'
import { DATE_FORMAT, MODAL_TOOLTIP_ZINDEX, datePlaceholder, dateTimePlaceholder } from '@isdd/metais-common/constants'
import { Languages } from '@isdd/metais-common/localization/languages'
import classNames from 'classnames'
import { enUS as en, sk } from 'date-fns/locale'
import { forwardRef, useId } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Control, Controller, FieldValue, UseFormClearErrors, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import styles from './dateInput.module.scss'
import { useGetTranslates } from './useGetTranslates'

import { Tooltip } from '@isdd/idsk-ui-kit/tooltip/Tooltip'

import './dateInput.scss'

registerLocale('sk', sk)
registerLocale('en', en)

export enum DateTypeEnum {
    DATE = 'date',
    DATETIME = 'datetime',
}

type Props = {
    handleDateChange?: (date: Date | null, name: string) => void
    name: string
    type?: DateTypeEnum
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: Control<FieldValue<Record<string, any>>>
    label?: string | React.ReactNode
    hint?: string
    error?: string
    disabled?: boolean
    info?: string
    correct?: boolean
    className?: string
    inputClassName?: string
    hasInputIcon?: boolean
    maxLength?: number
    id?: string
    required?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clearErrors?: UseFormClearErrors<any>
    minDate?: Date
    maxDate?: Date
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValue: UseFormSetValue<any>
    isInModal?: boolean
    announceErrorToScreenReader?: boolean
}

export const DateInput = forwardRef<HTMLDivElement, Props>(
    (
        {
            name,
            type = DateTypeEnum.DATE,
            control,
            handleDateChange,
            label,
            disabled,
            hint,
            error,
            info,
            correct,
            className,
            inputClassName,
            hasInputIcon,
            id,
            required,
            clearErrors,
            minDate,
            maxDate,
            setValue,
            isInModal = false,
            announceErrorToScreenReader = false,
        },
        ref,
    ) => {
        const { t, i18n } = useTranslation()
        const translates = useGetTranslates()

        const uId = useId()
        const inputId = id ?? `input-${uId}` // NVDA has problems with ID starting with `useID` `::`, needs to be prefixed
        const hintId = `${inputId}-hint`
        const errorId = `${inputId}-error`
        const controlHintIt = `${inputId}-control`

        const placeholder = type === DateTypeEnum.DATE ? datePlaceholder : dateTimePlaceholder
        const format = type === DateTypeEnum.DATE ? DATE_FORMAT.dayMonthYear : DATE_FORMAT.dateWithHoursMinutes

        const showTimeSelect = type === DateTypeEnum.DATETIME

        const handleDefaultDateChange = (date: Date | null) => {
            setValue(
                name,
                date
                    ? type == DateTypeEnum.DATE
                        ? formatDateForDefaultValue(date.toISOString())
                        : formatDateTimeForDefaultValue(date.toISOString())
                    : null,
            )
        }

        return (
            <Controller
                name={name}
                control={control}
                render={({ field }) => {
                    return (
                        <div className={classNames('govuk-form-group', className, { 'govuk-form-group--error': !!error })} ref={ref}>
                            <div className={styles.labelDiv}>
                                {label && (
                                    <label className="govuk-label" htmlFor={inputId}>
                                        {label} {required && t('input.requiredField')}
                                    </label>
                                )}
                                {info && (
                                    <Tooltip
                                        altText={t('tooltip', { tooltip: label })}
                                        descriptionElement={<div className="tooltipWidth500">{info}</div>}
                                        contentStyle={isInModal ? { zIndex: MODAL_TOOLTIP_ZINDEX } : undefined}
                                    />
                                )}
                            </div>
                            <span id={hintId} className={classNames({ 'govuk-visually-hidden': !hint, 'govuk-hint': !!hint })}>
                                {hint}
                            </span>
                            {!disabled && (
                                <span className="sr-only" id={controlHintIt}>
                                    {t('input.datePicker.hint')}
                                </span>
                            )}
                            <span
                                id={errorId}
                                role={announceErrorToScreenReader ? 'alert' : undefined}
                                className={classNames({ 'govuk-visually-hidden': !error, 'govuk-error-message': !!error })}
                            >
                                {error && <span className="govuk-visually-hidden">{t('error')}</span>}
                                {error}
                            </span>
                            <div className={classNames(styles.inputWrapper, inputClassName)} style={{ position: 'relative' }}>
                                <DatePicker
                                    id={inputId}
                                    wrapperClassName={styles.fullWidth}
                                    className={classNames('govuk-input', { 'govuk-input--error': !!error })}
                                    placeholderText={placeholder}
                                    selected={field.value ? new Date(field.value) : null}
                                    popperClassName={styles.dateInputPopperClass}
                                    onChange={(date) => {
                                        date && clearErrors?.(name)
                                        handleDateChange ? handleDateChange(date, field.name) : handleDefaultDateChange(date)
                                    }}
                                    popperPlacement="bottom-start"
                                    dateFormat={format}
                                    showTimeSelect={showTimeSelect}
                                    locale={i18n.language === Languages.SLOVAK ? Languages.SLOVAK : Languages.ENGLISH}
                                    readOnly={disabled}
                                    required={required}
                                    minDate={minDate}
                                    maxDate={maxDate ? maxDate : new Date('9999-12-31')}
                                    ariaDescribedBy={`${controlHintIt} ${hintId} ${errorId}`}
                                    ariaInvalid={error ? 'true' : 'false'}
                                    onCalendarOpen={() => {
                                        const nodes = document.querySelectorAll('.react-datepicker')
                                        nodes.forEach((el) => el.removeAttribute('aria-label'))
                                    }}
                                    {...translates}
                                />
                                {correct && (
                                    <img
                                        src={GreenCheckMarkIcon}
                                        className={hasInputIcon ? styles.isCorrectWithIcon : styles.isCorrect}
                                        alt={t('valid')}
                                    />
                                )}
                            </div>
                        </div>
                    )
                }}
            />
        )
    },
)
