import { BaseModal, Button, Input, TextBody, TextHeading } from '@isdd/idsk-ui-kit'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalButtons } from '@isdd/metais-common/index'

type LinkModalPropsType = {
    isOpen: boolean
    defaultText?: string
    defaultLink?: string
    isEdit?: boolean
    onClose: () => void
    onSubmit: (link: string, text: string, shouldCreateLink?: boolean) => void
}
export const LinkModal: React.FC<LinkModalPropsType> = ({ isOpen, defaultLink, defaultText, isEdit, onClose, onSubmit }) => {
    const { t } = useTranslation()

    const [valueText, setValueText] = useState<string>('')
    const [valueLink, setValueLink] = useState<string>('')

    useEffect(() => {
        setValueLink(defaultLink ?? '')
        setValueText(defaultText ?? '')
    }, [defaultLink, defaultText])

    const handleSubmit = () => {
        onSubmit(valueLink, valueText, true)
        setValueLink('')
        setValueText('')
    }

    const handleRemoveLink = () => {
        onSubmit(valueLink, valueText, false)
        setValueLink('')
        setValueText('')
    }

    return (
        <BaseModal widthInPx={400} isOpen={isOpen} close={onClose}>
            <form onSubmit={handleSubmit} noValidate>
                <TextHeading size="L">{isEdit ? t('quill.link.headerEdit') : t('quill.link.header')}</TextHeading>
                <TextBody size="L">
                    <Input
                        label={t('quill.link.displayText')}
                        id="text"
                        name="text"
                        required
                        value={valueText}
                        onChange={(e) => setValueText(e.currentTarget.value)}
                    />
                    <Input
                        label={t('quill.link.link')}
                        hint={t('quill.link.linkHint')}
                        type="text"
                        id="link"
                        name="link"
                        value={valueLink}
                        required
                        onChange={(e) => setValueLink(e.currentTarget.value)}
                    />
                </TextBody>
                <ModalButtons
                    submitButtonLabel={isEdit ? t('quill.link.edit') : t('quill.link.add')}
                    onSubmit={handleSubmit}
                    closeButtonLabel={t('quill.link.cancel')}
                    onClose={onClose}
                    additionalButtons={[
                        isEdit && (
                            <Button key="remove" variant={'secondary'} label={t('quill.link.removeLink')} type="button" onClick={handleRemoveLink} />
                        ),
                    ]}
                />
            </form>
        </BaseModal>
    )
}
