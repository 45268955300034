import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { ConfigurationItemUiAttributes } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { Attribute } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { formatDateForDefaultValue } from '@isdd/metais-common/index'
import { TFunction } from 'i18next'
import { DateTime } from 'luxon'
import { array, boolean, date, object, string } from 'yup'

import { getNameByAttribute, getRequiredByAttribute } from '@/components/views/codeLists/CodeListDetailUtils'

const midNight = new Date(new Date().setHours(0, 0, 0, 0))

export enum RefCatalogFormTypeEnum {
    OWNER = 'owner',
    DATASET = 'dataset',
    PO = 'po',
}

export enum RefTemplateUriFormTypeEnum {
    OWNER = 'owner',
    TEMPLATE_URI = 'templateUri',
}

export enum RefDataItemFormTypeEnum {
    OWNER = 'owner',
    PO = 'po',
    DATA_ITEM = 'dataItem',
}

export enum RefDatasetFormTypeEnum {
    OWNER = 'owner',
    DATA_ITEM = 'dataItem',
    DATA_CODE = 'dataCode',
}

export enum RefOntologyFormTypeEnum {
    OWNER = 'owner',
    PO = 'po',
}

export enum RefOntologyVersionFormTypeEnum {
    OWNER = 'owner',
    ONTOLOGY = 'ontology',
}

export type RefCatalogFormType = {
    [RefCatalogFormTypeEnum.OWNER]: string
    [RefCatalogFormTypeEnum.DATASET]: string[]
    [RefCatalogFormTypeEnum.PO]: string
    attributes: {
        [ATTRIBUTE_NAME.Gen_Profil_nazov]?: string
        [ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov]?: string
        [ATTRIBUTE_NAME.Gen_Profil_popis]?: string
        [ATTRIBUTE_NAME.Profil_URIKatalog_uri]?: string
        [ATTRIBUTE_NAME.Profil_URIKatalog_platne_od]?: Date
        [ATTRIBUTE_NAME.Profil_URIKatalog_platne_do]?: Date | null
    }
}

export type RefTemplateUriFormType = {
    [RefTemplateUriFormTypeEnum.OWNER]: string
    [RefTemplateUriFormTypeEnum.TEMPLATE_URI]: string
    attributes: {
        [ATTRIBUTE_NAME.Gen_Profil_nazov]?: string
        [ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov]?: string
        [ATTRIBUTE_NAME.Gen_Profil_popis]?: string
        [ATTRIBUTE_NAME.Profil_Individuum_zaklad_uri]?: string
        [ATTRIBUTE_NAME.Profil_Individuum_kod]?: string
        [ATTRIBUTE_NAME.Profil_Individuum_platne_od]?: Date
        [ATTRIBUTE_NAME.Profil_Individuum_platne_do]?: Date | null
        [ATTRIBUTE_NAME.Profil_Individuum_hasVersion]?: boolean
        [ATTRIBUTE_NAME.Profil_Individuum_deferenceTarget]?: string
        [ATTRIBUTE_NAME.Profil_Individuum_id_regex_pattern]?: string
        [ATTRIBUTE_NAME.Profil_Individuum_parameter]?: string[]
        [ATTRIBUTE_NAME.Profil_Individuum_parameter_desc]?: string[]
    }
}

export type RefDataItemFormType = {
    [RefDataItemFormTypeEnum.OWNER]: string
    [RefDataItemFormTypeEnum.PO]: string
    [RefDataItemFormTypeEnum.DATA_ITEM]: string[] | null | undefined
    attributes: {
        [ATTRIBUTE_NAME.Gen_Profil_nazov]?: string
        [ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov]?: string
        [ATTRIBUTE_NAME.Gen_Profil_popis]?: string
        [ATTRIBUTE_NAME.Profil_DatovyPrvok_kod_datoveho_prvku]?: string
        [ATTRIBUTE_NAME.Profil_DatovyPrvok_typ_datoveho_prvku]?: string
        [ATTRIBUTE_NAME.Profil_DatovyPrvok_historicky_kod]?: string
        [ATTRIBUTE_NAME.Profil_DatovyPrvok_zaciatok_ucinnosti]?: Date | null
        [ATTRIBUTE_NAME.Profil_DatovyPrvok_koniec_ucinnosti]?: Date | null
    }
}

export type RefDatasetFormType = {
    [RefDatasetFormTypeEnum.OWNER]: string
    [RefDatasetFormTypeEnum.DATA_ITEM]: string
    [RefDatasetFormTypeEnum.DATA_CODE]: string
    attributes: {
        [ATTRIBUTE_NAME.Gen_Profil_nazov]?: string
        [ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov]?: string
        [ATTRIBUTE_NAME.Gen_Profil_popis]?: string
        [ATTRIBUTE_NAME.Profil_URIDataset_uri_datasetu]?: string
        [ATTRIBUTE_NAME.Profil_URIDataset_historicky_kod]?: string
    }
}

export type RefOntologyFormType = {
    [RefOntologyFormTypeEnum.OWNER]: string
    [RefOntologyFormTypeEnum.PO]: string
    attributes: {
        [ATTRIBUTE_NAME.Gen_Profil_nazov]?: string
        [ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov]: string
        [ATTRIBUTE_NAME.Gen_Profil_popis]?: string
        [ATTRIBUTE_NAME.Profil_Ontologia_uri_pre_ontologiu]?: string
        [ATTRIBUTE_NAME.Profil_Ontologia_historicky_kod]?: string
    }
}

export type RefOntologyVersionFormType = {
    [RefOntologyVersionFormTypeEnum.OWNER]: string
    [RefOntologyVersionFormTypeEnum.ONTOLOGY]: string
    attributes: {
        [ATTRIBUTE_NAME.Gen_Profil_nazov]?: string
        [ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov]?: string
        [ATTRIBUTE_NAME.Profil_VerziaOntologie_uri_distribucie]?: string
        [ATTRIBUTE_NAME.Profil_VerziaOntologie_typ_media]?: string
        [ATTRIBUTE_NAME.Profil_VerziaOntologie_verzia]?: string
        [ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_od]?: Date
        [ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_do]?: Date | null
        [ATTRIBUTE_NAME.Profil_VerziaOntologie_download_url]?: string
    }
}

const getCurrentDateValueToCompare = (attributesDefaultValues: ConfigurationItemUiAttributes, technicalName: string) => {
    const currentValue = attributesDefaultValues[technicalName]
    const isCurrentValueSmallerThanActualDate =
        currentValue && DateTime.fromJSDate(new Date(currentValue)).isValid && new Date(currentValue).getTime() < new Date().getTime() ? true : false
    const currentDateValueToCompare = isCurrentValueSmallerThanActualDate ? currentValue ?? midNight : midNight
    return currentDateValueToCompare
}

const uriRegex = /^https:\/\/data.gov.sk\/id\/.*$/

export const refIdentifierCreateTemplateUriSchema = (
    t: TFunction<'translation', undefined, 'translation'>,
    language: string,
    formDefaultValues: ConfigurationItemUiAttributes,
    attributes: Attribute[] | undefined,
) => {
    return object().shape({
        [RefTemplateUriFormTypeEnum.OWNER]: string().required(t('validation.required')),
        [RefTemplateUriFormTypeEnum.TEMPLATE_URI]: string().required(t('validation.required')),
        attributes: object().shape({
            [ATTRIBUTE_NAME.Gen_Profil_nazov]: string().when('isRequired', (_, current) => {
                if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_nazov))) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
            [ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov]: string().when('isRequired', (_, current) => {
                if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov))) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
            [ATTRIBUTE_NAME.Gen_Profil_popis]: string().when('isRequired', (_, current) => {
                if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_popis))) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
            [ATTRIBUTE_NAME.Profil_Individuum_zaklad_uri]: string()
                .when('isRequired', (_, current) => {
                    if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_zaklad_uri))) {
                        return current.required(t('validation.required'))
                    }
                    return current
                })
                .matches(uriRegex, t('validation.wrongRegex', { regexFormat: 'https://data.gov.sk/id/.*' })),
            [ATTRIBUTE_NAME.Profil_Individuum_hasVersion]: boolean().when('isRequired', (_, current) => {
                if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_hasVersion))) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
            [ATTRIBUTE_NAME.Profil_Individuum_deferenceTarget]: string().when('isRequired', (_, current) => {
                if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_deferenceTarget))) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
            [ATTRIBUTE_NAME.Profil_Individuum_id_regex_pattern]: string().when('isRequired', (_, current) => {
                if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_id_regex_pattern))) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
            [ATTRIBUTE_NAME.Profil_Individuum_platne_od]: date()
                .typeError(t('validation.required'))
                .when('isRequired', (_, current) => {
                    if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_platne_od))) {
                        return current.required(t('validation.required'))
                    }
                    return current
                })
                .transform((curr, orig) => (orig === '' ? null : curr))
                .min(
                    getCurrentDateValueToCompare(formDefaultValues, ATTRIBUTE_NAME.Profil_Individuum_platne_od),
                    `${t('validation.dateMustBeEqualOrGreaterThen')} ${formatDateForDefaultValue(
                        getCurrentDateValueToCompare(formDefaultValues, ATTRIBUTE_NAME.Profil_Individuum_platne_od).toString(),
                        'dd.MM.yyyy',
                    )}`,
                ),
            [ATTRIBUTE_NAME.Profil_Individuum_platne_do]: date()
                .nullable()
                .transform((curr, orig) => (orig === '' ? null : curr))
                .when('isRequired', (_, current) => {
                    if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_platne_do))) {
                        return current.required(t('validation.required'))
                    }
                    return current
                })
                .when(ATTRIBUTE_NAME.Profil_Individuum_platne_od, (from, yupSchema) => {
                    return DateTime.fromJSDate(new Date(`${from}`)).isValid
                        ? yupSchema.min(
                              from,
                              `${t('validation.dateMustBeGreaterThen')} ${getNameByAttribute(
                                  language,
                                  attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_Individuum_platne_od),
                              )}`,
                          )
                        : yupSchema
                }),
            [ATTRIBUTE_NAME.Profil_Individuum_parameter]: array()
                .of(string().trim().required(t('validation.required')))
                .ensure(),
            [ATTRIBUTE_NAME.Profil_Individuum_parameter_desc]: array()
                .of(string().trim().required(t('validation.required')))
                .ensure(),
        }),
    })
}

export const refIdentifierCreateDataItemSchema = (
    t: TFunction<'translation', undefined, 'translation'>,
    language: string,
    formDefaultValues: ConfigurationItemUiAttributes,
    attributes: Attribute[] | undefined,
) => {
    return object().shape({
        [RefDataItemFormTypeEnum.OWNER]: string().required(t('validation.required')),
        [RefDataItemFormTypeEnum.PO]: string().required(t('validation.required')),
        [RefDataItemFormTypeEnum.DATA_ITEM]: array()
            .of(string().required(t('validation.required')))
            .nullable(),
        attributes: object().shape({
            [ATTRIBUTE_NAME.Gen_Profil_nazov]: string().when('isRequired', (_, current) => {
                if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_nazov))) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
            [ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov]: string().when('isRequired', (_, current) => {
                if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov))) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
            [ATTRIBUTE_NAME.Gen_Profil_popis]: string().when('isRequired', (_, current) => {
                if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_popis))) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
            [ATTRIBUTE_NAME.Profil_DatovyPrvok_kod_datoveho_prvku]: string().when('isRequired', (_, current) => {
                if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_DatovyPrvok_kod_datoveho_prvku))) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
            [ATTRIBUTE_NAME.Profil_DatovyPrvok_typ_datoveho_prvku]: string().when('isRequired', (_, current) => {
                if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_DatovyPrvok_typ_datoveho_prvku))) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
            [ATTRIBUTE_NAME.Profil_DatovyPrvok_historicky_kod]: string().when('isRequired', (_, current) => {
                if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_DatovyPrvok_historicky_kod))) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
            [ATTRIBUTE_NAME.Profil_DatovyPrvok_zaciatok_ucinnosti]: date()
                .nullable()
                .typeError(t('validation.required'))
                .transform((curr, orig) => (orig === '' ? null : curr))
                .when('isRequired', (_, current) => {
                    if (
                        getRequiredByAttribute(
                            attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_DatovyPrvok_zaciatok_ucinnosti),
                        )
                    ) {
                        return current.required(t('validation.required'))
                    }
                    return current
                })
                .min(
                    getCurrentDateValueToCompare(formDefaultValues, ATTRIBUTE_NAME.Profil_DatovyPrvok_zaciatok_ucinnosti),
                    `${t('validation.dateMustBeEqualOrGreaterThen')} ${formatDateForDefaultValue(
                        getCurrentDateValueToCompare(formDefaultValues, ATTRIBUTE_NAME.Profil_DatovyPrvok_zaciatok_ucinnosti).toString(),
                        'dd.MM.yyyy',
                    )}`,
                ),
            [ATTRIBUTE_NAME.Profil_DatovyPrvok_koniec_ucinnosti]: date()
                .nullable()
                .transform((curr, orig) => (orig === '' ? null : curr))
                .when('isRequired', (_, current) => {
                    if (
                        getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_DatovyPrvok_koniec_ucinnosti))
                    ) {
                        return current.required(t('validation.required'))
                    }
                    return current
                })
                .when(ATTRIBUTE_NAME.Profil_DatovyPrvok_zaciatok_ucinnosti, (from, yupSchema) => {
                    return DateTime.fromJSDate(new Date(`${from}`)).isValid
                        ? yupSchema.min(
                              from,
                              `${t('validation.dateMustBeGreaterThen')} ${getNameByAttribute(
                                  language,
                                  attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_DatovyPrvok_zaciatok_ucinnosti),
                              )}`,
                          )
                        : yupSchema
                }),
        }),
    })
}

export const refIdentifierCreateOntologySchema = (
    t: TFunction<'translation', undefined, 'translation'>,
    language: string,
    formDefaultValues: ConfigurationItemUiAttributes,
    attributes: Attribute[] | undefined,
) => {
    return object().shape({
        [RefCatalogFormTypeEnum.OWNER]: string().required(t('validation.required')),
        [RefDataItemFormTypeEnum.PO]: string().required(t('validation.required')),
        attributes: object().shape({
            [ATTRIBUTE_NAME.Gen_Profil_nazov]: string().when('isRequired', (_, current) => {
                if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_nazov))) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
            [ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov]: string().required(t('validation.required')),
            [ATTRIBUTE_NAME.Gen_Profil_popis]: string().when('isRequired', (_, current) => {
                if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_popis))) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
            [ATTRIBUTE_NAME.Profil_Ontologia_uri_pre_ontologiu]: string().when('isRequired', (_, current) => {
                if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_URIKatalog_uri))) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
            [ATTRIBUTE_NAME.Profil_Ontologia_historicky_kod]: string().when('isRequired', (_, current) => {
                if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_URIKatalog_platne_od))) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
        }),
    })
}

export const refIdentifierCreateOntologyVersionSchema = (
    t: TFunction<'translation', undefined, 'translation'>,
    language: string,
    formDefaultValues: ConfigurationItemUiAttributes,
    attributes: Attribute[] | undefined,
) => {
    return object().shape({
        [RefOntologyVersionFormTypeEnum.OWNER]: string().required(t('validation.required')),
        [RefOntologyVersionFormTypeEnum.ONTOLOGY]: string().required(t('validation.required')),
        attributes: object().shape({
            [ATTRIBUTE_NAME.Gen_Profil_nazov]: string().when('isRequired', (_, current) => {
                if (
                    getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_uri_distribucie))
                ) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
            [ATTRIBUTE_NAME.Profil_VerziaOntologie_uri_distribucie]: string().when('isRequired', (_, current) => {
                if (
                    getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_uri_distribucie))
                ) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
            [ATTRIBUTE_NAME.Profil_VerziaOntologie_typ_media]: string().when('isRequired', (_, current) => {
                if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_typ_media))) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
            [ATTRIBUTE_NAME.Profil_VerziaOntologie_verzia]: string().when('isRequired', (_, current) => {
                if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_verzia))) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
            [ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_od]: date()
                .typeError(t('validation.required'))
                .transform((curr, orig) => (orig === '' ? null : curr))
                .when('isRequired', (_, current) => {
                    if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_od))) {
                        return current.required(t('validation.required'))
                    }
                    return current
                })
                .min(
                    getCurrentDateValueToCompare(formDefaultValues, ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_od),
                    `${t('validation.dateMustBeEqualOrGreaterThen')} ${formatDateForDefaultValue(
                        getCurrentDateValueToCompare(formDefaultValues, ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_od).toString(),
                        'dd.MM.yyyy',
                    )}`,
                ),
            [ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_do]: date()
                .nullable()
                .transform((curr, orig) => (orig === '' ? null : curr))
                .when('isRequired', (_, current) => {
                    if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_do))) {
                        return current.required(t('validation.required'))
                    }
                    return current
                })
                .when(ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_od, (from, yupSchema) => {
                    return DateTime.fromJSDate(new Date(`${from}`)).isValid
                        ? yupSchema.min(
                              from,
                              `${t('validation.dateMustBeGreaterThen')} ${getNameByAttribute(
                                  language,
                                  attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_od),
                              )}`,
                          )
                        : yupSchema
                }),
            [ATTRIBUTE_NAME.Profil_VerziaOntologie_download_url]: string().when('isRequired', (_, current) => {
                if (getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_download_url))) {
                    return current.required(t('validation.required'))
                }
                return current
            }),
        }),
    })
}
