import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { ConfigurationItemUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { ENTITY_KS } from '@isdd/metais-common/constants'
import { useAttributesHook } from '@isdd/metais-common/hooks/useAttributes.hook'
import { useCiHook } from '@isdd/metais-common/hooks/useCi.hook'
import React, { useState } from 'react'
import { ButtonGroupRow, Button } from '@isdd/idsk-ui-kit/index'
import { QueryFeedback } from '@isdd/metais-common/index'
import { useTranslation } from 'react-i18next'
import { NavigationSubRoutes } from '@isdd/metais-common/navigation/routeNames'
import { useNavigate } from 'react-router-dom'

import { CreateKSEntity } from '@/components/create-entity/CreateKSEntity'
import { PublicAuthorityState } from '@/hooks/usePublicAuthorityAndRole.hook'
import { CiPermissionsWrapper } from '@/components/permissions/CiPermissionsWrapper'

interface IEditKS {
    onPrevious: () => void
    publicAuthorityState: PublicAuthorityState
    ciItemData?: ConfigurationItemUi
}

export const EditKS: React.FC<IEditKS> = ({ onPrevious, ciItemData, publicAuthorityState }) => {
    const ciItemAttributes = ciItemData?.attributes
    const [isSuccess, setIsSuccess] = useState(false)
    const { t } = useTranslation()
    const navigate = useNavigate()
    const entityIdToUpdate = {
        cicode: ciItemAttributes?.[ATTRIBUTE_NAME.Gen_Profil_kod_metais],
        ciurl: ciItemAttributes?.[ATTRIBUTE_NAME.Gen_Profil_ref_id],
    }
    const { isLoading: isCiItemLoading, isError: isCiItemError, gestorData } = useCiHook(ciItemData?.uuid)
    const { constraintsData, ciTypeData, unitsData, isLoading: isAttLoading, isError: isAttError } = useAttributesHook(ENTITY_KS)

    if (ciItemAttributes) {
        ciItemAttributes['KS_Profil_UPVS_stav_publikovania'] = 'c_stav_publikovania_sluzby.2'
    }

    const ownerId = gestorData?.[0].gid ?? ''
    const poUUID = gestorData?.[0].owner ?? ''
    return (
        <QueryFeedback loading={isCiItemLoading || isAttLoading} error={isCiItemError || isAttError} withChildren>
            <CiPermissionsWrapper entityId={ciItemData?.uuid ?? ''} entityName={ENTITY_KS}>
                <CreateKSEntity
                    isSuccess={isSuccess}
                    setIsSuccess={setIsSuccess}
                    key={ciItemData?.uuid}
                    updateCiItemId={ciItemData?.uuid}
                    data={{
                        ownerId: ownerId,
                        poUUID,
                        attributesData: { ciTypeData, constraintsData, unitsData },
                        generatedEntityId: entityIdToUpdate,
                    }}
                    entityName={ENTITY_KS}
                    defaultItemAttributeValues={ciItemAttributes}
                    isLoading={isCiItemLoading || isAttLoading}
                    publicAuthorityState={publicAuthorityState}
                    withCancelButton={false}
                    submitInModal
                />
            </CiPermissionsWrapper>
            <ButtonGroupRow>
                <Button
                    label={isSuccess ? t('KSPublishHelper.backToList') : t('form.back')}
                    onClick={() => (isSuccess ? navigate(NavigationSubRoutes.KONCOVE_SLUZBY) : onPrevious())}
                    variant="secondary"
                />
            </ButtonGroupRow>
        </QueryFeedback>
    )
}
