import { BaseModal, TextBody } from '@isdd/idsk-ui-kit/index'
import { DMS_DOWNLOAD_FILE } from '@isdd/metais-common/api/constants'
import { useGenerateReferenceRegisterByUuidHook } from '@isdd/metais-common/api/generated/reference-registers-swagger'
import { ModalButtons, QueryFeedback } from '@isdd/metais-common/index'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { downloadFile } from '@/components/views/documents/utils'

interface IRefRegisterGeneratePropDialog {
    openGeneratePropDialog: boolean
    setOpenGeneratePropDialog: (state: boolean) => void
    entityId: string
}

export const RefRegisterGeneratePropDialog = ({ openGeneratePropDialog, setOpenGeneratePropDialog, entityId }: IRefRegisterGeneratePropDialog) => {
    const { t } = useTranslation()
    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const generateDoc = useGenerateReferenceRegisterByUuidHook()

    const onSubmit = useCallback(() => {
        setIsLoading(true)
        setError(false)
        generateDoc(entityId)
            .then((generatedDocs) => {
                downloadFile(`${DMS_DOWNLOAD_FILE}${generatedDocs?.uuid}`, generatedDocs?.filename ?? '')
                setOpenGeneratePropDialog(false)
            })
            .catch(() => setError(true))
            .finally(() => setIsLoading(false))
    }, [entityId, generateDoc, setOpenGeneratePropDialog])

    return (
        <BaseModal isOpen={openGeneratePropDialog} close={() => setOpenGeneratePropDialog(false)}>
            <QueryFeedback loading={isLoading} error={error} withChildren>
                <TextBody size="S">{t('refRegisters.header.generateProp1')}</TextBody>
                <TextBody size="S">{t('refRegisters.header.generateProp2')}</TextBody>
                <ModalButtons
                    submitButtonLabel={t('refRegisters.header.generateProp')}
                    onSubmit={onSubmit}
                    closeButtonLabel={t('evaluation.cancelBtn')}
                    onClose={() => setOpenGeneratePropDialog(false)}
                />
            </QueryFeedback>
        </BaseModal>
    )
}
