import { CheckBox, Input, SimpleSelect, TextBody } from '@isdd/idsk-ui-kit/index'
import { ApiAutoincrementType, ApiCodelistAutoincrementType } from '@isdd/metais-common/api/generated/codelist-repo-swagger'
import React from 'react'
import { FormState, UseFormRegister, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import styles from './requestList.module.scss'

import { IRequestForm } from '@/componentHelpers/requests'
import { onKeyDownOfNumberInput } from '@/components/attribute-input/attributeInputHelpers'
import { RequestFormEnum } from '@/components/views/requestLists/CreateRequestView'

type AutoIncrementProps = {
    register: UseFormRegister<IRequestForm>
    formState: FormState<IRequestForm>
    autoIncrement: string
    isAutoIncremenetValid: boolean
    setValue: UseFormSetValue<IRequestForm>
    autoincrementTypes?: ApiAutoincrementType[]
    canEdit: boolean
    seed: number
}

export const AutoIncrement: React.FC<AutoIncrementProps> = ({
    register,
    formState,
    autoIncrement,
    isAutoIncremenetValid,
    setValue,
    autoincrementTypes,
    canEdit,
    seed,
}) => {
    const { t } = useTranslation()

    return (
        <fieldset className={styles.fieldset}>
            <legend className="govuk-visually-hidden">{t('autoincrement.legend')}</legend>
            <Input
                {...register(RequestFormEnum.PREFIX)}
                error={formState.errors[RequestFormEnum.PREFIX]?.message}
                label={t('autoincrement.prefix')}
                disabled={!canEdit}
            />
            <div className={styles.row}>
                <div className="govuk-form-group">
                    <CheckBox {...register(RequestFormEnum.AUTOINCREMENT_VALID)} label={t('autoincrement.valid')} disabled={!canEdit} />
                </div>

                <SimpleSelect
                    key={seed}
                    defaultValue={formState.defaultValues?.type}
                    label={t('autoincrement.type.label')}
                    name={RequestFormEnum.AUTOINCREMENT_TYPE}
                    options={
                        autoincrementTypes?.map((type) => ({
                            label: type.value ?? '',
                            value: type.type ?? '',
                        })) ?? []
                    }
                    disabled={!isAutoIncremenetValid || !canEdit}
                    onChange={(e) => setValue(RequestFormEnum.AUTOINCREMENT_TYPE, e as ApiCodelistAutoincrementType)}
                    isClearable={false}
                />

                <Input
                    {...register(RequestFormEnum.AUTOINCREMENT_CHAR_COUNT)}
                    type="number"
                    label={t('autoincrement.charCount')}
                    //maxLength or min/max does not prevent user to add numbers using keyboard
                    onInput={(e) => {
                        if (e.currentTarget.value == '0') {
                            e.currentTarget.value = ''
                        } else {
                            e.currentTarget.value = e.currentTarget.value.slice(0, 1)
                        }
                    }}
                    disabled={!isAutoIncremenetValid || !canEdit}
                    min={1}
                    max={9}
                    maxLength={1}
                    hint={t('autoincrement.charCountHint')}
                    onKeyDown={onKeyDownOfNumberInput}
                />
            </div>

            <TextBody className={styles.grey}>{t('autoincrement.preview', { autoincrement: autoIncrement })}</TextBody>
        </fieldset>
    )
}
