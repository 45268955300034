import React, { DetailedHTMLProps, forwardRef, useId } from 'react'
import { FieldError } from 'react-hook-form'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Fieldset } from '@isdd/idsk-ui-kit/index'
interface IRadioWithLabelProps extends DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    id?: string
    hideLegend?: boolean
    legend?: string | React.ReactNode
    hint?: string
    error?: FieldError
    disabled?: boolean
    inline?: boolean
    small?: boolean
    withoutFieldset?: boolean
}
export const RadioGroup = forwardRef<HTMLDivElement, IRadioWithLabelProps>(
    ({ children, id, legend, hideLegend, hint, error, disabled, className, inline, small, withoutFieldset, ...rest }, ref) => {
        const { t } = useTranslation()
        const uId = useId()
        const aID = id ?? `input-${uId}` // NVDA has problems with ID starting with `useID` `::`, needs to be prefixed
        const hintId = `${aID}-hint`
        const errorId = `${aID}-error`
        const radioGroupContent = (
            <>
                <span id={hintId} className={classNames({ 'govuk-visually-hidden': !hint, 'govuk-hint': !!hint })}>
                    {hint}
                </span>
                <span id={errorId} className={classNames({ 'govuk-visually-hidden': !error, 'govuk-error-message': !!error })}>
                    {error && error.message && (
                        <>
                            <span className="govuk-visually-hidden">{t('error')}</span>
                            {error?.message}
                        </>
                    )}
                </span>
                <div
                    ref={ref}
                    aria-describedby={hint ? hintId : undefined}
                    aria-disabled={disabled}
                    className={classNames('govuk-radios', { 'govuk-radios--inline': inline, 'govuk-radios--small': small })}
                    {...rest}
                >
                    {children}
                </div>
            </>
        )
        return withoutFieldset ? (
            <div className={classNames('govuk-form-group', className, { 'govuk-form-group--error': !!error })}>{radioGroupContent}</div>
        ) : (
            <Fieldset
                aria-errormessage={errorId}
                aria-describedby={`${hintId} ${errorId}`}
                aria-invalid={!!(error && error.message)}
                legend={legend}
                hideLegend={hideLegend}
                className={classNames(className, 'govuk-form-group', { 'govuk-form-group--error': !!error })}
            >
                {radioGroupContent}
            </Fieldset>
        )
    },
)
