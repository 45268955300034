import { ATTRIBUTE_NAME, DMS_DOWNLOAD_FILE } from '@isdd/metais-common/api'
import { ApiStandardRequest } from '@isdd/metais-common/api/generated/standards-swagger'
import { Attribute } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { getInfoGuiProfilStandardRequest, getLabelGuiProfilStandardRequest } from '@isdd/metais-common/api/hooks/containers/containerHelpers'
import { AttachmentIcon, DownloadIcon } from '@isdd/metais-common/assets/images'
import { InformationGridRow } from '@isdd/metais-common/components/info-grid-row/InformationGridRow'
import { useStateMachine } from '@isdd/metais-common/components/state-machine/hooks/useStateMachine'
import { IS_KOORDINATOR } from '@isdd/metais-common/constants'
import { useAbilityContext } from '@isdd/metais-common/hooks/permissions/useAbilityContext'
import { Actions } from '@isdd/metais-common/hooks/permissions/useUserAbility'
import { generateMetaString, useGetFileSize } from '@isdd/metais-common/hooks/useGetFileSize'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import styles from '@/components/entities/draftslist/draftsListCreateForm.module.scss'
import { StandardDraftsStateMachine } from '@/pages/standardization/draftslist/[entityId]/form'

interface Props {
    data: {
        requestData?: ApiStandardRequest
        guiAttributes?: Attribute[]
    }
}
export const DraftListRelatedDocuments: React.FC<Props> = ({ data }) => {
    const { requestData, guiAttributes } = data
    const { t, i18n } = useTranslation()
    const stateContext = useContext(StandardDraftsStateMachine)
    const stateMachine = useStateMachine({ stateContext })
    const ability = useAbilityContext()
    const isKoordinator = ability?.can(Actions.HAS_ROLE, IS_KOORDINATOR)
    const currentState = stateMachine?.getCurrentState()
    const [existingFiles, setExistingFiles] = useState<
        {
            uuid: string
            size: string
            extension: string
            metaComponent: string
        }[]
    >([])
    const showLinks = isKoordinator && currentState !== 'REQUESTED' && requestData?.links && requestData?.links?.length > 0
    const showAttachments = existingFiles && existingFiles.length > 0
    const getFilesMeta = useGetFileSize()
    useEffect(() => {
        const filterFiles = async () => {
            setExistingFiles(await getFilesMeta.loadData(requestData?.attachments?.map((d) => d.attachmentId ?? '') ?? []))
        }
        filterFiles()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestData])

    const linkElements = showLinks
        ? requestData?.links?.map((link) => (
              <div key={link.id} className={styles.displayFlexCenter}>
                  <img src={AttachmentIcon} alt="" />
                  <Link to={link?.url ?? ''} state={{ from: location }} target="_blank" className="govuk-link">
                      {`${link?.linkDescription as string} ${generateMetaString(link?.linkType, link?.linkSize)}`}
                      <br />
                  </Link>
              </div>
          ))
        : undefined

    const attachmentElements = showAttachments
        ? requestData?.attachments
              ?.filter((a) => existingFiles.map((s) => s.uuid).includes(a.attachmentId ?? ''))
              .map((attachment) => (
                  <div key={attachment.id} className={styles.displayFlexCenter}>
                      <img src={DownloadIcon} alt="" />
                      <Link to={`${DMS_DOWNLOAD_FILE}${attachment?.attachmentId}`} state={{ from: location }} target="_blank" className="govuk-link">
                          {`${attachment?.attachmentName as string} ${
                              existingFiles.find((e) => e.uuid == attachment?.attachmentId)?.metaComponent ?? ''
                          }`}
                          <br />
                      </Link>
                  </div>
              ))
        : undefined
    const relatedDocuments = [...(linkElements ?? []), ...(attachmentElements ?? [])]

    return (
        <InformationGridRow
            key={ATTRIBUTE_NAME.relatedDocuments}
            label={getLabelGuiProfilStandardRequest(i18n.language, ATTRIBUTE_NAME.relatedDocuments, guiAttributes) ?? ''}
            value={relatedDocuments && relatedDocuments?.length > 0 ? <>{relatedDocuments}</> : <> {t('DraftsList.detail.noDocuments')}</>}
            tooltip={getInfoGuiProfilStandardRequest(i18n.language, ATTRIBUTE_NAME.relatedDocuments, guiAttributes) ?? ''}
        />
    )
}
