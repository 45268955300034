import { IColumn } from '@isdd/idsk-ui-kit'

import { DRAFT, INVALIDATED } from '@isdd/metais-common/constants'
import { FilterAttribute } from '@isdd/metais-common/components/dynamicFilterAttributes/DynamicFilterAttributes'
import { FilterMetaAttributesUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { CiType } from '@isdd/metais-common/api/generated/types-repo-swagger'

export const filterAttributesBasedOnIgnoreList = (filterAttributes: FilterAttribute[], ignoreListOfNames: string[]): FilterAttribute[] => {
    return filterAttributes.filter((item) => !ignoreListOfNames?.includes(item.name ?? ''))
}

export const getLiableEntityFromPreferences = (myPO: string | undefined) => {
    return myPO ? [myPO] : undefined
}

export const getStateFromPreferences = (showInvalidatedItems: boolean) => {
    return showInvalidatedItems ? [DRAFT, INVALIDATED] : [DRAFT]
}

type MetaAttribuesForFilterArgs = {
    myPO: string
    evidenceStatus: string[]
    showInvalidatedItems: boolean
    metaAttributeFilters: FilterMetaAttributesUi | undefined
}

export const getMetaAttributesForCiFilter = ({ myPO, metaAttributeFilters, showInvalidatedItems, evidenceStatus }: MetaAttribuesForFilterArgs) => {
    const liableEntity = getLiableEntityFromPreferences(myPO)
    const state = evidenceStatus?.length ? evidenceStatus : getStateFromPreferences(showInvalidatedItems)

    const metaAttributes = { state, liableEntity, ...metaAttributeFilters }
    return metaAttributes
}

export const getSearchFields = (columnListData: IColumn | undefined, ciSchema: CiType): string[] => {
    const allowedTypes = {
        string: 'STRING',
        html: 'HTML',
        email: 'EMAIL',
        phone: 'PHONE',
    }

    const recordAttributesTypes: Record<string, string> = [
        ...(ciSchema?.attributes ?? []),
        ...(ciSchema?.attributeProfiles?.flatMap((prof) => prof?.attributes) ?? []),
    ].reduce<Record<string, string>>((acc, obj) => {
        if (obj?.attributeTypeEnum && obj?.technicalName) {
            return { ...acc, [obj?.technicalName]: obj?.attributeTypeEnum }
        }
        return acc
    }, {})

    const filteredColumnList =
        columnListData?.attributes
            ?.filter((a) => Object.values(allowedTypes).includes(recordAttributesTypes[a?.name ?? ''] ?? ''))
            .map((a) => a.name ?? '') ?? []

    return filteredColumnList
}
