import { PaginatorWrapper } from '@isdd/idsk-ui-kit/paginatorWrapper/PaginatorWrapper'
import { Table, TableHandle } from '@isdd/idsk-ui-kit/table/Table'
import { ColumnSort, IFilter, Pagination } from '@isdd/idsk-ui-kit/types'
import { CellContext, ColumnOrderState, Updater } from '@tanstack/react-table'
import React, { useRef } from 'react'
import { HTML_TYPE } from '@isdd/metais-common/constants'
import { SafeHtmlComponent } from '@isdd/idsk-ui-kit/save-html-component/SafeHtmlComponent'

import { EnhancedReportResultObjectResult } from '@/components/containers/ReportsDetailContainer'

interface IReportTable {
    data?: EnhancedReportResultObjectResult
    sort: ColumnSort[]
    pagination: Pagination
    handleFilterChange: (filter: IFilter) => void
    storeColumnsOrder: (updaterOrValue: Updater<ColumnOrderState>) => void
}

interface IRowType {
    [key: string]: string
}

export const ReportTable: React.FC<IReportTable> = ({ data, pagination, sort, handleFilterChange, storeColumnsOrder }) => {
    const tableHandle = useRef<TableHandle>(null)
    const columnsFromApi =
        data?.headers
            ?.filter((h) => h.visible)
            .map((header) => {
                return {
                    accessorFn: (row: IRowType) => row?.[header?.name],
                    header: () => header?.name ?? '',
                    id: header?.name ?? '',
                    cell: (ctx: CellContext<IRowType, unknown>) =>
                        header.type === HTML_TYPE ? <SafeHtmlComponent dirtyHtml={ctx?.getValue?.() as string} /> : ctx?.getValue(),
                    meta: { getCellContext: (ctx: CellContext<IRowType, unknown>) => ctx?.getValue?.() },
                    size: 200,
                    enableSorting: false,
                }
            }) ?? []

    return (
        <>
            <Table
                handleRef={tableHandle}
                columns={columnsFromApi}
                data={data?.rows}
                sort={sort}
                onSortingChange={(columnSort) => {
                    handleFilterChange({ sort: columnSort })
                }}
                onColumnOrderChange={(updaterOrValue) => storeColumnsOrder(updaterOrValue)}
                canDrag
            />
            <PaginatorWrapper {...pagination} handlePageChange={handleFilterChange} />
        </>
    )
}
