import { ObjectSchema, array, number, object, string } from 'yup'
import { TFunction } from 'i18next'
import { ApiReferenceRegisterState } from '@isdd/metais-common/api/generated/reference-registers-swagger'
import { REGEX_EMAIL, REGEX_TEL } from '@isdd/metais-common/constants'

import { effectiveToGreaterThanEffectiveFrom } from '@/components/views/codeLists/useEditCodeListSchemas'

export interface IRefRegisterCreateFormData {
    refRegisters: {
        codeMetaIS?: string
        creator?: string
        sourceRegister?: string
        name?: string
        name_en?: string
        refId?: string
        effectiveFrom?: string
        effectiveTo?: string
        state?: ApiReferenceRegisterState
        manager: {
            PO?: string
            email?: string
            firstName?: string
            lastName?: string
            phoneNumber?: string
        }
        registrar: {
            PO?: string
            email?: string
            firstName?: string
            lastName?: string
            phoneNumber?: string
        }
        additionalData?: string
    }
}

export const createRefRegisterSchema = (
    t: TFunction<'translation', undefined, 'translation'>,
    showCreatorForm: boolean,
    showSourceRegisterForm: boolean,
): ObjectSchema<IRefRegisterCreateFormData> => {
    return object().shape({
        refRegisters: object().shape({
            creator: string().when([], {
                is: () => showCreatorForm,
                then: (schema) => schema.required(t('validation.required')),
                otherwise: (schema) => schema.notRequired(),
            }),
            name: string().required(t('validation.required')),
            name_en: string(),
            sourceRegister: string().when([], {
                is: () => showSourceRegisterForm,
                then: (schema) => schema.required(t('validation.required')),
                otherwise: (schema) => schema.notRequired(),
            }),
            codeMetaIS: string().required(t('validation.required')),
            refId: string().required(t('validation.required')),
            effectiveFrom: string().required(t('validation.required')),
            effectiveTo: string().when('effectiveFrom', {
                is: (value: string | undefined) => value && value.length > 0,
                then: () => string().test('largerThan', t('codeListList.requestValidations.dateGreaterThan'), effectiveToGreaterThanEffectiveFrom),
            }),
            manager: object().shape({
                lastName: string().required(t('validation.required')),
                firstName: string().required(t('validation.required')),
                phoneNumber: string().matches(REGEX_TEL, t('validation.invalidPhone')).required(t('validation.required')),
                email: string().matches(REGEX_EMAIL, t('validation.invalidEmail')).required(t('validation.required')),
            }),
            registrar: object().shape({
                PO: string().required(t('validation.required')),
                lastName: string().required(t('validation.required')),
                firstName: string().required(t('validation.required')),
                phoneNumber: string().matches(REGEX_TEL, t('validation.invalidPhone')).required(t('validation.required')),
                email: string().matches(REGEX_EMAIL, t('validation.invalidEmail')).required(t('validation.required')),
            }),
            additionalData: string().required(t('validation.required')),
        }),
    })
}

export const createRefRegItemSchema = (t: TFunction<'translation', undefined, 'translation'>) => {
    return object().shape({
        itemName: string().required(t('validation.required')),
        order: number().typeError(t('validation.mustBeNumber')).required(t('validation.required')),
        referenceRegisterGroup: object().shape({
            groupName: string(),
        }),
        referenceRegisterSubGroup: object().shape({
            groupName: string(),
        }),
        refID: string().optional(),
        subjectIdentification: string(),
        dataElementRefID: string(),
        note: string(),
        sourceReferenceHolders: array(),
    })
}
