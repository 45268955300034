import { TextHeading } from '@isdd/idsk-ui-kit/index'
import { Stepper } from '@isdd/idsk-ui-kit/stepper/Stepper'
import { ISection, IStepLabel } from '@isdd/idsk-ui-kit/stepper/StepperSection'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfigurationItemUi, HierarchyRightsUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { ENTITY_KS } from '@isdd/metais-common/constants'
import { GidRoleData } from '@isdd/metais-common/api/generated/iam-swagger'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'

import { SelectPOView } from './SelectPOView'
import { SelectPublishType } from './SelectPublishType'
import { SelectKSService } from './SelectKSService'
import { EditKS } from './EditKS'
import { AddNewPORelation } from './AddNewPORelation'

import { useGetEntityParamsFromUrl } from '@/componentHelpers/ci'
import { useCiCreateEntityHook } from '@/hooks/useCiCreateEntity.hook'
import { CiPermissionsWrapper } from '@/components/permissions/CiPermissionsWrapper'

export enum PublishType {
    SELF = 'self',
    GENERIC = 'generic',
}

export const KSHelperView: React.FC = () => {
    const { t } = useTranslation()
    const { entityName } = useGetEntityParamsFromUrl()

    const [selectedType, setSelectedType] = useState<PublishType>(PublishType.SELF)
    const [selectedKS, setSelectedKS] = useState<ConfigurationItemUi>()
    const { publicAuthorityState, ciTypeName } = useCiCreateEntityHook({ entityName: entityName ?? '', omitDocumentTile: true })
    const [sections, setSections] = useState<ISection[]>([])
    const [selectedPO, setSelectedPO] = useState<HierarchyRightsUi | null>(null)
    const [selectedRole, setSelectedRole] = useState<GidRoleData | null>(null)
    const handleSectionOpen = (id: string) => {
        setSections((prev) => prev.map((item) => (item.id === id ? { ...item, isOpen: !item.isOpen } : { ...item, isOpen: false })))
    }

    const { setIsActionSuccess } = useActionSuccess()

    const getDefaultSteps = [
        {
            title: t('createEntity.publicAuthority'),
            id: 'PO',
            isOpen: true,
            stepLabel: { label: '1', variant: 'circle' } as IStepLabel,
            content: (
                <SelectPOView
                    onNext={() => {
                        handleSectionOpen('serviceType')
                    }}
                    setSelectedPublicAuthority={setSelectedPO}
                    setSelectedRole={setSelectedRole}
                    omitDocumentTile
                />
            ),
        },
        {
            title: t('monitoringServices.table.serviceType'),
            id: 'serviceType',
            stepLabel: { label: '2', variant: 'circle' } as IStepLabel,
            content: (
                <>
                    <SelectPublishType
                        onNext={() => {
                            handleSectionOpen('KSList')
                        }}
                        onSelect={setSelectedType}
                        onPrevious={() => handleSectionOpen('PO')}
                    />
                </>
            ),
        },
        {
            title: t('titles.ciList', { ci: ciTypeName }),
            id: 'KSList',
            stepLabel: { label: '3', variant: 'circle' } as IStepLabel,
            content: (
                <>
                    <SelectKSService
                        onNext={() => {
                            handleSectionOpen(selectedType == PublishType.SELF ? 'EditKS' : 'AddNewRel')
                        }}
                        selectedType={selectedType}
                        onPrevious={() => handleSectionOpen('serviceType')}
                        onSelect={(value) => {
                            setSelectedKS(value)
                        }}
                    />
                </>
            ),
        },
        {
            title: t('KSPublishHelper.button'),
            hide: selectedType == PublishType.GENERIC,
            id: 'EditKS',
            stepLabel: { label: '4', variant: 'circle' } as IStepLabel,
            last: true,
            content: (
                <>
                    <EditKS
                        publicAuthorityState={publicAuthorityState}
                        key={selectedKS?.uuid}
                        ciItemData={selectedKS}
                        onPrevious={() => handleSectionOpen('KSList')}
                    />
                </>
            ),
        },
        {
            title: t('KSPublishHelper.button'),
            id: 'AddNewRel',
            hide: selectedType == PublishType.SELF,
            stepLabel: { label: '4', variant: 'circle' } as IStepLabel,
            last: true,
            content: (
                <>
                    <CiPermissionsWrapper entityId={selectedKS?.uuid ?? ''} entityName={ENTITY_KS}>
                        <AddNewPORelation
                            selectedRole={selectedRole}
                            key={selectedKS?.uuid}
                            ciItemData={selectedKS ?? {}}
                            onPrevious={() => {
                                handleSectionOpen('KSList')
                                setIsActionSuccess({ value: false, path: `/ci/KS/publish` })
                                setIsActionSuccess({ value: false, path: `/ci/KS/publish` })
                            }}
                        />
                    </CiPermissionsWrapper>
                </>
            ),
        },
    ]

    if (sections.length == 0) {
        setSections(getDefaultSteps)
    }

    //KS LIST TAB
    useEffect(() => {
        setSelectedKS(undefined)
        sections.splice(2, 1, {
            title: t('titles.ciList', { ci: ciTypeName }),
            id: 'KSList',
            stepLabel: { label: '3', variant: 'circle' } as IStepLabel,
            content: (
                <>
                    <SelectKSService
                        key={selectedPO?.poUUID}
                        onNext={() => handleSectionOpen(selectedType == PublishType.SELF ? 'EditKS' : 'AddNewRel')}
                        onPrevious={() => handleSectionOpen('serviceType')}
                        onSelect={setSelectedKS}
                        selectedType={selectedType}
                    />
                </>
            ),
        }),
            setSections(sections)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPO?.poUUID])

    useEffect(() => {
        sections.splice(2, 1, {
            title: t('titles.ciList', { ci: ciTypeName }),
            id: 'KSList',
            stepLabel: { label: '3', variant: 'circle' } as IStepLabel,
            content: (
                <>
                    <SelectKSService
                        onNext={() => handleSectionOpen(selectedType == PublishType.SELF ? 'EditKS' : 'AddNewRel')}
                        onPrevious={() => handleSectionOpen('serviceType')}
                        onSelect={setSelectedKS}
                        selectedType={selectedType}
                    />
                </>
            ),
        }),
            setSections(sections)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedType])

    //EDIT KS TAB
    useEffect(() => {
        sections.splice(3, 1, {
            title: t('KSPublishHelper.button'),
            hide: selectedType == PublishType.GENERIC,
            id: 'EditKS',
            stepLabel: { label: '4', variant: 'circle' } as IStepLabel,
            last: true,
            content: (
                <>
                    <EditKS
                        publicAuthorityState={publicAuthorityState}
                        key={selectedKS?.uuid}
                        ciItemData={selectedKS}
                        onPrevious={() => handleSectionOpen('KSList')}
                    />
                </>
            ),
        }),
            setSections(sections)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedKS, selectedType])

    //NEW REL TAB
    useEffect(() => {
        sections.splice(4, 1, {
            title: t('KSPublishHelper.button'),
            id: 'AddNewRel',
            stepLabel: { label: '4', variant: 'circle' } as IStepLabel,
            last: true,
            hide: selectedType == PublishType.SELF,

            content: (
                <>
                    <CiPermissionsWrapper entityId={selectedKS?.uuid ?? ''} entityName={ENTITY_KS}>
                        <AddNewPORelation
                            selectedRole={selectedRole}
                            key={selectedKS?.uuid}
                            ciItemData={selectedKS ?? {}}
                            onPrevious={() => {
                                handleSectionOpen('KSList')
                                setIsActionSuccess({ value: false, path: `/ci/KS/publish` })
                            }}
                        />
                    </CiPermissionsWrapper>
                </>
            ),
        }),
            setSections(sections)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedKS, selectedPO, selectedType])

    return (
        <>
            <TextHeading size="L">{t('KSPublishHelper.button')}</TextHeading>
            <Stepper subtitleTitle="" stepperList={sections} />
        </>
    )
}
