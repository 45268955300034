//@ts-nocheck
/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * MetaIS IAM
 * OpenAPI spec version: latest
 */
import { useQuery, useMutation } from '@tanstack/react-query'
import type { UseQueryOptions, UseMutationOptions, QueryFunction, MutationFunction, UseQueryResult, QueryKey } from '@tanstack/react-query'
import { useIAmSwaggerClient } from '../hooks/useIAmSwaggerClient'
export type FindAllWithParamsParams = {
    search: string
}

export type GetStdHistoryParams = {
    groupShortName: string
    createdAt: number
}

export type FindAllWithIdentities1Params = {
    expression: string
}

export type FindAll4Params = {
    orderBy?: string
    direction?: string
}

export type GetPages3Params = {
    identity?: string
    organization?: string
}

export type FindRelatedIdentitiesAndCountParams = {
    orderBy?: string
    desc?: boolean
    expression?: string
    memberUuid?: string
    poUuid?: string
    role?: string
    substitutedBy?: string
    identityState?: string
    page?: string
    perPage?: string
}

export type FindRelatedIdentities3Params = {
    expression?: string
}

export type FindRelatedIdentities2Params = {
    orderBy?: string
    desc?: boolean
    expression?: string
    memberUuid?: string
    poUuid?: string
    role?: string
    substitutedBy?: string
    page?: string
    perPage?: string
}

export type AvailableLoginParams = {
    login: string
}

export type CountByLoginLikeParams = {
    login: string
}

export type Find1Params = {
    expression: string
    orderBy?: string
    direction?: string
    orgId?: string
    roleUuid?: string
    searchIn?: string
    state?: string
}

export type FindPagesParams = {
    expression: string
    orgId?: string
    roleUuid?: string
    searchIn?: string
    state?: string
}

export type FindByRolesParams = {
    expression?: string
    orderBy?: string
    direction?: string
    orgId?: string
    roles?: string
    searchIn?: string
    state?: string
}

export type FindPagesByRolesParams = {
    expression?: string
    orgId?: string
    roles?: string
    searchIn?: string
    state?: string
}

export type FindNotRelatedWithParams = {
    expression: string
    groupUuid: string
    relName: string
    orderBy?: string
    direction?: string
    orgId?: string
    roleUuid?: string
    searchIn?: string
    state?: string
}

export type FindAll3Params = {
    orderBy?: string
    direction?: string
}

export type FindRoleOrgRelationsParams = {
    readonly?: boolean
}

export type ReadChangesParams = {
    page: number
    perPage: number
    cmdbId?: string[]
    state?: string
}

export type GetOwnerRolesFromLoginParams = {
    login: string
    gid: string
}

export type IsAssignedInHierarchyParams = {
    asParent?: boolean
}

export type FindAll2Params = {
    orderBy?: string
    direction?: string
}

export type FindRelatedRolesParams = {
    checkDeletable?: boolean
}

export type FindAll21200 = Organization | Organization[]

export type FindAll21Params = {
    orderBy?: string
    direction?: string
    cmdbId: string
}

export type GetRolesForOrganizationsParams = {
    checkDeletable?: boolean
}

export type FindAll1Params = {
    orderBy?: string
    direction?: string
}

export type FindByNameWithParamsParams = {
    name: string
    group: string
    system: string
    orderBy?: string
    direction?: string
}

export type FindByNameWithParamsCountParams = {
    name: string
    group?: string
    system?: string
}

export type Find2111200 = Group | Group[]

export type Find2111Params = {
    expression?: string
    identity?: string
    organization?: string
    sortBy?: string
    ascending?: boolean
    shortName?: string
    orderBy?: string
    direction?: string
    name?: string
}

export type ExportIdentitiesState = (typeof ExportIdentitiesState)[keyof typeof ExportIdentitiesState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExportIdentitiesState = {
    REGISTERED: 'REGISTERED',
    ACTIVATED: 'ACTIVATED',
    VERIFIED: 'VERIFIED',
    BLOCKED: 'BLOCKED',
    DEACTIVATED: 'DEACTIVATED',
    ANONYMIZED: 'ANONYMIZED',
} as const

export type ExportIdentitiesParams = {
    expression?: string
    orgId?: string
    roleUuid?: string
    state?: ExportIdentitiesState
    orderBy?: string
    direction?: string
}

export type FindAll311200 = Identity | Identity[]

export type FindAll311Params = {
    orderBy?: string
    direction?: string
    login: string
    email: string
}

export type FindAll11200 = Role | Role[]

export type FindAll11Params = {
    orderBy?: string
    direction?: string
    name?: string
}

export interface Configuration {
    issuerHostname?: string
    issuerPort?: string
    oauthAdminUiClientId?: string
    oauthAdminUiScope?: string
    oauthAdminUiRedirectHostname?: string
    oauthAdminUiRedirectPort?: string
    oauthAdminUiRedirectUri?: string
    portalAddress?: string
}

export interface RoleOrgGroupWithParams {
    page?: number
    limit?: number
    total?: number
    gids?: RoleOrgGroup[]
}

export interface Person {
    firstName?: string
    lastName?: string
}

export interface StdHistory {
    identity?: Person
    orgName?: string
    roleName?: string
    roleDesc?: string
    substitutedBy?: Person[]
}

export interface IdentityInGroupData {
    identity?: Identity
    gids?: RoleOrgIdentity[]
    substitutedBy?: Identity[]
}

export interface GroupWithIdentities {
    group?: Group
    identities?: IdentityInGroupData[]
}

export interface IdentitiesInGroupAndCount {
    list?: IdentityInGroupData[]
    count?: number
}

export interface IdentityTermsWithEntity {
    uuid?: string
    licenceTermsAccepted?: boolean
    licenceTermsAcceptedAt?: number
}

export interface GidData {
    orgId?: string
    roles?: GidRoleData[]
}

export interface MembershipData {
    groupUuid?: string
    groupName?: string
    roleUuid?: string
    roleName?: string
    showEmail?: boolean
    represents?: string
}

export interface MembershipDataOut {
    login?: string
    roles?: string[]
    membershipData?: MembershipData[]
}

export interface PaginationUi {
    page?: number
    perPage?: number
    totalPages?: number
    totaltems?: number
}

export interface MetaAttributesUi {
    owner?: string
    state?: string
    createdBy?: string
    createdAt?: string
    lastModifiedBy?: string
    lastModifiedAt?: string
}

export interface RelationshipUi {
    type?: string
    uuid?: string
    owner?: string
    startUuid?: string
    startType?: string
    startTypeName?: string
    startName?: string
    startKodMetaIS?: string
    endUuid?: string
    endType?: string
    endTypeName?: string
    endName?: string
    endKodMetaIS?: string
    attributes?: AttributeUi[]
    metaAttributes?: MetaAttributesUi
}

export interface ConfigurationItemSetUi {
    pagination?: PaginationUi
    configurationItemSet?: ConfigurationItemUi[]
    incidentRelationshipSet?: RelationshipUi[]
}

export type AttributeUiValue = { [key: string]: any }

export interface AttributeUi {
    name?: string
    value?: AttributeUiValue
}

export interface ConfigurationItemUi {
    type?: string
    uuid?: string
    owner?: string
    attributes?: AttributeUi[]
    metaAttributes?: MetaAttributesUi
}

export interface PoWithElectronicMailbox {
    name?: string
    mailboxUri?: string
}

export type CsruOrganizationSource = (typeof CsruOrganizationSource)[keyof typeof CsruOrganizationSource]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CsruOrganizationSource = {
    CSRU: 'CSRU',
    CSRU_WITH_MAILBOX: 'CSRU_WITH_MAILBOX',
} as const

export interface CsruOrganization {
    fullName?: string
    ico?: string
    internetAddress?: string
    referenceId?: string
    postalCode?: string
    street?: string
    buildingNumber?: string
    district?: string
    city?: string
    titlesBeforeName?: string
    titlesAfterName?: string
    givenName?: string
    familyName?: string
    source?: CsruOrganizationSource
    electronicMailboxList?: PoWithElectronicMailbox[]
    terminate?: boolean
}

export interface OwnerAndRoles {
    isOwner?: boolean
    ownerPO?: string
    ownerRole?: string
    roles?: string[]
}

export type RelatedRoleType = (typeof RelatedRoleType)[keyof typeof RelatedRoleType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelatedRoleType = {
    SYSTEM: 'SYSTEM',
    NON_SYSTEM: 'NON_SYSTEM',
} as const

export interface RelatedRole {
    uuid?: string
    category?: string
    name?: string
    description?: string
    weight?: number
    type?: RelatedRoleType
    assignedGroup?: string[]
    deletableRelation?: boolean
}

export type RelatedIdentityWithPoAttributes = { [key: string]: string }

export interface RelatedIdentityWithPo {
    login?: string
    email?: string
    orgId?: string
    firstName?: string
    lastName?: string
    uuid?: string
    attributes?: RelatedIdentityWithPoAttributes
}

export type RelatedIdentityType = (typeof RelatedIdentityType)[keyof typeof RelatedIdentityType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelatedIdentityType = {
    PHYSICAL: 'PHYSICAL',
    TECH: 'TECH',
} as const

export type RelatedIdentityState = (typeof RelatedIdentityState)[keyof typeof RelatedIdentityState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelatedIdentityState = {
    REGISTERED: 'REGISTERED',
    ACTIVATED: 'ACTIVATED',
    VERIFIED: 'VERIFIED',
    BLOCKED: 'BLOCKED',
    DEACTIVATED: 'DEACTIVATED',
    ANONYMIZED: 'ANONYMIZED',
} as const

export type RelatedIdentityAuthenticationResource = (typeof RelatedIdentityAuthenticationResource)[keyof typeof RelatedIdentityAuthenticationResource]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RelatedIdentityAuthenticationResource = {
    LOGIN_PASS: 'LOGIN_PASS',
    UPVS: 'UPVS',
    KERBEROS: 'KERBEROS',
    CLARITY: 'CLARITY',
} as const

export interface RelatedIdentity {
    uuid?: string
    createdAt?: number
    modifiedAt?: number
    firstName?: string
    lastName?: string
    email?: string
    phone?: string
    position?: string
    login?: string
    loginLegacy?: string
    authResourceLP?: string
    authResourceEid?: string
    authResourceKrb?: string
    authResourceClar?: string
    authenticationResource?: RelatedIdentityAuthenticationResource
    state?: RelatedIdentityState
    activationToken?: boolean
    type?: RelatedIdentityType
    changePassExpiration?: string
    disabledNotifications?: boolean
    wrongLoginAttemptCount?: number
    lastSuccessfulLogin?: number
    lastUnsuccessfulLogin?: string
    emailNotifPreferences?: string[]
    webNotifPreferences?: string[]
    projectNotifPreferences?: string[]
    anonymizedAt?: number
    deletableRelation?: boolean
    displayName?: string
}

export type ResponseMapStringOwnershipPayload = { [key: string]: Ownership }

export interface ResponseMapStringOwnership {
    payload?: ResponseMapStringOwnershipPayload
    failMessage?: string
    failure?: boolean
}

export type ItemTypeItemClass = (typeof ItemTypeItemClass)[keyof typeof ItemTypeItemClass]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ItemTypeItemClass = {
    RELATIONSHIP: 'RELATIONSHIP',
    CONFIGURATION_ITEM: 'CONFIGURATION_ITEM',
} as const

export interface ItemType {
    itemTypeName?: string
    itemClass?: ItemTypeItemClass
}

export interface GetAttributeOwnershipRequest {
    userLogin?: string
    itemId?: string
    itemType?: ItemType
    attributeNames?: string[]
}

export interface Ownership {
    owner?: boolean
    ownerDelegate?: boolean
}

export interface ResponseOwnership {
    payload?: Ownership
    failMessage?: string
    failure?: boolean
}

export interface GetOwnershipRequest {
    userLogin?: string
    itemId?: string
}

export interface UserPermissionAssignData {
    po?: string
    email?: string
    phone?: string
    description?: string
    createdByUuid?: string
}

export interface MessageGid {
    gid?: RoleOrgGroup
    message?: string
}

export interface GidBoolean {
    gid?: string
    assigned?: boolean
}

export interface IsInPoByGidOutput {
    gids?: GidBoolean[]
}

export interface IsInPoByGidInput {
    identityGids?: string[]
    gids?: string[]
}

export interface Group {
    uuid?: string
    shortName?: string
    name?: string
    description?: string
}

export interface IdentityNotifications {
    emailNotifPreferences?: string[]
    webNotifPreferences?: string[]
    projectNotifPreferences?: string[]
}

export interface IdentityProfile {
    email?: string
    phone?: string
    disabledNotifications?: boolean
}

export interface IdentityTerms {
    licenceTermsAccepted?: boolean
}

export interface ChangePasswordRequest {
    oldPassword?: string
    newPassword?: string
}

export interface StringList {
    requestUuid?: string
    strings?: string[]
}

export interface FindByLoginOut {
    input?: string
    identity?: Identity
}

export interface OperationResultGenericIdentity {
    type?: string
    message?: string
    entity?: Identity
}

export type IdentityType = (typeof IdentityType)[keyof typeof IdentityType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IdentityType = {
    PHYSICAL: 'PHYSICAL',
    TECH: 'TECH',
} as const

export type IdentityState = (typeof IdentityState)[keyof typeof IdentityState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IdentityState = {
    REGISTERED: 'REGISTERED',
    ACTIVATED: 'ACTIVATED',
    VERIFIED: 'VERIFIED',
    BLOCKED: 'BLOCKED',
    DEACTIVATED: 'DEACTIVATED',
    ANONYMIZED: 'ANONYMIZED',
} as const

export type IdentityAuthenticationResource = (typeof IdentityAuthenticationResource)[keyof typeof IdentityAuthenticationResource]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IdentityAuthenticationResource = {
    LOGIN_PASS: 'LOGIN_PASS',
    UPVS: 'UPVS',
    KERBEROS: 'KERBEROS',
    CLARITY: 'CLARITY',
} as const

export interface Identity {
    uuid?: string
    createdAt?: number
    modifiedAt?: number
    firstName?: string
    lastName?: string
    email?: string
    phone?: string
    position?: string
    login?: string
    loginLegacy?: string
    authResourceLP?: string
    authResourceEid?: string
    authResourceKrb?: string
    authResourceClar?: string
    authenticationResource?: IdentityAuthenticationResource
    state?: IdentityState
    activationToken?: boolean
    type?: IdentityType
    changePassExpiration?: string
    disabledNotifications?: boolean
    wrongLoginAttemptCount?: number
    lastSuccessfulLogin?: number
    lastUnsuccessfulLogin?: string
    emailNotifPreferences?: string[]
    webNotifPreferences?: string[]
    projectNotifPreferences?: string[]
    anonymizedAt?: number
    displayName?: string
}

export interface IdentityWithResult {
    input?: string
    identity?: Identity
    result?: string
}

export type UpdateRoleBulkResponseUpdatedEntities = { [key: string]: string[] }

export interface UpdateRoleBulkResponse {
    updatedEntities?: UpdateRoleBulkResponseUpdatedEntities
}

export type UpdateRoleBulkRequestOperation = (typeof UpdateRoleBulkRequestOperation)[keyof typeof UpdateRoleBulkRequestOperation]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateRoleBulkRequestOperation = {
    ADD: 'ADD',
    DELETE: 'DELETE',
} as const

export interface UpdateRoleBulkRequest {
    gidList?: string[]
    identityUuidList?: string[]
    operation?: UpdateRoleBulkRequestOperation
}

export interface IdentityUuidWithState {
    uuid?: string
    activate?: boolean
}

export interface RoleOrgIdentity {
    roleId?: string
    orgId?: string
    orgName?: string
    roleName?: string
    roleDesc?: string
    orgZIP?: string
    orgVillage?: string
    orgStreet?: string
    orgNumber?: string
    note?: string
    showEmail?: boolean
    represents?: string
    identityId?: string
    gid?: string
}

export interface IdentityWithOnePoAndRolesWithoutAuthResource {
    identity?: IdentityWithoutAuthResource
    orgId?: string
    roleIds?: string[]
}

export interface GroupOrgRoleIdentityRelationParams {
    note?: string
    showEmail?: boolean
    represents?: string
}

export type IdentityWithoutAuthResourceType = (typeof IdentityWithoutAuthResourceType)[keyof typeof IdentityWithoutAuthResourceType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IdentityWithoutAuthResourceType = {
    PHYSICAL: 'PHYSICAL',
    TECH: 'TECH',
} as const

export type IdentityWithoutAuthResourceState = (typeof IdentityWithoutAuthResourceState)[keyof typeof IdentityWithoutAuthResourceState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IdentityWithoutAuthResourceState = {
    REGISTERED: 'REGISTERED',
    ACTIVATED: 'ACTIVATED',
    VERIFIED: 'VERIFIED',
    BLOCKED: 'BLOCKED',
    DEACTIVATED: 'DEACTIVATED',
    ANONYMIZED: 'ANONYMIZED',
} as const

export interface IdentityWithoutAuthResource {
    uuid?: string
    firstName?: string
    lastName?: string
    email?: string
    phone?: string
    position?: string
    login?: string
    state?: IdentityWithoutAuthResourceState
    type?: IdentityWithoutAuthResourceType
}

export interface IdentityWithGidWithoutAuthResource {
    identity?: IdentityWithoutAuthResource
    gids?: RoleOrgIdentity[]
}

export type OrganizationWithGidResult = (typeof OrganizationWithGidResult)[keyof typeof OrganizationWithGidResult]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrganizationWithGidResult = {
    FOUND: 'FOUND',
    NOT_FOUND: 'NOT_FOUND',
    WRONG_INPUT: 'WRONG_INPUT',
} as const

export interface OrganizationWithGid {
    input?: string
    organization?: Organization
    result?: OrganizationWithGidResult
}

export interface HighestOrganization {
    gid?: string
    highestOrganization?: string
}

export interface IdentityUuidWithHighestOrganization {
    identityUuid?: string
    result?: string
    orgs?: HighestOrganization[]
}

export interface IdentityUuidWithGid {
    identityUuid?: string
    gids?: string[]
}

export interface LoginOrganizationIn {
    login?: string
    gids?: string[]
}

export interface GidRoleData {
    gid?: string
    roleWeight?: number
    roleUuid?: string
    roleName?: string
    roleDescription?: string
}

export interface GidRoleDataHolder {
    gid?: string
    gidRoles?: GidRoleData[]
}

export interface OrganizationHierarchyInWithoutPagination {
    uuids?: string[]
    level?: number
}

export interface OrganizationHierarchy {
    organization?: Organization
}

export interface OrganizationHierarchyIn {
    uuids?: string[]
    level?: number
    paging?: Paging
}

export interface IdentityOrganizationIn {
    identityUuid?: string
    cmdbId?: string
}

export interface LoginGid {
    login?: string
    gid?: string
}

export interface OrganizationBoolean {
    cmdbId?: string
    implicitMember?: boolean
}

export interface IdentityWithOrganizationOut {
    identityUuid?: string
    cmdbId?: OrganizationBoolean[]
}

export interface IdentityWithOrganizationIn {
    identityUuid?: string
    cmdbId?: string[]
}

export interface OwnerByGidInput {
    login?: string
    gids?: string[]
}

export interface IsOwner {
    gid?: string
    owner?: boolean
}

export interface OwnerByGidOutput {
    login?: string
    isOwner?: IsOwner[]
}

export interface OwnerByUserOut {
    isOwner?: IsOwner[]
}

export interface OwnerByUserIn {
    identityUuid?: string
    gids?: string[]
}

export interface PoRelationshipIntegrityConstraints {
    cmdbId?: string
    subCmdbId?: string
    assignedInHierarchy?: boolean
    cycleInHierarchy?: boolean
}

export interface PoRelationship {
    cmdbId?: string
    subCmdbId?: string
}

export interface RoleOrgGroup {
    roleId?: string
    orgId?: string
    orgName?: string
    roleName?: string
    roleDesc?: string
    orgZIP?: string
    orgVillage?: string
    orgStreet?: string
    orgNumber?: string
    note?: string
    showEmail?: boolean
    represents?: string
    gid?: string
}

export interface Paging {
    pageNumber?: number
    recordsPerPage?: number
    recordsTotal?: number
}

export interface OrganizationHierarchyOut {
    paging?: Paging
    hierarchy?: OrganizationHierarchy[]
}

export interface OrganizationRoleHierarchyOut {
    gids?: RoleOrgGroup[]
    paging?: Paging
}

export interface OrganizationRoleHierarchyIn {
    cmdbId?: string
    roleUuid?: string
    level?: number
    paging?: Paging
}

export type OrganizationAttributes = { [key: string]: string }

export interface Organization {
    uuid?: string
    cmdbId?: string
    attributes?: OrganizationAttributes
}

export interface SubOrganizations {
    uuid?: string
    subOrganizations?: Organization[]
}

export interface SubOrganizationCmdbId {
    subOrganizations?: Organization[]
    cmdbId?: string
    result?: string
}

export type RoleType = (typeof RoleType)[keyof typeof RoleType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoleType = {
    SYSTEM: 'SYSTEM',
    NON_SYSTEM: 'NON_SYSTEM',
} as const

export interface Role {
    uuid?: string
    category?: string
    name?: string
    description?: string
    weight?: number
    type?: RoleType
    assignedGroup?: string[]
}

export interface OperationResultGenericRole {
    type?: string
    message?: string
    entity?: Role
}

export interface Password {
    value?: string
}

export interface CreateGidOperationResult {
    type?: string
    message?: string
    gid?: string
}

export interface OperationResult {
    type?: string
    message?: string
}

export type ApiErrorData = { [key: string]: any }

export interface ApiError {
    type?: string
    message?: string
    data?: ApiErrorData
    logToken?: string
    values?: string[]
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const useAddSubRoleHook = () => {
    const addSubRole = useIAmSwaggerClient<OperationResult>()

    return (roleUuid: string, subRoleUuid: string) => {
        return addSubRole({ url: `/roles/${roleUuid}/roles/${subRoleUuid}`, method: 'put' })
    }
}

export const useAddSubRoleMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddSubRoleHook>>>,
        TError,
        { roleUuid: string; subRoleUuid: string },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddSubRoleHook>>>, TError, { roleUuid: string; subRoleUuid: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const addSubRole = useAddSubRoleHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAddSubRoleHook>>>, { roleUuid: string; subRoleUuid: string }> = (
        props,
    ) => {
        const { roleUuid, subRoleUuid } = props ?? {}

        return addSubRole(roleUuid, subRoleUuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type AddSubRoleMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddSubRoleHook>>>>

export type AddSubRoleMutationError = ApiError

export const useAddSubRole = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddSubRoleHook>>>,
        TError,
        { roleUuid: string; subRoleUuid: string },
        TContext
    >
}) => {
    const mutationOptions = useAddSubRoleMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useFindRoleOrgGroupHook = () => {
    const findRoleOrgGroup = useIAmSwaggerClient<RoleOrgGroup>()

    return (roleUuid: string, orgId: string, signal?: AbortSignal) => {
        return findRoleOrgGroup({ url: `/roles/${roleUuid}/organizations/${orgId}`, method: 'get', signal })
    }
}

export const getFindRoleOrgGroupQueryKey = (roleUuid: string, orgId: string) => [`/roles/${roleUuid}/organizations/${orgId}`] as const

export const useFindRoleOrgGroupQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindRoleOrgGroupHook>>>, TError = ApiError>(
    roleUuid: string,
    orgId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRoleOrgGroupHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRoleOrgGroupHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindRoleOrgGroupQueryKey(roleUuid, orgId)

    const findRoleOrgGroup = useFindRoleOrgGroupHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindRoleOrgGroupHook>>>> = ({ signal }) =>
        findRoleOrgGroup(roleUuid, orgId, signal)

    return { queryKey, queryFn, enabled: !!(roleUuid && orgId), ...queryOptions }
}

export type FindRoleOrgGroupQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindRoleOrgGroupHook>>>>
export type FindRoleOrgGroupQueryError = ApiError

export const useFindRoleOrgGroup = <TData = Awaited<ReturnType<ReturnType<typeof useFindRoleOrgGroupHook>>>, TError = ApiError>(
    roleUuid: string,
    orgId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRoleOrgGroupHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindRoleOrgGroupQueryOptions(roleUuid, orgId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useAddOrganizationToRoleHook = () => {
    const addOrganizationToRole = useIAmSwaggerClient<CreateGidOperationResult>()

    return (roleUuid: string, orgId: string) => {
        return addOrganizationToRole({ url: `/roles/${roleUuid}/organizations/${orgId}`, method: 'put' })
    }
}

export const useAddOrganizationToRoleMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddOrganizationToRoleHook>>>,
        TError,
        { roleUuid: string; orgId: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddOrganizationToRoleHook>>>,
    TError,
    { roleUuid: string; orgId: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const addOrganizationToRole = useAddOrganizationToRoleHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAddOrganizationToRoleHook>>>, { roleUuid: string; orgId: string }> = (
        props,
    ) => {
        const { roleUuid, orgId } = props ?? {}

        return addOrganizationToRole(roleUuid, orgId)
    }

    return { mutationFn, ...mutationOptions }
}

export type AddOrganizationToRoleMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddOrganizationToRoleHook>>>>

export type AddOrganizationToRoleMutationError = ApiError

export const useAddOrganizationToRole = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddOrganizationToRoleHook>>>,
        TError,
        { roleUuid: string; orgId: string },
        TContext
    >
}) => {
    const mutationOptions = useAddOrganizationToRoleMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useRemoveOrzanizationFromRoleHook = () => {
    const removeOrzanizationFromRole = useIAmSwaggerClient<OperationResult>()

    return (roleUuid: string, orgId: string) => {
        return removeOrzanizationFromRole({ url: `/roles/${roleUuid}/organizations/${orgId}`, method: 'delete' })
    }
}

export const useRemoveOrzanizationFromRoleMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRemoveOrzanizationFromRoleHook>>>,
        TError,
        { roleUuid: string; orgId: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRemoveOrzanizationFromRoleHook>>>,
    TError,
    { roleUuid: string; orgId: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const removeOrzanizationFromRole = useRemoveOrzanizationFromRoleHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useRemoveOrzanizationFromRoleHook>>>,
        { roleUuid: string; orgId: string }
    > = (props) => {
        const { roleUuid, orgId } = props ?? {}

        return removeOrzanizationFromRole(roleUuid, orgId)
    }

    return { mutationFn, ...mutationOptions }
}

export type RemoveOrzanizationFromRoleMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRemoveOrzanizationFromRoleHook>>>>

export type RemoveOrzanizationFromRoleMutationError = ApiError

export const useRemoveOrzanizationFromRole = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRemoveOrzanizationFromRoleHook>>>,
        TError,
        { roleUuid: string; orgId: string },
        TContext
    >
}) => {
    const mutationOptions = useRemoveOrzanizationFromRoleMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useAddRelatedIdentityHook = () => {
    const addRelatedIdentity = useIAmSwaggerClient<OperationResult>()

    return (roleUuid: string, relatedIdentityUuid: string) => {
        return addRelatedIdentity({ url: `/roles/${roleUuid}/identities/${relatedIdentityUuid}`, method: 'put' })
    }
}

export const useAddRelatedIdentityMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddRelatedIdentityHook>>>,
        TError,
        { roleUuid: string; relatedIdentityUuid: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddRelatedIdentityHook>>>,
    TError,
    { roleUuid: string; relatedIdentityUuid: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const addRelatedIdentity = useAddRelatedIdentityHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useAddRelatedIdentityHook>>>,
        { roleUuid: string; relatedIdentityUuid: string }
    > = (props) => {
        const { roleUuid, relatedIdentityUuid } = props ?? {}

        return addRelatedIdentity(roleUuid, relatedIdentityUuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type AddRelatedIdentityMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddRelatedIdentityHook>>>>

export type AddRelatedIdentityMutationError = ApiError

export const useAddRelatedIdentity = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddRelatedIdentityHook>>>,
        TError,
        { roleUuid: string; relatedIdentityUuid: string },
        TContext
    >
}) => {
    const mutationOptions = useAddRelatedIdentityMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteRelatedIdentityHook = () => {
    const deleteRelatedIdentity = useIAmSwaggerClient<OperationResult>()

    return (roleUuid: string, relatedIdentityUuid: string) => {
        return deleteRelatedIdentity({ url: `/roles/${roleUuid}/identities/${relatedIdentityUuid}`, method: 'delete' })
    }
}

export const useDeleteRelatedIdentityMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteRelatedIdentityHook>>>,
        TError,
        { roleUuid: string; relatedIdentityUuid: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteRelatedIdentityHook>>>,
    TError,
    { roleUuid: string; relatedIdentityUuid: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteRelatedIdentity = useDeleteRelatedIdentityHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useDeleteRelatedIdentityHook>>>,
        { roleUuid: string; relatedIdentityUuid: string }
    > = (props) => {
        const { roleUuid, relatedIdentityUuid } = props ?? {}

        return deleteRelatedIdentity(roleUuid, relatedIdentityUuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteRelatedIdentityMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteRelatedIdentityHook>>>>

export type DeleteRelatedIdentityMutationError = ApiError

export const useDeleteRelatedIdentity = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteRelatedIdentityHook>>>,
        TError,
        { roleUuid: string; relatedIdentityUuid: string },
        TContext
    >
}) => {
    const mutationOptions = useDeleteRelatedIdentityMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useRejectChangeHook = () => {
    const rejectChange = useIAmSwaggerClient<void>()

    return (uuid: string) => {
        return rejectChange({ url: `/organizations/csru/changes/${uuid}/reject`, method: 'put' })
    }
}

export const useRejectChangeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRejectChangeHook>>>, TError, { uuid: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRejectChangeHook>>>, TError, { uuid: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const rejectChange = useRejectChangeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRejectChangeHook>>>, { uuid: string }> = (props) => {
        const { uuid } = props ?? {}

        return rejectChange(uuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type RejectChangeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRejectChangeHook>>>>

export type RejectChangeMutationError = ApiError

export const useRejectChange = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRejectChangeHook>>>, TError, { uuid: string }, TContext>
}) => {
    const mutationOptions = useRejectChangeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useApproveChangeHook = () => {
    const approveChange = useIAmSwaggerClient<void>()

    return (uuid: string) => {
        return approveChange({ url: `/organizations/csru/changes/${uuid}/approve`, method: 'put' })
    }
}

export const useApproveChangeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useApproveChangeHook>>>, TError, { uuid: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useApproveChangeHook>>>, TError, { uuid: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const approveChange = useApproveChangeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useApproveChangeHook>>>, { uuid: string }> = (props) => {
        const { uuid } = props ?? {}

        return approveChange(uuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type ApproveChangeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useApproveChangeHook>>>>

export type ApproveChangeMutationError = ApiError

export const useApproveChange = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useApproveChangeHook>>>, TError, { uuid: string }, TContext>
}) => {
    const mutationOptions = useApproveChangeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useAddIdentitySubstituteHook = () => {
    const addIdentitySubstitute = useIAmSwaggerClient<OperationResult>()

    return (uuid: string, substitutesUuid: string, groupUuid: string) => {
        return addIdentitySubstitute({ url: `/identities/${uuid}/substitutes/${substitutesUuid}/groups/${groupUuid}`, method: 'put' })
    }
}

export const useAddIdentitySubstituteMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddIdentitySubstituteHook>>>,
        TError,
        { uuid: string; substitutesUuid: string; groupUuid: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddIdentitySubstituteHook>>>,
    TError,
    { uuid: string; substitutesUuid: string; groupUuid: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const addIdentitySubstitute = useAddIdentitySubstituteHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useAddIdentitySubstituteHook>>>,
        { uuid: string; substitutesUuid: string; groupUuid: string }
    > = (props) => {
        const { uuid, substitutesUuid, groupUuid } = props ?? {}

        return addIdentitySubstitute(uuid, substitutesUuid, groupUuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type AddIdentitySubstituteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddIdentitySubstituteHook>>>>

export type AddIdentitySubstituteMutationError = ApiError

export const useAddIdentitySubstitute = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddIdentitySubstituteHook>>>,
        TError,
        { uuid: string; substitutesUuid: string; groupUuid: string },
        TContext
    >
}) => {
    const mutationOptions = useAddIdentitySubstituteMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteIdentitySubstituteHook = () => {
    const deleteIdentitySubstitute = useIAmSwaggerClient<OperationResult>()

    return (uuid: string, substitutesUuid: string, groupUuid: string) => {
        return deleteIdentitySubstitute({ url: `/identities/${uuid}/substitutes/${substitutesUuid}/groups/${groupUuid}`, method: 'delete' })
    }
}

export const useDeleteIdentitySubstituteMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteIdentitySubstituteHook>>>,
        TError,
        { uuid: string; substitutesUuid: string; groupUuid: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteIdentitySubstituteHook>>>,
    TError,
    { uuid: string; substitutesUuid: string; groupUuid: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteIdentitySubstitute = useDeleteIdentitySubstituteHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useDeleteIdentitySubstituteHook>>>,
        { uuid: string; substitutesUuid: string; groupUuid: string }
    > = (props) => {
        const { uuid, substitutesUuid, groupUuid } = props ?? {}

        return deleteIdentitySubstitute(uuid, substitutesUuid, groupUuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteIdentitySubstituteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteIdentitySubstituteHook>>>>

export type DeleteIdentitySubstituteMutationError = ApiError

export const useDeleteIdentitySubstitute = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteIdentitySubstituteHook>>>,
        TError,
        { uuid: string; substitutesUuid: string; groupUuid: string },
        TContext
    >
}) => {
    const mutationOptions = useDeleteIdentitySubstituteMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useFindRoleOrgRelationHook = () => {
    const findRoleOrgRelation = useIAmSwaggerClient<RoleOrgIdentity>()

    return (uuid: string, roleUuid: string, orgId: string, signal?: AbortSignal) => {
        return findRoleOrgRelation({ url: `/identities/${uuid}/roles/${roleUuid}/organizations/${orgId}`, method: 'get', signal })
    }
}

export const getFindRoleOrgRelationQueryKey = (uuid: string, roleUuid: string, orgId: string) =>
    [`/identities/${uuid}/roles/${roleUuid}/organizations/${orgId}`] as const

export const useFindRoleOrgRelationQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindRoleOrgRelationHook>>>, TError = ApiError>(
    uuid: string,
    roleUuid: string,
    orgId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRoleOrgRelationHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRoleOrgRelationHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindRoleOrgRelationQueryKey(uuid, roleUuid, orgId)

    const findRoleOrgRelation = useFindRoleOrgRelationHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindRoleOrgRelationHook>>>> = ({ signal }) =>
        findRoleOrgRelation(uuid, roleUuid, orgId, signal)

    return { queryKey, queryFn, enabled: !!(uuid && roleUuid && orgId), ...queryOptions }
}

export type FindRoleOrgRelationQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindRoleOrgRelationHook>>>>
export type FindRoleOrgRelationQueryError = ApiError

export const useFindRoleOrgRelation = <TData = Awaited<ReturnType<ReturnType<typeof useFindRoleOrgRelationHook>>>, TError = ApiError>(
    uuid: string,
    roleUuid: string,
    orgId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRoleOrgRelationHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindRoleOrgRelationQueryOptions(uuid, roleUuid, orgId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useAddRoleOrgRelationHook = () => {
    const addRoleOrgRelation = useIAmSwaggerClient<OperationResult>()

    return (uuid: string, roleUuid: string, orgId: string) => {
        return addRoleOrgRelation({ url: `/identities/${uuid}/roles/${roleUuid}/organizations/${orgId}`, method: 'put' })
    }
}

export const useAddRoleOrgRelationMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddRoleOrgRelationHook>>>,
        TError,
        { uuid: string; roleUuid: string; orgId: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddRoleOrgRelationHook>>>,
    TError,
    { uuid: string; roleUuid: string; orgId: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const addRoleOrgRelation = useAddRoleOrgRelationHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useAddRoleOrgRelationHook>>>,
        { uuid: string; roleUuid: string; orgId: string }
    > = (props) => {
        const { uuid, roleUuid, orgId } = props ?? {}

        return addRoleOrgRelation(uuid, roleUuid, orgId)
    }

    return { mutationFn, ...mutationOptions }
}

export type AddRoleOrgRelationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddRoleOrgRelationHook>>>>

export type AddRoleOrgRelationMutationError = ApiError

export const useAddRoleOrgRelation = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddRoleOrgRelationHook>>>,
        TError,
        { uuid: string; roleUuid: string; orgId: string },
        TContext
    >
}) => {
    const mutationOptions = useAddRoleOrgRelationMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteRoleOrgRelationHook = () => {
    const deleteRoleOrgRelation = useIAmSwaggerClient<OperationResult>()

    return (uuid: string, roleUuid: string, orgId: string) => {
        return deleteRoleOrgRelation({ url: `/identities/${uuid}/roles/${roleUuid}/organizations/${orgId}`, method: 'delete' })
    }
}

export const useDeleteRoleOrgRelationMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteRoleOrgRelationHook>>>,
        TError,
        { uuid: string; roleUuid: string; orgId: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteRoleOrgRelationHook>>>,
    TError,
    { uuid: string; roleUuid: string; orgId: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteRoleOrgRelation = useDeleteRoleOrgRelationHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useDeleteRoleOrgRelationHook>>>,
        { uuid: string; roleUuid: string; orgId: string }
    > = (props) => {
        const { uuid, roleUuid, orgId } = props ?? {}

        return deleteRoleOrgRelation(uuid, roleUuid, orgId)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteRoleOrgRelationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteRoleOrgRelationHook>>>>

export type DeleteRoleOrgRelationMutationError = ApiError

export const useDeleteRoleOrgRelation = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteRoleOrgRelationHook>>>,
        TError,
        { uuid: string; roleUuid: string; orgId: string },
        TContext
    >
}) => {
    const mutationOptions = useDeleteRoleOrgRelationMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateRoleOnGroupOrgForIdentityHook = () => {
    const updateRoleOnGroupOrgForIdentity = useIAmSwaggerClient<OperationResult>()

    return (uuid: string, groupUuid: string, oldRoleUuid: string, newRoleUuid: string, orgId: string) => {
        return updateRoleOnGroupOrgForIdentity({
            url: `/identities/${uuid}/groups/${groupUuid}/oldRole/${oldRoleUuid}/newRole/${newRoleUuid}/organizations/${orgId}`,
            method: 'put',
        })
    }
}

export const useUpdateRoleOnGroupOrgForIdentityMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateRoleOnGroupOrgForIdentityHook>>>,
        TError,
        { uuid: string; groupUuid: string; oldRoleUuid: string; newRoleUuid: string; orgId: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateRoleOnGroupOrgForIdentityHook>>>,
    TError,
    { uuid: string; groupUuid: string; oldRoleUuid: string; newRoleUuid: string; orgId: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateRoleOnGroupOrgForIdentity = useUpdateRoleOnGroupOrgForIdentityHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateRoleOnGroupOrgForIdentityHook>>>,
        { uuid: string; groupUuid: string; oldRoleUuid: string; newRoleUuid: string; orgId: string }
    > = (props) => {
        const { uuid, groupUuid, oldRoleUuid, newRoleUuid, orgId } = props ?? {}

        return updateRoleOnGroupOrgForIdentity(uuid, groupUuid, oldRoleUuid, newRoleUuid, orgId)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateRoleOnGroupOrgForIdentityMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useUpdateRoleOnGroupOrgForIdentityHook>>>
>

export type UpdateRoleOnGroupOrgForIdentityMutationError = ApiError

export const useUpdateRoleOnGroupOrgForIdentity = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateRoleOnGroupOrgForIdentityHook>>>,
        TError,
        { uuid: string; groupUuid: string; oldRoleUuid: string; newRoleUuid: string; orgId: string },
        TContext
    >
}) => {
    const mutationOptions = useUpdateRoleOnGroupOrgForIdentityMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useChangePasswordHook = () => {
    const changePassword = useIAmSwaggerClient<OperationResult>()

    return (uuid: string, password: Password) => {
        return changePassword({
            url: `/identities/${uuid}/change_pass`,
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data: password,
        })
    }
}

export const useChangePasswordMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangePasswordHook>>>, TError, { uuid: string; data: Password }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangePasswordHook>>>, TError, { uuid: string; data: Password }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const changePassword = useChangePasswordHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useChangePasswordHook>>>, { uuid: string; data: Password }> = (props) => {
        const { uuid, data } = props ?? {}

        return changePassword(uuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ChangePasswordMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useChangePasswordHook>>>>
export type ChangePasswordMutationBody = Password
export type ChangePasswordMutationError = ApiError

export const useChangePassword = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangePasswordHook>>>, TError, { uuid: string; data: Password }, TContext>
}) => {
    const mutationOptions = useChangePasswordMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useAddSubGroupHook = () => {
    const addSubGroup = useIAmSwaggerClient<OperationResult>()

    return (groupUuid: string, subGroupUuid: string) => {
        return addSubGroup({ url: `/groups/${groupUuid}/groups/${subGroupUuid}`, method: 'put' })
    }
}

export const useAddSubGroupMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddSubGroupHook>>>,
        TError,
        { groupUuid: string; subGroupUuid: string },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddSubGroupHook>>>, TError, { groupUuid: string; subGroupUuid: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const addSubGroup = useAddSubGroupHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useAddSubGroupHook>>>, { groupUuid: string; subGroupUuid: string }> = (
        props,
    ) => {
        const { groupUuid, subGroupUuid } = props ?? {}

        return addSubGroup(groupUuid, subGroupUuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type AddSubGroupMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddSubGroupHook>>>>

export type AddSubGroupMutationError = ApiError

export const useAddSubGroup = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddSubGroupHook>>>,
        TError,
        { groupUuid: string; subGroupUuid: string },
        TContext
    >
}) => {
    const mutationOptions = useAddSubGroupMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useFindAll11Hook = () => {
    const findAll11 = useIAmSwaggerClient<FindAll11200>()

    return (params?: FindAll11Params, signal?: AbortSignal) => {
        return findAll11({ url: `/roles`, method: 'get', params, signal })
    }
}

export const getFindAll11QueryKey = (params?: FindAll11Params) => [`/roles`, ...(params ? [params] : [])] as const

export const useFindAll11QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindAll11Hook>>>, TError = ApiError>(
    params?: FindAll11Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll11Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll11Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindAll11QueryKey(params)

    const findAll11 = useFindAll11Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindAll11Hook>>>> = ({ signal }) => findAll11(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindAll11QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindAll11Hook>>>>
export type FindAll11QueryError = ApiError

export const useFindAll11 = <TData = Awaited<ReturnType<ReturnType<typeof useFindAll11Hook>>>, TError = ApiError>(
    params?: FindAll11Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll11Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindAll11QueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUpdateOrCreateHook = () => {
    const updateOrCreate = useIAmSwaggerClient<OperationResult>()

    return (role: Role) => {
        return updateOrCreate({ url: `/roles`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: role })
    }
}

export const useUpdateOrCreateMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOrCreateHook>>>, TError, { data: Role }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOrCreateHook>>>, TError, { data: Role }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateOrCreate = useUpdateOrCreateHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateOrCreateHook>>>, { data: Role }> = (props) => {
        const { data } = props ?? {}

        return updateOrCreate(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateOrCreateMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateOrCreateHook>>>>
export type UpdateOrCreateMutationBody = Role
export type UpdateOrCreateMutationError = ApiError

export const useUpdateOrCreate = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOrCreateHook>>>, TError, { data: Role }, TContext>
}) => {
    const mutationOptions = useUpdateOrCreateMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useBulkUpdateOrCreateHook = () => {
    const bulkUpdateOrCreate = useIAmSwaggerClient<OperationResultGenericRole[]>()

    return (role: Role[]) => {
        return bulkUpdateOrCreate({ url: `/roles/bulk`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: role })
    }
}

export const useBulkUpdateOrCreateMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useBulkUpdateOrCreateHook>>>, TError, { data: Role[] }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useBulkUpdateOrCreateHook>>>, TError, { data: Role[] }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const bulkUpdateOrCreate = useBulkUpdateOrCreateHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useBulkUpdateOrCreateHook>>>, { data: Role[] }> = (props) => {
        const { data } = props ?? {}

        return bulkUpdateOrCreate(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type BulkUpdateOrCreateMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useBulkUpdateOrCreateHook>>>>
export type BulkUpdateOrCreateMutationBody = Role[]
export type BulkUpdateOrCreateMutationError = ApiError

export const useBulkUpdateOrCreate = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useBulkUpdateOrCreateHook>>>, TError, { data: Role[] }, TContext>
}) => {
    const mutationOptions = useBulkUpdateOrCreateMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGetNewVersionFromCsruHook = () => {
    const getNewVersionFromCsru = useIAmSwaggerClient<void>()

    return (uuid: string) => {
        return getNewVersionFromCsru({ url: `/organizations/${uuid}/update`, method: 'post' })
    }
}

export const useGetNewVersionFromCsruMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetNewVersionFromCsruHook>>>, TError, { uuid: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetNewVersionFromCsruHook>>>, TError, { uuid: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const getNewVersionFromCsru = useGetNewVersionFromCsruHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useGetNewVersionFromCsruHook>>>, { uuid: string }> = (props) => {
        const { uuid } = props ?? {}

        return getNewVersionFromCsru(uuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type GetNewVersionFromCsruMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetNewVersionFromCsruHook>>>>

export type GetNewVersionFromCsruMutationError = ApiError

export const useGetNewVersionFromCsru = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetNewVersionFromCsruHook>>>, TError, { uuid: string }, TContext>
}) => {
    const mutationOptions = useGetNewVersionFromCsruMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary findSubOrganizationsByCmdbIdBulk
 */
export const useFindSubOrganizationsByCmdbIdBulkHook = () => {
    const findSubOrganizationsByCmdbIdBulk = useIAmSwaggerClient<SubOrganizationCmdbId[]>()

    return (findSubOrganizationsByCmdbIdBulkBody: string[]) => {
        return findSubOrganizationsByCmdbIdBulk({
            url: `/organizations/subOrganizationsByCmdbIdBulk`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: findSubOrganizationsByCmdbIdBulkBody,
        })
    }
}

export const useFindSubOrganizationsByCmdbIdBulkMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useFindSubOrganizationsByCmdbIdBulkHook>>>,
        TError,
        { data: string[] },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useFindSubOrganizationsByCmdbIdBulkHook>>>, TError, { data: string[] }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const findSubOrganizationsByCmdbIdBulk = useFindSubOrganizationsByCmdbIdBulkHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useFindSubOrganizationsByCmdbIdBulkHook>>>, { data: string[] }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return findSubOrganizationsByCmdbIdBulk(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type FindSubOrganizationsByCmdbIdBulkMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useFindSubOrganizationsByCmdbIdBulkHook>>>
>
export type FindSubOrganizationsByCmdbIdBulkMutationBody = string[]
export type FindSubOrganizationsByCmdbIdBulkMutationError = ApiError

/**
 * @summary findSubOrganizationsByCmdbIdBulk
 */
export const useFindSubOrganizationsByCmdbIdBulk = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useFindSubOrganizationsByCmdbIdBulkHook>>>,
        TError,
        { data: string[] },
        TContext
    >
}) => {
    const mutationOptions = useFindSubOrganizationsByCmdbIdBulkMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary findSubOrganizationsBulk
 */
export const useFindSubOrganizationsBulkHook = () => {
    const findSubOrganizationsBulk = useIAmSwaggerClient<SubOrganizations[]>()

    return (findSubOrganizationsBulkBody: string[]) => {
        return findSubOrganizationsBulk({
            url: `/organizations/subOrganizationsBulk`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: findSubOrganizationsBulkBody,
        })
    }
}

export const useFindSubOrganizationsBulkMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useFindSubOrganizationsBulkHook>>>, TError, { data: string[] }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useFindSubOrganizationsBulkHook>>>, TError, { data: string[] }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const findSubOrganizationsBulk = useFindSubOrganizationsBulkHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useFindSubOrganizationsBulkHook>>>, { data: string[] }> = (props) => {
        const { data } = props ?? {}

        return findSubOrganizationsBulk(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type FindSubOrganizationsBulkMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindSubOrganizationsBulkHook>>>>
export type FindSubOrganizationsBulkMutationBody = string[]
export type FindSubOrganizationsBulkMutationError = ApiError

/**
 * @summary findSubOrganizationsBulk
 */
export const useFindSubOrganizationsBulk = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useFindSubOrganizationsBulkHook>>>, TError, { data: string[] }, TContext>
}) => {
    const mutationOptions = useFindSubOrganizationsBulkMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary getHierarchyGids
 */
export const useGetHierarchyGidsHook = () => {
    const getHierarchyGids = useIAmSwaggerClient<OrganizationRoleHierarchyOut>()

    return (organizationRoleHierarchyIn: OrganizationRoleHierarchyIn) => {
        return getHierarchyGids({
            url: `/organizations/roleHierarchy`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: organizationRoleHierarchyIn,
        })
    }
}

export const useGetHierarchyGidsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetHierarchyGidsHook>>>,
        TError,
        { data: OrganizationRoleHierarchyIn },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetHierarchyGidsHook>>>, TError, { data: OrganizationRoleHierarchyIn }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const getHierarchyGids = useGetHierarchyGidsHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useGetHierarchyGidsHook>>>, { data: OrganizationRoleHierarchyIn }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return getHierarchyGids(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type GetHierarchyGidsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetHierarchyGidsHook>>>>
export type GetHierarchyGidsMutationBody = OrganizationRoleHierarchyIn
export type GetHierarchyGidsMutationError = ApiError

/**
 * @summary getHierarchyGids
 */
export const useGetHierarchyGids = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetHierarchyGidsHook>>>,
        TError,
        { data: OrganizationRoleHierarchyIn },
        TContext
    >
}) => {
    const mutationOptions = useGetHierarchyGidsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary getPoRelationshipIntegrityConstraints
 */
export const useGetPoRelationshipIntegrityConstraintsHook = () => {
    const getPoRelationshipIntegrityConstraints = useIAmSwaggerClient<PoRelationshipIntegrityConstraints[]>()

    return (poRelationship: PoRelationship[]) => {
        return getPoRelationshipIntegrityConstraints({
            url: `/organizations/poRelationshipIntegrityConstraints`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: poRelationship,
        })
    }
}

export const getGetPoRelationshipIntegrityConstraintsQueryKey = (poRelationship: PoRelationship[]) =>
    [`/organizations/poRelationshipIntegrityConstraints`, poRelationship] as const

export const useGetPoRelationshipIntegrityConstraintsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetPoRelationshipIntegrityConstraintsHook>>>,
    TError = ApiError,
>(
    poRelationship: PoRelationship[],
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPoRelationshipIntegrityConstraintsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPoRelationshipIntegrityConstraintsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetPoRelationshipIntegrityConstraintsQueryKey(poRelationship)

    const getPoRelationshipIntegrityConstraints = useGetPoRelationshipIntegrityConstraintsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPoRelationshipIntegrityConstraintsHook>>>> = () =>
        getPoRelationshipIntegrityConstraints(poRelationship)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetPoRelationshipIntegrityConstraintsQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetPoRelationshipIntegrityConstraintsHook>>>
>
export type GetPoRelationshipIntegrityConstraintsQueryError = ApiError

/**
 * @summary getPoRelationshipIntegrityConstraints
 */
export const useGetPoRelationshipIntegrityConstraints = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetPoRelationshipIntegrityConstraintsHook>>>,
    TError = ApiError,
>(
    poRelationship: PoRelationship[],
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPoRelationshipIntegrityConstraintsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetPoRelationshipIntegrityConstraintsQueryOptions(poRelationship, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary isOwnerByUser
 */
export const useIsOwnerByUserHook = () => {
    const isOwnerByUser = useIAmSwaggerClient<OwnerByUserOut>()

    return (ownerByUserIn: OwnerByUserIn) => {
        return isOwnerByUser({
            url: `/organizations/isOwnerByUser`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: ownerByUserIn,
        })
    }
}

export const useIsOwnerByUserMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsOwnerByUserHook>>>, TError, { data: OwnerByUserIn }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsOwnerByUserHook>>>, TError, { data: OwnerByUserIn }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const isOwnerByUser = useIsOwnerByUserHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useIsOwnerByUserHook>>>, { data: OwnerByUserIn }> = (props) => {
        const { data } = props ?? {}

        return isOwnerByUser(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type IsOwnerByUserMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useIsOwnerByUserHook>>>>
export type IsOwnerByUserMutationBody = OwnerByUserIn
export type IsOwnerByUserMutationError = ApiError

/**
 * @summary isOwnerByUser
 */
export const useIsOwnerByUser = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsOwnerByUserHook>>>, TError, { data: OwnerByUserIn }, TContext>
}) => {
    const mutationOptions = useIsOwnerByUserMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary isOwnerByGid
 */
export const useIsOwnerByGidHook = () => {
    const isOwnerByGid = useIAmSwaggerClient<OwnerByGidOutput>()

    return (ownerByGidInput: OwnerByGidInput) => {
        return isOwnerByGid({
            url: `/organizations/isOwnerByGid`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: ownerByGidInput,
        })
    }
}

export const getIsOwnerByGidQueryKey = (ownerByGidInput: OwnerByGidInput) => [`/organizations/isOwnerByGid`, ownerByGidInput] as const

export const useIsOwnerByGidQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useIsOwnerByGidHook>>>, TError = ApiError>(
    ownerByGidInput: OwnerByGidInput,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIsOwnerByGidHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIsOwnerByGidHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getIsOwnerByGidQueryKey(ownerByGidInput)

    const isOwnerByGid = useIsOwnerByGidHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useIsOwnerByGidHook>>>> = () => isOwnerByGid(ownerByGidInput)

    return { queryKey, queryFn, ...queryOptions }
}

export type IsOwnerByGidQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useIsOwnerByGidHook>>>>
export type IsOwnerByGidQueryError = ApiError

/**
 * @summary isOwnerByGid
 */
export const useIsOwnerByGid = <TData = Awaited<ReturnType<ReturnType<typeof useIsOwnerByGidHook>>>, TError = ApiError>(
    ownerByGidInput: OwnerByGidInput,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIsOwnerByGidHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useIsOwnerByGidQueryOptions(ownerByGidInput, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary isInPoByPo
 */
export const useIsInPoByPoHook = () => {
    const isInPoByPo = useIAmSwaggerClient<IdentityWithOrganizationOut>()

    return (identityWithOrganizationIn: IdentityWithOrganizationIn) => {
        return isInPoByPo({
            url: `/organizations/isInPoByPo`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: identityWithOrganizationIn,
        })
    }
}

export const useIsInPoByPoMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsInPoByPoHook>>>, TError, { data: IdentityWithOrganizationIn }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsInPoByPoHook>>>, TError, { data: IdentityWithOrganizationIn }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const isInPoByPo = useIsInPoByPoHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useIsInPoByPoHook>>>, { data: IdentityWithOrganizationIn }> = (props) => {
        const { data } = props ?? {}

        return isInPoByPo(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type IsInPoByPoMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useIsInPoByPoHook>>>>
export type IsInPoByPoMutationBody = IdentityWithOrganizationIn
export type IsInPoByPoMutationError = ApiError

/**
 * @summary isInPoByPo
 */
export const useIsInPoByPo = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsInPoByPoHook>>>, TError, { data: IdentityWithOrganizationIn }, TContext>
}) => {
    const mutationOptions = useIsInPoByPoMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary isInPoByPoAndEaGarpo
 */
export const useIsInPoByPoAndEaGarpoHook = () => {
    const isInPoByPoAndEaGarpo = useIAmSwaggerClient<boolean>()

    return (loginGid: LoginGid) => {
        return isInPoByPoAndEaGarpo({
            url: `/organizations/isInPoByPoAndEaGarpo`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: loginGid,
        })
    }
}

export const useIsInPoByPoAndEaGarpoMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsInPoByPoAndEaGarpoHook>>>, TError, { data: LoginGid }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsInPoByPoAndEaGarpoHook>>>, TError, { data: LoginGid }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const isInPoByPoAndEaGarpo = useIsInPoByPoAndEaGarpoHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useIsInPoByPoAndEaGarpoHook>>>, { data: LoginGid }> = (props) => {
        const { data } = props ?? {}

        return isInPoByPoAndEaGarpo(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type IsInPoByPoAndEaGarpoMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useIsInPoByPoAndEaGarpoHook>>>>
export type IsInPoByPoAndEaGarpoMutationBody = LoginGid
export type IsInPoByPoAndEaGarpoMutationError = ApiError

/**
 * @summary isInPoByPoAndEaGarpo
 */
export const useIsInPoByPoAndEaGarpo = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsInPoByPoAndEaGarpoHook>>>, TError, { data: LoginGid }, TContext>
}) => {
    const mutationOptions = useIsInPoByPoAndEaGarpoMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary isInPoByGid
 */
export const useIsInPoByGidHook = () => {
    const isInPoByGid = useIAmSwaggerClient<boolean>()

    return (identityOrganizationIn: IdentityOrganizationIn) => {
        return isInPoByGid({
            url: `/organizations/isInPoByGid`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: identityOrganizationIn,
        })
    }
}

export const useIsInPoByGidMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsInPoByGidHook>>>, TError, { data: IdentityOrganizationIn }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsInPoByGidHook>>>, TError, { data: IdentityOrganizationIn }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const isInPoByGid = useIsInPoByGidHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useIsInPoByGidHook>>>, { data: IdentityOrganizationIn }> = (props) => {
        const { data } = props ?? {}

        return isInPoByGid(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type IsInPoByGidMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useIsInPoByGidHook>>>>
export type IsInPoByGidMutationBody = IdentityOrganizationIn
export type IsInPoByGidMutationError = ApiError

/**
 * @summary isInPoByGid
 */
export const useIsInPoByGid = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsInPoByGidHook>>>, TError, { data: IdentityOrganizationIn }, TContext>
}) => {
    const mutationOptions = useIsInPoByGidMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary getHierarchy
 */
export const useGetHierarchyHook = () => {
    const getHierarchy = useIAmSwaggerClient<OrganizationHierarchyOut>()

    return (organizationHierarchyIn: OrganizationHierarchyIn) => {
        return getHierarchy({
            url: `/organizations/hierarchy`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: organizationHierarchyIn,
        })
    }
}

export const useGetHierarchyMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetHierarchyHook>>>, TError, { data: OrganizationHierarchyIn }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetHierarchyHook>>>, TError, { data: OrganizationHierarchyIn }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const getHierarchy = useGetHierarchyHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useGetHierarchyHook>>>, { data: OrganizationHierarchyIn }> = (props) => {
        const { data } = props ?? {}

        return getHierarchy(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type GetHierarchyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetHierarchyHook>>>>
export type GetHierarchyMutationBody = OrganizationHierarchyIn
export type GetHierarchyMutationError = ApiError

/**
 * @summary getHierarchy
 */
export const useGetHierarchy = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetHierarchyHook>>>, TError, { data: OrganizationHierarchyIn }, TContext>
}) => {
    const mutationOptions = useGetHierarchyMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary getHierarchyFromCache
 */
export const useGetHierarchyFromCacheHook = () => {
    const getHierarchyFromCache = useIAmSwaggerClient<OrganizationHierarchyOut>()

    return (organizationHierarchyInWithoutPagination: OrganizationHierarchyInWithoutPagination) => {
        return getHierarchyFromCache({
            url: `/organizations/hierarchyFromCache`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: organizationHierarchyInWithoutPagination,
        })
    }
}

export const useGetHierarchyFromCacheMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetHierarchyFromCacheHook>>>,
        TError,
        { data: OrganizationHierarchyInWithoutPagination },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGetHierarchyFromCacheHook>>>,
    TError,
    { data: OrganizationHierarchyInWithoutPagination },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const getHierarchyFromCache = useGetHierarchyFromCacheHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useGetHierarchyFromCacheHook>>>,
        { data: OrganizationHierarchyInWithoutPagination }
    > = (props) => {
        const { data } = props ?? {}

        return getHierarchyFromCache(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type GetHierarchyFromCacheMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetHierarchyFromCacheHook>>>>
export type GetHierarchyFromCacheMutationBody = OrganizationHierarchyInWithoutPagination
export type GetHierarchyFromCacheMutationError = ApiError

/**
 * @summary getHierarchyFromCache
 */
export const useGetHierarchyFromCache = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetHierarchyFromCacheHook>>>,
        TError,
        { data: OrganizationHierarchyInWithoutPagination },
        TContext
    >
}) => {
    const mutationOptions = useGetHierarchyFromCacheMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary getRightsForPO
 */
export const useGetRightsForPOHook = () => {
    const getRightsForPO = useIAmSwaggerClient<GidRoleData[]>()

    return (identityOrganizationIn: IdentityOrganizationIn) => {
        return getRightsForPO({
            url: `/organizations/getRightsForPO`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: identityOrganizationIn,
        })
    }
}

export const getGetRightsForPOQueryKey = (identityOrganizationIn: IdentityOrganizationIn) =>
    [`/organizations/getRightsForPO`, identityOrganizationIn] as const

export const useGetRightsForPOQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetRightsForPOHook>>>, TError = ApiError>(
    identityOrganizationIn: IdentityOrganizationIn,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRightsForPOHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRightsForPOHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetRightsForPOQueryKey(identityOrganizationIn)

    const getRightsForPO = useGetRightsForPOHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetRightsForPOHook>>>> = () => getRightsForPO(identityOrganizationIn)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetRightsForPOQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetRightsForPOHook>>>>
export type GetRightsForPOQueryError = ApiError

/**
 * @summary getRightsForPO
 */
export const useGetRightsForPO = <TData = Awaited<ReturnType<ReturnType<typeof useGetRightsForPOHook>>>, TError = ApiError>(
    identityOrganizationIn: IdentityOrganizationIn,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRightsForPOHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetRightsForPOQueryOptions(identityOrganizationIn, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary getRightsForPOBulk
 */
export const useGetRightsForPOBulkHook = () => {
    const getRightsForPOBulk = useIAmSwaggerClient<GidRoleDataHolder[]>()

    return (loginOrganizationIn: LoginOrganizationIn) => {
        return getRightsForPOBulk({
            url: `/organizations/getRightsForPOBulk`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: loginOrganizationIn,
        })
    }
}

export const useGetRightsForPOBulkMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetRightsForPOBulkHook>>>, TError, { data: LoginOrganizationIn }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetRightsForPOBulkHook>>>, TError, { data: LoginOrganizationIn }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const getRightsForPOBulk = useGetRightsForPOBulkHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useGetRightsForPOBulkHook>>>, { data: LoginOrganizationIn }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return getRightsForPOBulk(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type GetRightsForPOBulkMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetRightsForPOBulkHook>>>>
export type GetRightsForPOBulkMutationBody = LoginOrganizationIn
export type GetRightsForPOBulkMutationError = ApiError

/**
 * @summary getRightsForPOBulk
 */
export const useGetRightsForPOBulk = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetRightsForPOBulkHook>>>, TError, { data: LoginOrganizationIn }, TContext>
}) => {
    const mutationOptions = useGetRightsForPOBulkMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary getHighestOrganization
 */
export const useGetHighestOrganizationHook = () => {
    const getHighestOrganization = useIAmSwaggerClient<IdentityUuidWithHighestOrganization[]>()

    return (identityUuidWithGid: IdentityUuidWithGid[]) => {
        return getHighestOrganization({
            url: `/organizations/getHighestOrganization`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: identityUuidWithGid,
        })
    }
}

export const useGetHighestOrganizationMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetHighestOrganizationHook>>>,
        TError,
        { data: IdentityUuidWithGid[] },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetHighestOrganizationHook>>>, TError, { data: IdentityUuidWithGid[] }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const getHighestOrganization = useGetHighestOrganizationHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useGetHighestOrganizationHook>>>, { data: IdentityUuidWithGid[] }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return getHighestOrganization(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type GetHighestOrganizationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetHighestOrganizationHook>>>>
export type GetHighestOrganizationMutationBody = IdentityUuidWithGid[]
export type GetHighestOrganizationMutationError = ApiError

/**
 * @summary getHighestOrganization
 */
export const useGetHighestOrganization = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetHighestOrganizationHook>>>,
        TError,
        { data: IdentityUuidWithGid[] },
        TContext
    >
}) => {
    const mutationOptions = useGetHighestOrganizationMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary findOrgByGidBulk
 */
export const useFindOrgByGidBulkHook = () => {
    const findOrgByGidBulk = useIAmSwaggerClient<OrganizationWithGid[]>()

    return (findOrgByGidBulkBody: string[]) => {
        return findOrgByGidBulk({
            url: `/organizations/findOrgByGidBulk`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: findOrgByGidBulkBody,
        })
    }
}

export const useFindOrgByGidBulkMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useFindOrgByGidBulkHook>>>, TError, { data: string[] }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useFindOrgByGidBulkHook>>>, TError, { data: string[] }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const findOrgByGidBulk = useFindOrgByGidBulkHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useFindOrgByGidBulkHook>>>, { data: string[] }> = (props) => {
        const { data } = props ?? {}

        return findOrgByGidBulk(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type FindOrgByGidBulkMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindOrgByGidBulkHook>>>>
export type FindOrgByGidBulkMutationBody = string[]
export type FindOrgByGidBulkMutationError = ApiError

/**
 * @summary findOrgByGidBulk
 */
export const useFindOrgByGidBulk = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useFindOrgByGidBulkHook>>>, TError, { data: string[] }, TContext>
}) => {
    const mutationOptions = useFindOrgByGidBulkMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useGenerateNotificationForCsruHook = () => {
    const generateNotificationForCsru = useIAmSwaggerClient<void>()

    return (time: string) => {
        return generateNotificationForCsru({ url: `/organizations/csru/changes/notication/${time}`, method: 'post' })
    }
}

export const useGenerateNotificationForCsruMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGenerateNotificationForCsruHook>>>, TError, { time: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGenerateNotificationForCsruHook>>>, TError, { time: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const generateNotificationForCsru = useGenerateNotificationForCsruHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useGenerateNotificationForCsruHook>>>, { time: string }> = (props) => {
        const { time } = props ?? {}

        return generateNotificationForCsru(time)
    }

    return { mutationFn, ...mutationOptions }
}

export type GenerateNotificationForCsruMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGenerateNotificationForCsruHook>>>>

export type GenerateNotificationForCsruMutationError = ApiError

export const useGenerateNotificationForCsru = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGenerateNotificationForCsruHook>>>, TError, { time: string }, TContext>
}) => {
    const mutationOptions = useGenerateNotificationForCsruMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useApproveChangesHook = () => {
    const approveChanges = useIAmSwaggerClient<void>()

    return () => {
        return approveChanges({ url: `/organizations/csru/changes/approve`, method: 'post' })
    }
}

export const useApproveChangesMutationOptions = <TError = ApiError, TVariables = void, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useApproveChangesHook>>>, TError, TVariables, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useApproveChangesHook>>>, TError, TVariables, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const approveChanges = useApproveChangesHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useApproveChangesHook>>>, TVariables> = () => {
        return approveChanges()
    }

    return { mutationFn, ...mutationOptions }
}

export type ApproveChangesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useApproveChangesHook>>>>

export type ApproveChangesMutationError = ApiError

export const useApproveChanges = <TError = ApiError, TVariables = void, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useApproveChangesHook>>>, TError, TVariables, TContext>
}) => {
    const mutationOptions = useApproveChangesMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary getAllSubPOSet
 */
export const useGetAllSubPOSetHook = () => {
    const getAllSubPOSet = useIAmSwaggerClient<Organization[]>()

    return (getAllSubPOSetBody: string[]) => {
        return getAllSubPOSet({
            url: `/organizations/allSubPOSet`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: getAllSubPOSetBody,
        })
    }
}

export const useGetAllSubPOSetMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetAllSubPOSetHook>>>, TError, { data: string[] }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetAllSubPOSetHook>>>, TError, { data: string[] }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const getAllSubPOSet = useGetAllSubPOSetHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useGetAllSubPOSetHook>>>, { data: string[] }> = (props) => {
        const { data } = props ?? {}

        return getAllSubPOSet(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type GetAllSubPOSetMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllSubPOSetHook>>>>
export type GetAllSubPOSetMutationBody = string[]
export type GetAllSubPOSetMutationError = ApiError

/**
 * @summary getAllSubPOSet
 */
export const useGetAllSubPOSet = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetAllSubPOSetHook>>>, TError, { data: string[] }, TContext>
}) => {
    const mutationOptions = useGetAllSubPOSetMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useFindAll311Hook = () => {
    const findAll311 = useIAmSwaggerClient<FindAll311200>()

    return (params: FindAll311Params, signal?: AbortSignal) => {
        return findAll311({ url: `/identities`, method: 'get', params, signal })
    }
}

export const getFindAll311QueryKey = (params: FindAll311Params) => [`/identities`, ...(params ? [params] : [])] as const

export const useFindAll311QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindAll311Hook>>>, TError = ApiError>(
    params: FindAll311Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll311Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll311Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindAll311QueryKey(params)

    const findAll311 = useFindAll311Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindAll311Hook>>>> = ({ signal }) => findAll311(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindAll311QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindAll311Hook>>>>
export type FindAll311QueryError = ApiError

export const useFindAll311 = <TData = Awaited<ReturnType<ReturnType<typeof useFindAll311Hook>>>, TError = ApiError>(
    params: FindAll311Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll311Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindAll311QueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUpdateOrCreate1Hook = () => {
    const updateOrCreate1 = useIAmSwaggerClient<OperationResult>()

    return (identityWithoutAuthResource: IdentityWithoutAuthResource) => {
        return updateOrCreate1({
            url: `/identities`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: identityWithoutAuthResource,
        })
    }
}

export const useUpdateOrCreate1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateOrCreate1Hook>>>,
        TError,
        { data: IdentityWithoutAuthResource },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOrCreate1Hook>>>, TError, { data: IdentityWithoutAuthResource }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateOrCreate1 = useUpdateOrCreate1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateOrCreate1Hook>>>, { data: IdentityWithoutAuthResource }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return updateOrCreate1(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateOrCreate1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateOrCreate1Hook>>>>
export type UpdateOrCreate1MutationBody = IdentityWithoutAuthResource
export type UpdateOrCreate1MutationError = ApiError

export const useUpdateOrCreate1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateOrCreate1Hook>>>,
        TError,
        { data: IdentityWithoutAuthResource },
        TContext
    >
}) => {
    const mutationOptions = useUpdateOrCreate1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useAddGroupOrgRoleIdentityRelationHook = () => {
    const addGroupOrgRoleIdentityRelation = useIAmSwaggerClient<OperationResult>()

    return (
        uuid: string,
        groupUuid: string,
        roleUuid: string,
        orgId: string,
        groupOrgRoleIdentityRelationParams: GroupOrgRoleIdentityRelationParams,
    ) => {
        return addGroupOrgRoleIdentityRelation({
            url: `/identities/${uuid}/groups/${groupUuid}/roles/${roleUuid}/organizations/${orgId}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: groupOrgRoleIdentityRelationParams,
        })
    }
}

export const useAddGroupOrgRoleIdentityRelationMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddGroupOrgRoleIdentityRelationHook>>>,
        TError,
        { uuid: string; groupUuid: string; roleUuid: string; orgId: string; data: GroupOrgRoleIdentityRelationParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddGroupOrgRoleIdentityRelationHook>>>,
    TError,
    { uuid: string; groupUuid: string; roleUuid: string; orgId: string; data: GroupOrgRoleIdentityRelationParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const addGroupOrgRoleIdentityRelation = useAddGroupOrgRoleIdentityRelationHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useAddGroupOrgRoleIdentityRelationHook>>>,
        { uuid: string; groupUuid: string; roleUuid: string; orgId: string; data: GroupOrgRoleIdentityRelationParams }
    > = (props) => {
        const { uuid, groupUuid, roleUuid, orgId, data } = props ?? {}

        return addGroupOrgRoleIdentityRelation(uuid, groupUuid, roleUuid, orgId, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type AddGroupOrgRoleIdentityRelationMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useAddGroupOrgRoleIdentityRelationHook>>>
>
export type AddGroupOrgRoleIdentityRelationMutationBody = GroupOrgRoleIdentityRelationParams
export type AddGroupOrgRoleIdentityRelationMutationError = ApiError

export const useAddGroupOrgRoleIdentityRelation = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useAddGroupOrgRoleIdentityRelationHook>>>,
        TError,
        { uuid: string; groupUuid: string; roleUuid: string; orgId: string; data: GroupOrgRoleIdentityRelationParams },
        TContext
    >
}) => {
    const mutationOptions = useAddGroupOrgRoleIdentityRelationMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useCheckPasswordHook = () => {
    const checkPassword = useIAmSwaggerClient<boolean>()

    return (uuid: string, password: Password) => {
        return checkPassword({
            url: `/identities/${uuid}/check_pass`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: password,
        })
    }
}

export const useCheckPasswordMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCheckPasswordHook>>>, TError, { uuid: string; data: Password }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCheckPasswordHook>>>, TError, { uuid: string; data: Password }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const checkPassword = useCheckPasswordHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useCheckPasswordHook>>>, { uuid: string; data: Password }> = (props) => {
        const { uuid, data } = props ?? {}

        return checkPassword(uuid, data)
    }

    return { mutationFn, ...mutationOptions }
}

export type CheckPasswordMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCheckPasswordHook>>>>
export type CheckPasswordMutationBody = Password
export type CheckPasswordMutationError = ApiError

export const useCheckPassword = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useCheckPasswordHook>>>, TError, { uuid: string; data: Password }, TContext>
}) => {
    const mutationOptions = useCheckPasswordMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateWithOnePoAndRolesHook = () => {
    const updateWithOnePoAndRoles = useIAmSwaggerClient<OperationResult>()

    return (identityWithOnePoAndRolesWithoutAuthResource: IdentityWithOnePoAndRolesWithoutAuthResource) => {
        return updateWithOnePoAndRoles({
            url: `/identities/withOnePoAndRoles`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: identityWithOnePoAndRolesWithoutAuthResource,
        })
    }
}

export const useUpdateWithOnePoAndRolesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateWithOnePoAndRolesHook>>>,
        TError,
        { data: IdentityWithOnePoAndRolesWithoutAuthResource },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateWithOnePoAndRolesHook>>>,
    TError,
    { data: IdentityWithOnePoAndRolesWithoutAuthResource },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateWithOnePoAndRoles = useUpdateWithOnePoAndRolesHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateWithOnePoAndRolesHook>>>,
        { data: IdentityWithOnePoAndRolesWithoutAuthResource }
    > = (props) => {
        const { data } = props ?? {}

        return updateWithOnePoAndRoles(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateWithOnePoAndRolesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateWithOnePoAndRolesHook>>>>
export type UpdateWithOnePoAndRolesMutationBody = IdentityWithOnePoAndRolesWithoutAuthResource
export type UpdateWithOnePoAndRolesMutationError = ApiError

export const useUpdateWithOnePoAndRoles = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateWithOnePoAndRolesHook>>>,
        TError,
        { data: IdentityWithOnePoAndRolesWithoutAuthResource },
        TContext
    >
}) => {
    const mutationOptions = useUpdateWithOnePoAndRolesMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateOrCreateWithGidHook = () => {
    const updateOrCreateWithGid = useIAmSwaggerClient<OperationResult>()

    return (identityWithGidWithoutAuthResource: IdentityWithGidWithoutAuthResource) => {
        return updateOrCreateWithGid({
            url: `/identities/withGid`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: identityWithGidWithoutAuthResource,
        })
    }
}

export const useUpdateOrCreateWithGidMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateOrCreateWithGidHook>>>,
        TError,
        { data: IdentityWithGidWithoutAuthResource },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateOrCreateWithGidHook>>>,
    TError,
    { data: IdentityWithGidWithoutAuthResource },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateOrCreateWithGid = useUpdateOrCreateWithGidHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useUpdateOrCreateWithGidHook>>>,
        { data: IdentityWithGidWithoutAuthResource }
    > = (props) => {
        const { data } = props ?? {}

        return updateOrCreateWithGid(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateOrCreateWithGidMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateOrCreateWithGidHook>>>>
export type UpdateOrCreateWithGidMutationBody = IdentityWithGidWithoutAuthResource
export type UpdateOrCreateWithGidMutationError = ApiError

export const useUpdateOrCreateWithGid = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateOrCreateWithGidHook>>>,
        TError,
        { data: IdentityWithGidWithoutAuthResource },
        TContext
    >
}) => {
    const mutationOptions = useUpdateOrCreateWithGidMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateIdentityStateHook = () => {
    const updateIdentityState = useIAmSwaggerClient<OperationResult>()

    return (identityUuidWithState: IdentityUuidWithState) => {
        return updateIdentityState({
            url: `/identities/updateIdentityState`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: identityUuidWithState,
        })
    }
}

export const useUpdateIdentityStateMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateIdentityStateHook>>>,
        TError,
        { data: IdentityUuidWithState },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateIdentityStateHook>>>, TError, { data: IdentityUuidWithState }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateIdentityState = useUpdateIdentityStateHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateIdentityStateHook>>>, { data: IdentityUuidWithState }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return updateIdentityState(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateIdentityStateMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateIdentityStateHook>>>>
export type UpdateIdentityStateMutationBody = IdentityUuidWithState
export type UpdateIdentityStateMutationError = ApiError

export const useUpdateIdentityState = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useUpdateIdentityStateHook>>>,
        TError,
        { data: IdentityUuidWithState },
        TContext
    >
}) => {
    const mutationOptions = useUpdateIdentityStateMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useUpdateGidBulkHook = () => {
    const updateGidBulk = useIAmSwaggerClient<UpdateRoleBulkResponse>()

    return (updateRoleBulkRequest: UpdateRoleBulkRequest) => {
        return updateGidBulk({
            url: `/identities/updateGidBulk`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: updateRoleBulkRequest,
        })
    }
}

export const useUpdateGidBulkMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateGidBulkHook>>>, TError, { data: UpdateRoleBulkRequest }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateGidBulkHook>>>, TError, { data: UpdateRoleBulkRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateGidBulk = useUpdateGidBulkHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateGidBulkHook>>>, { data: UpdateRoleBulkRequest }> = (props) => {
        const { data } = props ?? {}

        return updateGidBulk(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateGidBulkMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateGidBulkHook>>>>
export type UpdateGidBulkMutationBody = UpdateRoleBulkRequest
export type UpdateGidBulkMutationError = ApiError

export const useUpdateGidBulk = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateGidBulkHook>>>, TError, { data: UpdateRoleBulkRequest }, TContext>
}) => {
    const mutationOptions = useUpdateGidBulkMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useLinkClarityUserHook = () => {
    const linkClarityUser = useIAmSwaggerClient<OperationResultGenericIdentity>()

    return (identity: Identity) => {
        return linkClarityUser({
            url: `/identities/linkClarityUser`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: identity,
        })
    }
}

export const useLinkClarityUserMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useLinkClarityUserHook>>>, TError, { data: Identity }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useLinkClarityUserHook>>>, TError, { data: Identity }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const linkClarityUser = useLinkClarityUserHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useLinkClarityUserHook>>>, { data: Identity }> = (props) => {
        const { data } = props ?? {}

        return linkClarityUser(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type LinkClarityUserMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useLinkClarityUserHook>>>>
export type LinkClarityUserMutationBody = Identity
export type LinkClarityUserMutationError = ApiError

export const useLinkClarityUser = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useLinkClarityUserHook>>>, TError, { data: Identity }, TContext>
}) => {
    const mutationOptions = useLinkClarityUserMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary isAdminBulk
 */
export const useIsAdminBulkHook = () => {
    const isAdminBulk = useIAmSwaggerClient<IdentityUuidWithState[]>()

    return (isAdminBulkBody: string[]) => {
        return isAdminBulk({ url: `/identities/isAdminBulk`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: isAdminBulkBody })
    }
}

export const useIsAdminBulkMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsAdminBulkHook>>>, TError, { data: string[] }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsAdminBulkHook>>>, TError, { data: string[] }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const isAdminBulk = useIsAdminBulkHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useIsAdminBulkHook>>>, { data: string[] }> = (props) => {
        const { data } = props ?? {}

        return isAdminBulk(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type IsAdminBulkMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useIsAdminBulkHook>>>>
export type IsAdminBulkMutationBody = string[]
export type IsAdminBulkMutationError = ApiError

/**
 * @summary isAdminBulk
 */
export const useIsAdminBulk = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsAdminBulkHook>>>, TError, { data: string[] }, TContext>
}) => {
    const mutationOptions = useIsAdminBulkMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary getIdentitiesByLoginsBulk
 */
export const useGetIdentitiesByLoginsBulkHook = () => {
    const getIdentitiesByLoginsBulk = useIAmSwaggerClient<FindByLoginOut[]>()

    return (getIdentitiesByLoginsBulkBody: string[]) => {
        return getIdentitiesByLoginsBulk({
            url: `/identities/getIdentitiesByLoginsBulk/`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: getIdentitiesByLoginsBulkBody,
        })
    }
}

export const getGetIdentitiesByLoginsBulkQueryKey = (getIdentitiesByLoginsBulkBody: string[]) =>
    [`/identities/getIdentitiesByLoginsBulk/`, getIdentitiesByLoginsBulkBody] as const

export const useGetIdentitiesByLoginsBulkQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetIdentitiesByLoginsBulkHook>>>,
    TError = ApiError,
>(
    getIdentitiesByLoginsBulkBody: string[],
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIdentitiesByLoginsBulkHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIdentitiesByLoginsBulkHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetIdentitiesByLoginsBulkQueryKey(getIdentitiesByLoginsBulkBody)

    const getIdentitiesByLoginsBulk = useGetIdentitiesByLoginsBulkHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetIdentitiesByLoginsBulkHook>>>> = () =>
        getIdentitiesByLoginsBulk(getIdentitiesByLoginsBulkBody)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetIdentitiesByLoginsBulkQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetIdentitiesByLoginsBulkHook>>>>
export type GetIdentitiesByLoginsBulkQueryError = ApiError

/**
 * @summary getIdentitiesByLoginsBulk
 */
export const useGetIdentitiesByLoginsBulk = <TData = Awaited<ReturnType<ReturnType<typeof useGetIdentitiesByLoginsBulkHook>>>, TError = ApiError>(
    getIdentitiesByLoginsBulkBody: string[],
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIdentitiesByLoginsBulkHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetIdentitiesByLoginsBulkQueryOptions(getIdentitiesByLoginsBulkBody, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @summary getByUuidBulk
 */
export const useGetByUuidBulkHook = () => {
    const getByUuidBulk = useIAmSwaggerClient<IdentityWithResult[]>()

    return (getByUuidBulkBody: string[]) => {
        return getByUuidBulk({
            url: `/identities/getByUuidBulk`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: getByUuidBulkBody,
        })
    }
}

export const useGetByUuidBulkMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetByUuidBulkHook>>>, TError, { data: string[] }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetByUuidBulkHook>>>, TError, { data: string[] }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const getByUuidBulk = useGetByUuidBulkHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useGetByUuidBulkHook>>>, { data: string[] }> = (props) => {
        const { data } = props ?? {}

        return getByUuidBulk(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type GetByUuidBulkMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetByUuidBulkHook>>>>
export type GetByUuidBulkMutationBody = string[]
export type GetByUuidBulkMutationError = ApiError

/**
 * @summary getByUuidBulk
 */
export const useGetByUuidBulk = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetByUuidBulkHook>>>, TError, { data: string[] }, TContext>
}) => {
    const mutationOptions = useGetByUuidBulkMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary findOrganizationsForList
 */
export const useFindOrganizationsForListHook = () => {
    const findOrganizationsForList = useIAmSwaggerClient<StringList[]>()

    return (findOrganizationsForListBody: string[]) => {
        return findOrganizationsForList({
            url: `/identities/findOrganizationsForList`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: findOrganizationsForListBody,
        })
    }
}

export const useFindOrganizationsForListMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useFindOrganizationsForListHook>>>, TError, { data: string[] }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useFindOrganizationsForListHook>>>, TError, { data: string[] }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const findOrganizationsForList = useFindOrganizationsForListHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useFindOrganizationsForListHook>>>, { data: string[] }> = (props) => {
        const { data } = props ?? {}

        return findOrganizationsForList(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type FindOrganizationsForListMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindOrganizationsForListHook>>>>
export type FindOrganizationsForListMutationBody = string[]
export type FindOrganizationsForListMutationError = ApiError

/**
 * @summary findOrganizationsForList
 */
export const useFindOrganizationsForList = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useFindOrganizationsForListHook>>>, TError, { data: string[] }, TContext>
}) => {
    const mutationOptions = useFindOrganizationsForListMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useExportIdentitiesHook = () => {
    const exportIdentities = useIAmSwaggerClient<Blob>()

    return (exportIdentitiesBody: string[], params?: ExportIdentitiesParams) => {
        return exportIdentities({
            url: `/identities/export`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: exportIdentitiesBody,
            params,
            responseType: 'blob',
        })
    }
}

export const useExportIdentitiesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportIdentitiesHook>>>,
        TError,
        { data: string[]; params?: ExportIdentitiesParams },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportIdentitiesHook>>>,
    TError,
    { data: string[]; params?: ExportIdentitiesParams },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const exportIdentities = useExportIdentitiesHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useExportIdentitiesHook>>>,
        { data: string[]; params?: ExportIdentitiesParams }
    > = (props) => {
        const { data, params } = props ?? {}

        return exportIdentities(data, params)
    }

    return { mutationFn, ...mutationOptions }
}

export type ExportIdentitiesMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useExportIdentitiesHook>>>>
export type ExportIdentitiesMutationBody = string[]
export type ExportIdentitiesMutationError = ApiError

export const useExportIdentities = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useExportIdentitiesHook>>>,
        TError,
        { data: string[]; params?: ExportIdentitiesParams },
        TContext
    >
}) => {
    const mutationOptions = useExportIdentitiesMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useChangePassword1Hook = () => {
    const changePassword1 = useIAmSwaggerClient<OperationResult>()

    return (changePasswordRequest: ChangePasswordRequest) => {
        return changePassword1({
            url: `/identities/change_pass`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: changePasswordRequest,
        })
    }
}

export const useChangePassword1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangePassword1Hook>>>, TError, { data: ChangePasswordRequest }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangePassword1Hook>>>, TError, { data: ChangePasswordRequest }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const changePassword1 = useChangePassword1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useChangePassword1Hook>>>, { data: ChangePasswordRequest }> = (props) => {
        const { data } = props ?? {}

        return changePassword1(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ChangePassword1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useChangePassword1Hook>>>>
export type ChangePassword1MutationBody = ChangePasswordRequest
export type ChangePassword1MutationError = ApiError

export const useChangePassword1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangePassword1Hook>>>, TError, { data: ChangePasswordRequest }, TContext>
}) => {
    const mutationOptions = useChangePassword1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useChangeIdentityTermsHook = () => {
    const changeIdentityTerms = useIAmSwaggerClient<OperationResult>()

    return (identityTerms: IdentityTerms) => {
        return changeIdentityTerms({
            url: `/identities/changeIdentityTerms`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: identityTerms,
        })
    }
}

export const useChangeIdentityTermsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangeIdentityTermsHook>>>, TError, { data: IdentityTerms }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangeIdentityTermsHook>>>, TError, { data: IdentityTerms }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const changeIdentityTerms = useChangeIdentityTermsHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useChangeIdentityTermsHook>>>, { data: IdentityTerms }> = (props) => {
        const { data } = props ?? {}

        return changeIdentityTerms(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ChangeIdentityTermsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useChangeIdentityTermsHook>>>>
export type ChangeIdentityTermsMutationBody = IdentityTerms
export type ChangeIdentityTermsMutationError = ApiError

export const useChangeIdentityTerms = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangeIdentityTermsHook>>>, TError, { data: IdentityTerms }, TContext>
}) => {
    const mutationOptions = useChangeIdentityTermsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useChangeIdentityProfileHook = () => {
    const changeIdentityProfile = useIAmSwaggerClient<OperationResult>()

    return (identityProfile: IdentityProfile) => {
        return changeIdentityProfile({
            url: `/identities/changeIdentityProfile`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: identityProfile,
        })
    }
}

export const useChangeIdentityProfileMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangeIdentityProfileHook>>>, TError, { data: IdentityProfile }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangeIdentityProfileHook>>>, TError, { data: IdentityProfile }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const changeIdentityProfile = useChangeIdentityProfileHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useChangeIdentityProfileHook>>>, { data: IdentityProfile }> = (props) => {
        const { data } = props ?? {}

        return changeIdentityProfile(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ChangeIdentityProfileMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useChangeIdentityProfileHook>>>>
export type ChangeIdentityProfileMutationBody = IdentityProfile
export type ChangeIdentityProfileMutationError = ApiError

export const useChangeIdentityProfile = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useChangeIdentityProfileHook>>>, TError, { data: IdentityProfile }, TContext>
}) => {
    const mutationOptions = useChangeIdentityProfileMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useChangeIdentityNotificationsHook = () => {
    const changeIdentityNotifications = useIAmSwaggerClient<OperationResult>()

    return (identityNotifications: IdentityNotifications) => {
        return changeIdentityNotifications({
            url: `/identities/changeIdentityNotification`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: identityNotifications,
        })
    }
}

export const useChangeIdentityNotificationsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useChangeIdentityNotificationsHook>>>,
        TError,
        { data: IdentityNotifications },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useChangeIdentityNotificationsHook>>>,
    TError,
    { data: IdentityNotifications },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const changeIdentityNotifications = useChangeIdentityNotificationsHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useChangeIdentityNotificationsHook>>>,
        { data: IdentityNotifications }
    > = (props) => {
        const { data } = props ?? {}

        return changeIdentityNotifications(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type ChangeIdentityNotificationsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useChangeIdentityNotificationsHook>>>>
export type ChangeIdentityNotificationsMutationBody = IdentityNotifications
export type ChangeIdentityNotificationsMutationError = ApiError

export const useChangeIdentityNotifications = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useChangeIdentityNotificationsHook>>>,
        TError,
        { data: IdentityNotifications },
        TContext
    >
}) => {
    const mutationOptions = useChangeIdentityNotificationsMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useFind2111Hook = () => {
    const find2111 = useIAmSwaggerClient<Find2111200>()

    return (params?: Find2111Params, signal?: AbortSignal) => {
        return find2111({ url: `/groups`, method: 'get', params, signal })
    }
}

export const getFind2111QueryKey = (params?: Find2111Params) => [`/groups`, ...(params ? [params] : [])] as const

export const useFind2111QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFind2111Hook>>>, TError = ApiError>(
    params?: Find2111Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFind2111Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFind2111Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFind2111QueryKey(params)

    const find2111 = useFind2111Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFind2111Hook>>>> = ({ signal }) => find2111(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type Find2111QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFind2111Hook>>>>
export type Find2111QueryError = ApiError

export const useFind2111 = <TData = Awaited<ReturnType<ReturnType<typeof useFind2111Hook>>>, TError = ApiError>(
    params?: Find2111Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFind2111Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFind2111QueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useUpdateOrCreate2Hook = () => {
    const updateOrCreate2 = useIAmSwaggerClient<OperationResult>()

    return (group: Group) => {
        return updateOrCreate2({ url: `/groups`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: group })
    }
}

export const useUpdateOrCreate2MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOrCreate2Hook>>>, TError, { data: Group }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOrCreate2Hook>>>, TError, { data: Group }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const updateOrCreate2 = useUpdateOrCreate2Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useUpdateOrCreate2Hook>>>, { data: Group }> = (props) => {
        const { data } = props ?? {}

        return updateOrCreate2(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type UpdateOrCreate2MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateOrCreate2Hook>>>>
export type UpdateOrCreate2MutationBody = Group
export type UpdateOrCreate2MutationError = ApiError

export const useUpdateOrCreate2 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useUpdateOrCreate2Hook>>>, TError, { data: Group }, TContext>
}) => {
    const mutationOptions = useUpdateOrCreate2MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useIsInPoByGid1Hook = () => {
    const isInPoByGid1 = useIAmSwaggerClient<IsInPoByGidOutput>()

    return (isInPoByGidInput: IsInPoByGidInput) => {
        return isInPoByGid1({ url: `/gids/isInPoByGid`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: isInPoByGidInput })
    }
}

export const useIsInPoByGid1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsInPoByGid1Hook>>>, TError, { data: IsInPoByGidInput }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsInPoByGid1Hook>>>, TError, { data: IsInPoByGidInput }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const isInPoByGid1 = useIsInPoByGid1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useIsInPoByGid1Hook>>>, { data: IsInPoByGidInput }> = (props) => {
        const { data } = props ?? {}

        return isInPoByGid1(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type IsInPoByGid1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useIsInPoByGid1Hook>>>>
export type IsInPoByGid1MutationBody = IsInPoByGidInput
export type IsInPoByGid1MutationError = ApiError

export const useIsInPoByGid1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useIsInPoByGid1Hook>>>, TError, { data: IsInPoByGidInput }, TContext>
}) => {
    const mutationOptions = useIsInPoByGid1MutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary find
 */
export const useFindHook = () => {
    const find = useIAmSwaggerClient<MessageGid[]>()

    return (findBody: string[]) => {
        return find({ url: `/gids/gidList`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: findBody })
    }
}

export const useFindMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useFindHook>>>, TError, { data: string[] }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useFindHook>>>, TError, { data: string[] }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const find = useFindHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useFindHook>>>, { data: string[] }> = (props) => {
        const { data } = props ?? {}

        return find(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type FindMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindHook>>>>
export type FindMutationBody = string[]
export type FindMutationError = ApiError

/**
 * @summary find
 */
export const useFind = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useFindHook>>>, TError, { data: string[] }, TContext>
}) => {
    const mutationOptions = useFindMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDataChangeHook = () => {
    const dataChange = useIAmSwaggerClient<OperationResult>()

    return (dataChangeBody: string[]) => {
        return dataChange({ url: `/data_synchronization`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: dataChangeBody })
    }
}

export const useDataChangeMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDataChangeHook>>>, TError, { data: string[] }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDataChangeHook>>>, TError, { data: string[] }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const dataChange = useDataChangeHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDataChangeHook>>>, { data: string[] }> = (props) => {
        const { data } = props ?? {}

        return dataChange(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type DataChangeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDataChangeHook>>>>
export type DataChangeMutationBody = string[]
export type DataChangeMutationError = ApiError

export const useDataChange = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDataChangeHook>>>, TError, { data: string[] }, TContext>
}) => {
    const mutationOptions = useDataChangeMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useRequestPermissionsHook = () => {
    const requestPermissions = useIAmSwaggerClient<OperationResult>()

    return (userPermissionAssignData: UserPermissionAssignData) => {
        return requestPermissions({
            url: `/access_rights/requestPermissions`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: userPermissionAssignData,
        })
    }
}

export const useRequestPermissionsMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRequestPermissionsHook>>>,
        TError,
        { data: UserPermissionAssignData },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useRequestPermissionsHook>>>, TError, { data: UserPermissionAssignData }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const requestPermissions = useRequestPermissionsHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useRequestPermissionsHook>>>, { data: UserPermissionAssignData }> = (
        props,
    ) => {
        const { data } = props ?? {}

        return requestPermissions(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type RequestPermissionsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRequestPermissionsHook>>>>
export type RequestPermissionsMutationBody = UserPermissionAssignData
export type RequestPermissionsMutationError = ApiError

export const useRequestPermissions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRequestPermissionsHook>>>,
        TError,
        { data: UserPermissionAssignData },
        TContext
    >
}) => {
    const mutationOptions = useRequestPermissionsMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary getOwnership
 */
export const useGetOwnershipHook = () => {
    const getOwnership = useIAmSwaggerClient<ResponseOwnership[]>()

    return (getOwnershipRequest: GetOwnershipRequest[]) => {
        return getOwnership({
            url: `/access_rights/ownership`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: getOwnershipRequest,
        })
    }
}

export const useGetOwnershipMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetOwnershipHook>>>, TError, { data: GetOwnershipRequest[] }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetOwnershipHook>>>, TError, { data: GetOwnershipRequest[] }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const getOwnership = useGetOwnershipHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useGetOwnershipHook>>>, { data: GetOwnershipRequest[] }> = (props) => {
        const { data } = props ?? {}

        return getOwnership(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type GetOwnershipMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOwnershipHook>>>>
export type GetOwnershipMutationBody = GetOwnershipRequest[]
export type GetOwnershipMutationError = ApiError

/**
 * @summary getOwnership
 */
export const useGetOwnership = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetOwnershipHook>>>, TError, { data: GetOwnershipRequest[] }, TContext>
}) => {
    const mutationOptions = useGetOwnershipMutationOptions(options)

    return useMutation(mutationOptions)
}

/**
 * @summary getAttributeOwnership
 */
export const useGetAttributeOwnershipHook = () => {
    const getAttributeOwnership = useIAmSwaggerClient<ResponseMapStringOwnership[]>()

    return (getAttributeOwnershipRequest: GetAttributeOwnershipRequest[]) => {
        return getAttributeOwnership({
            url: `/access_rights/attribute_ownership`,
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: getAttributeOwnershipRequest,
        })
    }
}

export const useGetAttributeOwnershipMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetAttributeOwnershipHook>>>,
        TError,
        { data: GetAttributeOwnershipRequest[] },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGetAttributeOwnershipHook>>>,
    TError,
    { data: GetAttributeOwnershipRequest[] },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const getAttributeOwnership = useGetAttributeOwnershipHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useGetAttributeOwnershipHook>>>,
        { data: GetAttributeOwnershipRequest[] }
    > = (props) => {
        const { data } = props ?? {}

        return getAttributeOwnership(data)
    }

    return { mutationFn, ...mutationOptions }
}

export type GetAttributeOwnershipMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAttributeOwnershipHook>>>>
export type GetAttributeOwnershipMutationBody = GetAttributeOwnershipRequest[]
export type GetAttributeOwnershipMutationError = ApiError

/**
 * @summary getAttributeOwnership
 */
export const useGetAttributeOwnership = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useGetAttributeOwnershipHook>>>,
        TError,
        { data: GetAttributeOwnershipRequest[] },
        TContext
    >
}) => {
    const mutationOptions = useGetAttributeOwnershipMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useFindByUuidHook = () => {
    const findByUuid = useIAmSwaggerClient<Role>()

    return (uuid: string, signal?: AbortSignal) => {
        return findByUuid({ url: `/roles/${uuid}`, method: 'get', signal })
    }
}

export const getFindByUuidQueryKey = (uuid: string) => [`/roles/${uuid}`] as const

export const useFindByUuidQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindByUuidHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByUuidHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByUuidHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindByUuidQueryKey(uuid)

    const findByUuid = useFindByUuidHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindByUuidHook>>>> = ({ signal }) => findByUuid(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindByUuidQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindByUuidHook>>>>
export type FindByUuidQueryError = ApiError

export const useFindByUuid = <TData = Awaited<ReturnType<ReturnType<typeof useFindByUuidHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByUuidHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindByUuidQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const use_DeleteHook = () => {
    const _delete = useIAmSwaggerClient<OperationResult>()

    return (uuid: string) => {
        return _delete({ url: `/roles/${uuid}`, method: 'delete' })
    }
}

export const useDeleteMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof use_DeleteHook>>>, TError, { uuid: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof use_DeleteHook>>>, TError, { uuid: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const _delete = use_DeleteHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof use_DeleteHook>>>, { uuid: string }> = (props) => {
        const { uuid } = props ?? {}

        return _delete(uuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type _DeleteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof use_DeleteHook>>>>

export type _DeleteMutationError = ApiError

export const useDelete = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof use_DeleteHook>>>, TError, { uuid: string }, TContext>
}) => {
    const mutationOptions = useDeleteMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useFindSubRolesHook = () => {
    const findSubRoles = useIAmSwaggerClient<Role[]>()

    return (uuid: string, signal?: AbortSignal) => {
        return findSubRoles({ url: `/roles/${uuid}/roles`, method: 'get', signal })
    }
}

export const getFindSubRolesQueryKey = (uuid: string) => [`/roles/${uuid}/roles`] as const

export const useFindSubRolesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindSubRolesHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindSubRolesHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindSubRolesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindSubRolesQueryKey(uuid)

    const findSubRoles = useFindSubRolesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindSubRolesHook>>>> = ({ signal }) => findSubRoles(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindSubRolesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindSubRolesHook>>>>
export type FindSubRolesQueryError = ApiError

export const useFindSubRoles = <TData = Awaited<ReturnType<ReturnType<typeof useFindSubRolesHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindSubRolesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindSubRolesQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindRelatedIdentitiesHook = () => {
    const findRelatedIdentities = useIAmSwaggerClient<RelatedIdentity[]>()

    return (uuid: string, signal?: AbortSignal) => {
        return findRelatedIdentities({ url: `/roles/${uuid}/identities`, method: 'get', signal })
    }
}

export const getFindRelatedIdentitiesQueryKey = (uuid: string) => [`/roles/${uuid}/identities`] as const

export const useFindRelatedIdentitiesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindRelatedIdentitiesQueryKey(uuid)

    const findRelatedIdentities = useFindRelatedIdentitiesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesHook>>>> = ({ signal }) =>
        findRelatedIdentities(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindRelatedIdentitiesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesHook>>>>
export type FindRelatedIdentitiesQueryError = ApiError

export const useFindRelatedIdentities = <TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindRelatedIdentitiesQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindRelatedIdentitiesWithPOHook = () => {
    const findRelatedIdentitiesWithPO = useIAmSwaggerClient<RelatedIdentityWithPo[]>()

    return (uuid: string, signal?: AbortSignal) => {
        return findRelatedIdentitiesWithPO({ url: `/roles/${uuid}/identitiesWithPo`, method: 'get', signal })
    }
}

export const getFindRelatedIdentitiesWithPOQueryKey = (uuid: string) => [`/roles/${uuid}/identitiesWithPo`] as const

export const useFindRelatedIdentitiesWithPOQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesWithPOHook>>>,
    TError = ApiError,
>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesWithPOHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesWithPOHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindRelatedIdentitiesWithPOQueryKey(uuid)

    const findRelatedIdentitiesWithPO = useFindRelatedIdentitiesWithPOHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesWithPOHook>>>> = ({ signal }) =>
        findRelatedIdentitiesWithPO(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindRelatedIdentitiesWithPOQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesWithPOHook>>>>
export type FindRelatedIdentitiesWithPOQueryError = ApiError

export const useFindRelatedIdentitiesWithPO = <TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesWithPOHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesWithPOHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindRelatedIdentitiesWithPOQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindGidRelationsHook = () => {
    const findGidRelations = useIAmSwaggerClient<RoleOrgGroup[]>()

    return (uuid: string, signal?: AbortSignal) => {
        return findGidRelations({ url: `/roles/${uuid}/gids`, method: 'get', signal })
    }
}

export const getFindGidRelationsQueryKey = (uuid: string) => [`/roles/${uuid}/gids`] as const

export const useFindGidRelationsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindGidRelationsHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindGidRelationsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindGidRelationsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindGidRelationsQueryKey(uuid)

    const findGidRelations = useFindGidRelationsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindGidRelationsHook>>>> = ({ signal }) => findGidRelations(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindGidRelationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindGidRelationsHook>>>>
export type FindGidRelationsQueryError = ApiError

export const useFindGidRelations = <TData = Awaited<ReturnType<ReturnType<typeof useFindGidRelationsHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindGidRelationsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindGidRelationsQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindRelatedIdentitiesWithOrganizationHook = () => {
    const findRelatedIdentitiesWithOrganization = useIAmSwaggerClient<Identity[]>()

    return (uuid: string, signal?: AbortSignal) => {
        return findRelatedIdentitiesWithOrganization({ url: `/roles/${uuid}/findRelatedIdentitiesWithOrganization`, method: 'get', signal })
    }
}

export const getFindRelatedIdentitiesWithOrganizationQueryKey = (uuid: string) => [`/roles/${uuid}/findRelatedIdentitiesWithOrganization`] as const

export const useFindRelatedIdentitiesWithOrganizationQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesWithOrganizationHook>>>,
    TError = ApiError,
>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesWithOrganizationHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesWithOrganizationHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindRelatedIdentitiesWithOrganizationQueryKey(uuid)

    const findRelatedIdentitiesWithOrganization = useFindRelatedIdentitiesWithOrganizationHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesWithOrganizationHook>>>> = ({ signal }) =>
        findRelatedIdentitiesWithOrganization(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindRelatedIdentitiesWithOrganizationQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesWithOrganizationHook>>>
>
export type FindRelatedIdentitiesWithOrganizationQueryError = ApiError

export const useFindRelatedIdentitiesWithOrganization = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesWithOrganizationHook>>>,
    TError = ApiError,
>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesWithOrganizationHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindRelatedIdentitiesWithOrganizationQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindOrgCmdbIdsHook = () => {
    const findOrgCmdbIds = useIAmSwaggerClient<string[]>()

    return (roleUuid: string, signal?: AbortSignal) => {
        return findOrgCmdbIds({ url: `/roles/${roleUuid}/organizations`, method: 'get', signal })
    }
}

export const getFindOrgCmdbIdsQueryKey = (roleUuid: string) => [`/roles/${roleUuid}/organizations`] as const

export const useFindOrgCmdbIdsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindOrgCmdbIdsHook>>>, TError = ApiError>(
    roleUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindOrgCmdbIdsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindOrgCmdbIdsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindOrgCmdbIdsQueryKey(roleUuid)

    const findOrgCmdbIds = useFindOrgCmdbIdsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindOrgCmdbIdsHook>>>> = ({ signal }) => findOrgCmdbIds(roleUuid, signal)

    return { queryKey, queryFn, enabled: !!roleUuid, ...queryOptions }
}

export type FindOrgCmdbIdsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindOrgCmdbIdsHook>>>>
export type FindOrgCmdbIdsQueryError = ApiError

export const useFindOrgCmdbIds = <TData = Awaited<ReturnType<ReturnType<typeof useFindOrgCmdbIdsHook>>>, TError = ApiError>(
    roleUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindOrgCmdbIdsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindOrgCmdbIdsQueryOptions(roleUuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindByNameWithParamsCountHook = () => {
    const findByNameWithParamsCount = useIAmSwaggerClient<number>()

    return (params: FindByNameWithParamsCountParams, signal?: AbortSignal) => {
        return findByNameWithParamsCount({ url: `/roles/withParams/pages`, method: 'get', params, signal })
    }
}

export const getFindByNameWithParamsCountQueryKey = (params: FindByNameWithParamsCountParams) =>
    [`/roles/withParams/pages`, ...(params ? [params] : [])] as const

export const useFindByNameWithParamsCountQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindByNameWithParamsCountHook>>>,
    TError = ApiError,
>(
    params: FindByNameWithParamsCountParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByNameWithParamsCountHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByNameWithParamsCountHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindByNameWithParamsCountQueryKey(params)

    const findByNameWithParamsCount = useFindByNameWithParamsCountHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindByNameWithParamsCountHook>>>> = ({ signal }) =>
        findByNameWithParamsCount(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindByNameWithParamsCountQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindByNameWithParamsCountHook>>>>
export type FindByNameWithParamsCountQueryError = ApiError

export const useFindByNameWithParamsCount = <TData = Awaited<ReturnType<ReturnType<typeof useFindByNameWithParamsCountHook>>>, TError = ApiError>(
    params: FindByNameWithParamsCountParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByNameWithParamsCountHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindByNameWithParamsCountQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindByNameWithParamsHook = () => {
    const findByNameWithParams = useIAmSwaggerClient<Role[]>()

    return (page: number, limit: number, params: FindByNameWithParamsParams, signal?: AbortSignal) => {
        return findByNameWithParams({ url: `/roles/withParams/page/${page}/${limit}`, method: 'get', params, signal })
    }
}

export const getFindByNameWithParamsQueryKey = (page: number, limit: number, params: FindByNameWithParamsParams) =>
    [`/roles/withParams/page/${page}/${limit}`, ...(params ? [params] : [])] as const

export const useFindByNameWithParamsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindByNameWithParamsHook>>>, TError = ApiError>(
    page: number,
    limit: number,
    params: FindByNameWithParamsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByNameWithParamsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByNameWithParamsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindByNameWithParamsQueryKey(page, limit, params)

    const findByNameWithParams = useFindByNameWithParamsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindByNameWithParamsHook>>>> = ({ signal }) =>
        findByNameWithParams(page, limit, params, signal)

    return { queryKey, queryFn, enabled: !!(page && limit), ...queryOptions }
}

export type FindByNameWithParamsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindByNameWithParamsHook>>>>
export type FindByNameWithParamsQueryError = ApiError

export const useFindByNameWithParams = <TData = Awaited<ReturnType<ReturnType<typeof useFindByNameWithParamsHook>>>, TError = ApiError>(
    page: number,
    limit: number,
    params: FindByNameWithParamsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByNameWithParamsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindByNameWithParamsQueryOptions(page, limit, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetPagesHook = () => {
    const getPages = useIAmSwaggerClient<number>()

    return (signal?: AbortSignal) => {
        return getPages({ url: `/roles/pages`, method: 'get', signal })
    }
}

export const getGetPagesQueryKey = () => [`/roles/pages`] as const

export const useGetPagesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetPagesHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPagesHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPagesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetPagesQueryKey()

    const getPages = useGetPagesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPagesHook>>>> = ({ signal }) => getPages(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetPagesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetPagesHook>>>>
export type GetPagesQueryError = ApiError

export const useGetPages = <TData = Awaited<ReturnType<ReturnType<typeof useGetPagesHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPagesHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetPagesQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindAll1Hook = () => {
    const findAll1 = useIAmSwaggerClient<Role[]>()

    return (page: number, limit: number, params?: FindAll1Params, signal?: AbortSignal) => {
        return findAll1({ url: `/roles/page/${page}/${limit}`, method: 'get', params, signal })
    }
}

export const getFindAll1QueryKey = (page: number, limit: number, params?: FindAll1Params) =>
    [`/roles/page/${page}/${limit}`, ...(params ? [params] : [])] as const

export const useFindAll1QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindAll1Hook>>>, TError = ApiError>(
    page: number,
    limit: number,
    params?: FindAll1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll1Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll1Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindAll1QueryKey(page, limit, params)

    const findAll1 = useFindAll1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindAll1Hook>>>> = ({ signal }) => findAll1(page, limit, params, signal)

    return { queryKey, queryFn, enabled: !!(page && limit), ...queryOptions }
}

export type FindAll1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindAll1Hook>>>>
export type FindAll1QueryError = ApiError

export const useFindAll1 = <TData = Awaited<ReturnType<ReturnType<typeof useFindAll1Hook>>>, TError = ApiError>(
    page: number,
    limit: number,
    params?: FindAll1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindAll1QueryOptions(page, limit, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

/**
 * @deprecated
 */
export const useGetRolesForOrganizationsHook = () => {
    const getRolesForOrganizations = useIAmSwaggerClient<RelatedRole[]>()

    return (orgId: string, params?: GetRolesForOrganizationsParams, signal?: AbortSignal) => {
        return getRolesForOrganizations({ url: `/roles/organizations/${orgId}`, method: 'get', params, signal })
    }
}

export const getGetRolesForOrganizationsQueryKey = (orgId: string, params?: GetRolesForOrganizationsParams) =>
    [`/roles/organizations/${orgId}`, ...(params ? [params] : [])] as const

export const useGetRolesForOrganizationsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetRolesForOrganizationsHook>>>,
    TError = ApiError,
>(
    orgId: string,
    params?: GetRolesForOrganizationsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRolesForOrganizationsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRolesForOrganizationsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetRolesForOrganizationsQueryKey(orgId, params)

    const getRolesForOrganizations = useGetRolesForOrganizationsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetRolesForOrganizationsHook>>>> = ({ signal }) =>
        getRolesForOrganizations(orgId, params, signal)

    return { queryKey, queryFn, enabled: !!orgId, ...queryOptions }
}

export type GetRolesForOrganizationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetRolesForOrganizationsHook>>>>
export type GetRolesForOrganizationsQueryError = ApiError

/**
 * @deprecated
 */
export const useGetRolesForOrganizations = <TData = Awaited<ReturnType<ReturnType<typeof useGetRolesForOrganizationsHook>>>, TError = ApiError>(
    orgId: string,
    params?: GetRolesForOrganizationsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRolesForOrganizationsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetRolesForOrganizationsQueryOptions(orgId, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindAll21Hook = () => {
    const findAll21 = useIAmSwaggerClient<FindAll21200>()

    return (params: FindAll21Params, signal?: AbortSignal) => {
        return findAll21({ url: `/organizations`, method: 'get', params, signal })
    }
}

export const getFindAll21QueryKey = (params: FindAll21Params) => [`/organizations`, ...(params ? [params] : [])] as const

export const useFindAll21QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindAll21Hook>>>, TError = ApiError>(
    params: FindAll21Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll21Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll21Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindAll21QueryKey(params)

    const findAll21 = useFindAll21Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindAll21Hook>>>> = ({ signal }) => findAll21(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindAll21QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindAll21Hook>>>>
export type FindAll21QueryError = ApiError

export const useFindAll21 = <TData = Awaited<ReturnType<ReturnType<typeof useFindAll21Hook>>>, TError = ApiError>(
    params: FindAll21Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll21Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindAll21QueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindByUuid1Hook = () => {
    const findByUuid1 = useIAmSwaggerClient<Organization>()

    return (uuid: string, signal?: AbortSignal) => {
        return findByUuid1({ url: `/organizations/${uuid}`, method: 'get', signal })
    }
}

export const getFindByUuid1QueryKey = (uuid: string) => [`/organizations/${uuid}`] as const

export const useFindByUuid1QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindByUuid1Hook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByUuid1Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByUuid1Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindByUuid1QueryKey(uuid)

    const findByUuid1 = useFindByUuid1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindByUuid1Hook>>>> = ({ signal }) => findByUuid1(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindByUuid1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindByUuid1Hook>>>>
export type FindByUuid1QueryError = ApiError

export const useFindByUuid1 = <TData = Awaited<ReturnType<ReturnType<typeof useFindByUuid1Hook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByUuid1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindByUuid1QueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindRelatedRolesHook = () => {
    const findRelatedRoles = useIAmSwaggerClient<RelatedRole[]>()

    return (uuid: string, params?: FindRelatedRolesParams, signal?: AbortSignal) => {
        return findRelatedRoles({ url: `/organizations/${uuid}/roles`, method: 'get', params, signal })
    }
}

export const getFindRelatedRolesQueryKey = (uuid: string, params?: FindRelatedRolesParams) =>
    [`/organizations/${uuid}/roles`, ...(params ? [params] : [])] as const

export const useFindRelatedRolesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedRolesHook>>>, TError = ApiError>(
    uuid: string,
    params?: FindRelatedRolesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedRolesHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedRolesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindRelatedRolesQueryKey(uuid, params)

    const findRelatedRoles = useFindRelatedRolesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindRelatedRolesHook>>>> = ({ signal }) =>
        findRelatedRoles(uuid, params, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindRelatedRolesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindRelatedRolesHook>>>>
export type FindRelatedRolesQueryError = ApiError

export const useFindRelatedRoles = <TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedRolesHook>>>, TError = ApiError>(
    uuid: string,
    params?: FindRelatedRolesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedRolesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindRelatedRolesQueryOptions(uuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindSubOrganizationsHook = () => {
    const findSubOrganizations = useIAmSwaggerClient<Organization[]>()

    return (uuid: string, signal?: AbortSignal) => {
        return findSubOrganizations({ url: `/organizations/${uuid}/organizations`, method: 'get', signal })
    }
}

export const getFindSubOrganizationsQueryKey = (uuid: string) => [`/organizations/${uuid}/organizations`] as const

export const useFindSubOrganizationsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindSubOrganizationsHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindSubOrganizationsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindSubOrganizationsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindSubOrganizationsQueryKey(uuid)

    const findSubOrganizations = useFindSubOrganizationsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindSubOrganizationsHook>>>> = ({ signal }) =>
        findSubOrganizations(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindSubOrganizationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindSubOrganizationsHook>>>>
export type FindSubOrganizationsQueryError = ApiError

export const useFindSubOrganizations = <TData = Awaited<ReturnType<ReturnType<typeof useFindSubOrganizationsHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindSubOrganizationsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindSubOrganizationsQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindRelatedIdentities1Hook = () => {
    const findRelatedIdentities1 = useIAmSwaggerClient<Identity[]>()

    return (uuid: string, signal?: AbortSignal) => {
        return findRelatedIdentities1({ url: `/organizations/${uuid}/identities`, method: 'get', signal })
    }
}

export const getFindRelatedIdentities1QueryKey = (uuid: string) => [`/organizations/${uuid}/identities`] as const

export const useFindRelatedIdentities1QueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentities1Hook>>>,
    TError = ApiError,
>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentities1Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentities1Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindRelatedIdentities1QueryKey(uuid)

    const findRelatedIdentities1 = useFindRelatedIdentities1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentities1Hook>>>> = ({ signal }) =>
        findRelatedIdentities1(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindRelatedIdentities1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentities1Hook>>>>
export type FindRelatedIdentities1QueryError = ApiError

export const useFindRelatedIdentities1 = <TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentities1Hook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentities1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindRelatedIdentities1QueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetAssignedHKOHook = () => {
    const getAssignedHKO = useIAmSwaggerClient<Identity[]>()

    return (uuid: string, signal?: AbortSignal) => {
        return getAssignedHKO({ url: `/organizations/${uuid}/hko`, method: 'get', signal })
    }
}

export const getGetAssignedHKOQueryKey = (uuid: string) => [`/organizations/${uuid}/hko`] as const

export const useGetAssignedHKOQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetAssignedHKOHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAssignedHKOHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAssignedHKOHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetAssignedHKOQueryKey(uuid)

    const getAssignedHKO = useGetAssignedHKOHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAssignedHKOHook>>>> = ({ signal }) => getAssignedHKO(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type GetAssignedHKOQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAssignedHKOHook>>>>
export type GetAssignedHKOQueryError = ApiError

export const useGetAssignedHKO = <TData = Awaited<ReturnType<ReturnType<typeof useGetAssignedHKOHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAssignedHKOHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetAssignedHKOQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetHierarchy1Hook = () => {
    const getHierarchy1 = useIAmSwaggerClient<OrganizationHierarchy>()

    return (uuid: string, signal?: AbortSignal) => {
        return getHierarchy1({ url: `/organizations/${uuid}/hierarchy`, method: 'get', signal })
    }
}

export const getGetHierarchy1QueryKey = (uuid: string) => [`/organizations/${uuid}/hierarchy`] as const

export const useGetHierarchy1QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetHierarchy1Hook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetHierarchy1Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetHierarchy1Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetHierarchy1QueryKey(uuid)

    const getHierarchy1 = useGetHierarchy1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetHierarchy1Hook>>>> = ({ signal }) => getHierarchy1(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type GetHierarchy1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetHierarchy1Hook>>>>
export type GetHierarchy1QueryError = ApiError

export const useGetHierarchy1 = <TData = Awaited<ReturnType<ReturnType<typeof useGetHierarchy1Hook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetHierarchy1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetHierarchy1QueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetPages1Hook = () => {
    const getPages1 = useIAmSwaggerClient<number>()

    return (signal?: AbortSignal) => {
        return getPages1({ url: `/organizations/pages`, method: 'get', signal })
    }
}

export const getGetPages1QueryKey = () => [`/organizations/pages`] as const

export const useGetPages1QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetPages1Hook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPages1Hook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPages1Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetPages1QueryKey()

    const getPages1 = useGetPages1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPages1Hook>>>> = ({ signal }) => getPages1(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetPages1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetPages1Hook>>>>
export type GetPages1QueryError = ApiError

export const useGetPages1 = <TData = Awaited<ReturnType<ReturnType<typeof useGetPages1Hook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPages1Hook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetPages1QueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindAll2Hook = () => {
    const findAll2 = useIAmSwaggerClient<Organization[]>()

    return (page: number, limit: number, params?: FindAll2Params, signal?: AbortSignal) => {
        return findAll2({ url: `/organizations/page/${page}/${limit}`, method: 'get', params, signal })
    }
}

export const getFindAll2QueryKey = (page: number, limit: number, params?: FindAll2Params) =>
    [`/organizations/page/${page}/${limit}`, ...(params ? [params] : [])] as const

export const useFindAll2QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindAll2Hook>>>, TError = ApiError>(
    page: number,
    limit: number,
    params?: FindAll2Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll2Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll2Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindAll2QueryKey(page, limit, params)

    const findAll2 = useFindAll2Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindAll2Hook>>>> = ({ signal }) => findAll2(page, limit, params, signal)

    return { queryKey, queryFn, enabled: !!(page && limit), ...queryOptions }
}

export type FindAll2QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindAll2Hook>>>>
export type FindAll2QueryError = ApiError

export const useFindAll2 = <TData = Awaited<ReturnType<ReturnType<typeof useFindAll2Hook>>>, TError = ApiError>(
    page: number,
    limit: number,
    params?: FindAll2Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll2Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindAll2QueryOptions(page, limit, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useIsIdentityAssignedToPOHook = () => {
    const isIdentityAssignedToPO = useIAmSwaggerClient<boolean>()

    return (cmdbId: string, signal?: AbortSignal) => {
        return isIdentityAssignedToPO({ url: `/organizations/isIdentityAssignedToPO/${cmdbId}`, method: 'get', signal })
    }
}

export const getIsIdentityAssignedToPOQueryKey = (cmdbId: string) => [`/organizations/isIdentityAssignedToPO/${cmdbId}`] as const

export const useIsIdentityAssignedToPOQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useIsIdentityAssignedToPOHook>>>,
    TError = ApiError,
>(
    cmdbId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIsIdentityAssignedToPOHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIsIdentityAssignedToPOHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getIsIdentityAssignedToPOQueryKey(cmdbId)

    const isIdentityAssignedToPO = useIsIdentityAssignedToPOHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useIsIdentityAssignedToPOHook>>>> = ({ signal }) =>
        isIdentityAssignedToPO(cmdbId, signal)

    return { queryKey, queryFn, enabled: !!cmdbId, ...queryOptions }
}

export type IsIdentityAssignedToPOQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useIsIdentityAssignedToPOHook>>>>
export type IsIdentityAssignedToPOQueryError = ApiError

export const useIsIdentityAssignedToPO = <TData = Awaited<ReturnType<ReturnType<typeof useIsIdentityAssignedToPOHook>>>, TError = ApiError>(
    cmdbId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIsIdentityAssignedToPOHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useIsIdentityAssignedToPOQueryOptions(cmdbId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useIsCycleInHierarchyHook = () => {
    const isCycleInHierarchy = useIAmSwaggerClient<boolean>()

    return (cmdbId: string, subCmdbId: string, signal?: AbortSignal) => {
        return isCycleInHierarchy({ url: `/organizations/isCycleInHierarchy/${cmdbId}/sub/${subCmdbId}`, method: 'get', signal })
    }
}

export const getIsCycleInHierarchyQueryKey = (cmdbId: string, subCmdbId: string) =>
    [`/organizations/isCycleInHierarchy/${cmdbId}/sub/${subCmdbId}`] as const

export const useIsCycleInHierarchyQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useIsCycleInHierarchyHook>>>, TError = ApiError>(
    cmdbId: string,
    subCmdbId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIsCycleInHierarchyHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIsCycleInHierarchyHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getIsCycleInHierarchyQueryKey(cmdbId, subCmdbId)

    const isCycleInHierarchy = useIsCycleInHierarchyHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useIsCycleInHierarchyHook>>>> = ({ signal }) =>
        isCycleInHierarchy(cmdbId, subCmdbId, signal)

    return { queryKey, queryFn, enabled: !!(cmdbId && subCmdbId), ...queryOptions }
}

export type IsCycleInHierarchyQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useIsCycleInHierarchyHook>>>>
export type IsCycleInHierarchyQueryError = ApiError

export const useIsCycleInHierarchy = <TData = Awaited<ReturnType<ReturnType<typeof useIsCycleInHierarchyHook>>>, TError = ApiError>(
    cmdbId: string,
    subCmdbId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIsCycleInHierarchyHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useIsCycleInHierarchyQueryOptions(cmdbId, subCmdbId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useIsAssignedInHierarchyHook = () => {
    const isAssignedInHierarchy = useIAmSwaggerClient<boolean>()

    return (cmdbId: string, params?: IsAssignedInHierarchyParams, signal?: AbortSignal) => {
        return isAssignedInHierarchy({ url: `/organizations/isAssignedInHierarchy/${cmdbId}`, method: 'get', params, signal })
    }
}

export const getIsAssignedInHierarchyQueryKey = (cmdbId: string, params?: IsAssignedInHierarchyParams) =>
    [`/organizations/isAssignedInHierarchy/${cmdbId}`, ...(params ? [params] : [])] as const

export const useIsAssignedInHierarchyQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useIsAssignedInHierarchyHook>>>, TError = ApiError>(
    cmdbId: string,
    params?: IsAssignedInHierarchyParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIsAssignedInHierarchyHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIsAssignedInHierarchyHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getIsAssignedInHierarchyQueryKey(cmdbId, params)

    const isAssignedInHierarchy = useIsAssignedInHierarchyHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useIsAssignedInHierarchyHook>>>> = ({ signal }) =>
        isAssignedInHierarchy(cmdbId, params, signal)

    return { queryKey, queryFn, enabled: !!cmdbId, ...queryOptions }
}

export type IsAssignedInHierarchyQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useIsAssignedInHierarchyHook>>>>
export type IsAssignedInHierarchyQueryError = ApiError

export const useIsAssignedInHierarchy = <TData = Awaited<ReturnType<ReturnType<typeof useIsAssignedInHierarchyHook>>>, TError = ApiError>(
    cmdbId: string,
    params?: IsAssignedInHierarchyParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIsAssignedInHierarchyHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useIsAssignedInHierarchyQueryOptions(cmdbId, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetOwnerRolesFromLoginHook = () => {
    const getOwnerRolesFromLogin = useIAmSwaggerClient<OwnerAndRoles>()

    return (params: GetOwnerRolesFromLoginParams, signal?: AbortSignal) => {
        return getOwnerRolesFromLogin({ url: `/organizations/getOwnerRolesFromLogin`, method: 'get', params, signal })
    }
}

export const getGetOwnerRolesFromLoginQueryKey = (params: GetOwnerRolesFromLoginParams) =>
    [`/organizations/getOwnerRolesFromLogin`, ...(params ? [params] : [])] as const

export const useGetOwnerRolesFromLoginQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetOwnerRolesFromLoginHook>>>,
    TError = ApiError,
>(
    params: GetOwnerRolesFromLoginParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOwnerRolesFromLoginHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOwnerRolesFromLoginHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetOwnerRolesFromLoginQueryKey(params)

    const getOwnerRolesFromLogin = useGetOwnerRolesFromLoginHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOwnerRolesFromLoginHook>>>> = ({ signal }) =>
        getOwnerRolesFromLogin(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetOwnerRolesFromLoginQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOwnerRolesFromLoginHook>>>>
export type GetOwnerRolesFromLoginQueryError = ApiError

export const useGetOwnerRolesFromLogin = <TData = Awaited<ReturnType<ReturnType<typeof useGetOwnerRolesFromLoginHook>>>, TError = ApiError>(
    params: GetOwnerRolesFromLoginParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOwnerRolesFromLoginHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetOwnerRolesFromLoginQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetOrganizationsForHkoHook = () => {
    const getOrganizationsForHko = useIAmSwaggerClient<string[]>()

    return (uuid: string, signal?: AbortSignal) => {
        return getOrganizationsForHko({ url: `/organizations/getOrganizationsForHko/${uuid}`, method: 'get', signal })
    }
}

export const getGetOrganizationsForHkoQueryKey = (uuid: string) => [`/organizations/getOrganizationsForHko/${uuid}`] as const

export const useGetOrganizationsForHkoQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetOrganizationsForHkoHook>>>,
    TError = ApiError,
>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationsForHkoHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationsForHkoHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetOrganizationsForHkoQueryKey(uuid)

    const getOrganizationsForHko = useGetOrganizationsForHkoHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOrganizationsForHkoHook>>>> = ({ signal }) =>
        getOrganizationsForHko(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type GetOrganizationsForHkoQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOrganizationsForHkoHook>>>>
export type GetOrganizationsForHkoQueryError = ApiError

export const useGetOrganizationsForHko = <TData = Awaited<ReturnType<ReturnType<typeof useGetOrganizationsForHkoHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationsForHkoHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetOrganizationsForHkoQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetOrganizationFromCsruHook = () => {
    const getOrganizationFromCsru = useIAmSwaggerClient<CsruOrganization>()

    return (ico: string) => {
        return getOrganizationFromCsru({ url: `/organizations/csru/${ico}`, method: 'get' })
    }
}

export const useGetOrganizationFromCsruMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationFromCsruHook>>>, TError, { ico: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationFromCsruHook>>>, TError, { ico: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const getOrganizationFromCsru = useGetOrganizationFromCsruHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useGetOrganizationFromCsruHook>>>, { ico: string }> = (props) => {
        const { ico } = props ?? {}

        return getOrganizationFromCsru(ico)
    }

    return { mutationFn, ...mutationOptions }
}

export type GetOrganizationFromCsruMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOrganizationFromCsruHook>>>>

export type GetOrganizationFromCsruMutationError = ApiError

export const useGetOrganizationFromCsru = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useGetOrganizationFromCsruHook>>>, TError, { ico: string }, TContext>
}) => {
    const mutationOptions = useGetOrganizationFromCsruMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useReadChangesHook = () => {
    const readChanges = useIAmSwaggerClient<ConfigurationItemSetUi>()

    return (params: ReadChangesParams, signal?: AbortSignal) => {
        return readChanges({ url: `/organizations/csru/changes`, method: 'get', params, signal })
    }
}

export const getReadChangesQueryKey = (params: ReadChangesParams) => [`/organizations/csru/changes`, ...(params ? [params] : [])] as const

export const useReadChangesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useReadChangesHook>>>, TError = ApiError>(
    params: ReadChangesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadChangesHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadChangesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getReadChangesQueryKey(params)

    const readChanges = useReadChangesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useReadChangesHook>>>> = ({ signal }) => readChanges(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type ReadChangesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useReadChangesHook>>>>
export type ReadChangesQueryError = ApiError

export const useReadChanges = <TData = Awaited<ReturnType<ReturnType<typeof useReadChangesHook>>>, TError = ApiError>(
    params: ReadChangesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useReadChangesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useReadChangesQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindByUuid2Hook = () => {
    const findByUuid2 = useIAmSwaggerClient<Identity>()

    return (uuid: string, signal?: AbortSignal) => {
        return findByUuid2({ url: `/identities/${uuid}`, method: 'get', signal })
    }
}

export const getFindByUuid2QueryKey = (uuid: string) => [`/identities/${uuid}`] as const

export const useFindByUuid2QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindByUuid2Hook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByUuid2Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByUuid2Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindByUuid2QueryKey(uuid)

    const findByUuid2 = useFindByUuid2Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindByUuid2Hook>>>> = ({ signal }) => findByUuid2(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindByUuid2QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindByUuid2Hook>>>>
export type FindByUuid2QueryError = ApiError

export const useFindByUuid2 = <TData = Awaited<ReturnType<ReturnType<typeof useFindByUuid2Hook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByUuid2Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindByUuid2QueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useDelete1Hook = () => {
    const delete1 = useIAmSwaggerClient<OperationResult>()

    return (uuid: string) => {
        return delete1({ url: `/identities/${uuid}`, method: 'delete' })
    }
}

export const useDelete1MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDelete1Hook>>>, TError, { uuid: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDelete1Hook>>>, TError, { uuid: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const delete1 = useDelete1Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDelete1Hook>>>, { uuid: string }> = (props) => {
        const { uuid } = props ?? {}

        return delete1(uuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type Delete1MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDelete1Hook>>>>

export type Delete1MutationError = ApiError

export const useDelete1 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDelete1Hook>>>, TError, { uuid: string }, TContext>
}) => {
    const mutationOptions = useDelete1MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useFindSubstitutedByHook = () => {
    const findSubstitutedBy = useIAmSwaggerClient<Identity[]>()

    return (uuid: string, groupUuid: string, signal?: AbortSignal) => {
        return findSubstitutedBy({ url: `/identities/${uuid}/substitutedBy/groups/${groupUuid}`, method: 'get', signal })
    }
}

export const getFindSubstitutedByQueryKey = (uuid: string, groupUuid: string) => [`/identities/${uuid}/substitutedBy/groups/${groupUuid}`] as const

export const useFindSubstitutedByQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindSubstitutedByHook>>>, TError = ApiError>(
    uuid: string,
    groupUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindSubstitutedByHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindSubstitutedByHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindSubstitutedByQueryKey(uuid, groupUuid)

    const findSubstitutedBy = useFindSubstitutedByHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindSubstitutedByHook>>>> = ({ signal }) =>
        findSubstitutedBy(uuid, groupUuid, signal)

    return { queryKey, queryFn, enabled: !!(uuid && groupUuid), ...queryOptions }
}

export type FindSubstitutedByQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindSubstitutedByHook>>>>
export type FindSubstitutedByQueryError = ApiError

export const useFindSubstitutedBy = <TData = Awaited<ReturnType<ReturnType<typeof useFindSubstitutedByHook>>>, TError = ApiError>(
    uuid: string,
    groupUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindSubstitutedByHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindSubstitutedByQueryOptions(uuid, groupUuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindSubstitutedIdentitiesHook = () => {
    const findSubstitutedIdentities = useIAmSwaggerClient<Identity[]>()

    return (uuid: string, groupUuid: string, signal?: AbortSignal) => {
        return findSubstitutedIdentities({ url: `/identities/${uuid}/substituted/groups/${groupUuid}`, method: 'get', signal })
    }
}

export const getFindSubstitutedIdentitiesQueryKey = (uuid: string, groupUuid: string) =>
    [`/identities/${uuid}/substituted/groups/${groupUuid}`] as const

export const useFindSubstitutedIdentitiesQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindSubstitutedIdentitiesHook>>>,
    TError = ApiError,
>(
    uuid: string,
    groupUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindSubstitutedIdentitiesHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindSubstitutedIdentitiesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindSubstitutedIdentitiesQueryKey(uuid, groupUuid)

    const findSubstitutedIdentities = useFindSubstitutedIdentitiesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindSubstitutedIdentitiesHook>>>> = ({ signal }) =>
        findSubstitutedIdentities(uuid, groupUuid, signal)

    return { queryKey, queryFn, enabled: !!(uuid && groupUuid), ...queryOptions }
}

export type FindSubstitutedIdentitiesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindSubstitutedIdentitiesHook>>>>
export type FindSubstitutedIdentitiesQueryError = ApiError

export const useFindSubstitutedIdentities = <TData = Awaited<ReturnType<ReturnType<typeof useFindSubstitutedIdentitiesHook>>>, TError = ApiError>(
    uuid: string,
    groupUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindSubstitutedIdentitiesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindSubstitutedIdentitiesQueryOptions(uuid, groupUuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindRelatedRoles1Hook = () => {
    const findRelatedRoles1 = useIAmSwaggerClient<RelatedRole[]>()

    return (uuid: string, signal?: AbortSignal) => {
        return findRelatedRoles1({ url: `/identities/${uuid}/roles`, method: 'get', signal })
    }
}

export const getFindRelatedRoles1QueryKey = (uuid: string) => [`/identities/${uuid}/roles`] as const

export const useFindRelatedRoles1QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedRoles1Hook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedRoles1Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedRoles1Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindRelatedRoles1QueryKey(uuid)

    const findRelatedRoles1 = useFindRelatedRoles1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindRelatedRoles1Hook>>>> = ({ signal }) => findRelatedRoles1(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindRelatedRoles1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindRelatedRoles1Hook>>>>
export type FindRelatedRoles1QueryError = ApiError

export const useFindRelatedRoles1 = <TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedRoles1Hook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedRoles1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindRelatedRoles1QueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindRoleOrgRelationsHook = () => {
    const findRoleOrgRelations = useIAmSwaggerClient<RoleOrgIdentity[]>()

    return (uuid: string, params?: FindRoleOrgRelationsParams, signal?: AbortSignal) => {
        return findRoleOrgRelations({ url: `/identities/${uuid}/roles/organizations`, method: 'get', params, signal })
    }
}

export const getFindRoleOrgRelationsQueryKey = (uuid: string, params?: FindRoleOrgRelationsParams) =>
    [`/identities/${uuid}/roles/organizations`, ...(params ? [params] : [])] as const

export const useFindRoleOrgRelationsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindRoleOrgRelationsHook>>>, TError = ApiError>(
    uuid: string,
    params?: FindRoleOrgRelationsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRoleOrgRelationsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRoleOrgRelationsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindRoleOrgRelationsQueryKey(uuid, params)

    const findRoleOrgRelations = useFindRoleOrgRelationsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindRoleOrgRelationsHook>>>> = ({ signal }) =>
        findRoleOrgRelations(uuid, params, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindRoleOrgRelationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindRoleOrgRelationsHook>>>>
export type FindRoleOrgRelationsQueryError = ApiError

export const useFindRoleOrgRelations = <TData = Awaited<ReturnType<ReturnType<typeof useFindRoleOrgRelationsHook>>>, TError = ApiError>(
    uuid: string,
    params?: FindRoleOrgRelationsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRoleOrgRelationsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindRoleOrgRelationsQueryOptions(uuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindOrganizationsHook = () => {
    const findOrganizations = useIAmSwaggerClient<string[]>()

    return (uuid: string, signal?: AbortSignal) => {
        return findOrganizations({ url: `/identities/${uuid}/organizations`, method: 'get', signal })
    }
}

export const getFindOrganizationsQueryKey = (uuid: string) => [`/identities/${uuid}/organizations`] as const

export const useFindOrganizationsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindOrganizationsHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindOrganizationsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindOrganizationsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindOrganizationsQueryKey(uuid)

    const findOrganizations = useFindOrganizationsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindOrganizationsHook>>>> = ({ signal }) => findOrganizations(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindOrganizationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindOrganizationsHook>>>>
export type FindOrganizationsQueryError = ApiError

export const useFindOrganizations = <TData = Awaited<ReturnType<ReturnType<typeof useFindOrganizationsHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindOrganizationsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindOrganizationsQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindRelatedOrganizationsHook = () => {
    const findRelatedOrganizations = useIAmSwaggerClient<Organization[]>()

    return (uuid: string, signal?: AbortSignal) => {
        return findRelatedOrganizations({ url: `/identities/${uuid}/organizationObjects`, method: 'get', signal })
    }
}

export const getFindRelatedOrganizationsQueryKey = (uuid: string) => [`/identities/${uuid}/organizationObjects`] as const

export const useFindRelatedOrganizationsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedOrganizationsHook>>>,
    TError = ApiError,
>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedOrganizationsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedOrganizationsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindRelatedOrganizationsQueryKey(uuid)

    const findRelatedOrganizations = useFindRelatedOrganizationsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindRelatedOrganizationsHook>>>> = ({ signal }) =>
        findRelatedOrganizations(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindRelatedOrganizationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindRelatedOrganizationsHook>>>>
export type FindRelatedOrganizationsQueryError = ApiError

export const useFindRelatedOrganizations = <TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedOrganizationsHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedOrganizationsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindRelatedOrganizationsQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindMembershipDataHook = () => {
    const findMembershipData = useIAmSwaggerClient<MembershipDataOut>()

    return (uuid: string, signal?: AbortSignal) => {
        return findMembershipData({ url: `/identities/${uuid}/membership_data`, method: 'get', signal })
    }
}

export const getFindMembershipDataQueryKey = (uuid: string) => [`/identities/${uuid}/membership_data`] as const

export const useFindMembershipDataQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindMembershipDataHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindMembershipDataHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindMembershipDataHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindMembershipDataQueryKey(uuid)

    const findMembershipData = useFindMembershipDataHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindMembershipDataHook>>>> = ({ signal }) => findMembershipData(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindMembershipDataQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindMembershipDataHook>>>>
export type FindMembershipDataQueryError = ApiError

export const useFindMembershipData = <TData = Awaited<ReturnType<ReturnType<typeof useFindMembershipDataHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindMembershipDataHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindMembershipDataQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindRelatedGroupsHook = () => {
    const findRelatedGroups = useIAmSwaggerClient<Group[]>()

    return (uuid: string, signal?: AbortSignal) => {
        return findRelatedGroups({ url: `/identities/${uuid}/groups`, method: 'get', signal })
    }
}

export const getFindRelatedGroupsQueryKey = (uuid: string) => [`/identities/${uuid}/groups`] as const

export const useFindRelatedGroupsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedGroupsHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedGroupsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedGroupsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindRelatedGroupsQueryKey(uuid)

    const findRelatedGroups = useFindRelatedGroupsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindRelatedGroupsHook>>>> = ({ signal }) => findRelatedGroups(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindRelatedGroupsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindRelatedGroupsHook>>>>
export type FindRelatedGroupsQueryError = ApiError

export const useFindRelatedGroups = <TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedGroupsHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedGroupsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindRelatedGroupsQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindGidDataHook = () => {
    const findGidData = useIAmSwaggerClient<GidData[]>()

    return (uuid: string, signal?: AbortSignal) => {
        return findGidData({ url: `/identities/${uuid}/gid_data`, method: 'get', signal })
    }
}

export const getFindGidDataQueryKey = (uuid: string) => [`/identities/${uuid}/gid_data`] as const

export const useFindGidDataQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindGidDataHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindGidDataHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindGidDataHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindGidDataQueryKey(uuid)

    const findGidData = useFindGidDataHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindGidDataHook>>>> = ({ signal }) => findGidData(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindGidDataQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindGidDataHook>>>>
export type FindGidDataQueryError = ApiError

export const useFindGidData = <TData = Awaited<ReturnType<ReturnType<typeof useFindGidDataHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindGidDataHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindGidDataQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetPages2Hook = () => {
    const getPages2 = useIAmSwaggerClient<number>()

    return (signal?: AbortSignal) => {
        return getPages2({ url: `/identities/pages`, method: 'get', signal })
    }
}

export const getGetPages2QueryKey = () => [`/identities/pages`] as const

export const useGetPages2QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetPages2Hook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPages2Hook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPages2Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetPages2QueryKey()

    const getPages2 = useGetPages2Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPages2Hook>>>> = ({ signal }) => getPages2(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetPages2QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetPages2Hook>>>>
export type GetPages2QueryError = ApiError

export const useGetPages2 = <TData = Awaited<ReturnType<ReturnType<typeof useGetPages2Hook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPages2Hook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetPages2QueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindAll3Hook = () => {
    const findAll3 = useIAmSwaggerClient<Identity[]>()

    return (page: number, limit: number, params?: FindAll3Params, signal?: AbortSignal) => {
        return findAll3({ url: `/identities/page/${page}/${limit}`, method: 'get', params, signal })
    }
}

export const getFindAll3QueryKey = (page: number, limit: number, params?: FindAll3Params) =>
    [`/identities/page/${page}/${limit}`, ...(params ? [params] : [])] as const

export const useFindAll3QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindAll3Hook>>>, TError = ApiError>(
    page: number,
    limit: number,
    params?: FindAll3Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll3Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll3Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindAll3QueryKey(page, limit, params)

    const findAll3 = useFindAll3Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindAll3Hook>>>> = ({ signal }) => findAll3(page, limit, params, signal)

    return { queryKey, queryFn, enabled: !!(page && limit), ...queryOptions }
}

export type FindAll3QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindAll3Hook>>>>
export type FindAll3QueryError = ApiError

export const useFindAll3 = <TData = Awaited<ReturnType<ReturnType<typeof useFindAll3Hook>>>, TError = ApiError>(
    page: number,
    limit: number,
    params?: FindAll3Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll3Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindAll3QueryOptions(page, limit, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useChangeIdentitySubstituteHook = () => {
    const changeIdentitySubstitute = useIAmSwaggerClient<OperationResult>()

    return (oldUuid: string, newUuid: string, substitutesUuid: string, groupUuid: string, signal?: AbortSignal) => {
        return changeIdentitySubstitute({
            url: `/identities/old/${oldUuid}/new/${newUuid}/substitutes/${substitutesUuid}/groups/${groupUuid}`,
            method: 'get',
            signal,
        })
    }
}

export const getChangeIdentitySubstituteQueryKey = (oldUuid: string, newUuid: string, substitutesUuid: string, groupUuid: string) =>
    [`/identities/old/${oldUuid}/new/${newUuid}/substitutes/${substitutesUuid}/groups/${groupUuid}`] as const

export const useChangeIdentitySubstituteQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useChangeIdentitySubstituteHook>>>,
    TError = ApiError,
>(
    oldUuid: string,
    newUuid: string,
    substitutesUuid: string,
    groupUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useChangeIdentitySubstituteHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useChangeIdentitySubstituteHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getChangeIdentitySubstituteQueryKey(oldUuid, newUuid, substitutesUuid, groupUuid)

    const changeIdentitySubstitute = useChangeIdentitySubstituteHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useChangeIdentitySubstituteHook>>>> = ({ signal }) =>
        changeIdentitySubstitute(oldUuid, newUuid, substitutesUuid, groupUuid, signal)

    return { queryKey, queryFn, enabled: !!(oldUuid && newUuid && substitutesUuid && groupUuid), ...queryOptions }
}

export type ChangeIdentitySubstituteQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useChangeIdentitySubstituteHook>>>>
export type ChangeIdentitySubstituteQueryError = ApiError

export const useChangeIdentitySubstitute = <TData = Awaited<ReturnType<ReturnType<typeof useChangeIdentitySubstituteHook>>>, TError = ApiError>(
    oldUuid: string,
    newUuid: string,
    substitutesUuid: string,
    groupUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useChangeIdentitySubstituteHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useChangeIdentitySubstituteQueryOptions(oldUuid, newUuid, substitutesUuid, groupUuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindSubstitutedByLoginHook = () => {
    const findSubstitutedByLogin = useIAmSwaggerClient<Identity[]>()

    return (login: string, groupUuid: string, signal?: AbortSignal) => {
        return findSubstitutedByLogin({ url: `/identities/login/${login}/substitutedBy/groups/${groupUuid}`, method: 'get', signal })
    }
}

export const getFindSubstitutedByLoginQueryKey = (login: string, groupUuid: string) =>
    [`/identities/login/${login}/substitutedBy/groups/${groupUuid}`] as const

export const useFindSubstitutedByLoginQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindSubstitutedByLoginHook>>>,
    TError = ApiError,
>(
    login: string,
    groupUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindSubstitutedByLoginHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindSubstitutedByLoginHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindSubstitutedByLoginQueryKey(login, groupUuid)

    const findSubstitutedByLogin = useFindSubstitutedByLoginHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindSubstitutedByLoginHook>>>> = ({ signal }) =>
        findSubstitutedByLogin(login, groupUuid, signal)

    return { queryKey, queryFn, enabled: !!(login && groupUuid), ...queryOptions }
}

export type FindSubstitutedByLoginQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindSubstitutedByLoginHook>>>>
export type FindSubstitutedByLoginQueryError = ApiError

export const useFindSubstitutedByLogin = <TData = Awaited<ReturnType<ReturnType<typeof useFindSubstitutedByLoginHook>>>, TError = ApiError>(
    login: string,
    groupUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindSubstitutedByLoginHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindSubstitutedByLoginQueryOptions(login, groupUuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindSubstitutedIdentitiesLoginHook = () => {
    const findSubstitutedIdentitiesLogin = useIAmSwaggerClient<Identity[]>()

    return (login: string, groupUuid: string, signal?: AbortSignal) => {
        return findSubstitutedIdentitiesLogin({ url: `/identities/login/${login}/substituted/groups/${groupUuid}`, method: 'get', signal })
    }
}

export const getFindSubstitutedIdentitiesLoginQueryKey = (login: string, groupUuid: string) =>
    [`/identities/login/${login}/substituted/groups/${groupUuid}`] as const

export const useFindSubstitutedIdentitiesLoginQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindSubstitutedIdentitiesLoginHook>>>,
    TError = ApiError,
>(
    login: string,
    groupUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindSubstitutedIdentitiesLoginHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindSubstitutedIdentitiesLoginHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindSubstitutedIdentitiesLoginQueryKey(login, groupUuid)

    const findSubstitutedIdentitiesLogin = useFindSubstitutedIdentitiesLoginHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindSubstitutedIdentitiesLoginHook>>>> = ({ signal }) =>
        findSubstitutedIdentitiesLogin(login, groupUuid, signal)

    return { queryKey, queryFn, enabled: !!(login && groupUuid), ...queryOptions }
}

export type FindSubstitutedIdentitiesLoginQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindSubstitutedIdentitiesLoginHook>>>>
export type FindSubstitutedIdentitiesLoginQueryError = ApiError

export const useFindSubstitutedIdentitiesLogin = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindSubstitutedIdentitiesLoginHook>>>,
    TError = ApiError,
>(
    login: string,
    groupUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindSubstitutedIdentitiesLoginHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindSubstitutedIdentitiesLoginQueryOptions(login, groupUuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindGidDataByLoginHook = () => {
    const findGidDataByLogin = useIAmSwaggerClient<GidData[]>()

    return (login: string, signal?: AbortSignal) => {
        return findGidDataByLogin({ url: `/identities/login/${login}/gid_data`, method: 'get', signal })
    }
}

export const getFindGidDataByLoginQueryKey = (login: string) => [`/identities/login/${login}/gid_data`] as const

export const useFindGidDataByLoginQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindGidDataByLoginHook>>>, TError = ApiError>(
    login: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindGidDataByLoginHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindGidDataByLoginHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindGidDataByLoginQueryKey(login)

    const findGidDataByLogin = useFindGidDataByLoginHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindGidDataByLoginHook>>>> = ({ signal }) =>
        findGidDataByLogin(login, signal)

    return { queryKey, queryFn, enabled: !!login, ...queryOptions }
}

export type FindGidDataByLoginQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindGidDataByLoginHook>>>>
export type FindGidDataByLoginQueryError = ApiError

export const useFindGidDataByLogin = <TData = Awaited<ReturnType<ReturnType<typeof useFindGidDataByLoginHook>>>, TError = ApiError>(
    login: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindGidDataByLoginHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindGidDataByLoginQueryOptions(login, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useInitAuthResourcesHook = () => {
    const initAuthResources = useIAmSwaggerClient<OperationResult[]>()

    return (signal?: AbortSignal) => {
        return initAuthResources({ url: `/identities/initAuthResource`, method: 'get', signal })
    }
}

export const getInitAuthResourcesQueryKey = () => [`/identities/initAuthResource`] as const

export const useInitAuthResourcesQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useInitAuthResourcesHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useInitAuthResourcesHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useInitAuthResourcesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getInitAuthResourcesQueryKey()

    const initAuthResources = useInitAuthResourcesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useInitAuthResourcesHook>>>> = ({ signal }) => initAuthResources(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type InitAuthResourcesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useInitAuthResourcesHook>>>>
export type InitAuthResourcesQueryError = ApiError

export const useInitAuthResources = <TData = Awaited<ReturnType<ReturnType<typeof useInitAuthResourcesHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useInitAuthResourcesHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useInitAuthResourcesQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetStdRolesListHook = () => {
    const getStdRolesList = useIAmSwaggerClient<string[]>()

    return (signal?: AbortSignal) => {
        return getStdRolesList({ url: `/identities/getStdRolesList`, method: 'get', signal })
    }
}

export const getGetStdRolesListQueryKey = () => [`/identities/getStdRolesList`] as const

export const useGetStdRolesListQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetStdRolesListHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetStdRolesListHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetStdRolesListHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetStdRolesListQueryKey()

    const getStdRolesList = useGetStdRolesListHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetStdRolesListHook>>>> = ({ signal }) => getStdRolesList(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetStdRolesListQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetStdRolesListHook>>>>
export type GetStdRolesListQueryError = ApiError

export const useGetStdRolesList = <TData = Awaited<ReturnType<ReturnType<typeof useGetStdRolesListHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetStdRolesListHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetStdRolesListQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetRolesByidentityAndOrgannizationHook = () => {
    const getRolesByidentityAndOrgannization = useIAmSwaggerClient<Role[]>()

    return (uuid: string, cmdbId: string, signal?: AbortSignal) => {
        return getRolesByidentityAndOrgannization({ url: `/identities/getRolesByidentityAndOrgannization/${uuid}/${cmdbId}`, method: 'get', signal })
    }
}

export const getGetRolesByidentityAndOrgannizationQueryKey = (uuid: string, cmdbId: string) =>
    [`/identities/getRolesByidentityAndOrgannization/${uuid}/${cmdbId}`] as const

export const useGetRolesByidentityAndOrgannizationQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetRolesByidentityAndOrgannizationHook>>>,
    TError = ApiError,
>(
    uuid: string,
    cmdbId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRolesByidentityAndOrgannizationHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRolesByidentityAndOrgannizationHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetRolesByidentityAndOrgannizationQueryKey(uuid, cmdbId)

    const getRolesByidentityAndOrgannization = useGetRolesByidentityAndOrgannizationHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetRolesByidentityAndOrgannizationHook>>>> = ({ signal }) =>
        getRolesByidentityAndOrgannization(uuid, cmdbId, signal)

    return { queryKey, queryFn, enabled: !!(uuid && cmdbId), ...queryOptions }
}

export type GetRolesByidentityAndOrgannizationQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetRolesByidentityAndOrgannizationHook>>>
>
export type GetRolesByidentityAndOrgannizationQueryError = ApiError

export const useGetRolesByidentityAndOrgannization = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetRolesByidentityAndOrgannizationHook>>>,
    TError = ApiError,
>(
    uuid: string,
    cmdbId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRolesByidentityAndOrgannizationHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetRolesByidentityAndOrgannizationQueryOptions(uuid, cmdbId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetLicenseRolesListHook = () => {
    const getLicenseRolesList = useIAmSwaggerClient<string[]>()

    return (signal?: AbortSignal) => {
        return getLicenseRolesList({ url: `/identities/getLicenseRolesList`, method: 'get', signal })
    }
}

export const getGetLicenseRolesListQueryKey = () => [`/identities/getLicenseRolesList`] as const

export const useGetLicenseRolesListQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetLicenseRolesListHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLicenseRolesListHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLicenseRolesListHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetLicenseRolesListQueryKey()

    const getLicenseRolesList = useGetLicenseRolesListHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLicenseRolesListHook>>>> = ({ signal }) => getLicenseRolesList(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetLicenseRolesListQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetLicenseRolesListHook>>>>
export type GetLicenseRolesListQueryError = ApiError

export const useGetLicenseRolesList = <TData = Awaited<ReturnType<ReturnType<typeof useGetLicenseRolesListHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLicenseRolesListHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetLicenseRolesListQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetIdentityTermsHook = () => {
    const getIdentityTerms = useIAmSwaggerClient<IdentityTermsWithEntity>()

    return (signal?: AbortSignal) => {
        return getIdentityTerms({ url: `/identities/getIdentityTerms`, method: 'get', signal })
    }
}

export const getGetIdentityTermsQueryKey = () => [`/identities/getIdentityTerms`] as const

export const useGetIdentityTermsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetIdentityTermsHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIdentityTermsHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIdentityTermsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetIdentityTermsQueryKey()

    const getIdentityTerms = useGetIdentityTermsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetIdentityTermsHook>>>> = ({ signal }) => getIdentityTerms(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetIdentityTermsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetIdentityTermsHook>>>>
export type GetIdentityTermsQueryError = ApiError

export const useGetIdentityTerms = <TData = Awaited<ReturnType<ReturnType<typeof useGetIdentityTermsHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIdentityTermsHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetIdentityTermsQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetHkoRolesBlacklistHook = () => {
    const getHkoRolesBlacklist = useIAmSwaggerClient<string[]>()

    return (signal?: AbortSignal) => {
        return getHkoRolesBlacklist({ url: `/identities/getHkoRolesBlacklist`, method: 'get', signal })
    }
}

export const getGetHkoRolesBlacklistQueryKey = () => [`/identities/getHkoRolesBlacklist`] as const

export const useGetHkoRolesBlacklistQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetHkoRolesBlacklistHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetHkoRolesBlacklistHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetHkoRolesBlacklistHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetHkoRolesBlacklistQueryKey()

    const getHkoRolesBlacklist = useGetHkoRolesBlacklistHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetHkoRolesBlacklistHook>>>> = ({ signal }) => getHkoRolesBlacklist(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetHkoRolesBlacklistQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetHkoRolesBlacklistHook>>>>
export type GetHkoRolesBlacklistQueryError = ApiError

export const useGetHkoRolesBlacklist = <TData = Awaited<ReturnType<ReturnType<typeof useGetHkoRolesBlacklistHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetHkoRolesBlacklistHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetHkoRolesBlacklistQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindNotRelatedWithHook = () => {
    const findNotRelatedWith = useIAmSwaggerClient<Identity[]>()

    return (page: number, limit: number, params: FindNotRelatedWithParams, signal?: AbortSignal) => {
        return findNotRelatedWith({ url: `/identities/findNotRelatedWith/page/${page}/${limit}`, method: 'get', params, signal })
    }
}

export const getFindNotRelatedWithQueryKey = (page: number, limit: number, params: FindNotRelatedWithParams) =>
    [`/identities/findNotRelatedWith/page/${page}/${limit}`, ...(params ? [params] : [])] as const

export const useFindNotRelatedWithQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindNotRelatedWithHook>>>, TError = ApiError>(
    page: number,
    limit: number,
    params: FindNotRelatedWithParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindNotRelatedWithHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindNotRelatedWithHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindNotRelatedWithQueryKey(page, limit, params)

    const findNotRelatedWith = useFindNotRelatedWithHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindNotRelatedWithHook>>>> = ({ signal }) =>
        findNotRelatedWith(page, limit, params, signal)

    return { queryKey, queryFn, enabled: !!(page && limit), ...queryOptions }
}

export type FindNotRelatedWithQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindNotRelatedWithHook>>>>
export type FindNotRelatedWithQueryError = ApiError

export const useFindNotRelatedWith = <TData = Awaited<ReturnType<ReturnType<typeof useFindNotRelatedWithHook>>>, TError = ApiError>(
    page: number,
    limit: number,
    params: FindNotRelatedWithParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindNotRelatedWithHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindNotRelatedWithQueryOptions(page, limit, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindPagesByRolesHook = () => {
    const findPagesByRoles = useIAmSwaggerClient<number>()

    return (params?: FindPagesByRolesParams, signal?: AbortSignal) => {
        return findPagesByRoles({ url: `/identities/findByRoles/pages`, method: 'get', params, signal })
    }
}

export const getFindPagesByRolesQueryKey = (params?: FindPagesByRolesParams) =>
    [`/identities/findByRoles/pages`, ...(params ? [params] : [])] as const

export const useFindPagesByRolesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindPagesByRolesHook>>>, TError = ApiError>(
    params?: FindPagesByRolesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindPagesByRolesHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindPagesByRolesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindPagesByRolesQueryKey(params)

    const findPagesByRoles = useFindPagesByRolesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindPagesByRolesHook>>>> = ({ signal }) => findPagesByRoles(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindPagesByRolesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindPagesByRolesHook>>>>
export type FindPagesByRolesQueryError = ApiError

export const useFindPagesByRoles = <TData = Awaited<ReturnType<ReturnType<typeof useFindPagesByRolesHook>>>, TError = ApiError>(
    params?: FindPagesByRolesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindPagesByRolesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindPagesByRolesQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindByRolesHook = () => {
    const findByRoles = useIAmSwaggerClient<Identity[]>()

    return (page: number, limit: number, params?: FindByRolesParams, signal?: AbortSignal) => {
        return findByRoles({ url: `/identities/findByRoles/page/${page}/${limit}`, method: 'get', params, signal })
    }
}

export const getFindByRolesQueryKey = (page: number, limit: number, params?: FindByRolesParams) =>
    [`/identities/findByRoles/page/${page}/${limit}`, ...(params ? [params] : [])] as const

export const useFindByRolesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindByRolesHook>>>, TError = ApiError>(
    page: number,
    limit: number,
    params?: FindByRolesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByRolesHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByRolesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindByRolesQueryKey(page, limit, params)

    const findByRoles = useFindByRolesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindByRolesHook>>>> = ({ signal }) =>
        findByRoles(page, limit, params, signal)

    return { queryKey, queryFn, enabled: !!(page && limit), ...queryOptions }
}

export type FindByRolesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindByRolesHook>>>>
export type FindByRolesQueryError = ApiError

export const useFindByRoles = <TData = Awaited<ReturnType<ReturnType<typeof useFindByRolesHook>>>, TError = ApiError>(
    page: number,
    limit: number,
    params?: FindByRolesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByRolesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindByRolesQueryOptions(page, limit, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindPagesHook = () => {
    const findPages = useIAmSwaggerClient<number>()

    return (params: FindPagesParams, signal?: AbortSignal) => {
        return findPages({ url: `/identities/find/pages`, method: 'get', params, signal })
    }
}

export const getFindPagesQueryKey = (params: FindPagesParams) => [`/identities/find/pages`, ...(params ? [params] : [])] as const

export const useFindPagesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindPagesHook>>>, TError = ApiError>(
    params: FindPagesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindPagesHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindPagesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindPagesQueryKey(params)

    const findPages = useFindPagesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindPagesHook>>>> = ({ signal }) => findPages(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindPagesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindPagesHook>>>>
export type FindPagesQueryError = ApiError

export const useFindPages = <TData = Awaited<ReturnType<ReturnType<typeof useFindPagesHook>>>, TError = ApiError>(
    params: FindPagesParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindPagesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindPagesQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFind1Hook = () => {
    const find1 = useIAmSwaggerClient<Identity[]>()

    return (page: number, limit: number, params: Find1Params, signal?: AbortSignal) => {
        return find1({ url: `/identities/find/page/${page}/${limit}`, method: 'get', params, signal })
    }
}

export const getFind1QueryKey = (page: number, limit: number, params: Find1Params) =>
    [`/identities/find/page/${page}/${limit}`, ...(params ? [params] : [])] as const

export const useFind1QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFind1Hook>>>, TError = ApiError>(
    page: number,
    limit: number,
    params: Find1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFind1Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFind1Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFind1QueryKey(page, limit, params)

    const find1 = useFind1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFind1Hook>>>> = ({ signal }) => find1(page, limit, params, signal)

    return { queryKey, queryFn, enabled: !!(page && limit), ...queryOptions }
}

export type Find1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFind1Hook>>>>
export type Find1QueryError = ApiError

export const useFind1 = <TData = Awaited<ReturnType<ReturnType<typeof useFind1Hook>>>, TError = ApiError>(
    page: number,
    limit: number,
    params: Find1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFind1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFind1QueryOptions(page, limit, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useCountByLoginLikeHook = () => {
    const countByLoginLike = useIAmSwaggerClient<number>()

    return (params: CountByLoginLikeParams, signal?: AbortSignal) => {
        return countByLoginLike({ url: `/identities/countByLoginLike`, method: 'get', params, signal })
    }
}

export const getCountByLoginLikeQueryKey = (params: CountByLoginLikeParams) => [`/identities/countByLoginLike`, ...(params ? [params] : [])] as const

export const useCountByLoginLikeQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useCountByLoginLikeHook>>>, TError = ApiError>(
    params: CountByLoginLikeParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCountByLoginLikeHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCountByLoginLikeHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getCountByLoginLikeQueryKey(params)

    const countByLoginLike = useCountByLoginLikeHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useCountByLoginLikeHook>>>> = ({ signal }) => countByLoginLike(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type CountByLoginLikeQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCountByLoginLikeHook>>>>
export type CountByLoginLikeQueryError = ApiError

export const useCountByLoginLike = <TData = Awaited<ReturnType<ReturnType<typeof useCountByLoginLikeHook>>>, TError = ApiError>(
    params: CountByLoginLikeParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCountByLoginLikeHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useCountByLoginLikeQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useAvailableLoginHook = () => {
    const availableLogin = useIAmSwaggerClient<string>()

    return (params: AvailableLoginParams, signal?: AbortSignal) => {
        return availableLogin({ url: `/identities/availableLogin`, method: 'get', params, signal })
    }
}

export const getAvailableLoginQueryKey = (params: AvailableLoginParams) => [`/identities/availableLogin`, ...(params ? [params] : [])] as const

export const useAvailableLoginQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useAvailableLoginHook>>>, TError = ApiError>(
    params: AvailableLoginParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAvailableLoginHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAvailableLoginHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getAvailableLoginQueryKey(params)

    const availableLogin = useAvailableLoginHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useAvailableLoginHook>>>> = ({ signal }) => availableLogin(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type AvailableLoginQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAvailableLoginHook>>>>
export type AvailableLoginQueryError = ApiError

export const useAvailableLogin = <TData = Awaited<ReturnType<ReturnType<typeof useAvailableLoginHook>>>, TError = ApiError>(
    params: AvailableLoginParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAvailableLoginHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useAvailableLoginQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useStripAccentsHook = () => {
    const stripAccents = useIAmSwaggerClient<string>()

    return (input: string, signal?: AbortSignal) => {
        return stripAccents({ url: `/identities/accents/${input}`, method: 'get', signal })
    }
}

export const getStripAccentsQueryKey = (input: string) => [`/identities/accents/${input}`] as const

export const useStripAccentsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useStripAccentsHook>>>, TError = ApiError>(
    input: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useStripAccentsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useStripAccentsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getStripAccentsQueryKey(input)

    const stripAccents = useStripAccentsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useStripAccentsHook>>>> = ({ signal }) => stripAccents(input, signal)

    return { queryKey, queryFn, enabled: !!input, ...queryOptions }
}

export type StripAccentsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useStripAccentsHook>>>>
export type StripAccentsQueryError = ApiError

export const useStripAccents = <TData = Awaited<ReturnType<ReturnType<typeof useStripAccentsHook>>>, TError = ApiError>(
    input: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useStripAccentsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useStripAccentsQueryOptions(input, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindByUuid3Hook = () => {
    const findByUuid3 = useIAmSwaggerClient<Group>()

    return (uuid: string, signal?: AbortSignal) => {
        return findByUuid3({ url: `/groups/${uuid}`, method: 'get', signal })
    }
}

export const getFindByUuid3QueryKey = (uuid: string) => [`/groups/${uuid}`] as const

export const useFindByUuid3QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindByUuid3Hook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByUuid3Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByUuid3Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindByUuid3QueryKey(uuid)

    const findByUuid3 = useFindByUuid3Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindByUuid3Hook>>>> = ({ signal }) => findByUuid3(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindByUuid3QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindByUuid3Hook>>>>
export type FindByUuid3QueryError = ApiError

export const useFindByUuid3 = <TData = Awaited<ReturnType<ReturnType<typeof useFindByUuid3Hook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindByUuid3Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindByUuid3QueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useDelete2Hook = () => {
    const delete2 = useIAmSwaggerClient<OperationResult>()

    return (uuid: string) => {
        return delete2({ url: `/groups/${uuid}`, method: 'delete' })
    }
}

export const useDelete2MutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDelete2Hook>>>, TError, { uuid: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDelete2Hook>>>, TError, { uuid: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const delete2 = useDelete2Hook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDelete2Hook>>>, { uuid: string }> = (props) => {
        const { uuid } = props ?? {}

        return delete2(uuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type Delete2MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDelete2Hook>>>>

export type Delete2MutationError = ApiError

export const useDelete2 = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDelete2Hook>>>, TError, { uuid: string }, TContext>
}) => {
    const mutationOptions = useDelete2MutationOptions(options)

    return useMutation(mutationOptions)
}

export const useFindIdentityInRoleHook = () => {
    const findIdentityInRole = useIAmSwaggerClient<Identity>()

    return (uuid: string, roleUuid: string, identityUuid: string, signal?: AbortSignal) => {
        return findIdentityInRole({ url: `/groups/${uuid}/roles/${roleUuid}/identities/${identityUuid}`, method: 'get', signal })
    }
}

export const getFindIdentityInRoleQueryKey = (uuid: string, roleUuid: string, identityUuid: string) =>
    [`/groups/${uuid}/roles/${roleUuid}/identities/${identityUuid}`] as const

export const useFindIdentityInRoleQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindIdentityInRoleHook>>>, TError = ApiError>(
    uuid: string,
    roleUuid: string,
    identityUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindIdentityInRoleHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindIdentityInRoleHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindIdentityInRoleQueryKey(uuid, roleUuid, identityUuid)

    const findIdentityInRole = useFindIdentityInRoleHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindIdentityInRoleHook>>>> = ({ signal }) =>
        findIdentityInRole(uuid, roleUuid, identityUuid, signal)

    return { queryKey, queryFn, enabled: !!(uuid && roleUuid && identityUuid), ...queryOptions }
}

export type FindIdentityInRoleQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindIdentityInRoleHook>>>>
export type FindIdentityInRoleQueryError = ApiError

export const useFindIdentityInRole = <TData = Awaited<ReturnType<ReturnType<typeof useFindIdentityInRoleHook>>>, TError = ApiError>(
    uuid: string,
    roleUuid: string,
    identityUuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindIdentityInRoleHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindIdentityInRoleQueryOptions(uuid, roleUuid, identityUuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindRelatedIdentities2Hook = () => {
    const findRelatedIdentities2 = useIAmSwaggerClient<IdentityInGroupData[]>()

    return (uuid: string, params?: FindRelatedIdentities2Params, signal?: AbortSignal) => {
        return findRelatedIdentities2({ url: `/groups/${uuid}/identities`, method: 'get', params, signal })
    }
}

export const getFindRelatedIdentities2QueryKey = (uuid: string, params?: FindRelatedIdentities2Params) =>
    [`/groups/${uuid}/identities`, ...(params ? [params] : [])] as const

export const useFindRelatedIdentities2QueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentities2Hook>>>,
    TError = ApiError,
>(
    uuid: string,
    params?: FindRelatedIdentities2Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentities2Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentities2Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindRelatedIdentities2QueryKey(uuid, params)

    const findRelatedIdentities2 = useFindRelatedIdentities2Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentities2Hook>>>> = ({ signal }) =>
        findRelatedIdentities2(uuid, params, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindRelatedIdentities2QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentities2Hook>>>>
export type FindRelatedIdentities2QueryError = ApiError

export const useFindRelatedIdentities2 = <TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentities2Hook>>>, TError = ApiError>(
    uuid: string,
    params?: FindRelatedIdentities2Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentities2Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindRelatedIdentities2QueryOptions(uuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindRelatedIdentities3Hook = () => {
    const findRelatedIdentities3 = useIAmSwaggerClient<number>()

    return (uuid: string, params?: FindRelatedIdentities3Params, signal?: AbortSignal) => {
        return findRelatedIdentities3({ url: `/groups/${uuid}/identitiesCount`, method: 'get', params, signal })
    }
}

export const getFindRelatedIdentities3QueryKey = (uuid: string, params?: FindRelatedIdentities3Params) =>
    [`/groups/${uuid}/identitiesCount`, ...(params ? [params] : [])] as const

export const useFindRelatedIdentities3QueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentities3Hook>>>,
    TError = ApiError,
>(
    uuid: string,
    params?: FindRelatedIdentities3Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentities3Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentities3Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindRelatedIdentities3QueryKey(uuid, params)

    const findRelatedIdentities3 = useFindRelatedIdentities3Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentities3Hook>>>> = ({ signal }) =>
        findRelatedIdentities3(uuid, params, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindRelatedIdentities3QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentities3Hook>>>>
export type FindRelatedIdentities3QueryError = ApiError

export const useFindRelatedIdentities3 = <TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentities3Hook>>>, TError = ApiError>(
    uuid: string,
    params?: FindRelatedIdentities3Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentities3Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindRelatedIdentities3QueryOptions(uuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindRelatedIdentitiesAndCountHook = () => {
    const findRelatedIdentitiesAndCount = useIAmSwaggerClient<IdentitiesInGroupAndCount>()

    return (uuid: string, params?: FindRelatedIdentitiesAndCountParams, signal?: AbortSignal) => {
        return findRelatedIdentitiesAndCount({ url: `/groups/${uuid}/identitiesAndCount`, method: 'get', params, signal })
    }
}

export const getFindRelatedIdentitiesAndCountQueryKey = (uuid: string, params?: FindRelatedIdentitiesAndCountParams) =>
    [`/groups/${uuid}/identitiesAndCount`, ...(params ? [params] : [])] as const

export const useFindRelatedIdentitiesAndCountQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesAndCountHook>>>,
    TError = ApiError,
>(
    uuid: string,
    params?: FindRelatedIdentitiesAndCountParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesAndCountHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesAndCountHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindRelatedIdentitiesAndCountQueryKey(uuid, params)

    const findRelatedIdentitiesAndCount = useFindRelatedIdentitiesAndCountHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesAndCountHook>>>> = ({ signal }) =>
        findRelatedIdentitiesAndCount(uuid, params, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindRelatedIdentitiesAndCountQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesAndCountHook>>>>
export type FindRelatedIdentitiesAndCountQueryError = ApiError

export const useFindRelatedIdentitiesAndCount = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesAndCountHook>>>,
    TError = ApiError,
>(
    uuid: string,
    params?: FindRelatedIdentitiesAndCountParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitiesAndCountHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindRelatedIdentitiesAndCountQueryOptions(uuid, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetRelatedIdentitiesEmailsHook = () => {
    const getRelatedIdentitiesEmails = useIAmSwaggerClient<string>()

    return (uuid: string, signal?: AbortSignal) => {
        return getRelatedIdentitiesEmails({ url: `/groups/${uuid}/identities/emails`, method: 'get', signal })
    }
}

export const getGetRelatedIdentitiesEmailsQueryKey = (uuid: string) => [`/groups/${uuid}/identities/emails`] as const

export const useGetRelatedIdentitiesEmailsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetRelatedIdentitiesEmailsHook>>>,
    TError = ApiError,
>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRelatedIdentitiesEmailsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRelatedIdentitiesEmailsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetRelatedIdentitiesEmailsQueryKey(uuid)

    const getRelatedIdentitiesEmails = useGetRelatedIdentitiesEmailsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetRelatedIdentitiesEmailsHook>>>> = ({ signal }) =>
        getRelatedIdentitiesEmails(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type GetRelatedIdentitiesEmailsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetRelatedIdentitiesEmailsHook>>>>
export type GetRelatedIdentitiesEmailsQueryError = ApiError

export const useGetRelatedIdentitiesEmails = <TData = Awaited<ReturnType<ReturnType<typeof useGetRelatedIdentitiesEmailsHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRelatedIdentitiesEmailsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetRelatedIdentitiesEmailsQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindSubGroupsHook = () => {
    const findSubGroups = useIAmSwaggerClient<Group[]>()

    return (uuid: string, signal?: AbortSignal) => {
        return findSubGroups({ url: `/groups/${uuid}/groups`, method: 'get', signal })
    }
}

export const getFindSubGroupsQueryKey = (uuid: string) => [`/groups/${uuid}/groups`] as const

export const useFindSubGroupsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindSubGroupsHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindSubGroupsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindSubGroupsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindSubGroupsQueryKey(uuid)

    const findSubGroups = useFindSubGroupsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindSubGroupsHook>>>> = ({ signal }) => findSubGroups(uuid, signal)

    return { queryKey, queryFn, enabled: !!uuid, ...queryOptions }
}

export type FindSubGroupsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindSubGroupsHook>>>>
export type FindSubGroupsQueryError = ApiError

export const useFindSubGroups = <TData = Awaited<ReturnType<ReturnType<typeof useFindSubGroupsHook>>>, TError = ApiError>(
    uuid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindSubGroupsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindSubGroupsQueryOptions(uuid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetPages3Hook = () => {
    const getPages3 = useIAmSwaggerClient<number>()

    return (params?: GetPages3Params, signal?: AbortSignal) => {
        return getPages3({ url: `/groups/pages`, method: 'get', params, signal })
    }
}

export const getGetPages3QueryKey = (params?: GetPages3Params) => [`/groups/pages`, ...(params ? [params] : [])] as const

export const useGetPages3QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetPages3Hook>>>, TError = ApiError>(
    params?: GetPages3Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPages3Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPages3Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetPages3QueryKey(params)

    const getPages3 = useGetPages3Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPages3Hook>>>> = ({ signal }) => getPages3(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetPages3QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetPages3Hook>>>>
export type GetPages3QueryError = ApiError

export const useGetPages3 = <TData = Awaited<ReturnType<ReturnType<typeof useGetPages3Hook>>>, TError = ApiError>(
    params?: GetPages3Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPages3Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetPages3QueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindAll4Hook = () => {
    const findAll4 = useIAmSwaggerClient<Group[]>()

    return (page: number, limit: number, params?: FindAll4Params, signal?: AbortSignal) => {
        return findAll4({ url: `/groups/page/${page}/${limit}`, method: 'get', params, signal })
    }
}

export const getFindAll4QueryKey = (page: number, limit: number, params?: FindAll4Params) =>
    [`/groups/page/${page}/${limit}`, ...(params ? [params] : [])] as const

export const useFindAll4QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindAll4Hook>>>, TError = ApiError>(
    page: number,
    limit: number,
    params?: FindAll4Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll4Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll4Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindAll4QueryKey(page, limit, params)

    const findAll4 = useFindAll4Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindAll4Hook>>>> = ({ signal }) => findAll4(page, limit, params, signal)

    return { queryKey, queryFn, enabled: !!(page && limit), ...queryOptions }
}

export type FindAll4QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindAll4Hook>>>>
export type FindAll4QueryError = ApiError

export const useFindAll4 = <TData = Awaited<ReturnType<ReturnType<typeof useFindAll4Hook>>>, TError = ApiError>(
    page: number,
    limit: number,
    params?: FindAll4Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll4Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindAll4QueryOptions(page, limit, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindAllWithIdentities1Hook = () => {
    const findAllWithIdentities1 = useIAmSwaggerClient<GroupWithIdentities[]>()

    return (params: FindAllWithIdentities1Params, signal?: AbortSignal) => {
        return findAllWithIdentities1({ url: `/groups/identities`, method: 'get', params, signal })
    }
}

export const getFindAllWithIdentities1QueryKey = (params: FindAllWithIdentities1Params) =>
    [`/groups/identities`, ...(params ? [params] : [])] as const

export const useFindAllWithIdentities1QueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindAllWithIdentities1Hook>>>,
    TError = ApiError,
>(
    params: FindAllWithIdentities1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAllWithIdentities1Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAllWithIdentities1Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindAllWithIdentities1QueryKey(params)

    const findAllWithIdentities1 = useFindAllWithIdentities1Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindAllWithIdentities1Hook>>>> = ({ signal }) =>
        findAllWithIdentities1(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindAllWithIdentities1QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindAllWithIdentities1Hook>>>>
export type FindAllWithIdentities1QueryError = ApiError

export const useFindAllWithIdentities1 = <TData = Awaited<ReturnType<ReturnType<typeof useFindAllWithIdentities1Hook>>>, TError = ApiError>(
    params: FindAllWithIdentities1Params,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAllWithIdentities1Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindAllWithIdentities1QueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetStdHistoryHook = () => {
    const getStdHistory = useIAmSwaggerClient<StdHistory[]>()

    return (params: GetStdHistoryParams, signal?: AbortSignal) => {
        return getStdHistory({ url: `/groups/getHistory/`, method: 'get', params, signal })
    }
}

export const getGetStdHistoryQueryKey = (params: GetStdHistoryParams) => [`/groups/getHistory/`, ...(params ? [params] : [])] as const

export const useGetStdHistoryQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetStdHistoryHook>>>, TError = ApiError>(
    params: GetStdHistoryParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetStdHistoryHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetStdHistoryHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetStdHistoryQueryKey(params)

    const getStdHistory = useGetStdHistoryHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetStdHistoryHook>>>> = ({ signal }) => getStdHistory(params, signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetStdHistoryQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetStdHistoryHook>>>>
export type GetStdHistoryQueryError = ApiError

export const useGetStdHistory = <TData = Awaited<ReturnType<ReturnType<typeof useGetStdHistoryHook>>>, TError = ApiError>(
    params: GetStdHistoryParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetStdHistoryHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetStdHistoryQueryOptions(params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindAllHook = () => {
    const findAll = useIAmSwaggerClient<RoleOrgGroup[]>()

    return (signal?: AbortSignal) => {
        return findAll({ url: `/gids`, method: 'get', signal })
    }
}

export const getFindAllQueryKey = () => [`/gids`] as const

export const useFindAllQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindAllHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAllHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAllHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindAllQueryKey()

    const findAll = useFindAllHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindAllHook>>>> = ({ signal }) => findAll(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindAllQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindAllHook>>>>
export type FindAllQueryError = ApiError

export const useFindAll = <TData = Awaited<ReturnType<ReturnType<typeof useFindAllHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAllHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindAllQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFind2Hook = () => {
    const find2 = useIAmSwaggerClient<RoleOrgGroup>()

    return (gid: string, signal?: AbortSignal) => {
        return find2({ url: `/gids/${gid}`, method: 'get', signal })
    }
}

export const getFind2QueryKey = (gid: string) => [`/gids/${gid}`] as const

export const useFind2QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFind2Hook>>>, TError = ApiError>(
    gid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFind2Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFind2Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFind2QueryKey(gid)

    const find2 = useFind2Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFind2Hook>>>> = ({ signal }) => find2(gid, signal)

    return { queryKey, queryFn, enabled: !!gid, ...queryOptions }
}

export type Find2QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFind2Hook>>>>
export type Find2QueryError = ApiError

export const useFind2 = <TData = Awaited<ReturnType<ReturnType<typeof useFind2Hook>>>, TError = ApiError>(
    gid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFind2Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFind2QueryOptions(gid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindRelatedIdentitesHook = () => {
    const findRelatedIdentites = useIAmSwaggerClient<Identity[]>()

    return (gid: string, signal?: AbortSignal) => {
        return findRelatedIdentites({ url: `/gids/${gid}/identities`, method: 'get', signal })
    }
}

export const getFindRelatedIdentitesQueryKey = (gid: string) => [`/gids/${gid}/identities`] as const

export const useFindRelatedIdentitesQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitesHook>>>, TError = ApiError>(
    gid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitesHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitesHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindRelatedIdentitesQueryKey(gid)

    const findRelatedIdentites = useFindRelatedIdentitesHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitesHook>>>> = ({ signal }) =>
        findRelatedIdentites(gid, signal)

    return { queryKey, queryFn, enabled: !!gid, ...queryOptions }
}

export type FindRelatedIdentitesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitesHook>>>>
export type FindRelatedIdentitesQueryError = ApiError

export const useFindRelatedIdentites = <TData = Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitesHook>>>, TError = ApiError>(
    gid: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindRelatedIdentitesHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindRelatedIdentitesQueryOptions(gid, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGetPages4Hook = () => {
    const getPages4 = useIAmSwaggerClient<number>()

    return (signal?: AbortSignal) => {
        return getPages4({ url: `/gids/pages`, method: 'get', signal })
    }
}

export const getGetPages4QueryKey = () => [`/gids/pages`] as const

export const useGetPages4QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGetPages4Hook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPages4Hook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPages4Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGetPages4QueryKey()

    const getPages4 = useGetPages4Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPages4Hook>>>> = ({ signal }) => getPages4(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type GetPages4QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetPages4Hook>>>>
export type GetPages4QueryError = ApiError

export const useGetPages4 = <TData = Awaited<ReturnType<ReturnType<typeof useGetPages4Hook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPages4Hook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetPages4QueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindAll5Hook = () => {
    const findAll5 = useIAmSwaggerClient<RoleOrgGroup[]>()

    return (page: number, limit: number, signal?: AbortSignal) => {
        return findAll5({ url: `/gids/page/${page}/${limit}`, method: 'get', signal })
    }
}

export const getFindAll5QueryKey = (page: number, limit: number) => [`/gids/page/${page}/${limit}`] as const

export const useFindAll5QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindAll5Hook>>>, TError = ApiError>(
    page: number,
    limit: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll5Hook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll5Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindAll5QueryKey(page, limit)

    const findAll5 = useFindAll5Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindAll5Hook>>>> = ({ signal }) => findAll5(page, limit, signal)

    return { queryKey, queryFn, enabled: !!(page && limit), ...queryOptions }
}

export type FindAll5QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindAll5Hook>>>>
export type FindAll5QueryError = ApiError

export const useFindAll5 = <TData = Awaited<ReturnType<ReturnType<typeof useFindAll5Hook>>>, TError = ApiError>(
    page: number,
    limit: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAll5Hook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindAll5QueryOptions(page, limit, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindNeidentAdminHook = () => {
    const findNeidentAdmin = useIAmSwaggerClient<RoleOrgGroup>()

    return (signal?: AbortSignal) => {
        return findNeidentAdmin({ url: `/gids/neident_admin`, method: 'get', signal })
    }
}

export const getFindNeidentAdminQueryKey = () => [`/gids/neident_admin`] as const

export const useFindNeidentAdminQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindNeidentAdminHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindNeidentAdminHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindNeidentAdminHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindNeidentAdminQueryKey()

    const findNeidentAdmin = useFindNeidentAdminHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindNeidentAdminHook>>>> = ({ signal }) => findNeidentAdmin(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindNeidentAdminQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindNeidentAdminHook>>>>
export type FindNeidentAdminQueryError = ApiError

export const useFindNeidentAdmin = <TData = Awaited<ReturnType<ReturnType<typeof useFindNeidentAdminHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindNeidentAdminHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindNeidentAdminQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useAddOrGetGroupHook = () => {
    const addOrGetGroup = useIAmSwaggerClient<RoleOrgGroup>()

    return (roleUuid: string, orgId: string, signal?: AbortSignal) => {
        return addOrGetGroup({ url: `/gids/getGroup/${roleUuid}/${orgId}`, method: 'get', signal })
    }
}

export const getAddOrGetGroupQueryKey = (roleUuid: string, orgId: string) => [`/gids/getGroup/${roleUuid}/${orgId}`] as const

export const useAddOrGetGroupQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useAddOrGetGroupHook>>>, TError = ApiError>(
    roleUuid: string,
    orgId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAddOrGetGroupHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAddOrGetGroupHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getAddOrGetGroupQueryKey(roleUuid, orgId)

    const addOrGetGroup = useAddOrGetGroupHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useAddOrGetGroupHook>>>> = ({ signal }) =>
        addOrGetGroup(roleUuid, orgId, signal)

    return { queryKey, queryFn, enabled: !!(roleUuid && orgId), ...queryOptions }
}

export type AddOrGetGroupQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddOrGetGroupHook>>>>
export type AddOrGetGroupQueryError = ApiError

export const useAddOrGetGroup = <TData = Awaited<ReturnType<ReturnType<typeof useAddOrGetGroupHook>>>, TError = ApiError>(
    roleUuid: string,
    orgId: string,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAddOrGetGroupHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useAddOrGetGroupQueryOptions(roleUuid, orgId, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindAllWithParamsHook = () => {
    const findAllWithParams = useIAmSwaggerClient<RoleOrgGroupWithParams>()

    return (page: number, limit: number, params: FindAllWithParamsParams, signal?: AbortSignal) => {
        return findAllWithParams({ url: `/gids/findAllWithParams/${page}/${limit}`, method: 'get', params, signal })
    }
}

export const getFindAllWithParamsQueryKey = (page: number, limit: number, params: FindAllWithParamsParams) =>
    [`/gids/findAllWithParams/${page}/${limit}`, ...(params ? [params] : [])] as const

export const useFindAllWithParamsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindAllWithParamsHook>>>, TError = ApiError>(
    page: number,
    limit: number,
    params: FindAllWithParamsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAllWithParamsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAllWithParamsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindAllWithParamsQueryKey(page, limit, params)

    const findAllWithParams = useFindAllWithParamsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindAllWithParamsHook>>>> = ({ signal }) =>
        findAllWithParams(page, limit, params, signal)

    return { queryKey, queryFn, enabled: !!(page && limit), ...queryOptions }
}

export type FindAllWithParamsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindAllWithParamsHook>>>>
export type FindAllWithParamsQueryError = ApiError

export const useFindAllWithParams = <TData = Awaited<ReturnType<ReturnType<typeof useFindAllWithParamsHook>>>, TError = ApiError>(
    page: number,
    limit: number,
    params: FindAllWithParamsParams,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAllWithParamsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindAllWithParamsQueryOptions(page, limit, params, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindEaGarpoAdminHook = () => {
    const findEaGarpoAdmin = useIAmSwaggerClient<RoleOrgGroup>()

    return (signal?: AbortSignal) => {
        return findEaGarpoAdmin({ url: `/gids/ea_garpo_admin`, method: 'get', signal })
    }
}

export const getFindEaGarpoAdminQueryKey = () => [`/gids/ea_garpo_admin`] as const

export const useFindEaGarpoAdminQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useFindEaGarpoAdminHook>>>,
    TError = ApiError,
>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindEaGarpoAdminHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindEaGarpoAdminHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindEaGarpoAdminQueryKey()

    const findEaGarpoAdmin = useFindEaGarpoAdminHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindEaGarpoAdminHook>>>> = ({ signal }) => findEaGarpoAdmin(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindEaGarpoAdminQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindEaGarpoAdminHook>>>>
export type FindEaGarpoAdminQueryError = ApiError

export const useFindEaGarpoAdmin = <TData = Awaited<ReturnType<ReturnType<typeof useFindEaGarpoAdminHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindEaGarpoAdminHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindEaGarpoAdminQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFindAkvsAdminHook = () => {
    const findAkvsAdmin = useIAmSwaggerClient<RoleOrgGroup>()

    return (signal?: AbortSignal) => {
        return findAkvsAdmin({ url: `/gids/akvs_admin`, method: 'get', signal })
    }
}

export const getFindAkvsAdminQueryKey = () => [`/gids/akvs_admin`] as const

export const useFindAkvsAdminQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFindAkvsAdminHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAkvsAdminHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAkvsAdminHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFindAkvsAdminQueryKey()

    const findAkvsAdmin = useFindAkvsAdminHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFindAkvsAdminHook>>>> = ({ signal }) => findAkvsAdmin(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type FindAkvsAdminQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFindAkvsAdminHook>>>>
export type FindAkvsAdminQueryError = ApiError

export const useFindAkvsAdmin = <TData = Awaited<ReturnType<ReturnType<typeof useFindAkvsAdminHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFindAkvsAdminHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFindAkvsAdminQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useFind3Hook = () => {
    const find3 = useIAmSwaggerClient<Configuration>()

    return (signal?: AbortSignal) => {
        return find3({ url: `/configuration`, method: 'get', signal })
    }
}

export const getFind3QueryKey = () => [`/configuration`] as const

export const useFind3QueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useFind3Hook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFind3Hook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFind3Hook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getFind3QueryKey()

    const find3 = useFind3Hook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useFind3Hook>>>> = ({ signal }) => find3(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type Find3QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFind3Hook>>>>
export type Find3QueryError = ApiError

export const useFind3 = <TData = Awaited<ReturnType<ReturnType<typeof useFind3Hook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useFind3Hook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useFind3QueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useGenerateUuidsHook = () => {
    const generateUuids = useIAmSwaggerClient<string[]>()

    return (count: number, signal?: AbortSignal) => {
        return generateUuids({ url: `/configuration/generateUuids/${count}`, method: 'get', signal })
    }
}

export const getGenerateUuidsQueryKey = (count: number) => [`/configuration/generateUuids/${count}`] as const

export const useGenerateUuidsQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useGenerateUuidsHook>>>, TError = ApiError>(
    count: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGenerateUuidsHook>>>, TError, TData> },
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGenerateUuidsHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getGenerateUuidsQueryKey(count)

    const generateUuids = useGenerateUuidsHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGenerateUuidsHook>>>> = ({ signal }) => generateUuids(count, signal)

    return { queryKey, queryFn, enabled: !!count, ...queryOptions }
}

export type GenerateUuidsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGenerateUuidsHook>>>>
export type GenerateUuidsQueryError = ApiError

export const useGenerateUuids = <TData = Awaited<ReturnType<ReturnType<typeof useGenerateUuidsHook>>>, TError = ApiError>(
    count: number,
    options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGenerateUuidsHook>>>, TError, TData> },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGenerateUuidsQueryOptions(count, options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useIsAliveHook = () => {
    const isAlive = useIAmSwaggerClient<boolean>()

    return (signal?: AbortSignal) => {
        return isAlive({ url: `/alive`, method: 'get', signal })
    }
}

export const getIsAliveQueryKey = () => [`/alive`] as const

export const useIsAliveQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useIsAliveHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIsAliveHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIsAliveHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getIsAliveQueryKey()

    const isAlive = useIsAliveHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useIsAliveHook>>>> = ({ signal }) => isAlive(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type IsAliveQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useIsAliveHook>>>>
export type IsAliveQueryError = ApiError

export const useIsAlive = <TData = Awaited<ReturnType<ReturnType<typeof useIsAliveHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIsAliveHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useIsAliveQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useIsActiveHook = () => {
    const isActive = useIAmSwaggerClient<boolean>()

    return (signal?: AbortSignal) => {
        return isActive({ url: `/active_user`, method: 'get', signal })
    }
}

export const getIsActiveQueryKey = () => [`/active_user`] as const

export const useIsActiveQueryOptions = <TData = Awaited<ReturnType<ReturnType<typeof useIsActiveHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIsActiveHook>>>, TError, TData>
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIsActiveHook>>>, TError, TData> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {}

    const queryKey = queryOptions?.queryKey ?? getIsActiveQueryKey()

    const isActive = useIsActiveHook()

    const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useIsActiveHook>>>> = ({ signal }) => isActive(signal)

    return { queryKey, queryFn, ...queryOptions }
}

export type IsActiveQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useIsActiveHook>>>>
export type IsActiveQueryError = ApiError

export const useIsActive = <TData = Awaited<ReturnType<ReturnType<typeof useIsActiveHook>>>, TError = ApiError>(options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useIsActiveHook>>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useIsActiveQueryOptions(options)

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

    query.queryKey = queryOptions.queryKey

    return query
}

export const useDeleteSubRoleHook = () => {
    const deleteSubRole = useIAmSwaggerClient<OperationResult>()

    return (uuid: string, subRoleUuid: string) => {
        return deleteSubRole({ url: `/roles/${uuid}/roles/${subRoleUuid}`, method: 'delete' })
    }
}

export const useDeleteSubRoleMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteSubRoleHook>>>,
        TError,
        { uuid: string; subRoleUuid: string },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteSubRoleHook>>>, TError, { uuid: string; subRoleUuid: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteSubRole = useDeleteSubRoleHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteSubRoleHook>>>, { uuid: string; subRoleUuid: string }> = (
        props,
    ) => {
        const { uuid, subRoleUuid } = props ?? {}

        return deleteSubRole(uuid, subRoleUuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteSubRoleMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteSubRoleHook>>>>

export type DeleteSubRoleMutationError = ApiError

export const useDeleteSubRole = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteSubRoleHook>>>,
        TError,
        { uuid: string; subRoleUuid: string },
        TContext
    >
}) => {
    const mutationOptions = useDeleteSubRoleMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteGroupRelationHook = () => {
    const deleteGroupRelation = useIAmSwaggerClient<OperationResult>()

    return (uuid: string, groupUuid: string) => {
        return deleteGroupRelation({ url: `/identities/${uuid}/groups/${groupUuid}`, method: 'delete' })
    }
}

export const useDeleteGroupRelationMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteGroupRelationHook>>>,
        TError,
        { uuid: string; groupUuid: string },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteGroupRelationHook>>>, TError, { uuid: string; groupUuid: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteGroupRelation = useDeleteGroupRelationHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteGroupRelationHook>>>, { uuid: string; groupUuid: string }> = (
        props,
    ) => {
        const { uuid, groupUuid } = props ?? {}

        return deleteGroupRelation(uuid, groupUuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteGroupRelationMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteGroupRelationHook>>>>

export type DeleteGroupRelationMutationError = ApiError

export const useDeleteGroupRelation = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteGroupRelationHook>>>,
        TError,
        { uuid: string; groupUuid: string },
        TContext
    >
}) => {
    const mutationOptions = useDeleteGroupRelationMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDetachDeleteHook = () => {
    const detachDelete = useIAmSwaggerClient<OperationResult>()

    return (uuid: string) => {
        return detachDelete({ url: `/identities/${uuid}/detachDelete`, method: 'delete' })
    }
}

export const useDetachDeleteMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDetachDeleteHook>>>, TError, { uuid: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDetachDeleteHook>>>, TError, { uuid: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const detachDelete = useDetachDeleteHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDetachDeleteHook>>>, { uuid: string }> = (props) => {
        const { uuid } = props ?? {}

        return detachDelete(uuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type DetachDeleteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDetachDeleteHook>>>>

export type DetachDeleteMutationError = ApiError

export const useDetachDelete = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDetachDeleteHook>>>, TError, { uuid: string }, TContext>
}) => {
    const mutationOptions = useDetachDeleteMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useRemoveAuthResourceHook = () => {
    const removeAuthResource = useIAmSwaggerClient<OperationResult>()

    return (identityUuid: string, authResourceUuid: string) => {
        return removeAuthResource({ url: `/identities/${identityUuid}/authResource/${authResourceUuid}`, method: 'delete' })
    }
}

export const useRemoveAuthResourceMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRemoveAuthResourceHook>>>,
        TError,
        { identityUuid: string; authResourceUuid: string },
        TContext
    >
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRemoveAuthResourceHook>>>,
    TError,
    { identityUuid: string; authResourceUuid: string },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {}

    const removeAuthResource = useRemoveAuthResourceHook()

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useRemoveAuthResourceHook>>>,
        { identityUuid: string; authResourceUuid: string }
    > = (props) => {
        const { identityUuid, authResourceUuid } = props ?? {}

        return removeAuthResource(identityUuid, authResourceUuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type RemoveAuthResourceMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRemoveAuthResourceHook>>>>

export type RemoveAuthResourceMutationError = ApiError

export const useRemoveAuthResource = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useRemoveAuthResourceHook>>>,
        TError,
        { identityUuid: string; authResourceUuid: string },
        TContext
    >
}) => {
    const mutationOptions = useRemoveAuthResourceMutationOptions(options)

    return useMutation(mutationOptions)
}

export const useDeleteSubGroupHook = () => {
    const deleteSubGroup = useIAmSwaggerClient<OperationResult>()

    return (uuid: string, subGroupUuid: string) => {
        return deleteSubGroup({ url: `/groups/${uuid}/groups/${subGroupUuid}`, method: 'delete' })
    }
}

export const useDeleteSubGroupMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteSubGroupHook>>>,
        TError,
        { uuid: string; subGroupUuid: string },
        TContext
    >
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useDeleteSubGroupHook>>>, TError, { uuid: string; subGroupUuid: string }, TContext> => {
    const { mutation: mutationOptions } = options ?? {}

    const deleteSubGroup = useDeleteSubGroupHook()

    const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useDeleteSubGroupHook>>>, { uuid: string; subGroupUuid: string }> = (
        props,
    ) => {
        const { uuid, subGroupUuid } = props ?? {}

        return deleteSubGroup(uuid, subGroupUuid)
    }

    return { mutationFn, ...mutationOptions }
}

export type DeleteSubGroupMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteSubGroupHook>>>>

export type DeleteSubGroupMutationError = ApiError

export const useDeleteSubGroup = <TError = ApiError, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteSubGroupHook>>>,
        TError,
        { uuid: string; subGroupUuid: string },
        TContext
    >
}) => {
    const mutationOptions = useDeleteSubGroupMutationOptions(options)

    return useMutation(mutationOptions)
}
