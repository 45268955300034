import React, { useId } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import classNames from 'classnames'
import { Languages } from '@isdd/metais-common/localization/languages'
import { sk, enUS as en } from 'date-fns/locale'
import { useTranslation } from 'react-i18next'

import { useGetTranslates } from './useGetTranslates'
import styles from './dateInput.module.scss'
import './dateInput.scss'

registerLocale('sk', sk)
registerLocale('en', en)

interface SimpleDateInputProps {
    id: string
    showTimeSelect?: boolean
    className?: string
    wrapperClassName?: string
    placeholderText?: string
    name: string
    selected?: Date | null
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (date: Date | null, event: React.SyntheticEvent<any, Event> | undefined) => void
    dateFormat: string
    disabled?: boolean
}

export const SimpleDateInput = ({
    id,
    showTimeSelect,
    className,
    wrapperClassName,
    placeholderText,
    name,
    selected,
    onChange,
    dateFormat,
    disabled,
}: SimpleDateInputProps) => {
    const { t, i18n } = useTranslation()
    const translates = useGetTranslates()

    const uId = useId()
    const inputId = id ?? `input-${uId}` // NVDA has problems with ID starting with `useID` `::`, needs to be prefixed
    const controlHintIt = `${inputId}-control`

    return (
        <>
            <span className="sr-only" id={controlHintIt}>
                {t('input.datePicker.hint')}
            </span>
            {!disabled && (
                <span className="sr-only" id={controlHintIt}>
                    {t('input.datePicker.hint')}
                </span>
            )}
            <DatePicker
                showTimeSelect={showTimeSelect}
                wrapperClassName={wrapperClassName}
                id={id}
                className={classNames('govuk-input', className)}
                placeholderText={placeholderText}
                popperClassName={styles.dateInputPopperClass}
                popperPlacement="bottom-start"
                name={name}
                selected={selected}
                onChange={onChange}
                readOnly={disabled}
                ariaDescribedBy={controlHintIt}
                dateFormat={dateFormat}
                dateFormatCalendar={dateFormat}
                locale={i18n.language === Languages.SLOVAK ? Languages.SLOVAK : Languages.ENGLISH}
                onCalendarOpen={() => {
                    const nodes = document.querySelectorAll('.react-datepicker')
                    nodes.forEach((el) => el.removeAttribute('aria-label'))
                }}
                {...translates}
            />
        </>
    )
}
