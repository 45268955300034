import { getLongerArray, hasArraysEqualValues, reduceToObjectByUuid } from '@isdd/metais-common/utils/utils'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import classNames from 'classnames'
import { TFunction } from 'i18next'

import styles from '@/components/views/history/history-compare/historyCompare.module.scss'

export interface IRelationItem {
    type?: string
    name?: string
    uuid?: string
    actionTime?: Date
    actions?: string[]
}

export const getDifferent = (arr1: IRelationItem[], arr2: IRelationItem[], entityId: string): [IRelationItem[], IRelationItem[]] => {
    const firstRelDiff: Record<string, IRelationItem> = {}
    const secondRelDiff: Record<string, IRelationItem> = {}

    const firstReducedToObject = reduceToObjectByUuid(arr1)
    const secondReducedToObject = reduceToObjectByUuid(arr2)

    arr1.forEach((rel) => {
        if (rel.uuid == null) return
        if (rel.uuid == entityId) return

        const second = secondReducedToObject[rel.uuid]
        const hasSameAction = hasArraysEqualValues(rel.actions ?? [], second?.actions ?? [])
        if (!second || second.name !== rel.name || second.type !== rel.type || !hasSameAction) {
            firstRelDiff[rel.uuid] = rel
        }
    })

    arr2.forEach((rel) => {
        if (rel.uuid == null) return
        if (rel.uuid == entityId) return

        const first = firstReducedToObject[rel.uuid]
        const hasSameAction = hasArraysEqualValues(rel.actions ?? [], first?.actions ?? [])
        if (!first || first.name !== rel.name || first.type !== rel.type || !hasSameAction) {
            secondRelDiff[rel.uuid] = rel
        }
    })

    const firstArr = Object.values(firstRelDiff)
    const secondArr = Object.values(secondRelDiff)

    return [firstArr, secondArr]
}

export const getIsDiff = (arr1: IRelationItem[], arr2: IRelationItem[], entityId: string): boolean => {
    const secondReducedToObject = reduceToObjectByUuid(arr2 ?? [])

    if (Array.isArray(arr1) && Array.isArray(arr2)) {
        if (arr1.length !== arr2.length) {
            return true
        }

        const longerArray = getLongerArray(arr1, arr2)

        for (let i = 0; i < longerArray.length; i++) {
            const first = longerArray?.[i]
            if (first?.uuid) {
                if (first.uuid == entityId) continue
                const second = secondReducedToObject?.[first?.uuid]
                const hasSameAction = hasArraysEqualValues(first?.actions ?? [], second?.actions ?? [])

                if (first?.name !== second?.name || first?.type !== second?.type || !hasSameAction) {
                    return true
                }
            }
        }
    }

    return false
}

export const getRelationDiffColumns = (
    diffArr1: IRelationItem[],
    diffArr2: IRelationItem[],
    entityId: string,
    isSimple: boolean,
    t: TFunction<'translation', undefined, 'translation'>,
) => {
    const getDiffType = (actions: string[]): 'create' | 'invalidate' | 'unknown-action' => {
        if (actions.includes('CREATE_INCIDENT_REL')) {
            return 'create'
        }
        if (actions.includes('INVALIDATE_INCIDENT_REL')) {
            return 'invalidate'
        }
        return 'unknown-action'
    }

    const columns: Array<ColumnDef<IRelationItem>> = [
        {
            header: isSimple ? t('historyTab.relations') : t('historyTab.relationsFirst'),
            accessorFn: (row) => row?.name,
            enableSorting: false,
            id: 'name',
            meta: {
                getCellContext: (ctx: CellContext<IRelationItem, unknown>) => ctx?.getValue?.(),
            },
            cell: (ctx) => {
                const diff = diffArr1.find((i) => i.uuid === ctx.row.original.uuid)
                const diffType = getDiffType(diff?.actions ?? [])
                const isRelationOnItself = ctx.row.original.uuid == entityId

                const isCreate = diffType == 'create' && !isRelationOnItself
                const isInvalidate = diffType == 'invalidate' && !isRelationOnItself
                return (
                    <span
                        className={classNames({ [styles.diffIns]: isCreate, [styles.diffDel]: isInvalidate })}
                    >{`${ctx?.row?.original?.type}: ${ctx?.row?.original?.name}`}</span>
                )
            },
            size: 100,
        },
    ]
    const columnsSec: Array<ColumnDef<IRelationItem>> = [
        {
            header: t('historyTab.relationsSecond'),
            accessorFn: (row) => row?.name,
            enableSorting: false,
            id: 'name',
            meta: {
                getCellContext: (ctx: CellContext<IRelationItem, unknown>) => ctx?.getValue?.(),
            },
            cell: (ctx) => {
                const diff = diffArr2.find((i) => i.uuid === ctx.row.original.uuid)
                const diffType = getDiffType(diff?.actions ?? [])
                const isRelationOnItself = ctx.row.original.uuid == entityId

                const isCreate = diffType == 'create' && !isRelationOnItself
                const isInvalidate = diffType == 'invalidate' && !isRelationOnItself

                return (
                    <span
                        className={classNames({
                            [styles.diffIns]: isCreate,
                            [styles.diffDel]: isInvalidate,
                        })}
                    >{`${ctx?.row?.original?.type}: ${ctx?.row?.original?.name}`}</span>
                )
            },
            size: 100,
        },
    ]

    return { columns, columnsSec }
}
