import { Button } from '@isdd/idsk-ui-kit/button/Button'
import { Pagination } from '@isdd/idsk-ui-kit/types'
import React, { useState } from 'react'
import { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { IFilterParams, useFilterParams } from '../../../hooks/useFilter'

import { useGetProgress } from '@isdd/metais-common/hooks/useGetRequestProgress'
import {
    CiFilterUi,
    CiListFilterContainerUi,
    use_ExportHook,
    useExportRelHook,
    useLoadFileHook,
} from '@isdd/metais-common/api/generated/impexp-cmdb-swagger'
import { ExportIcon } from '@isdd/metais-common/assets/images'
import { mapFilterParamsToApi } from '@isdd/metais-common/componentHelpers'
import { getMetaAttributesForCiFilter } from '@isdd/metais-common/componentHelpers/ci/filter'
import { downloadBlobAsFile, generateExportFileName } from '@isdd/metais-common/componentHelpers/download/downloadHelper'
import { IconLabel } from '@isdd/metais-common/components/actions-over-table/icon-label/IconLabel'
import { ExportItemsOrRelations } from '@isdd/metais-common/components/export-items-or-relations/ExportItemsOrRelations'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { useUserPreferences } from '@isdd/metais-common/contexts/userPreferences/userPreferencesContext'
import { handleTooManyRequests } from '@isdd/metais-common/utils/utils'

export enum FileExtensionEnum {
    XML = 'XML',
    CSV = 'CSV',
    EXCEL = 'EXCEL',
    // RDF = 'RDF',
}
export interface IExportButtonProps<T> {
    pagination?: Pagination
    defaultFilterValues: T
    checkedItemsUuids: string[]
    ciTypeName?: string
    ciType: string
    selectedColumns?: string[]
}

export const ExportButton = <T extends FieldValues & IFilterParams>({
    defaultFilterValues,
    checkedItemsUuids,
    pagination,
    ciTypeName,
    ciType: entityName,
    selectedColumns,
}: IExportButtonProps<T>) => {
    const [modalOpen, setModalOpen] = useState(false)
    const { t } = useTranslation()
    const { currentPreferences } = useUserPreferences()
    const { filter: filterParams } = useFilterParams(defaultFilterValues)
    const [isLoading, setLoading] = useState<boolean>(false)

    const exportHook = use_ExportHook()
    const { getRequestStatus, isLoading: isGettingProgress } = useGetProgress('EXPORT')
    const fileLoad = useLoadFileHook()

    const exportRelHook = useExportRelHook()
    const { setIsActionSuccess } = useActionSuccess()
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string>()
    const openModal = () => {
        setModalOpen(true)
    }

    const onClose = () => {
        setModalOpen(false)
        setIsActionSuccess({ value: false, path: `/ci/${entityName}` })
        setIsError(false)
        setLoading(false)
    }

    const exportAndDownloadBlob = async (exportValue: 'items' | 'relations', extension: FileExtensionEnum, entity: string, filter: CiFilterUi) => {
        if (exportValue === 'items') {
            exportHook(extension, {
                filter: filter,
                page: pagination?.pageNumber,
                perpage: pagination?.pageSize,
            } as CiListFilterContainerUi)
                .then(async (result) => {
                    if (result.errorMessage) {
                        setIsError(true)
                    } else {
                        await getRequestStatus(
                            result.id ?? '',
                            async (validationResult) => {
                                const blob = await fileLoad(validationResult?.fileName ?? '')
                                if (blob) {
                                    downloadBlobAsFile(
                                        new Blob([blob]),
                                        generateExportFileName(entity, false, validationResult?.fileExtension, false),
                                        false,
                                    )
                                    setIsActionSuccess({ value: true, path: `/ci/${entityName}` })
                                } else {
                                    setIsError(true)
                                }
                            },
                            () => {
                                setIsError(true)
                            },
                        )
                        setLoading(false)
                    }
                })
                .catch((e) => {
                    handleTooManyRequests(
                        e.message,
                        () => {
                            setErrorMessage(t('exportItemsOrRelations.exportErrorTooManyRequests'))
                            setIsError(true)
                        },
                        () => {
                            setIsError(true)
                        },
                    )
                })
        }

        if (exportValue === 'relations') {
            exportRelHook(extension, {
                filter: filter,
                page: pagination?.pageNumber,
                perpage: pagination?.pageSize,
            } as CiListFilterContainerUi)
                .then(async (result) => {
                    if (result.errorMessage) {
                        setIsError(true)
                    } else {
                        await getRequestStatus(
                            result.id ?? '',
                            async (validationResult) => {
                                const blob = await fileLoad(validationResult?.fileName ?? '')
                                if (blob) {
                                    downloadBlobAsFile(
                                        new Blob([blob]),
                                        generateExportFileName(entity, true, validationResult?.fileExtension ?? '', false),
                                        false,
                                    )
                                    setIsActionSuccess({ value: true, path: `/ci/${entityName}` })
                                } else {
                                    setIsError(true)
                                }
                            },
                            () => {
                                setIsError(true)
                            },
                        )
                        setLoading(false)
                    }
                })
                .catch((e) => {
                    handleTooManyRequests(
                        e.message,
                        () => {
                            setErrorMessage(t('exportItemsOrRelations.exportErrorTooManyRequests'))
                            setIsError(true)
                        },
                        () => {
                            setIsError(true)
                        },
                    )
                })
        }
    }

    const onExportStart = async (exportValue: string, extension: FileExtensionEnum) => {
        if (!entityName) return
        setLoading(true)
        setIsError(false)
        setIsActionSuccess({ value: false, path: `/ci/${entityName}` })

        const filter: CiFilterUi = {
            type: [entityName],
            ...(checkedItemsUuids.length > 0 ? { uuid: checkedItemsUuids } : {}),
            metaAttributes: getMetaAttributesForCiFilter({
                myPO: currentPreferences.myPO,
                showInvalidatedItems: currentPreferences.showInvalidatedItems,
                evidenceStatus: filterParams.evidence_status,
                metaAttributeFilters: filterParams.metaAttributeFilters,
            }),
            fullTextSearch: filterParams.fullTextSearch || '',
            searchFields: selectedColumns,
            attributes: mapFilterParamsToApi({ ...filterParams, evidence_status: undefined }),
        }
        try {
            exportAndDownloadBlob(exportValue as 'items' | 'relations', extension, entityName, filter)
        } catch (e) {
            setIsError(true)
        }
    }

    return (
        <>
            <Button
                onClick={openModal}
                className="marginBottom0"
                variant="secondary"
                aria-label={t('actionOverTable.exportAria', { ciTypeName })}
                label={<IconLabel label={t('actionOverTable.export')} icon={ExportIcon} />}
                aria-haspopup={'dialog'}
            />

            <ExportItemsOrRelations
                errorMessage={errorMessage}
                isOpen={modalOpen}
                close={onClose}
                isLoading={isLoading || isGettingProgress}
                onExportStart={onExportStart}
                isError={isError}
            />
        </>
    )
}
