import { BreadCrumbs, HomeIcon, TextHeading } from '@isdd/idsk-ui-kit/index'
import { useTranslation } from 'react-i18next'
import { QueryFeedback } from '@isdd/metais-common/index'
import { ENTITY_OBJEKT_EVIDENCIE } from '@isdd/metais-common/constants'

import { MainContentWrapper } from '@/components/MainContentWrapper'
import { useCiCreateEntityHook } from '@/hooks/useCiCreateEntity.hook'
import { CreateEvidenceObjectView } from '@/components/views/ci/evidence-object/CreateEvidenceObjectView'

export const CreateEvidenceObject = () => {
    const { t } = useTranslation()
    const entityName = ENTITY_OBJEKT_EVIDENCIE
    const ciCreateData = useCiCreateEntityHook({ entityName: entityName ?? '' })

    return (
        <>
            <BreadCrumbs
                withWidthContainer
                links={[
                    { label: t('breadcrumbs.home'), href: '/', icon: HomeIcon },
                    {
                        label: t('titles.ciList', { ci: ciCreateData.ciTypeName }) ?? '',
                        href: `/ci/${entityName}`,
                    },
                    {
                        label: t('breadcrumbs.ciCreateEntity', { entityName: ciCreateData.data.attributesData.ciTypeData?.name }),
                        href: `/ci/create`,
                    },
                ]}
            />
            <MainContentWrapper>
                <QueryFeedback loading={ciCreateData.isLoading} error={ciCreateData.isError}>
                    <TextHeading size="XL">{t('ciType.createEntity', { entityName: ciCreateData.data.attributesData.ciTypeData?.name })}</TextHeading>
                    <CreateEvidenceObjectView
                        entityName={entityName}
                        ciTypeData={ciCreateData.data.attributesData.ciTypeData}
                        constraintsData={ciCreateData.data.attributesData.constraintsData}
                        generatedEntityId={ciCreateData.data.generatedEntityId}
                        publicAuthorityState={ciCreateData.publicAuthorityState}
                        roleState={ciCreateData.roleState}
                    />
                </QueryFeedback>
            </MainContentWrapper>
        </>
    )
}
