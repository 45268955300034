import { BreadCrumbs, HomeIcon, TextHeading } from '@isdd/idsk-ui-kit/index'
import { useTranslation } from 'react-i18next'
import { ATTRIBUTE_NAME, QueryFeedback, RELATION_TYPE } from '@isdd/metais-common/index'
import { useCiHook } from '@isdd/metais-common/hooks/useCi.hook'
import { useAttributesHook } from '@isdd/metais-common/hooks/useAttributes.hook'
import { useReadCiNeighboursWithAllRels } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { ENTITY_OBJEKT_EVIDENCIE } from '@isdd/metais-common/constants'
import { Languages } from '@isdd/metais-common/localization/languages'
import { useEffect } from 'react'

import { MainContentWrapper } from '@/components/MainContentWrapper'
import { CreateEvidenceObjectView } from '@/components/views/ci/evidence-object/CreateEvidenceObjectView'
import { getCiHowToBreadCrumb, useGetEntityParamsFromUrl } from '@/componentHelpers/ci'
import { EvidenceObjectForm } from '@/componentHelpers/ci/evidence-object'
import { usePublicAuthorityAndRoleHook } from '@/hooks/usePublicAuthorityAndRole.hook'

export const EditEvidenceObject = () => {
    const { t, i18n } = useTranslation()
    const entityName = ENTITY_OBJEKT_EVIDENCIE
    const { entityId } = useGetEntityParamsFromUrl()

    const { ciItemData, isLoading: isCiItemLoading, isError: isCiItemError, gestorData } = useCiHook(entityId)
    const { constraintsData, ciTypeData, isLoading: isAttLoading, isError: isAttError } = useAttributesHook(entityName)
    const ciTypeName = i18n.language === Languages.SLOVAK ? ciTypeData?.name : ciTypeData?.engName
    const {
        data: relList,
        isLoading: isRelListLoading,
        isError: isRelListError,
    } = useReadCiNeighboursWithAllRels(
        entityId ?? '',
        {
            relTypes: [RELATION_TYPE.OE_patri_Agenda, RELATION_TYPE.PO_je_poskytovatelom_OE],
            page: 1,
            perPage: 999,
        },
        {
            query: {
                enabled: !!entityId,
            },
        },
    )

    const {
        groupData,
        publicAuthorityState,
        roleState,
        isError: publicAuthAndRoleError,
        isLoading: publicAuthAndRoleLoading,
    } = usePublicAuthorityAndRoleHook()

    useEffect(() => {
        publicAuthorityState.setSelectedPublicAuthority({
            poUUID: gestorData?.[0].owner,
            poName: gestorData?.[0].configurationItemUi?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov],
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gestorData, groupData])

    const isLoading = isCiItemLoading || isAttLoading || isRelListLoading || publicAuthAndRoleLoading
    const isError = isCiItemError || isAttError || isRelListError || publicAuthAndRoleError

    const currentName =
        i18n.language == Languages.SLOVAK
            ? ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov]
            : ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov]

    return (
        <>
            <BreadCrumbs
                withWidthContainer
                links={[
                    { label: t('breadcrumbs.home'), href: '/', icon: HomeIcon },
                    ...getCiHowToBreadCrumb(entityName ?? '', t),
                    { label: t('titles.ciList', { ci: ciTypeName }), href: `/ci/${entityName}` },
                    { label: currentName ? currentName : t('breadcrumbs.noName'), href: `/ci/${entityName}/${entityId}` },
                    { label: t('breadcrumbs.ciEdit', { itemName: currentName }), href: `/ci/${entityName}/${entityId}/edit` },
                ]}
            />
            <MainContentWrapper>
                <QueryFeedback loading={isLoading} error={isError}>
                    <TextHeading size="XL">{t('ciType.editEntity', { entityName: ciTypeData?.name })}</TextHeading>
                    <CreateEvidenceObjectView
                        entityName={entityName}
                        ciTypeData={ciTypeData}
                        constraintsData={constraintsData}
                        generatedEntityId={{
                            cicode: ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_kod_metais],
                            ciurl: ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_ref_id],
                        }}
                        defaultValues={
                            {
                                ...ciItemData?.attributes,
                                provider: relList?.ciWithRels?.find((i) => i?.rels?.find((u) => u?.type == RELATION_TYPE.PO_je_poskytovatelom_OE))?.ci
                                    ?.uuid,
                                agenda: relList?.ciWithRels?.find((i) => i?.rels?.find((u) => u?.type == RELATION_TYPE.OE_patri_Agenda))?.ci?.uuid,
                            } as EvidenceObjectForm
                        }
                        editedEntity={ciItemData}
                        publicAuthorityState={publicAuthorityState}
                        roleState={roleState}
                        ownerId={entityId ? gestorData?.[0].owner ?? '' : groupData?.gid ?? ''}
                    />
                </QueryFeedback>
            </MainContentWrapper>
        </>
    )
}
