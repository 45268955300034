import { FormProps } from '@isdd/idsk-ui-kit/filter/Filter'
import { CheckBox, Input, SimpleSelect } from '@isdd/idsk-ui-kit/index'
import { EnumType } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { Parameter, ParameterType } from '@isdd/metais-common/api/generated/report-swagger'
import { IFilterParams } from '@isdd/metais-common/hooks/useFilter'
import { SelectFilterCMDBParams } from '@isdd/metais-common/components/select-cmdb-params/SelectFilterCMDBParams'
import React from 'react'
import { DateInput, DateTypeEnum } from '@isdd/idsk-ui-kit/date-input/DateInput'

import { loadEnumerateOptions, loadEnumsRepoOptions } from '@/componentHelpers'

interface IReportsFilterProps {
    parameters?: Parameter[]
    filterEnumData?: (EnumType | undefined)[]
    formProps: FormProps<
        IFilterParams & {
            [key: string]: string
        }
    >
}

export const ReportsFilter: React.FC<IReportsFilterProps> = ({ parameters, filterEnumData, formProps }) => {
    const { register, setValue, clearErrors, filter, formState, watch, control, isOpen } = formProps
    return (
        <>
            {parameters?.map((parameter) => {
                if (parameter.type === ParameterType.ENUMS_CMDB)
                    return (
                        <div key={parameter.key}>
                            <SelectFilterCMDBParams
                                required={parameter.required}
                                label={parameter.name ?? ''}
                                name={parameter.key ?? ''}
                                id={parameter.key}
                                key={'p' + parameter.id}
                                getOptionValue={(item) => item?.value}
                                getOptionLabel={(item) => item?.label}
                                setValue={setValue}
                                error={formState.errors?.[parameter.key ?? 0]?.message}
                                clearErrors={clearErrors}
                                type={parameter.metaData}
                                defaultValueKey={[watch(parameter.key ?? '')]}
                                tabIndex={isOpen ? undefined : -1}
                            />
                        </div>
                    )
                if (parameter.type === ParameterType.ENUMS_REPO)
                    return (
                        <SimpleSelect
                            required={parameter.required}
                            id={parameter?.key}
                            info={parameter?.description}
                            name={parameter?.key ?? ''}
                            label={parameter?.name ?? ''}
                            options={loadEnumsRepoOptions(filterEnumData ?? [], parameter?.metaData)}
                            defaultValue={filter?.[parameter?.key ?? 0] ?? parameter?.defaultValue}
                            error={formState.errors?.[parameter?.key ?? 0]?.message}
                            setValue={setValue}
                            clearErrors={clearErrors}
                            key={'p' + parameter.id}
                            tabIndex={isOpen ? undefined : -1}
                        />
                    )
                if (parameter.type && (parameter?.type === ParameterType.NUMBER || parameter.type === ParameterType.STRING))
                    return (
                        <Input
                            required={parameter.required}
                            label={parameter?.name}
                            defaultValue={parameter?.defaultValue ?? ''}
                            info={parameter?.description}
                            {...register(parameter?.key ?? '')}
                            error={formState.errors?.[parameter?.key ?? 0]?.message}
                            key={'p' + parameter.id}
                        />
                    )
                if (parameter.type === ParameterType.BOOLEAN)
                    return (
                        <div className="govuk-form-group" key={parameter.id}>
                            <div className="govuk-checkboxes govuk-checkboxes--small">
                                <CheckBox
                                    required={parameter.required}
                                    label={parameter.name ?? ''}
                                    id={parameter?.key ?? ''}
                                    info={parameter?.description}
                                    {...register(parameter?.key ?? '')}
                                    checked={String(watch(parameter?.key ?? '')) === 'true'}
                                    onChange={(e) => setValue(parameter?.key ?? '', `${e.currentTarget.checked}`, { shouldValidate: true })}
                                    error={formState.errors?.[parameter?.key ?? 0]?.message}
                                    key={'p' + parameter.id}
                                />
                            </div>
                        </div>
                    )
                if (parameter.type === ParameterType.ENUMERATE)
                    return (
                        <SimpleSelect
                            required={parameter.required}
                            id={parameter?.key}
                            name={parameter?.key ?? ''}
                            label={parameter?.name ?? ''}
                            info={parameter?.description}
                            options={loadEnumerateOptions(parameter?.metaData)}
                            defaultValue={filter?.[parameter?.key ?? 0] ?? parameter?.defaultValue}
                            error={formState.errors?.[parameter?.key ?? 0]?.message}
                            setValue={setValue}
                            clearErrors={clearErrors}
                            key={'p' + parameter.id}
                            tabIndex={isOpen ? undefined : -1}
                        />
                    )
                if (parameter?.type === ParameterType.DATE || parameter.type === ParameterType.DATE_TIME)
                    return (
                        <DateInput
                            required={parameter.required}
                            label={parameter?.name}
                            setValue={setValue}
                            type={parameter.type === ParameterType.DATE_TIME ? DateTypeEnum.DATETIME : DateTypeEnum.DATE}
                            control={control}
                            info={parameter?.description}
                            {...register(parameter?.key ?? '')}
                            error={formState.errors?.[parameter?.key ?? 0]?.message}
                            key={'p' + parameter.id}
                        />
                    )
            })}
        </>
    )
}
