import { IOption } from '@isdd/idsk-ui-kit/index'
import { User } from '@isdd/metais-common/contexts/auth/authContext'
import { formatDateTimeForDefaultValue } from '@isdd/metais-common/index'
import { TFunction } from 'i18next'
import { FieldValues } from 'react-hook-form'
import { ApiAttachment, ApiLink, ApiStandardRequestPreviewList, ApiVote, ApiVoteChoice } from '@isdd/metais-common/api/generated/standards-swagger'
import { FileUploadData } from '@isdd/metais-common/components/FileUpload/FileUpload'

import { ExistingFileData } from '@/components/views/standardization/votes/VoteComposeForm/components/ExistingFilesHandler/ExistingFilesHandler'
import { VoteStateEnum } from '@/components/views/standardization/votes/voteProps'

export const getStandardRequestOptions = (allStandardRequestDataArray: ApiStandardRequestPreviewList | undefined): IOption<number | undefined>[] => {
    return (
        allStandardRequestDataArray?.standardRequests?.map((sr) => {
            return { value: sr.id, label: sr.name ?? '' }
        }) ?? []
    )
}

export const getPageTitle = (newVote: boolean, t: TFunction) => {
    if (newVote) {
        return t('votes.voteEdit.newVoteTitle')
    }
    return `${t('votes.voteEdit.editVoteTitle')}`
}

export const getPageDocumentTitle = (newVote: boolean, t: TFunction, titleDetail: string) => {
    if (newVote) {
        return t('votes.voteEdit.newVoteTitle')
    }
    return `${t('votes.voteEdit.editVoteTitle')} ${titleDetail}`
}

export const mapUploadedFilesToApiAttachment = (uploadData: FileUploadData[]): ApiAttachment[] => {
    const filteredFilesUploadData = uploadData.filter((ud) => !ud.hasError)
    const apiAttachmentData = new Array<FileUploadData>(...filteredFilesUploadData).map((uploadedData) => {
        return {
            attachmentId: uploadedData.fileId,
            attachmentName: uploadedData.fileName,
            attachmentDescription: '',
        }
    })
    return apiAttachmentData
}

export const mapProcessedExistingFilesToApiAttachment = (data: ExistingFileData[]): ApiAttachment[] => {
    const apiAttachmentData = new Array<ExistingFileData>(...data).map((processedData) => {
        return {
            attachmentId: processedData.fileId,
            attachmentName: processedData.fileName,
            attachmentDescription: '',
        }
    })
    return apiAttachmentData
}

export const mapFormToApiRequestBody = (
    formData: FieldValues,
    editedVoteId: number | undefined,
    user: User | null,
    attachments: ApiAttachment[],
): ApiVote => {
    return {
        ...(editedVoteId && { id: editedVoteId }),
        name: formData.voteSubject,
        description: formData.voteDescription,
        createdAt: formatDateTimeForDefaultValue(`${new Date()}`, "yyyy-MM-dd'T'HH:mm:ss.SSS"),
        effectiveFrom: formData.effectiveFrom && formatDateTimeForDefaultValue(formData.effectiveFrom, "yyyy-MM-dd'T'00:00:00.000"),
        effectiveTo: formData.effectiveTo && formatDateTimeForDefaultValue(formData.effectiveTo, "yyyy-MM-dd'T'23:59:59.999"),
        voteState: VoteStateEnum.CREATED,
        secret: formData.secretVote,
        veto: formData.vetoRight,
        createdBy: user?.displayName ?? '',
        actionDesription: formData.description,
        standardRequestId: formData.standardRequest,
        voteActors: formData.invitedUsers,
        voteChoices: (formData.answerDefinitions as ApiVoteChoice[])?.filter((ad) => ad.value != null),
        attachments: attachments,
        links: (formData.documentLinks as ApiLink[])?.filter((link) => !!link),
    }
}
