import { TextBody } from '@isdd/idsk-ui-kit/index'
import { baseWikiUrl } from '@isdd/metais-common/constants'
import { useCiHook } from '@isdd/metais-common/hooks/useCi.hook'
import { QueryFeedback } from '@isdd/metais-common/index'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import sanitizeHtml from 'sanitize-html'
import { InformationGridRow } from '@isdd/metais-common/components/info-grid-row/InformationGridRow'
import { Link } from 'react-router-dom'

import { useGetEntityParamsFromUrl } from '@/componentHelpers/ci'

const fetchRenderPageHelp = async (lang: string, url: string) => {
    const response = await fetch(url, {
        headers: {
            'Accept-Language': lang,
        },
    })
    if (!response.ok) {
        throw new Error()
    }

    return response.text()
}

const DeclarationAccordionPage: React.FC = () => {
    const { entityId } = useGetEntityParamsFromUrl()
    const { i18n, t } = useTranslation()

    const { ciItemData, isLoading: isCiItemLoading, isError: isCiItemError } = useCiHook(entityId)

    const DECLARATION_URL = `${baseWikiUrl}/page/render/isvs_vyhlasenie_pristupnost?isvsCode=${ciItemData?.attributes?.Gen_Profil_kod_metais}&cached=false`

    const {
        data: htmlString,
        isError,
        isLoading,
    } = useQuery({
        queryKey: [DECLARATION_URL],
        queryFn: () => fetchRenderPageHelp(i18n.language, DECLARATION_URL),
    })

    return (
        <QueryFeedback loading={isLoading || isCiItemLoading} error={isCiItemError || isError} withChildren>
            <TextBody>
                <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(htmlString ?? '') }} />
            </TextBody>
            <InformationGridRow
                label={t('WeboveSidlo.declarationLink')}
                value={
                    <Link to={DECLARATION_URL} target="_blank">
                        {DECLARATION_URL}
                    </Link>
                }
                tooltip={t('WeboveSidlo.declarationLinkTooltip')}
                link
            />
        </QueryFeedback>
    )
}

export default DeclarationAccordionPage
