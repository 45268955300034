import { Identity, MessageGid, useFind, useFindAll11 } from '@isdd/metais-common/api/generated/iam-swagger'
import { TaskAssigneeType, TaskHistory, useCloseTask, useGetTaskById, useReassignTask } from '@isdd/metais-common/api/generated/tasks-swagger'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { useInvalidateTasksCache } from '@isdd/metais-common/hooks/invalidate-cache'
import { RouterRoutes } from '@isdd/metais-common/navigation/routeNames'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { getGidsForUserOrgRoles, getGidsForUserRoles } from '@/componentHelpers/tasks/tasks.helpers'
import { TaskDetailView } from '@/components/views/tasks/TaskDetailView'

enum ActivityType {
    TASK_CREATE = 'Novo vytvorená úloha',
    CLOSE_TASK = 'Uzatvorenie úlohy',
    USER_CHANGED = 'Zmena používatela',
}

interface ITaskDetailContainer {
    taskId: string | undefined
}

export type IGetAssignee = {
    assignee?: string
    assigneeType?: TaskAssigneeType
}

export const TaskDetailContainer: React.FC<ITaskDetailContainer> = ({ taskId }) => {
    const { t } = useTranslation()
    const {
        state: { user },
    } = useAuth()

    const [gidsData, setGidsData] = useState<MessageGid[]>()
    const { data: rolesDate, isLoading: isRolesLoading, isError: isRolesError } = useFindAll11()

    const roles = Array.isArray(rolesDate) ? rolesDate : rolesDate ? [rolesDate] : []

    const getAssignee = ({ assignee, assigneeType }: IGetAssignee) => {
        let result = assignee ?? ''
        switch (assigneeType) {
            case TaskAssigneeType.GID: {
                const gid = gidsData?.find((item) => item.gid?.gid === assignee)?.gid
                if (gid) {
                    result = `${gid.orgName} - ${gid.roleDesc}`
                }
                break
            }
            case TaskAssigneeType.ROLE_UUID:
                result = roles?.find((role) => role.uuid === assignee)?.description ?? ''
                break
        }
        return result
    }

    const historyColumns: ColumnDef<TaskHistory>[] = [
        {
            id: 'activity',
            header: t('tasks.tableHeaders.action'),
            accessorKey: 'activity',
            meta: {
                getCellContext: (ctx: CellContext<TaskHistory, unknown>) => ctx?.getValue?.(),
            },
        },
        {
            id: 'assignedTo',
            header: t('tasks.tableHeaders.assignedTo'),
            accessorKey: 'assignedTo',
            cell: (ctx) => getAssignee({ assignee: ctx.getValue() as string, assigneeType: ctx.row.original.assigneeType }),
            meta: {
                getCellContext: (ctx: CellContext<TaskHistory, unknown>) => ctx?.getValue?.(),
            },
        },
        {
            id: 'changedAt',
            header: t('tasks.tableHeaders.changedAt'),
            accessorKey: 'changedAt',
            cell: (row) => {
                return <span>{t('dateTime', { date: row.getValue() })}</span>
            },
        },
        {
            id: 'changedBy',
            header: t('tasks.tableHeaders.changedBy'),
            accessorKey: 'changedBy',
            meta: {
                getCellContext: (ctx: CellContext<TaskHistory, unknown>) => ctx?.getValue?.(),
            },
        },
    ]
    const [selectedLogin, setSelectedLogin] = useState<Identity | undefined>(undefined)

    const { isLoading, isError, data: task, refetch: refetchTask } = useGetTaskById({ id: parseInt(taskId ?? '') ?? '' })

    const gidMutation = useFind()

    useEffect(() => {
        if (task) {
            const gids = new Set<string>()
            task.taskHistoryList?.taskHistoryList?.forEach((item) => {
                if (item.assigneeType === TaskAssigneeType.GID && item.assignedTo) {
                    gids.add(item.assignedTo)
                }
            })
            if (task.assigneeType === TaskAssigneeType.GID && task.assignedTo) {
                gids?.add(task.assignedTo)
            }
            if (gids?.size > 0) {
                gidMutation.mutateAsync({ data: Array.from(gids) }).then((data) => {
                    setGidsData(data)
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [task])

    const { setIsActionSuccess } = useActionSuccess()
    const navigate = useNavigate()
    const location = useLocation()
    const closeTaskCall = useCloseTask()
    const reassignTaskCall = useReassignTask()

    const invalidateTasks = useInvalidateTasksCache()

    const closeTask = () => {
        closeTaskCall.mutate(
            {
                data: {
                    activity: ActivityType.CLOSE_TASK,
                    changedBy: user?.login,
                    id: task?.id,
                },
            },
            {
                onSuccess: async () => {
                    invalidateTasks.invalidate()
                    setIsActionSuccess({ value: true, path: `${RouterRoutes.TASKS}`, additionalInfo: { type: 'edit' } })
                    navigate(`${RouterRoutes.TASKS}`, { state: { from: location } })
                    await refetchTask()
                },
            },
        )
    }

    const reassignTask = () => {
        reassignTaskCall.mutate(
            {
                data: {
                    id: task?.id,
                    changedBy: user?.login,
                    assignedTo: selectedLogin ? selectedLogin?.login : user?.login,
                    activity: ActivityType.USER_CHANGED,
                    assignedToIds: [user?.login || '', ...getGidsForUserOrgRoles(user), ...getGidsForUserRoles(user)],
                    assigneeType: TaskAssigneeType.IDENTITY_LOGIN,
                },
            },
            {
                onSuccess: async () => {
                    setIsActionSuccess({
                        value: true,
                        path: `${RouterRoutes.TASKS}/${taskId}`,
                        additionalInfo: {
                            type: 'toUser',
                            userName:
                                (selectedLogin ? selectedLogin?.firstName + ' ' + selectedLogin?.lastName : user?.firstName + ' ' + user?.lastName) ??
                                '',
                        },
                    })
                    await refetchTask()
                },
            },
        )
    }

    return (
        <TaskDetailView
            task={task}
            isLoading={isLoading || isRolesLoading}
            isError={isError || isRolesError}
            historyColumns={historyColumns}
            closeTask={closeTask}
            reassignTask={reassignTask}
            setSelectedLogin={setSelectedLogin}
            getAssignee={getAssignee}
        />
    )
}
