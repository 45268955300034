import { PaginatorWrapper, Table } from '@isdd/idsk-ui-kit/index'
import { ApiReferenceRegisterItem } from '@isdd/metais-common/api/generated/reference-registers-swagger'
import { CellContext, ColumnDef, ExpandedState } from '@tanstack/react-table'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionsOverTable, CreateEntityButton, QueryFeedback } from '@isdd/metais-common/index'
import { FieldValues } from 'react-hook-form'
import { DEFAULT_PAGESIZE_OPTIONS } from '@isdd/metais-common/constants'
import { Can } from '@isdd/metais-common/hooks/permissions/useAbilityContext'
import { Actions } from '@isdd/metais-common/hooks/permissions/useUserAbility'

import { RefRegisterItemCreateModal } from './createView/RefRegisterItemCreateModal'

import { RefRegisterItemView } from '@/components/views/refregisters/RefRegisterItemView'
import { IRefRegisterItemsView } from '@/types/views'

export const RefRegistersItemTable = ({
    data: { refRegisterItems, referenceRegisterItemAttributes },
    setPagination,
    pagination,
    isLoading,
    isError,
    refRegUuid,
    createRefRegisterItem,
    isCreateModalOpen,
    setIsCreateModalOpen,
    updateRefRegisterItem,
    deleteRefRegisterItem,
}: IRefRegisterItemsView) => {
    const { t } = useTranslation()
    const [expanded, setExpanded] = useState<ExpandedState>({})

    const onSubmit = (formValues: FieldValues) => {
        createRefRegisterItem.mutate({
            referenceRegisterUuid: refRegUuid,
            data: {
                itemName: formValues.itemName,
                order: formValues.order,
                referenceRegisterGroup: formValues.referenceRegisterGroup,
                referenceRegisterSubGroup: formValues.referenceRegisterSubGroup,
                subjectIdentification: formValues.subjectIdentification,
                refID: formValues.refID,
                dataElementRefID: formValues.dataElementRefID,
                note: formValues.note,
                sourceReferenceHolders: formValues.sourceReferenceHolders,
            },
        })
    }

    const columns: Array<ColumnDef<ApiReferenceRegisterItem>> = [
        {
            accessorFn: (row) => row?.order,
            header: t('refRegisters.detail.items.order'),
            id: '1',
            size: 70,
        },
        {
            accessorFn: (row) => row?.itemName,
            header: t('refRegisters.detail.items.itemName'),
            id: '2',
            meta: {
                getCellContext: (ctx: CellContext<ApiReferenceRegisterItem, unknown>) => ctx?.getValue?.(),
            },
            cell: (row) => row?.getValue(),
            size: 200,
        },
        {
            accessorFn: (row) => row?.referenceRegisterGroup?.groupName,
            header: t('refRegisters.detail.items.group'),
            id: '3',
            cell: (row) => row.getValue(),
            size: 200,
        },
        {
            accessorFn: (row) => row?.referenceRegisterSubGroup?.groupName,
            header: t('refRegisters.detail.items.subGroup'),
            id: '4',
            cell: (row) => row.getValue(),
            size: 200,
        },
        {
            accessorFn: (row) => row?.subjectIdentification,
            header: t('refRegisters.detail.items.subjectIdentification'),
            id: '5',
            meta: {
                getCellContext: (ctx: CellContext<ApiReferenceRegisterItem, unknown>) => ctx?.getValue?.(),
            },
            cell: (row) => row?.getValue(),
            size: 200,
        },
        {
            accessorFn: (row) => row?.refID,
            header: t('refRegisters.detail.items.refID'),
            id: '6',
            meta: {
                getCellContext: (ctx: CellContext<ApiReferenceRegisterItem, unknown>) => ctx?.getValue?.(),
            },
            cell: (row) => row?.getValue(),
            size: 200,
        },
        {
            accessorFn: (row) => row?.dataElementRefID,
            header: t('refRegisters.detail.items.dataElementRefID'),
            id: '7',
            cell: (row) => row?.getValue(),
            size: 200,
        },
        {
            accessorFn: (row) => row?.note,
            header: t('refRegisters.detail.items.note'),
            id: '8',
            meta: {
                getCellContext: (ctx: CellContext<ApiReferenceRegisterItem, unknown>) => ctx?.getValue?.(),
            },
            cell: (row) => row?.getValue(),
            size: 200,
        },
    ]

    return (
        <QueryFeedback loading={isLoading} withChildren>
            <ActionsOverTable
                entityName="ReferenceRegisterItem"
                pagination={pagination}
                handlePagingSelect={(page) => setPagination({ ...pagination, pageSize: Number(page) || 0 })}
                hiddenButtons={{ SELECT_COLUMNS: true }}
                createButton={
                    <Can I={Actions.CREATE} a={`refRegisters.items`}>
                        <CreateEntityButton
                            label={t('codeListDetail.button.addNewItem')}
                            onClick={() => {
                                setIsCreateModalOpen(true)
                            }}
                        />
                    </Can>
                }
                pagingOptions={DEFAULT_PAGESIZE_OPTIONS}
            />
            <Table
                columns={columns}
                data={refRegisterItems?.apiReferenceRegisterItems}
                expandedRowsState={expanded}
                onExpandedChange={setExpanded}
                getExpandedRow={(row) => {
                    return (
                        <RefRegisterItemView
                            row={row?.original}
                            referenceRegisterItemAttributes={referenceRegisterItemAttributes}
                            updateRefRegisterItem={updateRefRegisterItem}
                            deleteRefRegisterItem={deleteRefRegisterItem}
                        />
                    )
                }}
                error={isError}
                isLoading={isLoading}
            />
            <PaginatorWrapper
                {...pagination}
                handlePageChange={(filter) => {
                    setPagination({ ...pagination, pageNumber: filter.pageNumber ?? 0 })
                }}
            />
            {isCreateModalOpen && (
                <RefRegisterItemCreateModal
                    isOpen={isCreateModalOpen}
                    close={() => {
                        setIsCreateModalOpen(false)
                    }}
                    onSubmit={onSubmit}
                    referenceRegisterItemAttributes={referenceRegisterItemAttributes}
                    isLoading={createRefRegisterItem.isLoading}
                    isError={createRefRegisterItem.isError}
                />
            )}
        </QueryFeedback>
    )
}
