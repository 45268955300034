import { yupResolver } from '@hookform/resolvers/yup'
import { AccordionContainer, Button, ButtonGroupRow, ButtonLink, ErrorBlock, IAccordionSection } from '@isdd/idsk-ui-kit/index'
import { ISection } from '@isdd/idsk-ui-kit/stepper/StepperSection'
import { ATTRIBUTE_NAME, PO_je_poskytovatelom_KS } from '@isdd/metais-common/api'
import { ConfigurationItemUi, GraphRequestUi, useReadRelationshipsHook, useStoreGraph } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { GidRoleData } from '@isdd/metais-common/api/generated/iam-swagger'
import { Attribute, AttributeProfile, useGenerateCodeHook, useGetRelationshipTypeHook } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { ElementToScrollTo } from '@isdd/metais-common/components/element-to-scroll-to/ElementToScrollTo'
import { ENTITY_KS, JOIN_OPERATOR, metaisEmail, PO } from '@isdd/metais-common/constants'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { useCiHook } from '@isdd/metais-common/hooks/useCi.hook'
import { useGetStatus } from '@isdd/metais-common/hooks/useGetRequestStatus'
import { MutationFeedback, QueryFeedback, SubmitWithFeedback } from '@isdd/metais-common/index'
import { Languages } from '@isdd/metais-common/localization/languages'
import { isObjectEmpty } from '@isdd/metais-common/utils/utils'
import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { FieldErrors, FieldValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { v4 as uuidV4 } from 'uuid'
import { NavigationSubRoutes } from '@isdd/metais-common/navigation/routeNames'

import { RelationStepperWrapper } from '../new-relation/RelationStepperWrapper'
import styles from '../new-relation/newRelationView.module.scss'

import { AttributeInput } from '@/components/attribute-input/AttributeInput'
import { generateFormSchema } from '@/components/create-entity/createCiEntityFormSchema'
import {
    findAttributeConstraint,
    getAttributeInputErrorMessage,
    getAttributesInputErrorMessage,
    getAttributeUnits,
} from '@/components/create-entity/createEntityHelpers'
import { useNewCiRelationHook } from '@/hooks/useNewCiRelation.hook'

interface IAddNewPORelation {
    onPrevious: () => void
    ciItemData: ConfigurationItemUi
    selectedRole: GidRoleData | null
}

export interface CiRelErrorPair {
    ci: ConfigurationItemUi
    error: string
}
export const AddNewPORelation: React.FC<IAddNewPORelation> = ({ onPrevious, ciItemData, selectedRole }) => {
    const [hasMutationError, setHasMutationError] = useState(false)
    const [hasErrors, setErrors] = useState<FieldErrors>({})
    const { gestorData } = useCiHook(ciItemData.uuid)
    const navigate = useNavigate()

    const ownerId = gestorData?.[0].gid ?? ''
    const { isActionSuccess, setIsActionSuccess } = useActionSuccess()
    const [isSuccess, setIsSuccess] = useState(false)
    const selectedRelationTypeTechnicalName = PO_je_poskytovatelom_KS

    const {
        getRequestStatus,
        isLoading: isRequestStatusLoading,
        isError: isRequestStatusError,
        isProcessedError,
        isTooManyFetchesError,
    } = useGetStatus()

    const getRelTypeByName = useGetRelationshipTypeHook()
    const readSourceRelations = useReadRelationshipsHook()
    const generateRelCode = useGenerateCodeHook()

    const { t, i18n } = useTranslation()

    const {
        data: relationData,
        isLoading: isRelationLoading,
        isError: isRelationError,
    } = useNewCiRelationHook({
        configurationItemId: ciItemData?.uuid,
        entityName: ENTITY_KS,
        tabName: PO,
        selectedRelationTypeTechName: PO_je_poskytovatelom_KS,
        ciItemData,
    })

    const relationSchema = relationData?.relationTypeData

    const relationSchemaCombinedAttributes = [...(relationSchema?.attributes ?? [])]
    const unitsData = relationData?.unitsData
    const constraintsData = useMemo(() => {
        return relationData?.constraintsData ?? []
    }, [relationData?.constraintsData])
    const generateSchema = useMemo(() => {
        return generateFormSchema(
            [relationSchema as AttributeProfile, ...(relationSchema?.attributeProfiles ?? [])],
            t,
            i18n.language,
            null,
            relationSchema?.technicalName,
            {},
            ciItemData?.uuid ?? '',
        )
    }, [i18n.language, relationSchema, ciItemData, t])

    const {
        register,
        handleSubmit: handleFormSubmit,
        formState: { errors, isSubmitted },
        setValue,
        clearErrors,
        trigger,
        control,
        getValues,
    } = useForm({
        resolver: yupResolver(generateSchema),
    })

    const [relsLoading, setRelsLoading] = useState(false)
    const [relsErrors, setRelsErrors] = useState<(CiRelErrorPair | undefined)[]>([])
    const [hasReset, setHasReset] = useState(false)
    const [waiting, setWaiting] = useState(true)
    const setRelMetaisCode = async (val: ConfigurationItemUi[]) => {
        const relTechName = relationData?.relationTypeData?.technicalName
        const hasGenRelCodeAttribute = relationSchema?.attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Gen_Profil_Rel_kod_metais)
        if (!relTechName || !hasGenRelCodeAttribute) return

        const formValues = getValues()
        val.forEach(async (item) => {
            const itemRelCodeInForm = `${ATTRIBUTE_NAME.Gen_Profil_Rel_kod_metais}${JOIN_OPERATOR}${item.uuid}`
            if (!formValues[itemRelCodeInForm]) {
                const code = await generateRelCode(relTechName)
                setValue(itemRelCodeInForm, code.code)
                setWaiting(false)
            }
        })
    }
    const formValues = getValues()
    const itemRelCodeInForm = `${ATTRIBUTE_NAME.Gen_Profil_Rel_kod_metais}${JOIN_OPERATOR}${ciItemData?.uuid}`

    useEffect(() => {
        if (!formValues[itemRelCodeInForm] && ciItemData && waiting && relationData) {
            setRelMetaisCode([ciItemData])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [relationData?.relationTypeData?.technicalName])

    const checkRels = async (
        currentCi: ConfigurationItemUi,
        selectedCi: ConfigurationItemUi,
        relTechName: string,
    ): Promise<CiRelErrorPair | undefined> => {
        setRelsErrors([])
        const relType = await getRelTypeByName(relTechName)
        const isCurrentIsTarget = relType?.targets?.map((tar) => tar.technicalName).includes(currentCi?.type)
        const error = readSourceRelations(selectedCi.uuid ?? '').then(async (selectedCiRels) => {
            const currentCiRels = await readSourceRelations(currentCi.uuid ?? '')
            setRelsLoading(false)
            if (isCurrentIsTarget) {
                const isAlsoTarget = relType?.sources?.map((tar) => tar.technicalName).includes(currentCi?.type)

                if (isAlsoTarget) {
                    const sourceSameRelsLength =
                        [...(selectedCiRels.endRelationshipSet ?? []), ...(selectedCiRels.startRelationshipSet ?? [])]
                            .map((r) => r.type)
                            .filter((e) => e === relTechName)?.length ?? 0

                    const relsMax = relType?.targetCardinality?.max ?? Infinity
                    if (sourceSameRelsLength >= relsMax) {
                        return {
                            ci: selectedCi,
                            error: t('cantGetMoreRels', { ciName: selectedCi.attributes?.Gen_Profil_nazov, relType: relType.name }),
                        } as CiRelErrorPair
                    }
                } else {
                    const targetSameRelsLength =
                        [...(currentCiRels.endRelationshipSet ?? []), ...(currentCiRels.startRelationshipSet ?? [])]
                            .map((r) => r.type)
                            .filter((e) => e === relTechName)?.length ?? 0

                    const relsMax = relType?.sourceCardinality?.max ?? Infinity
                    if (targetSameRelsLength >= relsMax) {
                        return {
                            ci: selectedCi,
                            error: t('cantGetMoreRels', { ciName: selectedCi.attributes?.Gen_Profil_nazov, relType: relType.name }),
                        } as CiRelErrorPair
                    }
                }
            } else {
                const sourceSameRelsLength =
                    [...(selectedCiRels.endRelationshipSet ?? []), ...(selectedCiRels.startRelationshipSet ?? [])]
                        .map((r) => r.type)
                        .filter((e) => e === relTechName)?.length ?? 0

                const relsMax = relType?.targetCardinality?.max ?? Infinity
                if (sourceSameRelsLength >= relsMax) {
                    return {
                        ci: selectedCi,
                        error: t('cantGetMoreRels', { ciName: selectedCi.attributes?.Gen_Profil_nazov, relType: relType.name }),
                    } as CiRelErrorPair
                } else {
                    const targetSameRelsLength =
                        [...(selectedCiRels.endRelationshipSet ?? []), ...(selectedCiRels.startRelationshipSet ?? [])]
                            .map((r) => r.type)
                            .filter((e) => e === relTechName)?.length ?? 0

                    const relMax = relType?.sourceCardinality?.max ?? Infinity
                    if (targetSameRelsLength >= relMax) {
                        return {
                            ci: selectedCi,
                            error: t('cantGetMoreRels', { ciName: selectedCi.attributes?.Gen_Profil_nazov, relType: relType.name }),
                        } as CiRelErrorPair
                    }
                }
            }
        })
        return error
    }
    const onStoreGraphSuccess = () => {
        setIsActionSuccess({ value: true, path: `/ci/KS/publish` })
        setIsSuccess(true)
    }

    const storeGraph = useStoreGraph({
        mutation: {
            onSuccess(data) {
                getRequestStatus(data.requestId ?? '', onStoreGraphSuccess)
            },
            onError() {
                setHasMutationError(true)
            },
        },
    })

    const onError = (err: FieldErrors) => {
        setErrors(err)
    }

    const handleSubmit = async (formData: FieldValues) => {
        setHasMutationError(false)
        const splittedFormData = Object.keys(formData)
            .map((key) => key.split(JOIN_OPERATOR))
            .map((item) => ({ name: item[0], id: item[1] }))

        const isRelatedEntityAsTarget =
            relationData?.relatedListAsTargets &&
            relationData?.relatedListAsTargets.find((data) => data.relationshipTypeTechnicalName === selectedRelationTypeTechnicalName)

        const profileAtt: Attribute[] = []

        const first =
            relationSchema?.attributeProfiles && Array.isArray(relationSchema?.attributeProfiles)
                ? relationSchema?.attributeProfiles.map((profile: AttributeProfile) => {
                      profile.attributes?.map((att: Attribute) => {
                          profileAtt.push({
                              ...splittedFormData
                                  .filter((key) => key.name === att.technicalName ?? '')
                                  .map((key) => {
                                      const value = getValues(key.name + JOIN_OPERATOR + key.id + JOIN_OPERATOR + ciItemData?.uuid)
                                      return {
                                          name: key.name,
                                          value: value === '' ? null : value,
                                      }
                                  })[0],
                          })
                      })

                      return {
                          type: selectedRelationTypeTechnicalName,
                          attributes: [
                              ...splittedFormData
                                  .filter((key) => key.id == ciItemData?.uuid)
                                  .map((key) => ({ name: key.name, value: formData[key.name + JOIN_OPERATOR + key.id] })),
                              ...profileAtt.filter((profAtt) => !isObjectEmpty(profAtt)),
                          ],
                          //uuid of picked entities
                          startUuid: isRelatedEntityAsTarget ? ciItemData?.uuid : gestorData?.[0].configurationItemUi?.uuid,
                          //id of current entity
                          endUuid: isRelatedEntityAsTarget ? gestorData?.[0].configurationItemUi?.uuid : ciItemData?.uuid,
                          //from getGroup Api
                          owner: ownerId,
                          uuid: uuidV4(),
                      }
                  })
                : []

        const data = {
            storeSet: {
                relationshipSet: [Array.isArray(first) ? first[first.length - 1] : first],
            },
        } as GraphRequestUi

        if (gestorData?.[0].configurationItemUi) {
            setRelsLoading(true)
            const error = await checkRels(ciItemData, gestorData[0].configurationItemUi, selectedRelationTypeTechnicalName)
            if (error) {
                setRelsErrors([error])
            }
        }
        setIsSuccess(false)
        storeGraph.mutateAsync({ data })
    }

    const getSections = (itemId: string): ISection[] => {
        return relationSchema?.attributeProfiles && Array.isArray(relationSchema?.attributeProfiles)
            ? relationSchema?.attributeProfiles?.map((profile: AttributeProfile, index) => {
                  return {
                      title: (i18n.language === Languages.SLOVAK ? profile.description : profile.engDescription) ?? profile.name ?? '',
                      error: getAttributesInputErrorMessage(profile.attributes ?? [], errors),
                      stepLabel: { label: (index + 1).toString(), variant: 'circle' },
                      id: profile.id + JOIN_OPERATOR + itemId,
                      last: relationSchema?.attributeProfiles?.length === index + 1 ? true : false,
                      isOpen: true,
                      content: profile.attributes?.map((attribute) => {
                          const nameSuffix = JOIN_OPERATOR + profile.id + JOIN_OPERATOR + itemId
                          if (attribute.technicalName === 'KS_Profil_PO_UPVS_stav_publikovania') {
                              attribute.defaultValue = 'c_stav_publikovania_sluzby.2'
                              if (!getValues(attribute.technicalName + nameSuffix)) {
                                  setValue(attribute.technicalName + nameSuffix, 'c_stav_publikovania_sluzby.2')
                              }
                          }
                          return (
                              attribute?.valid &&
                              !attribute.invisible && (
                                  <AttributeInput
                                      key={`${attribute?.id}+${profile.id}+${itemId}`}
                                      attribute={attribute ?? {}}
                                      register={register}
                                      setValue={setValue}
                                      clearErrors={clearErrors}
                                      trigger={trigger}
                                      isSubmitted={isSubmitted}
                                      error={getAttributeInputErrorMessage(attribute ?? {}, errors, nameSuffix)}
                                      nameSuffix={nameSuffix}
                                      hasResetState={{ hasReset, setHasReset }}
                                      constraints={findAttributeConstraint(
                                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                          //@ts-ignore
                                          attribute?.constraints?.map((att: AttributeConstraintEnumAllOf) => att.enumCode ?? '') ?? [],
                                          constraintsData,
                                      )}
                                      unitsData={attribute?.units ? getAttributeUnits(attribute.units ?? '', unitsData) : undefined}
                                      control={control}
                                  />
                              )
                          )
                      }),
                  }
              })
            : []
    }

    const sectionsNew = () => {
        const relsError = !!relsErrors.find((re) => re?.ci.uuid == ciItemData?.uuid)?.error
        const isAccordionSectionError = Object.keys(errors).find((i) => i.includes(ciItemData?.uuid ?? ''))
        return {
            error: relsError || !!isAccordionSectionError,
            title: ciItemData?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov],
            onLoadOpen: true,
            summary: (
                <ButtonGroupRow key={ciItemData?.uuid} className={styles.accordionButtonDiv}>
                    <ButtonLink
                        type="button"
                        label={t('newRelation.detailButton')}
                        className={classNames(styles.buttonLink, styles.blue)}
                        onClick={() => {
                            window.open(`/ci/PO/${ciItemData?.uuid}`, '_blank')
                        }}
                    />
                </ButtonGroupRow>
            ),
            content: (
                <>
                    {relationSchemaCombinedAttributes.map(
                        (attribute) =>
                            attribute?.valid &&
                            !attribute.invisible && (
                                <AttributeInput
                                    key={`${attribute?.id}+${ciItemData?.uuid}`}
                                    attribute={attribute ?? {}}
                                    register={register}
                                    setValue={setValue}
                                    clearErrors={clearErrors}
                                    trigger={trigger}
                                    isSubmitted={isSubmitted}
                                    error={getAttributeInputErrorMessage(attribute ?? {}, errors)}
                                    nameSuffix={JOIN_OPERATOR + ciItemData?.uuid}
                                    hint={attribute?.description}
                                    hasResetState={{ hasReset, setHasReset }}
                                    constraints={findAttributeConstraint(
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        //@ts-ignore
                                        attribute?.constraints?.map((att: AttributeConstraintEnumAllOf) => att.enumCode ?? '') ?? [],
                                        constraintsData,
                                    )}
                                    unitsData={attribute?.units ? getAttributeUnits(attribute.units ?? '', unitsData) : undefined}
                                    control={control}
                                    disabled={attribute.technicalName == ATTRIBUTE_NAME.Gen_Profil_Rel_kod_metais}
                                />
                            ),
                    )}
                    <RelationStepperWrapper data={getSections(ciItemData?.uuid ?? '')} errors={hasErrors} setErrors={setErrors} />
                </>
            ),
        } as IAccordionSection
    }

    return (
        <>
            <QueryFeedback loading={relsLoading || storeGraph.isLoading || relsLoading || isRelationLoading || isRequestStatusLoading} withChildren>
                <ElementToScrollTo
                    trigger={
                        storeGraph.isError ||
                        isProcessedError ||
                        isTooManyFetchesError ||
                        isActionSuccess.value ||
                        isRequestStatusError ||
                        isRelationError
                    }
                    manualScroll
                >
                    <MutationFeedback
                        error={storeGraph.isError || isRequestStatusError || isRelationError}
                        mutationProcessingError={isProcessedError}
                        mutationTooLong={isTooManyFetchesError}
                        success={isActionSuccess.value}
                    />
                </ElementToScrollTo>
                {relsErrors
                    .filter((e) => !!e)
                    .map((e, index) => (
                        <MutationFeedback key={e?.error ?? '' + index} error={relsErrors.length > 0} errorMessage={e?.error} />
                    ))}

                <form onSubmit={handleFormSubmit(handleSubmit, onError)} noValidate>
                    {!isSuccess && ciItemData && <AccordionContainer sections={[sectionsNew()]} />}
                    {hasMutationError && (
                        <ErrorBlock
                            errorTitle={t('newRelation.errorTitle')}
                            errorMessage={
                                <>
                                    {t('newRelation.errorMessage')}
                                    <Link className="govuk-link" state={{ from: location }} to={`mailto:${metaisEmail}`}>
                                        {metaisEmail}
                                    </Link>
                                </>
                            }
                        />
                    )}

                    {!isSuccess && (
                        <SubmitWithFeedback
                            submitButtonLabel={t('newRelation.save')}
                            loading={storeGraph.isLoading}
                            disabled={!selectedRole?.roleUuid || (relsErrors && relsErrors.filter((e) => !!e).length > 0)}
                        />
                    )}
                </form>
                <ButtonGroupRow>
                    <Button
                        label={isSuccess ? t('KSPublishHelper.backToList') : t('form.back')}
                        onClick={() => (isSuccess ? navigate(NavigationSubRoutes.KONCOVE_SLUZBY) : onPrevious())}
                        variant="secondary"
                    />
                </ButtonGroupRow>
            </QueryFeedback>
        </>
    )
}
