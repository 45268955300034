import React, { SetStateAction } from 'react'
import classNames from 'classnames'
import { ArrowDownIcon, TextBody } from '@isdd/idsk-ui-kit/index'
import { useTranslation } from 'react-i18next'

import styles from '@isdd/metais-common/components/GridView.module.scss'

interface Props {
    isSidebarExpanded: boolean
    setIsSidebarExpanded: React.Dispatch<SetStateAction<boolean>>
    controlsId: string
}

export const SidebarButton = ({ isSidebarExpanded, setIsSidebarExpanded, controlsId }: Props) => {
    const { t } = useTranslation()
    return (
        <button
            className={styles.closeSidebarButton}
            onClick={() => setIsSidebarExpanded(!isSidebarExpanded)}
            aria-expanded={isSidebarExpanded}
            aria-controls={controlsId}
            aria-label={isSidebarExpanded ? t('sidebar.hideAriaLabel') : t('sidebar.unhideAriaLabel')}
        >
            <div aria-hidden className={styles.rotate}>
                <TextBody className={classNames('marginBottom0', [styles.fontSize14])} size="S">
                    {isSidebarExpanded ? t('sidebar.hide') : t('sidebar.unhide')}
                </TextBody>
            </div>
            <img src={ArrowDownIcon} alt="" className={classNames(!isSidebarExpanded ? styles.rotate : styles.rotateClockWise)} />
        </button>
    )
}
