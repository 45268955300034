import { TFunction } from 'i18next'
import { object, string } from 'yup'

export enum GroupFormEnum {
    NAME = 'name',
    SHORT_NAME = 'shortName',
    DESCRIPTION = 'description',
    USER = 'user',
    ORGANIZATION = 'organization',
}

export const createGroupSchema = (t: TFunction<'translation', undefined, 'translation'>) => {
    return object().shape({
        [GroupFormEnum.NAME]: string().required(t('validation.required')),
        [GroupFormEnum.SHORT_NAME]: string().required(t('validation.required')),
        [GroupFormEnum.DESCRIPTION]: string().required(t('validation.required')),
        [GroupFormEnum.USER]: string().required(t('validation.required')),
        [GroupFormEnum.ORGANIZATION]: string().required(t('validation.required')),
    })
}

export const editGroupSchema = (t: TFunction<'translation', undefined, 'translation'>) => {
    return object().shape({
        [GroupFormEnum.NAME]: string().required(t('validation.required')),
        [GroupFormEnum.SHORT_NAME]: string().required(t('validation.required')),
        [GroupFormEnum.DESCRIPTION]: string().required(t('validation.required')),
        [GroupFormEnum.USER]: string(),
        [GroupFormEnum.ORGANIZATION]: string(),
    })
}
