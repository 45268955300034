import { useListCiTypes1Hook } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { OTHER_ENTITY } from '@isdd/metais-common/index'
import { NavigationSubItem } from '@isdd/metais-common/navigation/routeNames'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

export const useOtherEntityHook = () => {
    const { i18n } = useTranslation()
    const listCiTypes = useListCiTypes1Hook()
    const { data: ciOtherEntity } = useQuery({
        queryFn: () => listCiTypes({ labels: [OTHER_ENTITY] }),
        queryKey: ['listCiTypes'],
    })

    const ciOtherEntityItems: NavigationSubItem[] =
        ciOtherEntity?.results?.map((item) => ({
            title: i18n.language === 'en' ? item.engName ?? '' : item.name ?? '',
            path: `/ci/${item.technicalName}`,
        })) ?? []

    return {
        ciOtherEntityItems,
    }
}
