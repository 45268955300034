import { useEffect, useState } from 'react'

import { useWindowSize } from './useWindowSize'
interface IWidthBreakpoints {
    mobile: boolean
    tablet: boolean
    desktop: boolean
}

export const useWindowWidthBreakpoints = (): IWidthBreakpoints | undefined => {
    const windowSize = useWindowSize()
    const [breakpoints, setBreakpoints] = useState<IWidthBreakpoints>({
        mobile: true,
        tablet: true,
        desktop: true,
    })

    useEffect(() => {
        if (windowSize.width == null) {
            return undefined
        }
        const width = windowSize.width
        const newSize = {
            mobile: width >= 320,
            tablet: width >= 641,
            desktop: width >= 769,
        }
        if (breakpoints.desktop !== newSize.desktop || breakpoints.tablet !== newSize.tablet || breakpoints.mobile !== newSize.mobile) {
            setBreakpoints(newSize)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowSize.width])

    return breakpoints
}
