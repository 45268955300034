import React from 'react'

export const SearchIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18">
            <path
                d="M12.5001 10.9996H11.7101L11.4301 10.7296C12.6301 9.32965 13.2501 7.41965 12.9101 5.38965C12.4401 2.60965 10.1201 0.389649 7.32014 0.049649C3.09014 -0.470351 -0.469863 3.08965 0.0501373 7.31965C0.390137 10.1196 2.61014 12.4396 5.39014 12.9096C7.42014 13.2496 9.33014 12.6296 10.7301 11.4296L11.0001 11.7096V12.4996L15.2501 16.7496C15.6601 17.1596 16.3301 17.1596 16.7401 16.7496C17.1501 16.3396 17.1501 15.6696 16.7401 15.2596L12.5001 10.9996ZM6.50014 10.9996C4.01014 10.9996 2.00014 8.98965 2.00014 6.49965C2.00014 4.00965 4.01014 1.99965 6.50014 1.99965C8.99014 1.99965 11.0001 4.00965 11.0001 6.49965C11.0001 8.98965 8.99014 10.9996 6.50014 10.9996Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth={0.5}
            />
        </svg>
    )
}
