import { Button, RadioButton, RadioGroup, TextArea } from '@isdd/idsk-ui-kit/index'
import { ApiError } from '@isdd/metais-common/api/generated/iam-swagger'
import { ApiVote } from '@isdd/metais-common/api/generated/standards-swagger'
import { useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { MutationFeedback, QueryFeedback } from '@isdd/metais-common/index'
import { getErrorTranslateKey } from '@isdd/metais-common/utils/errorMapper'
import classNames from 'classnames'
import { useCallback, useMemo, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ROLES } from '@isdd/metais-common/constants'

import { VoteStateOptionEnum, getVoteStateEnum } from '../voteProps'

interface ICastVote {
    voteProcessing: boolean
    voteData: ApiVote | undefined
    handleCastVote: (choiceId: number, description: string) => void
    handleVetoVote: (description: string | undefined) => Promise<void>
    canCast: boolean
    canVeto: boolean
    castedVoteId: number | null
    vetoed: boolean
    canSendNote: boolean
    handleSendDescription: (description: string) => Promise<void>
    cancelState: boolean
    votesProcessingError?: ApiError
    setVotesProcessingError: React.Dispatch<React.SetStateAction<ApiError | undefined>>
    voted: boolean
    setVoted: React.Dispatch<React.SetStateAction<boolean>>
    castVoteMessage: string
    handleSummarizeVote: (description: string) => Promise<void>
}

interface IChoise {
    id: number
    value: string
    description: string
    isVeto: boolean
    disabled: boolean
}

const VETO_VOTE_ID = -1

export const VotesHandler: React.FC<ICastVote> = ({
    voteData,
    handleCastVote,
    handleVetoVote,
    canCast,
    canVeto,
    castedVoteId,
    voteProcessing,
    vetoed,
    canSendNote,
    handleSendDescription,
    cancelState,
    votesProcessingError,
    setVotesProcessingError,
    voted,
    setVoted,
    castVoteMessage,
    handleSummarizeVote,
}) => {
    const { t } = useTranslation()
    const {
        state: { user },
    } = useAuth()
    const voteState = useMemo(() => {
        return getVoteStateEnum(voteData?.voteState, voteData?.effectiveFrom ?? '', voteData?.effectiveTo ?? '')
    }, [voteData?.effectiveFrom, voteData?.effectiveTo, voteData?.voteState])
    const canSummarizeVote = voteData?.createdBy === user?.login || user?.roles.includes(ROLES.STD_KOORDINATOR_AGENDY)
    const pastState = voteState === VoteStateOptionEnum.ENDED
    const nowState = voteState === VoteStateOptionEnum.UPCOMING
    const plannedState = voteState === VoteStateOptionEnum.PLANNED
    const isUserLogged = !!user
    const { register, handleSubmit } = useForm()
    const searchParams = new URLSearchParams(window.location.search)
    const token = searchParams.get('token')
    const alreadyVoted = !!castedVoteId
    const [sentNoteMessage, setSentNoteMessage] = useState(false)
    const [sentSummarize, setSentSummarize] = useState(false)
    const voteChoicesFactory = useCallback(
        (voteApiData: ApiVote | undefined, canDoCast: boolean, canDoVeto: boolean): Array<IChoise> => {
            const voteChoicesFromApi = voteApiData?.voteChoices?.map((choice, index) => {
                const choiceData: IChoise = {
                    id: choice.id ?? index,
                    value: choice.value ?? '',
                    description: choice.description ?? '',
                    isVeto: false,
                    disabled: !canDoCast || alreadyVoted,
                }
                return choiceData
            })
            if (!canDoVeto) {
                return voteChoicesFromApi ?? []
            }

            const vetoChoiceData: IChoise = {
                id: VETO_VOTE_ID,
                value: t('votes.voteDetail.voteVetoChoiceLabel'),
                description: 'veto',
                isVeto: true,
                disabled: !canDoVeto || alreadyVoted,
            }
            const voteHandlingChoicesData = voteChoicesFromApi?.concat(vetoChoiceData)
            return voteHandlingChoicesData ?? []
        },
        [alreadyVoted, t],
    )

    const voteChoicesData = useMemo((): IChoise[] => {
        return voteChoicesFactory(voteData, canCast, canVeto)
    }, [canCast, canVeto, voteChoicesFactory, voteData])

    const onSubmit = async (formData: FieldValues) => {
        if (voteData === undefined || voteData?.id === undefined) {
            return
        }

        const choiceId: number | undefined | null = formData['voteChoice']
        const choiceDescription: string | undefined = formData['voteDescription']
        const voteSummarize: string | undefined = formData['voteSummarize']

        if (canSendNote) {
            if (choiceDescription) {
                handleSendDescription(choiceDescription).then(() => {
                    setVoted(true)
                    setSentNoteMessage(true)
                })
            }
        }

        if (pastState) {
            try {
                if (voteSummarize) await handleSummarizeVote(voteSummarize)
                setVoted(true)
                setSentSummarize(true)
                return
            } catch {
                setVotesProcessingError({ message: t('votes.actions.failedToSend') })
            }
        }
        if (choiceId == undefined || voted) {
            return
        }
        const isVeto = choiceId == VETO_VOTE_ID

        if (isVeto) {
            handleVetoVote(choiceDescription)
        } else {
            handleCastVote(choiceId, choiceDescription ?? '')
        }
    }
    const errorMessage = getErrorTranslateKey(votesProcessingError)

    return (
        <QueryFeedback
            withChildren
            loading={voteProcessing}
            indicatorProps={{ transparentMask: true, layer: 'dialog', label: t('votes.voteDetail.voteProcessing') }}
        >
            <MutationFeedback
                success={voted && !votesProcessingError}
                error={!!votesProcessingError}
                errorMessage={errorMessage && t(errorMessage)}
                successMessage={
                    token
                        ? sentNoteMessage
                            ? t('votes.actions.sentNote')
                            : castVoteMessage
                        : sentSummarize
                        ? t('votes.actions.sentSummarize')
                        : t('votes.actions.sent')
                }
                onMessageClose={() => setVotesProcessingError(undefined)}
            />
            <form onSubmit={handleSubmit(onSubmit)} className={classNames('govuk-!-font-size-19')} noValidate>
                <RadioGroup
                    legend={t('votes.voteDetail.votesHandlingRadioLabel')}
                    hint={
                        cancelState
                            ? t('votes.voteDetail.voteChoiceLabel.cannotCast')
                            : vetoed
                            ? t('votes.voteDetail.vetoed')
                            : canCast
                            ? alreadyVoted
                                ? t('votes.voteDetail.voteChoiceLabel.alreadyVoted')
                                : t('votes.voteDetail.voteChoiceLabel.canCast')
                            : t('votes.voteDetail.voteChoiceLabel.cannotCast')
                    }
                >
                    {voteChoicesData.map((choice) => {
                        return (
                            <RadioButton
                                key={choice.id}
                                id={choice.id.toString()}
                                value={choice.id}
                                label={choice.value ?? ''}
                                {...register('voteChoice')}
                                disabled={choice.disabled || voted || vetoed || cancelState || plannedState}
                                defaultChecked={choice.id == castedVoteId}
                            />
                        )
                    })}
                </RadioGroup>
                {((canCast && !vetoed && isUserLogged && (!alreadyVoted || token)) || (!isUserLogged && canSendNote && voted)) &&
                    nowState &&
                    !sentNoteMessage && (
                        <>
                            <TextArea rows={3} label={t('votes.voteDetail.description')} {...register('voteDescription')} />
                            <Button type="submit" label={canSendNote ? t('votes.voteDetail.save') : t('votes.voteDetail.submitVote')} />
                        </>
                    )}
                {pastState && isUserLogged && canSummarizeVote && !vetoed && (
                    <>
                        <TextArea rows={3} label={t('votes.voteDetail.summarize')} {...register('voteSummarize')} />
                        <Button type="submit" label={t('votes.voteDetail.saveSummarize')} />
                    </>
                )}
            </form>
        </QueryFeedback>
    )
}
