import { BaseModal, LoadingIndicator } from '@isdd/idsk-ui-kit/index'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UploadedUppyFile } from '@uppy/core'

import { FileImportView } from './FileImportView'

import { useGetProgress } from '@isdd/metais-common/hooks/useGetRequestProgress'
import { ErrorDetailUi, HierarchyRightsUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { GidRoleData } from '@isdd/metais-common/api/generated/iam-swagger'
import { FileImportStepEnum } from '@isdd/metais-common/components/actions-over-table/ActionsOverTable'
import { useUppy } from '@isdd/metais-common/hooks/useUppy'
import { FileImportEditOptions } from '@isdd/metais-common/components/file-import/FileImportHeader'
import { sanitizeErrorTranslateString } from '@isdd/metais-common/utils/utils'

interface IFileImport {
    allowedFileTypes: string[]
    multiple: boolean
    endpointUrl: string
    //in bytes
    maxFileSize?: number
    isOpen: boolean
    close: () => void
    fileImportStep: FileImportStepEnum
    setFileImportStep: (value: FileImportStepEnum) => void
    ciType: string
    checkRequestId?: boolean
    doCleanUp?: boolean
}
export enum FileState {
    VALIDATED = 'validated',
    INVALIDATED = 'invalidated',
}

export const FileImport: React.FC<IFileImport> = ({
    allowedFileTypes,
    isOpen,
    close,
    multiple,
    endpointUrl,
    fileImportStep,
    setFileImportStep,
    maxFileSize = 20_971_520,
    ciType,
    checkRequestId,
    doCleanUp,
}) => {
    const { t } = useTranslation()

    const [radioButtonMetaData, setRadioButtonMetaData] = useState<FileImportEditOptions>(FileImportEditOptions.EXISTING_ONLY)

    const [selectedRole, setSelectedRole] = useState<GidRoleData | null>(null)
    const [selectedOrg, setSelectedOrg] = useState<HierarchyRightsUi | null>(null)
    const [customLoading, setCustomLoading] = useState(false)
    const { getRequestStatus, isTooManyFetchesError, isError, isLoading, resetErrorMessages, resetError } = useGetProgress('IMPORT')
    const {
        uppy,
        addGeneralErrorMessage,
        removeGeneralErrorMessages,
        currentFiles,
        handleRemoveFile,
        handleUpload,
        generalErrorMessages,
        cancelImport,
        uploadFilesStatus,
        updateUploadFilesStatus,
        isGettingProgress,
    } = useUppy({
        doCleanUp,
        maxFileSize,
        allowedFileTypes,
        multiple,
        endpointUrl,
        setFileImportStep,
        fileImportStep,
        checkRequestId,
        abortGetProgress: isOpen ? false : true,
    })

    const resolveValidationError = useCallback(
        (msg?: string, detail?: ErrorDetailUi) => {
            switch (msg) {
                case 'PROCESSING_ERROR':
                    return t('errors.import.filetype')
                case 'MANDATORY':
                    return t('errors.import.mandatory')
                case 'CREATE_CI':
                    if (detail?.description?.includes('owner')) {
                        return t('errors.import.missingOwner')
                    }
                    return detail?.description
                case 'ENUM':
                    if (detail?.description?.includes("doesn't exist in") && detail?.description?.includes('enum repository')) {
                        const regexPattern = /Attribute value (.+?) doesn't exist in (.+?) enum repository/g
                        const match = regexPattern.exec(detail?.description ?? '')
                        if (match) {
                            const attributeValue = match[1]
                            const enumRepository = match[2]
                            return t('errors.import.badEnumValue', { param: attributeValue, enum: enumRepository })
                        }
                    }
                    return detail?.description
                case 'REGEX':
                    if (detail?.description?.includes("doesn't match regex pattern constrain")) {
                        const regexPattern = /Attribute value (.+?) doesn't match regex pattern constrain/g
                        const match = regexPattern.exec(detail?.description ?? '')
                        if (match) {
                            const attributeValue = match[1]
                            return t('errors.import.badRegex', { value: attributeValue, regex: detail.regex })
                        }
                    }
                    return detail?.description
                default:
                    return detail?.description
            }
        },
        [t],
    )

    const handleValidate = useCallback(async () => {
        resetErrorMessages()
        resetError()
        const validFiles = new Set<UploadedUppyFile<Record<string, unknown>, Record<string, unknown>>>()
        uppy.setMeta({ editType: radioButtonMetaData, ciType: ciType, poId: selectedOrg?.poUUID, roleId: selectedRole?.roleUuid })
        try {
            setCustomLoading(true)
            uppy.upload().then(async (result) => {
                Promise.all(
                    result.successful.map(async (file) => {
                        await getRequestStatus(
                            file.response?.body.id as string,
                            () => {
                                validFiles.add(file)
                                updateUploadFilesStatus(file, true)
                            },
                            (errors) => {
                                const isArray = Array.isArray(errors)
                                const errorMsg = isArray ? '' : sanitizeErrorTranslateString(errors as string)
                                if (errors) {
                                    updateUploadFilesStatus(
                                        file,
                                        false,
                                        isArray
                                            ? errors.map(
                                                  (errAsArray) =>
                                                      `${errAsArray?.itemName ? errAsArray?.itemName + ': ' : ''} ${resolveValidationError(
                                                          errAsArray?.errorType,
                                                          errAsArray.errorDetail,
                                                      )} - ${errAsArray?.attributeName ?? ''} `,
                                              )
                                            : [t('validation.' + errorMsg)],
                                    )
                                }
                            },
                        )
                    }),
                ).then(() => {
                    if (validFiles && validFiles.size == result.successful.length && uploadFilesStatus.every((fs) => fs.isUploaded)) {
                        setFileImportStep(FileImportStepEnum.IMPORT)
                    }
                })

                result.failed.forEach((item) => {
                    updateUploadFilesStatus(item, false, [item.error])
                })
            })
            setCustomLoading(false)
        } catch (error) {
            addGeneralErrorMessage(t('fileImport.uploadFailed'))
        }
    }, [
        resetErrorMessages,
        uppy,
        radioButtonMetaData,
        ciType,
        selectedOrg?.poUUID,
        selectedRole?.roleUuid,
        getRequestStatus,
        updateUploadFilesStatus,
        resolveValidationError,
        uploadFilesStatus,
        setFileImportStep,
        addGeneralErrorMessage,
        t,
        resetError,
    ])

    const handleCancelImport = () => {
        cancelImport()
        setRadioButtonMetaData(FileImportEditOptions.EXISTING_ONLY)
        close()
        resetError()
        resetErrorMessages()
    }
    const handleImport = () => {
        return fileImportStep === FileImportStepEnum.VALIDATE ? handleValidate : handleUpload
    }

    return (
        <BaseModal isOpen={isOpen} close={handleCancelImport}>
            {(isLoading || isGettingProgress || customLoading) && <LoadingIndicator />}
            <FileImportView
                isGettingStatus={isLoading}
                isError={isTooManyFetchesError || isError}
                uppy={uppy}
                currentFiles={currentFiles}
                handleImport={handleImport()}
                uploadFilesStatus={uploadFilesStatus}
                handleCancelImport={handleCancelImport}
                handleRemoveFile={handleRemoveFile}
                handleRemoveErrorMessage={removeGeneralErrorMessages}
                setRadioButtonMetaData={setRadioButtonMetaData}
                generalErrorMessages={generalErrorMessages}
                fileImportStep={fileImportStep}
                radioButtonMetaData={radioButtonMetaData}
                ciType={ciType}
                setSelectedRole={setSelectedRole}
                setSelectedOrg={setSelectedOrg}
                selectedOrg={selectedOrg}
                selectedRole={selectedRole ?? {}}
            />
        </BaseModal>
    )
}
