import { BaseModal, Input, TextArea, TextHeading } from '@isdd/idsk-ui-kit/index'
import { Attribute } from '@isdd/metais-common/api/generated/types-repo-swagger'
import React from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ModalButtons, QueryFeedback } from '@isdd/metais-common/index'
import { yupResolver } from '@hookform/resolvers/yup'

import { RefRegisterItemViewItemLazySelects } from '@/components/views/refregisters/itemTypes/RefRegisterItemViewItemLazySelects'
import { createRefRegItemSchema } from '@/components/views/refregisters/schema'
import { RefRegisterItemItems, RefRegisterItemItemsFieldNames } from '@/types/filters'
import { onKeyDownOfNumberInput } from '@/components/attribute-input/attributeInputHelpers'

interface RefRegisterItemCreateProps {
    isOpen: boolean
    close: () => void
    onSubmit: (formValues: FieldValues) => void
    referenceRegisterItemAttributes: Attribute[] | undefined
    isLoading: boolean
    isError: boolean
}

export const RefRegisterItemCreateModal: React.FC<RefRegisterItemCreateProps> = ({
    isOpen,
    close,
    onSubmit,
    referenceRegisterItemAttributes,
    isLoading,
    isError,
}) => {
    const { t } = useTranslation()
    const { register, handleSubmit, setValue, watch, formState } = useForm({ resolver: yupResolver(createRefRegItemSchema(t)) })

    const refRegItemAttrPrefix = 'ReferenceRegisterItem_Profile_'
    const getTooltipOfRow = (refRegisterAttribute: string) => {
        return (
            referenceRegisterItemAttributes?.find((val) => val?.technicalName === `${refRegItemAttrPrefix}${refRegisterAttribute}`)?.description ?? ''
        )
    }

    const sourceReferenceHolders = watch('sourceReferenceHolders')

    return (
        <BaseModal isOpen={isOpen} close={close}>
            <TextHeading size="L">{t('refRegisters.detail.items.addNewItem')}</TextHeading>
            <QueryFeedback loading={isLoading} error={isError} withChildren indicatorProps={{ label: t('createEntity.redirectLoading') }}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Input
                        required
                        {...register(RefRegisterItemItemsFieldNames.ITEM_NAME)}
                        label={t('refRegisters.detail.items.itemName')}
                        info={getTooltipOfRow(RefRegisterItemItems.NAME)}
                        error={formState.errors?.[RefRegisterItemItemsFieldNames.ITEM_NAME]?.message}
                        isInModal
                    />
                    <Input
                        required
                        type="number"
                        {...register(RefRegisterItemItemsFieldNames.ORDER)}
                        label={t('refRegisters.detail.items.order')}
                        info={`${getTooltipOfRow(RefRegisterItemItems.ORDER)} (${t('input.number.hint')})`}
                        error={formState.errors?.[RefRegisterItemItemsFieldNames.ORDER]?.message}
                        isInModal
                        onKeyDown={onKeyDownOfNumberInput}
                    />
                    <Input
                        {...register(RefRegisterItemItemsFieldNames.GROUP)}
                        label={t('refRegisters.detail.items.group')}
                        info={getTooltipOfRow(RefRegisterItemItems.GROUP)}
                        isInModal
                    />
                    <Input
                        {...register(RefRegisterItemItemsFieldNames.SUB_GROUP_NAME)}
                        label={t('refRegisters.detail.items.subGroup')}
                        info={getTooltipOfRow(RefRegisterItemItems.SUB_GROUP_NAME)}
                        isInModal
                    />
                    <TextArea
                        {...register(RefRegisterItemItemsFieldNames.SUBJECT_IDENTIFICATION)}
                        label={t('refRegisters.detail.items.subjectIdentification')}
                        info={getTooltipOfRow(RefRegisterItemItems.SUBJECT_IDENTIFICATIONS)}
                        rows={3}
                        isInModal
                    />
                    <RefRegisterItemViewItemLazySelects
                        isChangeMode
                        setValue={setValue}
                        formValue={sourceReferenceHolders}
                        value={[]}
                        name={RefRegisterItemItemsFieldNames.sourceReferenceHolders}
                        label={t('refRegisters.detail.items.sourceElementHolders')}
                        tooltip={getTooltipOfRow(RefRegisterItemItems.sourceElementHolders)}
                    />
                    <TextArea
                        {...register(RefRegisterItemItemsFieldNames.REF_ID)}
                        label={t('refRegisters.detail.items.refID')}
                        info={getTooltipOfRow(RefRegisterItemItems.REF_ID)}
                        rows={3}
                        isInModal
                    />
                    <TextArea
                        {...register(RefRegisterItemItemsFieldNames.DATA_ELEMENT_REF_ID)}
                        label={t('refRegisters.detail.items.dataElementRefID')}
                        info={getTooltipOfRow(RefRegisterItemItems.DATA_ELEMENT_REF_ID)}
                        rows={3}
                        isInModal
                    />
                    <TextArea
                        {...register(RefRegisterItemItemsFieldNames.NOTE)}
                        label={t('refRegisters.detail.items.note')}
                        info={getTooltipOfRow(RefRegisterItemItems.NOTE)}
                        rows={3}
                        isInModal
                    />
                    <ModalButtons submitButtonLabel={t('codeListDetail.button.save')} closeButtonLabel={t('form.cancel')} onClose={close} />
                </form>
            </QueryFeedback>
        </BaseModal>
    )
}
