import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useMatch } from 'react-router-dom'
import classNames from 'classnames'

import styles from '@isdd/metais-common/components/navbar/navbar.module.scss'
import { NavigationSubItem } from '@isdd/metais-common/navigation/routeNames'

interface INavMenuSubItem {
    item: NavigationSubItem
    closeSubmenu: () => void
}

export const NavMenuSubItem: React.FC<INavMenuSubItem> = ({ item, closeSubmenu }) => {
    const { t } = useTranslation()

    const isUrlMatched = !!useMatch(item.path)
    const location = useLocation()

    return (
        <li className="idsk-header-web__nav-submenu-list-item">
            <Link
                className={classNames('govuk-link', 'idsk-header-web__nav-submenu-list-item-link', styles.submenuLink)}
                state={{ from: location }}
                to={item.path}
                aria-current={isUrlMatched ? 'page' : undefined}
                onClick={(event) => {
                    event.stopPropagation()
                    closeSubmenu()
                }}
                {...(item.target && { target: item.target })}
            >
                <span>{item.title}</span>
            </Link>
            <div className="idsk-header-web__nav-submenu">
                <div className="govuk-width-container">
                    <div className="govuk-grid-row">
                        <ul
                            className={classNames('idsk-header-web__nav-submenu-list', styles.mobileNested)}
                            aria-label={t('navMenu.innerNav', { parentTitle: item.title })}
                        >
                            {item.subItems?.map((subItem) => (
                                <NavMenuSubItem
                                    key={subItem.title}
                                    item={subItem}
                                    closeSubmenu={() => {
                                        closeSubmenu()
                                    }}
                                />
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </li>
    )
}
