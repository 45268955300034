import { BreadCrumbs, HomeIcon, TextHeading } from '@isdd/idsk-ui-kit/index'
import { mapFilterToExecuteParams } from '@isdd/metais-common/componentHelpers'
import { ReportExportButton } from '@isdd/metais-common/components/actions-over-table'
import { ActionsOverTable } from '@isdd/metais-common/components/actions-over-table/ActionsOverTable'
import { ElementToScrollTo } from '@isdd/metais-common/components/element-to-scroll-to/ElementToScrollTo'
import { FlexColumnReverseWrapper } from '@isdd/metais-common/components/flex-column-reverse-wrapper/FlexColumnReverseWrapper'
import { DEFAULT_PAGESIZE_OPTIONS, HowTo, REPORTS } from '@isdd/metais-common/constants'
import { IFilterParams } from '@isdd/metais-common/hooks/useFilter'
import { MutationFeedback, QueryFeedback } from '@isdd/metais-common/index'
import { RouteNames } from '@isdd/metais-common/navigation/routeNames'
import { getHowToTranslate } from '@isdd/metais-common/utils/utils'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MainContentWrapper } from '@/components/MainContentWrapper'
import { ReportsDetailContainer } from '@/components/containers/ReportsDetailContainer'
import { ReportTable } from '@/components/views/reports/ReportTable'
import { ReportsCard } from '@/components/views/reports/ReportsCard'
import { ReportsFilterParameterWrapper } from '@/components/views/reports/ReportsFilterParameterWrapper'

export const ReportsDetailPage: React.FC = () => {
    const { t } = useTranslation()

    const defaultFilterValues: IFilterParams & { [key: string]: string } = {}
    const [successDownload, setSuccessDownload] = useState(false)

    return (
        <ReportsDetailContainer
            defaultFilterValues={defaultFilterValues}
            View={(props) => {
                return (
                    <>
                        <BreadCrumbs
                            withWidthContainer
                            links={[
                                { label: t('breadcrumbs.home'), href: '/', icon: HomeIcon },
                                { label: getHowToTranslate(HowTo.MONITORING_HOWTO, t), href: RouteNames.HOW_TO_MONITORING },
                                { label: t('reports.heading') ?? '', href: `/ci/${REPORTS}` },
                            ]}
                        />
                        <MainContentWrapper>
                            <QueryFeedback loading={props.isLoading} error={false} withChildren>
                                <FlexColumnReverseWrapper>
                                    <TextHeading size="XL">{props.data?.name}</TextHeading>
                                    <QueryFeedback loading={false} error={props.isError} />
                                    <MutationFeedback error={props.isError} errorMessage={t('mutationFeedback.runMutationError')} />
                                </FlexColumnReverseWrapper>
                                <ReportsCard data={props.data} />
                                <ReportsFilterParameterWrapper
                                    defaultFilterValues={defaultFilterValues}
                                    parameters={props.data?.parameters}
                                    filterEnumData={props.filterEmumData}
                                />

                                <ActionsOverTable
                                    pagination={props.pagination}
                                    handleFilterChange={props.handleFilterChange}
                                    pagingOptions={DEFAULT_PAGESIZE_OPTIONS}
                                    entityName={props?.data?.lookupKey ?? ''}
                                    exportButton={
                                        <ReportExportButton
                                            entityId={props?.data?.id ?? 0}
                                            entityName={props.data?.name ?? ''}
                                            filter={mapFilterToExecuteParams(props.filterParams ?? {}, props.data?.parameters, props.filterEmumData)}
                                            setSuccessDownload={setSuccessDownload}
                                        />
                                    }
                                    simpleTableColumnsSelect={{
                                        selectedColumns: props.reportResult?.headers?.map((h) => ({
                                            technicalName: h.name,
                                            selected: !!h.visible,
                                            name: h.name,
                                        })),
                                        saveSelectedColumns: props.saveSelectedColumns,
                                        resetSelectedColumns: props.resetSelectedColumns,
                                    }}
                                />

                                <ElementToScrollTo trigger={successDownload}>
                                    <MutationFeedback
                                        success={successDownload}
                                        successMessage={t('exportItemsOrRelations.exportSuccess')}
                                        onMessageClose={() => setSuccessDownload(false)}
                                    />
                                </ElementToScrollTo>

                                <ReportTable
                                    data={props?.reportResult}
                                    sort={props?.filterParams?.sort ?? []}
                                    handleFilterChange={props?.handleFilterChange}
                                    pagination={props.pagination}
                                    storeColumnsOrder={props.storeColumnsOrder}
                                />
                            </QueryFeedback>
                        </MainContentWrapper>
                    </>
                )
            }}
        />
    )
}
