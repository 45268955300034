import { useParams } from 'react-router-dom'

import { RefIdentifierDetailContainer } from '@/components/containers/ref-identifiers/RefIdentifierDetailContainer'

const RefIdentifierRequestDetailPage = () => {
    const { id } = useParams()

    return <RefIdentifierDetailContainer id={id ?? ''} pageType="requests" />
}

export default RefIdentifierRequestDetailPage
