import React from 'react'
import sanitizeHtml from 'sanitize-html'
import { getUseLightFontBasedOnContrast } from '@isdd/metais-common/utils/utils'
import { SystemStatusCloseIcon } from '@isdd/metais-common/assets/images'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './informationBar.module.scss'

interface ITextListProps {
    text: string
    status: string
    color?: string
    onClose?: () => void
    showCloseButton?: boolean
}

export const InformationBar: React.FC<ITextListProps> = ({ color, text, status, onClose, showCloseButton = true }) => {
    const { t } = useTranslation()

    const shouldUseLightFont = getUseLightFontBasedOnContrast(color ?? '')

    return (
        <div className={classNames(styles.wrapper, { [styles.lightTheme]: shouldUseLightFont })} style={{ backgroundColor: color }}>
            {showCloseButton && (
                <button
                    className={classNames('idsk-button', 'idsk-button--secondary', styles.closeButton)}
                    onClick={onClose}
                    aria-label={t('navbar.systemState.close')}
                >
                    {SystemStatusCloseIcon(shouldUseLightFont)}
                </button>
            )}
            <div className="govuk-width-container govuk-body wiki" dangerouslySetInnerHTML={{ __html: sanitizeHtml(status + text) }} />
        </div>
    )
}
