import { IFilter, SortType } from '@isdd/idsk-ui-kit/types'
import { User, useAuth } from '@isdd/metais-common/contexts/auth/authContext'
import { AbilityContext } from '@isdd/metais-common/hooks/permissions/useAbilityContext'
import { IFilterParams, useFilterParams } from '@isdd/metais-common/hooks/useFilter'
import { BASE_PAGE_NUMBER, BASE_PAGE_SIZE } from '@isdd/metais-common/index'
import {
    Group,
    IdentitiesInGroupAndCount,
    IdentityInGroupData,
    OperationResult,
    useFindByUuid3,
    useFindRelatedIdentitiesAndCount,
} from '@isdd/metais-common/api/generated/iam-swagger'
import { ColumnDef, Row } from '@tanstack/react-table'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { setDocumentTitle } from '@isdd/metais-common/utils/utils'
import { RowSelection, useRowSelection } from '@isdd/metais-common/hooks/useRowSelection'

import { buildColumns, TableMeta } from '@/components/views/standardization/groups/groupMembersTableUtils'

export interface TableData {
    uuid: string
    lastName_firstName: string
    orgName: string
    orgId: string
    roleName: string
    email: string
}

export interface IdentityToEdit {
    row: Row<TableData>
}

export interface FilterParams extends IFilterParams, IFilter {
    memberUuid: string | undefined
    poUuid: string | undefined
    role: string | undefined
    identityState: string
}

export const defaultSort = [
    {
        orderBy: 'lastName_firstName',
        sortDirection: SortType.ASC,
    },
]

export const identitiesFilter: FilterParams = {
    memberUuid: undefined,
    poUuid: undefined,
    role: undefined,
    pageNumber: BASE_PAGE_NUMBER,
    pageSize: BASE_PAGE_SIZE,
    identityState: 'ACTIVATED',
    sort: defaultSort,
}

export interface GroupDetailViewProps {
    id?: string
    group: Group | undefined
    isAddModalOpen: boolean
    setAddModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    user: User | null
    rowSelection: RowSelection<TableData>
    isIdentitiesLoading: boolean
    selectableColumnsSpec: ColumnDef<TableData>[]
    tableData: TableData[] | undefined
    identitiesData: IdentitiesInGroupAndCount | undefined
    identityToDelete: string | undefined
    identityToEdit: IdentityToEdit | undefined
    setIdentityToDelete: React.Dispatch<React.SetStateAction<string | undefined>>
    setIdentityToEdit: React.Dispatch<React.SetStateAction<IdentityToEdit | undefined>>
    handleFilterChange: (changedFilter: IFilter) => void
    filter: FilterParams
    isIdentitiesError: boolean
    error: OperationResult | null
    isLoading: boolean
    rowActionFocusTriggerRef: React.MutableRefObject<(() => void) | undefined>
    tableMeta: TableMeta
}

interface GroupDetailContainer {
    id?: string
    View: React.FC<GroupDetailViewProps>
}

const GroupDetailContainer: React.FC<GroupDetailContainer> = ({ id, View }) => {
    const {
        state: { user },
    } = useAuth()
    const isUserLogged = !!user

    const ability = useContext(AbilityContext)
    const { t } = useTranslation()
    const rowSelection = useRowSelection<TableData>()

    const { filter, handleFilterChange } = useFilterParams<FilterParams>(identitiesFilter)
    const { data: group, isLoading: isGroupLoading } = useFindByUuid3(id ?? '')

    const [isAddModalOpen, setAddModalOpen] = useState(false)
    const [identities, setIdentities] = useState<IdentityInGroupData[]>()
    const rowActionFocusTriggerRef = useRef<() => void>()

    const {
        data: identitiesData,
        isLoading,
        isFetching,
        isError: isIdentitiesError,
        error,
    } = useFindRelatedIdentitiesAndCount(id ?? '', {
        page: String(filter.pageNumber),
        perPage: String(filter.pageSize),
        ...(filter.sort != undefined
            ? { orderBy: filter.sort[0]?.orderBy, desc: filter.sort[0]?.sortDirection === SortType.DESC }
            : { orderBy: defaultSort[0].orderBy, desc: defaultSort[0].sortDirection === SortType.DESC }),
        ...(filter.memberUuid != undefined && { memberUuid: filter.memberUuid }),
        ...(filter.identityState != undefined && { identityState: filter.identityState }),
        ...(filter.poUuid != undefined && { poUuid: filter.poUuid }),
        ...(filter.role != 'all' && filter.role != undefined && { role: filter.role }),
        ...(filter.fullTextSearch != undefined && { expression: filter.fullTextSearch }),
    })

    const [tableData, setTableData] = useState<TableData[]>()
    const getRoleFromIdentityGids = (identity: IdentityInGroupData) => {
        return identity?.gids ? identity.gids[identity.gids.length - 1].roleName : ''
    }

    useEffect(() => {
        if (identities !== identitiesData) {
            setIdentities(identitiesData?.list)
        }
    }, [identities, identitiesData])

    useEffect(() => {
        setTableData(
            identities?.map((item) => ({
                uuid: item.identity?.uuid ?? '',
                lastName_firstName: item.identity?.lastName + ' ' + item.identity?.firstName,
                orgName: item.gids?.[item.gids.length - 1].orgName?.toString() ?? '',
                roleName: getRoleFromIdentityGids(item) ?? '',
                email: item.identity?.email ?? '',
                orgId: item.gids?.map((org) => org.orgId)?.toString() ?? '',
            })),
        )
    }, [identities])

    const [identityToDelete, setIdentityToDelete] = useState<string>()
    const [identityToEdit, setIdentityToEdit] = useState<IdentityToEdit>()

    const isMemberEditModalOpened = !!identityToEdit?.row.original.uuid
    const isMemberDeleteModalOpened = !!identityToDelete
    const tableMeta: TableMeta = {
        isAnyModalOpened: isAddModalOpen || isMemberEditModalOpened || isMemberDeleteModalOpened,
    }

    const selectableColumnsSpec: ColumnDef<TableData>[] = buildColumns({
        tableData,
        t,
        ability,
        isUserLogged,
        roles: user?.roles,
        setIdentityToDelete,
        setIdentityToEdit,
        rowActionFocusTriggerRef,
    })

    setDocumentTitle(`${t('titles.groupDetail')} ${group?.shortName ?? ''}`)
    return (
        <View
            isIdentitiesError={isIdentitiesError}
            isLoading={[isLoading, isGroupLoading].some((item) => item)}
            error={error}
            filter={filter}
            handleFilterChange={handleFilterChange}
            id={id}
            group={group}
            isAddModalOpen={isAddModalOpen}
            setAddModalOpen={setAddModalOpen}
            user={user}
            rowSelection={rowSelection}
            isIdentitiesLoading={[isLoading, isFetching].some((item) => item)}
            selectableColumnsSpec={selectableColumnsSpec}
            tableData={tableData}
            identitiesData={identitiesData}
            identityToDelete={identityToDelete}
            identityToEdit={identityToEdit}
            setIdentityToDelete={setIdentityToDelete}
            setIdentityToEdit={setIdentityToEdit}
            rowActionFocusTriggerRef={rowActionFocusTriggerRef}
            tableMeta={tableMeta}
        />
    )
}

export default GroupDetailContainer
