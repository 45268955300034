import { IOption } from '@isdd/idsk-ui-kit/index'
import { EnumType } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { Parameter, ParameterType, ReportResultObject } from '@isdd/metais-common/api/generated/report-swagger'
import { ColumnOrderState, Updater } from '@tanstack/react-table'
import { TFunction } from 'i18next'
import { boolean, object, string } from 'yup'

export const getDefaultValueForKey = (key?: string, parameters?: Parameter[]) => {
    const currentParameter = parameters?.find((param) => param.key === key)
    if (currentParameter?.type === ParameterType.BOOLEAN) return currentParameter.defaultValue ? currentParameter.defaultValue === 'true' : false
    return currentParameter?.defaultValue ?? ''
}

export const loadEnumsRepoOptions = (filterData: (EnumType | undefined)[], code?: string): IOption<string>[] => {
    const enumtItems = filterData.find((fD) => fD?.code === code)?.enumItems
    const selectOptions = enumtItems?.map((eI) => {
        return { value: eI.code ?? '', label: eI.description ?? '' }
    })
    return [...(selectOptions ?? [])]
}

export const loadEnumerateOptions = (enumsString?: string): IOption<string>[] => {
    const selectOptions = enumsString
        ?.split(';')
        .filter(Boolean)
        .map((enumOption) => {
            return { value: enumOption, label: enumOption }
        })

    return [...(selectOptions ?? [])]
}

export const getReportsFilterParameterYupSchema = (
    t: TFunction<'translation', undefined, 'translation'>,
    parameterKeys?: (string | undefined)[],
    parameters?: Parameter[],
) => {
    if (parameterKeys) {
        const schema = Object.fromEntries(
            parameterKeys?.map((key) => {
                const currentParameter = parameters?.find((param) => param.key === key)
                const type = currentParameter?.type
                if (type === ParameterType.BOOLEAN) return [key, boolean()]

                return [
                    key,
                    string().when(`isRequired`, {
                        is: () => currentParameter?.required,
                        then: () => string().required(t('validation.required')),
                        otherwise: () => string().optional(),
                    }),
                ]
            }) ?? [],
        )
        return object()?.shape(schema)
    }
}

export type Header = { name: string; type: string }
export type EnhancedHeader = Header & { order: number; visible?: boolean }

export const isHeader = (obj: unknown): obj is Header => {
    return typeof obj === 'object' && obj !== null && 'name' in obj && typeof obj.name === 'string' && 'type' in obj && typeof obj.type === 'string'
}

export const isEnhancedHeader = (obj: unknown): obj is EnhancedHeader => {
    return isHeader(obj) && 'order' in obj && typeof obj.order === 'number'
}

export const validateHeaders = (data: unknown): data is Header[] => {
    return Array.isArray(data) && data.length > 0 && data.every(isHeader)
}

export const validateEnhancedHeaders = (data: unknown): data is EnhancedHeader[] => {
    return Array.isArray(data) && data.length > 0 && data.every(isEnhancedHeader)
}

const incrementByOne = (number: number) => {
    return number + 1
}

export const storeColumns = (updaterOrValue: Updater<ColumnOrderState>, id: string) => {
    try {
        const localColumns = localStorage.getItem(id)
        if (!localColumns) return []

        const parsedLocalColumns = JSON.parse(localColumns)
        if (!validateEnhancedHeaders(parsedLocalColumns)) return []

        const headersFromTable = updaterOrValue as ColumnOrderState
        const updatedOrdersHeaders = parsedLocalColumns.map((header: EnhancedHeader) => {
            const matchedIndex = incrementByOne(headersFromTable.findIndex((h) => h === header.name))
            return matchedIndex ? { ...header, order: matchedIndex } : header
        })

        localStorage.setItem(id, JSON.stringify(updatedOrdersHeaders))
        return updatedOrdersHeaders
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to store columns:', error)
        return []
    }
}

export const getTableWithOrder = (id: string, headers: Header[]): EnhancedHeader[] => {
    if (!id || !headers?.length) {
        return []
    }

    const defaultHeadersWithOrder = headers.map((header, index) => ({
        ...header,
        order: index + 1,
        visible: true,
    }))

    try {
        const localHeaders = localStorage.getItem(id)
        if (localHeaders) {
            const parsedHeaders: EnhancedHeader[] = JSON.parse(localHeaders)
            if (validateEnhancedHeaders(parsedHeaders)) {
                return parsedHeaders
            }
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error retrieving table order:', error)
    }

    localStorage.setItem(id, JSON.stringify(defaultHeadersWithOrder))
    return defaultHeadersWithOrder
}

export const getRows = (reportData: ReportResultObject | undefined): Record<string, string>[] => {
    if (!reportData || !reportData.result || !reportData.result.headers || !reportData.result.rows) {
        return []
    }

    const mapRowToRecord = (row: { values: string[] }): Record<string, string> => {
        const valuesMap: Record<string, string> = {}
        const headers = reportData?.result?.headers
        if (validateHeaders(headers))
            headers.forEach((header, index) => {
                valuesMap[header.name] = row.values[index] || ''
            })
        return valuesMap
    }

    return reportData.result.rows.map(mapRowToRecord)
}
