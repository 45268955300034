import { Button } from '@isdd/idsk-ui-kit/index'
import { RelListFilterContainerUi, useExportRelationsHook, useLoadFileHook } from '@isdd/metais-common/api/generated/impexp-cmdb-swagger'
import { ExportIcon } from '@isdd/metais-common/assets/images'
import { downloadBlobAsFile, generateExportFileName } from '@isdd/metais-common/componentHelpers/download/downloadHelper'
import { ExportItemsOrRelations } from '@isdd/metais-common/components/export-items-or-relations/ExportItemsOrRelations'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { useGetProgress } from '@isdd/metais-common/hooks/useGetRequestProgress'
import { FileExtensionEnum, IconLabel } from '@isdd/metais-common/index'
import { NavigationSubRoutes } from '@isdd/metais-common/navigation/routeNames'
import { handleTooManyRequests } from '@isdd/metais-common/utils/utils'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    filter?: RelListFilterContainerUi
    selectedUuids?: string[]
}

const RelationsExport: React.FC<Props> = ({ filter, selectedUuids }) => {
    const [modalOpen, setModalOpen] = useState(false)
    const { t } = useTranslation()

    const [isLoading, setLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState(false)
    const { getRequestStatus, isLoading: isGettingProgress } = useGetProgress('EXPORT')
    const fileLoad = useLoadFileHook()
    const exportRelations = useExportRelationsHook()

    const { setIsActionSuccess } = useActionSuccess()

    const openModal = () => {
        setModalOpen(true)
    }

    const onClose = () => {
        setModalOpen(false)
    }

    const onError = () => {
        setIsError(true)
        setIsActionSuccess({ value: false, path: `${NavigationSubRoutes.RELATIONS_LIST}` })
        onClose()
    }
    const [errorMessage, setErrorMessage] = useState<string>()

    const exportAndDownloadBlob = async (extension: FileExtensionEnum, entity: string, relFilter: RelListFilterContainerUi) => {
        if (selectedUuids && selectedUuids.length > 0) relFilter.relFilter = { ...relFilter.relFilter, uuid: selectedUuids }
        await exportRelations(extension, relFilter).then(async (result) => {
            await getRequestStatus(
                result.id ?? '',
                async (validationResult) => {
                    const blob = await fileLoad(validationResult?.fileName ?? '')
                    if (blob) {
                        downloadBlobAsFile(new Blob([blob]), generateExportFileName(entity, true, validationResult?.fileExtension ?? ''), false)
                        setIsActionSuccess({ value: true, path: `${NavigationSubRoutes.RELATIONS_LIST}` })
                        onClose()
                    } else {
                        onError()
                    }
                },
                () => {
                    onError()
                },
            ).catch((e) => {
                handleTooManyRequests(
                    e.message,
                    () => {
                        setErrorMessage(t('exportItemsOrRelations.exportErrorTooManyRequests'))
                        setIsError(true)
                    },
                    () => {
                        setIsError(true)
                    },
                )
            })
        })

        setLoading(false)
    }

    const onExportStart = (exportValue: string, extension: FileExtensionEnum) => {
        setLoading(true)
        setIsError(false)
        exportAndDownloadBlob(extension, 'Relationships', filter ?? {})
    }

    return (
        <>
            <Button
                onClick={openModal}
                className="marginBottom0"
                variant="secondary"
                label={<IconLabel label={t('actionOverTable.export')} icon={ExportIcon} />}
            />
            <ExportItemsOrRelations
                errorMessage={errorMessage}
                isOpen={modalOpen}
                isLoading={isLoading || isGettingProgress}
                isError={isError}
                close={onClose}
                onExportStart={onExportStart}
                hideRadioBtns
                customHeader={t('relationshipList.exportHeader')}
            />
        </>
    )
}

export default RelationsExport
