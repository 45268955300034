import { Button } from '@isdd/idsk-ui-kit/button/Button'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useExport3Hook, useLoadFileHook } from '@isdd/metais-common/api/generated/impexp-cmdb-swagger'
import { ReportExecute } from '@isdd/metais-common/api/generated/report-swagger'
import { ExportIcon } from '@isdd/metais-common/assets/images'
import { downloadBlobAsFile, generateExportFileName } from '@isdd/metais-common/componentHelpers/download/downloadHelper'
import { FileExtensionEnum } from '@isdd/metais-common/components/actions-over-table'
import { IconLabel } from '@isdd/metais-common/components/actions-over-table/icon-label/IconLabel'
import { ExportItemsOrRelations } from '@isdd/metais-common/components/export-items-or-relations/ExportItemsOrRelations'
import { useGetProgress } from '@isdd/metais-common/hooks/useGetRequestProgress'
import { handleTooManyRequests } from '@isdd/metais-common/utils/utils'
interface IExportButtonProps {
    entityId: number
    entityName: string
    filter: ReportExecute
    setSuccessDownload: (val: boolean) => void
}

export const ReportExportButton: React.FC<IExportButtonProps> = ({ entityId, entityName, filter, setSuccessDownload }) => {
    const [modalOpen, setModalOpen] = useState(false)
    const { t } = useTranslation()
    const { getRequestStatus, isLoading: isGettingProgress } = useGetProgress('EXPORT')
    const exportHook = useExport3Hook()
    const fileLoad = useLoadFileHook()

    const [isLoading, setLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState(false)

    const openModal = () => {
        setModalOpen(true)
    }

    const onClose = () => {
        setModalOpen(false)
    }

    const [errorMessage, setErrorMessage] = useState<string>()

    const exportAndDownloadBlob = async (extension: FileExtensionEnum) => {
        exportHook(extension, entityId, filter)
            .then(async (result) => {
                await getRequestStatus(
                    result.id ?? '',
                    async (validationResult) => {
                        const blob = await fileLoad(validationResult?.fileName ?? '')
                        if (blob) {
                            downloadBlobAsFile(
                                new Blob([blob]),
                                generateExportFileName(entityName, false, validationResult?.fileExtension ?? '', false),
                                false,
                            )
                        } else {
                            setIsError(true)
                        }
                    },
                    () => {
                        setIsError(true)
                    },
                )
            })
            .catch((e) => {
                handleTooManyRequests(
                    e.message,
                    () => {
                        setErrorMessage(t('exportItemsOrRelations.exportErrorTooManyRequests'))
                        setIsError(true)
                    },
                    () => {
                        setIsError(true)
                    },
                )
            })

        setLoading(false)
    }

    const onSuccess = () => {
        setLoading(false)
        setSuccessDownload(true)
        onClose()
    }

    const onError = () => {
        setSuccessDownload(false)
        setIsError(true)
        setLoading(false)
    }

    const onExportStart = (exportValue: string, extension: FileExtensionEnum) => {
        setLoading(true)
        setIsError(false)
        exportAndDownloadBlob(extension).then(onSuccess).catch(onError)
    }

    return (
        <>
            <Button
                onClick={openModal}
                className="marginBottom0"
                variant="secondary"
                label={<IconLabel label={t('actionOverTable.export')} icon={ExportIcon} />}
                aria-haspopup={'dialog'}
            />
            <ExportItemsOrRelations
                errorMessage={errorMessage}
                isOpen={modalOpen}
                isLoading={isLoading || isGettingProgress}
                isError={isError}
                close={onClose}
                onExportStart={onExportStart}
                hideRadioBtns
                customHeader={t('reports.export.header')}
            />
        </>
    )
}
