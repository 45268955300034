import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { setDocumentTitle } from '@isdd/metais-common/utils/utils'

import NotificationsDetailContainer from '@/components/containers/NotificationsDetailContainer'
import NotificationsDetailView from '@/components/views/notifications/NotificationsDetailView'

const NotificationsDetailPage = () => {
    const { t } = useTranslation()
    const { id } = useParams()

    return (
        <NotificationsDetailContainer
            id={id}
            View={(props) => {
                setDocumentTitle(t('titles.notificationDetail', { itemName: props.data?.messagePerex ?? '' }))
                return <NotificationsDetailView id={props.id} data={props.data} isError={props.isError} isLoading={props.isLoading} />
            }}
        />
    )
}

export default NotificationsDetailPage
