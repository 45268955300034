import { Button, TransparentButtonWrapper } from '@isdd/idsk-ui-kit/index'
import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { ConfigurationItemUi, IncidentRelationshipSetUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { CloseIcon } from '@isdd/metais-common/assets/images'
import { CiLazySelectCreatable, CreatableOptionType } from '@isdd/metais-common/components/ci-lazy-select-creatable/CiLazySelectCreatable'
import { DRAFT } from '@isdd/metais-common/constants'
import { isConfigurationItemUi } from '@isdd/metais-common/utils/utils'
import classNames from 'classnames'
import { UseFieldArrayReturn, UseFormReturn, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'
import { MultiValue } from 'react-select'

import styles from '@/components/attribute-input/attributeInput.module.scss'

export type FormValues = {
    creatable: { id: string; value?: ConfigurationItemUi | CreatableOptionType }[]
}
export const CRETABLE_FORM_KEY = 'creatable'

type ArrayCiLazySelectCreatableProps = {
    handleCreatableItemSelection: (items: CreatableOptionType[] | null) => void
    handleCiItemSelection: (items: ConfigurationItemUi[] | null) => void
    fieldArray: UseFieldArrayReturn<FormValues, 'creatable', 'id'>
    methods: UseFormReturn<FormValues>
    label: string
    existingRelations: IncidentRelationshipSetUi | undefined
    ciType: string
    setSelectedItems: React.Dispatch<React.SetStateAction<ConfigurationItemUi | MultiValue<ConfigurationItemUi> | null>>
    selectedItems: ConfigurationItemUi | MultiValue<ConfigurationItemUi> | null
    creatableItems: CreatableOptionType[]
}

export const ArrayCiLazySelectCreatable: React.FC<ArrayCiLazySelectCreatableProps> = ({
    handleCiItemSelection,
    handleCreatableItemSelection,
    creatableItems,
    fieldArray,
    methods,
    label,
    ciType,
    existingRelations,
    setSelectedItems,
    selectedItems,
}) => {
    const { t } = useTranslation()
    const { fields, append, remove } = fieldArray
    const creatableField = methods.watch(CRETABLE_FORM_KEY)

    const updateSelections = (items: (ConfigurationItemUi | CreatableOptionType | undefined)[]) => {
        const ciValues: ConfigurationItemUi[] = []
        const creatableValues: CreatableOptionType[] = []
        items.forEach((i) => {
            if (!i) return
            if (isConfigurationItemUi(i)) {
                ciValues.push(i)
            } else {
                creatableValues.push(i)
            }
        })
        handleCiItemSelection(ciValues.length > 0 ? ciValues : null)
        handleCreatableItemSelection(creatableValues.length > 0 ? creatableValues : null)
    }

    return (
        <>
            <fieldset className={styles.fieldset}>
                <span className="govuk-visually-hidden" role="alert">
                    {t('arrayInput.changeRowsNumber', { name, count: fields.length })}
                </span>
                <legend className="govuk-label">{label}</legend>
                <div className={classNames('govuk-form-group', styles.formGroup)}>
                    <div className={styles.buttonDiv}>
                        <Button
                            label={<div className={styles.buttonWithIcon}>{`+ ${t('createEntity.addNewRow')}`}</div>}
                            type="button"
                            onClick={() => {
                                append({ id: v4() })
                                updateSelections([...creatableField.map((f) => f.value)])
                            }}
                        />
                    </div>
                </div>

                {fields.map((field, index) => {
                    return (
                        <div key={field.id}>
                            <Controller
                                control={methods.control}
                                name={`creatable.${index}`}
                                render={(renderProps) => {
                                    const renderFieldValue = renderProps.field.value
                                    const fieldValue = isConfigurationItemUi(renderFieldValue.value)
                                        ? renderFieldValue.value
                                        : { value: renderFieldValue.value?.value ?? '', label: renderFieldValue.value?.value ?? '' }
                                    return (
                                        <div className={styles.selectArray}>
                                            <TransparentButtonWrapper
                                                onClick={() => {
                                                    if (isConfigurationItemUi(renderFieldValue.value)) {
                                                        const ciUuid = renderFieldValue.value.uuid
                                                        setSelectedItems(() => {
                                                            const ci: ConfigurationItemUi[] = Array.isArray(selectedItems)
                                                                ? selectedItems
                                                                : [selectedItems]

                                                            return ci.filter((i) => {
                                                                return i?.uuid != ciUuid
                                                            })
                                                        })
                                                    } else {
                                                        const creatableUuid = renderFieldValue.value?.value ?? ''
                                                        const cFiltered = creatableItems?.filter((i) => i?.value != creatableUuid) ?? []
                                                        handleCreatableItemSelection(cFiltered.length > 0 ? cFiltered : null)
                                                    }

                                                    remove(index)
                                                }}
                                                type="button"
                                                aria-label={t('arrayInput.deleteRow', { itemName: index })}
                                            >
                                                <img src={CloseIcon} alt="" className={styles.cancelRowIcon} />
                                            </TransparentButtonWrapper>

                                            <div className={styles.select}>
                                                <CiLazySelectCreatable
                                                    ciTypes={[ciType]}
                                                    {...renderProps.field}
                                                    fieldValue={fieldValue}
                                                    onChange={(val) => {
                                                        const firstValue = val[0]
                                                        renderProps.field.onChange({ id: field.id, value: firstValue })
                                                        updateSelections([...creatableField.map((f) => f.value)])
                                                    }}
                                                    searchAttributeNames={[ATTRIBUTE_NAME.Gen_Profil_kod_metais, ATTRIBUTE_NAME.Gen_Profil_nazov]}
                                                    existingRelations={existingRelations}
                                                    additionalData={{ metaAttributes: { state: [DRAFT] } }}
                                                />
                                            </div>
                                        </div>
                                    )
                                }}
                            />
                        </div>
                    )
                })}
            </fieldset>
        </>
    )
}
