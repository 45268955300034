import { Cell, Row, flexRender } from '@tanstack/react-table'
import classNames from 'classnames'
import { ConnectDragSource, ConnectDropTarget, useDrag, useDrop } from 'react-dnd'
import { useLocation, useNavigate } from 'react-router-dom'
import React, { Dispatch, PropsWithChildren, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MODAL_TOOLTIP_ZINDEX } from '@isdd/metais-common/constants'

import { getListCellValue } from './tableUtils'
import styles from './table.module.scss'
import { CHECKBOX_CELL, TOOLTIP_TEXT_BREAKER } from './constants'

import { Tooltip } from '@isdd/idsk-ui-kit/tooltip/Tooltip'
import { DndIcon } from '@isdd/idsk-ui-kit/assets/images'
import { Del } from '@isdd/idsk-ui-kit/typography/Del'
import { sanitizedHtml } from '@isdd/idsk-ui-kit/save-html-component/SafeHtmlComponent'

interface ITableDragRowProps<T> {
    row: Row<T>
    isRowSelected?: (row: Row<T>) => boolean
    isRowBold?: (row: Row<T>) => boolean
    isRowDanger?: (row: Row<T>) => boolean
    onRowClick?: (row: Row<T>) => void
    rowHref?: (row: Row<T>) => string
    linkToNewTab?: boolean
    reorderRow?: (index: number, target: number) => void
    isInvalidated: boolean
    selectedRow: number | null
    setSelectedRow: Dispatch<SetStateAction<number | null>>
    isLoading?: boolean
    dataLength: number
    isInModal?: boolean
    automaticHeaderColumn?: boolean
}

type TableDragRowHeader<T> = PropsWithChildren & {
    cell: Cell<T, unknown>
    index: number
    className: string
    rowIndex: number
    columnDefSize: number | undefined
    dropRef: ConnectDropTarget
    dragRef: ConnectDragSource
    selectedRow: number | null
    setSelectedRow: Dispatch<SetStateAction<number | null>>
    reorderRow: ((index: number, target: number) => void) | undefined
    isLoading?: boolean
    dataLength: number
}

const TableDragRowHeader = <T,>({
    cell,
    index,
    dropRef,
    dragRef,
    className,
    columnDefSize,
    rowIndex,
    children,
    selectedRow,
    setSelectedRow,
    reorderRow,
    isLoading,
    dataLength,
}: TableDragRowHeader<T>) => {
    const { t } = useTranslation()

    const [lastKeyPressed, setLastKeyPressed] = useState<string | null>(null)
    const [changeMessage, setChangeMessage] = useState('')

    const selectedId = `${selectedRow}_${cell.column.id}`

    useEffect(() => {
        if (selectedRow !== null && !isLoading) {
            document.getElementById(selectedId)?.focus()
        }
    }, [selectedRow, isLoading, selectedId])

    const handleBlur = () => {
        if (lastKeyPressed !== 'ArrowUp' && lastKeyPressed !== 'ArrowDown') {
            setSelectedRow(null)
        }
    }

    const handleKeyDownEvent = (event: React.KeyboardEvent<HTMLTableHeaderCellElement>) => {
        setLastKeyPressed(event.key)
        if (isLoading) return
        if (event.key === 'Escape') {
            setSelectedRow(null)
            setChangeMessage(t('table.dragRow.srDeselected', { item: cell.getValue() }))
            return
        }
        if (event.key === ' ' || event.key === 'Space') {
            event.preventDefault()
            if (selectedRow == rowIndex) {
                setSelectedRow(null)
                setChangeMessage(t('table.dragRow.srDeselected', { item: cell.getValue() }))
                return
            }
            setSelectedRow(rowIndex)
            setChangeMessage(t('table.dragRow.srSelected', { index: rowIndex + 1, item: cell.getValue(), listLength: dataLength }))
            return
        }

        if (selectedRow === null) return
        if (event.key === 'ArrowUp') {
            const reducedRowIndex = selectedRow - 1
            const newIndex = reducedRowIndex >= 0 ? reducedRowIndex : 0
            reorderRow?.(selectedRow, newIndex)
            setSelectedRow(newIndex)
            setChangeMessage(t('table.dragRow.srMessage', { index1: selectedRow, index2: newIndex }))
            return
        }
        if (event.key === 'ArrowDown') {
            const newIndex = selectedRow + 1
            reorderRow?.(selectedRow, newIndex)
            setSelectedRow(newIndex)
            setChangeMessage(t('table.dragRow.srMessage', { index1: selectedRow, index2: newIndex }))
            return
        }
    }

    return (
        <th
            scope="row"
            className={className}
            style={columnDefSize ? { width: columnDefSize } : { width: 'auto' }}
            key={cell.id}
            id={cell.id}
            ref={index === 0 ? dropRef : null}
            tabIndex={0}
            onKeyDown={(event) => handleKeyDownEvent(event)}
            onBlur={() => handleBlur()}
            aria-describedby="operation"
            aria-selected={cell.id == selectedId}
            draggable
            data-index={rowIndex}
        >
            <div aria-live="assertive" className="sr-only">
                {changeMessage}
            </div>
            <div ref={index === 0 ? dragRef : null} className={styles.flexCenter} aria-label={t('dnd')}>
                {index === 0 && <img height={30} className={classNames({ [styles.handlePointer]: index === 0 })} src={DndIcon} alt="" />}
                {children}
            </div>
        </th>
    )
}

export const TableDragRow = <T,>({
    row,
    isRowBold,
    isRowDanger,
    onRowClick,
    rowHref,
    reorderRow,
    isInvalidated,
    linkToNewTab,
    selectedRow,
    setSelectedRow,
    isLoading,
    dataLength,
    isInModal,
    automaticHeaderColumn,
}: ITableDragRowProps<T>): JSX.Element => {
    const navigate = useNavigate()
    const location = useLocation()
    const hasCheckbox = row.getVisibleCells().find((cell) => cell.column.id === CHECKBOX_CELL)

    const [, dropRef] = useDrop({
        accept: 'row',
        drop: (draggedRow: Row<T>) => {
            reorderRow?.(draggedRow.index, row.index)
        },
    })

    const [{ isDragging }, dragRef, previewRef] = useDrag({
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        item: () => row,
        type: 'row',
    })

    return (
        <tr
            ref={previewRef}
            style={{ opacity: isDragging ? 0.5 : 1 }}
            className={classNames(
                'idsk-table__row',
                { [styles.fontWeightBolder]: isRowBold && isRowBold(row) },
                styles.tableRow,
                styles.tableRowMinHeight,
                {
                    [styles.danger]: isRowDanger && isRowDanger(row),
                    [styles.checkBoxRow]: hasCheckbox,
                },
            )}
            onClick={() => {
                if (rowHref) {
                    if (linkToNewTab) {
                        window.open(rowHref(row), '_blank')
                    } else {
                        navigate(rowHref(row), { state: { from: location } })
                    }
                }
                onRowClick?.(row)
            }}
        >
            {row.getVisibleCells().map((cell, index) => {
                const columnDef = cell.column.columnDef
                const tooltipText = columnDef?.meta?.getCellContext?.(cell.getContext())
                const isArray = Array.isArray(cell.getValue())
                const cellValue = flexRender(columnDef.cell, cell.getContext())
                const shortString = isArray ? true : typeof tooltipText === 'string' && tooltipText.length >= TOOLTIP_TEXT_BREAKER
                const showArrayTooltip = isArray && (cell.getValue() as string[])?.length > 3

                const hideTooltip =
                    cell.column.id === CHECKBOX_CELL ||
                    cell.getValue() === '' ||
                    cell.getValue() === undefined ||
                    cell.getValue() === null ||
                    !shortString

                const useHeader = automaticHeaderColumn ? (index === 0 && cell.column.id !== CHECKBOX_CELL) || index === 1 : false
                const cellContent =
                    hideTooltip && !showArrayTooltip ? (
                        cellValue
                    ) : (
                        <div ref={index === 0 ? dropRef : null}>
                            <Tooltip
                                contentStyle={isInModal ? { zIndex: MODAL_TOOLTIP_ZINDEX } : undefined}
                                position={'top center'}
                                disabled={
                                    cell.column.id === CHECKBOX_CELL ||
                                    cell.getValue() === '' ||
                                    cell.getValue() === undefined ||
                                    cell.getValue() === null ||
                                    !shortString
                                }
                                on={['hover', 'focus']}
                                descriptionElement={
                                    //dangerouslySetInnerHTML because of tooltip for attribute of type HTML
                                    isArray ? (
                                        getListCellValue(cell.getValue() as string[], true)
                                    ) : (
                                        <div className={styles.tooltipWidth500} dangerouslySetInnerHTML={{ __html: sanitizedHtml(tooltipText) }} />
                                    )
                                }
                                tooltipContent={() => (
                                    <div className={classNames({ [styles.tooltipTextWrapper]: !isArray })}>
                                        {!isArray ? cellValue : getListCellValue(cell.getValue() as string[])}
                                    </div>
                                )}
                            />
                        </div>
                    )
                return useHeader ? (
                    <TableDragRowHeader<T>
                        cell={cell}
                        index={index}
                        columnDefSize={columnDef.size}
                        reorderRow={reorderRow}
                        dragRef={dragRef}
                        dropRef={dropRef}
                        rowIndex={row.index}
                        className={classNames('idsk-table__cell', styles.fontWeightNormal, styles.dragFocus, {
                            [styles.fontWeightBolder]: isRowBold && isRowBold(row),
                            [styles.checkBoxCell]: cell.column.id === CHECKBOX_CELL,
                            [styles.rowSelected]: row.getIsSelected(),
                        })}
                        selectedRow={selectedRow}
                        setSelectedRow={setSelectedRow}
                        isLoading={isLoading}
                        dataLength={dataLength}
                    >
                        <Del isInvalid={isInvalidated}>{cellContent}</Del>
                    </TableDragRowHeader>
                ) : (
                    <td
                        className={classNames('idsk-table__cell', {
                            [styles.checkBoxCell]: cell.column.id === CHECKBOX_CELL,
                            [styles.rowSelected]: row.getIsSelected(),
                        })}
                        style={columnDef.size ? { width: columnDef.size } : { width: 'auto' }}
                        key={cell.id}
                        ref={index === 0 ? dropRef : null}
                    >
                        <div ref={index === 0 ? dragRef : null}>
                            <Del isInvalid={isInvalidated}>{cellContent}</Del>
                        </div>
                    </td>
                )
            })}
        </tr>
    )
}
