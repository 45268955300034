import { BreadCrumbs, HomeIcon } from '@isdd/idsk-ui-kit/index'
import { useTranslation } from 'react-i18next'
import { FooterRouteNames } from '@isdd/metais-common/navigation/routeNames'

import { MainContentWrapper } from '@/components/MainContentWrapper'
import { OpenDataView } from '@/components/views/openData'

const OpenDataPage = () => {
    const { t } = useTranslation()

    return (
        <>
            <BreadCrumbs
                withWidthContainer
                links={[
                    { label: t('breadcrumbs.home'), href: '/', icon: HomeIcon },
                    { label: t('footer.opendata'), href: FooterRouteNames.OPENDATA },
                ]}
            />

            <MainContentWrapper>
                <OpenDataView />
            </MainContentWrapper>
        </>
    )
}

export default OpenDataPage
