import { BaseModal, SimpleSelect, TextHeading } from '@isdd/idsk-ui-kit/index'
import {
    Role,
    useFindAll11Hook,
    useFindByUuid3,
    useFindRelatedIdentitiesAndCountHook,
    useUpdateRoleOnGroupOrgForIdentityHook,
} from '@isdd/metais-common/api/generated/iam-swagger'
import { GROUP_ROLES, KSIVS_SHORT_NAME } from '@isdd/metais-common/constants'
import { useActionSuccess } from '@isdd/metais-common/contexts/actionSuccess/actionSuccessContext'
import { ModalButtons, QueryFeedback } from '@isdd/metais-common/index'
import { NavigationSubRoutes } from '@isdd/metais-common/navigation/routeNames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInvalidateGroupMembersCache } from '@isdd/metais-common/hooks/invalidate-cache'

import { DEFAULT_KSISVS_ROLES, DEFAULT_ROLES } from '../defaultRoles'

import { IdentityToEdit } from '@/components/containers/standardization/groups/GroupDetailContainer'

interface EditGroupMemberModalProps {
    identityToEdit?: IdentityToEdit
    groupUuid: string
    isOpen: boolean
    onClose: () => void
}

const EditGroupMemberModal: React.FC<EditGroupMemberModalProps> = ({ identityToEdit, groupUuid, isOpen, onClose }) => {
    const { t } = useTranslation()

    const [lastChairmanError, setLastChairmanError] = useState(false)
    const [selectedRole, setSelectedRole] = useState<string>()
    const [isSaving, setIsSaving] = useState<boolean>(false)

    const { setIsActionSuccess } = useActionSuccess()
    const invalidateGroupMembersCache = useInvalidateGroupMembersCache(groupUuid ?? '')
    const relatedIdentitiesAndCountHook = useFindRelatedIdentitiesAndCountHook()
    const updateGroupRequest = useUpdateRoleOnGroupOrgForIdentityHook()
    const findRoleRequest = useFindAll11Hook()

    const { data: group, isLoading: isGroupLoading } = useFindByUuid3(groupUuid ?? '')
    const isKsisvs = group?.shortName === KSIVS_SHORT_NAME

    const handleOnEditClick = async () => {
        const memberToUpdate = identityToEdit?.row.original
        if (!memberToUpdate) {
            return
        }

        setIsSaving(true)
        const chairmans = await relatedIdentitiesAndCountHook(groupUuid, { role: GROUP_ROLES.STD_PSPRE })
        if (chairmans.count === 1 && chairmans.list?.[0]?.identity?.uuid === memberToUpdate.uuid) {
            setLastChairmanError(true)
            setIsSaving(false)
            return
        }

        setLastChairmanError(false)
        setIsSaving(true)

        const orgIds = memberToUpdate.orgId.split(',')
        const oldRole: Role = (await findRoleRequest({ name: memberToUpdate.roleName })) as Role
        const newRole: Role = (await findRoleRequest({ name: selectedRole })) as Role

        await updateGroupRequest(memberToUpdate.uuid, groupUuid ?? '', oldRole.uuid ?? '', newRole.uuid ?? '', orgIds[orgIds.length - 1])
        invalidateGroupMembersCache.invalidate()
        onClose()
        setIsActionSuccess({
            value: true,
            path: `${NavigationSubRoutes.PRACOVNA_SKUPINA_DETAIL}/${groupUuid}`,
            additionalInfo: { entity: 'member', type: 'memberUpdate' },
        })
        setIsSaving(false)
    }

    useEffect(() => {
        identityToEdit && setSelectedRole(identityToEdit.row.original.roleName)
    }, [identityToEdit])

    return (
        <>
            <BaseModal isOpen={isOpen} close={onClose}>
                <QueryFeedback
                    error={lastChairmanError}
                    loading={isGroupLoading || isSaving}
                    errorProps={{ errorMessage: t('groups.lastChairmanError') }}
                    indicatorProps={{ layer: 'dialog', label: t('groups.editingMember') }}
                    withChildren
                >
                    <TextHeading size="L">{t('groups.editGroupMember')}</TextHeading>
                    <SimpleSelect
                        name="selectRole"
                        value={selectedRole}
                        onChange={(value) => setSelectedRole(value)}
                        isClearable={false}
                        label=""
                        options={(isKsisvs ? DEFAULT_KSISVS_ROLES : DEFAULT_ROLES).map((item) => ({
                            value: item.code,
                            label: item.value,
                        }))}
                        ariaLabel={t('groups.role')}
                    />
                    <ModalButtons
                        submitButtonLabel={t('form.save')}
                        onSubmit={handleOnEditClick}
                        closeButtonLabel={t('form.cancel')}
                        onClose={onClose}
                    />
                </QueryFeedback>
            </BaseModal>
        </>
    )
}

export default EditGroupMemberModal
