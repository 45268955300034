import { ApiAttachment, ApiLink, ApiMeetingActor } from '@isdd/metais-common/api/generated/standards-swagger'
import { TFunction } from 'i18next'
import { DateTime } from 'luxon'
import { object, string, array, mixed } from 'yup'

export enum MeetingFormEnum {
    NAME = 'name',
    BEGIN_DATE = 'beginDate',
    END_DATE = 'endDate',
    TIME_START = 'timeStart',
    TIME_END = 'timeEnd',
    PLACE = 'place',
    GROUP = 'group',
    MEETING_ACTORS = 'meetingActors',
    MEETING_EXTERNAL_ACTORS = 'meetingExternalActors',
    MEETING_EXTERNAL_ACTORS_NAME = 'meetingExternalActorsName',
    MEETING_EXTERNAL_ACTORS_EMAIL = 'meetingExternalActorsEmail',
    MEETING_EXTERNAL_ACTORS_DESCRIPTION = 'meetingExternalActorsDescription',
    DESCRIPTION = 'description',
    MEETING_ATTACHMENTS = 'meetingAttachments',
    MEETING_LINKS = 'documentLinks',
    MEETING_CHANGE_REASON = 'descriptionOfChange',
    MEETING_PROPOSAL = 'meetingProposal',
    NOTIF_NEW_USERS = 'notifNewUsers',
    IGNORE_PERSONAL_SETTINGS = 'ignorePersonalSettings',
}

export const createMeetingSchema = (t: TFunction<'translation', undefined, 'translation'>) => {
    return object().shape({
        [MeetingFormEnum.NAME]: string().required(t('validation.required')),
        [MeetingFormEnum.BEGIN_DATE]: string()
            .required(t('validation.required'))
            .test('timeValid', t('validation.required'), (value) => value.length > 0 && value != 'Invalid DateTime')
            .test('timeStartValidation', t('validation.dateMustBeInFuture'), (value) => {
                return DateTime.fromJSDate(new Date(value)) > DateTime.fromJSDate(new Date())
            }),
        [MeetingFormEnum.END_DATE]: string()
            .required(t('validation.required'))
            .test('timeStartValidation', t('validation.required'), (value) => value.length > 0 && value != 'Invalid DateTime')
            .test('isLaterThanBegin', `${t('validation.timeMustBeGreaterThen')} ${t('meetings.start')}`, (value, schema) => {
                const { beginDate } = schema.parent
                return DateTime.fromJSDate(new Date(value)) > DateTime.fromJSDate(new Date(beginDate))
            }),

        [MeetingFormEnum.PLACE]: string().required(t('validation.required')),
        [MeetingFormEnum.GROUP]: array().of(string()),
        [MeetingFormEnum.MEETING_ACTORS]: mixed<ApiMeetingActor[]>().test({
            message: t('validation.required'),
            test: (value) => Array.isArray(value) && value.length > 0,
        }),
        [MeetingFormEnum.MEETING_EXTERNAL_ACTORS]: array(
            object().shape({
                name: string().required(t('validation.required')),
                email: string().email(t('validation.invalidEmail')).required(t('validation.required')),
                description: string(),
            }),
        ),
        [MeetingFormEnum.DESCRIPTION]: string().required(t('validation.required')),
        [MeetingFormEnum.MEETING_PROPOSAL]: array().of(string()),
        [MeetingFormEnum.MEETING_ATTACHMENTS]: mixed<ApiAttachment[]>(),
        [MeetingFormEnum.MEETING_LINKS]: mixed<ApiLink[]>(),
    })
}

export const editMeetingSchema = (t: TFunction<'translation', undefined, 'translation'>) => {
    return object().shape({
        [MeetingFormEnum.NAME]: string().required(t('validation.required')),
        [MeetingFormEnum.BEGIN_DATE]: string()
            .required(t('validation.required'))
            .test('timeStartValidation', t('validation.required'), (value) => value.length > 0 && value != 'Invalid DateTime'),
        [MeetingFormEnum.END_DATE]: string()
            .required(t('validation.required'))
            .test('timeStartValidation', t('validation.required'), (value) => value.length > 0 && value != 'Invalid DateTime')
            .test('isLaterThanBegin', `${t('validation.timeMustBeGreaterThen')} ${t('meetings.start')}`, (value, schema) => {
                const { beginDate } = schema.parent
                return DateTime.fromJSDate(new Date(value)) > DateTime.fromJSDate(new Date(beginDate))
            }),

        [MeetingFormEnum.PLACE]: string().required(t('validation.required')),
        [MeetingFormEnum.GROUP]: array().of(string()),
        [MeetingFormEnum.MEETING_ACTORS]: mixed<ApiMeetingActor[]>().test({
            message: t('validation.required'),
            test: (value) => Array.isArray(value) && value.length > 0,
        }),
        [MeetingFormEnum.MEETING_EXTERNAL_ACTORS]: array(
            object().shape({
                name: string().required(t('validation.required')),
                email: string().required(t('validation.required')),
                description: string(),
            }),
        ),
        [MeetingFormEnum.DESCRIPTION]: string().required(t('validation.required')),
        [MeetingFormEnum.MEETING_PROPOSAL]: array().of(string()),
        [MeetingFormEnum.MEETING_ATTACHMENTS]: mixed<ApiAttachment[]>(),
        [MeetingFormEnum.MEETING_LINKS]: mixed<ApiLink[]>(),
    })
}
