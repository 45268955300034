import React from 'react'
import { Row } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import { RowOpenIcon, RowCloseIcon } from '@isdd/metais-common/assets/images'

import styles from './expandableRow.module.scss'

interface ExpandableRowCellProps<T> {
    row: Row<T>
    ariaControlsId: string
}

export const ExpandableRowCellWrapper = <T,>({ row, ariaControlsId }: ExpandableRowCellProps<T>): JSX.Element => {
    const { t } = useTranslation()

    return (
        <button
            type="button"
            className={styles.expandableRowButton}
            aria-label={row.getIsExpanded() ? t('table.expandableCloseItem') : t('table.expandableExpandItem')}
            aria-expanded={row.getIsExpanded()}
            aria-controls={ariaControlsId}
            onClick={row.getToggleExpandedHandler()}
        >
            {row.getIsExpanded() ? <img src={RowCloseIcon} alt="" /> : <img src={RowOpenIcon} alt="" />}
        </button>
    )
}
