import { yupResolver } from '@hookform/resolvers/yup'
import { DateInput } from '@isdd/idsk-ui-kit/date-input/DateInput'
import { Button, ButtonGroupRow, IOption, Input, SimpleSelect } from '@isdd/idsk-ui-kit/index'
import { ATTRIBUTE_NAME } from '@isdd/metais-common/api'
import { ConfigurationItemUi } from '@isdd/metais-common/api/generated/cmdb-swagger'
import { Attribute } from '@isdd/metais-common/api/generated/types-repo-swagger'
import { CiLazySelect } from '@isdd/metais-common/components/ci-lazy-select/CiLazySelect'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { OptionProps } from 'react-select'
import { Option } from '@isdd/idsk-ui-kit/common/SelectCommon'
import { EnumType } from '@isdd/metais-common/api/generated/enums-repo-swagger'
import { Languages } from '@isdd/metais-common/localization/languages'

import { getDescriptionByAttribute, getNameByAttribute, getRequiredByAttribute } from '@/components/views/codeLists/CodeListDetailUtils'
import { REF_PORTAL_SUBMIT_ID } from '@/components/views/ref-identifiers/RefIdentifierCreateView'
import {
    RefCatalogFormTypeEnum,
    RefOntologyVersionFormType,
    RefOntologyVersionFormTypeEnum,
    refIdentifierCreateOntologyVersionSchema,
} from '@/components/views/ref-identifiers/forms/refCreateSchema'

interface RefOntologyVersionFormPropsType {
    onSubmit: (data: RefOntologyVersionFormType, send: boolean) => void
    onCancel: () => void
    clearUriExist: () => void
    isUpdate?: boolean
    isUriExist: boolean
    isDisabled?: boolean
    ciItemData?: ConfigurationItemUi
    attributes: Attribute[] | undefined
    ownerOptions: IOption<string>[]
    defaultOntologyUri?: string
    regStates?: EnumType
}

export const RefOntologyVersionForm: React.FC<RefOntologyVersionFormPropsType> = ({
    onSubmit,
    onCancel,
    clearUriExist,
    isUriExist,
    isUpdate,
    isDisabled,
    ciItemData,
    attributes,
    ownerOptions,
    defaultOntologyUri,
    regStates,
}) => {
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const attributeList = [
        ATTRIBUTE_NAME.Profil_VerziaOntologie_uri_distribucie,
        ATTRIBUTE_NAME.Profil_VerziaOntologie_typ_media,
        ATTRIBUTE_NAME.Profil_VerziaOntologie_verzia,
        ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_od,
        ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_do,
        ATTRIBUTE_NAME.Profil_VerziaOntologie_download_url,
        ATTRIBUTE_NAME.Gen_Profil_nazov,
        ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov,
    ]

    const attributesDefaultValues = Object.fromEntries(attributeList.map((item) => [item, ciItemData?.attributes?.[item]]))

    const methods = useForm<RefOntologyVersionFormType>({
        defaultValues: {
            [RefOntologyVersionFormTypeEnum.OWNER]: ownerOptions?.at(0)?.value,
            [RefOntologyVersionFormTypeEnum.ONTOLOGY]: defaultOntologyUri,
            attributes: attributesDefaultValues,
        },
        mode: 'onChange',
        resolver: yupResolver(refIdentifierCreateOntologyVersionSchema(t, language, attributesDefaultValues, attributes)),
    })

    const { register, formState, handleSubmit, setError, setValue, clearErrors, control, trigger } = methods
    const { errors } = formState

    const [selectedOntology, setSelectedOntology] = useState<ConfigurationItemUi | undefined>()

    const buttonRefId = document.getElementById(REF_PORTAL_SUBMIT_ID)

    useEffect(() => {
        if (isUriExist) {
            setError(`attributes.${ATTRIBUTE_NAME.Profil_VerziaOntologie_uri_distribucie}`, { message: t('refIdentifiers.create.uriAlreadyExist') })
        }
    }, [isUriExist, setError, t])

    useEffect(() => {
        formState.isSubmitted && trigger()
    }, [formState.isSubmitted, t, trigger])

    useEffect(() => {
        setValue(RefOntologyVersionFormTypeEnum.ONTOLOGY, selectedOntology?.uuid ?? '')
        setValue(
            `attributes.${ATTRIBUTE_NAME.Gen_Profil_nazov}`,
            `Verzia ontológie ${selectedOntology?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_nazov]}` as never,
        )
        setValue(
            `attributes.${ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov}`,
            `Ontology distribution ${selectedOntology?.attributes?.[ATTRIBUTE_NAME.Gen_Profil_anglicky_nazov]}` as never,
        )
    }, [selectedOntology, setValue])

    const selectLazyLoadingCiOption = (props: OptionProps<ConfigurationItemUi>) => {
        const optionAttributes = props.data.attributes
        return language === Languages.SLOVAK ? (
            <Option {...props}>
                <div>
                    <span>{`${optionAttributes?.Gen_Profil_nazov} (${
                        regStates?.enumItems?.find((e) => e.code === optionAttributes?.Gen_Profil_RefID_stav_registracie)?.value
                    })`}</span>
                </div>
            </Option>
        ) : (
            <Option {...props}>
                <div>
                    <span>{`${optionAttributes?.Gen_Profil_anglicky_nazov} (${
                        regStates?.enumItems?.find((e) => e.code === optionAttributes?.Gen_Profil_RefID_stav_registracie)?.engValue
                    })`}</span>
                </div>
            </Option>
        )
    }

    useEffect(() => {
        if (defaultOntologyUri) setValue(RefOntologyVersionFormTypeEnum.ONTOLOGY, defaultOntologyUri)
    }, [defaultOntologyUri, setValue])

    return (
        <form noValidate>
            {!isUpdate && (
                <SimpleSelect
                    label={t('refIdentifiers.create.ownerUser')}
                    options={ownerOptions ?? []}
                    setValue={setValue}
                    name={RefCatalogFormTypeEnum.OWNER}
                    isClearable={false}
                    clearErrors={clearErrors}
                    defaultValue={ownerOptions?.at(0)?.value}
                    error={errors?.[RefCatalogFormTypeEnum.OWNER]?.message}
                />
            )}
            <CiLazySelect
                ciType={'Ontologia'}
                label={t('refIdentifiers.create.ontologyUri')}
                setValue={setValue}
                clearErrors={clearErrors}
                name={RefOntologyVersionFormTypeEnum.ONTOLOGY}
                metaAttributes={{
                    state: ['DRAFT'],
                }}
                option={selectLazyLoadingCiOption}
                required
                error={errors?.[RefOntologyVersionFormTypeEnum.ONTOLOGY]?.message}
                selectedCi={selectedOntology}
                setSelectedCi={setSelectedOntology}
            />
            <Input
                required={getRequiredByAttribute(
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_uri_distribucie),
                )}
                disabled={isDisabled}
                label={getNameByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_uri_distribucie),
                )}
                info={getDescriptionByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_uri_distribucie),
                )}
                {...register(`attributes.${ATTRIBUTE_NAME.Profil_VerziaOntologie_uri_distribucie}`)}
                onBlur={clearUriExist}
                error={errors?.attributes?.[ATTRIBUTE_NAME.Profil_VerziaOntologie_uri_distribucie]?.message}
            />
            <Input
                required={getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_typ_media))}
                label={getNameByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_typ_media),
                )}
                info={getDescriptionByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_typ_media),
                )}
                {...register(`attributes.${ATTRIBUTE_NAME.Profil_VerziaOntologie_typ_media}`)}
                error={errors?.attributes?.[ATTRIBUTE_NAME.Profil_VerziaOntologie_typ_media]?.message}
            />

            <Input
                required={getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_verzia))}
                label={getNameByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_verzia),
                )}
                info={getDescriptionByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_verzia),
                )}
                {...register(`attributes.${ATTRIBUTE_NAME.Profil_VerziaOntologie_verzia}`)}
                error={errors?.attributes?.[ATTRIBUTE_NAME.Profil_VerziaOntologie_verzia]?.message}
            />
            <DateInput
                required={getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_od))}
                setValue={setValue}
                handleDateChange={(date, name) => date && setValue(name as keyof RefOntologyVersionFormType, DateTime.fromJSDate(date).toISO() ?? '')}
                {...register(`attributes.${ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_od}`)}
                control={control}
                label={getNameByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_od),
                )}
                info={getDescriptionByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_od),
                )}
                error={errors?.attributes?.[ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_od]?.message}
            />

            <DateInput
                required={getRequiredByAttribute(attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_do))}
                handleDateChange={(date, name) => date && setValue(name as keyof RefOntologyVersionFormType, DateTime.fromJSDate(date).toISO() ?? '')}
                setValue={setValue}
                {...register(`attributes.${ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_do}`)}
                control={control}
                label={getNameByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_do),
                )}
                info={getDescriptionByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_do),
                )}
                error={errors?.attributes?.[ATTRIBUTE_NAME.Profil_VerziaOntologie_platne_do]?.message}
            />

            <Input
                required={getRequiredByAttribute(
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_download_url),
                )}
                label={getNameByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_download_url),
                )}
                info={getDescriptionByAttribute(
                    language,
                    attributes?.find((item) => item.technicalName === ATTRIBUTE_NAME.Profil_VerziaOntologie_download_url),
                )}
                {...register(`attributes.${ATTRIBUTE_NAME.Profil_VerziaOntologie_download_url}`)}
                error={errors?.attributes?.[ATTRIBUTE_NAME.Profil_VerziaOntologie_download_url]?.message}
            />

            {buttonRefId &&
                createPortal(
                    <ButtonGroupRow>
                        <Button onClick={handleSubmit((data) => onSubmit(data, true))} label={t('refIdentifiers.create.finishRequest')} />
                        <Button onClick={handleSubmit((data) => onSubmit(data, false))} label={t('refIdentifiers.create.saveRequest')} />
                        <Button variant="secondary" onClick={onCancel} label={t('refIdentifiers.create.cancelRequest')} />
                    </ButtonGroupRow>,
                    buttonRefId,
                )}
        </form>
    )
}
