import { ActionsOverTable, QueryFeedback, formatDateForDefaultValue } from '@isdd/metais-common/index'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Filter, GridCol, GridRow, Input, PaginatorWrapper, SimpleSelect, Table, TextHeading } from '@isdd/idsk-ui-kit/index'
import { FlexColumnReverseWrapper } from '@isdd/metais-common/components/flex-column-reverse-wrapper/FlexColumnReverseWrapper'
import { BASE_PAGE_NUMBER, BASE_PAGE_SIZE, DEFAULT_PAGESIZE_OPTIONS, ENTITY_AS, ENTITY_ISVS, ENTITY_KS, PO } from '@isdd/metais-common/constants'
import { IFilter } from '@isdd/idsk-ui-kit/types'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import { MonitoredItemDto, MonitoredItemListDto } from '@isdd/metais-common/api/generated/monitoring-swagger'
import { Link } from 'react-router-dom'
import { SelectPOForFilter } from '@isdd/metais-common/components/select-po/SelectPOForFilter'
import { RouterRoutes } from '@isdd/metais-common/navigation/routeNames'
import { DateInput } from '@isdd/idsk-ui-kit/date-input/DateInput'

import { MainContentWrapper } from '@/components/MainContentWrapper'
import { MonitoringFilterData } from '@/components/containers/MonitoringServiceListContainer'

interface IServicesView {
    isLoading: boolean
    isError: boolean
    filterParams: MonitoringFilterData
    data?: MonitoredItemListDto
    defaultFilterValues: MonitoringFilterData
    handleFilterChange: (filter: IFilter) => void
}

export const ServicesView: React.FC<IServicesView> = ({ data, isError, isLoading, filterParams, defaultFilterValues, handleFilterChange }) => {
    const { t, i18n } = useTranslation()

    const columns: Array<ColumnDef<MonitoredItemDto>> = [
        {
            accessorFn: (row) => row?.entityName,
            enableSorting: true,
            header: t('monitoringServices.table.serviceName'),
            size: 200,
            id: 'entityName',
            meta: {
                getCellContext: (ctx: CellContext<MonitoredItemDto, unknown>) => ctx?.getValue?.(),
            },
            cell: (ctx) => (
                <Link
                    to={`${RouterRoutes.MONITORING_SERVICES}/monitoras?serviceType=${filterParams.serviceType}&serviceUuid=${
                        ctx?.row?.original?.entityId
                    }&dateFrom=${formatDateForDefaultValue(ctx.row?.original?.valuesDateFrom ?? '')}&dateTo=${formatDateForDefaultValue(
                        ctx.row?.original?.valuesDateTo ?? '',
                    )}`}
                >
                    {ctx?.row?.original?.entityName}
                </Link>
            ),
        },
        {
            accessorFn: (row) => row?.metaisCode,
            enableSorting: true,
            header: t('monitoringServices.table.code'),
            size: 150,
            meta: {
                getCellContext: (ctx: CellContext<MonitoredItemDto, unknown>) => ctx?.getValue?.(),
            },
            id: 'metaisCode',
            cell: (row) => row.getValue() as string,
        },
        {
            accessorFn: (row) => row?.lastValueDate,
            enableSorting: true,
            header: t('monitoringServices.table.lastValueDate'),
            size: 150,
            meta: {
                getCellContext: (ctx: CellContext<MonitoredItemDto, unknown>) => ctx?.getValue?.(),
            },
            id: 'lastValueDate',
            cell: (row) => t('dateTime', { date: row.getValue() as string }),
        },
        {
            accessorFn: (row) => row?.poName,
            header: filterParams.serviceType === ENTITY_AS ? t('ciType.meta.manager') : t('ciType.meta.gestor'),
            id: 'responsibleAuthority',
            size: 200,
            meta: {
                getCellContext: (ctx: CellContext<MonitoredItemDto, unknown>) => ctx?.getValue?.(),
            },
            cell: (row) => row.getValue() as string,
        },
        {
            accessorFn: (row) => row?.valuesDateFrom,
            header: t('monitoringServices.table.from'),
            id: 'start',
            meta: {
                getCellContext: (ctx: CellContext<MonitoredItemDto, unknown>) => ctx?.getValue?.(),
            },
            cell: (ctx) => new Date(ctx.row?.original?.valuesDateFrom ?? '').toLocaleDateString(i18n.language),
        },
        {
            accessorFn: (row) => row?.valuesDateTo,
            header: t('monitoringServices.table.to'),
            id: 'end',
            meta: {
                getCellContext: (ctx: CellContext<MonitoredItemDto, unknown>) => ctx?.getValue?.(),
            },
            cell: (ctx) => new Date(ctx.row?.original?.valuesDateTo ?? '').toLocaleDateString(i18n.language),
        },
    ]

    return (
        <MainContentWrapper>
            <QueryFeedback loading={isLoading} error={false} withChildren>
                <FlexColumnReverseWrapper>
                    <TextHeading size="XL">{t('titles.monitoringServices')}</TextHeading>
                    <QueryFeedback loading={false} error={isError} />
                </FlexColumnReverseWrapper>
                <Filter<MonitoringFilterData>
                    onlyForm
                    defaultFilterValues={defaultFilterValues}
                    form={({ setValue, clearErrors, filter, register, control, watch, isOpen }) => {
                        const { intervalEnd: end, intervalStart: start, liableEntity, serviceType } = watch()
                        const endIsLowerThanStart = start && end && new Date(start) > new Date(end)

                        const additionalDataForISVS = liableEntity ? { metaAttributes: { liableEntity: [liableEntity] } } : {}

                        return (
                            <div>
                                <GridRow>
                                    <GridCol setWidth="one-half">
                                        <SimpleSelect
                                            isClearable={false}
                                            name="serviceType"
                                            label={t('reports.filter.category')}
                                            options={[
                                                { label: ENTITY_AS, value: ENTITY_AS },
                                                { label: ENTITY_KS, value: ENTITY_KS },
                                            ]}
                                            setValue={setValue}
                                            clearErrors={clearErrors}
                                            defaultValue={filter.serviceType}
                                            tabIndex={isOpen ? undefined : -1}
                                        />
                                    </GridCol>
                                    <GridCol setWidth="one-half">
                                        <SelectPOForFilter
                                            isMulti={false}
                                            ciType={PO}
                                            label={serviceType === ENTITY_AS ? t('ciType.meta.manager') : t('ciType.meta.gestor')}
                                            name="liableEntity"
                                            valuesAsUuids={filter.liableEntity ? [filter.liableEntity] : []}
                                            onChange={(val) => setValue('liableEntity', Array.isArray(val) ? val?.[0]?.uuid : val)}
                                            tabIndex={isOpen ? undefined : -1}
                                        />
                                    </GridCol>
                                </GridRow>
                                <Input
                                    id="serviceName"
                                    label={serviceType === ENTITY_AS ? t('filter.AS.name') : t('filter.KS.name')}
                                    {...register('serviceName')}
                                />
                                <Input id="metaisCode" label={t('filter.metaisCode.label')} {...register('metaisCode')} />
                                <GridRow>
                                    <GridCol setWidth="full">
                                        <SelectPOForFilter
                                            key={liableEntity}
                                            isMulti={false}
                                            ciType={ENTITY_ISVS}
                                            label={t('monitoringServices.filter.isvs')}
                                            name="isvs"
                                            valuesAsUuids={filter.isvs ? [filter.isvs] : []}
                                            onChange={(val) => {
                                                setValue('isvs', Array.isArray(val) ? val?.[0]?.uuid : val)
                                            }}
                                            additionalData={{ ...additionalDataForISVS }}
                                            tabIndex={isOpen ? undefined : -1}
                                        />
                                    </GridCol>
                                </GridRow>
                                <DateInput
                                    label={t('monitoringServices.table.from')}
                                    {...register('intervalStart')}
                                    control={control}
                                    setValue={setValue}
                                />
                                <DateInput
                                    label={t('monitoringServices.table.to')}
                                    {...register('intervalEnd')}
                                    control={control}
                                    setValue={setValue}
                                    error={endIsLowerThanStart ? t('codeListList.requestValidations.dateGreaterThan') : undefined}
                                />
                            </div>
                        )
                    }}
                />
                <ActionsOverTable
                    pagination={{
                        pageNumber: filterParams.pageNumber ?? BASE_PAGE_NUMBER,
                        pageSize: filterParams.pageSize ?? BASE_PAGE_SIZE,
                        dataLength: data?.pagination?.totalItems ?? 0,
                    }}
                    handleFilterChange={handleFilterChange}
                    pagingOptions={DEFAULT_PAGESIZE_OPTIONS}
                    entityName={'reports'}
                    hiddenButtons={{ SELECT_COLUMNS: true }}
                />

                <Table
                    columns={columns}
                    data={data?.items}
                    sort={filterParams.sort}
                    onSortingChange={(columnSort) => {
                        handleFilterChange({ sort: columnSort })
                    }}
                />
                <PaginatorWrapper
                    dataLength={data?.pagination?.totalItems ?? 0}
                    pageNumber={filterParams.pageNumber ?? BASE_PAGE_NUMBER}
                    pageSize={filterParams.pageSize ?? BASE_PAGE_SIZE}
                    totalPages={data?.pagination?.totalPages}
                    handlePageChange={(page) => handleFilterChange({ pageNumber: page.pageNumber ?? BASE_PAGE_SIZE })}
                />
            </QueryFeedback>
        </MainContentWrapper>
    )
}
